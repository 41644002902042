import classNames from 'classnames';
import {MarkdownViewerClasses, marked} from 'ui-components';
import {sanitize} from 'front-core';
import {useMemo} from 'react';
import {BaseMessageRendererProps} from '../../message-viewer.types';
import classes from './markdown-message.module.scss';

interface Props extends BaseMessageRendererProps<string> {
  className?: string;
}

export const MarkdownMessage = (props: Props) => {
  const {message, className} = props;
  const rendered = useMemo(() => marked(message.content || '').trim(), [message.content]);

  return (
    <div
      className={classNames(classes.MarkdownMessage, MarkdownViewerClasses.Markdown, className)}
      dangerouslySetInnerHTML={{__html: sanitize(rendered)}}
    />
  );
};
