import React from 'react';
import {FormLabelInput} from '../../../../../shared/form/components/form-label-input.component';
import classes from './auth-text-input.module.scss';
import {TextInput} from 'ui-components';
interface OwnProps {
  required: boolean;
  label: string;
  placeholder?: string;
  name: string;
  type: 'text' | 'number' | 'password';
  helperText?: string;
}

type AllProps = OwnProps;

export const AuthFormTextInput = (props: AllProps) => {
  const {required, label, placeholder, name, type, helperText} = props;

  return (
    <FormLabelInput
      label={label}
      helperText={helperText}
      name={name}
      required={required}
      render={field => (
        <TextInput
          {...field}
          type={type}
          placeholder={placeholder || label}
          fullWidth
          className={classes.AuthInput}
          inputClassName={classes.InputClassName}
        />
      )}
    />
  );
};
