import classNames from 'classnames';
import classes from './table-events-value-query-builder.module.scss';
import {
  createTableEventsInitialQuery,
  TableEventsQueryBuilder,
} from '../table-events-query-builder/table-events-query-builder.component';
import {LiteralValueType, QueryBuilderFactory} from 'ui-components';
import {
  METADATA_KEY,
  PARAMETERS_METADATA_KEY,
} from '../../../../../constants/parameters-saved-keys';
import {Fragment, useCallback, useMemo, useState} from 'react';
import {get} from 'lodash';
import {del, set} from 'object-path-immutable';
import {TableColumnSmartSelector} from '../../smart-selector/table-column-smart-selector.component';
import TransKeys from '../../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {TableEvent} from '../../../../../objects/models/table-event.model';

interface OwnProps {
  query: any;
  onChange?: (query: any) => void;
  // this is only relevant for sameTypeThen=true
  onColumnTypeChange?: (type: LiteralValueType) => void;
  errors?: any;
  disabled?: boolean;
  multiEvents?: boolean;
  multiSelection?: boolean;
  filters?: any;
  columnFilters?: any;
  className?: string;
  thenText?: string;
  sameTypeThen?: boolean;
}

type AllProps = OwnProps;

const createCase = (when: unknown = null) => [when, QueryBuilderFactory.createTableColumn()];

export const createTableEventsValueInitialQuery = (tableEvent?: TableEvent) => {
  const q = QueryBuilderFactory.createCases();
  q.table_id = tableEvent?.tableId;
  q.column = tableEvent?.name;

  q[PARAMETERS_METADATA_KEY] = {
    [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: 'TableEventsValueQueryBuilder',
    [METADATA_KEY.TABLE_EVENT_ID_KEY]: tableEvent?.id,
  };

  q.else_value = {
    type: 'Literal',
    value: null,
    value_type: 'null',
  };
  let tableEventsInitialQuery = null;
  if (tableEvent) {
    tableEventsInitialQuery = createTableEventsInitialQuery(tableEvent);
  }

  q.cases = [createCase(tableEventsInitialQuery)];

  return q;
};

const DEFAULT_COLUMN_FILTERS = {
  literalType: [
    LiteralValueType.INTEGER,
    LiteralValueType.FLOAT,
    LiteralValueType.STRING,
    LiteralValueType.BOOLEAN,
  ],
};

const {TABLE_COLUMN_ID_KEY, TABLE_COLUMN_DATA_TYPE_KEY, TABLE_COLUMN_LITERAL_TYPE_KEY} =
  METADATA_KEY;
const QUERY_INDEX = 0;
export const THEN_INDEX = 1;

export const TableEventsValueQueryBuilder = (props: AllProps) => {
  const {
    query,
    onChange,
    onColumnTypeChange,
    errors = {},
    disabled,
    filters,
    columnFilters,
    multiEvents,
    multiSelection,
    className,
    thenText,
    sameTypeThen,
  } = props;
  const {t} = useTranslation();
  const [tableLiteralType, setTableLiteralType_] = useState();
  const setTableLiteralType = useCallback(
    t => {
      setTableLiteralType_(t);
      onColumnTypeChange && onColumnTypeChange(t);
    },
    [setTableLiteralType_, onColumnTypeChange]
  );
  const cases = useMemo(() => query?.cases || [], [query]);
  const numberOfActualCases = useMemo(
    () => (query ? cases.filter(c => c[0] !== null).length : 0),
    [cases, query]
  );
  const numberOfCases = useMemo(() => (query ? cases.length : 0), [cases, query]);

  const tableColumnFiltersByCase = useMemo(() => {
    let literalType = columnFilters?.literalType || DEFAULT_COLUMN_FILTERS.literalType;
    if (sameTypeThen && tableLiteralType && numberOfActualCases > 1) {
      literalType = tableLiteralType;
    }
    return cases.map(c => {
      return {
        literalType,
        tableId:
          get(c, `${QUERY_INDEX}.conditions.0.left.table_id`) ||
          // in case where was added
          get(c, `${QUERY_INDEX}.conditions.0.conditions.0.left.table_id`),
      };
    });
  }, [cases, sameTypeThen, tableLiteralType, numberOfActualCases, columnFilters?.literalType]);

  const onAddCase = useCallback(() => {
    const newQ = set(query, `cases.${numberOfCases}`, createCase());
    onChange(newQ);
  }, [query, numberOfCases, onChange]);

  const onDeleteCase = useCallback(
    (caseIdx: number) => {
      const newQ = del(query, `cases.${caseIdx}`);
      onChange(newQ);
      if (numberOfActualCases === 1) {
        setTableLiteralType(undefined);
      }
    },
    [query, onChange, numberOfActualCases, setTableLiteralType]
  );

  const onTableColumnChange = useCallback(
    (caseIdx: number, tableColumnId: number, tableColumn: any) => {
      const then = QueryBuilderFactory.createTableColumn();
      then.table_id = tableColumn.tableId;
      then.column = tableColumn.name;
      const newQ = set(query, `cases.${caseIdx}.${THEN_INDEX}`, {
        ...then,
        [PARAMETERS_METADATA_KEY]: {
          [TABLE_COLUMN_ID_KEY]: tableColumnId,
          [TABLE_COLUMN_DATA_TYPE_KEY]: tableColumn.dataType,
          [TABLE_COLUMN_LITERAL_TYPE_KEY]: tableColumn.literalType,
        },
      });
      if (caseIdx === 0) {
        setTableLiteralType(tableColumn.literalType);
      }
      onChange(newQ);
    },
    [query, onChange, setTableLiteralType]
  );

  const onTableEventsChange = useCallback(
    (caseIdx: number, v) => {
      // If there is only single case (TableEventsQueryBuilder),
      // and it was removed, we want to set the entire query to null.
      if (cases.length === 1 && v === null) {
        onChange(null);
        return;
      }
      let newQ = query;
      if (!newQ) {
        newQ = createTableEventsValueInitialQuery();
      }
      newQ = set(newQ, `cases.${caseIdx}.${QUERY_INDEX}`, v);
      newQ = set(newQ, `cases.${caseIdx}.${THEN_INDEX}`, null);
      onChange(newQ);
    },
    [query, onChange, cases]
  );

  if (cases.length === 0) {
    return (
      <div className={classNames(classes.TableEventsValueQueryBuilder, className)}>
        <TableEventsQueryBuilder
          query={null}
          errors={errors}
          onChange={v => onTableEventsChange(0, v)}
          disabled={disabled}
          filters={filters}
        />
      </div>
    );
  }

  return (
    <div
      className={classNames(
        classes.TableEventsValueQueryBuilder,
        (multiSelection || disabled) && numberOfCases > 1 && classes.MultiSelection,
        className
      )}
    >
      {cases.map((c, cIdx) => (
        <Fragment key={cIdx}>
          {cIdx > 0 && (
            <div className={classes.CaseHeader}>
              <div className={classes.Title}>or</div>
              {!disabled && (
                <div className={classes.Actions}>
                  <div className={classes.Action} onClick={() => onDeleteCase(cIdx)}>
                    {t(TransKeys.GENERAL.ACTIONS.DELETE)}
                  </div>
                </div>
              )}
            </div>
          )}
          <div className={classes.Option}>
            <TableEventsQueryBuilder
              query={get(c, QUERY_INDEX)}
              errors={get(errors, `cases.${cIdx}.${QUERY_INDEX}`)}
              onChange={v => onTableEventsChange(cIdx, v)}
              multiEvents={multiEvents}
              disabled={disabled}
              filters={filters}
            />
            {tableColumnFiltersByCase[cIdx]?.tableId && (
              <div className={classNames(classes.Property, classes.ThenProperty)}>
                <div className={classes.Label}>{thenText || 'then'}</div>
                <TableColumnSmartSelector
                  value={get(c, `${THEN_INDEX}.${PARAMETERS_METADATA_KEY}.${TABLE_COLUMN_ID_KEY}`)}
                  placeholder={'Property'}
                  onChange={(...args) => onTableColumnChange(cIdx, ...args)}
                  filters={tableColumnFiltersByCase[cIdx]}
                  error={Boolean(get(errors, `cases.${cIdx}.${THEN_INDEX}`))}
                  disabled={disabled}
                />
              </div>
            )}
          </div>
        </Fragment>
      ))}
      {multiSelection && numberOfActualCases > 0 && !disabled && (
        <div className={classes.Add}>
          <div onClick={onAddCase} className={classes.Action}>
            + {t(TransKeys.GENERAL.ACTIONS.ADD_ANOTHER)}
          </div>
        </div>
      )}
    </div>
  );
};
