import {Controller, useFormContext} from 'react-hook-form';

interface OwnProps {
  names: string[];
}

export const FormHiddenInputs = (props: OwnProps) => {
  const {names} = props;
  const {control} = useFormContext();

  return (
    <>
      {names.map(n => (
        <Controller
          key={n}
          name={n}
          control={control}
          render={({field}) => <input {...field} type={'hidden'} />}
        />
      ))}
    </>
  );
};
