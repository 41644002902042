import {Metric, MetricType} from '../models/metric.model';
import yup from '../../config/yup.config';
import {values} from 'lodash';
import {TableEntity} from '../models/table.model';
import httpClientService from '../../services/http-client.service';
import {validateNameNetworkRequest} from '../../http/validations.network-requests';
import {SegmentCategory} from '../models/segment.model';
import {queryValidator} from '../../modules/analyses/analysis-forms/analysis-parameters/shared-validators';
import {queryElementValidatorFactory} from './query-builder.dto';
import {AggregationMode} from '../models/signal.model';
import {nullableValues} from '../../utils/yup.utils';
import {modelEventPropertiesDataSchema} from './model-event-properties.dto';

export type MetricDTO = Partial<Metric>;
export type MetricCategoryDTO = Partial<SegmentCategory>;

export interface SwapCategoriesDTO {
  metricCategoryId: number;
  index: number;
  newIndex: number;
}

export interface SetMetricOwnerDTO {
  metricId: number;
  userId: number;
}

export interface UpdateTeamMetricsDTO {
  teamId: number;
  metrics: number[];
}

export interface AddMetricToTeamDTO {
  teamId: number;
  metricId: number;
}

export interface GetRCAForMetricByDateDTO {
  metricId: number;
  date: string;
  granularity: string;
}

export const metricDTOValidator = yup.object().shape({
  id: yup.number().optional().nullable(),
  name: yup
    .string()
    .required()
    .test('unique_name', 'Name already exists', async function (name: string) {
      const {id} = this.parent;
      try {
        await httpClientService.exec(
          validateNameNetworkRequest({
            name,
            existingId: id,
            modelType: 'metric',
          })
        );
        return true;
      } catch (e) {
        return false;
      }
    }),
  type: yup.string().required(),
  entity: yup.string().oneOf(values(TableEntity)).required(),
  categoryId: yup.number().nullable(),
  shortDescription: yup.string().nullable(),
  signalDefinition: queryValidator(),
  signalPopulationFilter: queryElementValidatorFactory(false),
  aggregationMode: yup
    .mixed()
    .oneOf(nullableValues(AggregationMode))
    .test({
      name: 'aggregationMode',
      test: function (aggregationMode) {
        const {type} = this.parent;
        if (!aggregationMode && !type) {
          return this.createError({
            message: `No aggregation mode and no metric type`,
          });
        }
        if (!aggregationMode && type) {
          if (type === MetricType.USAGE || type === MetricType.REVENUE) {
            return this.createError({
              message: `Aggregation mode is required for metric type ${type}`,
            });
          }
        }
        return true;
      },
    }),
  eventProperties: modelEventPropertiesDataSchema,
});

export const metricCategoryValidator = yup.object().shape({
  id: yup.number().optional().nullable(),
  name: yup.string().required(),
});
