import classNames from 'classnames';
import {
  AICMessageFeedback,
  AIConversationThread,
} from '../../../../../../objects/models/ai-chat.model';
import classes from './chat-messages.module.scss';
import {ModelType} from 'ui-components';
import {exists} from 'front-core';
import {MessageViewer} from '../message-viewer/message-viewer.component';
import {ChatEmptyState} from '../chat-empty-state/chat-empty-state.component';
import {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../../../constants/translation-keys';

interface OwnProps {
  thread: AIConversationThread;
  onMessageFeedback: (messageId: number, feedback: AICMessageFeedback) => void;
  onModelReference: (modelId: number, modelType: ModelType) => void;
  onRequestAnalysis: (analysisTypeId: number, parameters: any) => void;
  onSubmitMessage: (message: string) => void;
  isLoading?: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const ChatMessages = (props: AllProps) => {
  const {
    thread,
    isLoading,
    onMessageFeedback,
    onModelReference,
    onSubmitMessage,
    onRequestAnalysis,
    className,
  } = props;
  const {t} = useTranslation();
  const messageContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // scroll smooth to bottom on new messages
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTo({
        top: messageContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [messageContainerRef, thread]);

  if (!exists(thread)) {
    return (
      <div className={classNames(classes.ChatMessages, className)}>
        <ChatEmptyState onSelectSuggestion={s => onSubmitMessage(s.message)} />
      </div>
    );
  }

  return (
    <div className={classNames(classes.ChatMessages, className)}>
      <div className={classes.Messages} ref={messageContainerRef}>
        {thread.messages.map(m => (
          <MessageViewer
            key={m.id}
            message={m}
            onMessageFeedback={onMessageFeedback}
            onModelReference={onModelReference}
            onSubmitMessage={onSubmitMessage}
            onRequestAnalysis={onRequestAnalysis}
          />
        ))}
        {isLoading && (
          <div className={classes.LoadingMessage}>{t(TransKeys.GEN_AI_BOT.AI_LOADING_TEXT)}</div>
        )}
      </div>
    </div>
  );
};
