import {AnomalyMode} from '../../../../../../objects/models/homepage.model';
import {Select, LoopsIconPurple, EnumInputOption, HtmlTooltip} from 'ui-components';
import classes from './significancy-definition-selector.module.scss';
import {FC, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';

interface OwnProps {
  anomalyMode: AnomalyMode;
  anomalyThreshold: number;
  onChange: (anomalyMode: AnomalyMode, threshold: number) => void;
  disabled?: boolean;
  className?: string;
}
type AllProps = OwnProps;

const LoopsAnomalyDetectionOption: FC<{option: EnumInputOption}> = props => {
  const {t} = useTranslation();
  return (
    <HtmlTooltip
      placement={'right'}
      title={
        <div className={classes.LoopsAlgoTooltip}>
          {t(
            TransKeys.HOMEPAGE_CONFIGURATION_PANEL.SIGNIFICANCY_DEFINITION_SELECTOR
              .LOOPS_ALGO_HELPER_TEXT
          )}
        </div>
      }
    >
      <div className={classes.CustomOptionLabelContainer}>
        <div className={classes.CustomOptionLabel}>
          <LoopsIconPurple className={classes.Icon} />
          <div>
            {t(
              TransKeys.HOMEPAGE_CONFIGURATION_PANEL.SIGNIFICANCY_DEFINITION_SELECTOR
                .LOOPS_ALGO_TITLE
            )}
          </div>
          <div className={classes.Recommended}>
            {t(
              TransKeys.HOMEPAGE_CONFIGURATION_PANEL.SIGNIFICANCY_DEFINITION_SELECTOR
                .LOOPS_ALGO_SUBTITLE
            )}
          </div>
        </div>
      </div>
    </HtmlTooltip>
  );
};

const OPTIONS = [
  {
    anomalyMode: AnomalyMode.LOOPS_ALGO,
    threshold: 0.1,
    label: 'Loops anomaly detection',
    icon: LoopsIconPurple,
    labelRenderer: (option: EnumInputOption) => <LoopsAnomalyDetectionOption option={option} />,
    className: classes.OverrideEnumDialogOption,
  },
  {
    anomalyMode: AnomalyMode.XOX_SAME_WEEKDAY,
    threshold: 0.05,
    label: 'WoW ±5%',
    labelRenderer: () => <div className={classes.CustomOption}>WoW ±5%</div>,
    className: classes.OverrideEnumDialogOption,
  },
  {
    anomalyMode: AnomalyMode.XOX_SAME_WEEKDAY,
    threshold: 0.1,
    label: 'WoW ±10%',
    labelRenderer: () => <div className={classes.CustomOption}>WoW ±10%</div>,
    className: classes.OverrideEnumDialogOption,
  },
  {
    anomalyMode: AnomalyMode.XOX_SAME_WEEKDAY,
    threshold: 0.2,
    label: 'WoW ±20%',
    labelRenderer: () => <div className={classes.CustomOption}>WoW ±20%</div>,
    className: classes.OverrideEnumDialogOption,
  },
];

const generateOptionKey = option => `${option.anomalyMode}_${option.threshold}`;

const OPTIONS_MAP = OPTIONS.reduce((acc, option) => {
  acc[generateOptionKey(option)] = option;
  return acc;
}, {});

const SELECT_THRESHOLD_OPTIONS = {
  options: OPTIONS.map(o => ({
    label: o.label,
    icon: o.icon,
    labelRenderer: o.labelRenderer,
    className: o.className,
    value: generateOptionKey(o),
  })),
};

export const SignificancyDefinitionSelector = (props: AllProps) => {
  const {anomalyMode, anomalyThreshold, onChange: onChangeFromProps, disabled, className} = props;

  const value = useMemo(
    () =>
      generateOptionKey({
        anomalyMode,
        threshold: anomalyThreshold,
      }),
    [anomalyMode, anomalyThreshold]
  );

  const onChange = useCallback(
    (value: string) => {
      const {anomalyMode, threshold} = OPTIONS_MAP[value];
      onChangeFromProps(anomalyMode, threshold);
    },
    [onChangeFromProps]
  );

  return (
    <Select
      dropdownButtonClassName={classes.Selector}
      className={className}
      options={SELECT_THRESHOLD_OPTIONS}
      value={value}
      onChange={v => onChange(v as string)}
      disabled={disabled}
      clearable={false}
      searchable={false}
      sortValues={false}
    />
  );
};
