import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {composition, exists} from 'front-core';
import {
  ANALYSIS_ID_PATH_PARAM,
  ANALYSIS_RESULT_ID_PATH_PARAM,
} from '../../../../constants/app-routes';
import {useParams} from 'react-router';
import {NumberParam, useQueryParam} from 'use-query-params';
import {AnalysisHeader} from '../../components/analysis-header/analysis-header.component';
import classes from './view-analysis.module.scss';
import {AnalysisResults} from '../../../shared/core/document-viewer/analysis-results.component';
import {useDispatch, useSelector} from 'react-redux';
import {
  getReducedLoadingStateSelector,
  getSingleErrorSelector,
  getSingleSelectedSelector,
} from '../../../../store/store.selectors';
import {getAnalysisResultNetworkRequest} from '../../../../http/analysis-results.network-requests';
import {PanelKey} from '../../../../constants/panels';
import {
  createSelected,
  getSelected,
  removeSelected,
} from '../../../../store/selected/selected.actions';
import {getAnalysisNetworkRequest} from '../../../../http/analyses.network-requests';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {ModelKey} from '../../../../constants/model-key';
import {AnalysisResultStatus} from '../../../../objects/models/analysis-result.model';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../constants/translation-keys';
import {useSyncIsViewed} from '../../../../core/hooks/user-reactions.hook';
import {ModelDiscriminatorType} from '../../../../objects/models/user-reaction.model';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import {AdvancedDocument} from 'ui-components';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {getAnalysisStepProgress} from '../../../../utils/analysis-result.utils';
import {useAnalysisResultActions} from '../../hooks/use-analysis-result-actions.hook';
import {useAnalysisGuides} from '../../hooks/use-analysis-guides.hook';
import PageLayout from '../../../shared/components/layout/page-layout/index';
import {
  AppNavigatorContext,
  AppNavigatorDisplayMode,
} from '../../../../core/contexts/app-navigator.context';
import {PendoGuides, triggerPendoGuide} from '../../../../config/pendo.config';
import {useCurrentUser} from '../../../../core/hooks/use-user.hook';
import {AnalysisTypeId} from '../../../../constants/analysis-type-id';
import {CircularProgress} from '@material-ui/core';

import {ViewAnalysisEmptyState} from '../../components/view-analysis-empty-state/view-analysis-empty-state.component';
import {PanelType} from '../../../../objects/system/panel-type.enum';

interface OwnProps {}

type AllProps = OwnProps;

const PENDO_SHARE_ANALYSIS_GUIDE_DELAY = 1000 * 30; //30 seconds

const SELECTED_ANALYSIS_KEY = SharedSelectionKeys.VIEW_ANALYSIS__ANALYSIS;
const SELECTED_ANALYSIS_RESULT_KEY = SharedSelectionKeys.VIEW_ANALYSIS__RESULT;

const SHOW_RESULT_STATUS = [AnalysisResultStatus.COMPLETED, AnalysisResultStatus.FAILED];
export const RESULT_RUNNING_STATUSES = [
  AnalysisResultStatus.CREATED,
  AnalysisResultStatus.RUNNING,
  AnalysisResultStatus.QUEUED,
  AnalysisResultStatus.REQUESTED,
];

const ViewAnalysisComponent = (props: AllProps) => {
  const dispatch = useDispatch();
  const notify = useAmplitude();
  const {t} = useTranslation();
  const {openPrimaryPanel} = useContext(PanelsContext);
  const {setOverrideDisplayMode, resetOverrideDisplayMode} = useContext(AppNavigatorContext);
  const [docTitle, setDocTitle] = useState<string>(null);
  const {[ANALYSIS_ID_PATH_PARAM]: analysisId} = useParams<any>();
  const [analysisResultId, setAnalysisResultId] = useQueryParam(
    ANALYSIS_RESULT_ID_PATH_PARAM,
    NumberParam
  );
  const triggerAnalysisGuide = useAnalysisGuides();
  // Selectors
  const analysis = useSelector(state => getSingleSelectedSelector(SELECTED_ANALYSIS_KEY, state));
  const analysisResult = useSelector(state =>
    getSingleSelectedSelector(SELECTED_ANALYSIS_RESULT_KEY, state)
  );
  const isLoadingResult = useSelector(state =>
    getReducedLoadingStateSelector(SELECTED_ANALYSIS_RESULT_KEY)(state)
  );
  const error = useSelector(state => getSingleErrorSelector(SELECTED_ANALYSIS_RESULT_KEY, state));
  useSyncIsViewed(analysisResult, ModelDiscriminatorType.ANALYSIS_RESULT);
  const sharedActions = useAnalysisResultActions();
  const user = useCurrentUser();
  // Computes
  const isOwner = useMemo(
    () => analysisResult && analysisResult.requestedByUserId === user.id,
    [analysisResult, user]
  );
  const disableEditing = useMemo(
    () =>
      [AnalysisTypeId.A_B_TEST, AnalysisTypeId.RELEASE_IMPACT].includes(
        analysisResult?.analysisTypeId
      ),
    [analysisResult]
  );

  // Effects
  useEffect(() => {
    dispatch(
      createSelected({
        selectedKey: SELECTED_ANALYSIS_KEY,
        actionKey: SELECTED_ANALYSIS_KEY,
        request: getAnalysisNetworkRequest,
        modelKey: ModelKey.ANALYSIS,
      })
    );
    dispatch(
      createSelected({
        selectedKey: SELECTED_ANALYSIS_RESULT_KEY,
        actionKey: SELECTED_ANALYSIS_RESULT_KEY,
        request: getAnalysisResultNetworkRequest,
        modelKey: ModelKey.ANALYSIS_RESULT,
      })
    );
    // clean up
    return () => {
      dispatch(removeSelected(SELECTED_ANALYSIS_KEY));
      dispatch(removeSelected(SELECTED_ANALYSIS_RESULT_KEY));
    };
  }, [dispatch]);
  useEffect(() => {
    // get analysis
    analysisId && dispatch(getSelected(SELECTED_ANALYSIS_KEY, analysisId));
  }, [analysisId, dispatch]);
  useEffect(() => {
    // When analysis result id changes we need to get it
    analysisResultId && dispatch(getSelected(SELECTED_ANALYSIS_RESULT_KEY, analysisResultId));
  }, [analysisResultId, analysisId, dispatch]);
  useEffect(() => {
    // if there is no analysisResultId we will get the last result
    const lastResultId = analysis?.lastCompletedResultId || analysis?.lastResultId;
    if (lastResultId && !analysisResultId) {
      setAnalysisResultId(lastResultId, 'replaceIn');
    }
  }, [analysisResultId, analysis, setAnalysisResultId]);
  useEffect(() => {
    if (analysisResult?.id) {
      const sharedPayload = {
        id: analysisResult.id,
        analysis_type_id: analysisResult.analysisTypeId,
        is_example_analysis: analysisResult.isExample,
      };
      notify(AmplitudeEvent.ANALYSIS_DEEP_DIVE, sharedPayload);
    }
  }, [analysisResult, notify]);
  useEffect(() => {
    // Override display mode on component load and reset it on unmount
    setOverrideDisplayMode(AppNavigatorDisplayMode.COLLAPSED);
    return () => resetOverrideDisplayMode();
  }, [setOverrideDisplayMode, resetOverrideDisplayMode]);
  const showResults = useMemo(
    () =>
      Boolean(analysisResult) &&
      (SHOW_RESULT_STATUS.includes(analysisResult.status) || exists(analysisResult.rootDocumentId)),
    [analysisResult]
  );
  const isInProgress =
    analysisResult && RESULT_RUNNING_STATUSES.indexOf(analysisResult.status) > -1;
  const analysisStepProgress = useMemo(
    () => getAnalysisStepProgress(analysisResult),
    [analysisResult]
  );

  const onDocPageChange = useCallback(
    (doc: AdvancedDocument) => {
      if (doc.id !== analysisResult?.rootDocumentId) {
        setDocTitle(doc.title || 'Deep dive');
        return;
      }
      setDocTitle(null);
    },
    [analysisResult]
  );
  // remove react warning
  const onChangeParameters = sharedActions.onChangeParameters;
  const onParametersChange = useCallback(
    (parameters: any) => onChangeParameters(analysisResult, parameters),
    [onChangeParameters, analysisResult]
  );
  const onDuplicate = useCallback(
    () => sharedActions.onDuplicate(analysisResult),
    [sharedActions, analysisResult]
  );

  const onDocumentResolvedSuccess = useCallback(
    (docId: string) => {
      if (!isLoadingResult && showResults && analysisResult.rootDocumentId === docId) {
        triggerAnalysisGuide(analysisResult);
      }
    },
    [isLoadingResult, showResults, analysisResult, triggerAnalysisGuide]
  );
  const onVersions = () =>
    openPrimaryPanel(
      PanelKey.ANALYSIS_RESULTS_PANEL,
      {
        [ANALYSIS_ID_PATH_PARAM]: analysisId,
      },
      PanelType.SIDE_PANEL
    );

  useEffect(() => {
    if (isOwner && showResults) {
      const timeout = setTimeout(() => {
        triggerPendoGuide(PendoGuides.SHARE_ANALYSIS);
      }, PENDO_SHARE_ANALYSIS_GUIDE_DELAY);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isOwner, showResults]);

  // useEffect(() => {
  //   const listener = action => {
  //     if (
  //       action.payload.modelKey === ModelKey.ANALYSIS_RESULT &&
  //       analysisResultId &&
  //       Number(analysisResultId) === action.payload.data?.id
  //     ) {
  //       dispatch(getSelected(SELECTED_ANALYSIS_RESULT_KEY, analysisResultId));
  //     }
  //   };
  //   dispatch(registerActionListener(CoreActionsType.MODEL_UPDATED, listener));
  //   return () => {
  //     dispatch(removeActionListener(CoreActionsType.MODEL_UPDATED, listener));
  //   };
  // }, [dispatch, analysisResultId]);

  return (
    <PageLayout.Layout>
      <AnalysisHeader
        {...sharedActions}
        onDuplicate={onDuplicate}
        analysisResult={analysisResult}
        docTitle={docTitle}
        versionsCount={analysis?.resultsCount}
        onVersions={() => onVersions()}
        disableEditing={disableEditing}
      />
      <PageLayout.Body noPadding isLoading={isLoadingResult}>
        <div className={classes.ViewAnalysis}>
          <div className={classes.Body}>
            {!showResults && !isLoadingResult && (
              <ViewAnalysisEmptyState analysisResult={analysisResult} error={error} />
            )}
            {isInProgress && showResults && (
              <div className={classes.InProgress}>
                <CircularProgress
                  variant={'indeterminate'}
                  disableShrink
                  size={16}
                  thickness={4}
                  color={'inherit'}
                />
                {t(TransKeys.ANALYSIS_RESULT.RUNNING_EMPTY_STATE.TITLE)}{' '}
                {analysisStepProgress > 0 ? `${Math.floor(analysisStepProgress * 100)}%` : ''}
              </div>
            )}
            {showResults && (
              <AnalysisResults
                analysisResult={analysisResult}
                isLoading={isLoadingResult}
                onPageChange={onDocPageChange}
                onParametersChange={onParametersChange}
                navigationDisabled
                onDocumentResolvedSuccess={onDocumentResolvedSuccess}
              />
            )}
          </div>
        </div>
      </PageLayout.Body>
    </PageLayout.Layout>
  );
};

const ViewAnalysis = composition<OwnProps>(ViewAnalysisComponent);

export default ViewAnalysis;
