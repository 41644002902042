import classNames from 'classnames';
import classes from './standard-check-box.module.scss';
import {CheckIcon} from 'ui-components';

interface OwnProps {
  checked?: boolean;
  radio?: boolean;
  onChange?: (newValue: boolean) => void;
  disabled?: boolean;
  className?: string;
  size?: 'small' | 'medium';
  error?: boolean;
}

type AllProps = OwnProps;

const StandardCheckBox = (props: AllProps) => {
  const {checked, onChange, radio, disabled, className, size = 'medium', error} = props;

  return (
    <div
      className={classNames(
        classes.StandardCheckBox,
        size && classes[size],
        disabled && classes.Disabled,
        checked && classes.Checked,
        radio && classes.Radio,
        error && classes.Error,
        className
      )}
      onClick={onChange && !disabled ? _ => onChange(!checked) : undefined}
    >
      {checked && !radio && (
        <CheckIcon className={classNames(classes.Icon, disabled && classes.Disabled)} />
      )}
      {checked && radio && <div className={classes.Circle} />}
    </div>
  );
};

export {StandardCheckBox};
