import classes from './auth-scope-form-container.module.scss';
import {ImagesResource} from '../../../../../assets/images';
import {ReactNode} from 'react';

interface OwnProps {
  children?: ReactNode;
}

type AllProps = OwnProps;

const AuthScopeFormContainer = (props: AllProps) => {
  const {children} = props;
  return (
    <div className={classes.Container}>
      <div className={classes.Logo}>
        <img src={ImagesResource.app.logoPurple} alt={'Logo'} />
      </div>
      <div className={classes.FormContainer}>{children}</div>
    </div>
  );
};

export default AuthScopeFormContainer;
