import {useTranslation} from 'react-i18next';
import {useFormContext} from 'react-hook-form';
import classes from '../../homepage-subscription-panel.module.scss';
import classNames from 'classnames';
import TransKeys from 'translations';
import {BullsEyeArrowDuotoneIcon, ChevronDownRegularIcon} from 'ui-components';
import {
  HomepageSubscriptionUnit,
  HomepageSettings,
  HomepageSubscriptionPushType,
} from '../../../../../../objects/models/homepage.model';
import {GenericLoading} from '../../../../../shared/components/general/generic-loading/generic-loading.component';
import {MetricSettingsList} from '../metric-settings-list/metric-settings-list.component';
import {useCallback, useMemo, useState} from 'react';
import {Metric} from '../../../../../../objects/models/metric.model';
import {keys, values} from 'lodash';

export interface SubscriptionMetricsConfigProps {
  isLoadingMetrics: boolean;
  metrics: Metric[];
  homepageSettings: HomepageSettings;
  homepageSubscriptionUnit: HomepageSubscriptionUnit;
}

type AllProps = SubscriptionMetricsConfigProps;

enum MetricSettingsName {
  WEEKLY = 'weeklyMetricSettings',
  DAILY = 'dailyMetricSettings',
}

export const SubscriptionMetricsConfig = (props: AllProps) => {
  const {isLoadingMetrics, metrics, homepageSettings, homepageSubscriptionUnit} = props;

  const {t} = useTranslation();
  const [collapseSubscription, setCollapseSubscription] = useState(false);
  const metricSettingsName = useMemo(() => {
    switch (homepageSubscriptionUnit) {
      case HomepageSubscriptionUnit.WEEKLY:
        return MetricSettingsName.WEEKLY;
      case HomepageSubscriptionUnit.DAILY:
        return MetricSettingsName.DAILY;
    }
  }, [homepageSubscriptionUnit]);
  const translationPath = useMemo(
    () => TransKeys[`${homepageSubscriptionUnit.toUpperCase()}_SUBSCRIPTION_CONFIG`],
    [homepageSubscriptionUnit]
  );
  const {setValue, watch} = useFormContext();
  const isActive = watch('isActive');
  const metricSettings = watch(metricSettingsName);

  const metricsDataForList = useMemo(
    () =>
      values(metrics).sort(
        (a, b) => homepageSettings.metrics.indexOf(a.id) - homepageSettings.metrics.indexOf(b.id)
      ),
    [metrics, homepageSettings.metrics]
  );

  const onChangeAllMetrics = useCallback(
    (pushType: HomepageSubscriptionPushType) => {
      const newMetrics = {};
      for (const metricId of keys(metricSettings)) {
        newMetrics[metricId] = pushType;
      }
      setValue(metricSettingsName, newMetrics);
    },
    [setValue, metricSettings, metricSettingsName]
  );

  const selectedMetricsCounts = useMemo(() => {
    const total = Object.keys(metricSettings).length;
    const totalNever = Object.values(metricSettings).filter(
      v => v === HomepageSubscriptionPushType.NEVER
    ).length;
    return {
      total,
      totalSelected: total - totalNever,
    };
  }, [metricSettings]);

  const texts = useMemo(
    () => ({
      title: t(translationPath.TITLE),
      description: t(translationPath.DESCRIPTION),
      metricListTitle: t(translationPath.METRIC_LIST.TITLE),
      metricListSubTitle: t(translationPath.METRIC_LIST.TITLE),
      significantChangeDescription: t(translationPath.SIGNIFICANT_CHANGE_DESCRIPTION),
    }),
    [t, translationPath]
  );

  const handleCollapse = useCallback(() => {
    setCollapseSubscription(prev => !prev);
  }, [setCollapseSubscription]);

  return (
    <div
      className={classNames(
        classes.CollapsibleHomepageSubscriptionSettingsContainer,
        collapseSubscription && classes.Collapse,
        !collapseSubscription && classes.Expand
      )}
    >
      <div className={classNames(classes.Block, classes.HeadBlock)}>
        <div className={classes.Control}>
          <div className={classes.Activation}>
            <div className={classes.Title} onClick={handleCollapse}>
              <span>{texts.title}</span>
              <span className={classes.ThinText}>
                ({selectedMetricsCounts.totalSelected}/{selectedMetricsCounts.total})
              </span>
            </div>
          </div>
          <ChevronDownRegularIcon
            className={classNames(
              classes.Chevron,
              !collapseSubscription && classes.Expand,
              collapseSubscription && classes.Collapse
            )}
            onClick={handleCollapse}
          />
        </div>
      </div>
      <div className={classes.Block}>
        <div className={classes.SubTitle}>
          <BullsEyeArrowDuotoneIcon />
          <span className={classes.Text}>{texts.metricListTitle}</span>
        </div>
        {texts.metricListSubTitle && (
          <div className={classes.Description}>{texts.metricListSubTitle}</div>
        )}
        <div className={classes.MetricSettings}>
          {isLoadingMetrics && <GenericLoading />}
          {!isLoadingMetrics && metrics && (
            <MetricSettingsList
              disabled={!isActive}
              metricsData={metricsDataForList}
              settings={metricSettings}
              onChange={(metricId, pushType) =>
                setValue(metricSettingsName, {
                  ...metricSettings,
                  [metricId]: pushType,
                })
              }
              onChangeAll={pushType => onChangeAllMetrics(pushType)}
              mode={homepageSubscriptionUnit}
            />
          )}
        </div>
      </div>
    </div>
  );
};
