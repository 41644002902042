import classNames from 'classnames';
import classes from './team-filter-helper.module.scss';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';

interface OwnProps {
  teamName?: string;
  onIgnore: () => void;
  className?: string;
}

type AllProps = OwnProps;

export const TeamFilterHelper = (props: AllProps) => {
  const {teamName, onIgnore, className} = props;
  const {t} = useTranslation();

  return (
    <div className={classNames(classes.TeamFilterHelper, className)}>
      {t(TransKeys.SELECTORS.TEAM_FILTER_HELPER, {team: teamName})}
      <span className={classes.ViewAll} onClick={onIgnore}>
        {t(TransKeys.GENERAL.ACTIONS.VIEW_ALL)}
      </span>
    </div>
  );
};
