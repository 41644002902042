import {action, ActionHooks, OnSuccessActionHook} from 'front-core';
import {
  HealthMonitorGroupDTO,
  HealthMonitorInitializeDTO,
  MonitoredMetricDTO,
  RemoveHealthMonitorGroupDTO,
} from '../../objects/dto/health-monitor.dto';
import {HealthMonitorSubscriptionDTO} from '../../objects/dto/health-monitor-subscription.dto';

import {showConfirmationMessage} from '../interface/interface.actions';
import i18n from '../../config/i18n.config';
import TransKeys from '../../constants/translation-keys';

export enum HealthMonitorActionType {
  CREATE_MONITORED_METRIC = '@@HEALTH_MONITOR/CREATE_MONITORED_METRIC',
  EDIT_MONITORED_METRIC = '@@HEALTH_MONITOR/EDIT_MONITORED_METRIC',
  REMOVE_MONITORED_METRIC = '@@HEALTH_MONITOR/REMOVE_MONITORED_METRIC',
  CREATE_HEALTH_MONITOR_GROUP = '@@HEALTH_MONITOR/CREATE_HEALTH_MONITOR_GROUP',
  EDIT_HEALTH_MONITOR_GROUP = '@@HEALTH_MONITOR/EDIT_HEALTH_MONITOR_GROUP',
  REMOVE_HEALTH_MONITOR_GROUP = '@@HEALTH_MONITOR/REMOVE_HEALTH_MONITOR_GROUP',
  HEALTH_MONITOR_CHANGED = '@@HEALTH_MONITOR/HEALTH_MONITOR_CHANGED',
  INITIALIZE_HEALTH_MONITOR = '@@HEALTH_MONITOR/INITIALIZE_HEALTH_MONITOR',
  CREATE_HEALTH_MONITOR_SUBSCRIPTION = '@@HEALTH_MONITOR/CREATE_HEALTH_MONITOR_SUBSCRIPTION',
  EDIT_HEALTH_MONITOR_SUBSCRIPTION = '@@HEALTH_MONITOR/EDIT_HEALTH_MONITOR_SUBSCRIPTION',
  DELETE_HEALTH_MONITOR_SUBSCRIPTION = '@@HEALTH_MONITOR/DELETE_HEALTH_MONITOR_SUBSCRIPTION',
}

export const healthMonitorChanged = (id?: number) =>
  action(HealthMonitorActionType.HEALTH_MONITOR_CHANGED, {id});

export const createHealthMonitorGroup = (
  data: HealthMonitorGroupDTO,
  onSuccess?: OnSuccessActionHook
) =>
  action(HealthMonitorActionType.CREATE_HEALTH_MONITOR_GROUP, data, {
    onSuccess,
  });

export const editHealthMonitorGroup = (
  data: HealthMonitorGroupDTO,
  onSuccess?: OnSuccessActionHook
) =>
  action(HealthMonitorActionType.EDIT_HEALTH_MONITOR_GROUP, data, {
    onSuccess,
  });

export const removeHealthMonitorGroupConfirmed = (data: RemoveHealthMonitorGroupDTO) =>
  showConfirmationMessage(
    {
      title: i18n.t(TransKeys.CONFIRMATIONS.DELETE_METRIC_SERIES_GROUP.TITLE),
      content: i18n.t(TransKeys.CONFIRMATIONS.DELETE_METRIC_SERIES_GROUP.CONTENT),
    },
    () => [removeHealthMonitorGroup(data)]
  );

export const removeHealthMonitorGroup = (data: RemoveHealthMonitorGroupDTO) =>
  action(HealthMonitorActionType.REMOVE_HEALTH_MONITOR_GROUP, data);

export const removeMonitoredMetricConfirmed = (id: number) =>
  showConfirmationMessage(
    {
      title: i18n.t(TransKeys.CONFIRMATIONS.DELETE_MONITORED_METRIC.TITLE),
      content: i18n.t(TransKeys.CONFIRMATIONS.DELETE_MONITORED_METRIC.CONTENT),
    },
    () => [removeMonitoredMetric(id)]
  );

export const removeMonitoredMetric = (monitoredMetricId: number) =>
  action(HealthMonitorActionType.REMOVE_MONITORED_METRIC, monitoredMetricId);

export const createMonitoredMetric = (data: MonitoredMetricDTO, onSuccess?: OnSuccessActionHook) =>
  action(HealthMonitorActionType.CREATE_MONITORED_METRIC, data, {onSuccess});

export const editMonitoredMetric = (data: MonitoredMetricDTO, onSuccess?: OnSuccessActionHook) =>
  action(HealthMonitorActionType.EDIT_MONITORED_METRIC, data, {onSuccess});

export const initializeHealthMonitor = (
  data: HealthMonitorInitializeDTO,
  onSuccess?: OnSuccessActionHook
) =>
  action(HealthMonitorActionType.INITIALIZE_HEALTH_MONITOR, data, {
    onSuccess,
  });

export const createHealthMonitorSubscription = (
  data: HealthMonitorSubscriptionDTO,
  hooks?: ActionHooks
) => action(HealthMonitorActionType.CREATE_HEALTH_MONITOR_SUBSCRIPTION, data, hooks);

export const editHealthMonitorSubscription = (
  data: HealthMonitorSubscriptionDTO,
  hooks?: ActionHooks
) => action(HealthMonitorActionType.EDIT_HEALTH_MONITOR_SUBSCRIPTION, data, hooks);

export const deleteHealthMonitorSubscription = (
  data: Omit<HealthMonitorSubscriptionDTO, 'cronExp'>,
  hooks?: ActionHooks
) => action(HealthMonitorActionType.DELETE_HEALTH_MONITOR_SUBSCRIPTION, data, hooks);
