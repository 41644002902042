import {useMemo} from 'react';
import {GenericLoading} from '../../../../../shared/components/general/generic-loading/generic-loading.component';
import {AnalysisResults} from '../../../../../shared/core/document-viewer/analysis-results.component';
import {DocumentElementType, RCAFunnelFigureViewer} from 'ui-components';
import classes from './funnel-page-rca.module.scss';
import {
  AnalysisResult,
  AnalysisResultStatus,
} from '../../../../../../objects/models/analysis-result.model';
import {ViewAnalysisEmptyState} from '../../../../../analyses/components/view-analysis-empty-state/view-analysis-empty-state.component.tsx';

type FunnelPageRCAProps = {
  analysisResult: AnalysisResult;
  targetSignalId?: number;
  onSelectRCATargetFunnelStep?: (targetSignalId: number) => void;
  isLoadingResult?: boolean;
  onDocumentResolved?: () => void;
};

export const FunnelPageRCA = (props: FunnelPageRCAProps) => {
  const {
    analysisResult,
    onDocumentResolved,
    isLoadingResult,
    targetSignalId,
    onSelectRCATargetFunnelStep,
  } = props;

  const componentsMap = useMemo(() => {
    return {
      [DocumentElementType.RCA_FUNNEL_FIGURE]: props => (
        <RCAFunnelFigureViewer
          {...props}
          targetSignalId={targetSignalId}
          onSelectRCATargetFunnelStep={onSelectRCATargetFunnelStep}
        />
      ),
    };
  }, [targetSignalId, onSelectRCATargetFunnelStep]);

  const showResult =
    analysisResult?.status === AnalysisResultStatus.COMPLETED && analysisResult?.rootDocumentId;

  if (!showResult) {
    return (
      <ViewAnalysisEmptyState analysisResult={analysisResult} className={classes.MarginBottom} />
    );
  }

  return (
    <>
      {isLoadingResult && <GenericLoading />}
      {analysisResult && (
        <AnalysisResults
          key={analysisResult.id}
          analysisResult={analysisResult}
          isLoading={isLoadingResult}
          componentsMap={componentsMap}
          onDocumentResolvedSuccess={onDocumentResolved}
          scrollable={false}
          navigationDisabled
        />
      )}
    </>
  );
};
