import classes from './rca-date-picker.module.scss';
import {DatePickerInput} from 'ui-components';
import {useCallback, useMemo} from 'react';
import moment from 'moment/moment';
import {TIME_FORMATS} from '../../../../../../constants/time-formats';
import {createStartDateComparingProps, dateToLabel} from '../../../../../../utils/dates.utils';

interface OwnProps {
  selected: string;
  maxDate: string;
  granularity: 'day' | 'week' | 'month';
  onChange: (date: string) => void;
  isLoading?: boolean;
}

type AllProps = OwnProps;

export const RCADatePicker = (props: AllProps) => {
  const {selected, onChange, maxDate, granularity, isLoading} = props;

  const datePickerProps = useMemo(
    () => ({
      ...createStartDateComparingProps(granularity),
      maxDate: moment.utc(maxDate).toDate(),
    }),
    [maxDate, granularity]
  );

  const renderValue = useCallback(
    (value: string) => dateToLabel(value, granularity),
    [granularity]
  );

  return (
    <div className={classes.DatePickerContainer}>
      <DatePickerInput
        className={classes.Picker}
        value={selected}
        onChange={onChange}
        disabled={isLoading}
        utc
        {...datePickerProps}
        icon={null}
        renderValue={renderValue}
        dateInputFormat={TIME_FORMATS.PARAMETER_DATE_FORMAT}
      />
    </div>
  );
};
