export enum SharedSelectionKeys {
  VIEW_GROWTH_MAP__MAP = 'VIEW_GROWTH_MAP/MAP',
  VIEW_ANALYSIS__ANALYSIS = 'VIEW_ANALYSIS/ANALYSIS',
  VIEW_ANALYSIS__RESULT = 'VIEW_ANALYSIS/RESULT',
  VIEW_OPPORTUNITY__OPPORTUNITY = 'VIEW_OPPORTUNITY/OPPORTUNITY',
  VIEW_OPPORTUNITY__RESULT = 'VIEW_OPPORTUNITY/RESULT',
  VIEW_EXPERIMENT__RESULT = 'VIEW_EXPERIMENT/RESULT',
  VIEW_EXPERIMENT__EXPERIMENT = 'VIEW_EXPERIMENT/EXPERIMENT',
  VIEW_MONITORED_METRIC__MONITORED_METRIC = 'VIEW_MONITORED_METRIC/MONITORED_METRIC',
  EXPERIMENT_FORM__EXPERIMENT = 'EXPERIMENT_FORM/EXPERIMENT',
  WORKFLOW_FORM__WORKFLOW = 'WORKFLOW__FORM/WORKFLOW',
  WORKFLOW_FORM__SLACK_CHANNELS = 'WORKFLOW__FORM/SLACK_CHANNELS',
  GOAL_FORM__GOAL = 'GOAL_FORM/GOAL',
  GOAL_FORM__METRIC = 'GOAL_FORM/METRIC',
  ANNOTATION_FORM__ANNOTATION = 'ANNOTATION_FORM/ANNOTATION',
  ANALYSIS_TYPE_PANEL__ANALYSIS_TYPE = 'ANALYSIS_TYPE_PANEL/ANALYSIS_TYPE',
  OPPORTUNITY_ACTIONS_PANEL__OPPORTUNITY = 'OPPORTUNITY_ACTIONS_PANEL/OPPORTUNITY',
  FUNNEL_FORM__FUNNEL = 'FUNNEL_FORM/FUNNEL',
  ANALYSIS_FORM__ANALYSIS_RESULT = 'ANALYSIS_FORM/ANALYSIS_RESULT',
  RENAME_ANALYSIS_PANEL__ANALYSIS_RESULT = 'RENAME_ANALYSIS_PANEL/ANALYSIS_RESULT',
  FEATURE_FORM__FEATURE = 'FEATURE_FORM/FEATURE',
  FEATURE_FORM__TABLE_EVENT = 'FEATURE_FORM/TABLE_EVENT',
  TABLE_FORM__TABLE = 'TABLE_FORM/TABLE',
  SEGMENT_FORM__SEGMENT = 'SEGMENT_FORM/SEGMENT',
  SEGMENT_FORM__TABLE_COLUMN = 'SEGMENT_FORM/TABLE_COLUMN',
  SEGMENT_FORM__TABLE_EVENT = 'SEGMENT_FORM/TABLE_EVENT',
  SEGMENT_CATEGORY_FORM__SEGMENT_CATEGORY = 'SEGMENT_CATEGORY_FORM/SEGMENT_CATEGORY',
  METRIC_CATEGORY_FORM__METRIC_CATEGORY = 'METRIC_CATEGORY_FORM/METRIC_CATEGORY',
  SEGMENT_VIEW__SEGMENT = 'SEGMENT_VIEW/SEGMENT',
  EVENT_GROUP_FORM__EVENT_GROUP = 'EVENT_GROUP_FORM/EVENT_GROUP',
  EXPERIMENT_VIEW__EXPERIMENT = 'EXPERIMENT_VIEW/EXPERIMENT',
  RENAME_EXPERIMENT_PANEL__EXPERIMENT = 'RENAME_EXPERIMENT_PANEL__EXPERIMENT',
  METRIC_FORM__METRIC = 'METRIC_FORM/METRIC',
  METRIC_VIEW__METRIC = 'METRIC_VIEW/METRIC',
  MILESTONE_FORM__MILESTONE = 'MILESTONE_FORM/MILESTONE',
  MILESTONE_FORM__MILESTONES_STATUS = 'MILESTONE_FORM/MILESTONES_STATUS',
  MILESTONE_VIEW__MILESTONE = 'MILESTONE_VIEW/MILESTONE',
  EVENT_GROUP_VIEW__EVENT_GROUP = 'EVENT_GROUP_VIEW/EVENT_GROUP',
  FUNNEL_VIEW__FUNNEL = 'FUNNEL_VIEW/FUNNEL',
  FEATURE_VIEW__FEATURE = 'FEATURE_VIEW/FEATURE',
  CONTENT_VIEW__CONTENT = 'CONTENT_VIEW/CONTENT',
  CONTENT_FORM__FEATURE = 'CONTENT_FORM/CONTENT',
  TABLE_VIEW__TABLE = 'TABLE_VIEW/TABLE',
  TABLE_VIEW__TABLE_COLUMNS = 'TABLE_VIEW/TABLE_COLUMNS',
  MONITORED_METRIC_FORM__MONITORED_METRIC = 'MONITORED_METRIC_FORM/MONITORED_METRIC',
  HEALTH_MONITOR_GROUP_FORM__GROUP = 'HEALTH_MONITOR_GROUP_FORM/GROUP',
  HEALTH_MONITOR_SUBSCRIPTION_PANEL__HEALTH_MONITOR = 'HEALTH_MONITOR_SUBSCRIPTION_PANEL/HEALTH_MONITOR',
  VIEW_METRIC_SERIES_RESULT__RESULT = 'VIEW_METRIC_SERIES_RESULT/RESULT',
  VIEW_PHENOMENA__PHENOMENA = 'VIEW_PHENOMENA/PHENOMENA',
  VIEW_PHENOMENA__RESULT = 'VIEW_PHENOMENA/RESULT',
  ANALYSIS_FOLDER_FORM__ANALYSIS_FOLDER = 'ANALYSIS_FOLDER_FORM/ANALYSIS_FOLDER',
  ANALYSES_MAIN__ANALYSIS_FOLDER = 'ANALYSES_MAIN/ANALYSIS_FOLDER',
  ANALYSES_MAIN__FOLDERS_TREE = 'ANALYSES_MAIN/FOLDERS_TREE',
  TEAM_FORM__TEAM = 'TEAM_FORM__TEAM',
}
