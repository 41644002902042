import classes from './settings.module.scss';
import {Outlet} from 'react-router';
import {AppRoutes} from '../../constants/app-routes';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../constants/translation-keys';
import {
  Nav,
  NavLink,
  NavList,
  NavSubtitle,
} from '../shared/components/navigation/left-navigation/left-navigation.component';
import usePermissions from '../../core/hooks/use-permissions.hook';
import PageLayout from '../shared/components/layout/page-layout';
import {GearLightIcon} from 'ui-components';
import {growthbook} from '../../config/growthbook.config';
import {FeatureFlag} from '../../constants/feature-flags';
import React from 'react';
import {useProductData} from '../../core/hooks/use-product-data.hook.ts';

export const Settings = () => {
  const {t} = useTranslation();
  const {role} = usePermissions();
  const {isDemoProduct} = useProductData();
  const USER_MANAGMENT_FEATURE_FLAG = growthbook.isOn(FeatureFlag.USER_MANAGEMENT);

  return (
    <PageLayout.Layout>
      <PageLayout.Title title={t(TransKeys.GENERAL.LABELS.SETTINGS)} icon={GearLightIcon} />
      <PageLayout.Body noPadding className={classes.Container}>
        <Nav className={classes.Nav}>
          <NavSubtitle>{t(TransKeys.GENERAL.LABELS.USER)}</NavSubtitle>
          <NavList>
            <NavLink to={AppRoutes.settingsNotifications().pathname}>
              {t(TransKeys.USER_SETTINGS.MENU.NOTIFICATIONS)}
            </NavLink>
            {!USER_MANAGMENT_FEATURE_FLAG && role && (
              <NavLink to={AppRoutes.settingsPermissions().pathname}>
                {t(TransKeys.USER_SETTINGS.MENU.PERMISSIONS)}
              </NavLink>
            )}
            <NavLink to={AppRoutes.settingsOther().pathname}>
              {t(TransKeys.USER_SETTINGS.MENU.OTHER)}
            </NavLink>
          </NavList>
          {!isDemoProduct && USER_MANAGMENT_FEATURE_FLAG && (
            <>
              <NavSubtitle>{t(TransKeys.GENERAL.LABELS.ACCOUNT)}</NavSubtitle>
              <NavList>
                <NavLink to={AppRoutes.settingsMembers().pathname}>
                  {t(TransKeys.ACCOUNT_SETTINGS.MENU.MEMBERS)}
                </NavLink>
                <NavLink to={AppRoutes.settingsTeams().pathname}>
                  {t(TransKeys.ACCOUNT_SETTINGS.MENU.TEAMS)}
                </NavLink>
              </NavList>
            </>
          )}
        </Nav>
        <div className={classes.Content}>
          <Outlet />
        </div>
      </PageLayout.Body>
    </PageLayout.Layout>
  );
};
