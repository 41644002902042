import yup from '../../config/yup.config';
import {queryElementValidatorFactory} from './query-builder.dto';

export const ModelEventPropertiesValidator = yup.object().shape({
  id: yup.number().nullable(),
  definition: queryElementValidatorFactory(true),
});

export const modelEventPropertiesDataSchema = yup
  .array()
  .of(ModelEventPropertiesValidator)
  .nullable();
