import {Button, CopyIcon} from 'ui-components';
import {SourceWizardHeader} from '../source-wizard-header/source-wizard-header.component';
import {SourceWizardPageLayout} from '../source-wizard-page-layout/source-wizard-page-layout.component';
import classes from './connection-failed-screen.module.scss';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../../../constants/translation-keys';
import {contactSupport} from '../../../../../../utils/general.utils';
import {useCallback, useMemo} from 'react';
import {SourceWizardLabelWrapper} from '../source-wizard-label-wrapper/source-wizard-label-wrapper.component';
import {ImagesResource} from '../../../../../../assets/images';

type ConnectionFailedScreenProps = {
  error: {status: number; message: string};
  onBack?: VoidFunction;
};

export const ConnectionFailedScreen = (props: ConnectionFailedScreenProps) => {
  const {error, onBack} = props;
  const {t} = useTranslation();

  const message = useMemo(
    () => `Error: Code ${error.status}\n\n Details: \n ${error.message}`,
    [error]
  );

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(message);
  }, [message]);

  return (
    <SourceWizardPageLayout contentClassName={classes.ErrorContent}>
      <div className={classes.ErrorScreenContent}>
        <SourceWizardHeader
          title={t(TransKeys.SOURCE_WIZARD.CONNECTION_ERROR_SCREEN.TITLE)}
          subtitle={t(TransKeys.SOURCE_WIZARD.CONNECTION_ERROR_SCREEN.SUBTITLE)}
          illustration={<img src={ImagesResource.app.sourceWizardConnectionFailed} />}
        />
        <SourceWizardLabelWrapper label="Error Details">
          <div className={classes.ErrorMessageContainer}>
            <div className={classes.CopyButton}>
              <Button
                onClick={handleCopy}
                variant="outlined"
                icon={CopyIcon}
                className={classes.Button}
              >
                {t(TransKeys.GENERAL.LABELS.COPY)}
              </Button>
            </div>
            <div>{message}</div>
          </div>
        </SourceWizardLabelWrapper>
        <Button variant="contained" onClick={onBack} size="large" className={classes.BackButton}>
          {t(TransKeys.GENERAL.ACTIONS.BACK)}
        </Button>
        <div className={classes.ContactSupport} onClick={contactSupport}>
          {t(TransKeys.SUPPORT.CONTACT_SUPPORT)}
        </div>
      </div>
    </SourceWizardPageLayout>
  );
};
