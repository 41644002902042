import {useCallback, useContext, useMemo, useState} from 'react';
import {
  ArrowRightRegularIcon,
  PointerWithStarsIcon,
  ShapesDuotoneIcon,
  TextButton,
} from 'ui-components';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {withLoadBefore} from '../../../../../core/hoc/with-load-before.hoc';
import {getAnalysisResultNetworkRequest} from '../../../../../http/analysis-results.network-requests';
import {
  ANALYSIS_RESULT_ID_PATH_PARAM,
  SIGNAL_ID_PATH_PARAM,
} from '../../../../../constants/app-routes';
import {AnalysisResult} from '../../../../../objects/models/analysis-result.model';
import {getAnalysisTypesNetworkRequest} from '../../../../../http/analysis-types.network-requests';
import {AnalysisTypeId} from '../../../../../constants/analysis-type-id';
import {AnalysisType} from '../../../../../objects/models/analysis-type.model';
import {Signal} from '../../../../../objects/models/signal.model';
import i18n from 'i18next';
import {PanelsContext} from '../../../../../core/contexts/panels.context';
import {PanelKey} from '../../../../../constants/panels';
import {funnelAnalysisConversionFollowUpActionParametersValidator} from '../../../../../objects/dto/follow-up-actions.dto';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useDispatch, useSelector} from 'react-redux';
import {getReducedLoadingStateSelector} from '../../../../../store/store.selectors';
import {ActionKey} from '../../../../../constants/action-key';
import {createFunnelAnalysisConversionFollowUpActions} from '../../../../../store/follow-up-actions/follow-up-actions.actions';
import {useAmplitude} from '../../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../../constants/amplitude-event';
import {GenericLoading} from '../../../components/general/generic-loading/generic-loading.component';
import {preventSubmitOnEnter} from '../../../../../utils/general.utils';
import {multiLoaderNetworkRequest} from '../../../../../http/multi-loader.network-requests';
import {FunnelAnalysisConversionFollowUpParameters} from 'ui-components';
import AnalysisTypesFollowUpPanelSelectSegment from '../analysis-types-follow-up-panel/components/analysis-types-follow-up-panel-select-segment.component';
import AnalysisTypesFollowUpPanel from '../analysis-types-follow-up-panel/analysis-types-follow-up-panel.component';
import AnalysisTypesFollowUpPanelHeader from '../analysis-types-follow-up-panel/components/analysis-types-follow-up-panel-header.component';
import AnalysisTypesFollowUpPanelBody from '../analysis-types-follow-up-panel/components/analysis-types-follow-up-panel-body.component';
import sharedClasses from '../analysis-types-follow-up-panel/analysis-types-follow-up-panel.module.scss';
import {composition} from 'front-core';
import {withModalInactiveSourceHandler} from '../../../../../core/hoc/with-modal-inactive-source-handler.hoc';
import {withDisableDemoProduct} from '../../../../../core/hoc/with-disable-demo-product.hoc';

interface OwnProps {
  parameters: FunnelAnalysisConversionFollowUpParameters;
  analysisResult: AnalysisResult;
  analysisTypes: {data: AnalysisType[]};
  multiEntities: any;
  onClose?: () => void;
  disabled?: boolean;
}

type AllProps = OwnProps;

const FOLLOW_UP_ANALYSES_TYPES = [AnalysisTypeId.USER_JOURNEY, AnalysisTypeId.GOAL_DRIVERS];

const BOXES_CONFIG = () => ({
  [AnalysisTypeId.USER_JOURNEY]: {
    description: i18n.t(
      TransKeys.FUNNEL_ANALYSIS_CONVERSION.FOLLOW_UP_ANALYSES.ANALYSIS_107.DESCRIPTION
    ),
    icon: <PointerWithStarsIcon className={sharedClasses.PointerWithStarsIcon} />,
  },
  [AnalysisTypeId.GOAL_DRIVERS]: {
    description: i18n.t(
      TransKeys.FUNNEL_ANALYSIS_CONVERSION.FOLLOW_UP_ANALYSES.ANALYSIS_80.DESCRIPTION
    ),
    icon: <ShapesDuotoneIcon className={sharedClasses.ShapesDuotoneIcon} />,
  },
});
const createSelectedKey = (key: string) => `FUNNEL_ANALYSIS_CONVERSION_FOLLOW_UP_FORM/${key}`;

const _GOAL_KEY = 'goal';
const _REF_DATE_KEY = 'refDate';
const _SEGMENT_SIGNAL_ID_KEY = 'segment';

const FunnelAnalysisConversionFollowUpFormPanelComponent = (props: AllProps) => {
  const {
    parameters,
    analysisResult,
    analysisTypes,
    multiEntities,
    onClose,
    disabled: disabledFromProps,
  } = props;
  const [followupAnalysesRunning, setFollowupAnalysesRunning] = useState(false);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const notify = useAmplitude();
  const {openSecondaryPanel} = useContext(PanelsContext);
  const formMethods = useForm({
    defaultValues: {
      ...parameters,
      analysisResultId: analysisResult.id,
      analysisFolderId: analysisResult.analysisFolderId,
      analysisTypeIds: [],
    } as any,
    resolver: yupResolver(funnelAnalysisConversionFollowUpActionParametersValidator.noUnknown()),
  });
  const {handleSubmit, watch} = formMethods;
  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(ActionKey.CREATE_FUNNEL_ANALYSIS_CONVERSION_FOLLOW_UP_ACTIONS)(
      state
    )
  );
  const followUpAnalysesTypeIds = watch('analysisTypeIds');
  const disabled = useMemo(
    () => isLoading || followUpAnalysesTypeIds.length === 0 || disabledFromProps,
    [isLoading, followUpAnalysesTypeIds, disabledFromProps]
  );

  const {firstStep, secondStep, segment} = useMemo(() => {
    return {
      firstStep: multiEntities.signals[parameters[_REF_DATE_KEY]],
      secondStep: multiEntities.signals[parameters[_GOAL_KEY]],
      segment: multiEntities.signals[parameters[_SEGMENT_SIGNAL_ID_KEY]],
    };
  }, [multiEntities, parameters]);
  const onStepClick = useCallback(
    (step: Signal) =>
      !isLoading &&
      openSecondaryPanel(PanelKey.SIGNAL_DEFINITION_PANEL, {
        [SIGNAL_ID_PATH_PARAM]: step.id,
      }),
    [openSecondaryPanel, isLoading]
  );

  const dynamicTitle = useMemo(() => {
    return (
      <>
        <TextButton className={sharedClasses.TextBtn} onClick={() => onStepClick(firstStep)}>
          {firstStep.name}
        </TextButton>
        <ArrowRightRegularIcon />
        <TextButton className={sharedClasses.TextBtn} onClick={() => onStepClick(secondStep)}>
          {secondStep.name}
        </TextButton>
        {segment ? (
          <AnalysisTypesFollowUpPanelSelectSegment
            segment={segment}
            isLoading={isLoading}
            segmentClass={parameters.segmentClass}
          />
        ) : null}
      </>
    );
  }, [onStepClick, firstStep, secondStep, segment, isLoading, parameters.segmentClass]);

  const onSubmit = useCallback(
    data => {
      dispatch(
        createFunnelAnalysisConversionFollowUpActions(data, () => {
          setFollowupAnalysesRunning(true);
        })
      );
      const amplitudePayload = followUpAnalysesTypeIds.reduce(
        (acc, curr, idx) => ({...acc, [String(idx)]: curr}),
        {}
      );
      notify(AmplitudeEvent.FUNNEL_ANALYSIS_CONVERSION_FOLLOW_UP_ACTIONS_CLICKED, amplitudePayload);
    },
    [dispatch, notify, followUpAnalysesTypeIds, setFollowupAnalysesRunning]
  );
  const analysisTypeBoxConfig = useMemo(() => BOXES_CONFIG(), []);

  return (
    <FormProvider {...formMethods}>
      <AnalysisTypesFollowUpPanel
        onClose={onClose}
        showSuccessMessage={followupAnalysesRunning}
        disabled={disabled}
        onSubmit={e => {
          e.stopPropagation();
          handleSubmit(onSubmit)(e);
        }}
      >
        {isLoading && <GenericLoading />}
        <form
          onKeyDown={preventSubmitOnEnter}
          onSubmit={e => {
            e.stopPropagation();
            handleSubmit(onSubmit)(e);
          }}
        >
          <AnalysisTypesFollowUpPanelHeader
            onClose={onClose}
            staticTitle={t(TransKeys.FUNNEL_ANALYSIS_CONVERSION.HEADER)}
            dynamicTitle={dynamicTitle}
          />
          <AnalysisTypesFollowUpPanelBody
            analysisTypeBoxConfig={analysisTypeBoxConfig}
            analysisTypes={analysisTypes}
            defaultFollowUpAnalysesTypeIds={FOLLOW_UP_ANALYSES_TYPES}
          />
        </form>
      </AnalysisTypesFollowUpPanel>
    </FormProvider>
  );
};

export const FunnelAnalysisConversionFollowUpFormPanel = composition<AllProps>(
  FunnelAnalysisConversionFollowUpFormPanelComponent,
  withModalInactiveSourceHandler,
  withDisableDemoProduct,
  withLoadBefore<AllProps>({
    analysisResult: {
      selectedKey: createSelectedKey('ANALYSIS_RESULT'),
      actionKey: createSelectedKey('ANALYSIS_RESULT'),
      request: getAnalysisResultNetworkRequest,
      mapPayloadFromProps: props => props[ANALYSIS_RESULT_ID_PATH_PARAM],
      shouldCall: props => props[ANALYSIS_RESULT_ID_PATH_PARAM] !== undefined,
    },
    analysisTypes: {
      selectedKey: createSelectedKey('ANALYSIS_TYPES'),
      actionKey: createSelectedKey('ANALYSIS_TYPES'),
      request: getAnalysisTypesNetworkRequest,
      mapPayloadFromProps: props => ({
        id: FOLLOW_UP_ANALYSES_TYPES,
      }),
    },
    multiEntities: {
      selectedKey: createSelectedKey('MULTI'),
      actionKey: createSelectedKey('MULTI'),
      request: multiLoaderNetworkRequest,
      mapPayloadFromProps: props => {
        const {
          [_GOAL_KEY]: goalSignalId,
          [_REF_DATE_KEY]: refDateSignalId,
          [_SEGMENT_SIGNAL_ID_KEY]: segmentSignalId,
        } = props.parameters;
        const requiredSignals = [goalSignalId, refDateSignalId];
        return {
          signals: segmentSignalId ? [...requiredSignals, segmentSignalId] : requiredSignals,
        };
      },
    },
  })
);
