import {Link, QueryBuilder, QueryBuilderConfig} from 'ui-components';
import classes from '../milestone-form-panel.module.scss';
import {Controller, useFormContext} from 'react-hook-form';
import React, {useCallback, useMemo} from 'react';
import TransKeys from '../../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {EntityPicker} from '../../../components/entity-picker/entity-picker.component';
import {MilestoneType} from '../../../../../objects/models/milestone.model';
import {getComponentForQuery} from '../../../../shared/core/query-builders/query-builders.utils';
import {
  queryBuilderModelConfig,
  ComputedTemplatesNames,
} from '../../../../../constants/query-builder';
import {get, values} from 'lodash';
import {TableEntity} from '../../../../../objects/models/table.model';
import {TabHeader} from '../../../../shared/components/general/tab-header/tab-header.component';

const checkIsComputedQuery = (query: any) => {
  const templateName = get(query, 'template');
  return values(ComputedTemplatesNames).includes(templateName);
};

const queryBuilderConfig: QueryBuilderConfig = {
  modelConfig: queryBuilderModelConfig,
};

export interface TabDefinition {
  key: MilestoneType;
  Builder: any;
  signalFilters?: any;
  allowManyDefinitions?: boolean;
  fallbackBuilder?: any;
}

interface OwnProps extends TabDefinition {
  milestoneType: MilestoneType;
  title: string;
  description: string;
  existingMilestoneIds: number[];
  entity: TableEntity;
  editMode: boolean;
  onViewMilestone: (entity: TableEntity, milestoneType: MilestoneType) => void;
  onEditMilestone: (entity: TableEntity, milestoneType: MilestoneType) => void;
  clearSignalDefinition: () => void;
}

type AllProps = OwnProps;

enum DisplayMode {
  EMPTY_STATE = 'emptyState',
  CUSTOM_QUERY = 'customQuery',
  CREATE = 'create',
  EDIT = 'edit',
  EXISTS_ONCE = 'existsOnce',
  EXISTS_MANY = 'existsMany',
}

export const MilestoneTabContent = (props: AllProps) => {
  const {
    milestoneType,
    title,
    description,
    existingMilestoneIds,
    signalFilters,
    entity,
    editMode,
    allowManyDefinitions,
    onViewMilestone,
    onEditMilestone,
    clearSignalDefinition,
    Builder,
  } = props;

  const {
    control,
    watch,
    formState: {errors},
    setValue,
  } = useFormContext();

  const {t} = useTranslation();
  const signalDefinition = watch('signalDefinition');
  const milestoneTypeDefined = existingMilestoneIds.length > 0;

  const isCustomQuery = useMemo(
    () => Boolean(signalDefinition) && getComponentForQuery(signalDefinition) === undefined,
    [signalDefinition]
  );

  let displayMode = '';
  if (!milestoneType) {
    displayMode = DisplayMode.EMPTY_STATE;
  } else if (isCustomQuery) {
    displayMode = DisplayMode.CUSTOM_QUERY;
  } else if (editMode) {
    displayMode = DisplayMode.EDIT;
  } else if (!milestoneTypeDefined || allowManyDefinitions) {
    displayMode = DisplayMode.CREATE;
  } else if (milestoneTypeDefined && existingMilestoneIds.length === 1) {
    displayMode = DisplayMode.EXISTS_ONCE;
  } else if (milestoneTypeDefined && existingMilestoneIds.length > 1) {
    displayMode = DisplayMode.EXISTS_MANY;
  }

  const onSignalDefinitionChange = useCallback(
    query => {
      setValue('signalDefinition', query || null);
      const isComputedQuery = checkIsComputedQuery(query);
      setValue('isComputed', isComputedQuery);
    },
    [setValue]
  );

  return (
    <div className={classes.MilestoneTab} key={milestoneType}>
      {milestoneType && <TabHeader size={'small'} title={title} subTitle={description} />}
      <p className={classes.MilestoneTabContent}>
        {displayMode === DisplayMode.EMPTY_STATE && (
          <div className={classes.EmptyState}>
            {t(TransKeys.MILESTONE_FORM.SELECT_MILESTONE_TYPE)}
          </div>
        )}
        {displayMode === DisplayMode.CUSTOM_QUERY && (
          <p>
            <span className={classes.OverrideWarning}>
              {t(TransKeys.MILESTONE_FORM.CREATED_USING_CUSTOM_QUERY)}
              <br />
              {t(TransKeys.MILESTONE_FORM.EDIT_OVERRIDE_CUSTOM_DEFINITION)}
              &nbsp;
              <Link className={classes.Link} onClick={clearSignalDefinition}>
                {t(TransKeys.GENERAL.LABELS.EDITING)}
              </Link>
              &nbsp;it.
            </span>
            <p>
              <Controller
                render={({field}) => (
                  <QueryBuilder
                    config={queryBuilderConfig}
                    query={field.value}
                    onChange={onSignalDefinitionChange}
                    errors={errors['signalDefinition']}
                    disabled
                  />
                )}
                name={'signalDefinition'}
                control={control}
              />
            </p>
          </p>
        )}
        {(displayMode === DisplayMode.CREATE || displayMode === DisplayMode.EDIT) && (
          <Controller
            render={({field}) => {
              return (
                <Builder
                  query={field.value}
                  entityContext={entity}
                  onChange={onSignalDefinitionChange}
                  errors={errors['signalDefinition']}
                  filters={signalFilters}
                />
              );
            }}
            name={'signalDefinition'}
            control={control}
          />
        )}
        {displayMode === DisplayMode.EXISTS_ONCE && (
          <>
            {t(TransKeys.MILESTONE_FORM.TYPE_ALREADY_DEFINED)}
            &nbsp;for&nbsp;
            <EntityPicker className={classes.Entity} value={entity} />.
            <br />
            <Link className={classes.Link} onClick={() => onViewMilestone(entity, milestoneType)}>
              {t(TransKeys.GENERAL.ACTIONS.VIEW)}
            </Link>
            &nbsp;or&nbsp;
            <Link className={classes.Link} onClick={() => onEditMilestone(entity, milestoneType)}>
              {t(TransKeys.GENERAL.ACTIONS.EDIT)}
            </Link>
            &nbsp;
            {t(TransKeys.MILESTONE_FORM.THE_EXISTING_DEFINITION)}.
          </>
        )}
        {displayMode === DisplayMode.EXISTS_MANY && (
          <>
            {t(TransKeys.MILESTONE_FORM.TYPE_ALREADY_DEFINED_MANY)}&nbsp;
            <EntityPicker className={classes.Entity} value={entity} />.
            <br />
            {t(TransKeys.MILESTONE_FORM.VIEW_OR_EDIT_FROM_LIST)}.
          </>
        )}
      </p>
    </div>
  );
};
