import {useMemo} from 'react';
import {LabelWrapper, SwitchActions} from 'ui-components';
import classes from '../table-form-panel.module.scss';
import {TableEntity} from '../../../../../objects/models/table.model';
import {useProductData} from '../../../../../core/hooks/use-product-data.hook';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../../constants/translation-keys';
import {getEntityIcon} from '../../../../../constants/entity.consts';

interface OwnProps {
  onChange: (tableEntity: TableEntity) => void;
  error: boolean;
  value: TableEntity;
  disabled?: boolean;
}

type AllProps = OwnProps;

const TableEntityTypeField = (props: AllProps) => {
  const {value, onChange, error, disabled} = props;
  const {t} = useTranslation();
  const {productEntities} = useProductData();

  const entityOptions = useMemo(
    () =>
      productEntities.map(p => ({
        key: p.key,
        label: p.name,
        onClick: () => onChange(p.key as TableEntity),
        isActive: value === p.key,
        icon: getEntityIcon(p.key),
      })),
    [productEntities, onChange, value]
  );

  if (!(entityOptions.length > 1)) {
    return null;
  }

  return (
    <LabelWrapper
      error={error}
      label={t(TransKeys.TABLE_FORM.INPUTS.ENTITY_TYPE.LABEL)}
      className={classes.LabelWrapper}
    >
      <SwitchActions actions={entityOptions} showActionsLabel disabled={disabled} />
    </LabelWrapper>
  );
};

export default TableEntityTypeField;
