import classes from './model-page-header-short-description.module.scss';
import {TooltipIfOverflow} from 'ui-components';
import {exists} from 'front-core';
import classNames from 'classnames';

type ModelPageHeaderShortDescriptionProps = {
  description?: string;
};

export const ModelPageHeaderShortDescription = (props: ModelPageHeaderShortDescriptionProps) => {
  const {description} = props;

  if (!exists(description)) {
    return null;
  }

  return (
    <div className={classes.ModelPageHeaderShortDescription}>
      <TooltipIfOverflow title={description}>
        <span className={classNames(classes.Text, !exists(description) && classes.Placeholder)}>
          {description}
        </span>
      </TooltipIfOverflow>
    </div>
  );
};
