import {action, upsert} from 'front-core';
import {INTERFACE_STORE_KEY} from './interface.store';
import {ConfirmationMessage} from '../../objects/system/confirmation-message.model';
import {ToastType} from '../../objects/system/toast-type.enum';
import {AnyAction} from 'redux';

export enum InterfaceActionsTypes {
  SHOW_CONFIRMATION_MESSAGE = '@@interface/SHOW_CONFIRMATION_MESSAGE',
  CONFIRMATION_MESSAGE_RESOLVED = '@@interface/CONFIRMATION_MESSAGE_RESOLVED',
  SHOW_TOAST_MESSAGE = '@@interface/SHOW_TOAST_MESSAGE',
}

export const showConfirmationMessage = (
  message: ConfirmationMessage,
  onApprove: () => AnyAction[]
) =>
  action(InterfaceActionsTypes.SHOW_CONFIRMATION_MESSAGE, {
    message,
    onApprove,
  });
export const setConfirmationMessage = (message: ConfirmationMessage) =>
  upsert(INTERFACE_STORE_KEY, 'confirmationMessage', message);
export const resolveConfirmationMessage = (res: boolean) =>
  action(InterfaceActionsTypes.CONFIRMATION_MESSAGE_RESOLVED, res);
export const showToastMessage = (message: string, type: ToastType) =>
  action(InterfaceActionsTypes.SHOW_TOAST_MESSAGE, {message, type});
export const setIsGrowthMapOpen = (open: boolean) =>
  upsert(INTERFACE_STORE_KEY, 'isGrowthMapOpen', open);
export const setIsGrowthMapHidden = (hidden: boolean) =>
  upsert(INTERFACE_STORE_KEY, 'isGrowthMapHidden', hidden);
