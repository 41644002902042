import classNames from 'classnames';
import {NavLinkProps, NavLink} from 'react-router';
import {useMemo} from 'react';

interface Props extends NavLinkProps {
  activeClassName?: string;
}

export const BetterNavLink = (props: Props) => {
  const {activeClassName} = props;
  const passProps = useMemo(() => {
    const x = {
      ...props,
    };
    delete x['activeClassName'];
    return x;
  }, [props]);

  return (
    <NavLink
      {...passProps}
      className={({isActive}) => classNames(props.className, isActive && activeClassName)}
    />
  );
};
