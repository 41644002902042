import {useCallback, useContext, useEffect, useMemo} from 'react';
import classNames from 'classnames';
import classes from '../../analysis-forms.module.scss';
import {TimeFrameSelector} from '../../components/ui-selectors/time-frame-selector/time-frame-selector.component';
import {ParametersFormContext} from '../../../../shared/core/parameters-form/parameters-form.context';
import {startEndDatesDefaultHandler} from '../../../../shared/core/parameters-form/parameters-form-default-handlers.utils';
import {SegmentFilterSelector} from '../../components/ui-selectors/segment-filter-selector/segment-filter-selector.component';
import {AnalysisFormProps} from '../../analysis-forms.types';
import {createUndefinedObject, hasError} from '../../../../../utils/general.utils';
import {values} from 'lodash';
import {BaseSignalBuilder} from '../../../../shared/core/query-builders/signal-builder/base-signal-builder.component';
import {ParameterInputWrapper} from '../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {SignalDataType, SignalType} from '../../../../../objects/models/signal.model';
import {TableEntity, TableEntityBinding} from '../../../../../objects/models/table.model';
import {EntitySelector} from '../../components/ui-selectors/entity-selector/entity-selector.component';
import {useProductData} from '../../../../../core/hooks/use-product-data.hook';
import {SignalSmartSelectorKey} from '../../../../shared/core/smart-selector/signal-smart-selector.component';
import {TreatmentSelector} from '../../components/ui-selectors/treatment-selector/treatment-selector.component';

const REF_DATE_SIGNAL_MAPPING = {
  signal_id: 'reference_date',
};
const entitySchemaMapping = {
  entity: 'entity',
};
const segmentFilterSchemaMapping = {
  population_filter: 'population_filter',
};
const GROUP_BY_SIGNAL_MAPPING = {
  signal_id: 'group_by_signal',
};
const treatmentsSelectorMap = {
  treatments_tag: 'feature_tag',
  treatments_signals: 'treatments_signals',
};
const RED_DATE_INCLUDE = [
  SignalSmartSelectorKey.METRICS,
  SignalSmartSelectorKey.FUNNELS,
  SignalSmartSelectorKey.FEATURES,
  SignalSmartSelectorKey.CONTENTS,
  SignalSmartSelectorKey.MILESTONES,
  SignalSmartSelectorKey.SIGNALS,
];

const createRefDateSignalFilters = (entityContext = undefined) => [
  {
    type: SignalType.DIMENSION,
    data_type: SignalDataType.TIMESTAMP,
    entity_binding: TableEntityBinding.DEFAULT,
    exclude_templates: '*',
    entityContext,
  },
  {
    type: SignalType.DIMENSION,
    data_type: SignalDataType.TIMESTAMP,
    entity_binding: TableEntityBinding.DEFAULT,
    templates: ['dom_template'],
    entityContext,
  },
];

const createGroupBySignalFilters = (entityContext = undefined) => ({
  type: SignalType.DIMENSION,
  data_type: [
    SignalDataType.STRING,
    SignalDataType.BOOLEAN,
    SignalDataType.INTEGER,
    SignalDataType.TIMESTAMP,
  ],
  entity_binding: TableEntityBinding.ONE_WAY,
  entityContext,
});

const GROUP_BY_INCLUDE_SELECTOR = [
  SignalSmartSelectorKey.METRICS,
  SignalSmartSelectorKey.SEGMENTS,
  SignalSmartSelectorKey.FUNNELS,
  SignalSmartSelectorKey.FEATURES,
  SignalSmartSelectorKey.CONTENTS,
  SignalSmartSelectorKey.SIGNALS,
];

export const Analysis96Form = (props: AnalysisFormProps) => {
  const {className, onSignalInfo} = props;
  const {errors, parameters, changeParametersValue, registerDefaultHandler, removeDefaultHandler} =
    useContext(ParametersFormContext);
  const {productEntities, defaultTableEntity, getSignalByTag} = useProductData();
  const entityContext = parameters[entitySchemaMapping.entity];
  const groupBySignalFilters = useMemo(
    () => createGroupBySignalFilters(entityContext),
    [entityContext]
  );
  const refDateSignalFilters = useMemo(
    () => createRefDateSignalFilters(entityContext),
    [entityContext]
  );

  useEffect(() => {
    registerDefaultHandler('start_end_dates', startEndDatesDefaultHandler);
    registerDefaultHandler('analysis_96', parameters => {
      const defaults = {};
      const joinDateSignal = getSignalByTag('join_date', entityContext);

      defaults[REF_DATE_SIGNAL_MAPPING.signal_id] = joinDateSignal?.id;
      defaults[entitySchemaMapping.entity] = defaultTableEntity;
      return defaults;
    });
    return () => {
      removeDefaultHandler('start_end_dates');
      removeDefaultHandler('analysis_96');
    };
  }, [
    registerDefaultHandler,
    removeDefaultHandler,
    defaultTableEntity,
    getSignalByTag,
    entityContext,
  ]);

  const onChangeEntityContext = useCallback(
    (entity: TableEntity) => {
      const resetKeys = Array.from(
        new Set([
          ...values(GROUP_BY_SIGNAL_MAPPING),
          ...values(segmentFilterSchemaMapping),
          ...values(treatmentsSelectorMap),
        ])
      );
      const resetParameters = createUndefinedObject(resetKeys);
      changeParametersValue({
        [entitySchemaMapping.entity]: entity,
        ...resetParameters,
      });
    },
    [changeParametersValue]
  );

  return (
    <div className={classNames(classes.AnalysisForm, className)}>
      <EntitySelector
        value={parameters}
        productEntities={productEntities}
        schemaKeysMapping={entitySchemaMapping}
        onChange={v => onChangeEntityContext(v[entitySchemaMapping.entity])}
        className={classes.Parameter}
      />
      <ParameterInputWrapper
        title={'Reference point'}
        subTitle={'Select the reference point from which first sessions will be analyzed.'}
        className={classes.Parameter}
        error={hasError(errors, values(REF_DATE_SIGNAL_MAPPING))}
      >
        <BaseSignalBuilder
          value={parameters}
          onChange={changeParametersValue}
          onSignalInfo={onSignalInfo}
          schemaKeysMapping={REF_DATE_SIGNAL_MAPPING}
          filters={refDateSignalFilters}
          include={RED_DATE_INCLUDE}
          errors={errors}
        />
      </ParameterInputWrapper>
      <TreatmentSelector
        value={parameters}
        onChange={changeParametersValue}
        className={classes.Parameter}
        schemaKeysMapping={treatmentsSelectorMap}
        errors={errors}
        entityContext={entityContext}
      />
      <ParameterInputWrapper
        title={'Group by (optional)'}
        subTitle={'Group first sessions by parameter. e.g. country/platform.'}
        className={classes.Parameter}
        error={hasError(errors, values(GROUP_BY_SIGNAL_MAPPING))}
        helperText={
          'E.g split the buckets based on country to see how users from different countries behave.'
        }
      >
        <BaseSignalBuilder
          value={parameters}
          onChange={changeParametersValue}
          onSignalInfo={onSignalInfo}
          schemaKeysMapping={GROUP_BY_SIGNAL_MAPPING}
          filters={groupBySignalFilters}
          include={GROUP_BY_INCLUDE_SELECTOR}
          errors={errors}
          clearable
        />
      </ParameterInputWrapper>
      <TimeFrameSelector className={classes.Parameter} errors={errors} />
      {entityContext && (
        <SegmentFilterSelector
          onChange={changeParametersValue}
          value={parameters}
          className={classes.Parameter}
          schemaKeysMapping={segmentFilterSchemaMapping}
          entityContext={entityContext}
          errors={errors}
        />
      )}
    </div>
  );
};
