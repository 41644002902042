import {useMemo} from 'react';
import {TableEntity} from '../../../../../../objects/models/table.model';
import {values} from 'lodash';
import {SwitchActions} from 'ui-components';
import {ParameterInputWrapper} from '../../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {OrderedProductEntities} from '../../../../../../objects/models/product-data.model';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../../../constants/translation-keys';
import {AnalysisSelectorProps, AnalysisSelectorVariant} from '../analysis-selector.types';
import classNames from 'classnames';
import {getEntityIcon} from '../../../../../../constants/entity.consts';

interface SchemaKeysMapping {
  entity: string;
}

const DEFAULT_SCHEMA_KEYS_MAPPING: SchemaKeysMapping = {
  entity: 'entity',
};

interface OwnProps extends AnalysisSelectorProps {
  productEntities: OrderedProductEntities;
  schemaKeysMapping?: SchemaKeysMapping;
  accept?: TableEntity[];
}

type AllProps = OwnProps;

export const EntitySelector = (props: AllProps) => {
  const {
    title,
    subTitle,
    helperText,
    productEntities,
    onChange,
    value,
    schemaKeysMapping = DEFAULT_SCHEMA_KEYS_MAPPING,
    variant,
    accept,
    className,
  } = props;
  const {t} = useTranslation();
  const entity = useMemo(() => value[schemaKeysMapping.entity], [value, schemaKeysMapping]);
  const options = useMemo(
    () =>
      productEntities
        .filter(p => (accept ? accept.indexOf(p.key as any) > -1 : true))
        .map(p => ({
          key: p.key,
          label: p.name,
          onClick: () => onChange({[schemaKeysMapping.entity]: p.key}),
          isActive: entity === p.key,
          icon: getEntityIcon(p.key),
        })),
    [productEntities, onChange, schemaKeysMapping, entity, accept]
  );

  const renderContent = () => (
    <SwitchActions
      showActionsLabel
      actions={options}
      className={classNames(variant === AnalysisSelectorVariant.INLINE && className)}
    />
  );

  if (values(options).length <= 1) {
    return null;
  }

  if (variant === AnalysisSelectorVariant.INLINE) {
    return renderContent();
  }

  return (
    <ParameterInputWrapper
      title={title || t(TransKeys.ANALYSIS_FORMS.SHARED.ENTITY_SELECTOR.TITLE)}
      subTitle={subTitle || t(TransKeys.ANALYSIS_FORMS.SHARED.ENTITY_SELECTOR.SUB_TITLE)}
      helperText={helperText || t(TransKeys.ANALYSIS_FORMS.SHARED.ENTITY_SELECTOR.HELPER_TEXT)}
      className={className}
    >
      {renderContent()}
    </ParameterInputWrapper>
  );
};
