import classNames from 'classnames';
import classes from './kpi-position-placeholder.module.scss';
import {Metric} from '../../../../../../objects/models/metric.model';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useReactFlow} from '@xyflow/react';
import {KPITreeContext} from '../../kpi-tree.context';
import {NodePosition} from '../../../../../../objects/models/metric-tree.model';
import {createPortal} from 'react-dom';
import {
  KPI_NODE_HEIGHT,
  KPI_NODE_WIDTH,
  NODE_PLACEHOLDER_PORTAL_CLASS_NAME,
} from '../../kpi-tree.consts';

interface OwnProps {
  metric: Metric;
  onClick: (position: NodePosition) => void;
  className?: string;
}

type AllProps = OwnProps;

export const KPIPositionPlaceholder = (props: AllProps) => {
  const {metric, onClick: onClickFromProps, className} = props;
  const {containerRef} = useContext(KPITreeContext);
  const anchorRef = useRef<HTMLDivElement>(
    containerRef.current.getElementsByClassName(
      NODE_PLACEHOLDER_PORTAL_CLASS_NAME
    )[0] as HTMLDivElement
  );
  const [elemRef, setElementRef] = useState<HTMLDivElement>(null);
  const {screenToFlowPosition, getViewport} = useReactFlow();
  const [mousePosition, setMousePosition] = useState<{
    clientX: number;
    clientY: number;
  }>({
    clientX: 0,
    clientY: 0,
  });
  const {zoom} = getViewport();
  const onClick = useCallback(
    e => {
      const position = screenToFlowPosition({
        x: e.clientX - KPI_NODE_WIDTH / 2, // minus half of the width of the placeholder
        y: e.clientY - KPI_NODE_HEIGHT / 2, // minus half of the height of the placeholder
      });
      onClickFromProps(position);
    },
    [onClickFromProps, screenToFlowPosition]
  );

  const containerRefCurrent = containerRef.current;
  useEffect(() => {
    if (containerRefCurrent === null || elemRef === null) {
      return;
    }
    const moveListener = e => {
      const containerBBox = containerRefCurrent.getBoundingClientRect();
      setMousePosition({
        clientX: e.clientX - containerBBox.left,
        clientY: e.clientY - containerBBox.top,
      });
    };
    const clickListener = e => {
      onClick(e);
    };
    containerRefCurrent.addEventListener('mousemove', moveListener);
    elemRef.addEventListener('click', clickListener);

    return () => {
      containerRefCurrent?.removeEventListener('mousemove', moveListener);
      elemRef.removeEventListener('click', clickListener);
    };
  }, [onClick, setMousePosition, containerRefCurrent, elemRef]);

  return createPortal(
    <div
      ref={ref => setElementRef(ref)}
      style={{
        top: mousePosition.clientY,
        left: mousePosition.clientX,
        transform: `scale(${zoom}) translate(-50%, -50%)`,
      }}
      className={classNames(classes.KPIPositionPlaceholder, className)}
    >
      {metric.name}
    </div>,
    anchorRef.current
  );
};
