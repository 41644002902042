import {LiteralValueType} from 'ui-components';
import {TableEventsQueryBuilder} from '../table-events-query-builder/table-events-query-builder.component';
import {
  PARAMETERS_METADATA_KEY,
  METADATA_KEY,
} from '../../../../../constants/parameters-saved-keys';
import {useCallback, useMemo} from 'react';
import {cloneDeep, get} from 'lodash';
import {TemplateItemQueryBuilder} from '../template-item-query-builder/template-item-query-builder.component';
import {SelectorModelType} from '../../smart-selector/advanced-smart-selector.component';
import {
  TableEntity,
  TableEntityBinding,
  TableType,
} from '../../../../../objects/models/table.model';

interface OwnProps {
  query: any;
  entityContext?: TableEntity;
  onChange: (value: any) => void;
  onSignalInfo?: (value: string | number) => void;
  errors?: any;
  disabled?: boolean;
}

type AllProps = OwnProps;

export const BUILDER_COMPONENT_NAME = 'RevenueChurnQueryBuilder';

const ALLOWED_TYPES = [SelectorModelType.EVENT, SelectorModelType.COLUMN];

const createEventFilters = entityContext => ({
  entityBinding: TableEntityBinding.TWO_WAY,
  entityContext,
});

const createColumnFilters = entityContext => ({
  tableType: TableType.ENTITY_PROPERTIES,
  literalType: LiteralValueType.DATE,
  entityBinding: TableEntityBinding.DEFAULT,
  entityContext,
});

export const createRevenueChurnInitialQuery = () => {
  const q = {
    [PARAMETERS_METADATA_KEY]: {
      [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: BUILDER_COMPONENT_NAME,
    },
  };
  return q;
};

export const RevenueChurnQueryBuilder = (props: AllProps) => {
  const {query, entityContext, errors, onChange: onChangeFromProps, onSignalInfo, disabled} = props;

  const onChange = useCallback(
    query => onChangeFromProps && onChangeFromProps(query),
    [onChangeFromProps]
  );

  const mappedOnChange = useCallback(
    changes => {
      const newValue = cloneDeep(changes);
      newValue[PARAMETERS_METADATA_KEY][METADATA_KEY.BUILDER_COMPONENT_NAME_KEY] =
        BUILDER_COMPONENT_NAME;
      onChange(newValue);
    },
    [onChange]
  );

  const columnFilters = useMemo(() => createColumnFilters(entityContext), [entityContext]);
  const eventFilters = useMemo(() => createEventFilters(entityContext), [entityContext]);

  const builderName = useMemo(
    () => get(query, `${PARAMETERS_METADATA_KEY}.${METADATA_KEY.BUILDER_COMPONENT_NAME_KEY}`),
    [query]
  );

  // legacy support for queries build with previous version of query builder
  if (builderName === 'TableEventsQueryBuilder') {
    return (
      <TableEventsQueryBuilder
        query={query}
        onChange={onChange}
        errors={errors}
        filters={eventFilters}
      />
    );
  }

  return (
    <TemplateItemQueryBuilder
      query={query}
      errors={errors}
      allowTypes={ALLOWED_TYPES}
      onChange={mappedOnChange}
      columnFilters={columnFilters}
      eventFilters={eventFilters}
      disabled={disabled}
      onSignalInfo={onSignalInfo}
    />
  );
};
