import {PropsWithChildren, ReactNode} from 'react';
import classes from './source-wizard-form-layout.module.scss';

type WizardFormLayoutProps = PropsWithChildren<{
  footer?: ReactNode;
}>;

export const SourceWizardFormLayout = (props: WizardFormLayoutProps) => {
  const {footer, children} = props;
  return (
    <div className={classes.FormLayout}>
      <div className={classes.ContentWrapper}>{children}</div>
      {footer && <div className={classes.Footer}>{footer}</div>}
    </div>
  );
};
