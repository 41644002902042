import {useFormContext} from 'react-hook-form';
import {useProductData} from '../../../../../../core/hooks/use-product-data.hook';
import {SlackChannel} from '../../../../../analyses/panels/share-resource-form-panel/components/share-direct-slack-form-fields/share-direct-slack-form-fields.component';
import {useMemo} from 'react';
import classes from '../../homepage-subscription-panel.module.scss';
import {SlackBtn} from '../../../../../analyses/panels/share-resource-form-panel/components/slack-btn/add-slack-btn.component';
import {MultiSelectInput} from '../../../../../shared/form/components/shared-form-input.component';
import TransKeys from 'translations';
import {HomepageSubscriptionVIA} from '../../../../../../objects/models/homepage.model';
import {useTranslation} from 'react-i18next';

interface OwnProps {
  channels: SlackChannel[];
  className?: string;
}

type AllProps = OwnProps;

const parseSlackChannelOption = (m: SlackChannel) => ({
  label: m.type === 'channel' ? `# ${m.name}` : m.name,
  value: m.id,
});

export const SubscribedSlackChannelsInput = (props: AllProps) => {
  const {channels: channelsFromProps, className} = props;
  const {t} = useTranslation();
  const {installedSlackToWorkspace} = useProductData();
  const channelsOptionsProp = useMemo(() => {
    if (!channelsFromProps || channelsFromProps.length === 0) {
      return null;
    }
    return {
      options: channelsFromProps.map(parseSlackChannelOption),
    };
  }, [channelsFromProps]);
  const {watch, setValue} = useFormContext();
  const slackChannels = watch('slackChannels');
  const via = watch('via');
  const isActive = watch('isActive');

  if (!installedSlackToWorkspace || !channelsOptionsProp) {
    return (
      <div className={className}>
        <div className={classes.ConnectSlack}>
          <SlackBtn className={classes.AddToSlackBtn} size={'small'} />
        </div>
      </div>
    );
  }

  return (
    <div className={className}>
      <MultiSelectInput
        options={channelsOptionsProp}
        label={''}
        name={'slackChannels'}
        placeholder={
          slackChannels.length === 0
            ? t(TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.NOTIFY.SLACK_PLACEHOLDER)
            : ''
        }
        disabled={!isActive || !via.includes(HomepageSubscriptionVIA.SLACK)}
        value={slackChannels}
        onChange={v => setValue('slackChannels', v)}
      />
    </div>
  );
};
