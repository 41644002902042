import {composition} from 'front-core';
import {get} from 'lodash';
import {useTranslation} from 'react-i18next';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {getMilestoneNetworkRequest} from '../../../../http/milestones.network-requests';
import {MILESTONE_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {FancyHeader, ModalLayout, LabelWrapper, RefreshIcon} from 'ui-components';
import TransKeys from '../../../../constants/translation-keys';
import {Milestone} from '../../../../objects/models/milestone.model';
import classes from './milestone-view-panel.module.scss';
import {
  QuerySqlTabs,
  QuerySqlTabsNames,
} from '../../../shared/core/query-sql-tabs/query-sql-tabs.component';
import {Modifiers} from '../../../shared/components/general/modifiers/modifiers.component';
import {TitleWithIcon} from '../../../shared/components/general/title/title.component';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {withModalErrorHandler} from '../../../../core/hoc/with-model-error-handler.hoc';
import {getEntityIcon} from '../../../../constants/entity.consts';

interface OwnProps {
  milestone: Milestone;
  onClose?: () => void;
  initialTab?: QuerySqlTabsNames;
}

type AllProps = OwnProps;

const SELECTED_MILESTONE_KEY = SharedSelectionKeys.MILESTONE_VIEW__MILESTONE;

export const MilestoneViewPanelComponent = (props: AllProps) => {
  const {onClose, milestone, initialTab} = props;
  const {t} = useTranslation();
  const {productEntitiesMap} = useProductData();

  return (
    <div className={classes.LayoutContainer}>
      <ModalLayout>
        <div className={classes.MilestoneViewPanel}>
          <div className={classes.Header}>
            <FancyHeader
              icon={RefreshIcon}
              title={t(TransKeys.MODELS.MILESTONE)}
              onClose={onClose}
              className={classes.Header}
            />
          </div>
          <div className={classes.Body}>
            <div className={classes.NameBlock}>
              <div className={classes.Name}>{milestone.name}</div>
              {milestone.shortDescription && (
                <div className={classes.Description}>{milestone.shortDescription}</div>
              )}
            </div>
            {milestone.entity && (
              <div className={classes.Block}>
                <LabelWrapper label={t(TransKeys.GENERAL.LABELS.ENTITY)}>
                  <TitleWithIcon
                    text={productEntitiesMap[milestone.entity].name}
                    icon={getEntityIcon(milestone.entity)}
                  />
                </LabelWrapper>
              </div>
            )}
            {milestone.signalDefinition && (
              <div className={classes.Block}>
                <QuerySqlTabs
                  query={milestone.signalDefinition}
                  signalId={milestone.signalId}
                  stickyHeader
                  initialTab={initialTab}
                />
              </div>
            )}
            {milestone.history.length > 0 && (
              <div className={classes.Block}>
                <Modifiers history={milestone.history} />
              </div>
            )}
          </div>
        </div>
      </ModalLayout>
    </div>
  );
};

const MilestoneViewPanel = composition<AllProps>(
  MilestoneViewPanelComponent,
  withModalErrorHandler({
    modalTitle: TransKeys.MODELS.MILESTONE,
    modalIcon: RefreshIcon,
    extractErrorCodeFromProps: props => get(props.errors, 'milestone.errorCode'),
  }),
  withLoadBefore({
    milestone: {
      selectedKey: SELECTED_MILESTONE_KEY,
      actionKey: SELECTED_MILESTONE_KEY,
      request: getMilestoneNetworkRequest,
      mapPayloadFromProps: props => props[MILESTONE_ID_PATH_PARAM],
      shouldCall: props => props[MILESTONE_ID_PATH_PARAM] !== undefined,
    },
  })
);

export default MilestoneViewPanel;
