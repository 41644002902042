import {useCallback, useContext, useMemo, useState} from 'react';
import classNames from 'classnames';
import classes from './time-frame-selector.module.scss';
import {
  DatePickerInput,
  EditIcon,
  HoverHelperTip,
  IconButton,
  LabelWrapper,
  Select,
  DateIcon,
} from 'ui-components';
import {exists} from 'front-core';
import moment from 'moment';
import {values} from 'lodash';
import {ParameterInputWrapper} from '../../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {TIME_FORMATS} from '../../../../../../constants/time-formats';
import {ParametersFormContext} from '../../../../../shared/core/parameters-form/parameters-form.context';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../../../constants/translation-keys';
import {hasErrors} from '../../../../../../utils/general.utils';
import {useProductData} from '../../../../../../core/hooks/use-product-data.hook';
import {AnalysisSelectorProps, AnalysisSelectorVariant} from '../analysis-selector.types';
import {DefaultTimeFrameStart} from '../../../../../../constants/shared-enums';

interface SchemaKeysMapping {
  start_date: string;
  end_date: string;
}

const DEFAULT_SCHEMA_KEYS_MAPPING: SchemaKeysMapping = {
  start_date: 'start_date',
  end_date: 'end_date',
};

interface OwnProps extends Omit<AnalysisSelectorProps, 'value' | 'onChange'> {
  onChange?: (parameters: any) => void;
  value?: any;
  schemaKeysMapping?: SchemaKeysMapping;
  minDays?: number;
  simpleMode?: boolean;
}

type AllProps = OwnProps;

const CUSTOM_OPTION_VALUE = 'custom';

export const QUICK_OPTIONS = {
  [DefaultTimeFrameStart.l7]: {
    key: DefaultTimeFrameStart.l7,
    count: 7,
    unit: 'd',
    label: 'Last 7 days',
  },
  [DefaultTimeFrameStart.l14]: {
    key: DefaultTimeFrameStart.l14,
    count: 14,
    unit: 'd',
    label: 'Last 14 days',
  },
  [DefaultTimeFrameStart.l30]: {
    key: DefaultTimeFrameStart.l30,
    count: 30,
    unit: 'd',
    label: 'Last 30 days',
  },
  [DefaultTimeFrameStart.l3m]: {
    key: DefaultTimeFrameStart.l3m,
    count: 3,
    unit: 'month',
    label: 'Last 3 months',
  },
  [DefaultTimeFrameStart.l6m]: {
    key: DefaultTimeFrameStart.l6m,
    count: 6,
    unit: 'month',
    label: 'Last 6 months',
  },
  // Assuming custom is the last!
  custom: {
    key: CUSTOM_OPTION_VALUE,
    label: 'Custom',
    unit: undefined,
    count: undefined,
  },
};

export const TimeFrameSelector = (props: AllProps) => {
  const {
    title,
    subTitle,
    helperText,
    errors,
    simpleMode = false,
    schemaKeysMapping = DEFAULT_SCHEMA_KEYS_MAPPING,
    minDays,
    variant,
    className,
  } = props;
  const {parameters: value, changeParametersValue: onChange} = useContext(ParametersFormContext);
  const {t} = useTranslation();
  const [isCustom, setIsCustom] = useState(false);
  const {defaultSource} = useProductData();
  const hasError = useMemo(
    () => hasErrors(errors, values(schemaKeysMapping)),
    [errors, schemaKeysMapping]
  );
  const errorMessage = useMemo(
    () =>
      errors[schemaKeysMapping.start_date]?.message || errors[schemaKeysMapping.end_date]?.message,
    [errors, schemaKeysMapping]
  );
  const maxDate = useMemo(
    () =>
      defaultSource?.lastValidDate
        ? moment.utc(defaultSource.lastValidDate, TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT).toDate()
        : moment.utc().toDate(),
    [defaultSource]
  );

  const options = useMemo(() => {
    let options = values(QUICK_OPTIONS).map(op => ({
      label: variant === AnalysisSelectorVariant.INLINE ? op.label.toLowerCase() : op.label,
      value: op.key,
    }));
    if (minDays) {
      options = options.filter(o => {
        const def = QUICK_OPTIONS[o.value];
        const now = moment().startOf('d');
        const prev = now.clone().subtract(def.count, def.unit);
        return now.diff(prev, 'd') >= minDays;
      });
    }
    return {options};
  }, [minDays, variant]);
  const startDateMoment = useMemo(() => {
    const v = value[schemaKeysMapping.start_date];
    if (exists(v)) {
      return moment(v, TIME_FORMATS.PARAMETER_DATE_FORMAT);
    }
    return null;
  }, [value, schemaKeysMapping]);
  const endDateMoment = useMemo(() => {
    const v = value[schemaKeysMapping.end_date];
    if (exists(v)) {
      return moment(v, TIME_FORMATS.PARAMETER_DATE_FORMAT);
    }
    return null;
  }, [value, schemaKeysMapping]);
  const selectedOption = useMemo(() => {
    if (!startDateMoment || !endDateMoment) {
      return null;
    }
    if (isCustom || !endDateMoment.isSame(moment(), 'date')) {
      return CUSTOM_OPTION_VALUE;
    }
    const diffDays = endDateMoment.diff(startDateMoment, 'd');
    let op = values(QUICK_OPTIONS).find(qo => qo.unit === 'd' && qo.count === diffDays);
    if (op) {
      return op.key;
    }
    const diffMonth = endDateMoment.diff(startDateMoment, 'month');
    op = values(QUICK_OPTIONS).find(qo => qo.unit === 'month' && qo.count === diffMonth);
    if (op) {
      return op.key;
    }
    // assuming custom is last
    return CUSTOM_OPTION_VALUE;
  }, [isCustom, endDateMoment, startDateMoment]);
  const getValuesForOption = useCallback(
    (selectionKey: string) => {
      const selected = QUICK_OPTIONS[selectionKey];
      const endDate = defaultSource?.lastValidDate
        ? moment
            .utc(defaultSource.lastValidDate, TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT)
            .startOf('d')
        : moment.utc().startOf('d');
      const startDate = endDate.clone().subtract(selected.count, selected.unit);
      return {
        [schemaKeysMapping.start_date]: startDate.format(TIME_FORMATS.PARAMETER_DATE_FORMAT),
        [schemaKeysMapping.end_date]: endDate.format(TIME_FORMATS.PARAMETER_DATE_FORMAT),
      };
    },
    [schemaKeysMapping, defaultSource]
  );
  const onSelectChange = useCallback(
    (selectionKey: string) => {
      if (selectionKey === 'custom') {
        setIsCustom(true);
        return;
      }
      setIsCustom(false);
      onChange(getValuesForOption(selectionKey));
    },
    [onChange, setIsCustom, getValuesForOption]
  );

  const renderContent = () => {
    return (
      <div
        className={classNames(classes.Row, variant === AnalysisSelectorVariant.INLINE && className)}
      >
        <div className={classes.Item}>
          <Select
            value={selectedOption}
            onChange={onSelectChange as any}
            options={options}
            dropdownButtonClassName={classes.SelectUnit}
            searchable={false}
            sortValues={false}
            clearable={false}
            error={hasError}
          />
        </div>
        {selectedOption && selectedOption !== 'custom' && !simpleMode && (
          <div className={classNames(classes.Item, classes.Customize)}>
            <div className={classes.ReadableDate}>
              {startDateMoment.format(TIME_FORMATS.READABLE_DATE)} -{' '}
              {endDateMoment.format(TIME_FORMATS.READABLE_DATE)}
            </div>
            <IconButton icon={EditIcon} onClick={_ => setIsCustom(true)} />
          </div>
        )}
        {selectedOption === 'custom' && (
          <>
            <div className={classNames(classes.Item)}>
              <DatePickerInput
                className={classes.Input}
                placeholder={'Select Date'}
                value={value[schemaKeysMapping.start_date]}
                onChange={value => onChange({[schemaKeysMapping.start_date]: value})}
                dateFormat={'DD/MM/YYYY'}
                dateInputFormat={TIME_FORMATS.PARAMETER_DATE_FORMAT}
                maxDate={maxDate}
                error={Boolean(errors[schemaKeysMapping.start_date])}
                icon={DateIcon}
                utc
              />
            </div>
            <div className={classes.Item}>-</div>
            <div className={classNames(classes.Item)}>
              <DatePickerInput
                className={classes.Input}
                placeholder={'Select Date'}
                value={value[schemaKeysMapping.end_date]}
                onChange={value => onChange({[schemaKeysMapping.end_date]: value})}
                dateFormat={'DD/MM/YYYY'}
                dateInputFormat={TIME_FORMATS.PARAMETER_DATE_FORMAT}
                maxDate={maxDate}
                error={Boolean(errors[schemaKeysMapping.end_date])}
                icon={DateIcon}
                utc
              />
              {defaultSource?.lastValidDate && (
                <HoverHelperTip
                  className={classes.HelperText}
                  title={t(
                    TransKeys.TIME_FRAME_SELECTOR.END_DATE.SOURCE_LAST_VALID_DATE.HELPER_TEXT
                  )}
                />
              )}
            </div>
          </>
        )}
      </div>
    );
  };

  if (variant === AnalysisSelectorVariant.INLINE) {
    return renderContent();
  }
  return (
    <ParameterInputWrapper
      title={title || t(TransKeys.ANALYSIS_FORMS.SHARED.TIME_FRAME_SELECTOR.TITLE)}
      subTitle={subTitle || t(TransKeys.ANALYSIS_FORMS.SHARED.TIME_FRAME_SELECTOR.SUB_TITLE)}
      className={classNames(classes.TimeFrameSelector, className)}
      helperText={helperText}
      error={hasError}
    >
      <LabelWrapper
        label={''}
        error={hasError}
        helperText={
          hasError
            ? errorMessage
            : t(TransKeys.ANALYSIS_FORMS.SHARED.TIME_FRAME_SELECTOR.END_DATE_HELPER_TEXT)
        }
      >
        {renderContent()}
      </LabelWrapper>
    </ParameterInputWrapper>
  );
};
