import {Epic} from 'redux-observable';
import {createRequestEpic} from 'front-core';
import {ActionKey} from '../../constants/action-key';
import HttpClient from '../../services/http-client.service';
import {modelCreated, modelUpdated, notifyEvent} from '../core/core.actions';
import {ModelKey} from '../../constants/model-key';
import {AnalysisFolderActionType} from './analysis-folders.actions';
import {AmplitudeEvent} from '../../constants/amplitude-event';
import {analysisFolderToastCreator} from '../toasts.actions';
import {
  createAnalysisFolderNetworkRequest,
  deleteAnalysisFolderNetworkRequest,
  moveAnalysisFolderNetworkRequest,
  updateAnalysisFolderNetworkRequest,
} from '../../http/analysis-folders.network-requests';
import {demoProductLimitedActionsFilter} from '../store.utils';

export const createAnalysisFolderEpic: Epic = createRequestEpic(
  {
    types: [AnalysisFolderActionType.CREATE_ANALYSIS_FOLDER],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.CREATE_ANALYSIS_FOLDER,
    request: createAnalysisFolderNetworkRequest,
    onSuccess: analysisFolder => [
      notifyEvent(AmplitudeEvent.ANALYSIS_FOLDER_CREATED, {
        id: analysisFolder.id,
      }),
      analysisFolderToastCreator('CREATE_SUCCESS'),
      modelCreated(analysisFolder, ModelKey.ANALYSIS_FOLDER),
    ],
    onError: err => [analysisFolderToastCreator('CREATE_ERROR')],
  },
  HttpClient
);

export const updateAnalysisFolderEpic: Epic = createRequestEpic(
  {
    types: [AnalysisFolderActionType.UPDATE_ANALYSIS_FOLDER],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.UPDATE_ANALYSIS_FOLDER,
    request: updateAnalysisFolderNetworkRequest,
    onSuccess: analysisFolder => [
      notifyEvent(AmplitudeEvent.ANALYSIS_FOLDER_UPDATED, {
        id: analysisFolder.id,
      }),
      analysisFolderToastCreator('UPDATE_SUCCESS'),
      modelUpdated(analysisFolder, ModelKey.ANALYSIS_FOLDER),
    ],
    onError: err => [analysisFolderToastCreator('UPDATE_ERROR')],
  },
  HttpClient
);

export const changeAnalysisFolderEpic: Epic = createRequestEpic(
  {
    types: [AnalysisFolderActionType.CHANGE_ANALYSIS_FOLDER_ICON],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.CHANGE_ANALYSIS_FOLDER_ICON,
    request: updateAnalysisFolderNetworkRequest,
    onSuccess: analysisFolder => [
      notifyEvent(AmplitudeEvent.ANALYSIS_FOLDER_ICON_UPDATED, {
        id: analysisFolder.id,
      }),
      modelUpdated(analysisFolder, ModelKey.ANALYSIS_FOLDER),
    ],
    onError: err => [analysisFolderToastCreator('UPDATE_ERROR')],
  },
  HttpClient
);

export const deleteAnalysisFolderEpic: Epic = createRequestEpic(
  {
    types: [AnalysisFolderActionType.DELETE_ANALYSIS_FOLDER],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.DELETE_ANALYSIS_FOLDER,
    request: deleteAnalysisFolderNetworkRequest,
    onSuccess: (analysisFolder, payload) => [
      notifyEvent(AmplitudeEvent.ANALYSIS_FOLDER_DELETED, {id: payload}),
      analysisFolderToastCreator('UPDATE_SUCCESS'),
      modelUpdated(analysisFolder, ModelKey.ANALYSIS_FOLDER),
    ],
    onError: err => [analysisFolderToastCreator('DELETE_ERROR')],
  },
  HttpClient
);

export const moveAnalysisFolderEpic: Epic = createRequestEpic(
  {
    types: [AnalysisFolderActionType.MOVE_ANALYSIS_FOLDER],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.MOVE_ANALYSIS_FOLDER,
    request: moveAnalysisFolderNetworkRequest,
    onSuccess: (analysisFolder, payload) => [
      notifyEvent(AmplitudeEvent.ANALYSIS_FOLDER_MOVED, {id: payload.id}),
      analysisFolderToastCreator('UPDATE_SUCCESS'),
      modelUpdated(analysisFolder, ModelKey.ANALYSIS_FOLDER),
    ],
    onError: err => [analysisFolderToastCreator('UPDATE_ERROR')],
  },
  HttpClient
);
