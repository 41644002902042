import classNames from 'classnames';
import classes from './parameter.module.scss';
import {BetterNavLink} from '../../../core/override/better-nav-link.component.tsx';

interface OwnProps {
  label?: string;
  value: any;
  onClick?: () => void;
  link?: string;
  className?: string;
}

type AllProps = OwnProps;

export const Parameter = (props: AllProps) => {
  const {label, value, onClick, link, className} = props;

  const renderContent = () => {
    if (link) {
      return (
        <BetterNavLink to={link} className={classNames(classes.Value, classes.Clickable)}>
          {value}
        </BetterNavLink>
      );
    }
    return (
      <div onClick={onClick} className={classNames(classes.Value, onClick && classes.Clickable)}>
        {value}
      </div>
    );
  };

  return (
    <div className={classNames(classes.Parameter, className)}>
      {label && <div className={classes.Label}>{label}:</div>}
      {renderContent()}
    </div>
  );
};
