import {AnalysisSelectorProps} from '../analysis-selector.types.ts';
import {TableEntity, TableEntityBinding} from '../../../../../../objects/models/table.model.ts';
import {useTranslation} from 'react-i18next';
import React, {useCallback, useMemo} from 'react';
import TransKeys from '../../../../../../constants/translation-keys.ts';
import classNames from 'classnames';
import classes from './gradual-release-selector.module.scss';
import {ParameterInputWrapper} from '../../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component.tsx';
import {hasErrors} from '../../../../../../utils/general.utils.ts';
import {get, values} from 'lodash';
import pluralize from 'pluralize';
import {useProductData} from '../../../../../../core/hooks/use-product-data.hook.ts';
import {BuilderWrapper} from '../../../../../shared/components/general/builder-wrapper/builder-wrapper.component.tsx';
import {TableEventsValueQueryBuilder} from '../../../../../shared/core/query-builders/table-events-value-query-builder/table-events-value-query-builder.component.tsx';
import {LiteralValueType, TextInput} from 'ui-components';
import {
  METADATA_KEY,
  PARAMETERS_METADATA_KEY,
} from '../../../../../../constants/parameters-saved-keys.ts';
import {exists} from 'front-core';

interface SchemaKeysMapping {
  gradual_release_query: string;
  gradual_versions: string;
}

const DEFAULT_SCHEMA_KEYS_MAPPING: SchemaKeysMapping = {
  gradual_release_query: 'gradual_release_query',
  gradual_versions: 'gradual_versions',
};

interface Props extends AnalysisSelectorProps {
  schemaKeysMapping?: SchemaKeysMapping;
  entityContext: TableEntity;
}

const COLUMN_FILTERS = {
  literalType: [LiteralValueType.INTEGER, LiteralValueType.FLOAT, LiteralValueType.STRING],
};

const DEFAULT_VERSIONS = [null, null];

export const GradualReleaseSelector = (props: Props) => {
  const {
    title,
    subTitle,
    helperText,
    onChange,
    value,
    schemaKeysMapping = DEFAULT_SCHEMA_KEYS_MAPPING,
    entityContext,
    errors,
    className,
  } = props;
  const {t} = useTranslation();
  const {productEntitiesMap} = useProductData();
  const query = useMemo(
    () => value[schemaKeysMapping.gradual_release_query],
    [value, schemaKeysMapping]
  );
  const versions = useMemo(
    () => value[schemaKeysMapping.gradual_versions] || DEFAULT_VERSIONS,
    [value, schemaKeysMapping]
  );
  const versionInputType = useMemo(() => {
    const literalType = get(
      query,
      `cases.0.1.${PARAMETERS_METADATA_KEY}.${METADATA_KEY.TABLE_COLUMN_LITERAL_TYPE_KEY}`
    );
    switch (literalType) {
      case LiteralValueType.INTEGER:
      case LiteralValueType.FLOAT:
        return 'number';
      case LiteralValueType.STRING:
        return 'text';
    }
  }, [query]);
  const hasError = useMemo(
    () => hasErrors(errors, values(schemaKeysMapping)),
    [errors, schemaKeysMapping]
  );
  const tableEventFilters = useMemo(
    () => [
      {
        entityBinding: TableEntityBinding.TWO_WAY,
        entityContext,
      },
    ],
    [entityContext]
  );
  const onQueryChange = useCallback(
    query => {
      onChange({
        [schemaKeysMapping.gradual_release_query]: query,
        [schemaKeysMapping.gradual_versions]: query ? DEFAULT_VERSIONS : null,
      });
    },
    [onChange, schemaKeysMapping]
  );
  const onVersionsChange = useCallback(
    (version: string | number, index: number) => {
      const newVersions = [...(versions || DEFAULT_VERSIONS)];
      newVersions[index] = version;
      onChange({
        [schemaKeysMapping.gradual_versions]: newVersions,
      });
    },
    [onChange, versions, schemaKeysMapping]
  );
  const entityName = useMemo(
    () => pluralize(productEntitiesMap[entityContext]?.name || '').toLowerCase(),
    [productEntitiesMap, entityContext]
  );

  return (
    <ParameterInputWrapper
      title={
        title || t(TransKeys.ANALYSIS_FORMS.SHARED.GRADUAL_RELEASE.TITLE, {entity: entityName})
      }
      subTitle={
        subTitle ||
        t(TransKeys.ANALYSIS_FORMS.SHARED.GRADUAL_RELEASE.SUB_TITLE, {entity: entityName})
      }
      helperText={
        helperText ||
        t(TransKeys.ANALYSIS_FORMS.SHARED.GRADUAL_RELEASE.HELPER_TEXT, {entity: entityName})
      }
      className={classNames(classes.SegmentFilterSelector, className)}
      error={hasError}
    >
      <BuilderWrapper>
        <TableEventsValueQueryBuilder
          query={query}
          onChange={onQueryChange}
          errors={errors[schemaKeysMapping.gradual_release_query]}
          filters={tableEventFilters}
          columnFilters={COLUMN_FILTERS}
          thenText={t(TransKeys.ANALYSIS_FORMS.SHARED.GRADUAL_RELEASE.THEN_TEXT)}
          multiEvents={false}
          multiSelection={false}
          sameTypeThen
        />
        <div className={classes.VersionInputs}>
          <span>{t(TransKeys.ANALYSIS_FORMS.SHARED.GRADUAL_RELEASE.COMPARE_VERSIONS)}</span>
          <TextInput
            className={classes.VersionInput}
            placeholder={t(
              TransKeys.ANALYSIS_FORMS.SHARED.GRADUAL_RELEASE.FROM_VERSION_PLACEHOLDER
            )}
            type={versionInputType}
            disabled={!exists(versionInputType)}
            error={Boolean(errors[schemaKeysMapping.gradual_versions]?.[0])}
            value={versions[0]}
            onChange={v => onVersionsChange(v as any, 0)}
          />
          <span>{t(TransKeys.ANALYSIS_FORMS.SHARED.GRADUAL_RELEASE.TO)}</span>
          <TextInput
            className={classes.VersionInput}
            placeholder={t(TransKeys.ANALYSIS_FORMS.SHARED.GRADUAL_RELEASE.TO_VERSION_PLACEHOLDER)}
            type={versionInputType}
            disabled={!exists(versionInputType)}
            value={versions[1]}
            error={Boolean(errors[schemaKeysMapping.gradual_versions]?.[1])}
            onChange={v => onVersionsChange(v as any, 1)}
          />
        </div>
      </BuilderWrapper>
    </ParameterInputWrapper>
  );
};
