import classNames from 'classnames';
import classes from './homepage-chart-empty-state.module.scss';
import {CircularProgress} from '@material-ui/core';

interface OwnProps {
  text: string;
  loading?: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const HomepageChartEmptyState = (props: AllProps) => {
  const {text, loading, className} = props;

  return (
    <div className={classNames(classes.HomepageChartEmptyState, className)}>
      {loading && <CircularProgress size={20} className={classes.Progress} />}
      {text}
    </div>
  );
};
