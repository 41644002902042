import classes from './behavioral-first-or-last-value-query-builder.module.scss';
import {Select} from 'ui-components';
import {useCallback, useMemo} from 'react';
import {
  createTableEventsValueInitialQuery,
  TableEventsValueQueryBuilder,
  THEN_INDEX,
} from '../table-events-value-query-builder/table-events-value-query-builder.component';
import {get} from 'lodash';
import {QueryBuilderFactory} from 'ui-components';
import {
  METADATA_KEY,
  PARAMETERS_METADATA_KEY,
} from '../../../../../constants/parameters-saved-keys';
import {BEHAVIORAL_FIRST_OR_LAST_VALUE_PARAMETER_MAPPING as PARAMETER_MAPPING} from '../query-builders.config';
import {ComputedTemplatesNames} from '../../../../../constants/query-builder';
import {TableEvent} from '../../../../../objects/models/table-event.model';

const {TABLE_COLUMN_DATA_TYPE_KEY, TABLE_COLUMN_LITERAL_TYPE_KEY} = METADATA_KEY;

export const BEHAVIORAL_FIRST_OR_LAST_VALUE_QUERY_BUILDER_NAME =
  'BehavioralFirstOrLastValueQueryBuilder';

enum BehavioralValueTemplateNames {
  FIRST_VALUE = ComputedTemplatesNames.FIRST_VALUE,
  LAST_VALUE = ComputedTemplatesNames.LAST_VALUE,
}

const TEMPLATE_OPTIONS = {
  options: [
    {value: BehavioralValueTemplateNames.FIRST_VALUE, label: 'First'},
    {value: BehavioralValueTemplateNames.LAST_VALUE, label: 'Last'},
  ],
};

interface OwnProps {
  query: any;
  errors?: any;
  filters?: any;
  onChange: (query: any) => void;
  disabled?: boolean;
}

type AllProps = OwnProps;

const FIRST_CASE_THEN_METADATA_PATH = `cases.0.${THEN_INDEX}.${PARAMETERS_METADATA_KEY}`;
const TEMPLATE_NAME_PARAMETER_PATH = 'template';

export const createInitialBehavioralFirstOrLastValueQuery = (tableEvent?: TableEvent) => {
  const q = QueryBuilderFactory.createTemplate(ComputedTemplatesNames.FIRST_VALUE);
  q[PARAMETERS_METADATA_KEY] = {
    [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: BEHAVIORAL_FIRST_OR_LAST_VALUE_QUERY_BUILDER_NAME,
  };

  if (tableEvent) {
    q['parameters'] = [createTableEventsValueInitialQuery(tableEvent)];
  }
  return q;
};

export const BehavioralFirstOrLastValueQueryBuilder = (props: AllProps) => {
  const {query: queryFromProps, filters, errors, onChange, disabled} = props;
  const query = useMemo(() => {
    if (queryFromProps) {
      return queryFromProps;
    }
    return createInitialBehavioralFirstOrLastValueQuery();
  }, [queryFromProps]);

  const onTableEventsValueQueryChange = useCallback(
    (tableEventsValueQuery: any) => {
      const parameters = [
        tableEventsValueQuery,
        get(
          tableEventsValueQuery,
          `${FIRST_CASE_THEN_METADATA_PATH}.${TABLE_COLUMN_DATA_TYPE_KEY}`
        ),
        get(
          tableEventsValueQuery,
          `${FIRST_CASE_THEN_METADATA_PATH}.${TABLE_COLUMN_LITERAL_TYPE_KEY}`
        ),
      ];
      const newQuery = {
        ...query,
        parameters,
      };

      onChange(newQuery);
    },
    [query, onChange]
  );
  const onTemplateChange = useCallback(
    (templateName: BehavioralValueTemplateNames) => {
      const newQuery = {
        ...query,
        template: templateName,
      };
      onChange(newQuery);
    },
    [query, onChange]
  );

  return (
    <div className={classes.BehavioralFirstOrLastValueQueryBuilderContainer}>
      <div className={classes.SelectTemplate}>
        <div>Take</div>
        <Select
          clearable={false}
          searchable={false}
          options={TEMPLATE_OPTIONS}
          onChange={v => onTemplateChange(v as BehavioralValueTemplateNames)}
          value={get(query, TEMPLATE_NAME_PARAMETER_PATH)}
          disabled={disabled}
        />
        <div>value property in</div>
      </div>
      <TableEventsValueQueryBuilder
        query={get(query, PARAMETER_MAPPING.table_events_value_query)}
        onChange={v => onTableEventsValueQueryChange(v)}
        errors={get(errors, PARAMETER_MAPPING.table_events_value_query)}
        filters={filters}
        multiEvents={false}
        multiSelection
        sameTypeThen
        disabled={disabled}
      />
    </div>
  );
};
