import classNames from 'classnames';
import classes from './tab-header.module.scss';
import {HoverHelperTip} from 'ui-components';

interface OwnProps {
  title: string;
  subTitle?: string;
  helperText?: string;
  renderRight?: any;
  size?: 'small' | 'default';
  className?: string;
}

type AllProps = OwnProps;

export const TabHeader = (props: AllProps) => {
  const {title, subTitle, helperText, size, renderRight, className} = props;

  return (
    <div
      className={classNames(
        classes.TabHeader,
        subTitle && classes.HasSubtitle,
        size === 'small' && classes.Small,
        className
      )}
    >
      <div className={classes.TitleWrapper}>
        <div className={classes.Title}>
          {title}
          {helperText && <HoverHelperTip title={helperText} className={classes.Helper} />}
        </div>
        {renderRight && <div className={classes.Right}>{renderRight}</div>}
      </div>
      {subTitle && <div className={classes.SubTitle}>{subTitle}</div>}
    </div>
  );
};
