import {action} from 'front-core';
import {Role} from '../../constants/permissions';
import {showConfirmationMessage} from '../interface/interface.actions';
import i18n from '../../config/i18n.config';
import TransKeys from '../../constants/translation-keys';

export enum MemberSettingsActionType {
  REMOVE_MEMBER = '@@MEMBER_SETTINGS/REMOVE_MEMBER',
  CHANGE_TEAM_FOR_MEMBER = '@@MEMBER_SETTINGS/CHANGE_TEAM_FOR_MEMBER',
  CHANGE_PERMISSION_ROLE_FOR_MEMBER = '@@MEMBER_SETTINGS/CHANGE_PERMISSION_ROLE_FOR_MEMBER',
}

export const removeMember = (id: number) => action(MemberSettingsActionType.REMOVE_MEMBER, id);
export const removeMemberConfirmed = (id: number, name: string) => {
  return showConfirmationMessage(
    {
      title: i18n.t(TransKeys.CONFIRMATIONS.REMOVE_MEMBER.TITLE),
      content: i18n.t(TransKeys.CONFIRMATIONS.REMOVE_MEMBER.CONTENT, {user: name}),
      rejectBtn: i18n.t(TransKeys.GENERAL.ACTIONS.CANCEL),
      approveBtn: i18n.t(TransKeys.GENERAL.ACTIONS.REMOVE),
    },
    () => [removeMember(id)]
  );
};
export const changeTeamForMember = (memberId: number, teamId: number) =>
  action(MemberSettingsActionType.CHANGE_TEAM_FOR_MEMBER, {memberId, teamId});
export const changePermissionRoleForMember = (memberId: number, permissionRole: Role) =>
  action(MemberSettingsActionType.CHANGE_PERMISSION_ROLE_FOR_MEMBER, {
    memberId,
    permissionRole,
  });
