import {exists} from 'front-core';
import {debounce, startCase} from 'lodash';
import {useCallback, useContext, useEffect, useMemo, useState, useRef} from 'react';
import {PanelKey} from '../../../../constants/panels';
import {Action, Role, Subject} from '../../../../constants/permissions';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import usePermissions from '../../../../core/hooks/use-permissions.hook';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {useRemoteList} from '../../../../core/hooks/use-remote-list.hook';
import {getMembersNetworkRequest} from '../../../../http/members.network-requests';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component.tsx';
import {GridTable} from '../../../shared/components/general/grid-table/grid-table.component.tsx';
import {MemberDTO} from '../../../../objects/dto/members-settings.dto';
import {Button, CloseIcon, IconButton, SearchInput} from 'ui-components';
import {MemberNameCell} from './cells/member-cell.component';
import classes from './member-settings.module.scss';
import {useDispatch} from 'react-redux';
import {
  changePermissionRoleForMember,
  changeTeamForMember,
  removeMemberConfirmed,
} from '../../../../store/members/members.actions';
import {ModelKey} from '../../../../constants/model-key';
import {CoreActionsType} from '../../../../store/core/core.actions';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../store/actions-listener/actions-listener.actions';
import {TeamSelectCell} from './cells/team-select-cell.component';
import {useCurrentUser} from '../../../../core/hooks/use-user.hook';
import {MemberPermissionCell} from './cells/member-permission-cell.component';
import TransKeys from '../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook.ts';
import {AmplitudeEvent} from '../../../../constants/amplitude-event.ts';

const createListKey = (key: string = 'MEMBER') => `MEMBERS/${key}`;

export const MembersSettings = () => {
  const dispatch = useDispatch();
  const {can} = usePermissions();
  const notify = useAmplitude();
  const {openPrimaryPanel} = useContext(PanelsContext);
  const {teams, name: productName} = useProductData();
  const {t} = useTranslation();

  const currentUser = useCurrentUser();

  const canDeleteMember = can(Subject.USER, Action.DELETE);

  const config = useMemo(
    () => ({
      listKey: createListKey(),
      actionKey: createListKey(),
      request: getMembersNetworkRequest,
      modelKey: ModelKey.MEMBER,
    }),
    []
  );

  const defaultFilters = useMemo(
    () => ({
      itemsPerPage: 10,
      orderBy: ['firstName', 'email'],
      order: 'asc',
    }),
    []
  );

  const filterKeys = useMemo(() => ['q'], []);

  const {isLoading, filters, listsData, onFiltersChange, onPageChange, onRefresh} = useRemoteList({
    defaultFilters,
    config,
    filterKeys,
  });

  const [searchMemberState, setSearchMemberState] = useState<string | undefined>(filters.q || '');
  const onChangeFilterRef = useRef(onFiltersChange);

  const changeSearchInFilters = useMemo(
    () => debounce((value: string) => onChangeFilterRef.current({q: value}), 200),
    [onChangeFilterRef]
  );

  useEffect(() => {
    changeSearchInFilters(searchMemberState);
  }, [searchMemberState, changeSearchInFilters]);

  useEffect(() => {
    const listener = action => {
      const {modelKey} = action.payload;
      if (modelKey === ModelKey.MEMBER) {
        onRefresh();
      }
    };

    dispatch(registerActionListener(CoreActionsType.MODEL_UPDATED, listener));
    dispatch(registerActionListener(CoreActionsType.MODEL_DELETED, listener));

    return () => {
      dispatch(removeActionListener(CoreActionsType.MODEL_UPDATED, listener));
      dispatch(removeActionListener(CoreActionsType.MODEL_DELETED, listener));
    };
  }, [dispatch, onRefresh]);

  const onSearchMember = useMemo(() => debounce((v: string) => setSearchMemberState(v)), []);

  const membersList = (listsData?.list as MemberDTO[]) ?? [];
  const perPage = listsData?.meta?.numPerPage;
  const total = listsData?.meta?.total;
  const page = listsData?.meta?.page - 1;

  const onInvite = useCallback(() => {
    notify(AmplitudeEvent.ADD_MEMBER_CLICKED);
    openPrimaryPanel(PanelKey.INVITE_USERS_PANEL);
  }, [openPrimaryPanel, notify]);

  const onRemoveUser = useCallback(
    (member: MemberDTO) => {
      dispatch(removeMemberConfirmed(member.id, member.fullName));
    },
    [dispatch]
  );

  const onChangeTeam = useCallback(
    (memberId: number, teamId: number) => {
      dispatch(changeTeamForMember(memberId, teamId));
    },
    [dispatch]
  );

  const onChangePermissionRole = useCallback(
    (memberId: number, permissionRole: Role) => {
      dispatch(changePermissionRoleForMember(memberId, permissionRole));
    },
    [dispatch]
  );

  const columns = useMemo(() => {
    return [
      {
        title: t(TransKeys.ACCOUNT_SETTINGS.MEMBERS.TABLE.COLUMNS.MEMBER_NAME.LABEL),
        width: '27rem',
        helperText: t(TransKeys.ACCOUNT_SETTINGS.MEMBERS.TABLE.COLUMNS.MEMBER_NAME.HELPER_TEXT),
        key: 'member',
        render: (member: MemberDTO) => <MemberNameCell member={member} />,
      },
      {
        title: t(TransKeys.ACCOUNT_SETTINGS.MEMBERS.TABLE.COLUMNS.ROLE.LABEL),
        width: '13rem',
        helperText: t(TransKeys.ACCOUNT_SETTINGS.MEMBERS.TABLE.COLUMNS.ROLE.HELPER_TEXT),
        key: 'user-role',
        render: ({userRole}) => {
          const role = exists(userRole?.trim()) ? startCase(userRole.trim()) : undefined;
          return role ? (
            <span>{role}</span>
          ) : (
            <span className={classes.Unassigned}>
              {t(TransKeys.ACCOUNT_SETTINGS.MEMBERS.TABLE.COLUMNS.ROLE.PLACEHOLDER)}
            </span>
          );
        },
      },
      {
        title: t(TransKeys.ACCOUNT_SETTINGS.MEMBERS.TABLE.COLUMNS.TEAM.LABEL),
        helperText: t(TransKeys.ACCOUNT_SETTINGS.MEMBERS.TABLE.COLUMNS.TEAM.HELPER_TEXT),
        width: '16rem',
        hidden: teams?.length === 0,
        key: 'team',
        render: ({team, id: memberId}: MemberDTO) => {
          return (
            <TeamSelectCell selectedTeam={team} memberId={memberId} onChangeTeam={onChangeTeam} />
          );
        },
      },
      {
        title: t(TransKeys.ACCOUNT_SETTINGS.MEMBERS.TABLE.COLUMNS.PERMISSION.LABEL),
        helperText: t(TransKeys.ACCOUNT_SETTINGS.MEMBERS.TABLE.COLUMNS.PERMISSION.HELPER_TEXT),
        width: '13rem',
        key: 'permission',
        render: (member: MemberDTO) => {
          return (
            <MemberPermissionCell onChangePermissionRole={onChangePermissionRole} member={member} />
          );
        },
      },
      {
        key: 'remove-user',
        width: '4rem',
        align: 'left',
        title: '',
        hidden: !canDeleteMember,
        render: (member: MemberDTO) =>
          currentUser.id !== member.id && (
            <IconButton
              tooltipText={t(TransKeys.ACCOUNT_SETTINGS.MEMBERS.ACTIONS.REMOVE_USER.TOOLTIP, {
                product: productName,
              })}
              icon={CloseIcon}
              onClick={() => onRemoveUser(member)}
              border={false}
            />
          ),
      },
    ];
  }, [
    t,
    teams,
    canDeleteMember,
    currentUser.id,
    onChangeTeam,
    onRemoveUser,
    onChangePermissionRole,
    productName,
  ]);

  return (
    <div className={classes.MemberSettings}>
      <div className={classes.Header}>
        <div className={classes.TitleSection}>
          <div className={classes.Title}>{t(TransKeys.ACCOUNT_SETTINGS.MEMBERS.TITLE)}</div>
          <span className={classes.Description}>
            {t(TransKeys.ACCOUNT_SETTINGS.MEMBERS.DESCRIPTION)}
          </span>
        </div>
        <div className={classes.Actions}>
          <SearchInput
            className={classes.Search}
            onChange={onSearchMember}
            value={searchMemberState}
            placeholder={t(TransKeys.GENERAL.LABELS.SEARCH)}
          />
          <Button
            variant="contained"
            onClick={onInvite}
            size="large"
            className={classes.AddMemberBtn}
          >
            {t(TransKeys.ACCOUNT_SETTINGS.MEMBERS.ACTIONS.ADD_MEMBER.LABEL)}
          </Button>
        </div>
      </div>
      <div className={classes.GridContainer}>
        {isLoading && <GenericLoading />}
        <GridTable
          data={membersList}
          page={page}
          order={'asc'}
          perPage={perPage}
          onPageChange={(e, page) => onPageChange(page + 1)}
          paginationMode="pages"
          pagination
          total={total}
          className={classes.Grid}
          rowClassName={classes.Row}
          isLoading={!membersList || isLoading}
          dataKey={'id'}
          columns={columns}
        />
      </div>
    </div>
  );
};
