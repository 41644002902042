import {createContext, useMemo, useState, useCallback} from 'react';
import {useLocalStorage} from 'ui-components';

export const enum AppNavigatorDisplayMode {
  EXPANDED = 'expanded',
  COLLAPSED = 'collapsed',
}

const NAVIGATOR_DISPLAY_MODE_STORAGE_KEY = 'navigator-display-mode';

interface IAppNavigatorContext {
  displayMode: AppNavigatorDisplayMode;
  setDisplayMode: (displayMode: AppNavigatorDisplayMode) => void;
  setOverrideDisplayMode: (displayMode: AppNavigatorDisplayMode) => void;
  resetOverrideDisplayMode: () => void;
  isCollapsed: boolean;
  toggleCollapse: () => void;
}

interface OwnProps {
  children: any;
}

export const AppNavigatorContext = createContext<IAppNavigatorContext>({
  displayMode: undefined,
  setDisplayMode: undefined,
  setOverrideDisplayMode: undefined,
  resetOverrideDisplayMode: undefined,
  isCollapsed: undefined,
  toggleCollapse: undefined,
});

export const AppNavigatorContextProvider = (props: OwnProps) => {
  const {children} = props;

  const [persistedDisplayMode, setDisplayMode_] = useLocalStorage(
    NAVIGATOR_DISPLAY_MODE_STORAGE_KEY,
    AppNavigatorDisplayMode.EXPANDED
  );

  const [overrideDisplayMode, setOverrideDisplayMode] = useState<AppNavigatorDisplayMode>();

  const setDisplayMode = useCallback(
    (displayMode: AppNavigatorDisplayMode) => {
      setDisplayMode_(displayMode);
      setOverrideDisplayMode(undefined);
    },
    [setDisplayMode_]
  );

  const resetOverrideDisplayMode = useCallback(
    () => setOverrideDisplayMode(undefined),
    [setOverrideDisplayMode]
  );

  const displayMode = useMemo(
    () => overrideDisplayMode ?? persistedDisplayMode,
    [overrideDisplayMode, persistedDisplayMode]
  );

  const isCollapsed = useMemo(
    () => displayMode === AppNavigatorDisplayMode.COLLAPSED,
    [displayMode]
  );

  const toggleCollapse = useCallback(
    () =>
      setDisplayMode(
        isCollapsed ? AppNavigatorDisplayMode.EXPANDED : AppNavigatorDisplayMode.COLLAPSED
      ),
    [isCollapsed, setDisplayMode]
  );

  const contextValue = useMemo(
    () => ({
      displayMode,
      isCollapsed,
      setDisplayMode,
      setOverrideDisplayMode,
      resetOverrideDisplayMode,
      toggleCollapse,
    }),
    [
      displayMode,
      isCollapsed,
      setDisplayMode,
      setOverrideDisplayMode,
      resetOverrideDisplayMode,
      toggleCollapse,
    ]
  );

  return (
    <AppNavigatorContext.Provider value={contextValue}>{children}</AppNavigatorContext.Provider>
  );
};
