import {createRequestEpic} from 'front-core';
import {Epic} from 'redux-observable';
import {AmplitudeEvent} from '../../constants/amplitude-event';
import {ActionKey} from '../../constants/action-key';
import {ModelKey} from '../../constants/model-key';
import {
  createTeamNetworkRequest,
  deleteTeamNetworkRequest,
  editTeamNetworkRequest,
} from '../../http/teams.network-requests';
import HttpClient from '../../services/http-client.service';
import {
  getProductData,
  modelCreated,
  modelDeleted,
  modelUpdated,
  notifyEvent,
} from '../core/core.actions';
import {teamToastCreator} from '../toasts.actions';
import {TeamsSettingsActionType} from './teams.actions';

export const createTeamEpic: Epic = createRequestEpic(
  {
    types: [TeamsSettingsActionType.CREATE_TEAM],
    actionKey: ActionKey.CREATE_TEAM,
    request: createTeamNetworkRequest,
    onSuccess: team => [
      getProductData(),
      notifyEvent(AmplitudeEvent.TEAM_CREATED, {id: team.id}),
      teamToastCreator('CREATE_SUCCESS'),
      modelCreated(team, ModelKey.TEAM),
    ],
    onError: () => [teamToastCreator('CREATE_ERROR')],
  },
  HttpClient
);

export const updateTeamEpic: Epic = createRequestEpic(
  {
    types: [TeamsSettingsActionType.UPDATE_TEAM],
    actionKey: ActionKey.UPDATE_TEAM,
    request: editTeamNetworkRequest,
    onSuccess: team => [
      getProductData(),
      notifyEvent(AmplitudeEvent.TEAM_UPDATED, {id: team.id}),
      teamToastCreator('UPDATE_SUCCESS'),
      modelUpdated(team, ModelKey.TEAM),
    ],
    onError: err_ => [teamToastCreator('UPDATE_ERROR')],
  },
  HttpClient
);

export const deleteTeamEpic: Epic = createRequestEpic(
  {
    types: [TeamsSettingsActionType.DELETE_TEAM],
    actionKey: ActionKey.DELETE_TEAM,
    request: deleteTeamNetworkRequest,
    onSuccess: team => [
      getProductData(),
      notifyEvent(AmplitudeEvent.TEAM_DELETED, {id: team.id}),
      teamToastCreator('DELETE_SUCCESS'),
      modelDeleted(team, ModelKey.TEAM),
    ],
    onError: () => [teamToastCreator('DELETE_ERROR')],
  },
  HttpClient
);
