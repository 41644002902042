import {Epic} from 'redux-observable';
import {createRequestEpic} from 'front-core';
import {ActionKey} from '../../constants/action-key';
import HttpClient from '../../services/http-client.service';
import {AnalysesActionType} from './analyses.actions';
import {analysisToastCreator} from '../toasts.actions';
import {
  changeAnalysisParametersNetworkRequest,
  createScheduleAnalysisNetworkRequest,
  deleteScheduleAnalysisNetworkRequest,
  editAnalysisNetworkRequest,
  editScheduleAnalysisNetworkRequest,
  moveAnalysisToFolderNetworkRequest,
  quickRunForAdminsNetworkRequest,
  requestAnalysisNetworkRequest,
} from '../../http/analyses.network-requests';
import {showToastMessage} from '../interface/interface.actions';
import {ToastType} from '../../objects/system/toast-type.enum';
import i18n from '../../config/i18n.config';
import TransKeys from '../../constants/translation-keys';
import {AmplitudeEvent} from '../../constants/amplitude-event';
import {
  getAppStatusCounters,
  getProductData,
  modelCreated,
  modelDeleted,
  modelUpdated,
  notifyEvent,
} from '../core/core.actions';
import {ModelKey} from '../../constants/model-key';
import {
  deleteAnalysisResultNetworkRequest,
  rerunAnalysisResultNetworkRequest,
  stopAnalysisResultNetworkRequest,
  updateAnalysisResultNetworkRequest,
} from '../../http/analysis-results.network-requests';
import {Analysis} from '../../objects/models/analysis.model';
import {SOURCE_META_KEY} from '../../constants/app-sources';
import {replace} from 'react-router-redux';
import {AppRoutes} from '../../constants/app-routes';
import {demoProductLimitedActionsFilter} from '../store.utils';

export const requestAnalysisEpic: Epic = createRequestEpic(
  {
    types: [AnalysesActionType.REQUEST_ANALYSIS],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.REQUEST_ANALYSIS,
    request: requestAnalysisNetworkRequest,
    onSuccess: (analysisResult, _, {metadata}) => [
      notifyEvent(AmplitudeEvent.ANALYSIS_REQUESTED, {
        id: analysisResult.id,
        analysis_type_id: analysisResult.analysisTypeId,
        source: metadata?.[SOURCE_META_KEY],
      }),
      showToastMessage(i18n.t(TransKeys.TOASTS.ANALYSIS_REQUESTED_SUCCESSFULLY), ToastType.SUCCESS),
      modelCreated(analysisResult, ModelKey.ANALYSIS_RESULT),
      getProductData(),
      getAppStatusCounters(),
    ],
    onError: (err, payload) => {
      const toastErrorAction =
        err.data?.error_code === 3000
          ? showToastMessage(
              i18n.t(TransKeys.ANALYSIS_FORMS.INCOMPLETE_ENTITY_DEFINITION_ERROR),
              ToastType.ERROR
            )
          : analysisToastCreator('CREATE_ERROR');

      return [
        toastErrorAction,
        notifyEvent(AmplitudeEvent.RYOA_API_ERROR, {
          id: payload.analysisResultId,
        }),
      ];
    },
  },
  HttpClient
);

export const updateAnalysisResultEpic: Epic = createRequestEpic(
  {
    types: [AnalysesActionType.UPDATE_ANALYSIS_RESULT],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.UPDATE_ANALYSIS_RESULT,
    request: updateAnalysisResultNetworkRequest,
    onSuccess: analysisResult => [
      showToastMessage(i18n.t(TransKeys.TOASTS.UPDATE_SUCCESS), ToastType.SUCCESS),
      modelUpdated(analysisResult, ModelKey.ANALYSIS_RESULT),
      getProductData(),
      getAppStatusCounters(),
    ],
    onError: (err, payload) => [analysisToastCreator('CREATE_ERROR')],
  },
  HttpClient
);

export const createScheduleAnalysisEpic: Epic = createRequestEpic(
  {
    types: [AnalysesActionType.CREATE_SCHEDULE_ANALYSIS],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.CREATE_SCHEDULE_ANALYSIS,
    request: createScheduleAnalysisNetworkRequest,
    onSuccess: (analysis: Analysis, payload) => [
      notifyEvent(AmplitudeEvent.CREATE_SCHEDULE_ANALYSIS, {
        id: analysis.id,
        schedule: analysis.cronExp,
      }),
      showToastMessage(
        i18n.t(TransKeys.TOASTS.CREATE_SCHEDULE_ANALYSIS_SUCCESS),
        ToastType.SUCCESS
      ),
      modelUpdated(
        {id: payload.analysisResultId, cronExp: analysis.cronExp},
        ModelKey.ANALYSIS_RESULT
      ),
    ],
    onError: (err, payload) => [
      showToastMessage(i18n.t(TransKeys.TOASTS.CREATE_SCHEDULE_ANALYSIS_ERROR), ToastType.ERROR),
    ],
  },
  HttpClient
);

export const editScheduleAnalysisEpic: Epic = createRequestEpic(
  {
    types: [AnalysesActionType.EDIT_SCHEDULE_ANALYSIS],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.EDIT_SCHEDULE_ANALYSIS,
    request: editScheduleAnalysisNetworkRequest,
    onSuccess: (analysis: Analysis, payload) => [
      notifyEvent(AmplitudeEvent.EDIT_SCHEDULE_ANALYSIS, {
        id: analysis.id,
        schedule: analysis.cronExp,
      }),
      showToastMessage(i18n.t(TransKeys.TOASTS.EDIT_SCHEDULE_ANALYSIS_SUCCESS), ToastType.SUCCESS),
      modelUpdated(
        {id: payload.analysisResultId, cronExp: analysis.cronExp},
        ModelKey.ANALYSIS_RESULT
      ),
    ],
    onError: (err, payload) => [
      showToastMessage(i18n.t(TransKeys.TOASTS.EDIT_SCHEDULE_ANALYSIS_ERROR), ToastType.ERROR),
    ],
  },
  HttpClient
);

export const deleteScheduleAnalysisEpic: Epic = createRequestEpic(
  {
    types: [AnalysesActionType.DELETE_SCHEDULE_ANALYSIS],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.DELETE_SCHEDULE_ANALYSIS,
    request: deleteScheduleAnalysisNetworkRequest,
    onSuccess: (analysis: Analysis, payload) => [
      notifyEvent(AmplitudeEvent.DELETE_SCHEDULE_ANALYSIS, {
        id: analysis.id,
        schedule: analysis.cronExp,
      }),
      showToastMessage(
        i18n.t(TransKeys.TOASTS.DELETE_SCHEDULE_ANALYSIS_SUCCESS),
        ToastType.SUCCESS
      ),
      modelUpdated(
        {id: payload.analysisResultId, cronExp: analysis.cronExp},
        ModelKey.ANALYSIS_RESULT
      ),
    ],
    onError: (err, payload) => [
      showToastMessage(i18n.t(TransKeys.TOASTS.DELETE_SCHEDULE_ANALYSIS_ERROR), ToastType.ERROR),
    ],
  },
  HttpClient
);

export const changeAnalysisParametersEpic: Epic = createRequestEpic(
  {
    types: [AnalysesActionType.CHANGE_ANALYSIS_PARAMETERS],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.CHANGE_ANALYSIS_PARAMETERS,
    request: changeAnalysisParametersNetworkRequest,
    onSuccess: (_, payload) => [
      notifyEvent(AmplitudeEvent.ANALYSIS_PARAMETERS_CHANGED, {
        id: payload.analysisId,
      }),
      showToastMessage(
        i18n.t(TransKeys.TOASTS.ANALYSIS_PARAMETERS_CHANGED_SUCCESSFULLY),
        ToastType.SUCCESS
      ),
    ],
    onError: (err, payload) => [
      showToastMessage(i18n.t(TransKeys.TOASTS.ANALYSIS_PARAMETERS_CHANGE_ERROR), ToastType.ERROR),
    ],
  },
  HttpClient
);

export const stopAnalysisEpic: Epic = createRequestEpic(
  {
    types: [AnalysesActionType.STOP_ANALYSIS_RESULT],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.STOP_ANALYSIS_RESULT,
    request: stopAnalysisResultNetworkRequest,
    onSuccess: (analysisResult, payload, {metadata}) => [
      notifyEvent(AmplitudeEvent.ANALYSIS_RESULT_STOP, {
        id: payload.analysisId,
      }),
      modelUpdated(analysisResult, ModelKey.ANALYSIS_RESULT),
      analysisToastCreator('STOP_SUCCESS'),
    ],
    onError: err => [analysisToastCreator('STOP_ERROR')],
  },
  HttpClient
);

export const editAnalysisEpic: Epic = createRequestEpic(
  {
    types: [AnalysesActionType.EDIT_ANALYSIS],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.EDIT_ANALYSIS,
    request: editAnalysisNetworkRequest,
    onSuccess: (analysisResult, payload) => [
      notifyEvent(AmplitudeEvent.ANALYSIS_EDIT, {
        id: payload.analysisId,
      }),
      analysisToastCreator('UPDATE_SUCCESS'),
      modelUpdated(analysisResult, ModelKey.ANALYSIS_RESULT),
      replace(AppRoutes.viewAnalysis(payload.analysisId)),
    ],
    onError: err => [analysisToastCreator('UPDATE_ERROR')],
  },
  HttpClient
);

export const quickRunForAdminsEpic: Epic = createRequestEpic(
  {
    types: [AnalysesActionType.QUICK_RUN_FOR_ADMINS],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.QUICK_RUN_FOR_ADMINS,
    request: quickRunForAdminsNetworkRequest,
    onSuccess: (analysisResult, payload) => [showToastMessage('Success', ToastType.SUCCESS)],
    onError: err => [showToastMessage(`Error: ${err.data.message}`, ToastType.ERROR)],
  },
  HttpClient
);

export const deleteAnalysisResultEpic: Epic = createRequestEpic(
  {
    types: [AnalysesActionType.DELETE_ANALYSIS_RESULT],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.DELETE_ANALYSIS_RESULT,
    request: deleteAnalysisResultNetworkRequest,
    onSuccess: (res, payload) => {
      const isDeleted = res.deletedAt !== null;
      const actions = [
        notifyEvent(
          isDeleted
            ? AmplitudeEvent.DELETE_ANALYSIS_RESULT
            : AmplitudeEvent.RESTORE_ANALYSIS_RESULT,
          {
            id: payload,
          }
        ),
        analysisToastCreator('UPDATE_SUCCESS'),
        modelUpdated(res, ModelKey.ANALYSIS_RESULT),
      ];
      // for lists
      if (isDeleted) {
        actions.push(modelDeleted(res, ModelKey.ANALYSIS_RESULT));
      }
      return actions;
    },
    onError: err => [analysisToastCreator('UPDATE_ERROR')],
  },
  HttpClient
);

export const reranAnalysisResultEpic: Epic = createRequestEpic(
  {
    types: [AnalysesActionType.RERUN_ANALYSIS_RESULT],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.RERUN_ANALYSIS_RESULT,
    request: rerunAnalysisResultNetworkRequest,
    onSuccess: (analysisResult, payload, {metadata}) => [
      notifyEvent(AmplitudeEvent.ANALYSIS_RESULT_RERUN, {
        id: payload.analysisResultId,
        source: metadata?.[SOURCE_META_KEY],
      }),
      analysisToastCreator('RERUN_SUCCESS'),
      modelUpdated(analysisResult, ModelKey.ANALYSIS_RESULT),
    ],
    onError: err => [analysisToastCreator('RERUN_ERROR')],
  },
  HttpClient
);

export const moveAnalysisToFolderEpic: Epic = createRequestEpic(
  {
    types: [AnalysesActionType.MOVE_ANALYSIS_TO_FOLDER],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.MOVE_ANALYSIS_TO_FOLDER,
    request: moveAnalysisToFolderNetworkRequest,
    onSuccess: (analysis: Analysis, payload) => [
      notifyEvent(AmplitudeEvent.ANALYSIS_MOVED_TO_FOLDER, {
        analysisId: payload.analysisId,
        analysisFolderId: payload.analysisFolderId,
      }),
      showToastMessage(
        i18n.t(TransKeys.TOASTS.CREATE_SCHEDULE_ANALYSIS_SUCCESS),
        ToastType.SUCCESS
      ),
      modelUpdated(
        {id: payload.analysisId, analysisFolderId: payload.analysisFolderId},
        ModelKey.ANALYSIS
      ),
    ],
    onError: (err, payload) => [
      showToastMessage(i18n.t(TransKeys.TOASTS.ANALYSIS_MOVED_TO_FOLDER_ERROR), ToastType.ERROR),
    ],
  },
  HttpClient
);
