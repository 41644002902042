import classes from './secondary-text.module.scss';
import classNames from 'classnames';

interface OwnProps {
  text: string;
  className?: string;
}

type AllProps = OwnProps;

export const SecondaryText = ({className, text}: AllProps) => (
  <span className={classNames(classes.SecondaryText, className)}>{text}</span>
);
