import {useCallback, useMemo, useState} from 'react';
import classNames from 'classnames';
import {composition, number2k, safeDivision} from 'front-core';
import {
  withPartialDocument,
  WithPartialDocumentInjectedProps,
} from '../../../../../../shared/core/document-viewer/with-partial-document-hoc/with-partial-document.hoc';
import {
  Checkbox,
  DocumentElementType,
  GraphIcon,
  HoverHelperTip,
  KPIOverviewFigure,
  KPISegmentationFigure,
  KPISegmentationType,
  LineChart,
  Select,
  TooltipIfOverflow,
  TrendChip,
  useLocalStorage,
  UserGroupLightIcon,
} from 'ui-components';
import {get, groupBy, isArray, take, values} from 'lodash';
import classes from './kpi-segmentation.module.scss';
import pluralize from 'pluralize';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';

interface OwnProps extends WithPartialDocumentInjectedProps {
  className?: string;
}

const INSIGNIFICANT_GROUP = [
  KPISegmentationType.INSIGNIFICANT,
  KPISegmentationType.INSIGNIFICANT_POSITIVE,
  KPISegmentationType.INSIGNIFICANT_NEGATIVE,
];

type AllProps = OwnProps;

// export const isSignificantValue = (value: number, lower: number, upper: number) =>
//   exists(upper) && exists(lower) ? value > upper || value < lower : false;
//
// const GOOD_COLOR = 'green';
// const BAD_COLOR = 'red';

const KPISegmentationComponent = (props: AllProps) => {
  const {analysisResult, elementData, className} = props;
  const {
    [DocumentElementType.KPI_SEGMENTATION_FIGURE]: kpiSegmentationFigure,
    [DocumentElementType.KPI_OVERVIEW_FIGURE]: kpiOverviewFigure,
  } = elementData;
  const {t} = useTranslation();
  const segmentationFigure = kpiSegmentationFigure as unknown as KPISegmentationFigure;
  const overviewFigure = kpiOverviewFigure as unknown as KPIOverviewFigure;
  const {data: segmentationFigureData, options: segmentationFigureOptions} = segmentationFigure;
  const {data: overviewFigureData, options: overviewFigureOptions} = overviewFigure;
  const [selectedSignalId, setSelectedSignalId] = useLocalStorage(
    `${analysisResult.id}_kpi_segmentation`,
    null
  );
  const segments = useMemo(
    () =>
      segmentationFigureData.items.map(s => ({
        ...s,
        signalId: s.signalId[0],
        segmentName: s.segmentName[0],
        segmentGroupName: s.segmentGroupName[0],
        uplift: safeDivision(s.goalInSegment - s.goalInNonSegment, s.goalInNonSegment, true),
        share: s.count / segmentationFigureData.totalEntities,
        notSignificant: INSIGNIFICANT_GROUP.indexOf(s.type) > -1 || s.isInformative === false,
      })),
    [segmentationFigureData]
  );
  const groupedSegments = useMemo(() => {
    const groups = groupBy(segments, 'signalId');
    for (const k in groups) {
      groups[k] = groups[k].sort((a, b) => {
        return Number(a.notSignificant) - Number(b.notSignificant) || b.share - a.share;
      });
    }
    return groups;
  }, [segments]);
  const [selectedSegments, setSelectedSegments] = useState<string[]>(
    // @ts-ignore
    selectedSignalId ? [get(groupedSegments, `${selectedSignalId}.0.segmentName`)] : []
  );
  const selectedSegmentsSet = useMemo(() => new Set(selectedSegments), [selectedSegments]);
  const onSegmentClicked = useCallback(
    segment => {
      if (selectedSegmentsSet.has(segment.segmentName)) {
        selectedSegmentsSet.delete(segment.segmentName);
      } else {
        selectedSegmentsSet.add(segment.segmentName);
      }
      setSelectedSegments(Array.from(selectedSegmentsSet));
    },
    [selectedSegmentsSet, setSelectedSegments]
  );
  const onSegmentGroupSelected = useCallback(
    (signalId: number) => {
      setSelectedSignalId(signalId);
      setSelectedSegments([groupedSegments[signalId][0].segmentName]);
    },
    [setSelectedSignalId, setSelectedSegments, groupedSegments]
  );
  const selectedGroup = useMemo(() => {
    if (!selectedSignalId) {
      return null;
    }
    return groupedSegments[selectedSignalId];
  }, [groupedSegments, selectedSignalId]);
  const segmentOptions = useMemo(
    () =>
      values(groupedSegments).map(g => ({
        value: g[0].signalId,
        label: g[0].segmentGroupName,
      })),
    [groupedSegments]
  );
  const chartProps = useMemo(() => {
    const datasets = [];
    let segments: any[];
    if (segmentationFigure.version >= 1.1) {
      segments = segmentationFigure.data.items;
      datasets.push({
        id: 'kpi',
        label: segmentationFigure.options.kpiName,
        data: segmentationFigure.data.allPopulation.map(s => ({
          x: s.datetime,
          y: overviewFigureOptions.isPercentageValue ? s.value * 100 : s.value,
          dashed: s.isPartial,
          clickable: !s.isPartial,
        })),
      });
    } else {
      segments = overviewFigureData.items;
    }
    segments = segments.filter(
      i =>
        selectedSegmentsSet.has(isArray(i.segmentName) ? i.segmentName[0] : i.segmentName) &&
        (isArray(i.signalId) ? i.signalId[0] : i.signalId) === selectedSignalId
    );
    if (segments.length === 0 && datasets.length === 0) {
      return;
    }
    for (const segmentData of segments) {
      const {samples} = segmentData;
      const segmentDS = samples.map(s => {
        // const isSignificant = isSignificantValue(s.value, s.lower, s.upper);
        // let markColor = undefined;
        // let pointTooltipTitle = undefined;
        // if (isSignificant && !s.isPartial) {
        //   if (s.value > s.upper) {
        //     markColor = GOOD_COLOR;
        //     pointTooltipTitle = t(TransKeys.HOMEPAGE.METRIC_CHART.LABELS.INVESTIGATE_RISE_TITLE);
        //   } else {
        //     markColor = BAD_COLOR;
        //     pointTooltipTitle = t(TransKeys.HOMEPAGE.METRIC_CHART.LABELS.INVESTIGATE_DROP_TITLE);
        //   }
        // }
        return {
          x: s.datetime,
          y: overviewFigureOptions.isPercentageValue ? s.value * 100 : s.value,
          dashed: s.isPartial,
          clickable: !s.isPartial,
          // upper: overviewFigureOptions.isPercentageValue ? s.upper * 100 : s.upper,
          // lower: overviewFigureOptions.isPercentageValue ? s.lower * 100 : s.lower,
          // markColor,
          // pointTooltipTitle,
        };
      });
      datasets.push({
        id: segmentData.key,
        label: segmentData.segmentName,
        data: segmentDS,
      });
    }
    const displayedDatasetIds = take(datasets, 5).map(ds => ds.key);
    return {
      datasets: datasets,
      displayedDatasetIds: displayedDatasetIds,
      options: {
        labels: {
          dateFormat: 'DD MMM',
          type: 'date',
          timeUnit: overviewFigureData.granularity,
        },
        yLabelSuffix: overviewFigureOptions.isPercentageValue ? '%' : undefined,
        yAxisMaxTicks: 5,
        minimalXAxisTicks: true,
        errorBar: true,
        showHideAnnotation: false,
        showHideCI: false,
        xLabel: 'date',
      },
    } as any;
  }, [
    overviewFigureOptions,
    overviewFigureData,
    selectedSegmentsSet,
    selectedSignalId,
    segmentationFigure,
  ]);

  return (
    <div className={classNames(classes.KPISegmentation, className)}>
      <div className={classes.Main}>
        <div className={classes.Left}>
          <div className={classes.SegmentSelector}>
            <Select
              dropdownButtonClassName={classes.SelectGroup}
              placeholder={'Select'}
              prefix={'Segment Group'}
              value={selectedSignalId}
              onChange={onSegmentGroupSelected as any}
              options={{options: segmentOptions}}
              searchable={segmentOptions.length > 5}
              icon={UserGroupLightIcon}
              clearable={false}
              fullWidth
            />
          </div>
          <div className={classes.List}>
            <div className={classes.TableHeader} key={'header'}>
              <div className={classes.THead}>Name</div>
              <div className={classes.THead}>KPI</div>
              <div className={classes.THead}>Share</div>
            </div>
            {selectedGroup?.map(s => (
              <div
                className={classNames(
                  classes.Segment,
                  s.notSignificant && classes.NotSignificant,
                  selectedSegmentsSet.has(s.segmentName) && classes.Selected
                )}
                key={s.key}
              >
                <div className={classes.Name}>
                  <Checkbox
                    className={classes.Checkbox}
                    checked={selectedSegmentsSet.has(s.segmentName)}
                    onChange={() => onSegmentClicked(s)}
                    multi
                  />
                  <TooltipIfOverflow title={s.segmentName}>
                    <span className={classes.Text}>{s.segmentName}</span>
                  </TooltipIfOverflow>
                  {s.helperText && <HoverHelperTip title={s.helperText} small />}
                </div>
                <div className={classes.KPIValue}>
                  <span>
                    {segmentationFigureOptions.isPercentageValue
                      ? `${number2k(s.goalInSegment * 100)}%`
                      : number2k(s.goalInSegment)}
                  </span>
                  <TrendChip value={s.uplift * 100} size={'xsmall'} />
                </div>
                <div className={classes.Share}>
                  <span className={classes.Text}>
                    {number2k(s.share * 100)}% ({number2k(s.count)}{' '}
                    {pluralize(segmentationFigureOptions.entity)})
                  </span>
                  <div className={classes.Bar} style={{width: `${s.share * 100}%`}} />
                </div>
              </div>
            ))}
            {!selectedGroup && (
              <div className={classes.NoGroupEmptyState}>
                <div className={classes.Icon}>
                  <UserGroupLightIcon />
                </div>
                {t(TransKeys.KPI_SEGMENTATION_FRAGMENT.EMPTY_STATES.SELECT_SEGMENT)}
              </div>
            )}
          </div>
        </div>
        <div className={classes.Chart}>
          {chartProps && <LineChart {...chartProps} key={selectedSignalId} />}
          {!chartProps && (
            <div className={classes.EmptyState}>
              <div className={classes.Icon}>
                <GraphIcon />
              </div>
              Select segment to view KPI over time
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const KPISegmentation = composition<AllProps>(
  KPISegmentationComponent,
  withPartialDocument({
    elementType: [
      DocumentElementType.KPI_SEGMENTATION_FIGURE,
      DocumentElementType.KPI_OVERVIEW_FIGURE,
    ],
    required: true,
    emptyStateTextKey: TransKeys.KPI_SEGMENTATION_FRAGMENT.EMPTY_STATES.RESULT_IN_PROGRESS,
  })
);
