import classes from '../app-announcement-bar.module.scss';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import ClearIcon from '@material-ui/icons/Clear';
import {ReactNode, useMemo} from 'react';
import {useLocalStorage} from 'ui-components';
import {useProductData} from '../../../../../core/hooks/use-product-data.hook';
import {AccountStatus} from '../../../../../objects/models/product-data.model';
import TransKeys from 'translations';

const POC_DAYS_REMAINING_ANNOUNCEMENT_TRESHOLD = 7;
const ANNOUNCEMENT_DISMISS_STORAGE_KEY = 'announcement-dismiss';

function usePocEndAnnouncement(
  accountStatus: AccountStatus,
  accountPocEndDate?: string
): {content?: ReactNode; dismissable?: boolean} {
  const {t} = useTranslation();

  return useMemo(() => {
    if (!accountPocEndDate || accountStatus !== AccountStatus.POC) {
      return {};
    }

    const today = moment().startOf('day');
    const endDate = moment.utc(accountPocEndDate, 'YYYY-MM-DD').endOf('day');
    const datePassed = endDate.isBefore(moment());
    const daysRemaining = endDate.diff(today, 'days');
    const daysRemainingString = endDate.fromNow();

    let content = null;
    let dismissable: boolean = true;

    if (datePassed) {
      content = <span>{t(TransKeys.APP_ANNOUNCEMENT.TRIAL_PERIOD.TRIAL_ENDED)}</span>;
      dismissable = false;
    } else if (daysRemaining === 1) {
      content = (
        <span>
          {t(TransKeys.APP_ANNOUNCEMENT.TRIAL_PERIOD.TRIAL_ENDS)}
          &nbsp;
          <span className={classes.Bold}>{t(TransKeys.APP_ANNOUNCEMENT.TRIAL_PERIOD.TODAY)}</span>
        </span>
      );
      dismissable = true;
    } else if (daysRemaining <= POC_DAYS_REMAINING_ANNOUNCEMENT_TRESHOLD) {
      content = (
        <span>
          {t(TransKeys.APP_ANNOUNCEMENT.TRIAL_PERIOD.TRIAL_ENDS)}
          &nbsp;
          <span className={classes.Bold}>{daysRemainingString}</span>
        </span>
      );
      dismissable = true;
    }

    return {content, dismissable};
  }, [t, accountStatus, accountPocEndDate]);
}

export const PocAnnouncement = () => {
  const {accountStatus, accountPocEndDate, productId} = useProductData();

  const announcementDismissTTL = useMemo(() => moment().local().endOf('day').valueOf(), []);
  const [dismissed, setDismiss] = useLocalStorage(
    `${ANNOUNCEMENT_DISMISS_STORAGE_KEY}-${productId}`,
    false,
    announcementDismissTTL
  );

  const {content, dismissable} = usePocEndAnnouncement(accountStatus, accountPocEndDate);

  if ((dismissed && dismissable) || !content) {
    return null;
  }

  return (
    <div className={classes.AnnouncementBar}>
      {content}
      {dismissable && <ClearIcon className={classes.Dismiss} onClick={() => setDismiss(true)} />}
    </div>
  );
};
