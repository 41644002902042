import * as Sentry from '@sentry/react';
import appConfig from './app.config';

export const initSentry = () => {
  if (appConfig.sentryDsn) {
    Sentry.init({
      dsn: appConfig.sentryDsn,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        Sentry.httpClientIntegration(),
        Sentry.captureConsoleIntegration({
          levels: ['error'],
        }),
      ],
      tracesSampleRate: 1,
    });
  }
};

export const setSentryUser = (id, isInternalUser, scopeProductId) => {
  Sentry.setUser({id, isInternalUser, scopeProductId});
};
