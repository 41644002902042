import {
  RCAInsightSentence,
  SpikeDropOverUnderPerformingSegmentShareIncreaseDecrease,
} from './objects';
import sharedClasses from '../insights.module.scss';
import {
  ChangeInShareOfEntitiesSentencePart,
  SegmentNameSentencePart,
} from '../components/shared-sentence-parts/shared-sentence-parts.components';

interface OwnProps extends RCAInsightSentence {
  formattedSentenceParts: SpikeDropOverUnderPerformingSegmentShareIncreaseDecrease;
}

type AllProps = OwnProps;

const SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseFormatter = (props: AllProps) => {
  const {formattedSentenceParts, sentenceParts} = props;
  const {entity, entityChangeDescription, changeInShareOfEntities, presentTensePerformanceVsAvg} =
    formattedSentenceParts;
  const {segmentViewData} = sentenceParts;

  return (
    <div className={sharedClasses.InsightSentenceContainer}>
      <SegmentNameSentencePart data={segmentViewData} />
      <span className={sharedClasses.InsightSentencePart}>
        {`share of ${entity} ${entityChangeDescription}d by`}
      </span>
      <ChangeInShareOfEntitiesSentencePart changeInShareOfEntities={changeInShareOfEntities} />
      <span className={sharedClasses.InsightSentencePart}>
        {`vs the analyzed period. This segment is ${presentTensePerformanceVsAvg} vs the average.`}
      </span>
    </div>
  );
};

export {SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseFormatter};
