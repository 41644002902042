import classes from '../user-specific-kpis-step.module.scss';
import classNames from 'classnames';
import {StepTitle} from '../../../components/step-title/step-title.component';
import TransKeys from 'translations';
import {capitalize} from 'front-core';
import {Controller, useFormContext} from 'react-hook-form';
import {Button, Select, EnumInputOptions} from 'ui-components';
import {useTranslation} from 'react-i18next';
import {UserKPITypeOption} from '../../consts';
import {useMemo} from 'react';
import {values} from 'lodash';

export interface SpecificKPIGroup {
  name: UserKPITypeOption;
  icon: any;
  iconClassName: string;
  options: EnumInputOptions;
}

interface OwnProps {
  isSubmittingQuestionnaire: boolean;
  groupedMetrics: SpecificKPIGroup[];
  onSubmit: (value: any) => void;
  onSelectChange: (metricId: number, groupName: UserKPITypeOption) => void;
  onSkipClick: () => void;
  className?: string;
}
type AllProps = OwnProps;

export const SpecificKpisGroups = (props: AllProps) => {
  const {
    isSubmittingQuestionnaire,
    className,
    onSubmit,
    groupedMetrics,
    onSelectChange,
    onSkipClick,
  } = props;
  const {t} = useTranslation();
  const {handleSubmit, watch, control} = useFormContext();
  const userSpecificKpis = watch('userSpecificKpis');
  const disabled = useMemo(
    () => isSubmittingQuestionnaire || values(userSpecificKpis).some(Boolean) === false,
    [isSubmittingQuestionnaire, userSpecificKpis]
  );

  return (
    <div className={classNames(classes.UserKPIDeepDiveStep, className)}>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.Form}>
        <StepTitle
          title={t(TransKeys.QUESTIONNAIRE.KPIS_DEEP_DIVE_STEP.TITLE)}
          subTitle={t(TransKeys.QUESTIONNAIRE.KPIS_DEEP_DIVE_STEP.SUB_TITLE)}
        />
        <div className={classes.Options}>
          {groupedMetrics.map((group: SpecificKPIGroup) => (
            <div key={group.name} className={classes.Option}>
              <div className={classes.IconWrapper}>
                <group.icon className={classNames(classes.Icon, group.iconClassName)} />
                <span className={classes.Name}>{capitalize(group.name)}</span>
              </div>
              <div className={classes.Label}>
                <Controller
                  render={({field}) => (
                    <Select
                      className={classes.Select}
                      onChange={metricId => {
                        field.onChange(metricId);
                        onSelectChange(metricId as number, group.name);
                      }}
                      dropdownButtonClassName={classes.Input}
                      value={userSpecificKpis[group.name]}
                      options={group.options}
                      clearable={false}
                      searchable={false}
                      sortValues={false}
                      placeholder={'Select KPI'}
                      fullWidth
                    />
                  )}
                  name={'userSpecificKpis'}
                  control={control}
                />
              </div>
            </div>
          ))}
        </div>
        <div className={classes.Spacer} />
        <Button
          disabled={disabled}
          onClick={handleSubmit(onSubmit)}
          type={'submit'}
          className={classNames(classes.Submit, disabled && classes.Disabled)}
        >
          {t(TransKeys.QUESTIONNAIRE.ACTIONS.CONTINUE_LAST_STEP)}
        </Button>
        <div onClick={handleSubmit(onSkipClick)} className={classes.Skip}>
          {t(TransKeys.QUESTIONNAIRE.ACTIONS.KPIS_DEEP_DIVE_SKIP)}
        </div>
      </form>
    </div>
  );
};
