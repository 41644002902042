import {useCallback, useContext, useMemo, useState} from 'react';
import {composition} from 'front-core';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {getMetricNetworkRequest} from '../../../../http/metrics.network-requests';
import {ANALYSIS_TYPE_ID_PATH_PARAM, METRIC_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {
  Button,
  Chip,
  ChipVariant,
  CircleInfoRegularIcon,
  FancyHeader,
  KPIIcon,
  LabelWrapper,
  ModalLayout,
  ModelType,
  PrimaryTab,
  PrimaryTabs,
  TeamIconChip,
  TextInput,
} from 'ui-components';
import TransKeys from '../../../../constants/translation-keys';
import {Metric, MetricType, MetricValueType} from '../../../../objects/models/metric.model';
import classes from './metric-view-panel.module.scss';
import {useTranslation} from 'react-i18next';
import {Modifiers} from '../../../shared/components/general/modifiers/modifiers.component';
import {TitleWithIcon} from '../../../shared/components/general/title/title.component';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {withModalErrorHandler} from '../../../../core/hoc/with-model-error-handler.hoc';
import {get} from 'lodash';
import {useDispatch} from 'react-redux';
import {getSelected} from '../../../../store/selected/selected.actions';
import {publishAdHocMetricConfirmed} from '../../../../store/metrics/metrics.actions';
import {AdHocIndication} from '../../../shared/components/general/ad-hoc-indication/ad-hoc-indication.component';
import usePermissions from '../../../../core/hooks/use-permissions.hook';
import {Action, Subject} from '../../../../constants/permissions';
import QueryDefinition from '../../../shared/components/general/query-definition/query-definition.component';
import SignalSql from '../../../shared/components/general/signal-sql/signal-sql.component';
import {ViewModelSeries} from '../../components/view-model-series/view-model-series.component';
import {ModelSampleSeriesModel} from '../../../../objects/models/model-sample-series.model';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {PanelKey} from '../../../../constants/panels';
import {AnalysisTypeId} from '../../../../constants/analysis-type-id';
import {PopulationSegmentQueryBuilder} from '../../../shared/core/query-builders/population-segment-query-builder/population-segment-query-builder.component';
import {QuerySqlTabsNames} from '../../../shared/core/query-sql-tabs/query-sql-tabs.component';
import {AggregationMode} from '../../../../objects/models/signal.model';
import {getEntityIcon} from '../../../../constants/entity.consts';

interface OwnProps {
  metric: Metric;
  onClose?: () => void;
  initialTab?: QuerySqlTabsNames;
}

type AllProps = OwnProps;

const SELECTED_METRIC_KEY = SharedSelectionKeys.METRIC_VIEW__METRIC;

export const MetricViewPanelComponent = (props: AllProps) => {
  const {onClose, metric, initialTab} = props;
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {productEntitiesMap} = useProductData();
  const {openPrimaryPanel} = useContext(PanelsContext);
  const {can} = usePermissions();
  const [selectedTabKey, setSelectedTabKey] = useState(initialTab || 'query');
  const onPublish = useCallback(
    () =>
      dispatch(
        publishAdHocMetricConfirmed(metric?.id, () => [
          getSelected(SELECTED_METRIC_KEY, metric?.id),
        ])
      ),
    [dispatch, metric?.id]
  );
  const onRunKPIAnalysis = useCallback(() => {
    if (!metric) {
      return;
    }
    openPrimaryPanel(PanelKey.ANALYSIS_FORM_PANEL, {
      [ANALYSIS_TYPE_ID_PATH_PARAM]: AnalysisTypeId.KPI_ANALYSIS,
      parameters: {
        goal: metric.signalId,
        entity: metric.entity,
        analysis_mode: metric.type === MetricType.USAGE ? AggregationMode.SUM : undefined,
      },
    });
  }, [openPrimaryPanel, metric]);
  const tabs: PrimaryTab[] = useMemo(
    () => [
      {
        label: 'Definition',
        key: 'query',
        render: () => (
          <div className={classes.TabWrapper}>
            <QueryDefinition query={metric.signalDefinition} />
            {metric.signalPopulationFilter && (
              <LabelWrapper
                className={classes.SegmentationFilter}
                label={
                  <div className={classes.SegmentationFilterLabel}>
                    <span>{t(TransKeys.METRIC_FORM_PANEL.INPUTS.SEGMENTATION_FILTER.LABEL)}</span>
                    <Chip label={'Beta'} variant={ChipVariant.NEW} size={'xsmall'} />
                  </div>
                }
                helperText={
                  <div className={classes.SegmentationFilterHelperText}>
                    <CircleInfoRegularIcon />
                    {t(TransKeys.METRIC_FORM_PANEL.INPUTS.SEGMENTATION_FILTER.HELPER_TEXT)}
                  </div>
                }
              >
                <PopulationSegmentQueryBuilder query={metric.signalPopulationFilter} disabled />
              </LabelWrapper>
            )}
            {metric.aggregationMode && (
              <LabelWrapper
                className={classes.AggregationModeContainer}
                label={t(TransKeys.METRIC_FORM_PANEL.INPUTS.AGGREGATION_MODE.LABEL)}
              >
                <TextInput
                  disabled={true}
                  value={
                    t(
                      TransKeys.ANALYSIS_FORMS.ANALYSIS_101.ANALYSIS_MODES[
                        metric.aggregationMode.toUpperCase()
                      ]?.LABEL
                    ) as any
                  }
                />
              </LabelWrapper>
            )}
          </div>
        ),
      },
      {
        label: 'SQL',
        key: 'sql',
        render: () => (
          <div className={classes.TabWrapper}>
            <SignalSql signalId={metric.signalId} />
          </div>
        ),
      },
      {
        label: `Values (${metric.totalWarnsCount} Warnings)`,
        key: 'samples',
        render: () => (
          <div className={classes.TabWrapper}>
            <ViewModelSeries
              modelType={ModelSampleSeriesModel.METRIC}
              modelId={metric.id}
              entity={metric.entity}
              createdOn={metric.createdOn}
              onRunKPIAnalysis={onRunKPIAnalysis}
              isPercentageValue={metric.valueType === MetricValueType.PERCENTAGE}
            />
          </div>
        ),
      },
    ],
    [metric, onRunKPIAnalysis, t]
  );

  return (
    <div className={classes.MetricViewPanelContainer}>
      <ModalLayout
        footer={
          metric.isAdHoc &&
          can(Subject.METRIC, Action.CREATE) && (
            <Button
              caps={false}
              helperText={t(TransKeys.GENERAL.ACTIONS.SAVE_TO_DMP_HELPER_TEXT, {
                model: t(TransKeys.MODELS.METRIC),
              })}
              onClick={onPublish}
            >
              {t(TransKeys.GENERAL.ACTIONS.SAVE_TO_DMP, {
                model: t(TransKeys.MODELS.METRIC),
              })}
            </Button>
          )
        }
      >
        <div className={classes.MetricViewPanel}>
          <FancyHeader
            icon={KPIIcon}
            title={
              <>
                {t(TransKeys.MODELS.METRIC)}
                {metric.isAdHoc && (
                  <AdHocIndication className={classes.AdHocIndication} model={ModelType.METRIC} />
                )}
              </>
            }
            onClose={onClose}
            className={classes.Header}
          />
          <div className={classes.Body}>
            <div className={classes.Head}>
              <div className={classes.Title}>
                <div className={classes.Name}>
                  {metric.categoryId && (
                    <span className={classes.Category}>{metric.categoryName} /</span>
                  )}
                  {metric.name}
                </div>
                <div className={classes.Teams}>
                  {metric.teams.map(team => (
                    <TeamIconChip {...team} size={'medium'} key={team.id} showTooltip />
                  ))}
                </div>
              </div>
              {metric.shortDescription && (
                <div className={classes.Description}>{metric.shortDescription}</div>
              )}
            </div>
            {metric.entity && (
              <div className={classes.Block}>
                <LabelWrapper label={t(TransKeys.GENERAL.LABELS.ENTITY)}>
                  <TitleWithIcon
                    text={productEntitiesMap[metric.entity].name}
                    icon={getEntityIcon(metric.entity)}
                  />
                </LabelWrapper>
              </div>
            )}
            <div className={classes.Block}>
              <PrimaryTabs
                selected={selectedTabKey}
                onChange={setSelectedTabKey}
                sticky
                tabs={tabs}
              />
            </div>
            {metric.history.length > 0 && (
              <div className={classes.Block}>
                <Modifiers history={metric.history} />
              </div>
            )}
          </div>
        </div>
      </ModalLayout>
    </div>
  );
};

const MetricViewPanel = composition<AllProps>(
  MetricViewPanelComponent,
  withModalErrorHandler({
    modalTitle: TransKeys.MODELS.METRIC,
    modalIcon: KPIIcon,
    extractErrorCodeFromProps: props => get(props.errors, 'metric.errorCode'),
  }),
  withLoadBefore({
    metric: {
      selectedKey: SELECTED_METRIC_KEY,
      actionKey: SELECTED_METRIC_KEY,
      request: getMetricNetworkRequest,
      mapPayloadFromProps: props => props[METRIC_ID_PATH_PARAM],
      shouldCall: props => props[METRIC_ID_PATH_PARAM] !== undefined,
    },
  })
);

export default MetricViewPanel;
