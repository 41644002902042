import {useCallback, useMemo} from 'react';
import classNames from 'classnames';
import {LiteralValueType, QueryBuilderFactory, Select, SqlElementType} from 'ui-components';
import {
  METADATA_KEY,
  PARAMETERS_METADATA_KEY,
} from '../../../../../constants/parameters-saved-keys';
import {SelectorModelType} from '../../../../shared/core/smart-selector/advanced-smart-selector.component';
import {TemplateItemQueryBuilder} from '../../../../shared/core/query-builders/template-item-query-builder/template-item-query-builder.component';
import classes from './behavioral-milestone-query-builder.module.scss';
import {
  TableEntity,
  TableEntityBinding,
  TableType,
} from '../../../../../objects/models/table.model';
import {
  createTableEventsInitialQuery,
  TableEventsQueryBuilder,
} from '../../../../shared/core/query-builders/table-events-query-builder/table-events-query-builder.component';
import {get} from 'object-path-immutable';
import {ComputedTemplatesNames} from '../../../../../constants/query-builder';

enum BehavioralMilestoneQueryBuilderTemplates {
  SIMPLE_FIRST_TIME = ComputedTemplatesNames.SIMPLE_FIRST_TIME,
  SIMPLE_LAST_TIME = ComputedTemplatesNames.SIMPLE_LAST_TIME,
}

interface OwnProps {
  query: any;
  onChange?: (query: any) => void;
  errors?: any;
  disabled?: boolean;
  entityContext: TableEntity;
  onSignalInfo: (value: string | number) => void;
  className?: string;
  signalFilters?: any;
}

type AllProps = OwnProps;

enum QueryConditionType {
  UNSET = 'unset',
  EVENT = 'event',
  TABLE_COLUMN = 'table_column',
}

const TEMPLATE_OPTIONS = {
  options: [
    {
      value: BehavioralMilestoneQueryBuilderTemplates.SIMPLE_FIRST_TIME,
      label: 'First Time',
    },
    {
      value: BehavioralMilestoneQueryBuilderTemplates.SIMPLE_LAST_TIME,
      label: 'Last Time',
    },
  ],
};

const TABLE_EVENTS_QUERY_PATH = 'parameters.0';
const TEMPLATE_NAME_PARAMETER_PATH = 'template';

function getQueryConditionType(condition) {
  if (condition?.type === SqlElementType.TABLE_COLUMN) {
    return QueryConditionType.TABLE_COLUMN;
  }
  if ([SqlElementType.TEMPLATE, SqlElementType.AND_CONDITION].includes(condition?.type)) {
    return QueryConditionType.EVENT;
  }
  return QueryConditionType.UNSET;
}

function createBehavioralMilestoneInitialQuery(initialCondition: any) {
  const conditionType = getQueryConditionType(initialCondition);
  if (conditionType !== QueryConditionType.EVENT) {
    return initialCondition;
  }
  const parameter_query = createTableEventsInitialQuery();
  parameter_query.conditions = [initialCondition];
  const q = QueryBuilderFactory.createTemplate(ComputedTemplatesNames.SIMPLE_FIRST_TIME);
  q.parameters = [parameter_query];
  return q;
}

function createEventFilters(entity: TableEntity, entityBinding: TableEntityBinding) {
  return {
    entityContext: entity,
    entityBinding: entityBinding,
  };
}

function createColumnFilters(entity: TableEntity, entityBinding: TableEntityBinding) {
  return {
    entityContext: entity,
    literalType: LiteralValueType.DATE,
    tableType: TableType.ENTITY_PROPERTIES,
    entityBinding: entityBinding,
  };
}

export const BehavioralMilestoneQueryBuilder = (props: AllProps) => {
  const {
    query,
    onChange: onChangeFromProps,
    errors,
    disabled,
    entityContext,
    onSignalInfo,
    className,
    signalFilters,
  } = props;
  const conditionType = useMemo(() => getQueryConditionType(query), [query]);
  const eventFilters = useMemo(
    () => createEventFilters(entityContext, TableEntityBinding.TWO_WAY),
    [entityContext]
  );
  const columnFilters = useMemo(
    () => createColumnFilters(entityContext, TableEntityBinding.DEFAULT),
    [entityContext]
  );
  const allowedTypes = useMemo(() => {
    return conditionType === QueryConditionType.EVENT
      ? [SelectorModelType.EVENT]
      : [SelectorModelType.EVENT, SelectorModelType.COLUMN];
  }, [conditionType]);
  // Callbacks
  const onChange = useCallback(
    query => {
      if (query) {
        query[PARAMETERS_METADATA_KEY] = {
          ...(query[PARAMETERS_METADATA_KEY] || {}),
          [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: 'BehavioralMilestoneQueryBuilder',
        };
      }
      onChangeFromProps && onChangeFromProps(query);
    },
    [onChangeFromProps]
  );

  const onTemplateChange = useCallback(
    (templateName: BehavioralMilestoneQueryBuilderTemplates) => {
      const newQuery = {
        ...query,
        template: templateName,
      };
      onChange(newQuery);
    },
    [query, onChange]
  );

  const onTableEventsChange = useCallback(
    queryUpdate => {
      const templateName = get(query, TEMPLATE_NAME_PARAMETER_PATH);
      let newQuery = QueryBuilderFactory.createTemplate(templateName);
      newQuery.parameters = [queryUpdate];
      newQuery = {
        ...query,
        ...newQuery,
      };
      onChange(newQuery);
    },
    [query, onChange]
  );

  const onQueryChange = useCallback(
    updateQuery => {
      const newQuery = createBehavioralMilestoneInitialQuery(updateQuery);
      onChange(newQuery);
    },
    [onChange]
  );

  const onClearClick = useCallback(() => onChange(null), [onChange]);

  return (
    <div
      className={classNames(
        classes.BehavioralMilestoneQueryBuilderContainer,
        disabled && classes.Disabled,
        className
      )}
    >
      <div className={classes.Builder}>
        {conditionType === QueryConditionType.EVENT && (
          <div className={classes.TableEventsQueryBuilderContainer}>
            <div className={classes.SelectTemplate}>
              <div>Take</div>
              <Select
                clearable={false}
                searchable={false}
                options={TEMPLATE_OPTIONS}
                onChange={v => onTemplateChange(v as BehavioralMilestoneQueryBuilderTemplates)}
                value={get(query, TEMPLATE_NAME_PARAMETER_PATH)}
                disabled={disabled}
              />
              <div>of</div>
              {!disabled && (
                <div className={classes.Clear} onClick={onClearClick}>
                  clear
                </div>
              )}
            </div>
            <TableEventsQueryBuilder
              query={get(query, TABLE_EVENTS_QUERY_PATH)}
              errors={errors}
              onChange={onTableEventsChange}
              disabled={disabled}
              filters={eventFilters}
              multiEvents={true}
            />
          </div>
        )}
        {conditionType === QueryConditionType.TABLE_COLUMN && (
          <TemplateItemQueryBuilder
            query={query}
            errors={errors}
            onChange={onQueryChange}
            disabled={disabled}
            allowTypes={allowedTypes}
            eventFilters={eventFilters}
            signalFilters={signalFilters}
            columnFilters={columnFilters}
            onSignalInfo={onSignalInfo}
          />
        )}
        {!query && (
          <div className={classes.AddEvent}>
            <TemplateItemQueryBuilder
              query={null}
              onChange={onQueryChange}
              eventFilters={eventFilters}
              signalFilters={signalFilters}
              columnFilters={columnFilters}
              allowTypes={allowedTypes}
              errors={errors}
              disabled={disabled}
              onSignalInfo={onSignalInfo}
            />
          </div>
        )}
      </div>
    </div>
  );
};
