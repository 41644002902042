import {useAnalysisResultList} from '../../../analyses/hooks/use-analysis-result-list.hook';
import {searchAnalysisResultsNetworkRequest} from '../../../../http/analysis-results.network-requests';
import {AnalysisResultStatus} from '../../../../objects/models/analysis-result.model';
import {exists} from 'front-core';
import {AnalysesListPlaceholder} from '../../../analyses/pages/analyses-main/components/general/analyses-list-placeholder/analyses-list-placeholder.component';
import {AnalysisResultList} from '../../../analyses/components/analysis-result-list/analysis-result-list.component';
import {useAnalysisResultActions} from '../../../analyses/hooks/use-analysis-result-actions.hook';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import classes from './recent-analyses.module.scss';
import {useCurrentUser} from '../../../../core/hooks/use-user.hook';
import {useMemo} from 'react';

interface OwnProps {
  className?: string;
}

type AllProps = OwnProps;

const DEFAULT_FILTERS_FOR_RECENT = (userId: number) => ({
  itemsPerPage: 5,
  isAutoGenerated: false,
  isSystemGenerated: false,
  or_filter_entries: [
    {
      status: AnalysisResultStatus.COMPLETED,
    },
    {
      requested_by_user_id: userId,
    },
  ],
});

export const RecentAnalyses = (props: AllProps) => {
  const {className} = props;
  const analysisResultActions = useAnalysisResultActions();
  const {t} = useTranslation();
  const user = useCurrentUser();
  const defaultFilters = useMemo(() => DEFAULT_FILTERS_FOR_RECENT(user.id), [user.id]);

  const {listsData, isLoading} = useAnalysisResultList({
    key: 'recent_results',
    extraDefaultFilters: defaultFilters,
    networkRequest: searchAnalysisResultsNetworkRequest,
  });

  if ((isLoading && !exists(listsData?.meta?.total)) || !exists(listsData)) {
    return <AnalysesListPlaceholder className={className} />;
  }

  return (
    <AnalysisResultList
      {...analysisResultActions}
      data={listsData.list}
      total={listsData.meta.total}
      page={listsData.meta.page}
      perPage={listsData.meta.numPerPage}
      isLoading={isLoading}
      showFolderColumn={false}
      showActionsColumn={false}
      showStar={false}
      emptyStateRow={
        <div className={classes.EmptyStateRow}>{t(TransKeys.ANALYSES.EMPTY_STATE.TITLE)}</div>
      }
    />
  );
};
