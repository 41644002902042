import {useCallback, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import classes from './analysis-info-tab.module.scss';
import yup from '../../../../../../config/yup.config';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {sharedClasses} from '../../../../../shared';
import {Button, useRemoteSource} from 'ui-components';
import TransKeys from '../../../../../../constants/translation-keys';
import {FormStep} from '../../../../../shared/components/layout/form-step/form-step.component';
import {
  TextareaFormInput,
  TextFormInput,
} from '../../../../../shared/form/components/shared-form-input.component';
import classNames from 'classnames';
import {TabHeader} from '../../../../../shared/components/general/tab-header/tab-header.component';
import {AnalysisResult} from '../../../../../../objects/models/analysis-result.model';
import {preventSubmitOnEnter} from '../../../../../../utils/general.utils';
import {generateDefaultAnalysisResultNameNetworkRequest} from '../../../../../../http/analysis-results.network-requests';
import {GenericLoading} from '../../../../../shared/components/general/generic-loading/generic-loading.component';
import {exists} from 'front-core';
import {AnalysisFolderSmartSelector} from '../../../../../shared/core/smart-selector/analysis-folder-smart-selector.component';
import {FormLabelInput} from '../../../../../shared/form/components/form-label-input.component';
import {PanelsContext} from '../../../../../../core/contexts/panels.context';
import {PanelKey} from '../../../../../../constants/panels';

interface OwnProps {
  onSubmit: (data: Partial<AnalysisResult>) => void;
  onBack?: () => void;
  data: Partial<AnalysisResult>;
  defaultFolderId?: number;
  submitText?: string;
  submitHelperText?: string;
  renameMode?: boolean;
  disabled?: boolean;
  className?: string;
}

type AllProps = OwnProps;

const validator = yup.object().shape({
  userAnalysisName: yup.string().required(),
  userNotes: yup.string().nullable(),
  analysisFolderId: yup.number().required(),
});

export const AnalysisInfoTab = (props: AllProps) => {
  const {
    data,
    defaultFolderId,
    onSubmit,
    onBack,
    submitText = 'Save',
    submitHelperText,
    renameMode,
    disabled,
    className,
  } = props;
  const {t} = useTranslation();
  const {openSecondaryPanel} = useContext(PanelsContext);
  const formMethods = useForm({
    defaultValues: data as any,
    resolver: yupResolver(validator.noUnknown()),
  });
  const {
    handleSubmit,
    watch,
    setValue,
    formState: {isSubmitting, isValidating},
  } = formMethods;
  const userAnalysisName = watch('userAnalysisName');
  const {exec, isLoading} = useRemoteSource({
    networkRequest: generateDefaultAnalysisResultNameNetworkRequest,
    type: 'source',
  });

  const getDefaultAnalysisResultNameFromAPI = useCallback(
    async (analysisTypeId: number) => {
      const name = await exec({analysisTypeId});
      setValue('userAnalysisName', name);
    },
    [exec, setValue]
  );
  const onCreateAnalysisFolder = useCallback(() => {
    openSecondaryPanel(PanelKey.ANALYSIS_FOLDER_FORM_PANEL, {
      onSuccess: analysisFolder => {
        setValue('analysisFolderId', analysisFolder.id);
      },
    });
  }, [openSecondaryPanel, setValue]);

  useEffect(() => {
    if (!exists(userAnalysisName)) {
      getDefaultAnalysisResultNameFromAPI(data?.analysisTypeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDefaultAnalysisResultNameFromAPI, data]);

  const buttonDisabled = Boolean(isValidating || isSubmitting || isLoading || disabled);

  return (
    <>
      {isLoading && <GenericLoading />}
      <FormProvider {...formMethods}>
        <form onKeyDown={preventSubmitOnEnter} className={sharedClasses.NoOverflow}>
          <FormStep
            footer={
              <>
                {onBack && (
                  <Button variant={'outlined'} type={'button'} onClick={onBack}>
                    {t(TransKeys.GENERAL.ACTIONS.BACK)}
                  </Button>
                )}
                <Button
                  onClick={buttonDisabled ? undefined : handleSubmit(onSubmit)}
                  disabled={buttonDisabled}
                  helperText={submitHelperText}
                >
                  {submitText}
                </Button>
              </>
            }
          >
            <div className={classNames(classes.AnalysisInfoTab, className)}>
              <TabHeader
                className={classes.Header}
                title={t(TransKeys.CREATE_ANALYSIS_FORM.STEPS.ANALYSIS_INFO_TITLE)}
                subTitle={t(TransKeys.CREATE_ANALYSIS_FORM.STEPS.ANALYSIS_INFO_SUBTITLE)}
              />
              <div className={classes.Block}>
                <TextFormInput
                  required
                  placeholder={t(TransKeys.CREATE_ANALYSIS_FORM.INPUTS.NAME.PLACEHOLDER)}
                  label={t(TransKeys.CREATE_ANALYSIS_FORM.INPUTS.NAME.LABEL)}
                  name={'userAnalysisName'}
                />
              </div>
              <div className={classes.Block}>
                <TextareaFormInput
                  placeholder={t(TransKeys.CREATE_ANALYSIS_FORM.INPUTS.NOTES.PLACEHOLDER)}
                  label={t(TransKeys.CREATE_ANALYSIS_FORM.INPUTS.NOTES.LABEL)}
                  name={'userNotes'}
                />
              </div>
              {!renameMode && (
                <div className={classes.Block}>
                  <FormLabelInput
                    label={t(TransKeys.CREATE_ANALYSIS_FORM.INPUTS.ANALYSIS_FOLDER.LABEL)}
                    name={'analysisFolderId'}
                    render={field => (
                      <AnalysisFolderSmartSelector
                        value={field.value}
                        placeholder={t(
                          TransKeys.CREATE_ANALYSIS_FORM.INPUTS.ANALYSIS_FOLDER.PLACEHOLDER
                        )}
                        defaultFolderId={defaultFolderId}
                        onChange={field.onChange}
                        onCreate={onCreateAnalysisFolder}
                      />
                    )}
                  />
                </div>
              )}
            </div>
          </FormStep>
        </form>
      </FormProvider>
    </>
  );
};
