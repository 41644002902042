import classNames from 'classnames';
import classes from './user-smart-selector-avatar.module.scss';
import {
  Avatar,
  MultiLoadResponse,
  PopoverWrapper,
  SmartSelector,
  SmartSelectorParameters,
  SmartSelectorSource,
  UserPlusLightIcon,
  CloseIcon,
} from 'ui-components';
import {useCallback, useContext, useMemo, useRef} from 'react';
import {exists, HttpClientContext, withStopPropagation} from 'front-core';
import {userSmartSelectorNetworkRequest} from '../../../../../http/smart-selector.network-requests';
import {Tooltip} from '@material-ui/core';

interface OwnProps {
  userName?: string;
  onChange: (value: number, item?: any) => void;
  clearText?: string;
  size?: 'small' | 'medium';
  tooltipText?: string;
  clearable?: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const UserSmartSelectorAvatar = (props: AllProps) => {
  const {
    userName,
    onChange: onChangeFromProps,
    clearable = true,
    clearText,
    size,
    tooltipText,
    className,
  } = props;
  const popoverRef = useRef<any>(null);
  const http = useContext(HttpClientContext);

  const onChange = useCallback(
    (value: number, item?: any) => {
      popoverRef.current.close();
      onChangeFromProps(value, item);
    },
    [onChangeFromProps]
  );

  const sources: SmartSelectorSource[] = useMemo(
    () => [
      {
        key: 'users',
        name: `Users`,
        onSelect: item => onChange(item.id, item),
      },
    ],
    [onChange]
  );

  const load = useCallback(
    async (keys: string[], parameters: SmartSelectorParameters): Promise<MultiLoadResponse> => {
      // @ts-ignore
      const res = await http.exec(
        userSmartSelectorNetworkRequest({
          page: parameters.page,
          q: parameters.query,
          limit: 10,
          orderBy: 'first_name',
          order: 'asc',
        })
      );
      res.data = res.data.map((user: any) => ({
        ...user,
        name: user.fullName,
      }));
      if (clearable && parameters.page === 1 && exists(userName)) {
        res.data.unshift({
          id: null,
          name: clearText || 'Clear',
          className: classes.ClearItem,
          icon: CloseIcon,
        });
      }
      return {
        users: res as any,
      };
    },
    [http, clearable, clearText, userName]
  );

  return (
    <PopoverWrapper
      ref={popoverRef}
      buttonRenderer={({isOpen, onClick}) => (
        <div
          className={classNames(classes.UserSmartSelectorAvatar, isOpen && classes.Open, className)}
        >
          {exists(userName) && (
            <Avatar
              name={userName}
              onClick={withStopPropagation(e => onClick(e))}
              variant={'secondary'}
              size={size}
            />
          )}
          {!exists(userName) && (
            <Tooltip title={tooltipText || ''} placement={'top'} interactive={false}>
              <div
                className={classNames(classes.Placeholder, classes[size])}
                onClick={withStopPropagation(e => onClick(e))}
              >
                <UserPlusLightIcon />
              </div>
            </Tooltip>
          )}
        </div>
      )}
    >
      <div
        className={classes.SelectorWrapper}
        /* Stop all click events from propagation */
        onClick={e => e.stopPropagation()}
      >
        <SmartSelector
          className={classes.Selector}
          sources={sources}
          load={load}
          withPreview={false}
        />
      </div>
    </PopoverWrapper>
  );
};
