export enum ModelKey {
  OPPORTUNITY = 'OPPORTUNITY',
  LOOP = 'LOOP',
  EXPERIMENT = 'EXPERIMENT',
  GOAL = 'GOAL',
  ANNOTATION = 'ANNOTATION',
  ANALYSIS = 'ANALYSIS',
  ANALYSIS_TYPE = 'ANALYSIS_TYPE',
  ANALYSIS_RESULT = 'ANALYSIS_RESULT',
  ANALYSIS_FOLLOWUP = 'ANALYSIS_FOLLOWUP',
  FEATURE = 'FEATURE',
  SEGMENT = 'SEGMENT',
  SEGMENT_CATEGORY = 'SEGMENT_CATEGORY',
  FUNNEL = 'FUNNEL',
  CONTENT = 'CONTENT',
  PHENOMENA = 'PHENOMENA',
  METRIC = 'METRIC',
  INPUT_METRIC = 'INPUT_METRIC',
  METRIC_TREE_NODE = 'METRIC_TREE_NODE',
  METRIC_CATEGORY = 'METRIC_CATEGORY',
  MILESTONE = 'MILESTONE',
  MONITORED_METRIC = 'MONITORED_METRIC',
  HEALTH_MONITOR = 'HEALTH_MONITOR',
  TABLE = 'TABLE',
  TABLE_EVENT = 'TABLE_EVENT',
  EVENT_GROUP = 'EVENT_GROUP',
  USER_SETTINGS = 'USER_SETTINGS',
  ANALYSIS_FOLDER = 'ANALYSIS_FOLDER',
  UPDATE_USER_NOTIFICATIONS_CONFIG = 'UPDATE_USER_NOTIFICATIONS_CONFIG',
  FEEDBACK = 'FEEDBACK',
  SHARE = 'SHARE',
  HOMEPAGE = 'HOMEPAGE',
  FAVORITE_SEGMENTS = 'FAVORITE_SEGMENTS',
  TEAM_METRICS = 'TEAM_METRICS',
  EXPERIMENT_AUTOMATION = 'EXPERIMENT_AUTOMATION',
  WORKFLOW = 'WORKFLOW',
  MEMBER = 'MEMBER',
  TEAM = 'TEAM',
  SOURCE = 'SOURCE',
}
