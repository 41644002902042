import classNames from 'classnames';
import classes from './model-page-header.module.scss';
import {EditIcon, IconButton} from 'ui-components';
import {exists} from 'front-core';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {ModelPageHeaderShortDescription} from './model-page-header-short-description.component';
import {ModelPageHeaderViewDefinition} from './view-definition.component';
import {ReactNode} from 'react';

interface OwnProps {
  onEdit?: () => void;
  title: string;
  className?: string;
  subtitleItems?: ReactNode[];
  description?: string;
  value?: string;
  valueDescription?: string;
  onViewDefinition?: () => void;
}

type AllProps = OwnProps;

export const ModelPageHeader = (props: AllProps) => {
  const {
    subtitleItems,
    className,
    title,
    description,
    value,
    valueDescription,
    onViewDefinition,
    onEdit,
  } = props;

  const {t} = useTranslation();

  return (
    <div className={classNames(classes.ModelPageHeader, className)}>
      <div className={classes.Head}>
        <div className={classes.Left}>
          <div className={classes.TitleWrapper}>
            <div className={classes.Title}>{title}</div>
            {exists(onEdit) && (
              <IconButton
                className={classes.EditButton}
                tooltipText={t(TransKeys.GENERAL.ACTIONS.EDIT)}
                icon={EditIcon}
                border={false}
                size={'normal'}
                onClick={onEdit}
              />
            )}
          </div>
          <div className={classes.Descriptors}>
            {subtitleItems?.map(item => item && <div className={classes.Item}>{item}</div>)}
            <div className={classNames(classes.DescriptionDefinitionContainer, classes.Item)}>
              <ModelPageHeaderShortDescription description={description} />
              <ModelPageHeaderViewDefinition onClick={onViewDefinition} />
            </div>
          </div>
        </div>
        <div className={classes.Right}>
          <div className={classes.Value}>{value}</div>
          <div className={classes.Updated}>{valueDescription}</div>
        </div>
      </div>
    </div>
  );
};
