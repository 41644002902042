import classNames from 'classnames';
import classes from './fallback-message.module.scss';
import {BaseMessageRendererProps} from '../../message-viewer.types';

interface Props extends BaseMessageRendererProps<unknown> {
  className?: string;
}

export const FallbackMessage = (props: Props) => {
  const {message, className} = props;

  return (
    <div className={classNames(classes.FallbackMessage, className)}>
      <pre>{JSON.stringify(message, null, 2)}</pre>
    </div>
  );
};
