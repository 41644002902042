import classNames from 'classnames';
import classes from './features-selector.module.scss';
import {ParameterInputWrapper} from '../../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {useCallback, useMemo} from 'react';
import {get, values} from 'lodash';
import {LabelWrapper, useQueryArray} from 'ui-components';
import {ItemList} from '../../../../../shared/core/query-builders/components/item-list/item-list.component';
import {
  SignalSmartSelector,
  SignalSmartSelectorKey,
} from '../../../../../shared/core/smart-selector/signal-smart-selector.component';
import {hasErrors} from '../../../../../../utils/general.utils';

interface SchemaKeysMapping {
  features: string;
}

const DEFAULT_SCHEMA_KEYS_MAPPING: SchemaKeysMapping = {
  features: 'features',
};

const ACCEPTED_SELECTORS = [SignalSmartSelectorKey.FEATURES];

interface OwnProps {
  title: string;
  onChange: (parameters: any) => void;
  value: any;
  schemaKeysMapping?: SchemaKeysMapping;
  onSignalInfo?: (value: string | number) => void;
  subTitle?: string;
  bottomHelper?: any;
  helperText?: any;
  errors?: any;
  className?: string;
}

type AllProps = OwnProps;

export const FeaturesSignalSelector = (props: AllProps) => {
  const {
    title,
    subTitle,
    helperText,
    bottomHelper,
    errors,
    value,
    onChange,
    onSignalInfo,
    schemaKeysMapping = DEFAULT_SCHEMA_KEYS_MAPPING,
    className,
  } = props;
  const hasError = useMemo(
    () => hasErrors(errors, values(schemaKeysMapping)),
    [errors, schemaKeysMapping]
  );
  const {addElement, removeElement} = useQueryArray(
    get(value, schemaKeysMapping.features, []),
    data => onChange({[schemaKeysMapping.features]: data}),
    () => null
  );
  const onFeatureChanged = useCallback(
    (pValue: any, idx: number) => {
      const existing = get(value, schemaKeysMapping.features, []) || [];
      const actions = [...existing];
      actions[idx] = pValue;
      onChange({[schemaKeysMapping.features]: actions});
    },
    [onChange, schemaKeysMapping, value]
  );

  return (
    <ParameterInputWrapper
      title={title}
      subTitle={subTitle}
      helperText={helperText}
      className={classNames(classes.FeaturesSelector, className)}
      bottomHelper={bottomHelper}
      error={hasError}
    >
      <div className={classes.Row}>
        <LabelWrapper label={'Select features'}>
          <ItemList
            items={value[schemaKeysMapping.features] || [null]}
            renderItem={(item, idx) => (
              <SignalSmartSelector
                placeholder={'Select'}
                onChange={value => onFeatureChanged(value, idx)}
                value={value[schemaKeysMapping.features]?.[idx]}
                error={Boolean(errors?.[schemaKeysMapping.features]?.[idx])}
                onSignalInfo={onSignalInfo}
                include={ACCEPTED_SELECTORS}
              />
            )}
            onRemoveItem={removeElement}
            onAddItem={addElement}
            min1Item={false}
          />
        </LabelWrapper>
      </div>
    </ParameterInputWrapper>
  );
};
