import {useCallback, useContext, useMemo, useState} from 'react';
import {
  Button,
  FancyHeader,
  KPIIcon,
  ModalLayout,
  SideMenu,
  EngagementKPIGroupIcon,
  MonetizationKPIGroupIcon,
  ActivationKPIGroupIcon,
  RetentionKPIIcon,
  ConversionKPIIcon,
  RateKPIIcon,
  CustomKPIIcon,
  ModelType,
  LabelWrapper,
  PlusLightIcon,
  CloseRegularIcon,
  Chip,
  ChipVariant,
  CircleInfoRegularIcon,
  SwitchActions,
} from 'ui-components';
import {CATEGORY_ID_PATH_PARAM, METRIC_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../constants/translation-keys';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {Metric, MetricType} from '../../../../objects/models/metric.model';
import {metricDTOValidator} from '../../../../objects/dto/metric.dto';
import {createMetric, updateMetric} from '../../../../store/metrics/metrics.actions';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {useDispatch, useSelector} from 'react-redux';
import {getReducedLoadingStateSelector} from '../../../../store/store.selectors';
import {yupResolver} from '@hookform/resolvers/yup';
import {getMetricNetworkRequest} from '../../../../http/metrics.network-requests';
import {ActionKey} from '../../../../constants/action-key';
import {composition, exists, OnSuccessActionHook} from 'front-core';
import {FormHiddenInputs} from '../../../shared/form/components/form-hidden-inputs.component';
import {contactSupport, preventSubmitOnEnter} from '../../../../utils/general.utils';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {NoteText} from '../../../shared/components/general/note-text/note-text.component';
import {TableEntity} from '../../../../objects/models/table.model';
import {SideMenuItem} from 'ui-components/dist/components/simple/controls/side-menu/side-menu.component';
import {useSignalInitialQueryFactory} from './hooks/use-signal-initial-query-factory.hook';
import classes from './metric-form-panel.module.scss';
import modalClasses from './modal-layout.module.scss';
import {sharedClasses} from '../../../shared';
import {EntityPicker} from '../../components/entity-picker/entity-picker.component';
import {ConfirmationDialogContext} from '../../../../core/contexts/confirmation-dialog.context';
import classNames from 'classnames';
import {MetricQueryBuilder} from './components/metric-query-builder/metric-query-builder.component';
import {NameDescriptionInput} from '../../../shared/form/components/name-description-input/name-description-input.component';
import {getMetricTypeName} from '../../../../constants/options';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import {ALLOW_AD_HOC_PROP_KEY} from '../../../../constants/shared-component-prop-key';
import {AdHocIndication} from '../../../shared/components/general/ad-hoc-indication/ad-hoc-indication.component';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {MetricCategorySmartSelector} from '../../../shared/core/smart-selector/metric-category-smart-selector.component';
import {PanelKey} from '../../../../constants/panels';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import i18n from 'i18next';
import {withModalInactiveSourceHandler} from '../../../../core/hoc/with-modal-inactive-source-handler.hoc';
import {PopulationSegmentQueryBuilder} from '../../../shared/core/query-builders/population-segment-query-builder/population-segment-query-builder.component';
import {useFeatureIsOn} from '@growthbook/growthbook-react';
import {FeatureFlag} from '../../../../constants/feature-flags';
import {BuilderWrapper} from '../../../shared/components/general/builder-wrapper/builder-wrapper.component';

import {useMetricAggregationModeSwitchActions} from '../../../analyses/analysis-forms/hooks/use-metric-aggregation-mode-switch-actions.hook';
import {AggregationMode} from '../../../../objects/models/signal.model';
import {withDisableDemoProduct} from '../../../../core/hoc/with-disable-demo-product.hoc';
import {useDemoProduct} from '../../../../core/hooks/use-demo-product.hook';
import {EventPropertiesSelectorFields} from '../../components/event-properties-fields/event-properties-fields.component';
import {extractTableIdFromSignalDefinition} from '../../components/event-properties-fields/event-properties-fields-utils';
import {useMetricFormInitialValues} from './hooks/use-metric-form-initial-values.hook';
import {useHiddenFlag} from '../../../../core/hooks/use-hidden-flag.hook';

const getMenuItems = (showHiddenTypes: boolean) => {
  const menuItems: SideMenuItem[] = [
    {
      label: i18n.t(TransKeys.METRIC_FORM.SIDE_MENU.GROUP_LABEL.RETENTION),
      value: 'retentionGroup',
      icon: RetentionKPIIcon,
      children: [
        {
          label: getMetricTypeName(MetricType.RETENTION),
          value: MetricType.RETENTION,
        },
        {
          label: getMetricTypeName(MetricType.WOW),
          value: MetricType.WOW,
        },
        {
          label: getMetricTypeName(MetricType.MOM),
          value: MetricType.MOM,
        },
        {
          label: getMetricTypeName(MetricType.DOD),
          value: MetricType.DOD,
        },
      ],
    },
    {
      label: getMetricTypeName(MetricType.CONVERSION),
      value: MetricType.CONVERSION,
      icon: ConversionKPIIcon,
    },
    {
      label: i18n.t(TransKeys.METRIC_FORM.SIDE_MENU.GROUP_LABEL.ACTIVATION),
      value: 'activationGroup',
      icon: ActivationKPIGroupIcon,
      children: [
        {
          label: getMetricTypeName(MetricType.HABIT_MOMENT),
          value: MetricType.HABIT_MOMENT,
        },
      ],
    },
    {
      label: i18n.t(TransKeys.METRIC_FORM.SIDE_MENU.GROUP_LABEL.ENGAGEMENT),
      value: 'egagementGroup',
      icon: EngagementKPIGroupIcon,
      children: [
        {
          label: getMetricTypeName(MetricType.DAU),
          value: MetricType.DAU,
        },
        {
          label: getMetricTypeName(MetricType.WAU),
          value: MetricType.WAU,
        },
        {
          label: getMetricTypeName(MetricType.MAU),
          value: MetricType.MAU,
        },
        {
          label: getMetricTypeName(MetricType.L7),
          value: MetricType.L7,
        },
        {
          label: getMetricTypeName(MetricType.L28),
          value: MetricType.L28,
        },
        {
          label: getMetricTypeName(MetricType.USAGE),
          value: MetricType.USAGE,
        },
        {
          label: getMetricTypeName(MetricType.BEHAVIORAL_CHURN),
          value: MetricType.BEHAVIORAL_CHURN,
        },
      ],
    },
    {
      label: i18n.t(TransKeys.METRIC_FORM.SIDE_MENU.GROUP_LABEL.MONITIZATION),
      value: 'monetizationGroup',
      icon: MonetizationKPIGroupIcon,
      children: [
        {
          label: getMetricTypeName(MetricType.REVENUE),
          value: MetricType.REVENUE,
        },
        {
          label: getMetricTypeName(MetricType.BOUNDED_REVENUE),
          value: MetricType.BOUNDED_REVENUE,
        },
        {
          label: getMetricTypeName(MetricType.PAYMENT_RETENTION),
          value: MetricType.PAYMENT_RETENTION,
        },
      ],
    },
    {
      label: getMetricTypeName(MetricType.RATE),
      value: MetricType.RATE,
      icon: RateKPIIcon,
    },
    {
      label: getMetricTypeName(MetricType.CUSTOM_SQL),
      value: MetricType.CUSTOM_SQL,
      icon: CustomKPIIcon,
    },
  ];

  if (showHiddenTypes) {
    menuItems.push({
      label: i18n.t(TransKeys.METRIC_FORM.SIDE_MENU.GROUP_LABEL.HIDDEN),
      value: 'hiddenGroup',
      children: [],
    });
  }

  return menuItems;
};

const SELECTED_METRIC_KEY = SharedSelectionKeys.METRIC_FORM__METRIC;

interface OwnProps {
  metric?: Metric;
  data: Partial<Metric>;
  onSubmit?: (data: Partial<Metric>) => void;
  onSuccess?: OnSuccessActionHook;
  onClose?: () => void;
  panelId?: string;
  cloneMode?: boolean;
  disabled?: boolean;
  [METRIC_ID_PATH_PARAM]?: number;
  [CATEGORY_ID_PATH_PARAM]?: number;
  [ALLOW_AD_HOC_PROP_KEY]?: boolean;
}

type AllProps = OwnProps;

const DEFAULT_DATA = {
  name: '',
  shortDescription: '',
};

const MetricFormPanelComponent = (props: AllProps) => {
  const {
    metric = {} as any,
    data = DEFAULT_DATA,
    onClose,
    cloneMode,
    [ALLOW_AD_HOC_PROP_KEY]: allowAdHoc,
    [CATEGORY_ID_PATH_PARAM]: categoryId,
    onSubmit: onSubmitFromProps,
    onSuccess,
    disabled: disabledFromProps,
  } = props;
  const {t} = useTranslation();
  const showHiddenTypes = useHiddenFlag();
  const {openSecondaryPanel} = useContext(PanelsContext);
  const notify = useAmplitude();
  const dispatch = useDispatch();
  const {defaultTableEntity, productEntitiesMap, getEntityName} = useProductData();
  const {demoProductValidator} = useDemoProduct();
  const {createInitialQuery} = useSignalInitialQueryFactory();
  const {setConfirmationDialog} = useContext(ConfirmationDialogContext);
  const [showSegmentationFilter, setShowSegmentationFilter] = useState(
    exists(metric.signalPopulationFilter)
  );
  const exposeSegmentFilter = useFeatureIsOn(FeatureFlag.ADD_SEGMENT_FILTER_TO_KPI as string);
  const allowModelEventPropertiesFields = useFeatureIsOn(FeatureFlag.MODEL_PROPERTY_EVENT as any);

  const formInitialValues = useMetricFormInitialValues({
    cloneMode,
    metric,
    data,
    categoryId,
    defaultTableEntity,
  });

  const formMethods = useForm<any>({
    criteriaMode: 'all',
    defaultValues: formInitialValues,
    resolver: yupResolver(demoProductValidator || metricDTOValidator.noUnknown()),
  });

  const {
    handleSubmit,
    formState: {errors, isSubmitted, isSubmitting, isValidating},
    trigger,
    watch,
    setValue,
    clearErrors,
    control,
  } = formMethods;

  const entityContext = watch('entity');
  const type = watch('type') as MetricType;
  const id = watch('id');
  const editMode = Boolean(id);
  const signalDefinition = watch('signalDefinition');
  const aggregationMode = watch('aggregationMode');
  const modelEventPropertiesData = watch('eventProperties');
  const isCustomType = type && !Object.values(MetricType).includes(type);
  const showEventPropertiesFields = useMemo(
    () => allowModelEventPropertiesFields && [MetricType.REVENUE, MetricType.USAGE].includes(type),
    [type, allowModelEventPropertiesFields]
  );
  const eventTableId = useMemo(
    () => extractTableIdFromSignalDefinition(signalDefinition),
    [signalDefinition]
  );
  const metricAggregationModeSwitchActions = useMetricAggregationModeSwitchActions({
    metricType: type,
    mode: aggregationMode,
    onChange: mode => setValue('aggregationMode', mode),
  });

  const typeTranslationKey = useMemo(() => {
    if (!type) {
      return TransKeys.METRIC_FORM.TYPES.EMPTY;
    }
    if (isCustomType) {
      return TransKeys.METRIC_FORM.TYPES.CUSTOM;
    }
    return TransKeys.METRIC_FORM.TYPES[type.toUpperCase()] || {};
  }, [type, isCustomType]);

  // I've tried using the formState.dirtyFields, but it doesn't work as expected
  const [isSignalDirty, setIsSignalDirty] = useState(editMode);

  const menuItems = useMemo(() => getMenuItems(showHiddenTypes), [showHiddenTypes]);
  const selectedMetricType = useMemo(() => (isCustomType ? 'custom' : type), [isCustomType, type]);

  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(ActionKey.CREATE_METRIC, ActionKey.UPDATE_METRIC)(state)
  );

  const confirmResetSignalDefinition = useCallback(
    (changedField, onConfirm) => {
      if (isSignalDirty) {
        setConfirmationDialog({
          title: t(TransKeys.CONFIRMATIONS.CHANGE, {subject: changedField}),
          content: t(TransKeys.CONFIRMATIONS.RESET_QUERY_WARNING),
          onApprove: () => onConfirm(),
        });
      } else {
        onConfirm();
      }
    },
    [t, isSignalDirty, setConfirmationDialog]
  );

  const resetSignalDefinition = useCallback(
    ({type_ = undefined, entity_ = undefined}) => {
      const initialSignalDefinition = createInitialQuery(type_ || type, entity_ || entityContext);

      notify(AmplitudeEvent.METRIC_FORM_FIELD_CHANGED, {
        id: id,
        field: 'signalDefinition',
        value: initialSignalDefinition,
        previousValue: signalDefinition,
      });

      setIsSignalDirty(false);
      setValue('signalDefinition', initialSignalDefinition);
      if (showEventPropertiesFields) {
        setValue('eventProperties' as any, []);
      }
      clearErrors('signalDefinition');
    },
    [
      id,
      notify,
      clearErrors,
      createInitialQuery,
      setValue,
      signalDefinition,
      entityContext,
      type,
      showEventPropertiesFields,
    ]
  );

  const handleEntitySelect = useCallback(
    (entity: TableEntity) => {
      confirmResetSignalDefinition('entity', () => {
        notify(AmplitudeEvent.METRIC_FORM_FIELD_CHANGED, {
          id: id,
          field: 'entity',
          value: entity,
          previousValue: entityContext,
        });
        setValue('entity', entity);
        resetSignalDefinition({entity_: entity});
        setValue('signalPopulationFilter', null);
      });
    },
    [id, notify, confirmResetSignalDefinition, resetSignalDefinition, setValue, entityContext]
  );

  const setMetricType = useCallback(
    (type_: MetricType) => {
      confirmResetSignalDefinition('KPI type', () => {
        notify(AmplitudeEvent.METRIC_FORM_FIELD_CHANGED, {
          id: id,
          field: 'type',
          value: type_,
          previousValue: type,
        });
        setValue(
          'aggregationMode',
          [MetricType.USAGE, MetricType.REVENUE].includes(type_) ? AggregationMode.SUM : null
        );
        setValue('type', type_);
        resetSignalDefinition({type_: type_});
        setValue('signalPopulationFilter', null);
      });
    },
    [id, notify, confirmResetSignalDefinition, resetSignalDefinition, setValue, type]
  );
  const onShowSegmentationFilter = useCallback(() => {
    setShowSegmentationFilter(true);
  }, [setShowSegmentationFilter]);
  const onRemoveSegmentationFilter = useCallback(() => {
    setShowSegmentationFilter(false);
    setValue('signalPopulationFilter', null);
  }, [setShowSegmentationFilter, setValue]);

  const onChangeSignalDefinition = useCallback(
    v => {
      const onChange_ = () => {
        notify(AmplitudeEvent.METRIC_FORM_FIELD_CHANGED, {
          id: id,
          field: 'signalDefinition',
          value: v,
          previousValue: signalDefinition,
        });
        setIsSignalDirty(true);
        setValue('signalDefinition', v);
        isSubmitted && trigger('signalDefinition');
      };

      if (!showEventPropertiesFields || modelEventPropertiesData.length === 0) {
        onChange_();
        return;
      }

      const newEventTableId = extractTableIdFromSignalDefinition(v);

      if (eventTableId === newEventTableId) {
        onChange_();
        return;
      }

      setConfirmationDialog({
        title: t(TransKeys.CONFIRMATIONS.CHANGE, {subject: 'Event Table'}),
        content: t(TransKeys.CONFIRMATIONS.MODEL_EVENT_PROPERTIES_TABLE_EVENT_CHANGE),
        onApprove: () => {
          onChange_();
          setValue('eventProperties' as any, []);
        },
      });
    },
    [
      id,
      notify,
      isSubmitted,
      setValue,
      trigger,
      signalDefinition,
      showEventPropertiesFields,
      modelEventPropertiesData,
      eventTableId,
      setConfirmationDialog,
      t,
    ]
  );
  const onCreateCategory = useCallback(() => {
    openSecondaryPanel(PanelKey.METRIC_CATEGORY_FORM_PANEL, {
      onSuccess: category => {
        setValue('categoryId', category.id);
      },
    });
  }, [openSecondaryPanel, setValue]);

  const namePlaceholder = useMemo(() => {
    return `${productEntitiesMap[entityContext].name} ${t(typeTranslationKey.TITLE)}`;
  }, [t, productEntitiesMap, entityContext, typeTranslationKey]);

  const title = useMemo(() => {
    if (editMode) {
      return (
        <>
          {t(TransKeys.METRIC_FORM.TITLE_EDIT)}{' '}
          {metric.isAdHoc && (
            <AdHocIndication model={ModelType.METRIC} className={classes.AdHocIndication} />
          )}
        </>
      );
    }
    return t(TransKeys.METRIC_FORM.TITLE_CREATE);
  }, [editMode, t, metric]);

  const entityName = useMemo(
    () => getEntityName(entityContext).toLowerCase(),
    [entityContext, getEntityName]
  );

  const onSubmit = useCallback(
    data => {
      if (onSubmitFromProps) {
        onSubmitFromProps(data);
        return;
      }
      const onActionSuccess = (res, action) => {
        onSuccess && onSuccess(res, action);
        onClose();
      };
      const action = editMode
        ? updateMetric(data, onActionSuccess)
        : createMetric(data, onActionSuccess);
      dispatch(action);
    },
    [dispatch, editMode, onSubmitFromProps, onClose, onSuccess]
  );
  const onSubmitAdHoc = useCallback(data => onSubmit({...data, isAdHoc: true}), [onSubmit]);
  const onSubmitSaveToDMP = useCallback(data => onSubmit({...data, isAdHoc: false}), [onSubmit]);
  const isDisabled = useMemo(
    () => isLoading || isSubmitting || isValidating || disabledFromProps,
    [isLoading, isSubmitting, isValidating, disabledFromProps]
  );

  return (
    <div className={modalClasses.ModalContainer}>
      <ModalLayout>
        {isLoading && <GenericLoading />}
        <div className={classes.CreateMetric}>
          <FancyHeader
            icon={KPIIcon}
            title={title}
            subTitle={t(TransKeys.METRIC_FORM.MAIN_TITLE)}
            onClose={onClose}
            className={classes.CreateMetricHeader}
          />
          <FormProvider {...formMethods}>
            <form
              className={sharedClasses.Form}
              onKeyDown={preventSubmitOnEnter}
              onSubmit={e => {
                e.stopPropagation();
                handleSubmit(onSubmit)(e);
              }}
            >
              <FormHiddenInputs names={['id', 'type']} />
              <div className={modalClasses.ModalLeftPanel}>
                <div className={modalClasses.Section}>
                  <div className={modalClasses.Header}>
                    {t(TransKeys.METRIC_FORM.SIDE_MENU.ENTITY_LABEL)}
                  </div>
                  <EntityPicker
                    value={entityContext}
                    onChange={entity => handleEntitySelect(entity)}
                    variant={'select'}
                  />
                </div>
                <div className={modalClasses.Section}>
                  <div className={modalClasses.Header}>
                    {t(TransKeys.METRIC_FORM.SIDE_MENU.KPI_TYPE_LABEL)}
                  </div>
                  <SideMenu
                    searchable
                    items={menuItems}
                    selected={selectedMetricType}
                    onSelect={v => {
                      setMetricType(v as MetricType);
                    }}
                  />
                </div>
              </div>
              <div className={modalClasses.ModalBody}>
                <div className={modalClasses.ModalContent}>
                  <div
                    className={classNames(!type && classes.EmptyStateTab, classes.ContentWrapper)}
                  >
                    <header className={classes.Header}>
                      <div className={classes.Title}>{t(typeTranslationKey.TITLE)}</div>
                      <div className={classes.Description}>
                        {t(typeTranslationKey.DESCRIPTION, {
                          entity: entityName,
                        })}
                      </div>
                    </header>
                    {type && (
                      <>
                        <NameDescriptionInput namePlaceholder={namePlaceholder} />
                        <LabelWrapper label={t(TransKeys.METRIC_FORM_PANEL.INPUTS.CATEGORY.LABEL)}>
                          <Controller
                            render={({field}) => (
                              <MetricCategorySmartSelector
                                placeholder={t(TransKeys.GENERAL.LABELS.SELECT)}
                                value={field.value}
                                onChange={field.onChange}
                                className={classes.CategorySelector}
                                onCreate={onCreateCategory}
                                clearable
                              />
                            )}
                            name={'categoryId'}
                            control={control}
                          />
                        </LabelWrapper>
                        <LabelWrapper
                          label={t(TransKeys.METRIC_FORM_PANEL.INPUTS.DEFINITION.LABEL)}
                        >
                          <BuilderWrapper>
                            <MetricQueryBuilder
                              key={type}
                              signalDefinition={signalDefinition}
                              onChangeSignalDefinition={onChangeSignalDefinition}
                              onResetSignalDefinition={resetSignalDefinition}
                              errors={errors['signalDefinition']}
                              entity={entityContext}
                              disabled={isCustomType}
                              withWrapper={false}
                            />
                            {metricAggregationModeSwitchActions.length > 0 && (
                              <LabelWrapper
                                label={t(TransKeys.METRIC_FORM_PANEL.INPUTS.AGGREGATION_MODE.LABEL)}
                                className={classes.AggregationModeLabel}
                              >
                                <SwitchActions
                                  showActionsLabel
                                  actions={metricAggregationModeSwitchActions}
                                />
                              </LabelWrapper>
                            )}
                          </BuilderWrapper>
                        </LabelWrapper>
                        {showEventPropertiesFields && (
                          <LabelWrapper
                            label={t(TransKeys.METRIC_FORM_PANEL.INPUTS.EVENT_PROPERTIES.LABEL)}
                          >
                            <div className={classes.EventPropertiesLabelExplanation}>
                              {t(TransKeys.METRIC_FORM_PANEL.INPUTS.EVENT_PROPERTIES.DESCRIPTION)}
                            </div>
                            <EventPropertiesSelectorFields
                              eventsTableId={eventTableId}
                              disabled={false}
                            />
                          </LabelWrapper>
                        )}
                        {exposeSegmentFilter && (
                          <LabelWrapper
                            label={
                              <div className={classes.SegmentationFilterLabel}>
                                <span>
                                  {t(TransKeys.METRIC_FORM_PANEL.INPUTS.SEGMENTATION_FILTER.LABEL)}
                                </span>
                                <Chip label={'Beta'} variant={ChipVariant.NEW} size={'xsmall'} />
                              </div>
                            }
                            helperText={
                              <div className={classes.SegmentationFilterHelperText}>
                                <CircleInfoRegularIcon />
                                {t(
                                  TransKeys.METRIC_FORM_PANEL.INPUTS.SEGMENTATION_FILTER.HELPER_TEXT
                                )}
                              </div>
                            }
                            renderRight={
                              showSegmentationFilter ? (
                                <span
                                  onClick={() => onRemoveSegmentationFilter()}
                                  className={classes.RemoveSegmentationFilterButton}
                                >
                                  <CloseRegularIcon />
                                  {t(
                                    TransKeys.METRIC_FORM_PANEL.INPUTS.SEGMENTATION_FILTER
                                      .REMOVE_BUTTON
                                  )}
                                </span>
                              ) : undefined
                            }
                          >
                            <div className={classes.SegmentationFilter}>
                              {!showSegmentationFilter && (
                                <Button
                                  onClick={() => onShowSegmentationFilter()}
                                  icon={PlusLightIcon}
                                  variant={'outlined'}
                                  className={classes.AddFilterButton}
                                >
                                  {t(
                                    TransKeys.METRIC_FORM_PANEL.INPUTS.SEGMENTATION_FILTER
                                      .ADD_BUTTON
                                  )}
                                </Button>
                              )}
                              {showSegmentationFilter && (
                                <BuilderWrapper>
                                  <Controller
                                    render={({field, fieldState: {error}}) => (
                                      <PopulationSegmentQueryBuilder
                                        onChange={field.onChange}
                                        query={field.value}
                                        errors={error}
                                        entityContext={entityContext as TableEntity}
                                      />
                                    )}
                                    name={'signalPopulationFilter'}
                                  />
                                </BuilderWrapper>
                              )}
                            </div>
                          </LabelWrapper>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <footer className={modalClasses.ModalFooter}>
                  <NoteText
                    text={t(TransKeys.SUPPORT.MISSING_EVENT_OR_PROPERTY)}
                    buttonText={t(TransKeys.SUPPORT.SUPPORT_BUTTON_TEXT)}
                    onButtonClicked={() => {
                      notify(AmplitudeEvent.METRIC_FORM_CONTACT_SUPPORT_CLICKED);
                      contactSupport();
                    }}
                  />
                  <div className={classes.SubmitActions}>
                    {((allowAdHoc && !editMode) || metric?.isAdHoc) && (
                      <Button
                        className={classes.AdHocSave}
                        helperText={t(TransKeys.GENERAL.ACTIONS.SAVE_AD_HOC_HELPER_TEXT, {
                          model: t(TransKeys.MODELS.METRIC),
                        })}
                        disabled={isDisabled}
                        onClick={handleSubmit(onSubmitAdHoc)}
                      >
                        {t(TransKeys.GENERAL.ACTIONS.SAVE_AD_HOC)}
                      </Button>
                    )}
                    <Button
                      caps={false}
                      disabled={isDisabled}
                      onClick={handleSubmit(onSubmitSaveToDMP)}
                    >
                      {metric?.isAdHoc
                        ? t(TransKeys.GENERAL.ACTIONS.SAVE_TO_DMP)
                        : t(TransKeys.GENERAL.ACTIONS.SAVE)}
                    </Button>
                  </div>
                </footer>
              </div>
            </form>
          </FormProvider>
        </div>
      </ModalLayout>
    </div>
  );
};

const MetricFormPanel = composition<AllProps>(
  MetricFormPanelComponent,
  withModalInactiveSourceHandler,
  withDisableDemoProduct,
  withLoadBefore({
    metric: {
      selectedKey: SELECTED_METRIC_KEY,
      actionKey: SELECTED_METRIC_KEY,
      request: getMetricNetworkRequest,
      mapPayloadFromProps: props => props[METRIC_ID_PATH_PARAM],
      shouldCall: props => props[METRIC_ID_PATH_PARAM] !== undefined,
    },
  })
);

export default MetricFormPanel;
