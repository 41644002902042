import classNames from 'classnames';
import classes from './expand-button.module.scss';
import {CloseModalButton} from 'ui-components';

interface OwnProps {
  isOpen: boolean;
  onChange: (isOpen?: boolean) => void;
  children: any;
  className?: string;
}

type AllProps = OwnProps;

export const ExpandButton = (props: AllProps) => {
  const {isOpen, onChange, children, className} = props;

  return (
    <div
      onClick={e => !isOpen && onChange(true)}
      className={classNames(classes.ExpandButton, isOpen && classes.Open, className)}
    >
      <div className={classes.ButtonBackground} />
      <CloseModalButton onClick={() => onChange(false)} className={classes.CloseButton} />
      {isOpen && children}
    </div>
  );
};
