import {RCAInsightSentence, SpikeDropOverUnderPerformingIncreasedDecreasedKPI} from './objects';
import sharedClasses from '../insights.module.scss';
import {
  ChangeInValueSentencePart,
  SegmentNameSentencePart,
} from '../components/shared-sentence-parts/shared-sentence-parts.components';

interface OwnProps extends RCAInsightSentence {
  formattedSentenceParts: SpikeDropOverUnderPerformingIncreasedDecreasedKPI;
}

type AllProps = OwnProps;

const SpikeDropOverUnderPerformingIncreasedDecreasedKpiFormatter = (props: AllProps) => {
  const {formattedSentenceParts, sentenceParts} = props;
  const {pastTensePerformanceVsWow} = formattedSentenceParts;
  const {segmentViewData, changeInValue} = sentenceParts;

  return (
    <div className={sharedClasses.InsightSentenceContainer}>
      <SegmentNameSentencePart data={segmentViewData} />
      <span
        className={sharedClasses.InsightSentencePart}
      >{`${pastTensePerformanceVsWow} vs the analyzed period by`}</span>
      <ChangeInValueSentencePart changeInValue={changeInValue} />
    </div>
  );
};
export {SpikeDropOverUnderPerformingIncreasedDecreasedKpiFormatter};
