import classNames from 'classnames';
import {ArrowUpIcon, Textarea} from 'ui-components';
import {Tooltip} from '@material-ui/core';
import {useCallback} from 'react';
import classes from './user-text-input.module.scss';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../../../constants/translation-keys';

interface OwnProps {
  value: string;
  onChange: (value: string) => void;
  onSubmit: () => void;
  disabled?: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const UserTextInput = (props: AllProps) => {
  const {value, onChange, onSubmit, disabled, className} = props;
  const {t} = useTranslation();

  const onKeyUp = useCallback(
    e => {
      if (e.key === 'Enter' && !e.shiftKey) {
        onSubmit();
      }
    },
    [onSubmit]
  );

  const handleChange = value => {
    onChange(value);
  };

  return (
    <div className={classNames(classes.UserTextInput, disabled && classes.Disabled, className)}>
      <div className={classes.InputWrapper}>
        <Textarea
          disabled={disabled}
          className={classNames(classes.Input, disabled && classes.Disabled)}
          value={value}
          onChange={e => handleChange(e.target.value)}
          placeholder={'Ask a question...'}
          onKeyUp={onKeyUp}
        />
        <Tooltip title={'Send message'} placement={'top'} interactive={false}>
          <div
            onClick={disabled ? undefined : () => onSubmit()}
            className={classNames(classes.SendButton, value && classes.Active)}
          >
            <ArrowUpIcon className={classes.Icon} />
          </div>
        </Tooltip>
      </div>
      <div className={classes.Note}>{t(TransKeys.GEN_AI_BOT.LANGUAGE_MODELS_USER_INPUT_NOTE)}</div>
    </div>
  );
};
