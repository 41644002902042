import ExperimentFormPanel from '../modules/experiments/panels/experiment-form-panel/experiment-form-panel.component';
import AnalysisResultsPanel from '../modules/analyses/panels/analysis-results-panel/analysis-results-panel.component';
import GoalFormPanel from '../modules/dmp/panels/goal-form-panel/goal-form-panel.component';
import AnnotationFormPanel from '../modules/dmp/panels/annotation-form-panel/annotation-form-panel.component';
import {ViewSignalDefinitionPanel} from '../modules/dmp/panels/view-signal-definition-panel/view-signal-definition-panel.component';
import {OpportunityActionsPanel} from '../modules/opportunities/panels/opportunity-actions-panel/opportunity-actions-panel.component';
import FunnelFormPanel from '../modules/dmp/panels/funnel-form-panel/funnel-form-panel.component';
import MilestoneFormPanel from '../modules/dmp/panels/milestone-form-panel/milestone-form-panel.component';
import {AnalysisFormPanel} from '../modules/analyses/panels/analysis-form-panel/analysis-form-panel.component';
import FeatureFormPanel from '../modules/dmp/panels/feature-form-panel/feature-form-panel.component';
import {RenameAnalysisPanel} from '../modules/analyses/panels/rename-analysis-panel/rename-analysis-panel.component';
import {ScheduleAnalysisPanel} from '../modules/analyses/panels/schdule-analysis-panel/schdule-analysis-panel.component';
import SegmentFormPanel from '../modules/dmp/panels/segment-form-panel/segment-form-panel.component';
import {ShareResourceFormPanel} from '../modules/analyses/panels/share-resource-form-panel/share-resource-form-panel.component';
import {SetResourceUsefulnessFormPanel} from '../modules/analyses/panels/set-resource-usefulness-form-panel/set-resource-usefulness-form-panel.component';
import MetricFormPanel from '../modules/dmp/panels/metric-form-panel/metric-form-panel.component';
import SegmentViewPanel from '../modules/dmp/panels/segment-view-panel/segment-view-panel.component';
import MetricViewPanel from '../modules/dmp/panels/metric-view-panel/metric-view-panel.component';
import FunnelViewPanel from '../modules/dmp/panels/funnel-view-panel/funnel-view-panel.component';
import FeatureViewPanel from '../modules/dmp/panels/feature-view-panel/feature-view-panel.component';
import ContentViewPanel from '../modules/dmp/panels/content-view-panel/content-view-panel.component';
import TableViewPanel from '../modules/dmp/panels/table-view-panel/table-view-panel.component';
import {ExperimentSubscriptionPanel} from '../modules/experiments/panels/experiment-subscription-panel/experiment-subscription-panel.component';
import SegmentCategoryFormPanel from '../modules/dmp/panels/segment-category-form-panel/segment-category-form-panel.component';
import ExperimentViewPanel from '../modules/experiments/panels/experiment-view-panel/experiment-view-panel.component';
import {MonitoredMetricFormPanel} from '../modules/health-monitor/panels/monitored-metric-form-panel/monitored-metric-form-panel.component';
import HealthMonitorGroupFormPanel from '../modules/health-monitor/panels/health-monitor-group-form-panel/health-monitor-group-form-panel.component';
import {TableFormPanel} from '../modules/dmp/panels/table-form-panel/table-form-panel.component';
import {HealthMonitorSubscriptionPanel} from '../modules/health-monitor/panels/health-monitor-subscription-panel/health-monitor-subscription-panel.component';
import {ChangeAnalysisParametersFormPanel} from '../modules/analyses/panels/analysis-form-panel/change-analysis-parameters-form-panel.component';
import EventGroupFormPanel from '../modules/dmp/panels/event-group-form-panel/event-group-form-panel.component';
import EventGroupViewPanel from '../modules/dmp/panels/event-group-view-panel/event-group-view-panel.component';
import MilestoneViewPanel from '../modules/dmp/panels/milestone-view-panel/milestone-view-panel.component';
import AnalysisFolderFormPanel from '../modules/analyses/panels/analysis-folder-form-panel/analysis-folder-form-panel.component';
import ContentFormPanel from '../modules/dmp/panels/content-form-panel/content-form-panel.component';
import {InviteUsersFormPanel} from '../modules/analyses/panels/invite-users-form-panel/invite-users-form-panel.component';
import FeedbackFormPanel from '../modules/shared/core/document-viewer/viewers/feedback-viewer/components/feedback-form-panel/feedback-form-panel.component';
import {SimpleTextPanel} from '../modules/shared/components/general/simple-text-panel/simple-text-panel.component';
import {FunnelAnalysisConversionFollowUpFormPanel} from '../modules/shared/follow-ups/panels/funnel-analysis-conversion-follow-up-form-panel/funnel-analysis-conversion-follow-up-form-panel.component';
import {GoalDriversAnalysisFollowUpFormPanel} from '../modules/shared/follow-ups/panels/goal-drivers-analysis-follow-up-panel/goal-drivers-analysis-follow-up-panel.component';
import {RetentionAnalysisInvestigateBucketFollowUpFormPanel} from '../modules/shared/follow-ups/panels/retention-analysis-bucket-investigation-follow-up-panel/retention-analysis-bucket-investigation-follow-up-panel.component';
import AnalysisExecutedSqlsPanel from '../modules/analyses/panels/analysis-executed-sqls-panel/analysis-executed-sqls-panel.component';
import {MoveToFolderPanel} from '../modules/analyses/panels/move-to-folder-panel/move-to-folder-panel.component';
import MetricCategoryFormPanel from '../modules/dmp/panels/metric-category-form-panel/metric-category-form-panel.component';
import {RCAFollowUpFormPanel} from '../modules/shared/follow-ups/panels/rca-follow-up-panel/rca-follow-up-panel.component';
import {RerunAnalysisPanel} from '../modules/analyses/panels/analysis-rerun-panel/analysis-rerun-panel.component';
import {FollowUpType} from 'ui-components';
import SelectSimpleAnalysisPickerPanel from '../modules/analyses/panels/select-simple-analysis-picker-panel/select-simple-analysis-picker-panel.component';
import {HomepageAnnotationsPanel} from '../modules/homepage/panels/homepage-annotations-panel/homepage-annotations-panel.component';
import WeeklyInterestFormPanel from '../modules/dmp/panels/weekly-interest-form-panel/weekly-interest-form-panel.component';
import {QuickRunAnalysesPanel} from '../modules/analyses/panels/quick-run-analyses-panel/quick-run-analyses-panel.component';
import {HomepageConfigurationPanel} from '../modules/homepage/panels/homepage-configuration-panel/homepage-configuration-panel.component';
import {AnomalyGamePanel} from '../modules/analyses/panels/anomaly-game-panel/anomaly-game-panel.component';
import {HowToImproveKPIPanel} from '../modules/homepage/panels/how-to-improve-kpi-panel/how-to-improve-kpi-panel.component';
import {HomepageSubscriptionPanel} from '../modules/homepage/panels/homepage-subscription-panel/homepage-subscription-panel.component';
import {FavoritesSegmentsFormPanel} from '../modules/dmp/panels/favorites-segments-form-panel/favorites-segments-form-panel.component';
import {BookADemoFormPanel} from '../core/components/book-a-demo-form-panel/book-a-demo-form-panel.component';
import {TeamMetricFormPanel} from '../modules/dmp/panels/team-metrics-form-panel/team-metrics-form-panel.component';
import {ExperimentAutomationsPanel} from '../modules/experiments/panels/experiment-automations-panel/experiment-automations-panel.component';
import {ExperimentAutomationFormPanel} from '../modules/experiments/panels/experiment-automation-form-panel/experiment-automation-form-panel.component';
import {MetricSegmentationOvertimePanel} from '../modules/metrics/panels/metric-segmentation-overtime-panel/metric-segmentation-overtime-panel.component';
import {RenameExperimentPanel} from '../modules/experiments/panels/rename-experiment-panel/rename-experiment-panel.component';
import WorkflowFormPanel from '../modules/workflows/panels/workflow-form-panel/workflow-form-panel.component';
import {TeamFormPanel} from '../modules/dmp/panels/team-form-panel/team-form-panel.component.tsx';

export enum PanelKey {
  EXPERIMENT_FORM_PANEL = 'EXPERIMENT_FORM_PANEL',
  EXPERIMENT_SUBSCRIPTION_FORM_PANEL = 'EXPERIMENT_SUBSCRIPTION_FORM_PANEL',
  ANALYSIS_RESULTS_PANEL = 'ANALYSIS_RESULTS_PANEL',
  SCHEDULE_ANALYSIS_PANEL = 'SCHEDULE_ANALYSIS_PANEL',
  GOAL_FORM_PANEL = 'GOAL_FORM_PANEL',
  ANNOTATION_FORM_PANEL = 'ANNOTATION_FORM_PANEL',
  FUNNEL_FORM_PANEL = 'FUNNEL_FORM_PANEL',
  MILESTONE_FORM_PANEL = 'MILESTONE_FORM_PANEL',
  SIGNAL_DEFINITION_PANEL = 'SIGNAL_DEFINITION_PANEL',
  OPPORTUNITY_ACTIONS_PANEL = 'OPPORTUNITY_ACTIONS_PANEL',
  ANALYSIS_FORM_PANEL = 'ANALYSIS_FORM_PANEL',
  FEATURE_FORM_PANEL = 'FEATURE_FORM_PANEL',
  TABLE_FORM_PANEL = 'TABLE_FORM_PANEL',
  RENAME_ANALYSIS_PANEL = 'RENAME_ANALYSIS_PANEL',
  RENAME_EXPERIMENT_PANEL = 'RENAME_EXPERIMENT_PANEL',
  SEGMENT_FORM_PANEL = 'SEGMENT_FORM_PANEL',
  SEGMENT_CATEGORY_FORM_PANEL = 'SEGMENT_CATEGORY_FORM_PANEL',
  METRIC_CATEGORY_FORM_PANEL = 'METRIC_CATEGORY_FORM_PANEL',
  SHARE_RESOURCE_PANEL = 'SHARE_RESOURCE_PANEL',
  INVITE_USERS_PANEL = 'INVITE_USERS_PANEL',
  SET_RESOURCE_USEFULNESS_PANEL = 'SET_RESOURCE_USEFULNESS_PANEL',
  METRIC_FORM_PANEL = 'METRIC_FORM_PANEL',
  EVENT_GROUP_FORM_PANEL = 'EVENT_GROUP_FORM_PANEL',
  VIEW_SEGMENT_PANEL = 'VIEW_SEGMENT_PANEL',
  VIEW_METRIC_PANEL = 'VIEW_METRIC_PANEL',
  VIEW_MILESTONE_PANEL = 'VIEW_MILESTONE_PANEL',
  VIEW_EVENT_GROUP_PANEL = 'VIEW_EVENT_GROUP_PANEL',
  VIEW_FUNNEL_PANEL = 'VIEW_FUNNEL_PANEL',
  VIEW_FEATURE_PANEL = 'VIEW_FEATURE_PANEL',
  VIEW_CONTENT_PANEL = 'VIEW_CONTENT_PANEL',
  VIEW_ANALYSIS_EXECUTED_SQLS_PANEL = 'VIEW_ANALYSIS_EXECUTED_SQLS_PANEL',
  CONTENT_FORM_PANEL = 'CONTENT_FORM_PANEL',
  VIEW_TABLE_PANEL = 'VIEW_TABLE_PANEL',
  VIEW_EXPERIMENT_PANEL = 'VIEW_EXPERIMENT_PANEL',
  MONITORED_METRIC_FORM_PANEL = 'MONITORED_METRIC_FORM_PANEL',
  HEALTH_MONITOR_GROUP_PANEL = 'HEALTH_MONITOR_GROUP_PANEL',
  HEALTH_MONITOR_SUBSCRIPTION_FORM_PANEL = 'HEALTH_MONITOR_SUBSCRIPTION_FORM_PANEL',
  CHANGE_ANALYSIS_PARAMETERS_FORM_PANEL = 'CHANGE_ANALYSIS_PARAMETERS_FORM_PANEL',
  ANALYSIS_FOLDER_FORM_PANEL = 'ANALYSIS_FOLDER_FORM_PANEL',
  ANALYSIS_RERUN_PANEL = 'ANALYSIS_RERUN_PANEL',
  MOVE_TO_ANALYSIS_FOLDER_PANEL = 'MOVE_TO_ANALYSIS_FOLDER_PANEL',
  FEEDBACK_FORM_PANEL = 'FEEDBACK_FORM_PANEL',
  SIMPLE_TEXT_PANEL = 'SIMPLE_TEXT_PANEL',
  RCA_FOLLOW_UP_PANEL = 'RCA_FOLLOW_UP_PANEL',
  FUNNEL_ANALYSIS_CONVERSION_FOLLOW_UP_PANEL = 'FUNNEL_ANALYSIS_CONVERSION_FOLLOW_UP_PANEL',
  GOAL_DRIVERS_ANALYSIS_IMPROVE_FEATURE_ADOPTION_FOLLOW_UP_PANEL = 'GOAL_DRIVERS_ANALYSIS_IMPROVE_FEATURE_ADOPTION_FOLLOW_UP_PANEL',
  RETENTION_ANALYSIS_BUCKET_INVESTIGATION_FOLLOW_UP_PANEL = 'RETENTION_ANALYSIS_BUCKET_INVESTIGATION_FOLLOW_UP_PANEL',
  SIMPLE_ANALYSIS_PICKER_PANEL = 'SIMPLE_ANALYSIS_PICKER_PANEL',
  HOMEPAGE_ANNOTATIONS_PANEL = 'HOMEPAGE_ANNOTATIONS_PANEL',
  HOMEPAGE_CONFIGURATION_PANEL = 'HOMEPAGE_CONFIGURATION_PANEL',
  WEEKLY_INTEREST_FORM_PANEL = 'WEEKLY_INTEREST_FORM_PANEL',
  QUICK_RUN_ANALYSES_PANEL = 'QUICK_RUN_ANALYSES_PANEL',
  ANOMALY_GAME_PANEL = 'ANOMALY_GAME_PANEL',
  HOW_TO_IMPROVE_KPI_PANEL = 'HOW_TO_IMPROVE_KPI_PANEL',
  HOMEPAGE_SUBSCRIPTION_PANEL = 'HOMEPAGE_SUBSCRIPTION_PANEL',
  FAVORITES_SEGMENTS_PANEL = 'FAVORITES_SEGMENTS_PANEL',
  TEAM_METRICS_PANEL = 'TEAM_METRICS_PANEL',
  BOOK_A_DEMO_PANEL = 'BOOK_A_DEMO_PANEL',
  EXPERIMENT_AUTOMATIONS_PANEL = 'EXPERIMENT_AUTOMATIONS_PANEL',
  EXPERIMENT_AUTOMATION_FORM_PANEL = 'EXPERIMENT_AUTOMATION_FORM_PANEL',
  METRIC_SEGMENTATION_OVERTIME_PANEL = 'METRIC_SEGMENTATION_OVERTIME_PANEL',
  WORKFLOW_FORM_PANEL = 'WORKFLOW_FORM_PANEL',
  TEAM_FORM_PANEL = 'TEAM_FORM_PANEL',
}

export const APP_PANELS = {
  [PanelKey.EXPERIMENT_FORM_PANEL]: ExperimentFormPanel,
  [PanelKey.ANALYSIS_RESULTS_PANEL]: AnalysisResultsPanel,
  [PanelKey.GOAL_FORM_PANEL]: GoalFormPanel,
  [PanelKey.ANNOTATION_FORM_PANEL]: AnnotationFormPanel,
  [PanelKey.SIGNAL_DEFINITION_PANEL]: ViewSignalDefinitionPanel,
  [PanelKey.OPPORTUNITY_ACTIONS_PANEL]: OpportunityActionsPanel,
  [PanelKey.FUNNEL_FORM_PANEL]: FunnelFormPanel,
  [PanelKey.MILESTONE_FORM_PANEL]: MilestoneFormPanel,
  [PanelKey.ANALYSIS_FORM_PANEL]: AnalysisFormPanel,
  [PanelKey.CHANGE_ANALYSIS_PARAMETERS_FORM_PANEL]: ChangeAnalysisParametersFormPanel,
  [PanelKey.FEATURE_FORM_PANEL]: FeatureFormPanel,
  [PanelKey.TABLE_FORM_PANEL]: TableFormPanel,
  [PanelKey.RENAME_ANALYSIS_PANEL]: RenameAnalysisPanel,
  [PanelKey.RENAME_EXPERIMENT_PANEL]: RenameExperimentPanel,
  [PanelKey.SEGMENT_FORM_PANEL]: SegmentFormPanel,
  [PanelKey.EVENT_GROUP_FORM_PANEL]: EventGroupFormPanel,
  [PanelKey.VIEW_EVENT_GROUP_PANEL]: EventGroupViewPanel,
  [PanelKey.SCHEDULE_ANALYSIS_PANEL]: ScheduleAnalysisPanel,
  [PanelKey.SHARE_RESOURCE_PANEL]: ShareResourceFormPanel,
  [PanelKey.SET_RESOURCE_USEFULNESS_PANEL]: SetResourceUsefulnessFormPanel,
  [PanelKey.INVITE_USERS_PANEL]: InviteUsersFormPanel,
  [PanelKey.METRIC_FORM_PANEL]: MetricFormPanel,
  [PanelKey.VIEW_SEGMENT_PANEL]: SegmentViewPanel,
  [PanelKey.VIEW_METRIC_PANEL]: MetricViewPanel,
  [PanelKey.VIEW_MILESTONE_PANEL]: MilestoneViewPanel,
  [PanelKey.VIEW_FUNNEL_PANEL]: FunnelViewPanel,
  [PanelKey.VIEW_FEATURE_PANEL]: FeatureViewPanel,
  [PanelKey.VIEW_CONTENT_PANEL]: ContentViewPanel,
  [PanelKey.CONTENT_FORM_PANEL]: ContentFormPanel,
  [PanelKey.VIEW_TABLE_PANEL]: TableViewPanel,
  [PanelKey.EXPERIMENT_SUBSCRIPTION_FORM_PANEL]: ExperimentSubscriptionPanel,
  [PanelKey.SEGMENT_CATEGORY_FORM_PANEL]: SegmentCategoryFormPanel,
  [PanelKey.METRIC_CATEGORY_FORM_PANEL]: MetricCategoryFormPanel,
  [PanelKey.VIEW_EXPERIMENT_PANEL]: ExperimentViewPanel,
  [PanelKey.MONITORED_METRIC_FORM_PANEL]: MonitoredMetricFormPanel,
  [PanelKey.HEALTH_MONITOR_GROUP_PANEL]: HealthMonitorGroupFormPanel,
  [PanelKey.HEALTH_MONITOR_SUBSCRIPTION_FORM_PANEL]: HealthMonitorSubscriptionPanel,
  [PanelKey.ANALYSIS_FOLDER_FORM_PANEL]: AnalysisFolderFormPanel,
  [PanelKey.ANALYSIS_RERUN_PANEL]: RerunAnalysisPanel,
  [PanelKey.VIEW_ANALYSIS_EXECUTED_SQLS_PANEL]: AnalysisExecutedSqlsPanel,
  [PanelKey.FEEDBACK_FORM_PANEL]: FeedbackFormPanel,
  [PanelKey.SIMPLE_TEXT_PANEL]: SimpleTextPanel,
  [PanelKey.MOVE_TO_ANALYSIS_FOLDER_PANEL]: MoveToFolderPanel,
  [PanelKey.SIMPLE_ANALYSIS_PICKER_PANEL]: SelectSimpleAnalysisPickerPanel,
  [PanelKey.HOMEPAGE_ANNOTATIONS_PANEL]: HomepageAnnotationsPanel,
  [PanelKey.HOMEPAGE_CONFIGURATION_PANEL]: HomepageConfigurationPanel,
  [PanelKey.HOMEPAGE_SUBSCRIPTION_PANEL]: HomepageSubscriptionPanel,
  [PanelKey.QUICK_RUN_ANALYSES_PANEL]: QuickRunAnalysesPanel,
  [PanelKey.ANOMALY_GAME_PANEL]: AnomalyGamePanel,
  [PanelKey.WEEKLY_INTEREST_FORM_PANEL]: WeeklyInterestFormPanel,
  [PanelKey.HOW_TO_IMPROVE_KPI_PANEL]: HowToImproveKPIPanel,
  [PanelKey.FAVORITES_SEGMENTS_PANEL]: FavoritesSegmentsFormPanel,
  [PanelKey.TEAM_METRICS_PANEL]: TeamMetricFormPanel,
  [PanelKey.BOOK_A_DEMO_PANEL]: BookADemoFormPanel,
  [PanelKey.EXPERIMENT_AUTOMATIONS_PANEL]: ExperimentAutomationsPanel,
  [PanelKey.EXPERIMENT_AUTOMATION_FORM_PANEL]: ExperimentAutomationFormPanel,
  [PanelKey.METRIC_SEGMENTATION_OVERTIME_PANEL]: MetricSegmentationOvertimePanel,
  [PanelKey.WORKFLOW_FORM_PANEL]: WorkflowFormPanel,
  [PanelKey.TEAM_FORM_PANEL]: TeamFormPanel,
  // Follow up actions panels
  [PanelKey.RCA_FOLLOW_UP_PANEL]: RCAFollowUpFormPanel,
  [PanelKey.FUNNEL_ANALYSIS_CONVERSION_FOLLOW_UP_PANEL]: FunnelAnalysisConversionFollowUpFormPanel,
  [PanelKey.GOAL_DRIVERS_ANALYSIS_IMPROVE_FEATURE_ADOPTION_FOLLOW_UP_PANEL]:
    GoalDriversAnalysisFollowUpFormPanel,
  [PanelKey.RETENTION_ANALYSIS_BUCKET_INVESTIGATION_FOLLOW_UP_PANEL]:
    RetentionAnalysisInvestigateBucketFollowUpFormPanel,
};

export const FOLLOW_UP_TYPE_TO_PANEL_KEY = {
  [FollowUpType.RCA_FROM_FUNNEL_ANALYSIS_LINE_CHART]: PanelKey.RCA_FOLLOW_UP_PANEL,
  [FollowUpType.RCA_FROM_KPI_ANALYSIS_LINE_CHART]: PanelKey.RCA_FOLLOW_UP_PANEL,
  [FollowUpType.FUNNEL_ANALYSIS_CONVERSION]: PanelKey.FUNNEL_ANALYSIS_CONVERSION_FOLLOW_UP_PANEL,
  [FollowUpType.GOAL_DRIVERS_ANALYSIS_IMPROVE_FEATURE_ADOPTION]:
    PanelKey.GOAL_DRIVERS_ANALYSIS_IMPROVE_FEATURE_ADOPTION_FOLLOW_UP_PANEL,
  [FollowUpType.RETENTION_ANALYSIS_BUCKET_INVESTIGATION]:
    PanelKey.RETENTION_ANALYSIS_BUCKET_INVESTIGATION_FOLLOW_UP_PANEL,
};
