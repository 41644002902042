import classNames from 'classnames';
import {GridTableProps} from './grid-table.types';
import classes from './grid-table.module.scss';
import {RefObject, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {debounce, min} from 'lodash';
import {Tooltip} from '@material-ui/core';
import {ArrowDownIcon} from 'ui-components';
import {EmptyState} from '../override';

type AllProps = GridTableProps;

interface ScrollStatus {
  left: boolean;
  right: boolean;
}

export const GridTableClasses = classes;

const useGrabX = (ref: RefObject<HTMLDivElement>, other?: RefObject<HTMLDivElement>) => {
  const [isGrabbing, setIsGrabbing] = useState(false);
  const [startX, setStartX] = useState(0);

  const onMouseDown = useCallback(
    (e: any) => {
      setIsGrabbing(true);
      setStartX(e.pageX - ref.current.offsetLeft + ref.current.scrollLeft);
    },
    [setIsGrabbing, setStartX, ref]
  );
  const onMouseMove = useCallback(
    (e: any) => {
      if (!isGrabbing) {
        return;
      }
      ref.current.scrollLeft = -1 * (e.pageX - ref.current.offsetLeft - startX);
      if (other && other.current) {
        other.current.scrollLeft = -1 * (e.pageX - ref.current.offsetLeft - startX);
      }
    },
    [isGrabbing, startX, ref, other]
  );
  const onMouseUp = useCallback(
    (e: any) => {
      setIsGrabbing(false);
    },
    [setIsGrabbing]
  );

  useEffect(() => {
    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('mousedown', onMouseDown);
    return () => {
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousedown', onMouseDown);
    };
  }, [onMouseUp, onMouseDown]);

  return {onMouseDown, onMouseMove, onMouseUp};
};

const DEFAULT_CONTENT_WRAPPER = (item, children) => children;

export const GridTable = (props: AllProps) => {
  const {
    data,
    dataKey,
    columns: columnsFromProps,
    onSort,
    orderBy,
    order,
    total,
    page,
    perPage,
    onPageChange,
    onRowClicked,
    pagination,
    className,
    paginationMode,
    emptyStateProps,
    emptyStateRow,
    // todo
    // selectedKeys,
    // allowSelection,
    // onSelectedKeysChange,
    selectedItemKey,
    rowClassName,
    contentWrapper = DEFAULT_CONTENT_WRAPPER,
    isLoading,
    footer,
  } = props;
  const [scrollStatus, setScrollStatus] = useState<ScrollStatus>({
    left: false,
    right: false,
  });
  const headersRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const grabProps = useGrabX(headersRef, bodyRef);
  // todo add selection
  const columns = useMemo(() => {
    return columnsFromProps.filter(c => c.hidden !== true);
  }, [columnsFromProps]);
  const prevDisabled = pagination ? page <= 0 : true;
  const nextDisabled = pagination ? page + 1 >= total / perPage : true;
  // const selectedKeysSet = useMemo(() => new Set(selectedKeys), [selectedKeys]);
  // const onSelectionChange = useCallback(
  //   (key: AcceptedSelectionKey) => {
  //     if (selectedKeysSet.has(key)) {
  //       return onSelectedKeysChange(selectedKeys.filter(i => i !== key));
  //     }
  //     return onSelectedKeysChange([key, ...selectedKeys]);
  //   },
  //   [onSelectedKeysChange, selectedKeys, selectedKeysSet]
  // );
  const gridTemplateColumns = useMemo(
    () =>
      columns
        .map(c => {
          let def = '';
          if (c.stretch) {
            def = c.width ? `minmax(${c.width}, 1fr)` : '1fr';
          } else {
            def = c.width ? c.width : '1fr';
          }
          return def;
        })
        .join(' '),
    [columns]
  );
  const hasPagination = useMemo(
    () => total > 0 && pagination && paginationMode === 'pages',
    [total, pagination, paginationMode]
  );
  const columnsMeta = useMemo(() => {
    const meta = {};
    for (const c of columns) {
      meta[c.key] = {
        className: classNames(
          c.sticky && classes.Sticky,
          c.sticky ? classes[`sticky-${c.sticky}`] : undefined,
          c.sticky === 'left' && scrollStatus.left && classes.Active,
          c.sticky === 'right' && scrollStatus.right && classes.Active
        ),
        style: {
          zIndex: c.sticky ? 10 : 1,
        },
        grabable: (scrollStatus.left || scrollStatus.right) && !c.sticky,
      };
    }
    return meta;
  }, [columns, scrollStatus]);
  useLayoutEffect(() => {
    if (!bodyRef.current || !headersRef.current) {
      return;
    }
    // sync scroll-x between headers and body
    const syncScrollListener = e => {
      headersRef.current.scrollLeft = e.target.scrollLeft;
    };
    const getScrollStatusListener = debounce(
      e => {
        const {target} = e;
        const {width} = target.getBoundingClientRect();
        const scrollLeft = target.scrollLeft;
        setScrollStatus({
          left: scrollLeft > 0,
          right: target.scrollWidth - scrollLeft > width,
        });
      },
      50,
      {leading: true, trailing: true}
    );
    bodyRef.current.addEventListener('scroll', syncScrollListener);
    bodyRef.current.addEventListener('scroll', getScrollStatusListener);
    getScrollStatusListener({target: bodyRef.current} as any);

    // https://stackoverflow.com/a/67069936
    let bodyRefCurrent = null;
    if (bodyRef.current) {
      bodyRefCurrent = bodyRef.current;
    }
    return () => {
      if (bodyRefCurrent) {
        bodyRefCurrent.removeEventListener('scroll', syncScrollListener);
        bodyRefCurrent.removeEventListener('scroll', getScrollStatusListener);
      }
    };
  }, [bodyRef, headersRef, setScrollStatus, data]);

  return (
    <div className={classNames(classes.GridTableWrapper, className)}>
      <div className={classes.GridTable}>
        <div ref={headersRef} className={classes.Headers} style={{gridTemplateColumns}}>
          {columns.map((c, idx) => (
            <div
              {...(columnsMeta[c.key].grabable ? (grabProps as any) : {})}
              key={c.key}
              className={classNames(
                classes.Header,
                columnsMeta[c.key].className,
                columnsMeta[c.key].grabable && classes.Grabable
              )}
              style={columnsMeta[c.key].style}
            >
              <div
                onClick={e => c.sortable && onSort && onSort(e, c.key)}
                className={classNames(
                  classes.HeaderContent,
                  c.sortable && onSort && classes.Sortable,
                  c.key === orderBy && classes.Sorted
                )}
              >
                <Tooltip title={c.helperText ? c.helperText : ''} placement={'top'}>
                  <span className={classes.Title}>{c.title}</span>
                </Tooltip>
                {c.sortable && c.key === orderBy && (
                  <div
                    className={classNames(classes.SortIndicator, order === 'asc' && classes.Flip)}
                  >
                    <ArrowDownIcon className={classes.SortIcon} />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        <div
          ref={bodyRef}
          className={classNames(classes.Body, hasPagination && classes.HasPagination)}
        >
          {data.map((item, index) =>
            contentWrapper(
              item,
              <div
                onClick={onRowClicked ? () => onRowClicked(item) : undefined}
                key={`row_${item[dataKey]}_${index}`}
                className={classNames(
                  classes.Row,
                  selectedItemKey && selectedItemKey === item[dataKey] && classes.Selected,
                  onRowClicked && classes.Clickable,
                  rowClassName
                    ? typeof rowClassName === 'function'
                      ? rowClassName(item)
                      : rowClassName
                    : undefined
                )}
                style={{gridTemplateColumns}}
              >
                {columns.map(c => (
                  <div
                    key={c.key}
                    className={classNames(classes.Cell, columnsMeta[c.key].className)}
                    style={columnsMeta[c.key].style}
                  >
                    <div
                      className={classNames(
                        classes.CellContent,
                        c.align === 'right' && classes.AlignRight
                      )}
                    >
                      {c.showOnHover && <div className={classes.ShowOnHover}>{c.render(item)}</div>}
                      {!c.showOnHover && c.render(item)}
                    </div>
                  </div>
                ))}
              </div>
            )
          )}
          {emptyStateRow && data.length === 0 && !isLoading && (
            <div className={classes.EmptyStateRow}>{emptyStateRow}</div>
          )}
          {footer && <div className={classes.FooterRow}>{footer}</div>}
        </div>
        {total > 0 && pagination && paginationMode === 'pages' && (
          <div className={classes.Footer}>
            <div className={classNames(classes.Pagination, isLoading && classes.Loading)}>
              <div className={classes.Status}>{`${1 + page * perPage}-${min([
                (page + 1) * perPage,
                total,
              ])} of ${total}`}</div>
              <div className={classes.Navigation}>
                <span
                  onClick={e => !isLoading && !prevDisabled && onPageChange(e, page - 1)}
                  className={classNames(classes.Button, prevDisabled && classes.Disabled)}
                >
                  {'<'}
                </span>
                <span
                  onClick={e => !isLoading && !nextDisabled && onPageChange(e, page + 1)}
                  className={classNames(classes.Button, nextDisabled && classes.Disabled)}
                >
                  {'>'}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
      {data.length === 0 && !isLoading && emptyStateProps && (
        <div className={classes.EmptyStateWrapper}>
          <EmptyState {...emptyStateProps} />
        </div>
      )}
    </div>
  );
};
