import {AIConversationMessageType} from '../../../../../../objects/models/ai-chat.model';
import {MarkdownMessage} from './components/markdown-message/markdown-message.component';
import {SelectKPIMessage} from './components/select-kpi-message/select-kpi-message.component';
import {KPIGraphMessage} from './components/kpi-graph-message/kpi-graph-message.component';

export const MESSAGE_RENDERER_MAPPING = {
  [AIConversationMessageType.TEXT]: MarkdownMessage,
  [AIConversationMessageType.USER_CHOOSE_KPI]: SelectKPIMessage,
  [AIConversationMessageType.KPI_GRAPH]: KPIGraphMessage,
};
