import classes from './analysis-type-image.module.scss';
import {AnalysisType} from '../../../../objects/models/analysis-type.model';
import classNames from 'classnames';

interface OwnProps {
  analysisType: AnalysisType;
  className?: string;
}

type AllProps = OwnProps;

export const AnalysisTypeImage = (props: AllProps) => {
  const {analysisType, className} = props;

  return (
    <div className={classNames(classes.CardImageContainer, className)}>
      <div className={classes.CardImageInnerContainer}>
        {analysisType.image && (
          <img
            className={classes.CardImage}
            src={analysisType.image}
            alt={analysisType.staticName}
          />
        )}
      </div>
    </div>
  );
};
