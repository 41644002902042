import {
  RCAInsights,
  RCAInsightsSentenceKeys,
} from '../../../../../../../../../insights/rca/objects';

import {useMemo} from 'react';
import {isArray} from 'lodash';
import {useTranslation} from 'react-i18next';
import sharedClasses from '../../rca-executive-summery.module.scss';
import classes from '../../rca-executive-summery.module.scss';
import TransKeys from 'translations';
import {
  SentenceContainer,
  SentencePart,
} from '../../../../../../../../../insights/components/shared-sentence-parts/shared-sentence-parts.components';
import {NoExplainersMsg} from '../no-explainers-msg/no-explainers-msg.component';
import {RCAInsightSectionComponent} from '../../../../../../../../../insights/components/rca-insight-section/rca-insight-section.component';
import {ChangeDescriptionAlias} from '../rca-executive-summary-orchestrator/rca-executive-summary-orchestrator.component';
import {RCAMode} from 'ui-components';
import classNames from 'classnames';

interface OwnProps extends Omit<RCAInsights, 'homepageMetric'> {
  changeDescriptionAlias: ChangeDescriptionAlias;
  mode: RCAMode;
}

type AllProps = OwnProps;

export const SegmentsInsightsSection = (props: AllProps) => {
  const {
    potentialDrivers,
    interestingSegmentsPerformanceVsPriorWeek,
    changeDescriptionAlias,
    mode,
  } = props;
  const {t} = useTranslation();
  const showNoExplainersMsg = useMemo(
    () =>
      isArray(potentialDrivers?.sentences) &&
      potentialDrivers.sentences.length === 1 &&
      potentialDrivers.sentences[0].key === RCAInsightsSentenceKeys.NO_EXPLAINERS,
    [potentialDrivers]
  );

  const rcaInsightsTitleTexts = useMemo(() => {
    const hasExplainers = !showNoExplainersMsg;
    return {
      partOne: t(
        TransKeys.DOCUMENT_VIEWER.RCA_EXECUTIVE_SUMMARY[
          `${hasExplainers ? 'EXPLAINERS' : 'HIGHLIGHTED'}_TITLE`
        ].PART_ONE,
        {
          changeDescription: changeDescriptionAlias.toUpperCase(),
        }
      ),
      partTwo: t(
        TransKeys.DOCUMENT_VIEWER.RCA_EXECUTIVE_SUMMARY[
          `${mode === RCAMode.LOOPS_ALGO ? 'EXPLAINERS' : 'HIGHLIGHTED'}_TITLE`
        ].PART_TWO
      ),
    };
  }, [showNoExplainersMsg, t, changeDescriptionAlias, mode]);

  if (
    !potentialDrivers ||
    !potentialDrivers.sentences ||
    (potentialDrivers.sentences[0].key === RCAInsightsSentenceKeys.NO_EXPLAINERS &&
      !interestingSegmentsPerformanceVsPriorWeek.key)
  ) {
    return null;
  }

  return (
    <div className={sharedClasses.ExecutiveSummarySection}>
      <SentenceContainer>
        <SentencePart text={rcaInsightsTitleTexts.partOne} className={classes.SubTitle} />
        <SentencePart
          text={rcaInsightsTitleTexts.partTwo}
          className={classNames(classes.SubTitle, classes.Dark)}
        />
      </SentenceContainer>
      {showNoExplainersMsg && <NoExplainersMsg />}
      {!showNoExplainersMsg && (
        <RCAInsightSectionComponent {...potentialDrivers} sectionKey={potentialDrivers.key} />
      )}
      {interestingSegmentsPerformanceVsPriorWeek.key && (
        <RCAInsightSectionComponent
          {...interestingSegmentsPerformanceVsPriorWeek}
          sectionKey={interestingSegmentsPerformanceVsPriorWeek.key}
        />
      )}
    </div>
  );
};
