import classes from './sample-classes-table.module.scss';

import {useTranslation} from 'react-i18next';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {useCallback, useMemo, useState} from 'react';
import Fuse from 'fuse.js';
import {exists, number2k} from 'front-core';
import {
  AdvancedTable,
  TableColumn as TableColumnDefinition,
} from '../../../shared/components/general/table/table.component';
import TransKeys from 'translations';
import {SampleClass} from '../../../../objects/models/sample-classes-model.model';
import {EmptyState} from '../../../shared/components/general/override';
import {SearchInput} from 'ui-components';
import {TableEntity} from '../../../../objects/models/table.model';

const SAMPLE_CLASSES = 'sampleClasses';
const FUSE_OPTIONS = {
  threshold: 0.3,
  ignoreLocation: true,
  keys: [{name: 'name', weight: 2}, 'count', 'value'],
};
interface OwnProps {
  classes: SampleClass[];
  entity?: TableEntity;
  sampleClassesTitle?: string;
  emptyStateText?: string;
  noResultFoundText?: string;
}

type AllProps = OwnProps;

const SampleClassesTable = (props: AllProps) => {
  const {
    classes: classesFromProps,
    entity: entityFromProps,
    emptyStateText: emptyStateTextFromProps,
    noResultFoundText: noResultFoundTextFromProps,
    sampleClassesTitle,
  } = props;
  // Hooks
  const {t} = useTranslation();
  const {getEntityName, defaultTableEntity} = useProductData();
  const [searchText, setSearchText] = useState('');
  // Memos
  const entity = useMemo(
    () => entityFromProps || defaultTableEntity,
    [entityFromProps, defaultTableEntity]
  );
  const sampleClasses = useMemo(() => classesFromProps || [], [classesFromProps]);
  const fuse = useMemo(() => new Fuse(sampleClasses, FUSE_OPTIONS), [sampleClasses]);
  const totalEntityCount = useMemo(
    () => sampleClasses.reduce((acc, curr) => acc + curr.count, 0),
    [sampleClasses]
  );
  const emptyStateText = useMemo(
    () => emptyStateTextFromProps || t(TransKeys.SAMPLE_CLASSES_TABLE.DEFAULTS.EMPTY_STATE_TITLE),
    [emptyStateTextFromProps, t]
  );
  const noResultFoundText = useMemo(
    () =>
      noResultFoundTextFromProps ||
      t(TransKeys.SAMPLE_CLASSES_TABLE.DEFAULTS.NO_SAMPLES_FOUND_IN_SEARCH),
    [noResultFoundTextFromProps, t]
  );
  // Callbacks
  const calculatePercentage = useCallback(
    (count: number) =>
      totalEntityCount > 0 ? `${number2k((count / totalEntityCount) * 100)}%` : '0%',
    [totalEntityCount]
  );
  const displayedSampleClasses = useMemo(() => {
    if (!exists(searchText)) {
      return sampleClasses;
    }
    return fuse.search(searchText).map(result => result.item);
  }, [searchText, sampleClasses, fuse]);
  // Table columns
  const tableColumnDefinition: TableColumnDefinition[] = useMemo(
    () => [
      {
        key: SAMPLE_CLASSES,
        title: sampleClassesTitle
          ? sampleClassesTitle.toUpperCase()
          : t(TransKeys.SAMPLE_CLASSES_TABLE.COLUMNS.SAMPLES.DEFAULT_TITLE).toUpperCase(),
        width: '40%',
        render: (sampleClasses: SampleClass) => <span>{sampleClasses.name}</span>,
      },
      {
        key: 'entityCount',
        title: getEntityName(entity, true).toUpperCase(),
        width: '20%',
        render: (sampleClasses: SampleClass) =>
          exists(sampleClasses.count) ? <span>{number2k(sampleClasses.count)}</span> : null,
      },
      {
        key: 'entityPercentage',
        title: t(TransKeys.SAMPLE_CLASSES_TABLE.COLUMNS.ENTITY_PERCENTAGE.TITLE).toUpperCase(),
        width: '40%',
        render: (sampleClass: SampleClass) =>
          exists(sampleClass.count) ? <span>{calculatePercentage(sampleClass.count)}</span> : null,
      },
    ],
    [t, sampleClassesTitle, getEntityName, entity, calculatePercentage]
  );

  // Sampling classes
  if (displayedSampleClasses.length === 0 && !exists(searchText)) {
    return (
      <div className={classes.TabWrapper}>
        <EmptyState size={'small'} title={emptyStateText} />
      </div>
    );
  }
  return (
    <div className={classes.TabWrapper}>
      <SearchInput
        placeholder={t(TransKeys.GENERAL.LABELS.SEARCH)}
        value={searchText}
        onChange={setSearchText}
        className={classes.SearchInput}
      />
      {displayedSampleClasses.length === 0 && exists(searchText) && (
        <EmptyState size={'small'} title={noResultFoundText} />
      )}
      {displayedSampleClasses.length > 0 && (
        <AdvancedTable
          dataKey={SAMPLE_CLASSES}
          columns={tableColumnDefinition}
          data={displayedSampleClasses}
          pagination={true}
        />
      )}
    </div>
  );
};
export default SampleClassesTable;
