import {number2k} from 'front-core';
import {TableEntity} from '../../objects/models/table.model';
import pluralize from 'pluralize';

export const formatPercentageValue = (value: number): string => `${number2k(value * 100)}%`;

export const formatPercentageValueWithPrefix = (value: number): string =>
  `${value > 0 ? '+' : ''}${formatPercentageValue(value)}`;

export const getArrowIndication = (value: number): string => {
  if (value === 0) {
    return '';
  } else if (value > 0) {
    return '↑';
  } else if (value < 0) {
    return '↓';
  } else {
    return '';
  }
};

export const extractEntityNameFromProductEntities = (
  entity: TableEntity,
  productEntities: {},
  lowerCase = true,
  pluralizeEntity = true
): string => {
  const productEntity = productEntities[entity]?.name || entity;
  const res = lowerCase ? productEntity.toLowerCase() : productEntity;
  return pluralizeEntity ? pluralize(res) : res;
};
