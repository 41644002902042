import {yupResolver} from '@hookform/resolvers/yup';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {TextInput} from 'ui-components';
import {Footer} from '../../footer/footer.component';
import classes from './databricks-form.module.scss';
import {isEmpty} from 'lodash';
import {SourceWizardFormLayout} from '../../source-wizard-form-layout/source-wizard-form-layout.component';
import {SourceWizardLabelWrapper} from '../../source-wizard-label-wrapper/source-wizard-label-wrapper.component';
import {DatabricksFormSchema, databricksSchema} from '../../../../../../../objects/dto/source.dto';

export type DatabricksFormProps = {
  onSubmit?: (values: DatabricksFormSchema) => void;
};

export const DatabricksForm = (props: DatabricksFormProps) => {
  const {onSubmit} = props;
  const formMethods = useForm({
    resolver: yupResolver(databricksSchema),
  });
  const {
    handleSubmit,
    formState: {errors, isValid},
    control,
  } = formMethods;

  return (
    <SourceWizardFormLayout
      footer={
        <Footer
          buttonText="Continue"
          onClickSubmit={handleSubmit(onSubmit)}
          disabledCTAButton={!isEmpty(errors) || !isValid}
        />
      }
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.DatabricksForm}>
          <SourceWizardLabelWrapper label="Server Hostname">
            <Controller
              render={({field, fieldState: {error}}) => (
                <TextInput
                  size="large"
                  placeholder={'Type here'}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(error)}
                  required
                />
              )}
              name={'serverHostname'}
              control={control}
            />
          </SourceWizardLabelWrapper>
          <SourceWizardLabelWrapper label="Access Token">
            <Controller
              render={({field, fieldState: {error}}) => (
                <TextInput
                  size="large"
                  placeholder={'Type here'}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(error)}
                  required
                  type="password"
                />
              )}
              name={'accessToken'}
              control={control}
            />
          </SourceWizardLabelWrapper>
          <SourceWizardLabelWrapper label="Http Path">
            <Controller
              render={({field, fieldState: {error}}) => (
                <TextInput
                  size="large"
                  placeholder={'Type here'}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(error)}
                />
              )}
              name={'httpPath'}
              control={control}
            />
          </SourceWizardLabelWrapper>
        </form>
      </FormProvider>
    </SourceWizardFormLayout>
  );
};
