import classNames from 'classnames';
import classes from './threads-list.module.scss';
import {AIConversationThread} from '../../../../../../objects/models/ai-chat.model';
import {Button, SearchInput} from 'ui-components';
import {useCallback, useMemo, useState} from 'react';
import moment from 'moment';
import {TIME_FORMATS} from '../../../../../../constants/time-formats';
import {exists} from 'front-core';
import {ThreadListItem} from './components/thread-list-item/thread-list-item.component';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../../../constants/translation-keys';

interface OwnProps {
  threads: AIConversationThread[];
  onSelectThread: (threadId: number) => void;
  onNewSession: () => void;
  selectedThreadId?: number;
  isLoading?: boolean;
  className?: string;
  onEditThreadTitle?: (threadId: number, newTitle: string) => void;
  onDeleteThread?: (threadId: number) => void;
}

type AllProps = OwnProps;

export const ThreadsList = (props: AllProps) => {
  const {
    threads,
    selectedThreadId,
    onEditThreadTitle,
    onDeleteThread,
    onSelectThread,
    onNewSession,
    className,
  } = props;

  const {t} = useTranslation();
  const [searchSessionsValue, setSearchSessionsValue] = useState('');

  const onSearchSessions = useCallback(searchValue => {
    setSearchSessionsValue(searchValue);
  }, []);

  const sessionGroups = useMemo(() => {
    const res = {};
    const threadList = threads.filter(v => v.title.includes(searchSessionsValue));
    threadList.forEach(t => {
      const date = moment(t.createdOn).format('YYYY-MM-DD');
      if (!res[date]) {
        res[date] = [];
      }
      res[date].push(t);
    });

    const groups = [];
    Object.keys(res).forEach(k => {
      const date = moment(k, 'YYYY-MM-DD');
      let groupLabel = '';
      if (date.isSame(moment(), 'day')) {
        groupLabel = 'today';
      } else if (date.isSame(moment().subtract(1, 'day'), 'day')) {
        groupLabel = 'yesterday';
      }
      groups.push({
        date: groupLabel ? groupLabel : date.format(TIME_FORMATS.READABLE_DATE_NO_YEAR),
        threads: res[k],
      });
    });

    return groups;
  }, [threads, searchSessionsValue]);

  return (
    <div className={classNames(classes.ThreadsList, className)}>
      <Button
        variant="contained"
        className={classNames(
          classes.CreateNewSession,
          !exists(selectedThreadId) && classes.Disabled
        )}
        onClick={onNewSession}
      >
        {t(TransKeys.GEN_AI_BOT.THREADS.ACTIONS.CREATE.LABEL)}
      </Button>
      <SearchInput
        className={classes.SearchInput}
        value={searchSessionsValue}
        placeholder={t(TransKeys.GENERAL.LABELS.SEARCH)}
        onChange={onSearchSessions}
      />
      {sessionGroups.map((g, idx) => (
        <div key={idx} className={classes.Group}>
          <div className={classes.GroupTitle}>{g.date}</div>
          {g.threads.map(t => (
            <div
              key={t.id}
              onClick={() => onSelectThread(t.id)}
              className={classNames(classes.Thread, selectedThreadId === t.id && classes.Selected)}
            >
              <ThreadListItem
                title={t.title}
                onEdit={newTitle => onEditThreadTitle(t.id, newTitle)}
                onDelete={() => onDeleteThread(t.id)}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
