import classes from './analysis-artifacts.module.scss';
import {AnalysisResultArtifact} from '../../../../objects/models/analysis-result.model';
import {DownloadLightIcon, DropdownButton, IconButton, PopoverWrapper} from 'ui-components';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';

interface OwnProps {
  artifacts: AnalysisResultArtifact[];
  onDownloadArtifact: (artifactId: number) => void;
  className?: string;
}

type AllProps = OwnProps;

export const AnalysisArtifacts = (props: AllProps) => {
  const {artifacts, onDownloadArtifact, className} = props;
  const {t} = useTranslation();

  return (
    <PopoverWrapper
      buttonRenderer={props => (
        <DropdownButton
          className={className}
          onClick={props.onClick}
          label={'Attachments'}
          isOpen={props.isOpen}
          rounded
          border={false}
        />
      )}
    >
      <div className={classes.AnalysisArtifacts}>
        {artifacts.map(a => (
          <div className={classes.Artifact} key={a.id}>
            <div className={classes.Name}>{a.name}</div>
            <div className={classes.Action}>
              <IconButton
                icon={DownloadLightIcon}
                onClick={() => onDownloadArtifact(a.id)}
                tooltipText={t(TransKeys.GENERAL.ACTIONS.DOWNLOAD)}
              />
            </div>
          </div>
        ))}
      </div>
    </PopoverWrapper>
  );
};
