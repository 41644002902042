import {FancyHeader, ModalLayout} from 'ui-components';
import TransKeys from '../../constants/translation-keys';
import {EmptyState} from '../../modules/shared/components/general/override';
import {useTranslation} from 'react-i18next';
import {useProductData} from '../hooks/use-product-data.hook';
import sharedClasses from './shared.module.scss';
import {ComponentType} from 'react';

export interface WithModalInactiveSourceHandlerInjectedProps {
  onClose?: (e) => void;
}
export function withModalInactiveSourceHandler<P>(
  WrappedComponent: ComponentType<P & WithModalInactiveSourceHandlerInjectedProps>
): ComponentType<P & WithModalInactiveSourceHandlerInjectedProps> {
  const WithModalInactiveSourceHandler = (
    props: P & WithModalInactiveSourceHandlerInjectedProps
  ) => {
    const {t} = useTranslation();
    const {isSourceActive} = useProductData();
    if (isSourceActive) {
      return <WrappedComponent {...props} />;
    }
    return (
      <div className={sharedClasses.ErrorPanelContainer}>
        <ModalLayout>
          <div className={sharedClasses.ErrorPanel}>
            <div className={sharedClasses.Header}>
              <FancyHeader
                title={t(TransKeys.INACTIVE_SOURCE_MODAL.TITLE)}
                onClose={(props as any)?.onClose}
              />
            </div>
            <div className={sharedClasses.Body}>
              <EmptyState title={t(TransKeys.INACTIVE_SOURCE_MODAL.DESCRIPTION)} />
            </div>
          </div>
        </ModalLayout>
      </div>
    );
  };

  const wrappedComponentName =
    WrappedComponent.displayName || (WrappedComponent as any).name || 'Component';
  (WithModalInactiveSourceHandler as any).displayName =
    `WithModalInactiveSourceHandler(${wrappedComponentName})`;

  return WithModalInactiveSourceHandler;
}
