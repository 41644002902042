import React, {useEffect} from 'react';
import {ToastContainer} from 'react-toastify';
import {composition} from 'front-core';
import AppRouting from './app-routing.component';
import HttpClient from '../../services/http-client.service';
import {logout} from '../../store/auth/auth.actions';
import {useDispatch, useSelector} from 'react-redux';
import {appFirstAction, setScopeProduct} from '../../store/core/core.actions';
import {getReducedLoadingStateSelector} from '../../store/store.selectors';
import {ActionKey} from '../../constants/action-key';
import {RootLoadingScreen} from './components/root-loading-screen/root-loading-screen.component';
import {CORE_STORE_KEY} from '../../store/core/core.store';
import classes from './app.module.scss';
import {NumberParam, useQueryParam} from 'use-query-params';

interface OwnProps {}

type AllProps = OwnProps;

export const AppComponent = (props: AllProps) => {
  const dispatch = useDispatch();
  const [changeScope, setChangeScope] = useQueryParam('setScope', NumberParam);
  const appReady = useSelector(state => state[CORE_STORE_KEY].appReady);
  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(ActionKey.APP_FIRST_ACTION)(state)
  );

  useEffect(() => {
    setChangeScope(undefined);
    changeScope && dispatch(setScopeProduct(changeScope));
  }, [changeScope, dispatch, setChangeScope]);

  useEffect(() => {
    const listener = err => {
      if (err?.status === 401 && appReady) {
        dispatch(logout());
      }
    };
    HttpClient.on('error', listener);

    if (!appReady) {
      !appReady && dispatch(appFirstAction());
    }

    return () => {
      HttpClient.removeListener('error', listener);
    };
  }, [dispatch, appReady]);

  if (!appReady || isLoading) {
    return <RootLoadingScreen />;
  }

  return (
    <>
      <ToastContainer
        toastClassName={classes.Toast}
        className={classes.ToastContainer}
        theme={'light'}
        position={'bottom-right'}
        autoClose={5000}
        hideProgressBar={false}
        closeButton={false}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
      />
      <AppRouting />
    </>
  );
};

const App = composition<OwnProps>(AppComponent);

export default App;
