import classNames from 'classnames';
import classes from './extended-parameters.module.scss';
import {ChevronDownIcon, IconButton} from 'ui-components';

interface OwnProps {
  label: string;
  children: any;
  isOpen?: boolean;
  onOpenChanged: () => void;
  className?: string;
  headClassName?: string;
}

type AllProps = OwnProps;

export const ExtendedParameters = (props: AllProps) => {
  const {label, isOpen, onOpenChanged, children, className, headClassName} = props;

  return (
    <div className={classNames(classes.ExtendedParameters, isOpen && classes.Show, className)}>
      <div className={classNames(classes.Head, headClassName)} onClick={_ => onOpenChanged()}>
        <IconButton
          className={classes.DropdownButton}
          icon={ChevronDownIcon}
          onClick={_ => onOpenChanged()}
        />
        <div className={classes.Label}>{label}</div>
      </div>
      <div className={classes.Children}>{children}</div>
    </div>
  );
};
