import {get, max, min} from 'lodash';
import {TIME_FORMATS} from '../constants/time-formats';
import moment from 'moment';
import {exists, ordinalSuffix as ordSuffix} from 'front-core';
import {withStopPropagation as withStopPropagationCore} from 'front-core';

export const contactSupport = () => window.open('mailto:support@getloops.ai');

export const isDefined = (v: any) => v !== null && v !== '' && v !== undefined;

export const limitNumberValue = (value: number, minValue: number, maxValue: number) =>
  min([max([value, minValue]), maxValue]);

export const pop = (obj: any, key: string) => {
  const val = obj[key];
  delete obj[key];
  return val;
};

export const preventSubmitOnEnter = e => {
  if (e.key !== 'Enter') {
    return;
  }
  if (e.target?.tagName === 'TEXTAREA') {
    return;
  }
  e.preventDefault();
};

export const toLocalTime = (
  dateString: string,
  format = TIME_FORMATS.DEFAULT_TIME_FORMAT,
  inputFormat = TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT
) => moment.utc(dateString, inputFormat).local().format(format);

export const ordinalSuffix = ordSuffix;

export const formatDate = (d: string) =>
  d ? moment(d, TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT).format(TIME_FORMATS.READABLE_DATE) : null;

const MOMENT_MONDAY_WEEKDAY_INDEX = 1;
export const closestMonday = (d: moment.Moment, before = false) => {
  const result = d.clone();
  if (result.day() === MOMENT_MONDAY_WEEKDAY_INDEX) {
    return result;
  }
  const direction = before ? -1 : 1;
  while (result.day() !== MOMENT_MONDAY_WEEKDAY_INDEX) {
    result.add(direction, 'day');
  }
  return result;
};

export const getMinimumDate = (
  dates: string[],
  dateFormat = TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT
): string => {
  if (!exists(dates[0])) {
    return dates[1];
  }
  if (!exists(dates[1])) {
    return dates[0];
  }
  const date1 = moment.utc(dates[0], dateFormat);
  const date2 = moment.utc(dates[1], dateFormat);
  if (date1.isBefore(date2)) {
    return date1.format(dateFormat);
  }
  return date2.format(dateFormat);
};

export const hasError = (errors: any, keys: string[]) =>
  keys.filter(k => get(errors, k) !== undefined).length > 0;

export const withStopPropagation = withStopPropagationCore;

export const createUndefinedObject = (keys: string[]) =>
  keys.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: undefined,
    }),
    {}
  );

export const hasErrors = (errors, keys) =>
  keys.filter(k => get(errors, k) !== undefined).length > 0;

export const greetings = () => {
  const myDate = new Date();
  const hours = myDate.getHours();
  let greet;

  if (hours < 12) greet = 'morning';
  else if (hours >= 12 && hours <= 17) greet = 'afternoon';
  else if (hours >= 17 && hours <= 24) greet = 'evening';

  return `Good ${greet}`;
};

export const getTimezone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const isSignificantValue = (value: number, lower: number, upper: number) =>
  exists(upper) && exists(lower) ? value > upper || value < lower : false;

export function splitAt<T>(arr: T[], index: number): [T[], T[]] {
  const clonedArray = [...arr];
  return [clonedArray.splice(0, index), clonedArray];
}
