import {PanelLayout} from '../../modules/shared/components/layout/panel-layout/panel-layout.component';
import {ComponentType} from 'react';

export interface WithPanelInjectedProps {
  onClose?: (e) => void;
}

export function withPanel<P>(
  WrappedComponent: ComponentType<P & WithPanelInjectedProps>
): ComponentType<P & WithPanelInjectedProps> {
  const WithPanel = (props: P & WithPanelInjectedProps) => {
    return (
      <PanelLayout {...props}>
        <WrappedComponent {...props} />
      </PanelLayout>
    );
  };
  return WithPanel;
}
