import {TrendModelSampleInsight} from '../objects';
import {formatPercentageValueWithPrefix, getArrowIndication} from '../../insights.utils';
import {
  SentenceContainer,
  SentencePart,
} from '../../components/shared-sentence-parts/shared-sentence-parts.components';

export const TrendModelSampleInsightFormatter = (props: TrendModelSampleInsight) => {
  const {sentenceParts} = props;

  return (
    <SentenceContainer>
      <SentencePart text={'The KPI'} />
      <SentencePart
        bold
        text={`${sentenceParts.trendDescription}d for ${sentenceParts.trendNumberOfPeriods}-weeks-in-a-row,`}
      />
      <SentencePart
        text={`Starting at ${sentenceParts.trendStartDate} with total ${sentenceParts.changeDescription} of`}
      />
      <SentencePart
        bold
        text={`${getArrowIndication(sentenceParts.value)} ${formatPercentageValueWithPrefix(
          sentenceParts.value
        )}`}
      />
    </SentenceContainer>
  );
};
