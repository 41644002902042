import {Opportunity} from '../../../../objects/models/opportunity.model';
import classes from './opportunity-actions-panel.module.scss';
import classNames from 'classnames';
import {ActionCard} from '../../components/action-card/action-card.component';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {getOpportunityNetworkRequest} from '../../../../http/opportunities.network-requests';
import {OPPORTUNITY_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {ActionIcon, BlockHeader, ModalLayout, Sentence} from 'ui-components';
import TransKeys from '../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {composition} from 'front-core';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';

interface OwnProps {
  opportunity?: Opportunity;
  [OPPORTUNITY_ID_PATH_PARAM]: number;
  onClose?: () => void;
}

type AllProps = OwnProps;

const SELECTED_OPPORTUNITY_KEY = SharedSelectionKeys.OPPORTUNITY_ACTIONS_PANEL__OPPORTUNITY;

export const OpportunityActionsPanelComponent = (props: AllProps) => {
  const {opportunity, onClose} = props;
  const {t} = useTranslation();

  return (
    <div className={classes.ActionsPanelContainer}>
      <ModalLayout onClose={onClose}>
        <div className={classNames(classes.ActionsPanel)}>
          <div className={classes.Header}>
            <Sentence text={opportunity?.title} />
          </div>
          <BlockHeader
            className={classes.ActionHeader}
            icon={ActionIcon}
            title={t(TransKeys.GENERAL.LABELS.ACTIONS)}
          />
          <div className={classes.List}>
            {opportunity?.actions.map(action => (
              <ActionCard className={classes.ActionCard} key={action.id} action={action} />
            ))}
          </div>
        </div>
      </ModalLayout>
    </div>
  );
};

export const OpportunityActionsPanel = composition<AllProps>(
  OpportunityActionsPanelComponent,
  withLoadBefore({
    opportunity: {
      selectedKey: SELECTED_OPPORTUNITY_KEY,
      actionKey: SELECTED_OPPORTUNITY_KEY,
      request: getOpportunityNetworkRequest,
      mapPayloadFromProps: props => props[OPPORTUNITY_ID_PATH_PARAM],
      shouldCall: props => props[OPPORTUNITY_ID_PATH_PARAM] !== undefined,
    },
  })
);
