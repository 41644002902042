import {useMemo} from 'react';
import {HomepageFunnel} from '../../../../../../../../objects/models/homepage.model';
import {
  ModelSampleSeriesType,
  ModelSeriesGranularity,
} from '../../../../../../../../objects/models/model-sample-series.model';
import {sum} from 'lodash';
import {FunnelChart, FunnelChartMode} from 'ui-components';
import {useProductData} from '../../../../../../../../core/hooks/use-product-data.hook';
import pluralize from 'pluralize';

interface OwnProps {
  funnel: HomepageFunnel;
  funnelChartMode: FunnelChartMode;
  onExploreConversion?: (stepId: number) => void;
  className?: string;
}

type AllProps = OwnProps;
const FUNNEL_OPTIONS = {
  stepMaxHeight: 240,
  showChangeMode: false,
  variant: 'ghost' as any,
};

export const HomepageFunnelChart = (props: AllProps) => {
  const {funnel, funnelChartMode, onExploreConversion, className} = props;
  const {productEntitiesMap} = useProductData();

  const funnelLabels = useMemo(() => funnel.steps.map(s => s.name), [funnel]);
  const funnelData = useMemo(() => {
    const usersPerStep = funnel.series
      .filter(
        s =>
          s.granularity === ModelSeriesGranularity.DAY &&
          s.seriesType === ModelSampleSeriesType.LINEAR_FUNNEL_STEP_COUNT_OVER_TIME
      )
      .map(s => ({
        ...s,
        order: funnel.steps.findIndex(step => step.id === s.signalId),
      }))
      .sort((a, b) => a.order - b.order)
      // exclude partial samples && sum all users
      .map(s => sum(s.samples.map(v => (v.isPartial ? 0 : v.value))));
    return {
      id: 'all-users',
      label: `All ${pluralize(productEntitiesMap[funnel.entity].name)}`,
      data: usersPerStep,
    };
  }, [funnel, productEntitiesMap]);

  return (
    <FunnelChart
      labels={funnelLabels}
      datasets={[funnelData] as any}
      options={FUNNEL_OPTIONS}
      mode={funnelChartMode}
      onExplore={onExploreConversion}
      className={className}
    />
  );
};
