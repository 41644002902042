import {useMemo} from 'react';
import {Select, SwitchActions} from 'ui-components';
import {TableType} from '../../../../../objects/models/table.model';
import {capitalize} from 'front-core';

export enum InputVariant {
  SWITCH = 'switch',
  SELECT = 'select',
}

interface OwnProps {
  variant?: InputVariant;
  value: any;
  onChange: (tableType: any) => void;
  disabled?: boolean;
  error?: boolean;
  placeholder?: string;
}

type AllProps = OwnProps;

const TableTypeInput = (props: AllProps) => {
  const {variant = InputVariant.SWITCH, value, onChange, disabled, error, placeholder} = props;

  const typeOptions = useMemo(
    () =>
      [TableType.EVENTS, TableType.ENTITY_PROPERTIES, TableType.STATE].map(tableType => ({
        key: tableType,
        value: tableType,
        label: capitalize(tableType.replace('_', ' ')),
        onClick: () => onChange(tableType as TableType),
        isActive: value === tableType,
      })),
    [value, onChange]
  );

  if (variant === InputVariant.SELECT) {
    return (
      <Select
        value={value}
        onChange={value => onChange(value as unknown as string)}
        placeholder={placeholder}
        disabled={disabled}
        error={error}
        options={{options: typeOptions}}
        searchable={false}
        clearable={false}
        capitalize={false}
        fullWidth
      />
    );
  }

  return <SwitchActions actions={typeOptions} showActionsLabel disabled={disabled} />;
};

export default TableTypeInput;
