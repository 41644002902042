import classes from './analysis-result-running-empty-state.module.scss';
import {ImagesResource} from '../../../../../../assets/images';
import {AnimatedProgress, Button} from 'ui-components';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {useMemo} from 'react';
import classNames from 'classnames';
import {AnalysisResultBaseEmptyStateProps} from '../analysis-result-base-empty-state/analysis-result-base-empty-state.component';

interface OwnProps extends Omit<AnalysisResultBaseEmptyStateProps, 'title' | 'buttonText'> {
  progress: number;
  title?: string;
  buttonText?: string;
}

type AllProps = OwnProps;

export const AnalysisResultRunningEmptyState = (props: AllProps) => {
  const {
    progress,
    subTitle,
    title: titleFromProps,
    buttonText: btnTextFromProps,
    onClick,
    className,
  } = props;
  const {t} = useTranslation();
  const {title, buttonText} = useMemo(
    () => ({
      title: titleFromProps || t(TransKeys.ANALYSIS_RESULT.RUNNING_EMPTY_STATE.TITLE),
      buttonText: btnTextFromProps || t(TransKeys.ANALYSIS_RESULT.RUNNING_EMPTY_STATE.BTN_TEXT),
    }),
    [t, titleFromProps, btnTextFromProps]
  );

  return (
    <div className={classNames(classes.Container, className)}>
      <div className={classes.Content}>
        <div className={classes.ImageWrapper}>
          <img src={ImagesResource.app.telescopeV2} alt={''} />
        </div>
        <div className={classes.Title}>{title}</div>
        <AnimatedProgress value={progress} className={classes.ProgressContainer} />
        <div className={classes.SubTitle}>{subTitle}</div>
        <Button onClick={onClick} size={'large'}>
          {buttonText}
        </Button>
      </div>
    </div>
  );
};
