import {useContext, useEffect} from 'react';
import Pusher from 'pusher-js';
import appConfig from '../../../../config/app.config';
import {useCurrentUser} from '../../../../core/hooks/use-user.hook';
import {HttpClientContext, RequestType, toCamelCase} from 'front-core';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {useDispatch} from 'react-redux';
import {newNotification} from '../../../../store/notifications/notifications.actions';
import {modelUpdated} from '../../../../store/core/core.actions';
import {ModelKey} from '../../../../constants/model-key';

Pusher.logToConsole = process.env.NODE_ENV === 'development';

interface OwnProps {}

type AllProps = OwnProps;

enum PusherEventName {
  NOTIFICATION_EVENT = 'notification',
  ANALYSIS_RESULT_UPDATED = 'analysis_result_updated',
}

const generatePrivateChannelForUser = (productId: string, userId: string) =>
  `private-${productId}-${userId}`;
const generatePrivateProductChannelForUser = (productId: string) => `private-${productId}`;

const getPusherPrivateAuthKeyNetworkRequest = (socketId, channelName) => ({
  relativeUrl: `/app/pusher/auth`,
  method: RequestType.POST,
  body: {
    socket_id: socketId,
    channel_name: channelName,
  },
});

export const AppPusherSubscriber = (props: AllProps) => {
  const user = useCurrentUser();
  const {id: productId} = useProductData();
  const http = useContext(HttpClientContext);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!appConfig.pusherKey) {
      return;
    }
    const privateUserChannel = generatePrivateChannelForUser(productId, user.id);
    const privateProductChannel = generatePrivateProductChannelForUser(productId);
    const pusher = new Pusher(appConfig.pusherKey, {
      cluster: appConfig.pusherCluster,
      channelAuthorization: {
        customHandler: (params, cb) => {
          http
            .exec(getPusherPrivateAuthKeyNetworkRequest(params.socketId, params.channelName))
            .then(res => {
              cb(null, res.data);
            });
        },
      },
    });
    const userChannel = pusher.subscribe(privateUserChannel);
    userChannel.bind(PusherEventName.NOTIFICATION_EVENT, function (data) {
      dispatch(newNotification(toCamelCase(data)));
    });
    const productChannel = pusher.subscribe(privateProductChannel);
    productChannel.bind(PusherEventName.ANALYSIS_RESULT_UPDATED, function (data) {
      dispatch(modelUpdated(toCamelCase(data), ModelKey.ANALYSIS_RESULT));
    });
    return () => {
      pusher.unsubscribe(privateUserChannel);
      pusher.unsubscribe(privateProductChannel);
    };
  }, [user.id, productId, http, dispatch]);

  return null;
};
