import {yupResolver} from '@hookform/resolvers/yup';
import {useCallback, useMemo} from 'react';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {
  Button,
  FancyHeader,
  LabelWrapper,
  ModalLayout,
  TextInput,
  UserGroupLightIcon,
} from 'ui-components';
import classes from './team-form-panel.module.scss';
import {composition, OnSuccessActionHook} from 'front-core';
import {withDisableDemoProduct} from '../../../../core/hoc/with-disable-demo-product.hoc';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {getTeamNetworkRequest} from '../../../../http/teams.network-requests';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {TEAM_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {teamValidator, TeamDTO} from '../../../../objects/dto/team.dto';
import {useDispatch} from 'react-redux';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import {createTeam, updateTeam} from '../../../../store/teams/teams.actions';
import TransKeys from '../../../../constants/translation-keys';
import {useCurrentUser} from '../../../../core/hooks/use-user.hook';

type TeamFormPanelProps = {
  onSubmit?: (data: Partial<TeamDTO>) => void;
  onSuccess?: OnSuccessActionHook;
  onClose?: VoidFunction;
  team?: TeamDTO;
  cloneMode?: boolean;
  [TEAM_ID_PATH_PARAM]?: number;
};

const SELECTED_TEAM_KEY = SharedSelectionKeys.TEAM_FORM__TEAM;

export const TeamFormPanelComponent = (props: TeamFormPanelProps) => {
  const {onClose, team, cloneMode, onSubmit: onSubmitFromProps, onSuccess} = props;
  const {t} = useTranslation();
  const notify = useAmplitude();
  const user = useCurrentUser();
  const overrideData = useMemo(() => (cloneMode ? {id: undefined} : {}), [cloneMode]);

  const dispatch = useDispatch();
  const formMethods = useForm({
    defaultValues: {...(team ?? {}), ...overrideData},
    resolver: yupResolver(teamValidator),
  });

  const {
    handleSubmit,
    formState: {errors},
    control,
    watch,
  } = formMethods;
  const teamId = watch('id');
  const editMode = Boolean(teamId);

  const onSubmit = useCallback(
    (data: TeamDTO) => {
      if (onSubmitFromProps) {
        onSubmitFromProps(data);
        return;
      }
      const onActionSuccess = (res, action) => {
        onSuccess && onSuccess(res, action);
        onClose?.();
      };

      const action = editMode
        ? updateTeam(data, onActionSuccess)
        : createTeam(data, onActionSuccess);
      dispatch(action);
      notify(AmplitudeEvent.TEAM_SAVE_CLICKED, {
        user: user.id,
      });
    },
    [onSubmitFromProps, onSuccess, dispatch, editMode, notify, onClose, user.id]
  );

  return (
    <div className={classes.TeamFormContainer}>
      <ModalLayout
        footer={
          <div className={classes.Actions}>
            <Button variant="outlined" onClick={onClose}>
              {t(TransKeys.GENERAL.ACTIONS.CANCEL)}
            </Button>
            <Button onClick={handleSubmit(onSubmit)}>{t(TransKeys.GENERAL.ACTIONS.SAVE)}</Button>
          </div>
        }
      >
        <div className={classes.Team}>
          <FancyHeader
            icon={UserGroupLightIcon}
            title={
              editMode
                ? t(TransKeys.ACCOUNT_SETTINGS.TEAMS.ACTIONS.EDIT_TEAM.TITLE)
                : t(TransKeys.ACCOUNT_SETTINGS.TEAMS.ACTIONS.CREATE_TEAM.TITLE)
            }
            onClose={onClose}
            className={classes.TeamHeader}
          />
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)} className={classes.TeamForm}>
              <LabelWrapper
                label={t(
                  TransKeys.ACCOUNT_SETTINGS.TEAMS.ACTIONS.CREATE_TEAM.FORM.NAME_INPUT.LABEL
                )}
                error={Boolean(errors.name)}
                helperText={errors.name?.message}
              >
                <Controller
                  render={({field, fieldState: {error}}) => (
                    <TextInput
                      placeholder={t(
                        TransKeys.ACCOUNT_SETTINGS.TEAMS.ACTIONS.CREATE_TEAM.FORM.NAME_INPUT
                          .PLACEHOLDER
                      )}
                      value={field.value}
                      onChange={field.onChange}
                      error={Boolean(error)}
                      required
                    />
                  )}
                  name={'name'}
                  control={control}
                />
              </LabelWrapper>
            </form>
          </FormProvider>
        </div>
      </ModalLayout>
    </div>
  );
};

export const TeamFormPanel = composition<TeamFormPanelProps>(
  TeamFormPanelComponent,
  withDisableDemoProduct,
  withLoadBefore({
    team: {
      selectedKey: SELECTED_TEAM_KEY,
      actionKey: SELECTED_TEAM_KEY,
      request: getTeamNetworkRequest,
      mapPayloadFromProps: props => props[TEAM_ID_PATH_PARAM],
      shouldCall: props => props[TEAM_ID_PATH_PARAM] !== undefined,
    },
  })
);
