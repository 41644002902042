import {NRC} from './index';
import {RequestType, toCamelCase, toSnakeCase} from 'front-core';
import {
  AIChatMessageFeedbackDTO,
  ASKAIChatDTO,
  UpdateAIChatThreadDTO,
} from '../objects/dto/ai-chat.dto';

export const getAIChartThreadsNetworkRequest: NRC<void, void> = () => ({
  method: RequestType.GET,
  relativeUrl: `/app/ai-chat/threads`,
  responseTransformer: res => toCamelCase(res.data),
});

export const getAIChatSuggestionsNetworkRequest: NRC<void, void> = () => ({
  method: RequestType.GET,
  relativeUrl: `/app/ai-chat/suggestions`,
  responseTransformer: res => toCamelCase(res.data),
});

export const updateAIChartThreadNetworkRequest: NRC<UpdateAIChatThreadDTO, void> = (
  data: UpdateAIChatThreadDTO
) => ({
  method: RequestType.PUT,
  relativeUrl: `/app/ai-chat/threads/${data.threadId}`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const deleteAIChartThreadNetworkRequest: NRC<number, void> = (id: number) => ({
  method: RequestType.DELETE,
  relativeUrl: `/app/ai-chat/threads/${id}`,
  responseTransformer: res => toCamelCase(res.data),
});

export const sendAIChatMessageFeedbackNetworkRequest: NRC<AIChatMessageFeedbackDTO, void> = (
  data: AIChatMessageFeedbackDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/ai-chat/messages/${data.aiConversationMessageId}`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data, ['action.parameters', 'message']),
});

export const getAIChartThreadNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.GET,
  relativeUrl: `/app/ai-chat/threads/${id}`,
  responseTransformer: res =>
    toCamelCase(res.data, ['messages.*.action.parameters', 'messages.*.message']),
});

export const askAIChatNetworkRequest: NRC<ASKAIChatDTO, void> = (payload: ASKAIChatDTO) => ({
  method: RequestType.POST,
  relativeUrl: `/app/ai-chat/messages`,
  body: payload,
  bodyTransformer: toSnakeCase,
  responseTransformer: res =>
    toCamelCase(res.data, ['new_messages.*.action.parameters', 'new_messages.*.message']),
});
