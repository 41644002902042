import {FancyHeader, ModalLayout} from 'ui-components';
import classes from './book-a-demo-form-panel.module.scss';
import {useEffect} from 'react';
import {
  HUBSPOT_PORTAL_ID,
  HUBSPOT_REGION,
  HUBSPOT_SCHEDULE_DEMO_FORM_ID,
} from '../../../config/hubspot.config';
import {useCurrentUser} from '../../hooks/use-user.hook';

interface OwnProps {
  onClose?: () => void;
}

type AllProps = OwnProps;

const HTML_TARGET_ID = 'hubspotForm';

const generateInputQuerySelector = (name: string) => `input[name="${name}"]`;

export const BookADemoFormPanel = (props: AllProps) => {
  const {onClose} = props;
  const user = useCurrentUser();

  useEffect(() => {
    const script = document.createElement('script');
    script.charset = 'utf-8';
    script.type = 'text/javascript';
    script.src = 'https://js.hsforms.net/forms/embed/v2.js';
    script.async = true;
    script.onload = () => {
      const hubSpot = window.hbspt;
      if (hubSpot) {
        hubSpot.forms.create({
          region: HUBSPOT_REGION,
          portalId: HUBSPOT_PORTAL_ID,
          formId: HUBSPOT_SCHEDULE_DEMO_FORM_ID,
          target: `#${HTML_TARGET_ID}`,
          onFormReady: function (form) {
            const setValueAndTriggerEvent = (selector, value) => {
              const element = form.querySelector(selector);
              if (element) {
                element.value = value;
                const event = new Event('input', {bubbles: true});
                element.dispatchEvent(event);
                const changeEvent = new Event('change', {bubbles: true});
                element.dispatchEvent(changeEvent);
              }
            };
            // Prepopulate fields
            setValueAndTriggerEvent(generateInputQuerySelector('email'), user.email);
            setValueAndTriggerEvent(generateInputQuerySelector('firstname'), user.firstName);
            setValueAndTriggerEvent(generateInputQuerySelector('lastname'), user.lastName);
            // In the case we had a signup for oauth, we don't have the jobTitle and companyName,
            // so we don't want to trigger an event for it, as it will be empty and the form will
            // be invalid (showing an error)
            if (user.jobTitle) {
              setValueAndTriggerEvent(generateInputQuerySelector('jobtitle'), user.jobTitle);
            }
            if (user.companyName) {
              setValueAndTriggerEvent(generateInputQuerySelector('company'), user.companyName);
            }
          },
        });
      }
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [user]);

  return (
    <div className={classes.BookADemoFormPanelContainer}>
      <ModalLayout>
        <div className={classes.BookADemoFormPanel}>
          <FancyHeader title={''} onClose={onClose} />
          <div className={classes.FormContainer}>
            <div id={`${HTML_TARGET_ID}`}></div>
          </div>
        </div>
      </ModalLayout>
    </div>
  );
};
