import classNames from 'classnames';
import {composition} from 'front-core';
import classes from './team-metrics-form-panel.module.scss';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {
  Button,
  Checkbox,
  FancyHeader,
  ModalLayout,
  TeamSelector,
  BullsEyeArrowLightIcon,
} from 'ui-components';
import TransKeys from '../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {useCallback, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {TEAM_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {
  MetricCategory,
  MetricsByCategory,
  MetricsByTeam,
  TeamMetric,
} from '../../../../objects/models/metric.model';
import {updateTeamMetrics} from '../../../../store/metrics/metrics.actions';
import {
  getMetricsByCategoryNetworkRequest,
  getTeamMetricsNetworkRequest,
} from '../../../../http/metrics.network-requests';
import {withDisableDemoProduct} from '../../../../core/hoc/with-disable-demo-product.hoc';

interface OwnProps {
  metricsByCategory: MetricsByCategory;
  metricsByTeam: MetricsByTeam;
  [TEAM_ID_PATH_PARAM]: number;
  onClose: () => void;
  disabled?: boolean;
}

type AllProps = OwnProps;

interface ExtendedMetricCategory extends MetricCategory {
  selectedCount: number;
}

const initState = (teamMetrics: TeamMetric[] = []): number[] => teamMetrics.map(m => m.metricId);

export const TeamMetricFormPanelComponent = (props: AllProps) => {
  const {
    disabled,
    metricsByCategory,
    metricsByTeam,
    [TEAM_ID_PATH_PARAM]: teamIdFromProps,
    onClose,
  } = props;
  const {t} = useTranslation();
  const {actualTeams: teams, defaultTeamId} = useProductData();
  const {categories: categories_} = metricsByCategory;
  const dispatch = useDispatch();
  const [teamId, setTeamId] = useState<number>(
    () => Number(teamIdFromProps) || defaultTeamId || teams[0]?.id
  );
  const initialState = useMemo(
    () => initState(metricsByTeam[teamId]) || [],
    [metricsByTeam, teamId]
  );
  const [formData, setFormData] = useState(initialState);
  const teamMetricsSet = useMemo(() => new Set(formData), [formData]);

  const categories: ExtendedMetricCategory[] = useMemo(
    () =>
      categories_
        .map(c => ({
          ...c,
          selectedCount: c.metrics.filter(m => teamMetricsSet.has(m.id)).length,
        }))
        .filter(c => c.metrics.length > 0),
    [categories_, teamMetricsSet]
  );
  const onTeamChanged = useCallback(
    (teamId: number) => {
      setFormData(initState(metricsByTeam[teamId]) || []);
      setTeamId(teamId);
    },
    [setTeamId, setFormData, metricsByTeam]
  );
  const onMetricSelected = useCallback(
    (metricId: number) => {
      if (teamMetricsSet.has(metricId)) {
        teamMetricsSet.delete(metricId);
        setFormData(Array.from(teamMetricsSet));
      } else {
        teamMetricsSet.add(metricId);
        setFormData(Array.from(teamMetricsSet));
      }
    },
    [setFormData, teamMetricsSet]
  );
  const onSelectCategory = useCallback(
    (categoryId: number) => {
      const category = categories.find(c => c.id === categoryId);
      if (!category) {
        return;
      }
      if (category.selectedCount > 0) {
        for (const m of category.metrics) {
          teamMetricsSet.delete(m.id);
          setFormData(Array.from(teamMetricsSet));
        }
      } else {
        for (const m of category.metrics) {
          teamMetricsSet.add(m.id);
          setFormData(Array.from(teamMetricsSet));
        }
      }
    },
    [categories, setFormData, teamMetricsSet]
  );
  const onSubmit = useCallback(() => {
    dispatch(
      updateTeamMetrics(
        {
          teamId,
          metrics: formData,
        },
        onClose
      )
    );
  }, [formData, teamId, onClose, dispatch]);

  return (
    <ModalLayout
      className={classes.Container}
      footer={
        <div className={classes.Footer}>
          <div className={classes.Actions}>
            <Button onClick={onClose} variant={'outlined'}>
              {t(TransKeys.GENERAL.ACTIONS.CANCEL)}
            </Button>
            <Button disabled={disabled} onClick={onSubmit}>
              {t(TransKeys.GENERAL.ACTIONS.SAVE)}
            </Button>
          </div>
        </div>
      }
    >
      <div className={classes.TeamMetricsForm}>
        <FancyHeader
          title={t(TransKeys.TEAM_METRICS_PANEL.TITLE)}
          icon={BullsEyeArrowLightIcon}
          onClose={onClose}
          className={classes.Header}
        />
        <div className={classes.Main}>
          <div className={classes.TeamSelector}>
            <TeamSelector
              teams={teams}
              value={teamId}
              onChange={onTeamChanged}
              clearable={false}
              className={classes.TeamSelection}
              withPrefix
            />
          </div>
          <div className={classes.SectionHeader}>
            <div className={classes.Title}>{t(TransKeys.TEAM_METRICS_PANEL.INNER_TITLE_TEAM)}</div>
            <div className={classes.SubTitle}>
              {t(TransKeys.TEAM_METRICS_PANEL.INNER_SUB_TITLE)}
            </div>
          </div>
          {categories.map(category => (
            <div className={classes.Category} key={category.id}>
              <div className={classes.CategoryHeader}>
                <span className={classes.CategoryName}>{category.name}</span>
                <span className={classes.CategoryCount}>
                  {category.selectedCount} / {category.metrics.length}
                </span>
                <span
                  className={classes.CategorySelect}
                  onClick={() => onSelectCategory(category.id)}
                >
                  {category.selectedCount > 0 && t(TransKeys.GENERAL.ACTIONS.REMOVE_ALL)}
                  {category.selectedCount === 0 && t(TransKeys.GENERAL.ACTIONS.ADD_ALL)}
                </span>
              </div>
              <div className={classes.ListOptions}>
                {category.metrics.map(metric => (
                  <div
                    className={classNames(
                      classes.Option,
                      teamMetricsSet.has(metric.id) && classes.Selected
                    )}
                    key={metric.id}
                  >
                    <Checkbox
                      className={teamMetricsSet.has(metric.id) ? undefined : classes.Checkbox}
                      checked={teamMetricsSet.has(metric.id)}
                      onChange={() => onMetricSelected(metric.id)}
                      size={'large'}
                    />
                    <div className={classes.MetricName}>{metric.name}</div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </ModalLayout>
  );
};

export const TeamMetricFormPanel = composition(
  TeamMetricFormPanelComponent,
  withDisableDemoProduct,
  withLoadBefore({
    metricsByCategory: {
      selectedKey: 'METRIC_BY_CATEGORY',
      actionKey: 'METRIC_BY_CATEGORY',
      request: getMetricsByCategoryNetworkRequest,
    },
    metricsByTeam: {
      selectedKey: 'TEAM_METRICS',
      actionKey: 'TEAM_METRICS',
      request: getTeamMetricsNetworkRequest,
    },
  })
);
