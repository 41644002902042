import classNames from 'classnames';
import classes from './kpis-selector.module.scss';
import {ParameterInputWrapper} from '../../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {useCallback, useMemo} from 'react';
import {get, isArray, values} from 'lodash';
import {HoverHelperTip, LabelWrapper, useQueryArray} from 'ui-components';
import {
  SignalSmartSelector,
  SignalSmartSelectorKey,
} from '../../../../../shared/core/smart-selector/signal-smart-selector.component';
import {ItemList} from '../../../../../shared/core/query-builders/components/item-list/item-list.component';
import {hasErrors} from '../../../../../../utils/general.utils';
import {StandardCheckBox} from '../../../../../shared/components/general/standard-check-box/standard-check-box.component';
import {exists} from 'front-core';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';

interface SchemaKeysMapping {
  primary_kpi: string;
  secondary_kpis: string;
  bound_kpis_to_assignment?: string;
}

const DEFAULT_SCHEMA_KEYS_MAPPING: SchemaKeysMapping = {
  primary_kpi: 'primary_kpi',
  secondary_kpis: 'secondary_kpis',
};

interface OwnProps {
  title: string;
  onChange: (parameters: any) => void;
  value: any;
  schemaKeysMapping?: SchemaKeysMapping;
  onSignalInfo?: (value: string | number) => void;
  enableOverrideDefaultBounding?: boolean;
  filters?: any;
  subTitle?: string;
  bottomHelper?: any;
  helperText?: any;
  errors?: any;
  className?: string;
}

type AllProps = OwnProps;

const INCLUDE_SIGNAL_SELECTOR = [
  SignalSmartSelectorKey.METRICS,
  SignalSmartSelectorKey.FEATURES,
  SignalSmartSelectorKey.CONTENTS,
  SignalSmartSelectorKey.FUNNELS,
  SignalSmartSelectorKey.SIGNALS,
  SignalSmartSelectorKey.MILESTONES,
];

export const KPIsSelector = (props: AllProps) => {
  const {
    title,
    subTitle,
    helperText,
    bottomHelper,
    errors,
    value,
    filters,
    onChange,
    onSignalInfo,
    schemaKeysMapping = DEFAULT_SCHEMA_KEYS_MAPPING,
    enableOverrideDefaultBounding,
    className,
  } = props;
  const {t} = useTranslation();
  const hasError = useMemo(
    () => hasErrors(errors, values(schemaKeysMapping)),
    [errors, schemaKeysMapping]
  );
  const primaryError = useMemo(() => {
    const err = errors[schemaKeysMapping.primary_kpi];
    if (err) {
      return err.message;
    }
  }, [errors, schemaKeysMapping]);

  const secondaryError = useMemo(() => {
    const err = errors[schemaKeysMapping.secondary_kpis];
    if (isArray(err)) {
      return get(
        err.filter(e => e),
        '0.message'
      );
    }
    if (err) {
      return err.message;
    }
  }, [errors, schemaKeysMapping]);
  const {addElement, removeElement} = useQueryArray(
    get(value, schemaKeysMapping.secondary_kpis, []),
    data => onChange({[schemaKeysMapping.secondary_kpis]: data}),
    () => null
  );
  const onSecondaryKPIsChanged = useCallback(
    (pValue: any, idx: number) => {
      const existing = get(value, schemaKeysMapping.secondary_kpis, []) || [];
      const actions = [...existing];
      actions[idx] = pValue;
      onChange({[schemaKeysMapping.secondary_kpis]: actions});
    },
    [onChange, schemaKeysMapping, value]
  );
  const onRemoveSecondaryKPI = useCallback(
    (index: number) => {
      if (get(value, schemaKeysMapping.secondary_kpis, []).length > 1) {
        removeElement(index);
        return;
      }
      onChange({[schemaKeysMapping.secondary_kpis]: undefined});
    },
    [removeElement, onChange, value, schemaKeysMapping]
  );

  return (
    <ParameterInputWrapper
      title={title}
      subTitle={subTitle}
      helperText={helperText}
      className={classNames(classes.KPIsSelector, className)}
      bottomHelper={bottomHelper}
      error={hasError}
    >
      <div className={classes.Row}>
        <LabelWrapper
          label={'What’s the primary KPI?'}
          error={Boolean(primaryError)}
          helperText={primaryError}
        >
          <SignalSmartSelector
            placeholder={'Select'}
            onChange={value => onChange({[schemaKeysMapping.primary_kpi]: value})}
            value={value[schemaKeysMapping.primary_kpi]}
            error={Boolean(errors?.[schemaKeysMapping.primary_kpi])}
            onSignalInfo={onSignalInfo}
            include={INCLUDE_SIGNAL_SELECTOR}
            filters={filters}
          />
        </LabelWrapper>
      </div>
      <div className={classes.Row}>
        <LabelWrapper
          label={'Are there secondary KPIs you want to track? (optional)'}
          error={Boolean(secondaryError)}
          helperText={secondaryError}
        >
          <ItemList
            items={value[schemaKeysMapping.secondary_kpis] || [null]}
            renderItem={(item, idx) => (
              <SignalSmartSelector
                placeholder={'Select'}
                onChange={value => onSecondaryKPIsChanged(value, idx)}
                value={value[schemaKeysMapping.secondary_kpis]?.[idx]}
                error={Boolean(errors?.[schemaKeysMapping.secondary_kpis]?.[idx])}
                onSignalInfo={onSignalInfo}
                include={INCLUDE_SIGNAL_SELECTOR}
                filters={filters}
              />
            )}
            min1Item={false}
            onRemoveItem={onRemoveSecondaryKPI}
            onAddItem={addElement}
          />
        </LabelWrapper>
      </div>
      {enableOverrideDefaultBounding && exists(schemaKeysMapping?.bound_kpis_to_assignment) && (
        <div className={classes.Row}>
          <div
            className={classNames(
              classes.OverrideCheckbox,
              value[schemaKeysMapping?.bound_kpis_to_assignment] && classes.Checked
            )}
            onClick={() =>
              onChange({
                [schemaKeysMapping?.bound_kpis_to_assignment]:
                  !value[schemaKeysMapping?.bound_kpis_to_assignment],
              })
            }
          >
            <StandardCheckBox
              checked={value[schemaKeysMapping?.bound_kpis_to_assignment]}
              onChange={v => onChange({[schemaKeysMapping?.bound_kpis_to_assignment]: v})}
            />
            <span className={classes.OverrideLabel}>
              {t(TransKeys.KPI_SELECTOR.OVERRIDE_KPIS_BOUNDING.LABEL)}
            </span>
            <HoverHelperTip title={t(TransKeys.KPI_SELECTOR.OVERRIDE_KPIS_BOUNDING.HELPER_TEXT)} />
          </div>
        </div>
      )}
    </ParameterInputWrapper>
  );
};
