import classes from './horizontal-card.module.scss';
import classNames from 'classnames';
import {Fragment, useMemo} from 'react';
import {colorAlphaTransformer} from 'ui-components';

export interface HorizontalCardColumn {
  key?: string;
  render: (model?: any) => JSX.Element;
  weight?: number;
  divider?: boolean;
}

interface OwnProps {
  columns: HorizontalCardColumn[];
  color: string;
  icon?: any;
  active?: boolean;
  className?: string;
  lastColumnRight?: boolean;
}

type AllProps = OwnProps;

export const HorizontalCard = (props: AllProps) => {
  const {columns, color, icon: Icon, active, className, lastColumnRight = true} = props;

  const shouldRenderDivider = (c, idx, total): boolean => {
    if (idx === total - 1) {
      return false;
    }
    return c.divider === undefined || c.divider === true;
  };
  const colorOpacity = useMemo(() => colorAlphaTransformer(color, 0.2, true), [color]);

  return (
    <div
      className={classNames(
        classes.HorizontalCard,
        active && classes.Active,
        lastColumnRight && classes.LastColumnRight,
        classes[color],
        className
      )}
    >
      {Icon && (
        <div className={classes.Start} style={{backgroundColor: colorOpacity}}>
          <Icon style={{color}} className={classes.IconStart} />
        </div>
      )}

      <div className={classes.HorizontalCardBody}>
        {(columns || []).map((c, idx) => (
          <Fragment key={`${c.key}_${idx}`}>
            <div
              className={classNames(classes.Column)}
              style={{
                flex: `${c.weight || 1}`,
              }}
            >
              {c.render()}
            </div>
            {shouldRenderDivider(c, idx, columns.length) && <div className={classes.Divider} />}
          </Fragment>
        ))}
      </div>
    </div>
  );
};
