import {useCallback, useContext, useMemo, useState} from 'react';
import classes from './select-simple-analysis-picker-panel.module.scss';
import {
  Button,
  QueryBuilderFactory,
  RouteDuotoneIcon,
  ShapesDuotoneIcon,
  TextButton,
  UsersDuotoneIcon,
} from 'ui-components';
import {composition, exists} from 'front-core';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import sharedClasses from '../../../shared/follow-ups/panels/analysis-types-follow-up-panel/analysis-types-follow-up-panel.module.scss';
import AnalysisActionBox, {
  AnalysisActionBoxProps,
} from '../../components/analysis-action-box/analysis-action-box.component';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {ANALYSIS_TYPE_ID_PATH_PARAM, SIGNAL_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {PanelKey} from '../../../../constants/panels';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {GOAL_KEY as KPI_ANALYSIS_GOAL_KEY} from '../../analysis-forms/analysis-parameters/analysis-101/analysis-101-form.component';
import {GOAL_KEY as ENGAGEMENT_DRIVERS_GOAL_KEY} from '../../analysis-forms/analysis-parameters/analysis-122/analysis-122-form.component';
import {goalQueryStrategySchemaMapping as goalDriversGoalQueryStrategySchemaMapping} from '../../analysis-forms/analysis-parameters/analysis-80/analysis-80-form.component';
import {goalQueryStrategySchemaMapping as userJourneyGoalQueryStrategySchemaMapping} from '../../analysis-forms/analysis-parameters/analysis-107/analysis-107-form.component';
import {UIGoalType} from '../../analysis-forms/components/ui-selectors/goal-query-strategy-selector/goal-query-strategy-selector.component';
import {getSignalNetworkRequest} from '../../../../http/signals.network-requests';
import {Signal} from '../../../../objects/models/signal.model';
import {METADATA_KEY, PARAMETERS_METADATA_KEY} from '../../../../constants/parameters-saved-keys';
import {keyBy} from 'lodash';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import {AnalysisTypeId} from '../../../../constants/analysis-type-id';
import {useAnalysisTypesCatalog} from '../../hooks/use-analysis-types-catalog.hook';
import {useUserOnboarding} from '../../../../core/hooks/use-user-onboarding.hook';

interface SimpleAnalysisOptionConfig {
  icon: any;
  getParameters: (signal?: Signal) => any;
  getTxKey: (signal?: Signal) => string;
}

interface SimpleAnalysisOptionsConfigMap {
  [key: number]: SimpleAnalysisOptionConfig;
}

interface SelectedAnalysisSubmitData {
  [ANALYSIS_TYPE_ID_PATH_PARAM]: AnalysisTypeId;
  parameters: any;
}

export interface SelectSimpleAnalysisPickerPanelProps {
  onSubmit?: (data: SelectedAnalysisSubmitData) => void;
  analysisTypeIds: AnalysisTypeId[];
  [SIGNAL_ID_PATH_PARAM]: number;
  signal: Signal;
  onClose: () => void;
  title?: string;
  subTitle?: string;
  description?: string;
}

type AllProps = SelectSimpleAnalysisPickerPanelProps;

const createMappedUISignalColumn = (signal: Signal) => ({
  ...QueryBuilderFactory.createSignalColumn(signal.id),
  [PARAMETERS_METADATA_KEY]: {
    [METADATA_KEY.DISPLAY_NAME_KEY]: signal.name,
    [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: 'TemplateItemQueryBuilder',
  },
});

const getAnalysisDescriptionTxKey = (analysisTypeId: AnalysisTypeId, signal?: Signal) =>
  TransKeys.SIMPLE_ANALYSIS_PICKER_PANEL.ANALYSES_BOXES[`ANALYSIS_TYPE_ID_${analysisTypeId}`][
    signal ? 'DYNAMIC_DESCRIPTION' : 'STATIC_DESCRIPTION'
  ];

const SIMPLE_ANALYSIS_CONFIG_MAP: SimpleAnalysisOptionsConfigMap = {
  [AnalysisTypeId.GOAL_DRIVERS]: {
    icon: <ShapesDuotoneIcon className={sharedClasses.ShapesDuotoneIcon} />,
    getParameters: signal => ({
      [goalDriversGoalQueryStrategySchemaMapping.ui_goal_type]: UIGoalType.EXISTING,
      [goalDriversGoalQueryStrategySchemaMapping.goal_query]: createMappedUISignalColumn(signal),
    }),
    getTxKey: signal => getAnalysisDescriptionTxKey(AnalysisTypeId.GOAL_DRIVERS, signal),
  },
  [AnalysisTypeId.USER_JOURNEY]: {
    icon: <RouteDuotoneIcon className={sharedClasses.RouteDuotoneIcon} />,
    getParameters: signal => ({
      [userJourneyGoalQueryStrategySchemaMapping.ui_goal_type]: UIGoalType.EXISTING,
      [userJourneyGoalQueryStrategySchemaMapping.goal_query]: createMappedUISignalColumn(signal),
    }),
    getTxKey: signal => getAnalysisDescriptionTxKey(AnalysisTypeId.USER_JOURNEY, signal),
  },
  [AnalysisTypeId.KPI_ANALYSIS]: {
    icon: <UsersDuotoneIcon className={classes.UsersIcon} />,
    getParameters: signal => ({
      [KPI_ANALYSIS_GOAL_KEY]: signal.id,
    }),
    getTxKey: signal => getAnalysisDescriptionTxKey(AnalysisTypeId.KPI_ANALYSIS, signal),
  },
  [AnalysisTypeId.ENGAGEMENT_DRIVERS]: {
    icon: <ShapesDuotoneIcon className={sharedClasses.ShapesDuotoneIcon} />,
    getParameters: signal => ({
      [ENGAGEMENT_DRIVERS_GOAL_KEY]: signal.id,
    }),
    getTxKey: signal => getAnalysisDescriptionTxKey(AnalysisTypeId.ENGAGEMENT_DRIVERS, signal),
  },
};

const SELECTED_SIGNAL_KEY = 'SIMPLE_ANALYSIS_PICKER_PANEL/SIGNAL';

const SelectSimpleAnalysisPickerPanelComponent = (props: AllProps) => {
  const {
    analysisTypeIds,
    signal,
    title: titleFromProps,
    subTitle: subTitleFromProps,
    description: descriptionFromProps,
    onClose,
    onSubmit: onSubmitFromProps,
  } = props;
  const [selectedAnalysisId, setSelectedAnalysisId] = useState(null);
  const {analysisTypes, isLoading} = useAnalysisTypesCatalog({
    accepted: analysisTypeIds,
  });
  const {resetUserOnboardingSimpleAnalysisPickerPanelData} = useUserOnboarding();
  const {openPrimaryPanel} = useContext(PanelsContext);
  const {t} = useTranslation();
  const notify = useAmplitude();
  const {openSecondaryPanel} = useContext(PanelsContext);
  const analysesTypesLoaded = useMemo(
    () => !isLoading && analysisTypes.length > 0,
    [isLoading, analysisTypes]
  );
  const analysisTypesMap = useMemo(
    () => (analysesTypesLoaded ? keyBy(analysisTypes, 'id') : {}),
    [analysisTypes, analysesTypesLoaded]
  );
  const analysesBoxesProps: AnalysisActionBoxProps[] = useMemo(
    () =>
      analysisTypeIds.map((analysisTypeId: AnalysisTypeId) => {
        const analysisType = analysisTypesMap[analysisTypeId] || {};
        const {icon, getTxKey} = SIMPLE_ANALYSIS_CONFIG_MAP[analysisTypeId];
        return {
          key: analysisTypeId,
          icon,
          description: t(getTxKey(signal), {signalName: signal?.name}),
          name: analysisType.staticName,
          isCausal: analysisType.isCausal,
          onClick: () => setSelectedAnalysisId(analysisTypeId),
          selected: selectedAnalysisId === analysisTypeId,
        };
      }),
    [t, analysisTypeIds, analysisTypesMap, setSelectedAnalysisId, selectedAnalysisId, signal]
  );
  const texts = useMemo(
    () => ({
      title: titleFromProps || t(TransKeys.SIMPLE_ANALYSIS_PICKER_PANEL.TITLE),
      subTitle: subTitleFromProps || t(TransKeys.SIMPLE_ANALYSIS_PICKER_PANEL.SUBTITLE),
      description: descriptionFromProps || t(TransKeys.SIMPLE_ANALYSIS_PICKER_PANEL.DESCRIPTION),
      cta: t(TransKeys.SIMPLE_ANALYSIS_PICKER_PANEL.CTA),
      skipText: t(TransKeys.SIMPLE_ANALYSIS_PICKER_PANEL.SKIP_ANALYSIS_TEXT),
    }),
    [titleFromProps, subTitleFromProps, descriptionFromProps, t]
  );
  const onSubmit = useCallback(
    (data: SelectedAnalysisSubmitData) => {
      if (onSubmitFromProps) {
        return onSubmitFromProps(data);
      }
      openPrimaryPanel(PanelKey.ANALYSIS_FORM_PANEL, data);
      resetUserOnboardingSimpleAnalysisPickerPanelData();
    },
    [onSubmitFromProps, openPrimaryPanel, resetUserOnboardingSimpleAnalysisPickerPanelData]
  );
  const onSelectedAnalysisBtnClick = useCallback(() => {
    if (!exists(selectedAnalysisId)) {
      return;
    }
    notify(AmplitudeEvent.USER_SELECTED_ANALYSIS_FROM_SIMPLE_ANALYSIS_PICKER, {
      analysis_type_id: selectedAnalysisId,
    });
    const {getParameters} = SIMPLE_ANALYSIS_CONFIG_MAP[selectedAnalysisId];
    onSubmit({
      [ANALYSIS_TYPE_ID_PATH_PARAM]: selectedAnalysisId,
      parameters: getParameters(signal),
    });
    onClose();
  }, [selectedAnalysisId, signal, onClose, notify, onSubmit]);
  const onSignalClick = useCallback(
    () =>
      openSecondaryPanel(PanelKey.SIGNAL_DEFINITION_PANEL, {
        [SIGNAL_ID_PATH_PARAM]: signal.id,
      }),
    [openSecondaryPanel, signal]
  );

  return (
    <div className={classes.SelectSimpleAnalysisPickerPanel}>
      <div className={classes.Header}>
        <div className={classes.Title}>{texts.title}</div>
        <div className={classes.SubTitle}>{texts.subTitle}</div>
      </div>
      <div className={classes.Body}>
        {exists(signal.name) && (
          <div className={classes.SignalName}>
            <TextButton className={classes.TextBtn} onClick={onSignalClick}>
              {signal.name}
            </TextButton>
          </div>
        )}
        <div className={classes.Description}>{texts.description}</div>
        <div className={classes.Boxes}>{analysesBoxesProps.map(AnalysisActionBox)}</div>
        <div className={classes.Footer}>
          <Button
            size={'large'}
            className={classes.Btn}
            disabled={!exists(selectedAnalysisId)}
            onClick={onSelectedAnalysisBtnClick}
          >
            {texts.cta}
          </Button>
          <div className={classes.Skip} onClick={onClose}>
            {texts.skipText}
          </div>
        </div>
      </div>
    </div>
  );
};
const SelectSimpleIntroAnalysisPanel = composition<AllProps>(
  SelectSimpleAnalysisPickerPanelComponent,
  withLoadBefore<AllProps>({
    signal: {
      selectedKey: SELECTED_SIGNAL_KEY,
      actionKey: SELECTED_SIGNAL_KEY,
      request: getSignalNetworkRequest,
      mapPayloadFromProps: props => props[SIGNAL_ID_PATH_PARAM],
      shouldCall: props => props[SIGNAL_ID_PATH_PARAM] !== undefined,
    },
  })
);
export default SelectSimpleIntroAnalysisPanel;
