import {Epic} from 'redux-observable';
import {createRequestEpic} from 'front-core';
import {ActionKey} from '../../constants/action-key';
import HttpClient from '../../services/http-client.service';
import {ExperimentAutomationActionType} from './experiment-automations.actions';
import {modelCreated, modelDeleted, modelUpdated, notifyEvent} from '../core/core.actions';
import {ModelKey} from '../../constants/model-key';
import {experimentAutomationToastCreator} from '../toasts.actions';
import {AmplitudeEvent} from '../../constants/amplitude-event';
import {demoProductLimitedActionsFilter} from '../store.utils';
import {
  createExperimentAutomationNetworkRequest,
  deleteExperimentAutomationNetworkRequest,
  editExperimentAutomationNetworkRequest,
} from '../../http/experiment-automations.network-requests';

export const createEventGroupEpic: Epic = createRequestEpic(
  {
    types: [ExperimentAutomationActionType.CREATE_EXPERIMENT_AUTOMATION],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.CREATE_EXPERIMENT_AUTOMATION,
    request: createExperimentAutomationNetworkRequest,
    onSuccess: automation => [
      notifyEvent(AmplitudeEvent.EXPERIMENT_AUTOMATION_CREATED, {
        id: automation.id,
      }),
      experimentAutomationToastCreator('CREATE_SUCCESS'),
      modelCreated(automation, ModelKey.EXPERIMENT_AUTOMATION),
    ],
    onError: err_ => {
      return [experimentAutomationToastCreator('CREATE_ERROR')];
    },
  },
  HttpClient
);

export const updateEventGroupEpic: Epic = createRequestEpic(
  {
    types: [ExperimentAutomationActionType.UPDATE_EXPERIMENT_AUTOMATION],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.UPDATE_EXPERIMENT_AUTOMATION,
    request: editExperimentAutomationNetworkRequest,
    onSuccess: automation => [
      notifyEvent(AmplitudeEvent.EXPERIMENT_AUTOMATION_UPDATED, {
        id: automation.id,
      }),
      experimentAutomationToastCreator('UPDATE_SUCCESS'),
      modelUpdated(automation, ModelKey.EXPERIMENT_AUTOMATION),
    ],
    onError: err_ => {
      return [experimentAutomationToastCreator('CREATE_ERROR')];
    },
  },
  HttpClient
);

export const deleteEventGroupEpic: Epic = createRequestEpic(
  {
    types: [ExperimentAutomationActionType.DELETE_EXPERIMENT_AUTOMATION],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.DELETE_EXPERIMENT_AUTOMATION,
    request: deleteExperimentAutomationNetworkRequest,
    onSuccess: (res, payload) => [
      notifyEvent(AmplitudeEvent.EXPERIMENT_AUTOMATION_DELETED, {
        id: payload,
      }),
      experimentAutomationToastCreator('DELETE_SUCCESS'),
      modelDeleted(payload, ModelKey.EXPERIMENT_AUTOMATION),
    ],
    onError: err => [experimentAutomationToastCreator('DELETE_ERROR')],
  },
  HttpClient
);
