import {NRC} from './index';
import {RequestType} from 'front-core';
import {toFiltersDTO} from '../objects/dto/to-filters.dto';
import {toCamelCase, toSnakeCase} from 'front-core';
import {WorkflowFiltersDTO} from '../objects/dto/workflow-filters.dto';
import {
  RenameWorkflowDTO,
  SetWorkflowOwnerDTO,
  SetWorkflowTeamDTO,
  UpdateWorkflowTeamDTO,
  WorkflowDTO,
} from '../objects/dto/workflow.dto';

const EXCEPT = [
  'parameters',
  'output_definitions',
  'email_output',
  'slack_output',
  'database_output',
];

export const getWorkflowNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.GET,
  relativeUrl: `/app/workflows/${id}`,
  responseTransformer: res => toCamelCase(res.data, EXCEPT),
});

export const getWorkflowsNetworkRequest: NRC<void, WorkflowFiltersDTO> = (
  payload: WorkflowFiltersDTO
) => ({
  method: RequestType.GET,
  relativeUrl: `/app/workflows`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data, EXCEPT),
});

export const createWorkflowNetworkRequest: NRC<WorkflowDTO, void> = (data: WorkflowDTO) => ({
  method: RequestType.POST,
  relativeUrl: `/app/workflows`,
  body: data,
  bodyTransformer: body => toSnakeCase(body, EXCEPT),
  responseTransformer: res => toCamelCase(res.data),
});

export const updateWorkflowNetworkRequest: NRC<WorkflowDTO, void> = (data: WorkflowDTO) => ({
  method: RequestType.PUT,
  relativeUrl: `/app/workflows/${data.id}`,
  body: data,
  bodyTransformer: body => toSnakeCase(body, EXCEPT),
  responseTransformer: res => toCamelCase(res.data),
});

export const patchWorkflowNetworkRequest: NRC<WorkflowDTO, void> = (data: WorkflowDTO) => ({
  method: RequestType.PATCH,
  relativeUrl: `/app/workflows/${data.id}`,
  body: data,
  bodyTransformer: body => toSnakeCase(body, EXCEPT),
  responseTransformer: res => toCamelCase(res.data),
});

export const deleteWorkflowNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.DELETE,
  relativeUrl: `/app/workflows/${id}`,
  responseTransformer: toCamelCase,
});

export const setWorkflowOwnerNetworkRequest: NRC<SetWorkflowOwnerDTO, void> = (
  data: SetWorkflowOwnerDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/workflows/${data.workflowId}/owner`,
  body: data,
  bodyTransformer: data => toSnakeCase(data),
  responseTransformer: res => toCamelCase(res.data),
});

export const setWorkflowTeamNetworkRequest: NRC<SetWorkflowTeamDTO, void> = (
  data: SetWorkflowTeamDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/workflows/${data.workflowId}/team`,
  body: data,
  bodyTransformer: data => toSnakeCase(data),
  responseTransformer: res => toCamelCase(res.data),
});

export const updateWorkflowStatusNetworkRequest: NRC<UpdateWorkflowTeamDTO, void> = (
  data: UpdateWorkflowTeamDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/workflows/${data.workflowId}/status`,
  body: data,
  bodyTransformer: data => toSnakeCase(data),
  responseTransformer: res => toCamelCase(res.data),
});

export const renameWorkflowNetworkRequest: NRC<RenameWorkflowDTO, void> = (
  data: RenameWorkflowDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/workflows/${data.workflowId}/name`,
  body: data,
  bodyTransformer: data => toSnakeCase(data),
  responseTransformer: res => toCamelCase(res.data),
});

export const getWorkflowCountsNetworkRequest: NRC<void, WorkflowFiltersDTO> = (
  query: WorkflowFiltersDTO
) => ({
  method: RequestType.GET,
  relativeUrl: `/app/workflows/counts`,
  query,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => res.data,
});
