import {Button, PartyHornIcon} from 'ui-components';
import {StepProps} from '../weekly-interest-form-panel.component';
import classes from './steps.module.scss';
import classnames from 'classnames';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';

export const InterestFormCompletionStep = (props: StepProps) => {
  const {onNextStep} = props;
  const {t} = useTranslation();

  return (
    <>
      <div className={classnames(classes.Body, classes.FormCompletedStep)}>
        <PartyHornIcon className={classes.SuccessIcon} />
        <div className={classes.Title}>
          {t(TransKeys.WEEKLY_INTEREST_FORM_PANEL.STEP_3_FORM_COMPLETE.TITLE)}
        </div>
        <div className={classes.SubTitle}>
          {t(TransKeys.WEEKLY_INTEREST_FORM_PANEL.STEP_3_FORM_COMPLETE.DESCRIPTION)}
        </div>
      </div>
      <div className={classes.Footer}>
        <Button className={classes.Button} onClick={() => onNextStep({})}>
          {t(TransKeys.WEEKLY_INTEREST_FORM_PANEL.STEP_3_FORM_COMPLETE.BUTTON)}
        </Button>
      </div>
    </>
  );
};
