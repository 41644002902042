import classNames from 'classnames';
import classes from './chat-empty-state.module.scss';
import {useEffect, useMemo} from 'react';
import {GenericLoading} from '../../../../../shared/components/general/generic-loading/generic-loading.component';
import {
  ArrowTrendDownDuotoneIcon,
  ShapesDuotoneIcon,
  useRemoteSourceStated,
  UsersDuotoneIcon,
} from 'ui-components';
import {getAIChatSuggestionsNetworkRequest} from '../../../../../../http/ai-chart.network-requests';
import {sampleSize} from 'lodash';
import TransKeys from '../../../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';

interface Props {
  className?: string;
  onSelectSuggestion: (suggestion: SuggestionDTO) => void;
}

enum SuggestionTypes {
  DROP = 'drop',
  COMPARE = 'compare',
  USER = 'user',
}

const iconSuggestionMap = {
  [SuggestionTypes.DROP]: <ArrowTrendDownDuotoneIcon className={classes.DropIcon} />,
  [SuggestionTypes.USER]: <UsersDuotoneIcon className={classes.UsersIcon} />,
  [SuggestionTypes.COMPARE]: <ShapesDuotoneIcon className={classes.CompareIcon} />,
};

type SuggestionDTO = {
  type: SuggestionTypes;
  message: string;
};

export const ChatEmptyState = (props: Props) => {
  const {className, onSelectSuggestion} = props;
  const {t} = useTranslation();
  const {
    source: suggestions,
    exec: getSuggestions,
    isLoading: isLoadingSuggestions,
  } = useRemoteSourceStated({
    type: 'source',
    networkRequest: getAIChatSuggestionsNetworkRequest,
  });

  useEffect(() => {
    getSuggestions();
  }, [getSuggestions]);

  const suggestionsToShow = useMemo(() => {
    return sampleSize(suggestions, 4);
  }, [suggestions]);

  return (
    <div className={classNames(classes.ChatEmptyState, className)}>
      {isLoadingSuggestions && <GenericLoading />}
      <div className={classes.Title}>{t(TransKeys.GEN_AI_BOT.CHAT_EMPTY_STATE.TITLE)}</div>
      <div className={classes.Suggestions}>
        {suggestionsToShow?.map(suggestion => (
          <div className={classes.Suggestion} onClick={() => onSelectSuggestion(suggestion)}>
            <div className={classes.SuggestionIconContainer}>
              {iconSuggestionMap[suggestion.type]}
            </div>
            <div className={classes.SuggestionMessage}>{suggestion.message}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
