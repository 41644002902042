import {ModalLayout} from 'ui-components';
import {Modal} from '@material-ui/core';
import {SQLViewer} from '../sql-viewer/sql-viewer.component';
import classes from './view-sql-modal.module.scss';

interface OwnProps {
  isOpen: boolean;
  onClose: () => void;
  sql?: string;
  className?: string;
}

type AllProps = OwnProps;

export const ViewSQLModal = (props: AllProps) => {
  const {isOpen, sql, onClose} = props;

  return (
    <Modal open={isOpen} onClose={onClose} className={classes.SQLModalWrapper}>
      <ModalLayout className={classes.Modal} onClose={onClose}>
        <div className={classes.SQLViewer}>
          <SQLViewer sql={sql} />
        </div>
      </ModalLayout>
    </Modal>
  );
};
