import {NRC} from './index';
import {RequestType, toCamelCase, toSnakeCase} from 'front-core';

type CreateSourceDTO = {type: string; credentials: Record<string, string>};
export const createSourceNetworkRequest: NRC<CreateSourceDTO, void> = (
  payload: CreateSourceDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/sources`,
  body: payload,
  bodyTransformer: toSnakeCase,
  responseTransformer: toCamelCase,
});
