import {useCallback, useMemo} from 'react';
import classNames from 'classnames';
import classes from './events-discovery-goal-selector.module.scss';
import {ParameterInputWrapper} from '../../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {
  createUndefinedObject,
  hasErrors,
  limitNumberValue,
} from '../../../../../../utils/general.utils';
import {values} from 'lodash';
import {SignalDataType, SignalType} from '../../../../../../objects/models/signal.model';
import {
  TableEntity,
  TableEntityBinding,
  TableType,
} from '../../../../../../objects/models/table.model';
import {Checkbox, LabelWrapper, LiteralValueType, Select, TextInput} from 'ui-components';
import {EventsDiscoveryMode} from '../../../analysis-parameters/analysis-138/analysis-138-form.component';
import i18n from 'i18next';
import TransKeys from 'translations';
import {SelectorModelType} from '../../../../../shared/core/smart-selector/advanced-smart-selector.component';
import {TemplateItemQueryBuilder} from '../../../../../shared/core/query-builders/template-item-query-builder/template-item-query-builder.component';
import {useTranslation} from 'react-i18next';
import {TIME_UNIT_PLURAL_OPTIONS} from '../../../../../../constants/options';
import {fixNumber} from '../simple-number-selector/simple-number-selector.component';

interface SchemaKeysMapping {
  analysis_mode: string;
  goal: string;
  reference_date: string;
  churn_bound_n: string;
  churn_bound_unit: string;
  n_days_after_ref_date: string;
}

const DEFAULT_SCHEMA_KEYS_MAPPING: SchemaKeysMapping = {
  analysis_mode: 'analysis_mode',
  goal: 'goal',
  reference_date: 'reference_date',
  churn_bound_n: 'churn_bound_n',
  churn_bound_unit: 'churn_bound_unit',
  n_days_after_ref_date: 'n_days_after_ref_date',
};

interface OwnProps {
  title: string;
  subTitle?: string;
  helperText?: any;
  value: any;
  entityContext: TableEntity;
  onChange: (parameters: any) => void;
  schemaKeysMapping?: SchemaKeysMapping;
  onSignalInfo?: (value: string | number) => void;
  errors?: any;
  className?: string;
}

type AllProps = OwnProps;

const createGoalSignalFilters = (entityContext = undefined) => [
  {
    type: SignalType.MEASURE,
    data_type: SignalDataType.BOOLEAN,
    entity_binding: TableEntityBinding.TWO_WAY,
    entityContext,
  },
  {
    type: SignalType.DIMENSION,
    data_type: SignalDataType.TIMESTAMP,
    entity_binding: TableEntityBinding.TWO_WAY,
    entityContext,
  },
];

const createRefDateSignalFilters = (entityContext = undefined) => [
  {
    type: SignalType.MEASURE,
    data_type: SignalDataType.BOOLEAN,
    entity_binding: TableEntityBinding.DEFAULT,
    entityContext,
  },
  {
    type: SignalType.DIMENSION,
    data_type: SignalDataType.TIMESTAMP,
    entity_binding: TableEntityBinding.DEFAULT,
    entityContext,
  },
];

const createColumnFilters = (entityContext = undefined) => ({
  tableType: TableType.ENTITY_PROPERTIES,
  literalType: LiteralValueType.DATE,
  entity_binding: TableEntityBinding.DEFAULT,
  entityContext,
});

const MODES = () =>
  values(EventsDiscoveryMode).map(m => ({
    key: m,
    title: i18n.t(TransKeys.ANALYSIS_FORMS.ANALYSIS_138.ANALYSIS_MODE[m.toUpperCase()]['TITLE']),
    subTitle: i18n.t(
      TransKeys.ANALYSIS_FORMS.ANALYSIS_138.ANALYSIS_MODE[m.toUpperCase()]['SUB_TITLE_V2']
    ),
  }));

const MIN_COUNT_VALUE = 1;
const MAX_COUNT_VALUE = 50;
const N_DAYS_MIN_VALUE = 1;

export const EventsDiscoveryGoalSelector = (props: AllProps) => {
  const {
    title,
    subTitle,
    helperText,
    schemaKeysMapping = DEFAULT_SCHEMA_KEYS_MAPPING,
    errors,
    value,
    onChange,
    entityContext,
    onSignalInfo,
    className,
  } = props;
  const {t} = useTranslation();
  const selectedMode = useMemo(
    () => value[schemaKeysMapping.analysis_mode],
    [value, schemaKeysMapping]
  );
  const goalSignalFilters = useMemo(() => createGoalSignalFilters(entityContext), [entityContext]);
  const refDateSignalFilters = useMemo(
    () => createRefDateSignalFilters(entityContext),
    [entityContext]
  );
  const columnFilters = useMemo(() => createColumnFilters(entityContext), [entityContext]);
  const hasError = useMemo(
    () => hasErrors(errors, values(schemaKeysMapping)),
    [errors, schemaKeysMapping]
  );
  const modes = useMemo(() => MODES(), []);
  const onChangeMode = useCallback(
    (mode: EventsDiscoveryMode) => {
      const change = {
        ...createUndefinedObject(values(schemaKeysMapping)),
        [schemaKeysMapping.n_days_after_ref_date]: value[schemaKeysMapping.n_days_after_ref_date],
        [schemaKeysMapping.analysis_mode]: mode,
      };
      if (mode === EventsDiscoveryMode.BEFORE_CHURN) {
        change[schemaKeysMapping.churn_bound_n] = 4;
        change[schemaKeysMapping.churn_bound_unit] = 'week';
      }
      onChange && onChange(change);
    },
    [onChange, schemaKeysMapping, value]
  );

  const renderContentForMode = (key: EventsDiscoveryMode) => {
    const goalInput = (
      <TemplateItemQueryBuilder
        query={value[schemaKeysMapping.goal]}
        onChange={value => onChange && onChange({[schemaKeysMapping.goal]: value})}
        signalFilters={goalSignalFilters}
        onSignalInfo={onSignalInfo}
        columnFilters={columnFilters}
        allowTypes={[SelectorModelType.SIGNAL, SelectorModelType.EVENT, SelectorModelType.COLUMN]}
        errors={errors?.[schemaKeysMapping.goal]}
      />
    );

    switch (key) {
      case EventsDiscoveryMode.BEFORE_GOAL:
      case EventsDiscoveryMode.AFTER_GOAL:
        return (
          <LabelWrapper
            size={'small'}
            label={t(TransKeys.GENERAL.LABELS.DEFINE_GOAL).toUpperCase()}
            className={classes.MarginBottom}
          >
            {goalInput}
          </LabelWrapper>
        );
      case EventsDiscoveryMode.BETWEEN:
        return (
          <>
            <LabelWrapper
              size={'small'}
              label={t(
                TransKeys.ANALYSIS_FORMS.ANALYSIS_138.BETWEEN_DEFINITION
                  .REF_DATE_LABEL_INTERPOLATED,
                {value: value[schemaKeysMapping.n_days_after_ref_date]}
              ).toUpperCase()}
              className={classes.MarginBottom}
            >
              <TemplateItemQueryBuilder
                query={value[schemaKeysMapping.reference_date]}
                onChange={value =>
                  onChange && onChange({[schemaKeysMapping.reference_date]: value})
                }
                signalFilters={refDateSignalFilters}
                onSignalInfo={onSignalInfo}
                columnFilters={columnFilters}
                allowTypes={[
                  SelectorModelType.SIGNAL,
                  SelectorModelType.COLUMN,
                  SelectorModelType.EVENT,
                ]}
                errors={errors?.[schemaKeysMapping.reference_date]}
              />
            </LabelWrapper>
            <LabelWrapper
              size={'small'}
              label={t(
                TransKeys.ANALYSIS_FORMS.ANALYSIS_138.BETWEEN_DEFINITION.GOAL_LABEL
              ).toUpperCase()}
            >
              {goalInput}
            </LabelWrapper>
          </>
        );
      case EventsDiscoveryMode.BEFORE_CHURN:
        return (
          <LabelWrapper
            size={'small'}
            label={t(
              TransKeys.ANALYSIS_FORMS.ANALYSIS_138.CHURN_DEFINITION.SUB_TITLE
            ).toUpperCase()}
          >
            <div className={classes.Inline}>
              <TextInput
                className={classes.NumberInput}
                value={value?.[schemaKeysMapping.churn_bound_n]}
                onChange={value =>
                  onChange &&
                  onChange({
                    [schemaKeysMapping.churn_bound_n]: limitNumberValue(
                      value as number,
                      MIN_COUNT_VALUE,
                      MAX_COUNT_VALUE
                    ),
                  })
                }
                error={Boolean(errors?.[schemaKeysMapping.churn_bound_n]?.message)}
                type={'number'}
                fullWidth={false}
              />
              <Select
                value={value?.[schemaKeysMapping.churn_bound_unit]}
                onChange={value =>
                  onChange &&
                  onChange({
                    [schemaKeysMapping.churn_bound_unit]: value,
                  })
                }
                error={Boolean(errors?.[schemaKeysMapping.churn_bound_unit]?.message)}
                options={TIME_UNIT_PLURAL_OPTIONS}
                dropdownButtonClassName={classes.SelectUnit}
                searchable={false}
                clearable={false}
                capitalize={false}
              />
            </div>
          </LabelWrapper>
        );
    }
  };

  const renderSubtitle = (mode: any) => {
    if (selectedMode !== mode.key) {
      return (
        <div className={classes.SubTitle}>
          <span>
            {`${t(TransKeys.ANALYSIS_FORMS.ANALYSIS_138.ANALYSIS_MODE.BASE_SUBTITLE)} ${
              value[schemaKeysMapping.n_days_after_ref_date]
            } ${mode.subTitle}`}
          </span>
        </div>
      );
    }

    return (
      <div className={classes.SubTitle}>
        <span>{t(TransKeys.ANALYSIS_FORMS.ANALYSIS_138.ANALYSIS_MODE.BASE_SUBTITLE)}</span>
        <div className={classes.InlineNumberInput}>
          <TextInput
            className={classes.NumberInput}
            value={value[schemaKeysMapping.n_days_after_ref_date]}
            onChange={value =>
              onChange({
                [schemaKeysMapping.n_days_after_ref_date]: fixNumber(
                  value as any,
                  N_DAYS_MIN_VALUE,
                  undefined
                ),
              })
            }
            type={'number'}
            min={N_DAYS_MIN_VALUE}
          />
        </div>
        <span>{mode.subTitle}</span>
      </div>
    );
  };

  return (
    <ParameterInputWrapper
      title={title}
      subTitle={subTitle}
      className={classNames(classes.EventsDiscoveryGoalSelector, className)}
      helperText={helperText}
      error={hasError}
    >
      {modes.map(m => (
        <div className={classes.Mode} key={m.key}>
          <div className={classes.Header} onClick={() => onChangeMode(m.key)}>
            <div className={classes.CheckboxWrapper}>
              <Checkbox
                checked={selectedMode === m.key}
                className={classes.Checkbox}
                onChange={() => onChangeMode(m.key)}
              />
            </div>
            <span className={classes.Title}>{m.title}</span>
            <span className={classes.Dash}>-</span>
            {renderSubtitle(m)}
          </div>
          {selectedMode === m.key && (
            <div className={classes.Content}>{renderContentForMode(m.key)}</div>
          )}
        </div>
      ))}
    </ParameterInputWrapper>
  );
};
