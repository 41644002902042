import {yupResolver} from '@hookform/resolvers/yup';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {TextInput} from 'ui-components';
import {Footer} from '../../footer/footer.component';
import classes from './big-query-form.module.scss';
import {isEmpty} from 'lodash';
import {SourceWizardFormLayout} from '../../source-wizard-form-layout/source-wizard-form-layout.component';
import {ComponentProps, useCallback} from 'react';
import {SourceWizardLabelWrapper} from '../../source-wizard-label-wrapper/source-wizard-label-wrapper.component';
import {BigQueryFormSchema, bigQuerySchema} from '../../../../../../../objects/dto/source.dto';
import {
  FileDropzone,
  JSON_FILE_TYPE,
} from '../../../../../../shared/components/general/file-dropzone/file-dropzone.component';
import {FileType} from '../../../../../../shared/components/general/file-dropzone/file-dropzone.types.ts';

type BigQueryFormOnSubmitValues = {projectId: string; jsonCredentials: Record<string, unknown>};
export type BigQueryFormProps = {
  onSubmit?: (values: BigQueryFormOnSubmitValues) => void;
};

export const BigQueryForm = (props: BigQueryFormProps) => {
  const {onSubmit: onSubmitFromProps} = props;

  const formMethods = useForm({
    resolver: yupResolver(bigQuerySchema),
  });

  const {
    handleSubmit,
    setValue,
    setError,
    formState: {errors, isValid},
    control,
  } = formMethods;

  const onSubmit = useCallback(
    (values: BigQueryFormSchema) => {
      const data = {
        projectId: values.projectId,
        jsonCredentials: values.file.content,
      };

      onSubmitFromProps(data);
    },
    [onSubmitFromProps]
  );

  const onDrop: ComponentProps<typeof FileDropzone>['onChange'] = useCallback(
    acceptedFiles => {
      const file = acceptedFiles[0].file;
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          try {
            const json = JSON.parse(reader.result as string);
            setValue('file', {file, content: json}, {shouldValidate: true});
          } catch (error) {
            setValue('file', {file, content: null});
            setError('file', {
              type: 'validate',
              message: 'There was a problem parsing this file',
            });
          }
        };
        reader.readAsText(file);
      }
    },
    [setValue, setError]
  );

  const onDelete = useCallback(() => {
    setValue('file', null);
  }, [setValue]);

  return (
    <SourceWizardFormLayout
      footer={
        <Footer
          buttonText="Validate"
          onClickSubmit={handleSubmit(onSubmit)}
          disabledCTAButton={!isEmpty(errors) || !isValid}
        />
      }
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.BigQueryForm}>
          <SourceWizardLabelWrapper label="Project Id">
            <Controller
              render={({field, fieldState: {error}}) => (
                <TextInput
                  size="large"
                  placeholder={'Type here'}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(error)}
                  required
                />
              )}
              name={'projectId'}
              control={control}
            />
          </SourceWizardLabelWrapper>
          <SourceWizardLabelWrapper label={'Upload service account json file'}>
            <Controller
              render={({field: {value}, fieldState: {error}}) => {
                return (
                  <FileDropzone
                    onChange={onDrop}
                    onDelete={onDelete}
                    files={value as unknown as FileType[]}
                    acceptedFileTypes={JSON_FILE_TYPE}
                    error={error}
                  />
                );
              }}
              name={'file'}
              control={control}
            />
          </SourceWizardLabelWrapper>
        </form>
      </FormProvider>
    </SourceWizardFormLayout>
  );
};
