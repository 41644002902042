import {
  defaultShareDTOValidator,
  directSlackShareDTOValidator,
  ShareDTO,
} from '../../../../objects/dto/share.dto';
import classes from './share-resource-form-panel.module.scss';
import {
  AnalysisFileIcon,
  AppTabs,
  Button,
  FancyHeader,
  FlaskIcon,
  FolderIcon,
  ModalLayout,
  useLocalStorage,
  WandIcon,
} from 'ui-components';
import {CopyLinkButton} from '../../../shared/components/general/copy-link-button/copy-link-button.component';
import TransKeys from '../../../../constants/translation-keys';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {FormProvider, useForm} from 'react-hook-form';
import {preventSubmitOnEnter} from '../../../../utils/general.utils';
import React, {useCallback, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {ShareResourceType, ShareStrategyTypes} from '../../../../objects/models/share.model';
import {yupResolver} from '@hookform/resolvers/yup';
import {ActionKey} from '../../../../constants/action-key';
import {getReducedLoadingStateSelector} from '../../../../store/store.selectors';
import {shareResource} from '../../../../store/user-reactions/user-reactions.actions';
import {FormHiddenInputs} from '../../../shared/form/components/form-hidden-inputs.component';
import i18n from '../../../../config/i18n.config';
import {StringParam, useQueryParams, withDefault} from 'use-query-params';
import {DefaultShareFormFields} from './components/default-share-form-fields/default-share-form-fields.component';
import {ShareDirectSlackFormFields} from './components/share-direct-slack-form-fields/share-direct-slack-form-fields.component';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import {COMPANIES_LOGOS} from '../../../../constants/ui';
import {composition} from 'front-core';
import {withDisableDemoProduct} from '../../../../core/hoc/with-disable-demo-product.hoc';

interface OwnProps {
  modelId: number;
  type: ShareResourceType;
  onClose: () => void;
  panelId?: string;
  copyLink?: string;
  disabled?: boolean;
}

type AllProps = OwnProps;

const SHARE_CONFIG = () => ({
  [ShareResourceType.OPPORTUNITY]: {
    title: i18n.t(TransKeys.SHARE_RESOURCE_FORM.OPPORTUNITY.TITLE),
    icon: WandIcon,
    shareStrategies: [ShareStrategyTypes.DEFAULT, ShareStrategyTypes.DIRECT_SLACK],
  },
  [ShareResourceType.ANALYSIS_RESULT]: {
    title: i18n.t(TransKeys.SHARE_RESOURCE_FORM.ANALYSIS_RESULT.TITLE),
    icon: AnalysisFileIcon,
    shareStrategies: [ShareStrategyTypes.DEFAULT, ShareStrategyTypes.DIRECT_SLACK],
  },
  [ShareResourceType.ANALYSIS_FOLDER]: {
    title: i18n.t(TransKeys.SHARE_RESOURCE_FORM.ANALYSIS_FOLDER.TITLE),
    icon: FolderIcon,
    shareStrategies: [ShareStrategyTypes.DEFAULT],
  },
  [ShareResourceType.EXPERIMENT]: {
    title: i18n.t(TransKeys.SHARE_RESOURCE_FORM.EXPERIMENT.TITLE),
    icon: FlaskIcon,
    shareStrategies: [ShareStrategyTypes.DEFAULT, ShareStrategyTypes.DIRECT_SLACK],
  },
});

const getTabLabel = (tab: ShareStrategyTypes) => {
  if (tab === ShareStrategyTypes.DEFAULT) {
    return i18n.t(TransKeys.SHARE.TABS[tab.toUpperCase()]);
  }
  if (tab === ShareStrategyTypes.DIRECT_SLACK) {
    return (
      <div className={classes.ShareSlack}>
        <img alt={'Slack icon'} src={COMPANIES_LOGOS.slack} className={classes.SlackIcon} />
        {i18n.t(TransKeys.SHARE.TABS[tab.toUpperCase()])}
      </div>
    );
  }
};

const SHARE_STRATEGY_TYPE_STORAGE_KEY = 'share_strategy_type';

const _RECIPIENTS_KEY = 'recipients';
const _INVITEES_KEY = 'invitees';
const _CHANNELS_KEY = 'channels';

const ShareResourceFormPanelComponent = (props: AllProps) => {
  const {onClose: onCloseFromProps, modelId, type, copyLink, disabled} = props;
  const notify = useAmplitude();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [shareStrategyStorage, setShareStrategyStorage] = useLocalStorage(
    SHARE_STRATEGY_TYPE_STORAGE_KEY,
    ShareStrategyTypes.DEFAULT
  );
  const [queryParams, setQueryParams] = useQueryParams({
    shareTab: withDefault(StringParam, shareStrategyStorage),
    viewMode: StringParam,
  });
  const {installedSlackToWorkspace} = useProductData();
  const link = useMemo(() => (copyLink ? window.location.host + copyLink : undefined), [copyLink]);
  const shareConfig = useMemo(() => SHARE_CONFIG()[type], [type]);

  const shareDTOValidator = useMemo(
    () =>
      queryParams.shareTab === ShareStrategyTypes.DEFAULT
        ? defaultShareDTOValidator
        : directSlackShareDTOValidator,
    [queryParams.shareTab]
  );

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      modelId,
      type,
      [_RECIPIENTS_KEY]: [],
      [_INVITEES_KEY]: [],
      [_CHANNELS_KEY]: [],
      message: '',
      strategyType: queryParams.shareTab,
    },
    resolver: yupResolver(shareDTOValidator),
  });

  const {
    handleSubmit,
    setValue,
    trigger,
    watch,
    formState: {isSubmitting, isValidating, isValid},
  } = formMethods;
  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(ActionKey.SHARE_RESOURCE)(state)
  );

  const recipients = watch(_RECIPIENTS_KEY);
  const invitees = watch(_INVITEES_KEY);

  useEffect(() => {
    if (recipients.length > 0 || invitees.length > 0) {
      trigger([_RECIPIENTS_KEY, _INVITEES_KEY]);
    }
  }, [trigger, recipients, invitees]);

  const onClose = useCallback(() => {
    setQueryParams({shareTab: undefined});
    onCloseFromProps();
  }, [onCloseFromProps, setQueryParams]);

  const onSubmit = useCallback(
    (data: ShareDTO) =>
      dispatch(
        shareResource(data, {
          onSuccess: () => {
            setShareStrategyStorage(queryParams.shareTab as ShareStrategyTypes);
            onClose();
          },
        })
      ),
    [dispatch, onClose, setShareStrategyStorage, queryParams.shareTab]
  );

  const onTabClick = useCallback(
    (shareTab: string) => {
      [_RECIPIENTS_KEY, _INVITEES_KEY, _CHANNELS_KEY].forEach((key: any) => setValue(key, []));
      setQueryParams({shareTab});
      notify(AmplitudeEvent.RESOURCE_SHARE_TAB_CLICKED, {tab: shareTab});
    },
    [setQueryParams, setValue, notify]
  );

  const tabs = useMemo(() => {
    return shareConfig.shareStrategies.map(shareTab => ({
      key: shareTab,
      label: getTabLabel(shareTab),
    }));
  }, [shareConfig?.shareStrategies]);

  const selectedTab = useMemo(() => {
    return shareConfig.shareStrategies.includes(queryParams.shareTab as ShareStrategyTypes)
      ? queryParams.shareTab
      : ShareStrategyTypes.DEFAULT;
  }, [shareConfig?.shareStrategies, queryParams?.shareTab]);
  // cleanup query
  useEffect(() => {
    return () => {
      setQueryParams({shareTab: undefined});
    };
  }, [setQueryParams]);

  const renderMain = () => {
    switch (selectedTab) {
      case ShareStrategyTypes.DEFAULT:
        return <DefaultShareFormFields key={ShareStrategyTypes.DEFAULT} />;
      case ShareStrategyTypes.DIRECT_SLACK:
        return (
          <ShareDirectSlackFormFields
            key={ShareStrategyTypes.DIRECT_SLACK}
            installedSlack={installedSlackToWorkspace}
          />
        );
    }
  };
  const isDisabled = useMemo(
    () => isLoading || isSubmitting || isValidating || !isValid || disabled,
    [isLoading, isSubmitting, isValidating, isValid, disabled]
  );

  return (
    <div className={classes.Container}>
      <ModalLayout
        footer={
          <div className={classes.Footer}>
            <span>{link && <CopyLinkButton copyText={link} disabled={disabled} />}</span>
            <Button onClick={handleSubmit(onSubmit)} disabled={isDisabled}>
              {t(TransKeys.GENERAL.ACTIONS.SHARE)}
            </Button>
          </div>
        }
      >
        <div className={classes.Share}>
          <FancyHeader
            title={shareConfig.title}
            icon={shareConfig.icon}
            onClose={onClose}
            className={classes.ShareHeader}
          />
          <div className={classes.FormWrapper}>
            {isLoading && <GenericLoading />}
            <FormProvider {...formMethods}>
              <form
                className={classes.Form}
                onKeyDown={preventSubmitOnEnter}
                onSubmit={handleSubmit(onSubmit)}
              >
                <AppTabs
                  tabs={tabs}
                  selected={selectedTab}
                  onChange={onTabClick}
                  className={classes.Tabs}
                />
                <FormHiddenInputs names={['modelId', 'type']} />
                {renderMain()}
              </form>
            </FormProvider>
          </div>
        </div>
      </ModalLayout>
    </div>
  );
};

export const ShareResourceFormPanel = composition<AllProps>(
  ShareResourceFormPanelComponent,
  withDisableDemoProduct
);
