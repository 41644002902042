import {useTranslation} from 'react-i18next';
import classes from './experiment-info-tab.module.scss';
import yup from '../../../../../../config/yup.config';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {sharedClasses} from '../../../../../shared';
import {Button} from 'ui-components';
import TransKeys from '../../../../../../constants/translation-keys';
import {FormStep} from '../../../../../shared/components/layout/form-step/form-step.component';
import {
  TextareaFormInput,
  TextFormInput,
} from '../../../../../shared/form/components/shared-form-input.component';
import classNames from 'classnames';
import {TabHeader} from '../../../../../shared/components/general/tab-header/tab-header.component';
import {Experiment} from '../../../../../../objects/models/experiment.model';
import {preventSubmitOnEnter} from '../../../../../../utils/general.utils';
import {useDemoProduct} from '../../../../../../core/hooks/use-demo-product.hook';

interface OwnProps {
  onSubmit: (data: Partial<Experiment>) => void;
  onBack?: () => void;
  data: Partial<Experiment>;
  submitText?: string;
  disabled?: boolean;
  className?: string;
}

type AllProps = OwnProps;

const validator = yup.object().shape({
  name: yup.string().required(),
  shortDescription: yup.string().nullable(),
});

export const ExperimentInfoTab = (props: AllProps) => {
  const {data, onSubmit, onBack, submitText = 'Run', disabled, className} = props;
  const {t} = useTranslation();
  const {demoProductValidator} = useDemoProduct();

  const formMethods = useForm({
    defaultValues: data as any,
    resolver: yupResolver(demoProductValidator || validator.noUnknown()),
  });
  const {handleSubmit} = formMethods;

  return (
    <FormProvider {...formMethods}>
      <form
        onKeyDown={preventSubmitOnEnter}
        className={classNames(sharedClasses.NoOverflow, className)}
      >
        <FormStep
          footer={
            <>
              {onBack && (
                <Button variant={'outlined'} type={'button'} onClick={onBack}>
                  {t(TransKeys.GENERAL.ACTIONS.BACK)}
                </Button>
              )}
              <Button disabled={disabled} onClick={handleSubmit(onSubmit)}>
                {submitText}
              </Button>
            </>
          }
        >
          <div className={classes.ExperimentInfoTab}>
            <TabHeader
              className={classes.Header}
              title={t(TransKeys.EXPERIMENT_FORM.STEPS.EXPERIMENT_INFO_TITLE)}
              subTitle={t(TransKeys.EXPERIMENT_FORM.STEPS.EXPERIMENT_INFO_SUBTITLE)}
            />
            <div className={classes.Block}>
              <TextFormInput
                required
                placeholder={t(TransKeys.EXPERIMENT_FORM.INPUTS.NAME.PLACEHOLDER)}
                label={t(TransKeys.EXPERIMENT_FORM.INPUTS.NAME.LABEL)}
                name={'name'}
              />
            </div>
            <div className={classes.Block}>
              <TextareaFormInput
                placeholder={t(TransKeys.EXPERIMENT_FORM.INPUTS.DESCRIPTION.PLACEHOLDER)}
                label={t(TransKeys.EXPERIMENT_FORM.INPUTS.DESCRIPTION.LABEL)}
                name={'shortDescription'}
              />
            </div>
          </div>
        </FormStep>
      </form>
    </FormProvider>
  );
};
