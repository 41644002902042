import {useCallback, useContext, useMemo, useState} from 'react';
import {FancyHeader, FlaskIcon, ModalLayout, PrimaryTabs, WizardStepper} from 'ui-components';
import {
  ANALYSIS_PARAMETERS_KEY,
  ANALYSIS_TYPE_ID_PATH_PARAM,
  ANNOTATION_ID_PATH_PARAM,
  AppRoutes,
  EXPERIMENT_ID_PATH_PARAM,
  SIGNAL_ID_PATH_PARAM,
} from '../../../../constants/app-routes';
import classes from './experiment-form-panel.module.scss';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../constants/translation-keys';
import {Experiment} from '../../../../objects/models/experiment.model';
import {
  createExperiment,
  updateExperiment,
} from '../../../../store/experiments/experiments.actions';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {useDispatch, useSelector} from 'react-redux';
import {getReducedLoadingStateSelector} from '../../../../store/store.selectors';
import {getExperimentDataNetworkRequest} from '../../../../http/experiments.network-requests';
import {ActionKey} from '../../../../constants/action-key';
import {composition, exists} from 'front-core';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import {PanelKey} from '../../../../constants/panels';
import {AnalysisTypeSelectionTab} from '../../../analyses/panels/analysis-form-panel/components/analysis-type-selection-tab/analysis-type-selection-tab.component';
import {AnalysisParametersTab} from '../../../analyses/panels/analysis-form-panel/components/analysis-parameters-tab/analysis-parameters-tab.component';
import {AnalysisTypeId} from '../../../../constants/analysis-type-id';
import {ExperimentInfoTab} from './components/experiment-info-tab/experiment-info-tab.component';
import {WizardCompletedTab} from '../../../analyses/panels/analysis-form-panel/components/wizard-completed-tab/wizard-completed-tab.component';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {ExperimentSubscriptionTab} from './components/experiment-subscription-tab/experiment-subscription-tab.component';
import {useNavigate} from 'react-router';
import {USER_END_DATE_KEY} from '../../../analyses/analysis-forms/analysis-parameters/analysis-91/analysis-91-form.component';
import {useAnalysisRuntime} from '../../../analyses/hooks/use-analysis-runtime.hook';
import {getTimezone} from '../../../../utils/general.utils';
import {withModalInactiveSourceHandler} from '../../../../core/hoc/with-modal-inactive-source-handler.hoc';
import {useDemoProduct} from '../../../../core/hooks/use-demo-product.hook';

interface OwnProps {
  experiment?: Experiment;
  onClose?: () => void;
  [EXPERIMENT_ID_PATH_PARAM]?: number;
  [ANALYSIS_TYPE_ID_PATH_PARAM]?: number;
  [ANALYSIS_PARAMETERS_KEY]?: any;
  [ANNOTATION_ID_PATH_PARAM]?: number;
  cloneMode?: boolean;
}

type AllProps = OwnProps;

const SELECTED_EXPERIMENT_KEY = SharedSelectionKeys.EXPERIMENT_FORM__EXPERIMENT;

const wizardLabels = [
  {
    label: 'Select Analysis',
    value: 'SELECT_ANALYSIS',
  },
  {
    label: 'Set Parameters',
    value: 'SET_PARAMETERS',
  },
  {
    label: 'Subscribe',
    value: 'EXPERIMENT_SUBSCRIPTION',
  },
  {
    label: 'Name it',
    value: 'EXPERIMENT_INFO',
  },
  {
    label: 'Done!',
    value: 'DONE',
  },
];

const ACCEPTED_ANALYSIS_TYPE_ID = [AnalysisTypeId.A_B_TEST, AnalysisTypeId.RELEASE_IMPACT];

const createInitialFormState = (formData: any, cloneMode: boolean, type: AnalysisTypeId) => {
  // If we don't have an id, we're in create mode, and we don't to do nothing
  if (!formData?.id) {
    return formData;
  }
  // If we have an id, it means we're in either clone of edit mode
  const {parameters, runParameters} = formData;
  // this cloned experiment has actual endDate, which means that the user has set the endDate for this analysis
  const userSetEndDate = USER_END_DATE_KEY in parameters && exists(parameters[USER_END_DATE_KEY]);
  if (!userSetEndDate && type === AnalysisTypeId.A_B_TEST) {
    runParameters['end_date'] = undefined;
  }
  // if we're in clone mode, we want to create a new experiment and therefore set the id to undefined
  // if we're in edit mode, we want to perform the update on the experiment, and therefore we actually don't
  // do set the id
  const experimentId = cloneMode ? undefined : formData.id;
  return {
    ...formData,
    parameters,
    runParameters,
    id: experimentId,
    subscribers: [],
  };
};

const ExperimentFormPanelComponent = (props: AllProps) => {
  const {
    experiment = {} as any,
    onClose,
    [ANALYSIS_TYPE_ID_PATH_PARAM]: analysisTypeIdFromProps,
    [ANALYSIS_PARAMETERS_KEY]: analysisParametersFromProps,
    [ANNOTATION_ID_PATH_PARAM]: annotationId,
    cloneMode,
  } = props;
  const notify = useAmplitude();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {isDemoProduct, getDemoAnalysisResultResource} = useDemoProduct();
  const {getEstimatedRuntimeFormatted} = useAnalysisRuntime();
  const {openSecondaryPanel} = useContext(PanelsContext);
  const [activeIndex, setActiveIndex] = useState(analysisTypeIdFromProps ? 1 : 0);
  const [formData, setFormData] = useState<any>(
    createInitialFormState(
      {
        analysisTypeId: analysisTypeIdFromProps,
        parameters: analysisParametersFromProps,
        annotationId,
        ...experiment,
      },
      cloneMode,
      experiment.analysisTypeId
    )
  );
  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(ActionKey.CREATE_EXPERIMENT, ActionKey.UPDATE_EXPERIMENT)(state)
  );
  const editMode = useMemo(() => Boolean(formData?.id), [formData]);
  const isLastStep = useMemo(() => wizardLabels[activeIndex]?.value === 'DONE', [activeIndex]);
  const isCompletedExperiment = useMemo(
    () =>
      formData.analysisTypeId === AnalysisTypeId.A_B_TEST &&
      formData.runParameters &&
      exists(formData.runParameters['end_date']),
    [formData.analysisTypeId, formData.runParameters]
  );
  const hideSubscription = useMemo(() => {
    if (formData.analysisTypeId === AnalysisTypeId.RELEASE_IMPACT || editMode) {
      return true;
    }
    return isCompletedExperiment;
  }, [formData.analysisTypeId, isCompletedExperiment, editMode]);
  const wizardStepperLabels = useMemo(
    () =>
      hideSubscription
        ? wizardLabels.filter(l => l.value !== 'EXPERIMENT_SUBSCRIPTION')
        : wizardLabels,
    [hideSubscription]
  );
  const formTitle = useMemo(() => {
    if (editMode) {
      return t(TransKeys.EXPERIMENT_FORM.EDIT_TITLE);
    }
    if (cloneMode) {
      return t(TransKeys.EXPERIMENT_FORM.DUPLICATE_TITLE);
    }
    return t(TransKeys.EXPERIMENT_FORM.CREATE_TITLE);
  }, [t, editMode, cloneMode]);

  const onWizardStepClick = useCallback(
    (value: string) => {
      const labelIndex = wizardStepperLabels.findIndex(l => l.value === value);
      setActiveIndex(labelIndex || 0);
      notify(AmplitudeEvent.USER_ANALYSIS_CREATION_STEP_VIEW, {
        step_index: labelIndex,
        step_name: wizardStepperLabels[labelIndex]?.value,
        analysis_type_id: formData.analysisTypeId,
      });
    },
    [setActiveIndex, wizardStepperLabels, formData, notify]
  );
  const onSignalInfo = useCallback(
    signalId =>
      openSecondaryPanel(PanelKey.SIGNAL_DEFINITION_PANEL, {
        [SIGNAL_ID_PATH_PARAM]: signalId,
      }),
    [openSecondaryPanel]
  );
  const onSubmitForDemo = useCallback(
    data => {
      const demoAnalysisResult = getDemoAnalysisResultResource(data.analysisTypeId);
      setTimeout(() => {
        if (demoAnalysisResult) {
          onClose();
          navigate(demoAnalysisResult);
        } else {
          openSecondaryPanel(PanelKey.BOOK_A_DEMO_PANEL);
        }
      }, 500);
      // Move to DONE step before redirecting to the demo analysis or opening the demo panel
      onWizardStepClick('DONE');
    },
    [getDemoAnalysisResultResource, onClose, navigate, openSecondaryPanel, onWizardStepClick]
  );
  const onSubmit_ = useCallback(
    data => {
      const hooks = {
        onSuccess: () => {
          onWizardStepClick('DONE');
          if (!window.location.pathname.includes('experiments')) {
            return [];
          }
          navigate(AppRoutes.experiments());
          return [];
        },
      };
      const action = editMode ? updateExperiment : createExperiment;
      dispatch(
        action(
          {
            ...data,
            timezone: getTimezone(),
          },
          hooks
        )
      );
    },
    [editMode, onWizardStepClick, dispatch, navigate]
  );
  const onSubmit = useMemo(
    () => (isDemoProduct ? onSubmitForDemo : onSubmit_),
    [isDemoProduct, onSubmitForDemo, onSubmit_]
  );

  return (
    <div className={classes.ExperimentFormContainer}>
      <ModalLayout>
        {isLoading && <GenericLoading />}
        <div className={classes.ExperimentForm}>
          <FancyHeader
            title={formTitle}
            icon={FlaskIcon}
            onClose={onClose}
            className={classes.Header}
          />
          <div className={classes.WizardSteps}>
            {!isLastStep && (
              <WizardStepper
                className={classes.FullWidth}
                activeValue={wizardStepperLabels[activeIndex]?.value}
                lastCompletedValue={
                  activeIndex > 0 ? wizardStepperLabels[activeIndex - 1]?.value : undefined
                }
                labels={wizardStepperLabels}
                onClick={onWizardStepClick}
              />
            )}
          </div>
          {isLoading && <GenericLoading />}
          <PrimaryTabs
            className={classes.Tabs}
            selected={wizardStepperLabels[activeIndex].value}
            hideTabsButtons
            tabs={[
              {
                key: 'SELECT_ANALYSIS',
                render: () => (
                  <AnalysisTypeSelectionTab
                    data={formData}
                    accepted={ACCEPTED_ANALYSIS_TYPE_ID}
                    showCategories={false}
                    showSearch={false}
                    onSignalInfo={onSignalInfo}
                    onSubmit={data => {
                      setFormData(formData => ({...formData, ...data}));
                      onWizardStepClick('SET_PARAMETERS');
                    }}
                  />
                ),
              },
              {
                key: 'SET_PARAMETERS',
                render: () => (
                  <AnalysisParametersTab
                    data={formData}
                    onBack={cloneMode || editMode ? undefined : () => setActiveIndex(ai => ai - 1)}
                    onSwitchAnalysis={id => {
                      setFormData(formData => ({
                        ...formData,
                        analysisTypeId: id,
                      }));
                      onWizardStepClick('SELECT_ANALYSIS');
                    }}
                    onSubmit={data => {
                      setFormData(formData => ({...formData, ...data}));
                      onWizardStepClick(
                        hideSubscription ? 'EXPERIMENT_INFO' : 'EXPERIMENT_SUBSCRIPTION'
                      );
                    }}
                    onSignalInfo={onSignalInfo}
                  />
                ),
              },
              {
                key: 'EXPERIMENT_SUBSCRIPTION',
                render: () => (
                  <ExperimentSubscriptionTab
                    data={formData}
                    onBack={() => setActiveIndex(ai => ai - 1)}
                    onSubmit={data => {
                      setFormData(formData => ({...formData, ...data}));
                      onWizardStepClick('EXPERIMENT_INFO');
                    }}
                    submitText={t(TransKeys.GENERAL.ACTIONS.NEXT)}
                  />
                ),
                hide: hideSubscription,
              },
              {
                key: 'EXPERIMENT_INFO',
                render: () => (
                  <ExperimentInfoTab
                    data={formData}
                    onBack={() => setActiveIndex(ai => ai - 1)}
                    disabled={isLoading}
                    onSubmit={data => {
                      setFormData(formData => ({...formData, ...data}));
                      onSubmit({...formData, ...data});
                    }}
                  />
                ),
              },
              {
                key: 'DONE',
                render: () => (
                  <WizardCompletedTab
                    title={t(TransKeys.EXPERIMENT_FORM.SUCCESS_MESSAGE.TITLE)}
                    subTitle={getEstimatedRuntimeFormatted(formData.analysisTypeId)}
                    showSimilarAnalyses={false}
                    actions={[
                      {
                        text: t(TransKeys.GENERAL.ACTIONS.CLOSE),
                        onClick: onClose,
                      },
                    ]}
                  />
                ),
              },
            ]}
          />
        </div>
      </ModalLayout>
    </div>
  );
};

const ExperimentFormPanel = composition<AllProps>(
  ExperimentFormPanelComponent,
  withModalInactiveSourceHandler,
  withLoadBefore({
    experiment: {
      selectedKey: SELECTED_EXPERIMENT_KEY,
      actionKey: SELECTED_EXPERIMENT_KEY,
      request: getExperimentDataNetworkRequest,
      mapPayloadFromProps: props => props[EXPERIMENT_ID_PATH_PARAM],
      shouldCall: props => props[EXPERIMENT_ID_PATH_PARAM] !== undefined,
    },
  })
);

export default ExperimentFormPanel;
