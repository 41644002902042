import {ChangeDescription, RCAInsights} from '../../../../../../../../../insights/rca/objects';
import {
  MetricSampleSentences,
  MetricSampleWoWOverview,
} from '../../../../../../../../../insights/model-sample/objects';
import {RCAMode} from 'ui-components';
import {useMemo} from 'react';
import {MetricSampleWoWOverviewSection} from '../metric-overview-wow-section/metric-sample-wow-overview.component';
import {MetricSamplesSection} from '../metric-samples-section/metric-samples-section.component';
import {SegmentsInsightsSection} from '../segments-insights-section/segments-insights-section.component';

import {useProductData} from '../../../../../../../../../../core/hooks/use-product-data.hook';
import classes from '../../rca-executive-summery.module.scss';

export enum ChangeDescriptionAlias {
  DROP = 'drop',
  SPIKE = 'spike',
}

const CHANGE_DESCRIPTION_ALIAS_MAP = {
  [ChangeDescription.INCREASE]: ChangeDescriptionAlias.SPIKE,
  [ChangeDescription.DECREASE]: ChangeDescriptionAlias.DROP,
};

interface RCAExecutiveSummary {
  loopsAlgo: RCAInsights;
  compareToDate: RCAInsights;
  metricSampleInsights: MetricSampleSentences;
  metricSampleWowOverview: MetricSampleWoWOverview;
}

interface OwnProps {
  mode: RCAMode;
  executiveSummary: RCAExecutiveSummary;
}
type AllProps = OwnProps;

export const RCAExecutiveSummaryOrchestrator = (props: AllProps) => {
  const {executiveSummary: executiveSummaryFromProps, mode} = props;
  const {productEntitiesMap} = useProductData();

  const executiveSummary = useMemo(
    () => ({
      [RCAMode.COMPARE_TO_DATE]: executiveSummaryFromProps.compareToDate,
      [RCAMode.LOOPS_ALGO]: executiveSummaryFromProps.loopsAlgo,
      metricSampleInsights: executiveSummaryFromProps.metricSampleInsights,
      metricSampleWowOverview: executiveSummaryFromProps.metricSampleWowOverview,
    }),
    [executiveSummaryFromProps]
  );

  const changeDescriptionAlias = useMemo(() => {
    if (!executiveSummary.metricSampleWowOverview) {
      return '';
    }
    return CHANGE_DESCRIPTION_ALIAS_MAP[
      executiveSummary.metricSampleWowOverview.wowChangeDescription
    ];
  }, [executiveSummary]);

  const {trendInsight, periodAllTimeHighOrLowInsight, numberOfUsersInsight} = useMemo(
    () => ({
      trendInsight: executiveSummary.metricSampleInsights?.trendInsight || null,
      periodAllTimeHighOrLowInsight:
        executiveSummary.metricSampleInsights?.periodAllTimeHighOrLowInsight || null,
      numberOfUsersInsight: executiveSummary.metricSampleInsights?.numberOfUsersInsight || null,
    }),
    [executiveSummary]
  );

  return (
    <>
      {executiveSummary.metricSampleWowOverview && (
        <MetricSampleWoWOverviewSection
          {...executiveSummary.metricSampleWowOverview}
          changeDescriptionAlias={changeDescriptionAlias}
          className={classes.MaxWidth}
        />
      )}
      <MetricSamplesSection
        trendInsight={trendInsight}
        periodAllTimeHighOrLowInsight={periodAllTimeHighOrLowInsight}
        numberOfUsersInsight={numberOfUsersInsight}
        productEntities={productEntitiesMap}
      />
      {executiveSummary[mode] && (
        <SegmentsInsightsSection
          mode={mode}
          potentialDrivers={executiveSummary[mode].potentialDrivers}
          interestingSegmentsPerformanceVsPriorWeek={
            executiveSummary[mode].interestingSegmentsPerformanceVsPriorWeek
          }
          changeDescriptionAlias={changeDescriptionAlias}
        />
      )}
    </>
  );
};
