import {useCallback, useContext, useEffect, useMemo} from 'react';
import classes from './features-main.module.scss';
import {featureGroupFilterFilter, queryFilter} from '../../../../constants/filters';
import {featureToastCreator} from '../../../../store/toasts.actions';
import {ModelKey} from '../../../../constants/model-key';
import {getFeaturesNetworkRequest} from '../../../../http/features.network-requests';
import TransKeys from '../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {TableColumn} from '../../../shared/components/general/table/table.component';
import {
  FlexHorizontal,
  FlexVertical,
} from '../../../shared/components/layout/flex-layout/general-flex-layouts.component.';
import {Title, TitleWithIcon} from '../../../shared/components/general/title/title.component';
import {Feature} from '../../../../objects/models/feature.model';
import {
  Button,
  Chip,
  CopyIcon,
  EditIcon,
  ShapesDuotoneIcon,
  HoverHelperTip,
  IconButton,
  MoreIcon,
  TrashIcon,
  RadarLightIcon,
} from 'ui-components';
import moment from 'moment';
import {MainTableStructure} from '../../../shared/infrastracture/main-table-structure/main-table-structure.component';
import {useDispatch, useSelector} from 'react-redux';
import {PanelKey} from '../../../../constants/panels';
import {FEATURE_ID_PATH_PARAM, SIGNAL_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {PanelType} from '../../../../objects/system/panel-type.enum';
import {replaceList} from '../../../../store/remote-lists/remote-list.actions';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../store/actions-listener/actions-listener.actions';
import {CoreActionsType} from '../../../../store/core/core.actions';
import {deleteFeatureConfirmed} from '../../../../store/features/features.actions';
import {generateLastModified} from '../../../../utils/history.utils';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {withStopPropagation} from '../../../../utils/general.utils';
import {Permission} from '../../../../core/components/permission.component';
import {Action, Subject} from '../../../../constants/permissions';
import usePermissions from '../../../../core/hooks/use-permissions.hook';
import {useCurrentUser} from '../../../../core/hooks/use-user.hook';
import {getListCount} from '../../../../store/store.selectors';
import {isNumber, keys} from 'lodash';
import {Content} from '../../../../objects/models/content.model';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {resampleModelConfirmed} from '../../../../store/model-samples/model-samples.actions';
import {ModelSampleSeriesModel} from '../../../../objects/models/model-sample-series.model';

import {ValidationStatus} from '../../../../objects/models/signal.model';
import {QuerySqlTabsNames} from '../../../shared/core/query-sql-tabs/query-sql-tabs.component';
import InvalidSignalWarning from '../../components/invalid-signal-warning/invalid-signal-warning.component';
import {ModelActionsDropdown} from '../../../shared/core/model-actions-dropdown/model-actions-dropdown.component';
import {useDemoProduct} from '../../../../core/hooks/use-demo-product.hook';
import {getEntityIcon} from '../../../../constants/entity.consts';

interface OwnProps {}

type AllProps = OwnProps;

const createListKey = () => `FEATURES_MAIN/FEATURES`;

const getFeatureBrokenSignals = (feature: Feature) => {
  const brokenSignals = [];
  if (feature.usageSignal?.validationStatus === ValidationStatus.ERROR) {
    brokenSignals.push(feature.usageSignal);
  }
  if (feature.exposureSignal?.validationStatus === ValidationStatus.ERROR) {
    brokenSignals.push(feature.exposureSignal);
  }
  if (feature.intentSignal?.validationStatus === ValidationStatus.ERROR) {
    brokenSignals.push(feature.intentSignal);
  }
  return brokenSignals;
};

export const FeaturesMain = (props: AllProps) => {
  const {t} = useTranslation();
  const {can} = usePermissions();
  const dispatch = useDispatch();
  const {openPrimaryPanel} = useContext(PanelsContext);
  const {productEntities, productEntitiesMap} = useProductData();
  const {isDemoProduct} = useDemoProduct();
  const notify = useAmplitude();
  const user = useCurrentUser();
  const defaultFilters = useMemo(
    () => ({
      orderBy: 'updatedOn',
      order: 'desc',
      itemsPerPage: 50,
    }),
    []
  );
  const total = useSelector(state => getListCount(createListKey())(state));
  const title = useMemo(
    () => `${t(TransKeys.FEATURES.HEADER.TITLE)} ${isNumber(total) ? ` (${total})` : ''}`,
    [t, total]
  );
  const filtersDef = useMemo(() => [queryFilter(), featureGroupFilterFilter()], []);
  const config = useMemo(
    () => ({
      listKey: createListKey(),
      actionKey: createListKey(),
      request: getFeaturesNetworkRequest,
      onError: err => [featureToastCreator('GET_ERROR')],
      modelKey: ModelKey.FEATURE,
    }),
    []
  );
  const showFeature = useCallback(
    (feature: Feature) => {
      const parameters = {[FEATURE_ID_PATH_PARAM]: feature.id};
      const brokenSignals = getFeatureBrokenSignals(feature);
      if (brokenSignals.length > 0) {
        if (!isDemoProduct) {
          parameters['initialTab'] = QuerySqlTabsNames.SQL;
        }
        parameters[SIGNAL_ID_PATH_PARAM] = brokenSignals[0].id;
      }

      openPrimaryPanel(PanelKey.VIEW_FEATURE_PANEL, parameters, PanelType.MODAL);
    },
    [openPrimaryPanel, isDemoProduct]
  );
  const onDelete = useCallback(
    (feature: Feature) => dispatch(deleteFeatureConfirmed(feature.id)),
    [dispatch]
  );
  const onResample = useCallback(
    (feature: Feature) => {
      dispatch(
        resampleModelConfirmed({
          modelId: feature.id,
          modelType: ModelSampleSeriesModel.FEATURE,
        })
      );
    },
    [dispatch]
  );
  const onCreateEdit = useCallback(
    (feature?: Feature, parameters = {}) => {
      openPrimaryPanel(
        PanelKey.FEATURE_FORM_PANEL,
        {
          [FEATURE_ID_PATH_PARAM]: feature?.id,
          ...parameters,
        },
        PanelType.MODAL
      );
      if (!feature) {
        notify(AmplitudeEvent.FEATURE_CREATE_CLICKED, {
          userId: user.id,
        });
      }
      if (feature && parameters.cloneMode) {
        notify(AmplitudeEvent.FEATURE_DUPLICATE_CLICKED, {
          userId: user.id,
        });
      }
      if (feature && !parameters.cloneMode) {
        notify(AmplitudeEvent.FEATURE_EDIT_CLICKED, {
          userId: user.id,
        });
      }
    },
    [openPrimaryPanel, user, notify]
  );
  const columns: TableColumn[] = useMemo(
    () => [
      {
        key: 'name',
        title: t(TransKeys.GENERAL.HEADERS.NAME),
        width: '40rem',
        sortable: true,
        sticky: 'left',
        stretch: true,
        render: (feature: Feature) => (
          <FlexVertical spacing verticalAlignCenter className={classes.TitleWrapper}>
            <FlexHorizontal spacing verticalAlignCenter>
              <Title className={classes.Title} text={feature.name} caps={false} />
              {feature.shortDescription && <HoverHelperTip title={feature.shortDescription} />}
              {getFeatureBrokenSignals(feature).length > 0 && (
                <InvalidSignalWarning
                  title={t(TransKeys.FEATURES.SIGNAL_VALIDATION_STATUS_HELPER_TEXT)}
                />
              )}
            </FlexHorizontal>
            {feature.tags.length > 0 && (
              <FlexHorizontal spacing>
                {feature.tags.map(t => (
                  <Chip label={t} caps={false} key={t} size={'small'} />
                ))}
              </FlexHorizontal>
            )}
          </FlexVertical>
        ),
      },
      {
        key: 'entity',
        title: t(TransKeys.GENERAL.HEADERS.ENTITY),
        width: '12rem',
        sortable: true,
        hidden: keys(productEntities).length < 2,
        render: (content: Content) => (
          <FlexVertical spacing verticalAlignCenter className={classes.TitleWrapper}>
            <TitleWithIcon
              text={productEntitiesMap[content.entity]?.name}
              icon={getEntityIcon(content.entity)}
            />
          </FlexVertical>
        ),
      },
      {
        key: 'updatedOn',
        title: t(TransKeys.GENERAL.HEADERS.UPDATED),
        sortable: true,
        width: '18rem',
        render: (feature: Feature) => {
          const modifier = generateLastModified(feature.history[0], true);
          if (modifier) {
            return (
              <FlexVertical spacing verticalAlignCenter>
                {modifier.user ? t(TransKeys.GENERAL.LABELS.MODIFIED_BY, modifier) : modifier.date}
              </FlexVertical>
            );
          }
          return (
            <FlexVertical spacing verticalAlignCenter>
              {moment.utc(feature.updatedOn).local().fromNow()}
            </FlexVertical>
          );
        },
      },
      {
        key: 'actions',
        title: '',
        width: '12rem',
        align: 'right',
        sticky: 'right',
        render: (feature: Feature) => (
          <div className={classes.Actions}>
            <Permission subject={Subject.FEATURE} action={Action.EDIT}>
              <IconButton
                className={classes.Button}
                tooltipText={t(TransKeys.GENERAL.ACTIONS.EDIT)}
                icon={EditIcon}
                onClick={withStopPropagation(() => onCreateEdit(feature))}
              />
            </Permission>
            <Permission subject={Subject.FEATURE} action={Action.CREATE}>
              <IconButton
                className={classes.Button}
                tooltipText={t(TransKeys.GENERAL.ACTIONS.DUPLICATE)}
                icon={CopyIcon}
                onClick={withStopPropagation(() => onCreateEdit(feature, {cloneMode: true}))}
              />
            </Permission>
            <ModelActionsDropdown
              className={classes.Button}
              actions={[
                {
                  key: 'resample',
                  title: t(TransKeys.GENERAL.ACTIONS.RESCAN),
                  onClick: () => onResample(feature),
                  icon: RadarLightIcon,
                },
                {
                  key: 'delete',
                  disabled: !can(Subject.FEATURE, Action.DELETE),
                  title: t(TransKeys.GENERAL.ACTIONS.DELETE),
                  onClick: () => onDelete(feature),
                  icon: TrashIcon,
                },
              ]}
              label={t(TransKeys.GENERAL.LABELS.MORE_DOTS)}
              icon={MoreIcon}
              iconDropdown
            />
          </div>
        ),
      },
    ],
    [t, can, onCreateEdit, onDelete, productEntities, productEntitiesMap, onResample]
  );

  useEffect(() => {
    const listener = action => {
      if (action.payload.modelKey === ModelKey.FEATURE) {
        dispatch(replaceList(config.listKey, {page: 1}, 'append'));
      }
    };
    dispatch(registerActionListener(CoreActionsType.MODEL_CREATED, listener));
    return () => {
      dispatch(removeActionListener(CoreActionsType.MODEL_CREATED, listener));
    };
  }, [dispatch, config]);

  return (
    <MainTableStructure
      className={classes.FeaturesMain}
      title={title}
      icon={ShapesDuotoneIcon}
      onRowClicked={feature => showFeature(feature)}
      columns={columns}
      config={config}
      defaultFilters={defaultFilters}
      filtersDef={filtersDef}
      pagination
      paginationMode={'pages'}
      helperTextTitle={t(TransKeys.FEATURES.HEADER.HELPER_TEXT)}
      headerRenderRight={
        <Permission subject={Subject.FEATURE} action={Action.CREATE}>
          <Button onClick={() => onCreateEdit()}>
            {t(TransKeys.GENERAL.ACTIONS.CREATE_FEATURE)}
          </Button>
        </Permission>
      }
      emptyStateTranslationPath={TransKeys.FEATURES.EMPTY_STATE}
      itemsPerPage={50}
    />
  );
};
