import React, {useCallback, useMemo} from 'react';
import {Checkbox} from 'ui-components';
import classes from './cron-exp-checkbox.module.scss';
import {useCronExpBuilder} from '../../../../cron-exp-builder-context-provider/cron-exp-builder-context-provider.component';

interface OwnProps {
  setCronExpInputValue: (cronExpValue: string) => void;
  currentCronExpInputValue: string;
}

type AllProps = OwnProps;

const CronExpCheckbox = (props: AllProps) => {
  const {setCronExpInputValue, currentCronExpInputValue} = props;
  const {cronExpValue} = useCronExpBuilder();
  const checked = useMemo(
    () => currentCronExpInputValue === cronExpValue,
    [currentCronExpInputValue, cronExpValue]
  );
  const onChange = useCallback(
    () => setCronExpInputValue(cronExpValue),
    [setCronExpInputValue, cronExpValue]
  );
  return <Checkbox onChange={onChange} checked={checked} className={classes.Checkbox} />;
};

export default CronExpCheckbox;
