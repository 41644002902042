import {useMemo, useCallback, useEffect} from 'react';
import {
  TableType,
  TableEntity,
  TableEntityBinding,
} from '../../../../../objects/models/table.model';
import {QueryBuilderFactory, ConditionOperator} from 'ui-components';
import {
  METADATA_KEY,
  PARAMETERS_METADATA_KEY,
} from '../../../../../constants/parameters-saved-keys';
import {
  TableEventsQueryBuilder,
  createTableEventsInitialQuery,
} from '../table-events-query-builder/table-events-query-builder.component';
import {StandardCheckBox} from '../../../components/general/standard-check-box/standard-check-box.component';
import classes from './active-query-builder.module.scss';
import {TableSmartSelector} from '../../smart-selector/table-smart-selector.component';
import classNames from 'classnames';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';

enum ActiveQuerySubBuilderType {
  AnyEventQueryBuilder = 'AnyEventQueryBuilder',
  TableSmartSelector = 'TableSmartSelector',
  TableEventsQueryBuilder = 'TableEventsQueryBuilder',
}

interface OwnProps {
  query: any;
  onChange: (value: any) => void;
  errors?: any;
  filters?: any;
  entityContext?: TableEntity;
  disabled?: boolean;
}

const BUILDER_COMPONENT_NAME = 'ActiveQueryBuilder';

type AllProps = OwnProps;

const createTableQuery = (tableId = null) => {
  const query = QueryBuilderFactory.createCondition();
  query.left = QueryBuilderFactory.createTableColumn();
  query.left.table_id = tableId;
  query.left.column = 'event_name';
  query.right = QueryBuilderFactory.createLiteral(true);
  query.op = ConditionOperator.NE;
  return query;
};

const createAnyEventQuery = () => {
  const query = QueryBuilderFactory.createCondition();
  query.left = QueryBuilderFactory.createTemplate('all_events');
  query.right = QueryBuilderFactory.createLiteral(true);
  query.op = ConditionOperator.NE;
  return query;
};

export const createActiveInitialQuery = (
  subBuilderType = ActiveQuerySubBuilderType.AnyEventQueryBuilder
) => {
  let q = {};

  if (subBuilderType === ActiveQuerySubBuilderType.AnyEventQueryBuilder) {
    q = createAnyEventQuery();
  } else if (subBuilderType === ActiveQuerySubBuilderType.TableSmartSelector) {
    q = createTableQuery();
  } else if (subBuilderType === ActiveQuerySubBuilderType.TableEventsQueryBuilder) {
    q = createTableEventsInitialQuery();
  }

  q[PARAMETERS_METADATA_KEY] = {
    [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: BUILDER_COMPONENT_NAME,
    [METADATA_KEY.SUB_BUILDER_COMPONENT_NAME_KEY]: subBuilderType,
  };

  return q;
};

export const ActiveQueryBuilder = (props: AllProps) => {
  const {query, errors, entityContext, onChange: onChangeFromProps, disabled} = props;
  const {t} = useTranslation();

  useEffect(() => {
    if (!query) {
      onChangeFromProps(createActiveInitialQuery());
    }
  }, [query, onChangeFromProps]);

  const subBuilder: ActiveQuerySubBuilderType = useMemo(() => {
    const builderComponentName =
      query?.[PARAMETERS_METADATA_KEY]?.[METADATA_KEY.BUILDER_COMPONENT_NAME_KEY];

    // Legacy mode for back when the entire query was TableEventsQueryBuilder
    if (builderComponentName === ActiveQuerySubBuilderType.TableEventsQueryBuilder) {
      return ActiveQuerySubBuilderType.TableEventsQueryBuilder;
    }

    return query?.[PARAMETERS_METADATA_KEY]?.[METADATA_KEY.SUB_BUILDER_COMPONENT_NAME_KEY];
  }, [query]);

  const onSubBuilderChange = useCallback(
    (subBuilderType: ActiveQuerySubBuilderType) => {
      const newQuery = createActiveInitialQuery(subBuilderType);
      onChangeFromProps(newQuery);
    },
    [onChangeFromProps]
  );

  const onChange = useCallback(
    newQuery => {
      newQuery[PARAMETERS_METADATA_KEY] = {
        ...(newQuery[PARAMETERS_METADATA_KEY] || {}),
        [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: BUILDER_COMPONENT_NAME,
        [METADATA_KEY.SUB_BUILDER_COMPONENT_NAME_KEY]: subBuilder,
      };
      onChangeFromProps(newQuery);
    },
    [onChangeFromProps, subBuilder]
  );

  const tableFilters = useMemo(
    () => ({
      type: TableType.EVENTS,
      entityBinding: TableEntityBinding.TWO_WAY,
      entityContext,
    }),
    [entityContext]
  );

  const onTableChange = useCallback(
    (tableId: number) => onChange(createTableQuery(tableId)),
    [onChange]
  );

  const options = [
    {
      key: ActiveQuerySubBuilderType.AnyEventQueryBuilder,
      label: t(TransKeys.QUERY_BUILDERS.ACTIVE.OPTIONS.ANY_TABLE_ANY_EVENT),
    },
    {
      key: ActiveQuerySubBuilderType.TableSmartSelector,
      label: t(TransKeys.QUERY_BUILDERS.ACTIVE.OPTIONS.TABLE_ANY_EVENT),
      render: () => (
        <TableSmartSelector
          value={query?.left?.table_id}
          placeholder={'Select table'}
          onChange={onTableChange}
          error={Boolean(errors?.left?.table_id)}
          filters={tableFilters}
          disabled={disabled}
        />
      ),
    },
    {
      key: ActiveQuerySubBuilderType.TableEventsQueryBuilder,
      label: t(TransKeys.QUERY_BUILDERS.ACTIVE.OPTIONS.TABLE_EVENT),
      render: () => (
        <TableEventsQueryBuilder
          query={query}
          onChange={onChange}
          errors={errors}
          filters={tableFilters}
          disabled={disabled}
        />
      ),
    },
  ];

  return (
    <div className={classes.ActiveQueryBuilder}>
      {options.map(option => (
        <>
          <div className={classes.Row} key={option.key}>
            <StandardCheckBox
              radio
              disabled={disabled}
              checked={subBuilder === option.key}
              onChange={() => onSubBuilderChange(option.key)}
            />
            {option.label}
          </div>
          {option.render && subBuilder === option.key && (
            <div className={classNames(classes.Row, classes.Selected)}>{option.render()}</div>
          )}
        </>
      ))}
    </div>
  );
};
