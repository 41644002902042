import {useCallback, useState} from 'react';
import {
  ANALYSIS_ID_PATH_PARAM,
  ANALYSIS_RESULT_ID_PATH_PARAM,
  ANALYSIS_TYPE_ID_PATH_PARAM,
} from '../../../../constants/app-routes';
import {useDispatch, useSelector} from 'react-redux';
import {AnalysisFileIcon, FancyHeader, ModalLayout} from 'ui-components';
import classes from './rename-analysis-panel.module.scss';
import {getReducedLoadingStateSelector} from '../../../../store/store.selectors';
import {ActionKey} from '../../../../constants/action-key';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {getAnalysisResultNetworkRequest} from '../../../../http/analysis-results.network-requests';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {AnalysisResult} from '../../../../objects/models/analysis-result.model';
import {AnalysisInfoTab} from '../analysis-form-panel/components/analysis-info-tab/analysis-info-tab.component';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {updateAnalysisResult} from '../../../../store/analyses/analyses.actions';
import {composition} from 'front-core';
import {withDisableDemoProduct} from '../../../../core/hoc/with-disable-demo-product.hoc';

interface OwnProps {
  analysisResult?: AnalysisResult;
  onClose?: () => void;
  panelId?: string;
  [ANALYSIS_RESULT_ID_PATH_PARAM]?: number;
  [ANALYSIS_ID_PATH_PARAM]?: number;
  [ANALYSIS_TYPE_ID_PATH_PARAM]?: number;
  disabled?: boolean;
}

type AllProps = OwnProps;

const SELECTED_ANALYSIS_RESULT_KEY = SharedSelectionKeys.RENAME_ANALYSIS_PANEL__ANALYSIS_RESULT;

const RenameAnalysisPanelComponent = (props: AllProps) => {
  const {onClose, analysisResult, disabled} = props;
  const dispatch = useDispatch();

  const [formData] = useState({
    ...analysisResult,
  });
  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(ActionKey.REQUEST_ANALYSIS)(state)
  );

  const onSubmit = useCallback(
    data => {
      dispatch(
        updateAnalysisResult(
          {
            id: analysisResult?.id,
            ...data,
          },
          () => {
            onClose();
          }
        )
      );
    },
    [dispatch, onClose, analysisResult]
  );

  return (
    <div className={classes.RenameAnalysisPanelContainer}>
      <ModalLayout>
        {isLoading && <GenericLoading />}
        <div className={classes.RenameAnalysisPanel}>
          <FancyHeader
            title={'Rename Analysis'}
            icon={AnalysisFileIcon}
            onClose={onClose}
            className={classes.Header}
          />
          <div className={classes.Content}>
            <AnalysisInfoTab
              disabled={disabled}
              data={formData}
              onSubmit={data => onSubmit(data)}
              renameMode
            />
          </div>
        </div>
      </ModalLayout>
    </div>
  );
};

export const RenameAnalysisPanel = composition<AllProps>(
  RenameAnalysisPanelComponent,
  withDisableDemoProduct,
  withLoadBefore({
    analysisResult: {
      selectedKey: SELECTED_ANALYSIS_RESULT_KEY,
      actionKey: SELECTED_ANALYSIS_RESULT_KEY,
      request: getAnalysisResultNetworkRequest,
      mapPayloadFromProps: props => props[ANALYSIS_RESULT_ID_PATH_PARAM],
      shouldCall: props => props[ANALYSIS_RESULT_ID_PATH_PARAM] !== undefined,
    },
  })
);
