import {calculateUplift, exists} from 'front-core';
import {HomepageMetric, HomepageModel} from '../../../../objects/models/homepage.model';
import {MetricType} from '../../../../objects/models/metric.model';
import {
  BOUNDED_ACTION_ANALYSIS_PARAMETER_MAPPING,
  BOUNDED_ACTION_NUMERIC_PARAMETER_MAPPING,
  BOUNDED_ACTIONS_ANALYSIS_PARAMETER_MAPPING,
  HABIT_MOMENT_PARAMETER_MAPPING,
} from '../../../shared/core/query-builders/query-builders.config';
import {get} from 'lodash';
import {SqlElementType} from 'ui-components';
import {METADATA_KEY, PARAMETERS_METADATA_KEY} from '../../../../constants/parameters-saved-keys';
import HttpClient from '../../../../services/http-client.service';
import {multiLoaderNetworkRequest} from '../../../../http/multi-loader.network-requests';
import {
  ModelSample,
  ModelSampleSeriesModel,
  ModelSeriesGranularity,
} from '../../../../objects/models/model-sample-series.model';

export const getSampleWoWChange = (
  lastSample: ModelSample,
  prevSample: ModelSample,
  higherIsBetter: boolean = true
): {
  value: number;
  expected: number;
  isBetter?: boolean;
  isSignificant?: boolean;
  inf?: boolean;
} => {
  if (!lastSample || !prevSample) {
    return;
  }

  if (!exists(lastSample.value) || !exists(prevSample.value)) {
    return undefined;
  }

  const trendValue = calculateUplift(lastSample.value, prevSample.value) * 100;
  let isBetter = trendValue >= 0;
  if (higherIsBetter === false) {
    isBetter = !isBetter;
  }
  const isSignificant = lastSample.value > lastSample.upper || lastSample.value < lastSample.lower;

  return {
    value: trendValue,
    expected: lastSample.expectedValue,
    isBetter,
    inf: prevSample.value === 0 && lastSample.value > 0,
    isSignificant,
  };
};

export const extractXLabelFromMetric = async (
  metric: HomepageMetric,
  entityName: string,
  granularity: ModelSeriesGranularity
): Promise<string> => {
  let mapping = null;
  switch (metric.type) {
    case MetricType.RETENTION:
      mapping = BOUNDED_ACTIONS_ANALYSIS_PARAMETER_MAPPING;
      break;
    case MetricType.CONVERSION:
      mapping = BOUNDED_ACTION_ANALYSIS_PARAMETER_MAPPING;
      break;
    case MetricType.HABIT_MOMENT:
      mapping = HABIT_MOMENT_PARAMETER_MAPPING;
      break;
    case MetricType.BOUNDED_REVENUE:
      mapping = BOUNDED_ACTION_NUMERIC_PARAMETER_MAPPING;
      break;
    case MetricType.PAYMENT_RETENTION:
      return `${entityName} first payment`;
    case MetricType.DAU:
      return 'day';
    case MetricType.WAU:
    case MetricType.L7:
      return 'week';
    case MetricType.MAU:
    case MetricType.L28:
      return 'month';
    case MetricType.USAGE:
    case MetricType.REVENUE:
    case MetricType.BEHAVIORAL_CHURN:
    case MetricType.RATE:
    case MetricType.CUSTOM_SQL:
      return granularity;
  }
  if (mapping) {
    const refDateDefinition = get(metric.signalDefinition, mapping.ref_date);
    if (!exists(refDateDefinition)) {
      return granularity;
    }
    if (refDateDefinition.type === SqlElementType.TABLE_COLUMN) {
      return refDateDefinition.column;
    }
    if (refDateDefinition.type === SqlElementType.SIGNAL_COLUMN) {
      const res = await HttpClient.exec(
        multiLoaderNetworkRequest({
          signals: [refDateDefinition.signal_id],
        })
      );
      return get(res, `signals.${refDateDefinition.signal_id}.name`)?.toLowerCase();
    }
    if (
      get(
        refDateDefinition,
        `${PARAMETERS_METADATA_KEY}.${METADATA_KEY.BUILDER_COMPONENT_NAME_KEY}`
      ) === 'TemplateItemQueryBuilder'
    ) {
      return get(refDateDefinition, `${PARAMETERS_METADATA_KEY}.${METADATA_KEY.DISPLAY_NAME_KEY}`);
    }
  }

  return granularity;
};

export const transformModelName = (model: HomepageModel) => {
  if (!model) {
    return '';
  }
  if (model.modelType === ModelSampleSeriesModel.FUNNEL) {
    return model.name.toLowerCase().includes('funnel') ? model.name : model.name + ' funnel';
  }
  return model.name;
};
