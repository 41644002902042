import classNames from 'classnames';
import classes from './smart-selector.module.scss';
import {CloseIcon, DropdownButton, EditIcon, InputButton, Link} from 'ui-components';
import {Popover} from '@material-ui/core';
import {sharedClasses} from '../../index';
import {forwardRef, useImperativeHandle, useState} from 'react';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {withStopPropagation} from 'front-core';

interface OwnProps {
  id: string;
  label?: string;
  placeholder?: string;
  icon?: any;
  helperText?: string;
  error?: boolean;
  className?: string;
  disabled?: boolean;
  clearable?: boolean;
  onClear?: () => void;
  onEdit?: () => void;
  allowEdit?: boolean;
  onCreate?: () => void;
  addButton?: boolean;
  withPreview?: boolean;
  children: any;
  fullWidth?: boolean;
  ref?: any;
}

type AllProps = OwnProps;

const SmartSelectorLayout = forwardRef((props: AllProps, ref) => {
  const {
    id: idFromProps,
    label,
    placeholder,
    icon,
    helperText,
    children,
    error,
    onEdit,
    allowEdit,
    onClear,
    onCreate,
    clearable,
    disabled,
    withPreview = true,
    fullWidth,
    className,
    addButton,
  } = props;
  const {t} = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = event => {
    if (disabled) {
      return;
    }
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useImperativeHandle(ref, () => ({
    close: () => {
      setAnchorEl(null);
    },
  }));
  const open = Boolean(anchorEl);
  const id = open ? idFromProps : undefined;

  return (
    <>
      <div className={classes.SmartSelectButtonWrapper}>
        <DropdownButton
          className={classNames(classes.SmartSelectButton, className)}
          error={error}
          onClick={handleClick}
          icon={icon}
          helperText={helperText}
          fullWidth={fullWidth}
          placeholder={placeholder}
          label={label}
          isOpen={open}
          disabled={disabled}
          actions={
            <>
              {clearable && (
                <InputButton
                  icon={CloseIcon}
                  text={'Clear'}
                  className={classes.AdditionalAction}
                  onClick={withStopPropagation(() => onClear())}
                />
              )}
              {onEdit && allowEdit && (
                <InputButton
                  icon={EditIcon}
                  text={'Edit'}
                  className={classes.AdditionalAction}
                  onClick={withStopPropagation(() => onEdit())}
                />
              )}
            </>
          }
        />
        {addButton && onCreate && (
          <div className={classes.CreateLine}>
            or
            <Link onClick={onCreate} className={classes.CreateButton}>
              {t(TransKeys.GENERAL.ACTIONS.CREATE)}
            </Link>
          </div>
        )}
      </div>
      <Popover
        className={sharedClasses.BlankPaper}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        disablePortal
        classes={{
          paper: sharedClasses.BlankPaper,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div
          className={
            withPreview ? classes.PreviewSelectorWrapper : classes.NoPreviewSelectorWrapper
          }
        >
          {children}
        </div>
      </Popover>
    </>
  );
});

export {SmartSelectorLayout};
