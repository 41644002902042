import {ErrorViewer, ErrorFigure, GlobalDocumentDataContext} from 'ui-components';
import {Error1000Viewer} from './error-1000-viewer.component';
import {Error2001Viewer} from './error-2001-viewer.component';
import {Error2003Viewer} from './error-2003-viewer.component';
import {Error2004Viewer} from './error-2004-viewer.component';
import {useContext} from 'react';

export interface AppErrorFigureProps extends ErrorFigure {
  className?: string;
}

const COMPONENTS_MAP_BY_ERROR_CODE = {
  1000: Error1000Viewer,
  2000: Error1000Viewer,
  2001: Error2001Viewer,
  2003: Error2003Viewer,
  2004: Error2004Viewer,
};

export const AppErrorViewer = (props: AppErrorFigureProps) => {
  const {
    data: {code},
  } = props;
  const globalData = useContext(GlobalDocumentDataContext);
  const ErrorComponent = COMPONENTS_MAP_BY_ERROR_CODE[code];

  const newProps = {
    ...props,
    ...globalData,
  };

  if (ErrorComponent) {
    return <ErrorComponent {...newProps} />;
  }

  return <ErrorViewer {...newProps} />;
};
