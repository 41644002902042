import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';
import classes from '../../analysis-forms.module.scss';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../../constants/translation-keys';
import {TimeFrameSelector} from '../../components/ui-selectors/time-frame-selector/time-frame-selector.component';
import {SegmentFilterSelector} from '../../components/ui-selectors/segment-filter-selector/segment-filter-selector.component';
import {ParametersFormContext} from '../../../../shared/core/parameters-form/parameters-form.context';
import {startEndDatesDefaultHandler} from '../../../../shared/core/parameters-form/parameters-form-default-handlers.utils';
import {useProductData} from '../../../../../core/hooks/use-product-data.hook';
import {get, values} from 'lodash';
import {EntitySelector} from '../../components/ui-selectors/entity-selector/entity-selector.component';
import {TableEntity, TableEntityBinding} from '../../../../../objects/models/table.model';
import {AnalysisFormProps} from '../../analysis-forms.types';
import {
  DialogLayout,
  DropdownButton,
  PopoverWrapper,
  SqlElementType,
  WarningIcon,
} from 'ui-components';
import {SignalDataType, SignalType} from '../../../../../objects/models/signal.model';
import {TreatmentSelector} from '../../components/ui-selectors/treatment-selector/treatment-selector.component';
import {exists} from 'front-core';
import {
  METADATA_KEY,
  PARAMETERS_METADATA_KEY,
} from '../../../../../constants/parameters-saved-keys';
import {createUndefinedObject, hasError} from '../../../../../utils/general.utils';
import {AnalysisParametersFormMode} from '../../../panels/analysis-form-panel/components/analysis-parameters-tab/analysis-parameters-tab.component';
import {AnalysisSelectorVariant} from '../../components/ui-selectors/analysis-selector.types';
import {DaysCountSelector} from '../../components/ui-selectors/days-count-selector/days-count-selector.component';
import {SqlElementToTextUtils} from '../../../../../utils/sql-element-to-text.utils';
import {
  AnalysisFormPart,
  AnalysisFormSentence,
  AnalysisSimpleForm,
} from '../../components/simple-form-layout/analysis-simple-form.component';
import GoalQueryStrategySelector, {
  createInitialGoalQueryByStrategy,
  GoalStrategyPickerSchemaKeysMapping,
  UIGoalType,
} from '../../components/ui-selectors/goal-query-strategy-selector/goal-query-strategy-selector.component';
import {ExtendedParameters} from '../../../../shared/form/form-layout/extended-parameters/extended-parameters.component';
import {ConfoundersSelector} from '../../components/ui-selectors/confounders-selector/confounders-selector.component';
import {useTreatmentSignals} from '../../hooks/treatment-signals.hook';
import {GroupBySelector} from '../../components/ui-selectors/group-by-selector/group-by-selector.component';

export const QUERY_GOAL_KEY = 'goal_query';
const treatmentsSelectorMap = {
  treatments_tag: 'treatments_tag',
  treatments_signals: 'treatments_signals',
  days_count_key: 'max_days_from_reference',
};
const confoundersSelectorMap = {
  confounders_tag: 'confounders_tag',
  confounders_signals: 'confounders_signals',
};
const entitySchemaMapping = {
  entity: 'entity',
};
const segmentFilterSchemaMapping = {
  population_filter: 'population_filter',
};
export const goalQueryStrategySchemaMapping: GoalStrategyPickerSchemaKeysMapping = {
  goal_query: 'goal_query',
  ui_goal_type: 'model_version',
};
const GROUP_BY_SIGNAL_MAPPING = {
  signal_id: 'group_by_signal',
};

const ENABLED_STRATEGIES = [UIGoalType.CONVERSION, UIGoalType.RETENTION];

const CONFOUNDERS_SELECTOR_ELEMENT_ID = 'confounders_selector';

const TREATMENT_SIGNAL_FILTERS = [
  {
    type: SignalType.MEASURE,
    data_type: [SignalDataType.BOOLEAN, SignalDataType.STRING],
  },
];
const createGroupBySignalFilters = (entityContext = undefined) => ({
  type: SignalType.DIMENSION,
  entity_binding: TableEntityBinding.ONE_WAY,
  entityContext,
});

export const Analysis80Form = (props: AnalysisFormProps) => {
  const {onSignalInfo, className} = props;
  const {t} = useTranslation();
  const {
    errors,
    parameters,
    formMode,
    changeParametersValue,
    registerDefaultHandler,
    removeDefaultHandler,
  } = useContext(ParametersFormContext);
  const {getSignalByTag, productEntities, defaultTableEntity} = useProductData();
  const [isOpenAdvancedParams, setIsOpenAdvancedParams] = useState(false);
  const entityContext = parameters[entitySchemaMapping.entity];
  const goalType = useMemo(
    () => parameters[goalQueryStrategySchemaMapping.ui_goal_type],
    [parameters]
  );
  const selectedTreatmentSignals = useTreatmentSignals(parameters, treatmentsSelectorMap);

  useEffect(() => {
    registerDefaultHandler('start_end_dates', startEndDatesDefaultHandler);
    registerDefaultHandler('analysis_80', parameters => {
      const defaults = {};
      defaults[entitySchemaMapping.entity] = defaultTableEntity;
      defaults[treatmentsSelectorMap.days_count_key] = 14;
      defaults[goalQueryStrategySchemaMapping.ui_goal_type] = UIGoalType.CONVERSION;
      defaults[goalQueryStrategySchemaMapping.goal_query] = createInitialGoalQueryByStrategy(
        goalType,
        getSignalByTag,
        entityContext
      );
      return defaults;
    });

    return () => {
      removeDefaultHandler('start_end_dates');
      removeDefaultHandler('analysis_80');
    };
  }, [
    registerDefaultHandler,
    removeDefaultHandler,
    getSignalByTag,
    goalType,
    entityContext,
    defaultTableEntity,
  ]);
  const groupBySignalFilters = useMemo(
    () => createGroupBySignalFilters(entityContext),
    [entityContext]
  );
  // duplicated (102)
  const treatmentSelectorSince = useMemo(() => {
    const refDate = get(parameters, `${QUERY_GOAL_KEY}.parameters.4`);
    if (!exists(refDate)) {
      return;
    }
    if (refDate.type === SqlElementType.TABLE_COLUMN) {
      return refDate.column;
    }
    if (
      get(refDate, `${PARAMETERS_METADATA_KEY}.${METADATA_KEY.BUILDER_COMPONENT_NAME_KEY}`) ===
      'TemplateItemQueryBuilder'
    ) {
      return get(refDate, `${PARAMETERS_METADATA_KEY}.${METADATA_KEY.DISPLAY_NAME_KEY}`);
    }
    if (refDate.type === SqlElementType.SIGNAL_COLUMN) {
      return refDate.signal_id;
    }
  }, [parameters]);

  const onChangeEntityContext = useCallback(
    (entity: TableEntity) => {
      const resetKeys = Array.from(
        new Set([
          treatmentsSelectorMap.treatments_signals,
          treatmentsSelectorMap.treatments_tag,
          QUERY_GOAL_KEY,
          ...values(GROUP_BY_SIGNAL_MAPPING),
          ...values(segmentFilterSchemaMapping),
        ])
      );
      const resetParameters = createUndefinedObject(resetKeys);
      changeParametersValue({
        [entitySchemaMapping.entity]: entity,
        ...resetParameters,
      });
    },
    [changeParametersValue]
  );

  const goalLabel = useMemo(
    () => SqlElementToTextUtils.translate(parameters[QUERY_GOAL_KEY]),
    [parameters]
  );
  const gotoConfounders = useCallback(() => {
    setIsOpenAdvancedParams(true);
    setTimeout(() => {
      document.getElementById(CONFOUNDERS_SELECTOR_ELEMENT_ID).scrollIntoView({behavior: 'smooth'});
    }, 0);
  }, [setIsOpenAdvancedParams]);
  const treatmentWarnMessage = useMemo(() => {
    if (selectedTreatmentSignals.length === 0) {
      return null;
    }
    const signals = selectedTreatmentSignals.filter(
      s => s.type === SignalType.MEASURE && s.dataType === SignalDataType.BOOLEAN
    );

    if (signals.length <= 3 && selectedTreatmentSignals.length === signals.length) {
      return (
        <div className={classes.TreatmentWarn}>
          <WarningIcon className={classes.WarnIcon} />
          <span className={classes.Part}>
            {t(
              TransKeys.ANALYSIS_FORMS.ANALYSIS_80.TREATMENT_SELECTOR
                .SELECT_MORE_CONFOUNDERS_WARNING_MESSAGE_PART_1
            )}
          </span>
          <span className={classes.Link} onClick={gotoConfounders}>
            {t(
              TransKeys.ANALYSIS_FORMS.ANALYSIS_80.TREATMENT_SELECTOR
                .SELECT_MORE_CONFOUNDERS_WARNING_MESSAGE_LINK_TEXT
            )}
          </span>
          <span className={classes.Part}>
            {t(
              TransKeys.ANALYSIS_FORMS.ANALYSIS_80.TREATMENT_SELECTOR
                .SELECT_MORE_CONFOUNDERS_WARNING_MESSAGE_PART_2
            )}
          </span>
        </div>
      );
    }
    return null;
  }, [selectedTreatmentSignals, gotoConfounders, t]);
  const isOpenAdvancedParamsOrError = useMemo(() => {
    return isOpenAdvancedParams || hasError(errors, [...values(confoundersSelectorMap)]);
  }, [isOpenAdvancedParams, errors]);

  const goalQueryStrategySelectorSharedProps = {
    schemaKeysMapping: goalQueryStrategySchemaMapping,
    title: t(TransKeys.ANALYSIS_FORMS.ANALYSIS_80.GOAL.TITLE),
    subTitle: t(TransKeys.ANALYSIS_FORMS.ANALYSIS_80.GOAL.SUB_TITLE),
    helperText: t(TransKeys.ANALYSIS_FORMS.ANALYSIS_80.GOAL.HELPER_TEXT),
    errors,
    value: parameters,
    onChange: changeParametersValue,
    onSignalInfo,
    entityContext,
    enabledStrategies: ENABLED_STRATEGIES,
  };

  const renderAdvancedMode = () => (
    <div className={classNames(classes.AnalysisForm, className)}>
      <EntitySelector
        value={parameters}
        productEntities={productEntities}
        schemaKeysMapping={entitySchemaMapping}
        onChange={v => onChangeEntityContext(v[entitySchemaMapping.entity])}
        className={classes.Parameter}
      />
      <GoalQueryStrategySelector {...goalQueryStrategySelectorSharedProps} />
      <TreatmentSelector
        value={parameters}
        onChange={changeParametersValue}
        className={classes.Parameter}
        schemaKeysMapping={treatmentsSelectorMap}
        errors={errors}
        since={treatmentSelectorSince}
        warnMessage={treatmentWarnMessage}
        entityContext={entityContext}
        signalFilters={TREATMENT_SIGNAL_FILTERS}
      />
      <TimeFrameSelector className={classes.Parameter} errors={errors} />
      {entityContext && (
        <SegmentFilterSelector
          onChange={changeParametersValue}
          value={parameters}
          className={classes.Parameter}
          entityContext={entityContext}
          schemaKeysMapping={segmentFilterSchemaMapping}
          errors={errors}
        />
      )}
      <ExtendedParameters
        className={classes.SpaceBottom}
        label={t(TransKeys.GENERAL.LABELS.ADVANCED_PARAMETERS)}
        isOpen={isOpenAdvancedParamsOrError}
        onOpenChanged={() => setIsOpenAdvancedParams(!isOpenAdvancedParams)}
      >
        <GroupBySelector
          value={parameters}
          onChange={changeParametersValue}
          onSignalInfo={onSignalInfo}
          schemaKeysMapping={GROUP_BY_SIGNAL_MAPPING}
          filters={groupBySignalFilters}
          errors={errors}
        />
        <ConfoundersSelector
          id={CONFOUNDERS_SELECTOR_ELEMENT_ID}
          onChange={changeParametersValue}
          value={parameters}
          schemaKeysMapping={confoundersSelectorMap}
          entityContext={entityContext}
        />
      </ExtendedParameters>
    </div>
  );
  const renderSimpleMode = () => (
    <AnalysisSimpleForm
      parameters={parameters}
      errors={errors}
      changeParametersValue={changeParametersValue}
      onChangeEntityContext={onChangeEntityContext}
    >
      <AnalysisFormSentence>
        <AnalysisFormPart>
          {t(TransKeys.ANALYSIS_FORMS.ANALYSIS_80.SIMPLE.TREATMENT_PREFIX)}
        </AnalysisFormPart>
        <AnalysisFormPart>
          <TreatmentSelector
            value={parameters}
            onChange={changeParametersValue}
            schemaKeysMapping={treatmentsSelectorMap}
            errors={errors}
            since={treatmentSelectorSince}
            variant={AnalysisSelectorVariant.INLINE}
            entityContext={entityContext}
            signalFilters={TREATMENT_SIGNAL_FILTERS}
          />
        </AnalysisFormPart>
        <AnalysisFormPart>
          {t(TransKeys.ANALYSIS_FORMS.ANALYSIS_80.SIMPLE.DAYS_BEFORE_PREFIX)}
        </AnalysisFormPart>
        <DaysCountSelector
          value={parameters}
          onChange={changeParametersValue}
          className={classes.InlineParameter}
          schemaKeysMapping={treatmentsSelectorMap}
          errors={errors}
          variant={AnalysisSelectorVariant.INLINE}
          suffixHelper={t(TransKeys.TREATMENT_SELECTOR.DAYS_SELECTOR_SUFFIX_HELPER)}
        />
        <AnalysisFormPart>
          {t(TransKeys.ANALYSIS_FORMS.ANALYSIS_80.SIMPLE.GOAL_PREFIX)}
        </AnalysisFormPart>
        <AnalysisFormPart>
          <PopoverWrapper
            buttonRenderer={props => (
              <DropdownButton
                {...props}
                error={exists(errors[QUERY_GOAL_KEY])}
                placeholder={'goal'}
                label={goalLabel}
                capitalize={false}
              />
            )}
          >
            <DialogLayout className={classes.DialogLayout}>
              <GoalQueryStrategySelector
                {...goalQueryStrategySelectorSharedProps}
                variant={AnalysisSelectorVariant.INLINE}
              />
            </DialogLayout>
          </PopoverWrapper>
        </AnalysisFormPart>
      </AnalysisFormSentence>
    </AnalysisSimpleForm>
  );

  return formMode === AnalysisParametersFormMode.ADVANCED
    ? renderAdvancedMode()
    : renderSimpleMode();
};
