import yup from '../../config/yup.config';
import {Team} from '../models/team.model';
import {MemberDTO} from './members-settings.dto';

export type TeamDTO = Omit<Team, 'homepageId'> & {
  users: Pick<MemberDTO, 'id' | 'email' | 'fullName'>[];
};

export const teamValidator = yup.object().shape({
  name: yup.string().required(),
});
