import {AUTH_STORE_KEY} from '../auth/auth.store';
import {CORE_STORE_KEY} from './core.store';
import {setAmplitudeUserId, setAmplitudeUserProperties} from '../../config/amplitude.config';
import {getter} from '../store.utils';
import {initPendo} from '../../config/pendo.config';
import {getHubspotVisitorToken} from './core.actions';
import {setGrowthbookProperties} from '../../config/growthbook.config';
import {loadNotifications} from '../notifications/notifications.actions';
import {UserRole} from '../../objects/models/user.model';
import {setSentryUser} from '../../config/sentry.config';
import {setHotjarUser} from '../../config/hotjar.config';

export const afterAppLoadedEpicHandler = () => {
  const user = getter(AUTH_STORE_KEY, 'user');
  const product = getter(CORE_STORE_KEY, 'productData');

  setAmplitudeProperties(user, product);
  setGrowthbookProperties(user, product);
  initPendo(user, product);
  setSentryUser(user.id, user.isInternalUser, product.id);
  setHotjarUser(user.id, user.isInternalUser, product.id);

  return [getHubspotVisitorToken(), loadNotifications()];
};

function setAmplitudeProperties(user, product) {
  setAmplitudeUserId(user.id);
  setAmplitudeUserProperties({
    // user properties
    job_title: user.jobTitle,
    is_manager: user.isManager,
    is_internal_user: user.isInternalUser,
    is_admin: user.role === UserRole.ADMIN,
    is_demo_user: user.isDemoUser,

    // product properties
    product_id: product.id,
    product_name: product.name,

    // account properties
    account_id: product.accountId,
    account_name: product.accountName,
    account_status: product.accountStatus,
  });
}
