import {useMemo, useCallback} from 'react';
import {
  TableType,
  TableEntity,
  TableEntityBinding,
} from '../../../../../objects/models/table.model';
import {LiteralValueType, LabelWrapper, Select} from 'ui-components';
import {
  TableColumnQueryBuilder,
  createTableColumnInitialQuery,
} from '../table-column-query-builder/table-column-query-builder.component';
import {
  TableEventsValueQueryBuilder,
  createTableEventsValueInitialQuery,
} from '../table-events-value-query-builder/table-events-value-query-builder.component';
import {
  METADATA_KEY,
  PARAMETERS_METADATA_KEY,
} from '../../../../../constants/parameters-saved-keys';
import classes from './revenue-query-builder.module.scss';

const queryTypeOptions = {
  options: [
    {
      key: 'TableEventsValueQueryBuilder',
      value: 'TableEventsValueQueryBuilder',
      label: 'Event/Transaction Property',
    },
    {
      key: 'TableColumnQueryBuilder',
      value: 'TableColumnQueryBuilder',
      label: 'State Table Column',
    },
  ],
};

interface OwnProps {
  query: any;
  onChange: (value: any) => void;
  errors?: any;
  filters?: any;
  disabled?: boolean;
  entityContext?: TableEntity;
}

type AllProps = OwnProps;

export const createRevenueInitialQuery = (subBuilderType = undefined) => {
  let q = {};

  if (subBuilderType === 'TableEventsValueQueryBuilder') {
    q = createTableEventsValueInitialQuery();
  } else if (subBuilderType === 'TableColumnQueryBuilder') {
    q = createTableColumnInitialQuery();
  }

  q[PARAMETERS_METADATA_KEY] = {
    [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: 'RevenueQueryBuilder',
    [METADATA_KEY.SUB_BUILDER_COMPONENT_NAME_KEY]: subBuilderType,
  };

  return q;
};

const COLUMN_FILTERS = {
  literalType: [LiteralValueType.INTEGER, LiteralValueType.FLOAT],
};

export const RevenueQueryBuilder = (props: AllProps) => {
  const {errors, onChange: onChangeFromProps, query, disabled, entityContext} = props;

  const subBuilder = useMemo(
    () => query[PARAMETERS_METADATA_KEY][METADATA_KEY.SUB_BUILDER_COMPONENT_NAME_KEY],
    [query]
  );

  const eventTablesFilters = useMemo(
    () => ({
      entityBinding: TableEntityBinding.TWO_WAY,
      entityContext,
    }),
    [entityContext]
  );

  const stateTablesFilters = useMemo(
    () => ({
      type: TableType.STATE,
      entityBinding: TableEntityBinding.TWO_WAY,
      entityContext,
    }),
    [entityContext]
  );

  const onChange = useCallback(
    newQuery => {
      newQuery[PARAMETERS_METADATA_KEY] = {
        ...(newQuery[PARAMETERS_METADATA_KEY] || {}),
        [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: 'RevenueQueryBuilder',
        [METADATA_KEY.SUB_BUILDER_COMPONENT_NAME_KEY]: subBuilder,
      };
      onChangeFromProps(newQuery);
    },
    [onChangeFromProps, subBuilder]
  );

  const setSubBuilder = useCallback(
    value => {
      const newQuery = createRevenueInitialQuery(value);
      onChangeFromProps(newQuery);
    },
    [onChangeFromProps]
  );

  return (
    <div className={classes.Container}>
      <LabelWrapper label={`State where the revenue value is stored`}>
        <Select
          placeholder={'Select'}
          value={subBuilder}
          onChange={setSubBuilder}
          searchable={false}
          clearable={false}
          options={queryTypeOptions}
          disabled={disabled}
        />
      </LabelWrapper>
      {subBuilder === 'TableEventsValueQueryBuilder' && (
        <LabelWrapper label={`Select the relevant events`}>
          <TableEventsValueQueryBuilder
            query={query}
            filters={eventTablesFilters}
            columnFilters={COLUMN_FILTERS}
            thenText={'And take the assignment property from'}
            multiEvents={false}
            multiSelection
            onChange={onChange}
            errors={errors}
            disabled={disabled}
          />
        </LabelWrapper>
      )}
      {subBuilder === 'TableColumnQueryBuilder' && (
        <LabelWrapper label={`Select the relevant table and column`}>
          <TableColumnQueryBuilder
            query={query}
            tableFilters={stateTablesFilters}
            columnFilters={COLUMN_FILTERS}
            onChange={onChange}
            errors={errors}
            disabled={disabled}
          />
        </LabelWrapper>
      )}
    </div>
  );
};
