import classNames from 'classnames';
import classes from './workflow-output-option.module.scss';
import {ChevronDownRegularIcon, ToggleSwitch} from 'ui-components';
import {useEffect, useRef, useState} from 'react';

interface OwnProps {
  title: string | JSX.Element;
  isActive: boolean;
  onChange: (isActive: boolean) => void;
  children: any;
  disabled?: boolean;
  error?: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const WorkflowOutputOption = (props: AllProps) => {
  const {title, isActive, onChange, disabled, error, children, className} = props;
  const [collapse, setCollapse] = useState(false);
  const prevIsActive = useRef<boolean>();

  useEffect(() => {
    if (collapse && prevIsActive.current === false && isActive === true) {
      setCollapse(false);
    }
  }, [prevIsActive, collapse, isActive, setCollapse]);
  useEffect(() => {
    prevIsActive.current = isActive;
  }, [prevIsActive, isActive]);

  return (
    <div
      className={classNames(
        classes.WorkflowOutputOption,
        collapse && classes.Collapsed,
        !isActive && classes.Collapsed,
        disabled && classes.Disabled,
        !disabled && classes.NotActive,
        error && classes.Error,
        className
      )}
    >
      <div
        className={classes.Header}
        onClick={disabled ? undefined : () => setCollapse(prev => !prev)}
      >
        <div className={classes.Toggle}>
          <ToggleSwitch
            value={isActive}
            onChange={disabled ? undefined : () => onChange(!isActive)}
            disabled={disabled}
          />
        </div>
        <div className={classes.Title}>{title}</div>
        <ChevronDownRegularIcon
          onClick={() => setCollapse(prev => !prev)}
          className={classes.Chevron}
        />
      </div>
      <div className={classes.Content}>{isActive && children}</div>
    </div>
  );
};
