import classNames from 'classnames';
import classes from './note-text.module.scss';
import {Link} from 'ui-components';

interface OwnProps {
  className?: string;
  text: string;
  buttonText?: string;
  onButtonClicked?: () => void;
}

type AllProps = OwnProps;

export const NoteText = (props: AllProps) => {
  const {text, buttonText, onButtonClicked, className} = props;

  return (
    <div className={classNames(classes.NoteText, className)}>
      <span className={classes.Text}>{text}</span>
      {buttonText && onButtonClicked && (
        <Link underline={false} className={classes.Link} onClick={onButtonClicked}>
          {buttonText}
        </Link>
      )}
    </div>
  );
};
