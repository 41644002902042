import classNames from 'classnames';
import classes from './layout.module.scss';
import {ReactNode} from 'react';

interface OwnProps {
  className?: string;
  children?: ReactNode;
}

type AllProps = OwnProps;

export const Tabs = (props: AllProps) => {
  const {children, className} = props;

  return <div className={classNames(classes.Tabs, className)}>{children}</div>;
};
