import classNames from 'classnames';
import classes from './signal-dependencies.module.scss';
import {SignalDependency} from '../../../../objects/models/signal-dependency.model';
import {LinkLightIcon, PopoverWrapper, TooltipIfOverflow} from 'ui-components';
import {useCallback, useContext, useMemo} from 'react';
import _ from 'lodash';
import {ModelKey} from '../../../../constants/model-key';
import {withStopPropagation} from 'front-core';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {PanelKey} from '../../../../constants/panels';
import {
  SEGMENT_ID_PATH_PARAM,
  FEATURE_ID_PATH_PARAM,
  MILESTONE_ID_PATH_PARAM,
} from '../../../../constants/app-routes';

export const ALLOWED_MODEL_TYPES = [ModelKey.SEGMENT, ModelKey.FEATURE, ModelKey.MILESTONE];

interface OwnProps {
  dependencies: SignalDependency[];
  className?: string;
}

type AllProps = OwnProps;

export const SignalDependencies = (props: AllProps) => {
  const {dependencies: dependencies_, className} = props;
  const {openSecondaryPanel} = useContext(PanelsContext);

  // Group dependencies by model type and id (to avoid duplicate usages in Feature)
  const dependencies = useMemo(
    () =>
      _.chain(dependencies_)
        .filter(dependency =>
          ALLOWED_MODEL_TYPES.includes(ModelKey[dependency.model.type.toUpperCase()])
        )
        .groupBy(dependency => `${dependency.model.type}-${dependency.model.id}`)
        .mapValues(dependencies => dependencies[0])
        .values()
        .value(),
    [dependencies_]
  );

  const onClick = useCallback(
    (dependency: SignalDependency) => {
      let panelKey = null;
      let params = {};

      switch (dependency.model.type.toUpperCase()) {
        case ModelKey.SEGMENT:
          panelKey = PanelKey.VIEW_SEGMENT_PANEL;
          params = {[SEGMENT_ID_PATH_PARAM]: dependency.model.id};
          break;
        case ModelKey.FEATURE:
          panelKey = PanelKey.VIEW_FEATURE_PANEL;
          params = {[FEATURE_ID_PATH_PARAM]: dependency.model.id};
          break;
        case ModelKey.MILESTONE:
          panelKey = PanelKey.VIEW_MILESTONE_PANEL;
          params = {[MILESTONE_ID_PATH_PARAM]: dependency.model.id};
          break;
        default:
          break;
      }

      openSecondaryPanel(panelKey, params);
    },
    [openSecondaryPanel]
  );

  return (
    <div
      className={classNames(
        classes.Container,
        !dependencies.length && classes.NoDependencies,
        className
      )}
    >
      <PopoverWrapper
        buttonRenderer={props => (
          <div className={classes.Component} onClick={props.onClick}>
            <LinkLightIcon className={classes.Icon} />
            <span>{dependencies.length}</span>
          </div>
        )}
      >
        <div className={classes.DependencyList}>
          {dependencies.map(dependency => (
            <div
              key={dependency.id}
              className={classNames(classes.Dependency, className)}
              onClick={withStopPropagation(() => onClick(dependency))}
            >
              <div className={classes.Type}>{dependency.model.type}</div>
              <TooltipIfOverflow title={dependency.model.name}>
                <div className={classes.Name}>{dependency.model.name}</div>
              </TooltipIfOverflow>
            </div>
          ))}
        </div>
      </PopoverWrapper>
    </div>
  );
};
