import React, {useCallback, useMemo} from 'react';
import {range} from 'lodash';
import {Select} from 'ui-components';
import {useCronExpBuilder} from '../../../../cron-exp-builder-context-provider/cron-exp-builder-context-provider.component';
import {
  CronUnits,
  CronUnitsPositions,
} from '../../../../../../../../../../../constants/cron-exp.consts';
import classNames from 'classnames';
import sharedClasses from './shared-cron-exp.module.scss';
import {ordinalSuffix} from '../../../../../../../../../../../utils/general.utils';

interface OwnProps {
  disabled: boolean;
}

type AllProps = OwnProps;

const CronExpSelectMonthDay = (props: AllProps) => {
  const {disabled} = props;
  const {setUnit, cronExpValueAsArray} = useCronExpBuilder();
  const monthDayOptions = useMemo(
    () => ({
      options: range(1, 29).map(i => ({
        label: ordinalSuffix(i),
        value: String(i),
      })),
    }),
    []
  );
  const onDayOfTheMonthChange = useCallback(
    value => {
      setUnit(CronUnits.DAY_OF_THE_MONTH, value);
    },
    [setUnit]
  );

  return (
    <Select
      className={classNames(disabled && sharedClasses.DisabledSelect)}
      clearable={false}
      disabled={disabled}
      onChange={onDayOfTheMonthChange}
      sortValues={false}
      value={cronExpValueAsArray[CronUnitsPositions[CronUnits.DAY_OF_THE_MONTH]]}
      options={monthDayOptions}
    />
  );
};

export default CronExpSelectMonthDay;
