import {useCallback, useContext, useState} from 'react';
import {Workflow} from '../../../../../../objects/models/workflow.model';
import {WorkflowListItem} from './workflow-list-item/workflow-list-item.component';
import {PanelsContext} from '../../../../../../core/contexts/panels.context';
import {PanelKey} from '../../../../../../constants/panels';
import {AppRoutes, METRIC_ID_PATH_PARAM} from '../../../../../../constants/app-routes';
import {GenericLoading} from '../../../../../shared/components/general/generic-loading/generic-loading.component';
import {EmptyState} from '../../../../../shared/components/general/override';
import TransKeys from '../../../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import classes from './workflows-list.module.scss';
import {sharedClasses} from '../../../../../shared';
import {ViewSQLModal} from '../../../../../shared/components/general/view-sql-modal/view-sql-modal.component';
import {BetterNavLink} from '../../../../../shared/core/override/better-nav-link.component.tsx';

interface OwnProps {
  workflows: Workflow[];
  isLoading?: boolean;
}

type AllProps = OwnProps;

export const WorkflowsList = (props: AllProps) => {
  const {workflows, isLoading} = props;
  const {openSecondaryPanel} = useContext(PanelsContext);
  const {t} = useTranslation();
  const [viewedSQL, setViewedSQL] = useState<string>('');

  const onMetricClick = useCallback(
    (metricId: number) =>
      openSecondaryPanel(PanelKey.VIEW_METRIC_PANEL, {
        [METRIC_ID_PATH_PARAM]: metricId,
      }),
    [openSecondaryPanel]
  );
  const onViewSQL = useCallback((sql: string) => setViewedSQL(sql), []);

  return (
    <>
      {isLoading && <GenericLoading />}
      {workflows.length > 0 &&
        workflows.map(workflow => (
          <BetterNavLink
            key={workflow.id}
            className={sharedClasses.UnstyledLink}
            to={AppRoutes.viewWorkflow(workflow.id)}
          >
            <WorkflowListItem
              workflow={workflow}
              onMetricClick={onMetricClick}
              onViewSQL={onViewSQL}
            />
          </BetterNavLink>
        ))}
      {!isLoading && workflows.length === 0 && (
        <div className={classes.EmptyStateWrapper}>
          <EmptyState
            title={t(TransKeys.WORKFLOWS.EMPTY_STATE.TITLE)}
            subTitle={t(TransKeys.WORKFLOWS.EMPTY_STATE.SUB_TITLE)}
          />
        </div>
      )}
      <ViewSQLModal sql={viewedSQL} isOpen={!!viewedSQL} onClose={() => setViewedSQL('')} />
    </>
  );
};
