import {useCallback, useMemo} from 'react';
import classes from './confounders-selector.module.scss';
import {ParameterInputWrapper} from '../../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {isEmpty, values} from 'lodash';
import {exists} from 'front-core';
import TransKeys from '../../../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {hasErrors} from '../../../../../../utils/general.utils';
import {ConfoundersSmartSelector} from '../../../../../shared/core/smart-selector/confounders-smart-selector.component';
import {AnalysisSelectorProps, AnalysisSelectorVariant} from '../analysis-selector.types';
import {HoverHelperTip, HelperTipType} from 'ui-components';
import {TableEntity} from '../../../../../../objects/models/table.model';

interface SchemaKeysMapping {
  confounders_tag: string;
  confounders_signals: string;
}

const DEFAULT_SCHEMA_KEYS_MAPPING: SchemaKeysMapping = {
  confounders_tag: 'confounders_tag',
  confounders_signals: 'confounders_signals',
};

interface OwnProps extends AnalysisSelectorProps {
  id?: string;
  schemaKeysMapping?: SchemaKeysMapping;
  placeholder?: string;
  entityContext: TableEntity;
  signalFilters?: any;
}

type AllProps = OwnProps;

export const ConfoundersSelector = (props: AllProps) => {
  const {
    id,
    title,
    subTitle,
    helperText,
    errors,
    onChange: onChange_,
    schemaKeysMapping = DEFAULT_SCHEMA_KEYS_MAPPING,
    value: data,
    variant,
    entityContext,
    signalFilters,
    className,
  } = props;
  const {t} = useTranslation();
  const hasError = useMemo(
    () => hasErrors(errors, values(schemaKeysMapping)),
    [errors, schemaKeysMapping]
  );
  const confoundersError = useMemo(
    () =>
      hasErrors(errors, [schemaKeysMapping.confounders_signals, schemaKeysMapping.confounders_tag]),
    [errors, schemaKeysMapping]
  );
  const value = useMemo(() => {
    const confounderssTags =
      data[schemaKeysMapping.confounders_tag]?.split(',').filter(o => !!o) || [];
    return [...confounderssTags, ...(data[schemaKeysMapping.confounders_signals] || [])];
  }, [data, schemaKeysMapping]);
  const onChange = useCallback(
    v => {
      const confounderssTag =
        exists(v) && !isEmpty(v) ? v.filter(t => typeof t === 'string').join(',') : undefined;
      const confounderssSignals =
        exists(v) && !isEmpty(v) ? v.filter(t => typeof t === 'number') : undefined;
      onChange_({
        [schemaKeysMapping.confounders_tag]: confounderssTag,
        [schemaKeysMapping.confounders_signals]: confounderssSignals,
      });
    },
    [onChange_, schemaKeysMapping]
  );

  if (!entityContext) {
    return null;
  }

  return (
    <ParameterInputWrapper
      title={
        title === undefined ? t(TransKeys.ANALYSIS_FORMS.SHARED.CONFOUNDERS_SELECTOR.TITLE) : title
      }
      subTitle={
        subTitle === undefined
          ? t(TransKeys.ANALYSIS_FORMS.SHARED.CONFOUNDERS_SELECTOR.SUB_TITLE)
          : subTitle
      }
      className={className}
      helperText={
        helperText === undefined
          ? t(TransKeys.ANALYSIS_FORMS.SHARED.CONFOUNDERS_SELECTOR.HELPER_TEXT)
          : helperText
      }
      error={hasError}
    >
      <div className={classes.ConfounderSelector} id={id}>
        <ConfoundersSmartSelector
          value={value}
          onChange={onChange}
          placeholder={t(TransKeys.CONFOUNDERS_SELECTOR.SELECT_PLACEHOLDER)}
          selectedText={t(TransKeys.CONFOUNDERS_SELECTOR.SELECT_PLACEHOLDER_INLINE)}
          className={variant === AnalysisSelectorVariant.INLINE ? className : undefined}
          error={confoundersError}
          entityContext={entityContext}
          signalFilters={signalFilters}
        />
        {value && value.length > 0 && (
          <HoverHelperTip
            title={t(TransKeys.CONFOUNDERS_SELECTOR.WARN_MESSAGE)}
            type={HelperTipType.WARN}
            className={classes.WarnTip}
          />
        )}
      </div>
    </ParameterInputWrapper>
  );
};
