import {memo, useContext} from 'react';
import type {NodeProps} from '@xyflow/react/dist/esm/types';
import classes from './kpi-group.module.scss';
import {Position} from '@xyflow/react';
import {TreeMode} from '../../kpi-tree.consts';
import {NetworkWiredRegularIcon} from 'ui-components';
import {KPITreeContext} from '../../kpi-tree.context';
import classNames from 'classnames';
import {TreeHandle} from '../tree-handle/tree-handle.component';

interface OwnProps extends NodeProps {
  data: {
    count: number;
    rootMetricId: number;
  };
}

type AllProps = OwnProps;

export const KPIGroup = memo((props: AllProps) => {
  const {data} = props;
  const {count, rootMetricId} = data;
  const {onViewKPIGroup, mode} = useContext(KPITreeContext);

  return (
    <div
      className={classNames(classes.KPIGroup, mode === TreeMode.EDIT && classes.Disabled)}
      onClick={() => onViewKPIGroup(rootMetricId)}
    >
      <TreeHandle type="source" position={Position.Top} isConnectable={false} />
      <div className={classes.KPIGroupCount}>
        <NetworkWiredRegularIcon />
        {count} {count === 1 ? 'KPI' : 'KPIs'}
      </div>
    </div>
  );
});
