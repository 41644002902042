import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import classes from '../../settings.module.scss';
import usePermissions from '../../../../core/hooks/use-permissions.hook';
import {PermissionLabel} from '../../../shared/core/permissions/permission-label.component';
import {UserPermissionsTable} from './components/user-permissions-table/user-permissions-table.component';

const helpArticleUrl = 'https://help.getloops.ai/article/41-roles-and-permissions';
const mailToSupport = 'mailto:support@getloops.ai';

export const UserPermissions = () => {
  const {t} = useTranslation();
  const {role} = usePermissions();

  return (
    <section className={classes.Page}>
      <header className={classes.Header}>{t(TransKeys.USER_SETTINGS.MENU.PERMISSIONS)}</header>

      <div className={classes.Section}>
        <div>
          <div className={classes.Header}>
            {t(TransKeys.USER_SETTINGS.PERMISSIONS.YOUR_ROLE_IS)}&nbsp; "
            <PermissionLabel role={role} />"
          </div>
          <div className={classes.Description}>
            {t(TransKeys.USER_SETTINGS.PERMISSIONS.YOUR_PERMISSIONS)}
            <UserPermissionsTable />
            <ul className={classes.LearnMoreList}>
              <li>
                {t(TransKeys.USER_SETTINGS.PERMISSIONS.LEARN_MORE)}&nbsp;
                <a href={helpArticleUrl}>{t(TransKeys.GENERAL.LABELS.CLICK_HERE)}</a>
              </li>
              <li>
                {t(TransKeys.USER_SETTINGS.PERMISSIONS.REQUEST_DIFFERENT_PERMISSIONS)}
                &nbsp;
                <a href={mailToSupport}>{t(TransKeys.SUPPORT.CONTACT_OUR_SUPPORT)}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
