import {startCase} from 'lodash';
import {Action, Role, Subject} from '../../../../../constants/permissions';
import usePermissions from '../../../../../core/hooks/use-permissions.hook';
import {useCurrentUser} from '../../../../../core/hooks/use-user.hook';
import {MemberDTO} from '../../../../../objects/dto/members-settings.dto';
import {Select} from 'ui-components';

type MemberPermissionCellProps = {
  member: Pick<MemberDTO, 'id' | 'permissionRole'>;
  onChangePermissionRole: (memberId: number, permissionRole: Role) => void;
};

const roleOptions = [
  {value: Role.OWNER, label: startCase(Role.OWNER)},
  {value: Role.MANAGER, label: startCase(Role.MANAGER)},
  {value: Role.CONTRIBUTOR, label: startCase(Role.CONTRIBUTOR)},
  {value: Role.VIEWER, label: startCase(Role.VIEWER)},
];

export const MemberPermissionCell = (props: MemberPermissionCellProps) => {
  const {member, onChangePermissionRole} = props;
  const {can} = usePermissions();

  const currentUser = useCurrentUser();
  const canEditMember = can(Subject.USER, Action.EDIT);

  return canEditMember && member.id !== currentUser.id ? (
    <Select
      options={{options: roleOptions}}
      value={member.permissionRole}
      onChange={value => onChangePermissionRole(member.id, value as Role)}
      sortValues={false}
      searchable={false}
      capitalize={false}
      clearable={false}
    />
  ) : (
    <>{startCase(member.permissionRole)}</>
  );
};
