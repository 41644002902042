import {Fragment, useCallback, useMemo} from 'react';
import classes from './population-segment-query-builder.module.scss';
import {
  ConditionOperator,
  LiteralValueType,
  PlusLightIcon,
  QueryBuilderFactory,
  SqlElementType,
  TrashCanLightIcon,
} from 'ui-components';
import {
  PARAMETERS_METADATA_KEY,
  METADATA_KEY,
} from '../../../../../constants/parameters-saved-keys';
import {cloneDeep, isEmpty} from 'lodash';
import {SegmentCondition} from './segment-condition.component';
import {Button} from '@material-ui/core';
import classNames from 'classnames';
import {TableEntity} from '../../../../../objects/models/table.model';

interface OwnProps {
  query: any;
  entityContext?: TableEntity;
  onChange?: (parameters: any) => void;
  errors?: any;
  disabled?: boolean;
  className?: string;
}

export const BUILDER_COMPONENT_NAME = 'PopulationSegmentQueryBuilder';

export const createSegmentCondition = () => ({
  ...QueryBuilderFactory.createCondition(),
  op: ConditionOperator.IN,
  left: QueryBuilderFactory.createSignalColumn(),
  right: {
    ...QueryBuilderFactory.create(SqlElementType.LITERAL),
    value_type: LiteralValueType.STRING_LIST,
    value: [],
  },
});

export const createPopulationSegmentInitialQuery = () => ({
  ...QueryBuilderFactory.createOrCondition(),
  conditions: [
    {
      ...QueryBuilderFactory.createAndCondition(),
      conditions: [createSegmentCondition()],
    },
  ],
});

const isEmptyQuery = (query: any) =>
  isEmpty(query) ||
  query.conditions.length === 0 ||
  (query.conditions.length === 1 &&
    query.conditions[0].conditions.length === 1 &&
    query.conditions[0].conditions[0].left.signal_id === null &&
    query.conditions[0].conditions[0].op === ConditionOperator.IN &&
    query.conditions[0].conditions[0].right.value.length === 0);

const PlusButton = ({label, onClick, disabled, hidden}) => {
  if (hidden) {
    return null;
  }

  return (
    <div>
      <Button className={classes.AddCondition} onClick={onClick} disabled={disabled}>
        <div className={classNames(classes.AddConditionLabel, disabled && classes.Disabled)}>
          <PlusLightIcon />
          {label}
        </div>
      </Button>
    </div>
  );
};
type AllProps = OwnProps;

export const PopulationSegmentQueryBuilder = (props: AllProps) => {
  const {
    query: queryFromProps,
    onChange: onChangeFromProps,
    entityContext,
    errors,
    disabled,
  } = props;

  const query = useMemo(
    () => (queryFromProps ? queryFromProps : createPopulationSegmentInitialQuery()),
    [queryFromProps]
  );

  const onChange = useCallback(
    newQuery => {
      if (isEmptyQuery(newQuery)) {
        onChangeFromProps(null);
        return;
      }
      newQuery[PARAMETERS_METADATA_KEY] = {
        ...(newQuery[PARAMETERS_METADATA_KEY] || {}),
        [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: BUILDER_COMPONENT_NAME,
      };
      onChangeFromProps(newQuery);
    },
    [onChangeFromProps]
  );

  const onSegmentChange = useCallback(
    (populationIndex: number, segmentIndex: number, segment: any) => {
      const newQuery = cloneDeep(query);
      newQuery.conditions[populationIndex].conditions[segmentIndex] = segment;
      onChange(newQuery);
    },
    [query, onChange]
  );

  const onAddPopulation = useCallback(() => {
    const newQuery = cloneDeep(query);
    const newPopulation = QueryBuilderFactory.createAndCondition();
    newPopulation.conditions = [createSegmentCondition()];
    newQuery.conditions.push(newPopulation);
    onChange(newQuery);
  }, [query, onChange]);

  const onAddSegment = useCallback(
    (populationIndex: number) => {
      const newQuery = cloneDeep(query);
      newQuery.conditions[populationIndex].conditions.push(createSegmentCondition());
      onChange(newQuery);
    },
    [query, onChange]
  );

  const onRemovePopulation = useCallback(
    (populationIndex: number) => {
      const newQuery = cloneDeep(query);
      newQuery.conditions.splice(populationIndex, 1);
      onChange(newQuery);
    },
    [query, onChange]
  );

  const onRemoveSegment = useCallback(
    (populationIndex: number, segmentIndex: number) => {
      if (query.conditions[populationIndex].conditions.length === 1) {
        onRemovePopulation(populationIndex);
      } else {
        const newQuery = cloneDeep(query);
        newQuery.conditions[populationIndex].conditions.splice(segmentIndex, 1);
        onChange(newQuery);
      }
    },
    [query, onChange, onRemovePopulation]
  );

  const canAddSegment = useCallback(
    (populationIndex: number) => {
      const populationSegments = query.conditions[populationIndex].conditions;
      const lastSegment = populationSegments[populationSegments.length - 1];
      return lastSegment.left.signal_id !== null;
    },
    [query]
  );

  const canAddPopulation = useMemo(() => {
    return (
      query.conditions[query.conditions.length - 1]?.conditions?.length > 1 ||
      canAddSegment(query.conditions.length - 1)
    );
  }, [query, canAddSegment]);

  return (
    <div className={classes.Container}>
      {query.conditions.map((population, populationIndex) => (
        <Fragment key={populationIndex}>
          <div className={classes.Population}>
            <div className={classes.VerticalLine} />
            {population.conditions.map((segment, segmentIndex) => (
              <Fragment key={segmentIndex}>
                <div className={classes.Segment}>
                  <SegmentCondition
                    data={segment}
                    entityContext={entityContext}
                    onChange={e => onSegmentChange(populationIndex, segmentIndex, e)}
                    localErrors={errors?.conditions?.[populationIndex]?.conditions?.[segmentIndex]}
                    disabled={disabled}
                  />
                  {!disabled && (
                    <TrashCanLightIcon
                      className={classNames(
                        classes.TrashIcon,
                        isEmptyQuery(query) && classes.Disabled
                      )}
                      onClick={() => onRemoveSegment(populationIndex, segmentIndex)}
                    />
                  )}
                </div>
                {segmentIndex < population.conditions.length - 1 && (
                  <div className={classes.ConditionContainer}>
                    <div className={classes.Condition}>and</div>
                  </div>
                )}
              </Fragment>
            ))}
            <PlusButton
              label={'and'}
              onClick={() => onAddSegment(populationIndex)}
              hidden={disabled}
              disabled={!canAddSegment(populationIndex)}
            />
          </div>
          {populationIndex < query.conditions.length - 1 && (
            <div className={classes.ConditionContainer}>
              <div className={classes.Condition}>or</div>
            </div>
          )}
        </Fragment>
      ))}
      <PlusButton
        label={'or'}
        onClick={onAddPopulation}
        hidden={disabled}
        disabled={!canAddPopulation}
      />
    </div>
  );
};
