import classNames from 'classnames';
import classes from './window-size-selector.module.scss';
import {useMemo} from 'react';
import {values, get} from 'lodash';
import {ParameterInputWrapper} from '../../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {LabelWrapper, Select, TextInput} from 'ui-components';
import {hasErrors, limitNumberValue} from '../../../../../../utils/general.utils';
import {TIME_UNIT_PLURAL_OPTIONS} from '../../../../../../constants/options';

interface SchemaKeysMapping {
  window_size_n: string;
  window_size_unit: string;
}

const DEFAULT_SCHEMA_KEYS_MAPPING: SchemaKeysMapping = {
  window_size_n: 'window_size_n',
  window_size_unit: 'window_size_unit',
};

interface OwnProps {
  title: string;
  onChange: (parameters: any) => void;
  value: any;
  schemaKeysMapping?: SchemaKeysMapping;
  subTitle?: string;
  bottomHelper?: any;
  helperText?: any;
  preText?: string;
  postText?: string;
  label?: string;
  errors?: any;
  className?: string;
}

type AllProps = OwnProps;

const MIN_COUNT_VALUE = 1;
const MAX_COUNT_VALUE = 50;

export const WindowSizeSelector = (props: AllProps) => {
  const {
    title,
    subTitle,
    helperText,
    bottomHelper,
    errors,
    onChange,
    schemaKeysMapping = DEFAULT_SCHEMA_KEYS_MAPPING,
    value,
    preText,
    postText,
    label = 'Time Window',
    className,
  } = props;
  const hasError = useMemo(
    () => hasErrors(errors, values(schemaKeysMapping)),
    [errors, schemaKeysMapping]
  );
  const errorMessage = useMemo(
    () => get(errors, schemaKeysMapping.window_size_unit)?.message,
    [errors, schemaKeysMapping]
  );

  return (
    <ParameterInputWrapper
      title={title}
      subTitle={subTitle}
      helperText={helperText}
      className={classNames(classes.WindowSizeSelector, className)}
      bottomHelper={bottomHelper}
      error={hasError}
    >
      <LabelWrapper label={label} helperText={errorMessage} error={Boolean(errorMessage)}>
        <div className={classes.Row}>
          {preText && <div className={classes.Item}>{preText}</div>}
          <div className={classes.Item}>
            <TextInput
              className={classes.Input}
              value={value?.[schemaKeysMapping.window_size_n]}
              onChange={value =>
                onChange({
                  [schemaKeysMapping.window_size_n]: limitNumberValue(
                    value as number,
                    MIN_COUNT_VALUE,
                    MAX_COUNT_VALUE
                  ),
                })
              }
              error={Boolean(errors?.[schemaKeysMapping.window_size_n]?.message)}
              type={'number'}
            />
          </div>
          <div className={classes.Item}>
            <Select
              value={value?.[schemaKeysMapping.window_size_unit]}
              onChange={value =>
                onChange({
                  [schemaKeysMapping.window_size_unit]: value,
                })
              }
              error={Boolean(errors?.[schemaKeysMapping.window_size_unit]?.message)}
              options={TIME_UNIT_PLURAL_OPTIONS}
              dropdownButtonClassName={classes.SelectUnit}
              searchable={false}
              clearable={false}
            />
          </div>
          {postText && <div className={classes.Item}>{postText}</div>}
        </div>
      </LabelWrapper>
    </ParameterInputWrapper>
  );
};
