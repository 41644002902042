import classes from '../analysis-types-follow-up-panel.module.scss';
import {CloseRegularIcon} from 'ui-components';
import classNames from 'classnames';

interface OwnProps {
  onClose: () => void;
  staticTitle: string;
  dynamicTitle?: JSX.Element;
  topIcon?: JSX.Element;
  className?: string;
}

type AllProps = OwnProps;

const AnalysisTypesFollowUpPanelHeader = (props: AllProps) => {
  const {onClose, staticTitle, dynamicTitle, topIcon, className} = props;

  const renderTop = () => {
    if (!topIcon) {
      return (
        <div className={classes.Close} onClick={onClose}>
          <CloseRegularIcon className={classes.Icon} />
        </div>
      );
    }
    return (
      <div className={classes.TopContainer}>
        {topIcon}
        <div className={classes.CloseIcon} onClick={onClose}>
          <CloseRegularIcon className={classes.Icon} />
        </div>
      </div>
    );
  };

  return (
    <div className={classNames(classes.Header, className)}>
      {renderTop()}
      <div className={classes.TitleWrapper}>
        <div className={classes.StaticTitle}>{staticTitle}</div>
        <div className={classes.DynamicTitle}>{dynamicTitle}</div>
      </div>
    </div>
  );
};

export default AnalysisTypesFollowUpPanelHeader;
