import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {EmptyState} from '../../../../../shared/components/general/override';
import {ExperimentListRowItem} from './experiment-list-row-item/experiment-list-row-item.component';
import classes from './experiments-list.module.scss';
import {sharedClasses} from '../../../../../shared';
import {Experiment} from '../../../../../../objects/models/experiment.model';
import {GenericLoading} from '../../../../../shared/components/general/generic-loading/generic-loading.component';
import {AppRoutes} from '../../../../../../constants/app-routes';
import {BetterNavLink} from '../../../../../shared/core/override/better-nav-link.component.tsx';

type ExperimentsListProps = {isLoading?: boolean; experiments: Experiment[]};

export const ExperimentsList = (props: ExperimentsListProps) => {
  const {isLoading, experiments} = props;

  const {t} = useTranslation();

  return (
    <>
      {isLoading && <GenericLoading />}
      {experiments.length > 0 &&
        experiments.map((experiment: Experiment) => (
          <BetterNavLink
            key={experiment.id}
            className={sharedClasses.UnstyledLink}
            to={AppRoutes.viewExperiment(experiment.id)}
          >
            <ExperimentListRowItem experiment={experiment} />
          </BetterNavLink>
        ))}
      {!isLoading && experiments.length === 0 && (
        <div className={classes.EmptyStateWrapper}>
          <EmptyState
            title={t(TransKeys.EXPERIMENTS.EMPTY_STATE.TITLE)}
            subTitle={t(TransKeys.EXPERIMENTS.EMPTY_STATE.SUB_TITLE)}
          />
        </div>
      )}
    </>
  );
};
