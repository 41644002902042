import {useCallback, useMemo} from 'react';
import {
  Button,
  FancyHeader,
  FlagIcon,
  HashtagIcon,
  LabelWrapper,
  ModalLayout,
  PercentageIcon,
  TextInput,
} from 'ui-components';
import {GOAL_ID_PATH_PARAM, METRIC_ID_PATH_PARAM} from '../../../../constants/app-routes';
import classes from './goal-form-panel.module.scss';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../constants/translation-keys';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {useDispatch, useSelector} from 'react-redux';
import {sharedClasses} from '../../../shared';
import {yupResolver} from '@hookform/resolvers/yup';
import {Goal} from '../../../../objects/models/goal.model';
import {goalDTOValidator} from '../../../../objects/dto/goal.dto';
import {createGoal, deleteGoalConfirmed, updateGoal} from '../../../../store/goals/goals.actions';
import {composition, exists} from 'front-core';
import {getReducedLoadingStateSelector} from '../../../../store/store.selectors';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {ActionKey} from '../../../../constants/action-key';
import {FormHiddenInputs} from '../../../shared/form/components/form-hidden-inputs.component';
import {preventSubmitOnEnter} from '../../../../utils/general.utils';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {getGoalNetworkRequest} from '../../../../http/goals.network-requests';
import {getMetricNetworkRequest} from '../../../../http/metrics.network-requests';
import {Metric, MetricValueType} from '../../../../objects/models/metric.model';
import {withModalInactiveSourceHandler} from '../../../../core/hoc/with-modal-inactive-source-handler.hoc';
import {withDisableDemoProduct} from '../../../../core/hoc/with-disable-demo-product.hoc';

interface OwnProps {
  goal?: Goal;
  metric: Metric;
  data: Partial<Goal>;
  onClose?: () => void;
  [GOAL_ID_PATH_PARAM]?: number;
  [METRIC_ID_PATH_PARAM]?: number;
  acceptedMetricIds?: number[];
  panelId?: string;
  disabled?: boolean;
}

type AllProps = OwnProps;

const createEmptyGoal = (): Partial<Goal> => ({
  metricId: null,
  value: null,
});

const SELECTED_GOAL_KEY = SharedSelectionKeys.GOAL_FORM__GOAL;
const SELECTED_METRIC_KEY = SharedSelectionKeys.GOAL_FORM__METRIC;

const GoalFormPanelComponent = (props: AllProps) => {
  const {
    data = createEmptyGoal(),
    goal = {},
    metric,
    onClose,
    [METRIC_ID_PATH_PARAM]: metricId,
    disabled,
  } = props;
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const formMethods = useForm({
    defaultValues: {
      ...data,
      metricId,
      ...goal,
    } as any,
    resolver: yupResolver(goalDTOValidator, {abortEarly: false}),
  });
  const {
    handleSubmit,
    watch,
    formState: {errors},
  } = formMethods;
  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(ActionKey.CREATE_GOAL, ActionKey.UPDATE_GOAL)(state)
  );
  const goalId = watch('id');
  const editMode = Boolean(goalId);
  const onDelete = useCallback(() => {
    const onSuccess = () => {
      onClose();
    };
    dispatch(deleteGoalConfirmed(goalId, onSuccess));
  }, [dispatch, onClose, goalId]);
  const onSubmit = useCallback(
    data => {
      const onSuccess = () => {
        onClose();
      };
      dispatch(editMode ? updateGoal(data, onSuccess) : createGoal(data, onSuccess));
    },
    [dispatch, onClose, editMode]
  );
  const containerStyle = useMemo(() => ({minHeight: '28rem', width: '54rem'}), []);

  return (
    <div style={containerStyle}>
      <ModalLayout
        footer={
          <div className={classes.Actions}>
            {editMode && (
              <Button onClick={onDelete} className={classes.Delete}>
                {t(TransKeys.GENERAL.ACTIONS.DELETE)}
              </Button>
            )}
            <Button disabled={disabled} onClick={handleSubmit(onSubmit)}>
              {t(TransKeys.GENERAL.ACTIONS.SAVE)}
            </Button>
          </div>
        }
      >
        {isLoading && <GenericLoading />}
        <div className={classes.CreateGoal}>
          <FancyHeader
            title={t(editMode ? TransKeys.GOAL_FORM.TITLE_EDIT : TransKeys.GOAL_FORM.TITLE_CREATE)}
            subTitle={t(TransKeys.GOAL_FORM.MAIN_TITLE)}
            icon={FlagIcon}
            onClose={onClose}
            className={classes.Header}
          />
          <FormProvider {...formMethods}>
            <form
              className={sharedClasses.Form}
              onKeyDown={preventSubmitOnEnter}
              onSubmit={handleSubmit(onSubmit)}
            >
              <FormHiddenInputs names={['metricId', 'id']} />
              <div className={sharedClasses.FormContent}>
                <div className={sharedClasses.Block}>
                  <div className={sharedClasses.Input}>
                    <LabelWrapper
                      label={''}
                      error={Boolean(errors.value)}
                      helperText={errors.value?.message}
                      required
                    >
                      <Controller
                        render={({field}) => (
                          <TextInput
                            value={
                              metric.valueType === MetricValueType.PERCENTAGE && exists(field.value)
                                ? Number((field.value * 100).toFixed(2))
                                : field.value
                            }
                            // @ts-ignore
                            onChange={(v: number) =>
                              field.onChange(
                                metric.valueType === MetricValueType.PERCENTAGE
                                  ? exists(v)
                                    ? Number((v / 100).toFixed(2))
                                    : null
                                  : v
                              )
                            }
                            placeholder={t(TransKeys.GOAL_FORM.INPUTS.VALUE.PLACEHOLDER)}
                            type={'number'}
                            leftIcon={
                              metric.valueType === MetricValueType.PERCENTAGE
                                ? PercentageIcon
                                : HashtagIcon
                            }
                          />
                        )}
                        name={'value'}
                      />
                    </LabelWrapper>
                    {/*<TextFormInput*/}
                    {/*  required*/}
                    {/*  label={t(TransKeys.GOAL_FORM.INPUTS.VALUE.LABEL)}*/}
                    {/*  type={'number'}*/}
                    {/*  placeholder={t(TransKeys.GOAL_FORM.INPUTS.VALUE.PLACEHOLDER)}*/}
                    {/*  name={'value'}*/}
                    {/*  leftIcon={*/}
                    {/*    metric.valueType === MetricValueType.PERCENTAGE*/}
                    {/*      ? PercentageIcon*/}
                    {/*      : HashtagIcon*/}
                    {/*  }*/}
                    {/*/>*/}
                  </div>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </ModalLayout>
    </div>
  );
};

const GoalFormPanel = composition<AllProps>(
  GoalFormPanelComponent,
  withModalInactiveSourceHandler,
  withDisableDemoProduct,
  withLoadBefore({
    goal: {
      selectedKey: SELECTED_GOAL_KEY,
      actionKey: SELECTED_GOAL_KEY,
      request: getGoalNetworkRequest,
      mapPayloadFromProps: props => props[GOAL_ID_PATH_PARAM],
      shouldCall: props => exists(props[GOAL_ID_PATH_PARAM]),
    },
    metric: {
      selectedKey: SELECTED_METRIC_KEY,
      actionKey: SELECTED_METRIC_KEY,
      request: getMetricNetworkRequest,
      mapPayloadFromProps: props => props[METRIC_ID_PATH_PARAM],
      shouldCall: props => props[METRIC_ID_PATH_PARAM] !== undefined,
    },
  })
);

export default GoalFormPanel;
