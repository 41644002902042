import {pick} from 'lodash';
import {SourceType} from '../../../../objects/models/source.model';
import {AmazonAthenaForm} from './components/forms/amazon-athena-form/amazon-athena-form.component';
import {AmazonRedshiftForm} from './components/forms/amazon-redshift-form/amazon-redshift-form.component';
import {BigQueryForm} from './components/forms/big-query-form/big-query-form.component';
import {DatabricksForm} from './components/forms/databricks-form/databricks-form.component';
import {GenericConnectionDetailsForm} from './components/forms/generic-connection-details-form/generic-connection-details-form.component';
import {SnowflakeForm} from './components/forms/snowflake-form/snowflake-form.component';

const DataSourceFormsMap = {
  [SourceType.ATHENA]: AmazonAthenaForm,
  [SourceType.REDSHIFT]: AmazonRedshiftForm,
  [SourceType.DATABRICKS]: DatabricksForm,
  [SourceType.SNOWFLAKE]: SnowflakeForm,
  [SourceType.BIGQUERY]: BigQueryForm,
  [SourceType.VERTICA]: GenericConnectionDetailsForm,
  [SourceType.SINGLESTORE]: GenericConnectionDetailsForm,
  [SourceType.CLICKHOUSE]: GenericConnectionDetailsForm,
};

const UNSUPPORTED_SOURCES = pick(SourceType, [
  SourceType.VERTICA,
  SourceType.SINGLESTORE,
  SourceType.CLICKHOUSE,
]);

export {UNSUPPORTED_SOURCES, DataSourceFormsMap};
