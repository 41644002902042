import {useMemo} from 'react';
import classNames from 'classnames';
import classes from './base-bounded-revenue-query-builder.module.scss';
import {LiteralValueType, Select, TextInput} from 'ui-components';
import {SignalSmartSelectorKey} from '../../smart-selector/signal-smart-selector.component';
import {SignalDataType, SignalType} from '../../../../../objects/models/signal.model';
import {limitNumberValue} from '../../../../../utils/general.utils';
import {TemplateItemQueryBuilder} from '../template-item-query-builder/template-item-query-builder.component';
import {SelectorModelType} from '../../smart-selector/advanced-smart-selector.component';
import {TIME_UNIT_PLURAL_OPTIONS} from '../../../../../constants/options';
import {
  TableEntity,
  TableEntityBinding,
  TableType,
} from '../../../../../objects/models/table.model';

interface SchemaKeysMapping {
  goal: string;
  ref_date: string;
  goal_bound_unit: string;
  goal_bound_n_start: string;
  goal_bound_n_end: string;
  dimension_aggregation: string;
  force_ref_date_to_date_over_timestamp: string;
}

const DEFAULT_SCHEMA_KEYS_MAPPING: SchemaKeysMapping = {
  goal: 'goal',
  ref_date: 'ref_date',
  goal_bound_unit: 'goal_bound_unit',
  goal_bound_n_start: 'goal_bound_n_start',
  goal_bound_n_end: 'goal_bound_n_end',
  dimension_aggregation: 'dimension_aggregation',
  force_ref_date_to_date_over_timestamp: 'force_ref_date_to_date_over_timestamp',
};

interface OwnProps {
  value: any;
  onChange?: (parameters: any) => void;
  schemaKeysMapping?: SchemaKeysMapping;
  entityContext?: TableEntity;
  onSignalInfo?: (value: string | number) => void;
  refDateSignalFilters?: any;
  refDateAllowTypes?: SelectorModelType[];
  goalSignalFilters?: any;
  errors?: any;
  disabled?: any;
  className?: string;
}

type AllProps = OwnProps;

const createGoalFilters = entityContext => ({
  type: SignalType.MEASURE,
  data_type: [SignalDataType.INTEGER, SignalDataType.DECIMAL],
  entity_binding: TableEntityBinding.TWO_WAY,
  exclude_templates: ['dau', 'wau', 'mau', 'l7', 'l28'],
  entityContext,
});
const createGoalColumnFilters = entityContext => ({
  tableType: [TableType.STATE, TableType.EVENTS],
  literalType: [LiteralValueType.INTEGER, LiteralValueType.FLOAT],
  entity_binding: TableEntityBinding.TWO_WAY,
  entityContext,
});

const createRefDateFilters = entityContext => ({
  type: SignalType.DIMENSION,
  data_type: SignalDataType.TIMESTAMP,
  entity_binding: TableEntityBinding.DEFAULT,
  exclude_templates: ['bounded_action_ts', 'bounded_actions_ts', 'milestone_ts'],
  entityContext,
});

const createRefDateColumnFilters = (entityContext = undefined) => ({
  tableType: TableType.ENTITY_PROPERTIES,
  literalType: LiteralValueType.DATE,
  entity_binding: TableEntityBinding.DEFAULT,
  entityContext,
});

const GOAL_ALLOW_TYPES = [SelectorModelType.SIGNAL, SelectorModelType.COLUMN];
const REF_DATE_ALLOW_TYPES = [SelectorModelType.SIGNAL, SelectorModelType.COLUMN];

const INCLUDE_SELECTOR = [
  SignalSmartSelectorKey.METRICS,
  SignalSmartSelectorKey.FUNNELS,
  SignalSmartSelectorKey.FEATURES,
  SignalSmartSelectorKey.CONTENTS,
  SignalSmartSelectorKey.SIGNALS,
  SignalSmartSelectorKey.MILESTONES,
];

const MIN_COUNT_VALUE = 1;
const MAX_COUNT_VALUE = 360;

export const BaseBoundedRevenueQueryBuilder = (props: AllProps) => {
  const {
    errors,
    onChange,
    onSignalInfo,
    schemaKeysMapping = DEFAULT_SCHEMA_KEYS_MAPPING,
    value,
    disabled,
    entityContext,
    refDateAllowTypes,
    refDateSignalFilters: refDateSignalFiltersFromProps,
    goalSignalFilters: goalSignalFiltersFromProps,
    className,
  } = props;

  const goalSignalFilters = useMemo(
    () => goalSignalFiltersFromProps || createGoalFilters(entityContext),
    [entityContext, goalSignalFiltersFromProps]
  );
  const goalColumnFilters = useMemo(() => createGoalColumnFilters(entityContext), [entityContext]);

  const refDateSignalFilters = useMemo(
    () => refDateSignalFiltersFromProps || createRefDateFilters(entityContext),
    [entityContext, refDateSignalFiltersFromProps]
  );
  const refDateColumnFilters = useMemo(
    () => createRefDateColumnFilters(entityContext),
    [entityContext]
  );

  const fullWidthSince = useMemo(
    () => value[schemaKeysMapping.ref_date]?.type === 'And',
    [value, schemaKeysMapping]
  );

  return (
    <div className={classNames(classes.BaseBoundedRevenueQueryBuilder, className)}>
      <div className={classes.Row}>
        <TemplateItemQueryBuilder
          placeholder={'Revenue'}
          onChange={value => onChange({[schemaKeysMapping.goal]: value})}
          query={value[schemaKeysMapping.goal]}
          errors={errors?.[schemaKeysMapping.goal]}
          allowTypes={GOAL_ALLOW_TYPES}
          signalsInclude={INCLUDE_SELECTOR}
          signalFilters={goalSignalFilters}
          columnFilters={goalColumnFilters}
          disabled={disabled}
          onSignalInfo={onSignalInfo}
        />
      </div>
      <div className={classes.Row}>
        <div className={classes.Item}>Within</div>
        <div className={classes.Item}>
          <TextInput
            className={classes.Input}
            value={value?.[schemaKeysMapping.goal_bound_n_end]}
            onChange={value =>
              onChange({
                [schemaKeysMapping.goal_bound_n_end]: limitNumberValue(
                  value as any,
                  MIN_COUNT_VALUE,
                  MAX_COUNT_VALUE
                ),
              })
            }
            disabled={disabled}
            type={'number'}
          />
        </div>
        <div className={classes.Item}>
          <Select
            value={value?.[schemaKeysMapping.goal_bound_unit]}
            onChange={value =>
              onChange({
                [schemaKeysMapping.goal_bound_unit]: value,
              })
            }
            options={TIME_UNIT_PLURAL_OPTIONS}
            searchable={false}
            clearable={false}
            disabled={disabled}
            capitalize={false}
          />
        </div>
        <div className={classes.Item}>since</div>
        <div className={classNames(classes.Item, fullWidthSince && classes.FullWidth)}>
          <TemplateItemQueryBuilder
            onChange={value =>
              onChange({
                [schemaKeysMapping.ref_date]: value,
              })
            }
            query={value[schemaKeysMapping.ref_date]}
            errors={errors?.[schemaKeysMapping.ref_date]}
            signalFilters={refDateSignalFilters}
            columnFilters={refDateColumnFilters}
            disabled={disabled}
            allowTypes={refDateAllowTypes || REF_DATE_ALLOW_TYPES}
            onSignalInfo={onSignalInfo}
          />
        </div>
      </div>
    </div>
  );
};
