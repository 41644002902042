import {SmartSelector, useRemoteSourceStated} from 'ui-components';
import {useCallback, useEffect, useMemo, useRef} from 'react';
import {exists} from 'front-core';
import classes from '../smart-selector.module.scss';
import {CreatableSmartSelector, SmartSelectorSharedProps} from '../smart-selector.shared';
import {getMetricNetworkRequest} from '../../../../../http/metrics.network-requests';
import {SmartSelectorLayout} from '../smart-selector-layout.component';
import {useMetricSelectorProps} from './use-metric-selector-props.hook';

interface OwnProps extends SmartSelectorSharedProps, CreatableSmartSelector {}

type AllProps = OwnProps;

export const MetricSmartSelector = (props: AllProps) => {
  const {
    placeholder,
    value,
    onChange: onChangeFromProps,
    className,
    disabled,
    clearable,
    fullWidth,
    error,
    onCreate: onCreateFromProps,
  } = props;
  const {source: metric, exec} = useRemoteSourceStated({
    type: 'source',
    networkRequest: getMetricNetworkRequest,
    initialValue: null,
  });
  const selectorRef = useRef<any>(null);
  const onCreate = useCallback(() => {
    selectorRef.current.close();
    onCreateFromProps();
  }, [onCreateFromProps]);
  const onChange = useCallback(
    (value: number, item?: any) => {
      selectorRef.current.close();
      onChangeFromProps(value, item);
    },
    [onChangeFromProps]
  );
  const {sources, load} = useMetricSelectorProps({
    ...props,
    onCreate,
    onChange,
  });
  const selectedTableColumn = useMemo(
    () => (value && metric?.id === value ? metric : null),
    [value, metric]
  );
  const labelValue = useMemo(() => {
    if (value && selectedTableColumn) {
      return selectedTableColumn.name;
    }
  }, [value, selectedTableColumn]);
  useEffect(() => {
    value && metric?.id !== value && exec(value);
  }, [value, exec, metric]);

  return (
    <SmartSelectorLayout
      id={'metric-selector'}
      label={labelValue}
      disabled={disabled}
      error={error}
      clearable={clearable && exists(value)}
      onClear={() => onChange(null)}
      placeholder={placeholder}
      className={className}
      ref={selectorRef}
      withPreview={false}
      fullWidth={fullWidth}
    >
      <SmartSelector
        className={classes.Selector}
        sources={sources}
        load={load}
        withPreview={false}
      />
    </SmartSelectorLayout>
  );
};
