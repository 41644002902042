import {PropsWithChildren} from 'react';
import classes from './source-wizard-page-layout.module.scss';
import {ImagesResource} from '../../../../../../assets/images';
import classNames from 'classnames';

export const SourceWizardPageLayout = (props: PropsWithChildren<{contentClassName?: string}>) => {
  const {children, contentClassName} = props;
  return (
    <div className={classes.SourceWizardLayout}>
      <div className={classes.LoopsLogoContainer}>
        <img src={ImagesResource.app.logoPurple} className={classes.LoopsLogo} />
      </div>
      <div className={classes.ContentWrapper}>
        <div className={classNames(classes.Content, contentClassName)}>{children}</div>
      </div>
    </div>
  );
};
