import {RefreshIcon, TrashCanLightIcon} from 'ui-components';
import classes from './file-upload-actions.module.scss';
import {ReactNode} from 'react';

type FileUploadedActionsProps = {
  onDelete?: VoidFunction;
  onReplace?: VoidFunction;
  replaceActionLabel?: ReactNode;
};

export const FileUploadedActions = (props: FileUploadedActionsProps) => {
  const {onReplace, replaceActionLabel, onDelete} = props;
  return (
    <div className={classes.FileUploadActionsContainer}>
      {onReplace && replaceActionLabel && (
        <div className={classes.FileUploadActionButton} onClick={onReplace}>
          <RefreshIcon />
          <div>{replaceActionLabel}</div>
        </div>
      )}
      {onDelete && (
        <div onClick={onDelete} className={classes.FileUploadActionButton}>
          <TrashCanLightIcon />
          <div>Remove</div>
        </div>
      )}
    </div>
  );
};
