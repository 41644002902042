import classes from './app-user-onboarding-drawer.module.scss';
import {useCallback, useEffect, useState} from 'react';
import {OnboardingTask} from './components/onboarding-task.component';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import classNames from 'classnames';
import {Link, ChevronLeftSolidIcon} from 'ui-components';
import {useNavigate} from 'react-router';
import {USER_ONBOARDING_TASK_ACTION_URL_MAP} from '../../../../constants/user-onboarding';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import {useUserOnboarding} from '../../../../core/hooks/use-user-onboarding.hook';
import {OnboardingProgress} from './components/onboarding-progress.component';

interface OwnProps {}

type AllProps = OwnProps;

export const AppUserOnboardingDrawer = (props: AllProps) => {
  const {t} = useTranslation();
  const notify = useAmplitude();
  const navigate = useNavigate();
  const {
    tasks,
    completedTasks,
    isCompleted,
    isDismissed,
    setDismiss,
    isDrawerCollapsed,
    setDrawerCollapsed,
  } = useUserOnboarding();

  const [focusedTaskIndex, setFocusedTaskIndex] = useState(null);

  useEffect(() => {
    if (!isDrawerCollapsed) {
      setFocusedTaskIndex(tasks.findIndex(t => !t.completedOn));
    }
  }, [isDrawerCollapsed, tasks]);

  const onTaskClick = useCallback(
    (task: any, index: number) => () => {
      if (index !== focusedTaskIndex) {
        setFocusedTaskIndex(focusedTaskIndex === index ? null : index);
        notify(AmplitudeEvent.ONBOARDING_TASK_FOCUSED, {type: task.type});
      }
    },
    [notify, focusedTaskIndex, setFocusedTaskIndex]
  );

  const generateTaskOnActionHandler = useCallback(
    task => {
      const actionUrlGenerator = USER_ONBOARDING_TASK_ACTION_URL_MAP[task.type];
      if (!actionUrlGenerator || typeof actionUrlGenerator !== 'function') {
        return null;
      }
      const actionUrl = actionUrlGenerator(task.metadata);
      if (actionUrl) {
        return () => {
          navigate(actionUrl);
          notify(AmplitudeEvent.ONBOARDING_TASK_ACTION_CLICKED, {
            type: task.type,
          });
        };
      }
    },
    [notify, navigate]
  );

  if (isDismissed) {
    return null;
  }

  const titleLabel = t(
    isCompleted
      ? TransKeys.APP_USER_ONBOARDING.DRAWER_TITLE_COMPLETE_LABEL
      : TransKeys.APP_USER_ONBOARDING.DRAWER_TITLE_PARTIAL_LABEL
  );

  const dismissLabel = t(
    isCompleted
      ? TransKeys.APP_USER_ONBOARDING.DRAWER_DISMISS_COMPLETE_LABEL
      : TransKeys.APP_USER_ONBOARDING.DRAWER_DISMISS_PARTIAL_LABEL
  );

  return (
    <div
      className={classNames(
        classes.Drawer,
        isDrawerCollapsed && classes.Collapsed,
        isCompleted && classes.Completed
      )}
    >
      <div className={classes.Container}>
        <div className={classes.Header}>
          <div className={classes.Title}>
            <span className={classes.Label}>{titleLabel}</span>
            <span className={classes.IconButton} onClick={() => setDrawerCollapsed(true)}>
              <ChevronLeftSolidIcon />
            </span>
          </div>
          <OnboardingProgress
            variant={'linear'}
            count={tasks.length}
            completedCount={completedTasks.length}
          />
        </div>
        <div className={classes.Tasks}>
          {tasks.map((task, index) => (
            <div key={index} onClick={onTaskClick(task, index)}>
              <OnboardingTask
                task={task}
                focused={focusedTaskIndex === index}
                onActionClick={generateTaskOnActionHandler(task)}
              />
            </div>
          ))}
        </div>
        <div className={classes.Footer}>
          <Link className={classes.Dimiss} onClick={() => setDismiss()} underline={false}>
            {dismissLabel}
          </Link>
        </div>
      </div>
    </div>
  );
};
