import google from './company-logos/google.svg';
import spotify from './company-logos/spotify.svg';
import slack from './company-logos/slack.svg';
import facebook from './company-logos/facebook.svg';
import logo from './app/logo.png';
import logoGradient from './app/logo-gradient.svg';
import telescope from './app/telescope.svg';
import telescopeV2 from './app/telescope-v2.svg';
import magnifyingGlass from './app/magnifying-glass.svg';
import magnifyingGlassOnUsers from './app/magnifying-glass-on-users.svg';
import growthMap from './app/growth-map.svg';
import confetti from './backgrounds/confetti.svg';
import appBackground from './backgrounds/app-background.png';
import trophy from './app/trophy.svg';
import winner from './app/winner.svg';
import healthMonitorEmptyState from './app/health-monitor-empty-state-image.png';
import shortLogo from './app/short-logo.svg';
import squigglyArrowUp from './app/squiggly-arrow-up.svg';
import logoWhite from './app/logo-white.svg';
import logoPurple from './app/logo-purple.svg';
import miniLogoWhite from './app/mini-logo-white.svg';
import kpiTree from './app/kpi-tree.svg';
import athena from './data-sources/amazon-athena.svg';
import redshift from './data-sources/amazon-redshift.svg';
import bigquery from './data-sources/big-query.svg';
import clickhouse from './data-sources/click-house.svg';
import snowflake from './data-sources/snowflake.svg';
import databricks from './data-sources/databricks.svg';
import singlestore from './data-sources/single-store.svg';
import vertica from './data-sources/vertica.svg';
import loopsLogoBackground from './backgrounds/loops-logo-background.svg';
import sourceWizardIllustration from './app/source-wizard-illustration.svg';
import dataSourceConnectedSuccessfully from './app/data-source-connected-successfully.svg';
import sourceWizardConnectionFailed from './app/source-wizard-connection-failed.svg';
import uploadFile from './app/upload-file.svg';
import jsonFile from './app/json-file.svg';
import jsonFileError from './app/json-file-error.svg';
import loopsAIAssistant from './app/loops-ai-assistant.svg';
import graphEmptyStateIlustration from './app/graph-empty-state-illustration.svg';
import loopsAIAssistantPurple from './app/loops-ai-assistant-purple.svg';

export const ImagesResource = {
  app: {
    logoWhite,
    logoPurple,
    miniLogoWhite,
    logo,
    shortLogo,
    logoGradient,
    telescope,
    telescopeV2,
    magnifyingGlass,
    magnifyingGlassOnUsers,
    growthMap,
    trophy,
    winner,
    healthMonitorEmptyState,
    squigglyArrowUp,
    kpiTree,
    sourceWizardIllustration,
    dataSourceConnectedSuccessfully,
    sourceWizardConnectionFailed,
    uploadFile,
    jsonFile,
    jsonFileError,
    loopsAIAssistant,
    loopsAIAssistantPurple,
    graphEmptyStateIlustration,
  },
  companiesLogos: {
    google,
    spotify,
    facebook,
    slack,
  },
  backgrounds: {
    confetti,
    appBackground,
    loopsLogoBackground,
  },
  dataSources: {
    bigquery,
    redshift,
    snowflake,
    athena,
    vertica,
    databricks,
    clickhouse,
    singlestore,
  },
};
