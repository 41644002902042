import {NRC} from './index';
import {RequestType, toSnakeCase} from 'front-core';
import {toCamelCase} from 'front-core';
import {SegmentCategoryDTO, SwapCategoriesDTO} from '../objects/dto/segment.dto';

export const getSegmentCategoryNetworkRequest: NRC<void, any> = (id: number) => ({
  method: RequestType.GET,
  relativeUrl: `/app/segment-categories/${id}`,
  responseTransformer: res => toCamelCase(res.data),
});

export const createSegmentCategoryNetworkRequest: NRC<SegmentCategoryDTO, void> = (
  data: SegmentCategoryDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/segment-categories`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const editSegmentCategoryNetworkRequest: NRC<SegmentCategoryDTO, void> = (
  data: SegmentCategoryDTO
) => ({
  method: RequestType.PUT,
  relativeUrl: `/app/segment-categories/${data.id}`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const deleteSegmentCategoryNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.DELETE,
  relativeUrl: `/app/segment-categories/${id}`,
  responseTransformer: res => toCamelCase(res.data),
});

export const swapSegmentCategoryNetworkRequest: NRC<SwapCategoriesDTO, void> = (
  data: SwapCategoriesDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/segment-categories/swap`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const getSegmentCategoriesMapNetworkRequest: NRC<void, any> = () => ({
  method: RequestType.GET,
  relativeUrl: `/app/segment-categories/map`,
  responseTransformer: res => toCamelCase(res.data),
});

export const getSegmentCategoriesNetworkRequest: NRC<void, any> = () => ({
  method: RequestType.GET,
  relativeUrl: `/app/segment-categories`,
  responseTransformer: res => toCamelCase(res.data),
});
