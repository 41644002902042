import {AnalysisType} from '../../../../objects/models/analysis-type.model';
import classes from './analysis-type-card.module.scss';
import {
  Button,
  Chip,
  ChipVariant,
  createFontAwesomeIcon,
  SparklesSolidIcon,
  AvatarGroup,
} from 'ui-components';
import {AnalysisTypeImage} from '../analysis-type-image/analysis-type-image.component';
import classnames from 'classnames';
import {useCallback, useMemo} from 'react';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';

interface OwnProps {
  analysisType: AnalysisType;
  onClick: (analysisType: any) => void;
  onPreview?: (analysisType: any) => void;
  className?: string;
}

const ArrowRight = createFontAwesomeIcon('fa-regular fa-arrow-right');

type AllProps = OwnProps;

const AVATAR_GROUP_MAX_COUNT = 3;

export const AnalysisTypeCard = (props: AllProps) => {
  const {analysisType, onClick, onPreview, className} = props;
  const {t} = useTranslation();

  const hasPreview = !!onPreview;

  const handleOnClick = useCallback(
    e => {
      e.stopPropagation();
      onClick(analysisType);
    },
    [onClick, analysisType]
  );

  const handleCardOnClick = useCallback(
    e => {
      if (!hasPreview) {
        handleOnClick(e);
      }
    },
    [hasPreview, handleOnClick]
  );

  const handleOnPreview = useCallback(
    e => {
      e.stopPropagation();
      onPreview(analysisType);
    },
    [onPreview, analysisType]
  );

  const usersAvatars = useMemo(
    () =>
      analysisType.ranByTeamMembers.map(user => ({
        id: user.id,
        name: `${user.firstName} ${user.lastName}`,
        helperText: `${user.firstName} ${user.lastName} ${t(
          TransKeys.ANALYSIS_TYPE_CARD.SOCIAL_PROOFS.HELPER_TEXT_SUFFIX
        )}`,
      })),
    [analysisType.ranByTeamMembers, t]
  );

  return (
    <div
      className={classnames(classes.AnalysisTypeCard, !hasPreview && classes.Clickable, className)}
      onClick={handleCardOnClick}
    >
      <div className={classes.CardImage}>
        <AnalysisTypeImage analysisType={analysisType} className={classes.ImageContainer} />
        {hasPreview && (
          <div className={classes.CardImageOverlay}>
            <Button className={classes.SelectButton} onClick={handleOnClick}>
              {t(TransKeys.GENERAL.LABELS.SELECT)}
              <ArrowRight />
            </Button>
            <Button className={classes.MoreInfoButton} onClick={handleOnPreview}>
              {t(TransKeys.GENERAL.LABELS.MORE_INFO)}
            </Button>
          </div>
        )}
      </div>
      <div className={classes.CardBody}>
        <div className={classes.CardTitle}>
          <span className={classes.Title}>{analysisType.staticName}</span>
          {usersAvatars.length > 0 && (
            <AvatarGroup
              avatarsData={usersAvatars}
              variant={'secondary'}
              size={'small'}
              maxCount={AVATAR_GROUP_MAX_COUNT}
            />
          )}
        </div>
        <div className={classes.CardDescription}>{analysisType.shortDescription}</div>
        {analysisType.isCausal && (
          <div className={classes.ChipWrapper}>
            <Chip
              variant={ChipVariant.CAUSAL}
              className={classes.CausalTag}
              icon={SparklesSolidIcon}
              label={t(TransKeys.GENERAL.LABELS.CAUSAL)}
            />
          </div>
        )}
      </div>
    </div>
  );
};
