import {useMemo} from 'react';
import classNames from 'classnames';
import classes from './subscribed-segments-selector.module.scss';
import {HomepageSubscribedSegmentsType} from '../../../../../../objects/models/homepage.model';
import {Accordion, Chip, ChipVariant, OptionBox} from 'ui-components';
import TransKeys from '../../../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {Team} from '../../../../../../objects/models/team.model';
import {withStopPropagation} from 'front-core';

interface OwnProps {
  subscribedSegmentsType: HomepageSubscribedSegmentsType;
  onChangeSubscribedSegmentsType: (type: HomepageSubscribedSegmentsType) => void;
  onTeamSegmentsClick: () => void;
  team?: Team;
  disabled?: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const SubscribedSegmentsSelector = (props: AllProps) => {
  const {
    subscribedSegmentsType,
    onChangeSubscribedSegmentsType,
    team,
    onTeamSegmentsClick,
    disabled,
    className,
  } = props;
  const {t} = useTranslation();

  const subTitle = useMemo(() => {
    if (subscribedSegmentsType === HomepageSubscribedSegmentsType.ALL) {
      return <>All Segments</>;
    }
    if (subscribedSegmentsType === HomepageSubscribedSegmentsType.TEAM) {
      return <>Team Segments</>;
    }
  }, [subscribedSegmentsType]);

  return (
    <Accordion
      title={t(TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.SUBSCRIBED_SEGMENTS.TITLE)}
      subTitle={subTitle}
      disabled={disabled}
      className={classNames(classes.SubscribedSegmentsSelector, className)}
    >
      <div className={classes.Description}>
        {t(TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.SUBSCRIBED_SEGMENTS.DESCRIPTION)}
      </div>
      <OptionBox
        className={classes.Option}
        title={t(TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.SUBSCRIBED_SEGMENTS.ALL_SEGMENTS.TITLE)}
        description={t(
          TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.SUBSCRIBED_SEGMENTS.ALL_SEGMENTS.DESCRIPTION
        )}
        selected={subscribedSegmentsType === HomepageSubscribedSegmentsType.ALL}
        onSelect={() => onChangeSubscribedSegmentsType(HomepageSubscribedSegmentsType.ALL)}
      />
      {team && !team.isDefaultTeam && (
        <OptionBox
          className={classes.Option}
          title={
            <>
              {t(TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.SUBSCRIBED_SEGMENTS.TEAM_SEGMENTS.TITLE)}
              <Chip
                label={t(TransKeys.GENERAL.LABELS.RECOMMENDED).toUpperCase()}
                variant={ChipVariant.NEW}
                size={'small'}
              />
            </>
          }
          description={t(
            TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.SUBSCRIBED_SEGMENTS.TEAM_SEGMENTS.DESCRIPTION
          )}
          selected={subscribedSegmentsType === HomepageSubscribedSegmentsType.TEAM}
          onSelect={() => onChangeSubscribedSegmentsType(HomepageSubscribedSegmentsType.TEAM)}
        >
          <div className={classes.TeamSegmentsContent}>
            {team.segmentsCount > 0 && (
              <span
                className={classes.Button}
                onClick={withStopPropagation(() => onTeamSegmentsClick())}
              >
                {t(
                  TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.SUBSCRIBED_SEGMENTS.TEAM_SEGMENTS
                    .VIEW_TEAM_SEGMENTS,
                  {count: team.segmentsCount}
                )}
              </span>
            )}
            {team.segmentsCount === 0 && (
              <span
                className={classes.Text}
                onClick={withStopPropagation(() => onTeamSegmentsClick())}
              >
                {t(
                  TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.SUBSCRIBED_SEGMENTS.TEAM_SEGMENTS
                    .NO_TEAM_SEGMENTS_DEFINED,
                  {team_name: team.name}
                )}
                {', '}
                <span className={classes.Button}>
                  {t(
                    TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.SUBSCRIBED_SEGMENTS.TEAM_SEGMENTS
                      .NO_TEAM_SEGMENTS_DEFINED_CTA
                  )}
                </span>
              </span>
            )}
          </div>
        </OptionBox>
      )}
      <OptionBox
        className={classes.Option}
        title={
          <>
            {t(TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.SUBSCRIBED_SEGMENTS.CUSTOM.TITLE)}
            <Chip label={'SOON'} variant={ChipVariant.EXAMPLE} size={'small'} />
          </>
        }
        description={t(
          TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.SUBSCRIBED_SEGMENTS.CUSTOM.DESCRIPTION
        )}
        disabled={true}
        selected={subscribedSegmentsType === HomepageSubscribedSegmentsType.CUSTOM}
        onSelect={() => onChangeSubscribedSegmentsType(HomepageSubscribedSegmentsType.CUSTOM)}
      />
    </Accordion>
  );
};
