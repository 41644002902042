import {useCallback, useContext, useEffect, useMemo} from 'react';
import classNames from 'classnames';
import classes from './health-monitor.module.scss';
import {Button, HealthMonitorMenuIcon, RSSIcon} from 'ui-components';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../constants/translation-keys';
import {useDispatch, useSelector} from 'react-redux';
import {
  HealthMonitorActionType,
  initializeHealthMonitor,
  removeHealthMonitorGroupConfirmed,
  removeMonitoredMetricConfirmed,
} from '../../../../store/health-monitor/health-monitor.actions';
import {
  createSelected,
  getSelected,
  removeSelected,
} from '../../../../store/selected/selected.actions';
import {
  getReducedLoadingStateSelector,
  getSingleSelectedSelector,
} from '../../../../store/store.selectors';
import {HealthMonitorModel, MonitoredMetric} from '../../../../objects/models/health-monitor.model';
import {CalcSeriesMode} from '../../../../constants/metric-series.consts';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../store/actions-listener/actions-listener.actions';
import {getHealthMonitorNetworkRequest} from '../../../../http/health-monitor.network-requests';
import {exists} from 'front-core';
import {
  JsonParam,
  NumberParam,
  StringParam,
  useQueryParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import {PanelKey} from '../../../../constants/panels';
import {PanelType} from '../../../../objects/system/panel-type.enum';
import {
  ANALYSIS_PARAMETERS_KEY,
  ANALYSIS_TYPE_ID_PATH_PARAM,
  HEALTH_MONITOR_GROUP_ID_PATH_PARAM,
  METRIC_HEALTH_MONITOR_ID_PATH_PARAM,
  MONITORED_METRIC_ID_PATH_PARAM,
} from '../../../../constants/app-routes';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import {useParams} from 'react-router';
import {HealthMonitorMain} from './components/health-monitor-main/health-monitor-main.component';
import {HealthMonitorEmptyState} from '../../panels/health-monitor-empty-state/health-monitor-empty-state.component';
import {ViewMetricSeries} from '../view-monitored-metric/view-monitored-metric.component';
import {HealthMonitorWelcome} from './components/health-monitor-welcome/health-monitor-welcome.component';
import {AnalysisTypeId} from '../../../../constants/analysis-type-id';
import {GOAL_KEY} from '../../../analyses/analysis-forms/analysis-parameters/analysis-101/analysis-101-form.component';
import PageLayout from '../../../shared/components/layout/page-layout/index';
import {useCurrentUser} from '../../../../core/hooks/use-user.hook';

interface OwnProps {
  className?: string;
}

type AllProps = OwnProps;

const SELECTED_DATA_KEY = 'HEALTH_MONITOR/HEALTH_MONITOR';
const ID_AS_NEW_PATHNAME = 'new';

export const HealthMonitor = (props: AllProps) => {
  const {className} = props;
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const notify = useAmplitude();
  const {openPrimaryPanel} = useContext(PanelsContext);
  const {[METRIC_HEALTH_MONITOR_ID_PATH_PARAM]: healthMonitorId} = useParams<any>();
  const [filtersState, setFiltersState] = useQueryParams({
    filters: JsonParam,
  });
  const [selectedMetricId, setSelectedMetricId] = useQueryParam('selected', NumberParam);
  const [selectedMode, setSelectedMode] = useQueryParam(
    'mode',
    withDefault(StringParam, CalcSeriesMode.VS_EXPECTED)
  );
  const user = useCurrentUser();
  const healthMonitor: HealthMonitorModel = useSelector(state =>
    getSingleSelectedSelector(SELECTED_DATA_KEY, state)
  );
  const isLoading = useSelector(state => getReducedLoadingStateSelector(SELECTED_DATA_KEY)(state));
  const showEmptyState = useMemo(
    () => healthMonitor?.groups.length === 0 && healthMonitor.initialOptionsCount > 0,
    [healthMonitor]
  );
  const generateFilters = useCallback(
    () => ({
      ...(filtersState.filters || {}),
      id: healthMonitorId !== ID_AS_NEW_PATHNAME ? healthMonitorId : undefined,
    }),
    [filtersState.filters, healthMonitorId]
  );
  useEffect(() => {
    dispatch(
      createSelected({
        selectedKey: SELECTED_DATA_KEY,
        actionKey: SELECTED_DATA_KEY,
        request: getHealthMonitorNetworkRequest,
      })
    );
    return () => {
      dispatch(removeSelected(SELECTED_DATA_KEY));
    };
  }, [dispatch]);
  const onToDateChanged = useCallback(
    toDate => {
      setFiltersState({filters: {toDate}});
      notify(AmplitudeEvent.HEALTH_MONITOR_DATE_CHANGED, {
        date: toDate,
      });
    },
    [setFiltersState, notify]
  );
  const onCreateMetric = useCallback(
    (metricSeriesGroupId: number | null, isNorthStar: boolean = false) =>
      openPrimaryPanel(
        PanelKey.MONITORED_METRIC_FORM_PANEL,
        {
          metricHealthMonitorId: healthMonitor.id,
          metricSeriesGroupId,
          isNorthStar,
          onSuccess: (res, action) => {
            setSelectedMetricId(res.id);
          },
        },
        PanelType.MODAL
      ),
    [healthMonitor, openPrimaryPanel, setSelectedMetricId]
  );
  const onDelete = useCallback(
    (monitoredMetricId: number) => {
      dispatch(removeMonitoredMetricConfirmed(monitoredMetricId));
    },
    [dispatch]
  );
  const onEdit = useCallback(
    (monitoredMetricId: number) => {
      openPrimaryPanel(
        PanelKey.MONITORED_METRIC_FORM_PANEL,
        {
          [MONITORED_METRIC_ID_PATH_PARAM]: monitoredMetricId,
          onSuccess: (res, action) => {
            setSelectedMetricId(res.id);
          },
        },
        PanelType.MODAL
      );
    },
    [openPrimaryPanel, setSelectedMetricId]
  );
  const onCreateEditGroup = useCallback(
    (id?: number) => {
      openPrimaryPanel(
        PanelKey.HEALTH_MONITOR_GROUP_PANEL,
        {
          [HEALTH_MONITOR_GROUP_ID_PATH_PARAM]: id,
          [METRIC_HEALTH_MONITOR_ID_PATH_PARAM]: healthMonitor.id,
        },
        PanelType.MODAL
      );
    },
    [openPrimaryPanel, healthMonitor]
  );
  const onSubscribe = useCallback(() => {
    openPrimaryPanel(
      PanelKey.HEALTH_MONITOR_SUBSCRIPTION_FORM_PANEL,
      {
        [METRIC_HEALTH_MONITOR_ID_PATH_PARAM]: healthMonitor.id,
      },
      PanelType.MODAL
    );
  }, [openPrimaryPanel, healthMonitor]);
  const onDeleteGroup = useCallback(
    groupId => {
      dispatch(
        removeHealthMonitorGroupConfirmed({
          metricHealthMonitorId: healthMonitor.id,
          metricSeriesGroupId: groupId,
        })
      );
    },
    [dispatch, healthMonitor]
  );
  const gotoViewMonitoredMetric = useCallback(
    (id: number) => {
      setSelectedMetricId(id);
      notify(AmplitudeEvent.MONITORED_METRIC_VIEWED, {
        monitored_metric_id: id,
      });
    },
    [notify, setSelectedMetricId]
  );
  const onSubmitEmptyState = useCallback(
    (selectedMonitoredMetricIds: number[]) => {
      dispatch(
        initializeHealthMonitor({
          monitoredMetricIds: selectedMonitoredMetricIds,
          metricHealthMonitorId: healthMonitor.id,
        })
      );
    },
    [dispatch, healthMonitor]
  );
  const onRequestAnalysis = useCallback(
    (monitoredMetric: Partial<MonitoredMetric>) => {
      const {analysisContext} = monitoredMetric;
      openPrimaryPanel(
        PanelKey.ANALYSIS_FORM_PANEL,
        {
          [ANALYSIS_TYPE_ID_PATH_PARAM]: AnalysisTypeId.RCA_ANALYSIS,
          [ANALYSIS_PARAMETERS_KEY]: {
            [GOAL_KEY]: analysisContext.goalId,
          },
        },
        PanelType.MODAL
      );
    },
    [openPrimaryPanel]
  );

  useEffect(() => {
    dispatch(getSelected(SELECTED_DATA_KEY, generateFilters()));
  }, [generateFilters, dispatch]);

  useEffect(() => {
    const listener = () => dispatch(getSelected(SELECTED_DATA_KEY, generateFilters()));
    dispatch(registerActionListener(HealthMonitorActionType.HEALTH_MONITOR_CHANGED, listener));

    return () => {
      dispatch(removeActionListener(HealthMonitorActionType.HEALTH_MONITOR_CHANGED, listener));
    };
  }, [dispatch, generateFilters]);

  return (
    <div className={classNames(classes.HealthMonitor, className)}>
      <PageLayout.Layout>
        <PageLayout.Title
          title={t(TransKeys.HEALTH_MONITOR.HEADER.TITLE)}
          icon={HealthMonitorMenuIcon}
        />
        <PageLayout.Actions>
          <Button
            className={classes.Action}
            onClick={onSubscribe}
            variant={'outlined'}
            size={'large'}
            active={exists(healthMonitor?.cronExp)}
            icon={RSSIcon}
          >
            {t(
              TransKeys.GENERAL.ACTIONS[exists(healthMonitor?.cronExp) ? 'SUBSCRIBED' : 'SUBSCRIBE']
            )}
          </Button>
        </PageLayout.Actions>
        <PageLayout.Body className={classes.Body} noPadding isLoading={isLoading || !healthMonitor}>
          {healthMonitor && (
            <>
              <div className={classes.Main}>
                <HealthMonitorWelcome
                  userFirstName={user.firstName}
                  date={healthMonitor.toDate}
                  onDateChange={onToDateChanged}
                  mode={selectedMode as any}
                  setMode={setSelectedMode}
                />
                <HealthMonitorMain
                  healthMonitor={healthMonitor}
                  selectedMetricId={selectedMetricId}
                  onCreateMetric={onCreateMetric}
                  onViewMetric={gotoViewMonitoredMetric}
                  onEditMetric={onEdit}
                  onDeleteMetric={onDelete}
                  onCreateEditGroup={onCreateEditGroup}
                  onDeleteGroup={onDeleteGroup}
                  onRequestAnalysis={onRequestAnalysis}
                  mode={CalcSeriesMode.VS_EXPECTED}
                  emptyState={showEmptyState}
                />
              </div>
              <div className={classes.Viewer}>
                <ViewMetricSeries
                  modelId={selectedMetricId}
                  modelType={'MonitoredMetric'}
                  mode={CalcSeriesMode.VS_EXPECTED}
                  toDate={filtersState?.filters?.toDate}
                  onEdit={() => onEdit(selectedMetricId)}
                  onDelete={() => onDelete(selectedMetricId)}
                />
              </div>
            </>
          )}
        </PageLayout.Body>
      </PageLayout.Layout>

      {showEmptyState && (
        <HealthMonitorEmptyState onSubmit={onSubmitEmptyState} mode={CalcSeriesMode.VS_EXPECTED} />
      )}
    </div>
  );
};
