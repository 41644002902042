import {BookOpenCoverIcon, Link} from 'ui-components';
import classes from './view-definition.module.scss';

type Props = {
  onClick?: () => void;
};

export const ModelPageHeaderViewDefinition = (props: Props) => {
  const {onClick} = props;

  return (
    <div className={classes.ViewDefinition}>
      <BookOpenCoverIcon className={classes.ViewDefinitionIcon} />
      <Link inline onClick={onClick} underline={false}>
        View Definition
      </Link>
    </div>
  );
};
