import {useCallback, useEffect, useMemo, useRef} from 'react';
import {SmartTreeSelector, useRemoteSourceStated} from 'ui-components';
import {exists} from 'front-core';
import classes from './smart-selector.module.scss';
import {SmartSelectorLayout} from './smart-selector-layout.component';
import {CreatableSmartSelector, SmartSelectorSharedProps} from './smart-selector.shared';
import {getAnalysisFolderNetworkRequest} from '../../../../http/analysis-folders.network-requests';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {useAnalysisFoldersTree} from '../../../analyses/hooks/use-analysis-folders-tree.hook';

interface OwnProps extends SmartSelectorSharedProps, CreatableSmartSelector {
  excludeId?: number[];
  defaultFolderId?: number;
}

type AllProps = OwnProps;

export const AnalysisFolderSmartSelector = (props: AllProps) => {
  const {
    placeholder,
    value,
    onChange: onChangeFromProps,
    className,
    disabled,
    clearable,
    fullWidth,
    excludeId,
    defaultFolderId,
    error,
    onCreate: onCreateFromProps,
  } = props;
  const {t} = useTranslation();
  const selectorRef = useRef<any>(null);
  const {source: analysisFolder, exec} = useRemoteSourceStated({
    type: 'source',
    networkRequest: getAnalysisFolderNetworkRequest,
    initialValue: null,
    onError: err => {
      if (err.status === 404) {
        onChangeFromProps(defaultFolderId);
      }
    },
  });
  const {groups} = useAnalysisFoldersTree({excludeId});
  const selectedAnalysisFolder = useMemo(
    () => (value && analysisFolder?.id === value ? analysisFolder : null),
    [value, analysisFolder]
  );
  const labelValue = useMemo(() => {
    if (value && selectedAnalysisFolder) {
      return (
        t(TransKeys.ANALYSES_MAIN.FOLDERS_DEFAULT_NAME[selectedAnalysisFolder.name]?.['TITLE']) ||
        selectedAnalysisFolder.name
      );
    }
  }, [value, selectedAnalysisFolder, t]);
  const onChange = useCallback(
    (value: number, item?: any) => {
      selectorRef.current.close();
      onChangeFromProps(value, item);
    },
    [onChangeFromProps]
  );
  const onCreate = useCallback(() => {
    selectorRef.current.close();
    onCreateFromProps();
  }, [onCreateFromProps]);
  useEffect(() => {
    value && analysisFolder?.id !== value && exec(value);
  }, [value, exec, analysisFolder]);
  return (
    <SmartSelectorLayout
      id={'analysis-folder-selector'}
      label={labelValue}
      disabled={disabled}
      error={error}
      clearable={clearable && exists(value)}
      onClear={() => onChange(null)}
      placeholder={placeholder}
      className={className}
      ref={selectorRef}
      withPreview={false}
      fullWidth={fullWidth}
      onCreate={onCreate}
      addButton={Boolean(onCreateFromProps)}
    >
      <SmartTreeSelector
        className={classes.Selector}
        value={value}
        onChange={value => onChange(value as any)}
        groups={groups}
        startRootOpen={true}
        selectParentValue={true}
        multi={false}
      />
    </SmartSelectorLayout>
  );
};
