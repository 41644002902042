import classNames from 'classnames';
import classes from './copy-link-button.module.scss';
import {CheckCircleIcon, LinkIcon} from 'ui-components';
import {Tooltip} from '@material-ui/core';
import {useCopyToClipboard} from '../../../../../core/hooks/use-copy-to-clipboard.hooks';
import {useCallback} from 'react';

interface OwnProps {
  copyText: string;
  className?: string;
  disabled?: boolean;
}

type AllProps = OwnProps;

export const CopyLinkButton = (props: AllProps) => {
  const {copyText, className, disabled} = props;

  const {copiedText, copy} = useCopyToClipboard();

  const onCopyClick = useCallback(() => {
    if (disabled) {
      return;
    }
    copy(copyText);
  }, [disabled, copy, copyText]);

  return (
    <Tooltip
      title={
        copiedText !== null ? (
          <div className={classes.CopySuccess}>
            <CheckCircleIcon className={classes.Icon} /> Copied successfully!
          </div>
        ) : (
          ''
        )
      }
      placement={'top'}
    >
      <div
        onClick={onCopyClick}
        className={classNames(classes.CopyLinkButton, disabled && classes.Disabled, className)}
      >
        <LinkIcon className={classes.Icon} />
        Copy link
      </div>
    </Tooltip>
  );
};
