import {useContext, useCallback, useMemo} from 'react';
import {cloneDeep, get, keys, set} from 'lodash';
import {QueryBuilderFactory} from 'ui-components';
import {SUBSCRIPTION_RETENTION_ANALYSIS_PARAMETER_MAPPING} from '../query-builders.config';
import {
  METADATA_KEY,
  PARAMETERS_METADATA_KEY,
} from '../../../../../constants/parameters-saved-keys';
import {
  BasePaymentRetentionBuilder,
  SignalDependenciesMapping,
} from './base-payment-retention-builder.component';
import {Milestone, MilestoneType} from '../../../../../objects/models/milestone.model';
import {PanelsContext} from '../../../../../core/contexts/panels.context';
import {PanelKey} from '../../../../../constants/panels';
import {TableEntity} from '../../../../../objects/models/table.model';

interface OwnProps {
  query: any;
  onChange?: (parameters: any) => void;
  entityContext: TableEntity;
  errors?: any;
  disabled?: boolean;
  className?: string;
}

type AllProps = OwnProps;

const mapper = (value: any) => ({
  plan: get(value, SUBSCRIPTION_RETENTION_ANALYSIS_PARAMETER_MAPPING.plan),
  goal_bound_unit: get(value, SUBSCRIPTION_RETENTION_ANALYSIS_PARAMETER_MAPPING.goal_bound_unit),
  goal_bound_n: get(value, SUBSCRIPTION_RETENTION_ANALYSIS_PARAMETER_MAPPING.goal_bound_n),
});

const signalDependenciesMapper = (query: any): SignalDependenciesMapping => ({
  planSignalId: get(query, `${PARAMETERS_METADATA_KEY}.${METADATA_KEY.PLAN_SIGNAL_ID_KEY}`),
  firstPaymentSignalId: get(
    query,
    `${PARAMETERS_METADATA_KEY}.${METADATA_KEY.FIRST_PAYMENT_SIGNAL_ID_KEY}`
  ),
  churnSignalId: get(query, `${PARAMETERS_METADATA_KEY}.${METADATA_KEY.CHURN_SIGNAL_ID_KEY}`),
});

const MILESTONE_TYPE_TO_SIGNAL_ID_MAPPING = {
  [MilestoneType.PLAN]: METADATA_KEY.PLAN_SIGNAL_ID_KEY,
  [MilestoneType.FIRST_PAYMENT]: METADATA_KEY.FIRST_PAYMENT_SIGNAL_ID_KEY,
  [MilestoneType.CHURN]: METADATA_KEY.CHURN_SIGNAL_ID_KEY,
};

const DEPENDANT_MILESTONE_TYPES = [
  MilestoneType.PLAN,
  MilestoneType.FIRST_PAYMENT,
  MilestoneType.CHURN,
];

export const createPaymentRetentionInitialQuery = (getSignalByTag, entityContext) => {
  const q = QueryBuilderFactory.createTemplate();

  q.template = 'subscription_retention_ts';
  q.parameters[0] = 'month';
  q.parameters[1] = 2;
  q.parameters[2] = '1m';
  q[PARAMETERS_METADATA_KEY] = {
    [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: 'PaymentRetentionQueryBuilder',
  };

  DEPENDANT_MILESTONE_TYPES.forEach(milestoneType => {
    const signal = getSignalByTag(milestoneType, entityContext);
    q[PARAMETERS_METADATA_KEY][MILESTONE_TYPE_TO_SIGNAL_ID_MAPPING[milestoneType]] = signal?.id;
  });

  return q;
};

export const PaymentRetentionQueryBuilder = (props: AllProps) => {
  const {query, onChange: onChangeFromProps, entityContext, errors, disabled, className} = props;
  const {openSecondaryPanel} = useContext(PanelsContext);

  const mappedValue = useMemo(() => mapper(query), [query]);
  const mappedErrors = useMemo(() => mapper(errors), [errors]);
  const mappedSignalDependencies = useMemo(() => signalDependenciesMapper(query), [query]);
  const mappedSignalDependenciesErrors = useMemo(() => signalDependenciesMapper(errors), [errors]);

  const mappedOnChange = useCallback(
    changes => {
      const newValue = cloneDeep(query);
      for (const k of keys(changes)) {
        set(newValue, SUBSCRIPTION_RETENTION_ANALYSIS_PARAMETER_MAPPING[k], changes[k]);
      }
      newValue[PARAMETERS_METADATA_KEY][METADATA_KEY.BUILDER_COMPONENT_NAME_KEY] =
        'PaymentRetentionQueryBuilder';
      onChangeFromProps(newValue);
    },
    [onChangeFromProps, query]
  );

  const onMilestoneCreated = useCallback(
    (milestone: Milestone) => {
      const newValue = cloneDeep(query);
      newValue[PARAMETERS_METADATA_KEY][MILESTONE_TYPE_TO_SIGNAL_ID_MAPPING[milestone.type]] =
        milestone.signalId;

      onChangeFromProps(newValue);
    },
    [onChangeFromProps, query]
  );

  const handleMilstoneCreate = useCallback(
    (milestoneType: MilestoneType) => {
      openSecondaryPanel(PanelKey.MILESTONE_FORM_PANEL, {
        type: milestoneType,
        data: {
          type: milestoneType,
          entity: entityContext,
        },
        onSuccess: onMilestoneCreated,
      });
    },
    [openSecondaryPanel, onMilestoneCreated, entityContext]
  );

  return (
    <BasePaymentRetentionBuilder
      value={mappedValue}
      onChange={mappedOnChange}
      onMilestoneCreate={handleMilstoneCreate}
      entityContext={entityContext}
      errors={mappedErrors}
      className={className}
      disabled={disabled}
      signalDependencies={mappedSignalDependencies}
      signalDependenciesErrors={mappedSignalDependenciesErrors}
    />
  );
};
