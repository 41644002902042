import {
  MultiLoadResponse,
  SmartSelectorParameters,
  SmartSelectorSource,
  SmartSelector,
  useRemoteSourceStated,
  SingleUseIcon,
} from 'ui-components';
import {useCallback, useContext, useEffect, useMemo, useRef} from 'react';
import {exists, HttpClientContext} from 'front-core';
import {funnelSmartSelectorNetworkRequest} from '../../../../http/smart-selector.network-requests';
import classes from './smart-selector.module.scss';
import {getFunnelNetworkRequest} from '../../../../http/funnels.network-requests';
import {FunnelPreview} from './components/previews/funnel-preview.component';
import {SmartSelectorLayout} from './smart-selector-layout.component';
import {
  CreatableSmartSelector,
  EditableSmartSelector,
  SmartSelectorSharedProps,
} from './smart-selector.shared';
import {ModelKey} from '../../../../constants/model-key';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../store/actions-listener/actions-listener.actions';
import {CoreActionsType} from '../../../../store/core/core.actions';
import {useDispatch} from 'react-redux';

interface OwnProps
  extends SmartSelectorSharedProps,
    CreatableSmartSelector,
    EditableSmartSelector {}

type AllProps = OwnProps;

export const FunnelSmartSelector = (props: AllProps) => {
  const {
    placeholder,
    value,
    onCreate: onCreateFromProps,
    onChange: onChangeFromProps,
    onEdit,
    filters,
    className,
    disabled,
    onSignalInfo,
    clearable,
    error,
    fullWidth,
    emptyState,
    addButton,
  } = props;
  const dispatch = useDispatch();
  const selectorRef = useRef<any>(null);
  const http = useContext(HttpClientContext);
  const {source: funnel, exec} = useRemoteSourceStated({
    type: 'source',
    networkRequest: getFunnelNetworkRequest,
    initialValue: null,
  });
  const selectedFunnel = useMemo(
    () => (value && funnel?.id === value ? funnel : null),
    [value, funnel]
  );
  const labelValue = useMemo(() => {
    if (value && selectedFunnel) {
      return selectedFunnel.name;
    }
  }, [value, selectedFunnel]);
  const onCreate = useCallback(() => {
    selectorRef.current.close();
    onCreateFromProps();
  }, [onCreateFromProps]);
  const onChange = useCallback(
    (value: number, item?: any) => {
      selectorRef.current.close();
      onChangeFromProps(value, item);
    },
    [onChangeFromProps]
  );
  const sources: SmartSelectorSource[] = useMemo(
    () => [
      {
        key: 'funnels',
        name: `Funnels`,
        onCreate: onCreateFromProps ? onCreate : undefined,
        createLabel: 'Create Funnel',
        onSelect: item => {
          onChange(item.id, item);
        },
        renderItemPreview: item => (
          <FunnelPreview label={'Funnel'} data={item} onSignalInfo={onSignalInfo} />
        ),
      },
    ],
    [onChange, onSignalInfo, onCreate, onCreateFromProps]
  );
  const load = useCallback(
    async (keys: string[], parameters: SmartSelectorParameters): Promise<MultiLoadResponse> => {
      // @ts-ignore
      const funnels = await http.exec(
        funnelSmartSelectorNetworkRequest({
          page: parameters.page,
          q: parameters.query,
          limit: 10,
          orderBy: 'id',
          order: 'asc',
          ...(filters || {}),
        })
      );

      return {
        funnels: funnels as any,
      };
    },
    [http, filters]
  );
  useEffect(() => {
    value && funnel?.id !== value && exec(value);
  }, [value, exec, funnel]);
  // listen for funnel update (after update)
  useEffect(() => {
    const listener = action => {
      if (action.payload.modelKey === ModelKey.FUNNEL && value === action.payload.data?.id) {
        exec(value);
      }
    };
    dispatch(registerActionListener(CoreActionsType.MODEL_UPDATED, listener));
    return () => {
      dispatch(removeActionListener(CoreActionsType.MODEL_UPDATED, listener));
    };
  }, [dispatch, value, exec]);

  return (
    <SmartSelectorLayout
      id={'funnel-selector'}
      label={labelValue}
      disabled={disabled}
      error={error}
      clearable={clearable && exists(value)}
      onClear={() => onChange(null)}
      onEdit={onEdit ? () => onEdit(funnel.id) : undefined}
      allowEdit={funnel?.id}
      placeholder={placeholder}
      className={className}
      ref={selectorRef}
      fullWidth={fullWidth}
      onCreate={onCreate}
      addButton={addButton}
      icon={funnel?.isAdHoc ? SingleUseIcon : undefined}
    >
      <SmartSelector
        className={classes.Selector}
        sources={sources}
        load={load}
        emptyState={emptyState}
      />
    </SmartSelectorLayout>
  );
};
