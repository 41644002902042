import {
  MultiLoadResponse,
  SmartSelectorParameters,
  SmartSelectorSource,
  SmartSelector,
  useRemoteSourceStated,
} from 'ui-components';
import {useCallback, useContext, useEffect, useMemo, useRef} from 'react';
import {exists, HttpClientContext} from 'front-core';
import {segmentSmartSelectorNetworkRequest} from '../../../../http/smart-selector.network-requests';
import classes from './smart-selector.module.scss';
import {SmartSelectorLayout} from './smart-selector-layout.component';
import {groupBy, isArray, isEmpty, isObject} from 'lodash';
import {CreatableSmartSelector, SmartSelectorSharedProps} from './smart-selector.shared';
import {getSegmentNetworkRequest} from '../../../../http/segments.network-requests';
import {getSegmentCategoriesNetworkRequest} from '../../../../http/segment-categories.network-requests';
import {useTeamFilter} from '../../../../core/contexts/team-filter.context';

interface OwnProps extends SmartSelectorSharedProps, Omit<CreatableSmartSelector, 'onCreate'> {
  excludeIds?: number[];
  filters?: any;
  onCreate?: (categoryId?: number) => void;
}

type AllProps = OwnProps;

const generateSegmentCategoryKey = (categoryId?: number | string) => {
  if (!exists(categoryId) || categoryId === 'null') {
    categoryId = 'general';
  }
  return `segment_category_${categoryId}`;
};

export const SegmentSmartSelector = (props: AllProps) => {
  const {
    placeholder,
    value,
    onChange: onChangeFromProps,
    onCreate: onCreateFromProps,
    filters: filtersFromProps,
    className,
    disabled,
    clearable,
    error,
    fullWidth,
    emptyState,
    excludeIds,
  } = props;
  const {source: segmentCategories, exec: getSegmentCategories} = useRemoteSourceStated({
    networkRequest: getSegmentCategoriesNetworkRequest,
  });
  const {teamId} = useTeamFilter();
  const selectorRef = useRef<any>(null);
  const http = useContext(HttpClientContext);
  const {source: segment, exec} = useRemoteSourceStated({
    type: 'source',
    networkRequest: getSegmentNetworkRequest,
    initialValue: null,
  });
  const selectedSegment = useMemo(
    () => (value && segment?.id === value ? segment : null),
    [value, segment]
  );
  const labelValue = useMemo(() => {
    if (value && selectedSegment) {
      return selectedSegment.name;
    }
  }, [value, selectedSegment]);
  const onChange = useCallback(
    (value: number, item?: any) => {
      selectorRef.current.close();
      onChangeFromProps(value, item);
    },
    [onChangeFromProps]
  );
  const onCreate = useCallback(
    (categoryId?: number) => {
      selectorRef.current.close();
      onCreateFromProps(categoryId);
    },
    [onCreateFromProps]
  );
  const filters = useMemo(() => {
    if (!filtersFromProps) {
      return undefined;
    }
    if (isArray(filtersFromProps) && filtersFromProps.length) {
      return filtersFromProps;
    } else if (isObject(filtersFromProps) && !isEmpty(filtersFromProps)) {
      return [filtersFromProps];
    }
    return undefined;
  }, [filtersFromProps]);
  const sources: SmartSelectorSource[] = useMemo(() => {
    if (!segmentCategories) {
      return [];
    }
    return [
      ...segmentCategories
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .map(category => ({
          key: generateSegmentCategoryKey(category.id),
          name: category.name,
          onSelect: item => onChange(item.id, item),
          onCreate: onCreate ? () => onCreate(category.id) : undefined,
          createLabel: `Create ${category.name} Segment`,
        })),
      {
        key: generateSegmentCategoryKey(),
        name: 'General Segments',
        onSelect: item => onChange(item.id, item),
        onCreate: onCreate ? () => onCreate() : undefined,
        createLabel: 'Create General Segment',
      },
    ];
  }, [onChange, onCreate, segmentCategories]);
  const load = useCallback(
    async (keys: string[], parameters: SmartSelectorParameters): Promise<MultiLoadResponse> => {
      // @ts-ignore
      const segments = await http.exec(
        segmentSmartSelectorNetworkRequest({
          ...(filters || {}),
          page: parameters.page,
          q: parameters.query,
          limit: 1000,
          excludeId: excludeIds,
          teamId,
        })
      );
      const res = {};
      const groups = groupBy(segments.data, 'categoryId');
      Object.keys(groups).forEach(key => {
        res[generateSegmentCategoryKey(key)] = {
          data: groups[key],
          total: groups[key].length,
          hasNext: false,
        };
      });
      return res;
    },
    [http, excludeIds, filters, teamId]
  );
  useEffect(() => {
    value && segment?.id !== value && exec(value);
  }, [value, exec, segment]);
  useEffect(() => {
    getSegmentCategories();
  }, [getSegmentCategories]);

  return (
    <SmartSelectorLayout
      id={'segment-selector'}
      label={labelValue}
      disabled={disabled}
      error={error}
      clearable={clearable && exists(value)}
      onClear={() => onChange(null)}
      placeholder={placeholder}
      className={className}
      ref={selectorRef}
      fullWidth={fullWidth}
      withPreview={false}
    >
      <SmartSelector
        className={classes.Selector}
        sources={sources}
        load={load}
        emptyState={emptyState}
        withPreview={false}
      />
    </SmartSelectorLayout>
  );
};
