import {UserRole} from '../../objects/models/user.model';
import {useCurrentUser} from './use-user.hook';
import {useSelector} from 'react-redux';
import {CORE_STORE_KEY} from '../../store/core/core.store';

export function useIsAdmin() {
  const user = useCurrentUser();
  const adminPrivileges = useSelector(state => state[CORE_STORE_KEY].adminPrivileges);
  return user?.role === UserRole.ADMIN && adminPrivileges;
}
