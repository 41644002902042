import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {
  CrumbNavItem,
  PageHeader,
} from '../../../shared/components/layout/page-header/page-header.component';
import TransKeys from '../../../../constants/translation-keys';
import {ANALYSIS_RESULT_ID_PATH_PARAM, AppRoutes} from '../../../../constants/app-routes';
import {AnalysisResult} from '../../../../objects/models/analysis-result.model';
import {AnalysisParameters} from '../analysis-parameters/analysis-parameters.component';
import {Parameter} from '../../../shared/components/general/parameter/parameter.component';
import {get} from 'lodash';
import {formatDate} from '../../../../utils/general.utils';
import {Phenomena} from '../../../../objects/models/phenomena.model';

interface OwnProps {
  phenomena: Phenomena;
  analysisResult: AnalysisResult;
}

type AllProps = OwnProps;

export const PhenomenaHeader = (props: AllProps) => {
  const {phenomena, analysisResult} = props;
  const {t} = useTranslation();
  const analysisTitle = useMemo(
    () => analysisResult?.userAnalysisName || analysisResult?.analysisName,
    [analysisResult]
  );
  const crumbs: CrumbNavItem[] = useMemo(() => {
    const arr = [
      {
        name: t(TransKeys.ANALYSES.HEADER.TITLE),
        navigateTo: AppRoutes.analyses(),
      },
    ];
    arr.push({
      name: analysisTitle,
      navigateTo: AppRoutes.viewAnalysis(analysisResult?.analysisId, {
        [ANALYSIS_RESULT_ID_PATH_PARAM]: analysisResult?.id,
      }),
    });
    return arr;
  }, [t, analysisTitle, analysisResult]);
  const startDate = useMemo(
    () => formatDate(get(analysisResult, 'runParameters.start_date')),
    [analysisResult]
  );
  const endDate = useMemo(
    () => formatDate(get(analysisResult, 'runParameters.end_date')),
    [analysisResult]
  );
  const dates = useMemo(() => {
    if (startDate && endDate) {
      return `${startDate} - ${endDate}`;
    }
    return startDate || endDate;
  }, [endDate, startDate]);

  return (
    <PageHeader
      title={phenomena.phenomenaSentence}
      crumbs={crumbs}
      subHeaderItems={[
        <Parameter label={t(TransKeys.GENERAL.LABELS.TYPE)} value={phenomena.typeName} />,
        <Parameter label={t(TransKeys.GENERAL.LABELS.TIME_FRAME)} value={dates} />,
        analysisResult && (
          <AnalysisParameters
            label={t(TransKeys.GENERAL.LABELS.PARAMETERS)}
            analysisResultId={analysisResult?.id}
            size={'large'}
            border={false}
          />
        ),
      ]}
    />
  );
};
