import {MetricSuggestions} from '../../../../../../modules/metrics/components/metric-suggestions/metric-suggestions.component';
import {ModelSeriesGranularity} from '../../../../../../objects/models/model-sample-series.model';
import {FancyBlock} from 'ui-components';
import {MetricPage as MetricPageModel} from '../../../../../../objects/models/metric-page.model';
import classes from './metric-suggestion.module.scss';
import {HomepageMetric} from '../../../../../../objects/models/homepage.model';

type MetricSuggestionViewerProps = {
  metric: MetricPageModel;
  granularity: ModelSeriesGranularity;
  onRequestAnalysis: (analysisTypeId: number, parameters: any) => void;
};

export const MetricSuggestionViewer = (props: MetricSuggestionViewerProps) => {
  const {metric, granularity, onRequestAnalysis} = props;

  return (
    <FancyBlock addPadding variant="highlighted" className={classes.Container}>
      {metric && (
        <MetricSuggestions
          metric={metric as unknown as HomepageMetric}
          experiments={[]}
          onRequestAnalysis={onRequestAnalysis}
          granularity={granularity}
        />
      )}
    </FancyBlock>
  );
};
