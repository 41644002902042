import classNames from 'classnames';
import classes from './analysis-card.module.scss';
import {Avatar, Button, LoopsIcon, TooltipIfOverflow} from 'ui-components';
import {useMemo} from 'react';
import moment from 'moment';
import {TIME_FORMATS} from '../../../../constants/time-formats';
import {withStopPropagation} from 'front-core';

interface OwnProps {
  name: string;
  userFullName: string;
  isInternalUser: boolean;
  analysisTypeName: string;
  goalName?: string;
  startDate: string;
  endDate?: string;
  hasPopulationFilter?: boolean;
  onClick?: () => void;
  className?: string;
}

type AllProps = OwnProps;

export const AnalysisCard = (props: AllProps) => {
  const {
    name,
    userFullName,
    analysisTypeName,
    goalName,
    isInternalUser,
    startDate,
    endDate,
    hasPopulationFilter,
    onClick,
    className,
  } = props;

  const timeframe = useMemo(() => {
    const start = moment.utc(startDate).format(TIME_FORMATS.READABLE_DATE);
    const end = endDate ? moment.utc(endDate).format(TIME_FORMATS.READABLE_DATE) : undefined;
    if (!end) {
      return start;
    }
    return `${start} - ${end}`;
  }, [startDate, endDate]);

  return (
    <div className={classNames(classes.AnalysisCard, className)} onClick={() => onClick()}>
      <div className={classes.Title}>{name}</div>
      <div className={classes.Content}>
        <div className={classes.Creator}>
          {!isInternalUser && (
            <>
              <Avatar name={userFullName} size={'small'} />
              <span>{userFullName}</span>
            </>
          )}
          {isInternalUser && (
            <>
              <LoopsIcon className={classes.LoopsIcon} />
              <span>Loops</span>
            </>
          )}
        </div>
        <div className={classes.Button}>
          <Button onClick={withStopPropagation(() => onClick())}>View Analysis</Button>
        </div>
      </div>
      <div className={classes.Infos}>
        <span className={classes.Info}>{analysisTypeName}</span>
        <TooltipIfOverflow title={goalName}>
          <span className={classNames(classes.Info, classes.GoalName)}>{goalName}</span>
        </TooltipIfOverflow>
        <span className={classes.Info}>{timeframe}</span>
        {hasPopulationFilter && <span className={classes.Info}>Population filtered</span>}
      </div>
    </div>
  );
};
