import classNames from 'classnames';
import classes from './model-category-badge.module.scss';
import {ModelCategory} from '../../../../../objects/system/model-category.enum';
import {
  DoorOpenDuotoneIcon,
  GolfFlagHoleDuotoneIcon,
  HandPointerDuotoneIcon,
  KeySkeletonDuotoneIcon,
  MagnetDuotoneIcon,
  ShoppingCartDuotoneIcon,
  SquareDollarDuotoneIcon,
  FiltersDoutoneIcon,
  ShapesDuotoneIcon,
} from 'ui-components';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {MouseEventHandler} from 'react';

interface OwnProps {
  category: ModelCategory;
  selected?: boolean;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const MODEL_CATEGORY_ICONS = {
  [ModelCategory.CONVERSION]: GolfFlagHoleDuotoneIcon,
  [ModelCategory.ENGAGEMENT]: HandPointerDuotoneIcon,
  [ModelCategory.MONETIZATION]: SquareDollarDuotoneIcon,
  [ModelCategory.RETENTION]: MagnetDuotoneIcon,
  [ModelCategory.ACTIVATION]: KeySkeletonDuotoneIcon,
  [ModelCategory.ACQUISITION]: ShoppingCartDuotoneIcon,
  [ModelCategory.CHURN]: DoorOpenDuotoneIcon,
  [ModelCategory.FUNNEL]: FiltersDoutoneIcon,
  [ModelCategory.FEATURE]: ShapesDuotoneIcon,
};

type AllProps = OwnProps;

export const ModelCategoryBadge = (props: AllProps) => {
  const {category, selected, disabled, onClick} = props;
  const {t} = useTranslation();
  const transKey = category.toUpperCase();
  const Icon = MODEL_CATEGORY_ICONS[category];

  return (
    <div
      onClick={onClick}
      className={classNames(
        classes.Container,
        onClick && classes.Clickable,
        disabled && classes.Disabled,
        selected && classes.Selected,
        classes[category]
      )}
    >
      <Icon className={classes.Icon} />
      <div className={classes.Label}>{t(TransKeys.QUESTIONNAIRE.KPIS_STEP.OPTIONS[transKey])}</div>
    </div>
  );
};
