import {ArrowRightIcon, IndexDisplay, ModalLayout, ModelType} from 'ui-components';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import classNames from 'classnames';
import TransKeys from 'translations';
import {WeeklyInterestsDTO} from '../../../../objects/dto/push-insights.dto';
import {createWeeklyInterests} from '../../../../store/push-insights/push-insights.actions';
import {addModelToHomepage} from '../../../../store/homepage/homepage.actions';
import classes from './weekly-interest-form-panel.module.scss';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import {InterestCategorySelectionStep} from './components/interest-category-selection-step.component';
import {InterestModelSelectionStep} from './components/interest-model-selection-step.component';
import {InterestFormCompletionStep} from './components/interest-from-completion-step.component';
import {ModelSampleSeriesModel} from '../../../../objects/models/model-sample-series.model.ts';
import {useProductData} from '../../../../core/hooks/use-product-data.hook.ts';

interface OwnProps {
  onClose: () => void;
}

type AllProps = OwnProps;

export interface StepProps {
  data: WeeklyInterestsDTO;
  onNextStep: (value: Partial<WeeklyInterestsDTO>) => void;
}

enum InterestWizardStep {
  CATEGORY_SELECTION = 'category-selection-step',
  MODEL_SELECTION = 'model-selection-step',
  FORM_COMPLETION = 'form-completion-step',
}

const STEPS = [
  {
    component: InterestCategorySelectionStep,
    key: InterestWizardStep.CATEGORY_SELECTION,
  },
  {
    component: InterestModelSelectionStep,
    key: InterestWizardStep.MODEL_SELECTION,
  },
  {
    component: InterestFormCompletionStep,
    key: InterestWizardStep.FORM_COMPLETION,
  },
];

const WeeklyInterestFormPanel = (props: AllProps) => {
  const {onClose} = props;
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const notify = useAmplitude();
  const [stepIndex, setStepIndex] = useState(0);
  const {userHomepageId} = useProductData();

  const [formData, setFormData] = useState<WeeklyInterestsDTO>({
    category: null,
    modelType: null,
    modelId: null,
    modelName: null,
    topic: null,
    other: null,
  });

  const {StepComponent, isLastFormStep, isFormCompleteStep, showBackButton, showStepper} =
    useMemo(() => {
      const StepComponent = STEPS[stepIndex].component;
      const isLastFormStep = STEPS[stepIndex].key === InterestWizardStep.MODEL_SELECTION;
      const isFormCompleteStep = STEPS[stepIndex].key === InterestWizardStep.FORM_COMPLETION;
      const isFirstStep = stepIndex === 0;
      const showBackButton = !isFirstStep && !isFormCompleteStep;
      const showStepper = !isFormCompleteStep;

      return {
        StepComponent,
        isLastFormStep,
        isFormCompleteStep,
        showBackButton,
        showStepper,
      };
    }, [stepIndex]);

  const onStepCompleted = useCallback(
    (data: any) => {
      const newData: WeeklyInterestsDTO = {...formData, ...data};
      setFormData(newData);
      setStepIndex(i => i + 1);

      if (isLastFormStep) {
        dispatch(createWeeklyInterests(newData));

        // Doing this here to also refresh the homepage afterwards
        const {modelId, modelType} = newData;
        if (modelType === ModelType.METRIC && modelId) {
          dispatch(
            addModelToHomepage({
              modelId,
              modelType: modelType as unknown as ModelSampleSeriesModel,
              homepageId: userHomepageId,
            })
          );
        }

        notify(AmplitudeEvent.WEEKLY_INTEREST_FORM_SUBMIT);
      }

      if (isFormCompleteStep) {
        onClose();
      }
    },
    [
      dispatch,
      notify,
      setStepIndex,
      formData,
      onClose,
      setFormData,
      isLastFormStep,
      isFormCompleteStep,
      userHomepageId,
    ]
  );

  useEffect(() => {
    notify(AmplitudeEvent.WEEKLY_INTEREST_FORM_OPENED);
  }, [notify]);

  return (
    <div className={classes.ModalContainer}>
      <ModalLayout>
        <div className={classes.Container}>
          <div className={classes.Stepper}>
            <div
              onClick={() => setStepIndex(i => i - 1)}
              className={classNames(classes.Back, !showBackButton && classes.Hide)}
            >
              <ArrowRightIcon className={classes.Arrow} /> {t(TransKeys.GENERAL.ACTIONS.BACK)}
            </div>
            {showStepper && <IndexDisplay current={stepIndex} total={STEPS.length} />}
          </div>
          <StepComponent key={stepIndex} data={formData} onNextStep={onStepCompleted} />
        </div>
      </ModalLayout>
    </div>
  );
};

export default WeeklyInterestFormPanel;
