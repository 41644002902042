import classNames from 'classnames';
import classes from './base-signal-builder.module.scss';
import {
  SignalSmartSelector,
  SignalSmartSelectorKey,
} from '../../smart-selector/signal-smart-selector.component';

interface SchemaKeysMapping {
  signal_id: string;
}

const DEFAULT_SCHEMA_KEYS_MAPPING: SchemaKeysMapping = {
  signal_id: 'signal_id',
};

interface OwnProps {
  onChange: (parameters: any, signal: any) => void;
  value: any;
  filters?: any;
  schemaKeysMapping?: SchemaKeysMapping;
  onSignalInfo?: (value: string | number) => void;
  errors?: any;
  include?: SignalSmartSelectorKey[];
  clearable?: boolean;
  disabled?: boolean;
  className?: string;
}

type AllProps = OwnProps;

const INCLUDE_SELECTOR = [
  SignalSmartSelectorKey.METRICS,
  SignalSmartSelectorKey.FUNNELS,
  SignalSmartSelectorKey.FEATURES,
  SignalSmartSelectorKey.CONTENTS,
  SignalSmartSelectorKey.SIGNALS,
];

export const BaseSignalBuilder = (props: AllProps) => {
  const {
    errors,
    onChange,
    onSignalInfo,
    filters,
    schemaKeysMapping = DEFAULT_SCHEMA_KEYS_MAPPING,
    value,
    clearable,
    disabled,
    include,
    className,
  } = props;

  return (
    <div className={classNames(classes.SimpleGoalSelector, className)}>
      <div className={classes.Row}>
        <div className={classes.Item}>
          <SignalSmartSelector
            placeholder={'Select'}
            onChange={(value, item) => onChange({[schemaKeysMapping.signal_id]: value}, item)}
            value={value?.[schemaKeysMapping.signal_id]}
            error={Boolean(errors?.[schemaKeysMapping.signal_id])}
            onSignalInfo={onSignalInfo}
            filters={filters}
            include={include || INCLUDE_SELECTOR}
            clearable={clearable}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};
