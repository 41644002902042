import {Content} from '../models/content.model';
import yup from '../../config/yup.config';
import httpClientService from '../../services/http-client.service';
import {validateNameNetworkRequest} from '../../http/validations.network-requests';
import {values} from 'lodash';
import {queryElementValidatorFactory} from './query-builder.dto';
import {TableEntity} from '../models/table.model';
import {SignalDataType} from '../models/signal.model';
import {nullableValues} from '../../utils/yup.utils';

export type ContentDTO = Partial<Content>;

export const contentDTOValidator = yup.object().shape({
  id: yup.number().optional().nullable(),
  name: yup
    .string()
    .required()
    .test('unique_name', 'Name already exists', async function (name: string) {
      const {id} = this.parent;
      try {
        await httpClientService.exec(
          validateNameNetworkRequest({
            name,
            existingId: id,
            modelType: 'content',
          })
        );
        return true;
      } catch (e) {
        return false;
      }
    }),
  shortDescription: yup.string().nullable(),
  entity: yup.string().oneOf(values(TableEntity)).required(),
  // signals
  usageSignalId: yup.number().optional().nullable(),
  usageSignalDataType: yup.string().oneOf(Object.values(SignalDataType)).required(),
  usageSignalDefinition: queryElementValidatorFactory(),
  activationSignalId: yup.number().optional().nullable(),
  activationSignalDataType: yup.mixed().oneOf(nullableValues(SignalDataType)),
  activationSignalDefinition: queryElementValidatorFactory(false),
  exposureSignalId: yup.number().optional().nullable(),
  exposureSignalDataType: yup.mixed().oneOf(nullableValues(SignalDataType)),
  exposureSignalDefinition: queryElementValidatorFactory(false),
  intentSignalId: yup.number().optional().nullable(),
  intentSignalDataType: yup.mixed().oneOf(nullableValues(SignalDataType)),
  intentSignalDefinition: queryElementValidatorFactory(false),
  churnSignalId: yup.number().optional().nullable(),
  churnSignalDataType: yup.mixed().oneOf(nullableValues(SignalDataType)),
  churnSignalDefinition: queryElementValidatorFactory(false),
});
