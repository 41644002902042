import {NRC} from './index';
import {RequestType, toCamelCase, toSnakeCase} from 'front-core';
import {KPIGraphWidgetDTO} from '../objects/dto/widgets.dto';

export const getAIChartThreadsNetworkRequest: NRC<void, KPIGraphWidgetDTO> = (
  query: KPIGraphWidgetDTO
) => ({
  method: RequestType.GET,
  relativeUrl: `/app/widgets/kpi-graph`,
  query: query,
  queryTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});
