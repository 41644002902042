import classNames from 'classnames';
import classes from './left-navigation.module.scss';
import {BetterNavLink} from '../../../core/override/better-nav-link.component';
import {ReactNode} from 'react';

interface NavProps {
  className?: string;
  children?: ReactNode;
}

interface NavLinkProps extends NavProps {
  to: string;
  chip?: string;
}

export const Nav = (props: NavProps) => {
  const {className, children} = props;
  return <div className={classNames(className, classes.Navigator)}>{children}</div>;
};

export const NavTitle = (props: NavProps) => {
  const {className, children} = props;
  return <div className={classNames(className, classes.Title)}>{children}</div>;
};

export const NavSubtitle = (props: NavProps) => {
  const {className, children} = props;
  return <div className={classNames(className, classes.Subtitle)}>{children}</div>;
};

export const NavList = (props: NavProps) => {
  const {className, children} = props;
  return <div className={classNames(className, classes.List)}>{children}</div>;
};

export const NavLink = (props: NavLinkProps) => {
  const {className, children, to, chip} = props;

  return (
    <BetterNavLink
      className={classNames(className, classes.Link)}
      activeClassName={classes.Active}
      to={to}
    >
      {children}
      {chip && <div className={classes.Chip}>{chip}</div>}
    </BetterNavLink>
  );
};
