import classes from './analyses-list-placeholder.module.scss';
import classNames from 'classnames';
import {SkeletonLoading} from 'ui-components';
import {range} from 'lodash';

interface OwnProps {
  className?: string;
  count?: number;
}

type AllProps = OwnProps;

export const AnalysesListPlaceholder = (props: AllProps) => {
  const {count = 5, className} = props;

  return (
    <div className={classNames(classes.AnalysesListPlaceholder, className)}>
      {range(count).map(i => (
        <SkeletonLoading key={i} className={classes.TableRowPlaceholder} />
      ))}
    </div>
  );
};
