import {snakeCase} from 'snake-case';
import {convertDateToUTC, removeUndefinedKeys} from 'front-core';
import {toSnakeCase} from 'front-core';
import {isArray, keys} from 'lodash';
import {pop} from '../../utils/general.utils';

const fixOrderBy = (orderBy: string | string[]) => {
  if (!orderBy) {
    return undefined;
  }
  if (typeof orderBy === 'string') {
    return snakeCase(orderBy);
  }
  if (isArray(orderBy)) {
    return orderBy.map(o => snakeCase(o));
  }
};

export const toFiltersDTO = (filters: any = {}) => {
  const filtersDTO = {...filters};

  if ('dates' in filtersDTO) {
    filtersDTO['fromDate'] = filtersDTO.dates.from
      ? convertDateToUTC(filters['dates']?.from).toISOString()
      : undefined;
    filtersDTO['toDate'] = filtersDTO.dates.to
      ? convertDateToUTC(filters['dates']?.to).toISOString()
      : undefined;
    delete filtersDTO.dates;
  }

  return removeUndefinedKeys({
    ...toSnakeCase(filtersDTO),
    order_by: fixOrderBy(filters?.orderBy),
  });
};

export const toGrowthMapFilters = (query: any = {}) => {
  const queryDTO = toFiltersDTO(query);
  const newQ = {
    ...queryDTO,
    from_date: pop(queryDTO, 'from_date'),
    to_date: pop(queryDTO, 'to_date'),
    query_filters: {
      segments: {},
    },
  };
  for (const key of keys(query)) {
    if (key.includes('segment_')) {
      const segmentId = Number(key.replace('segment_', ''));
      newQ.query_filters.segments[segmentId] = query[key];
      delete newQ[key];
    }
  }
  return newQ;
};
