import {AnalysisType} from '../../../../../../objects/models/analysis-type.model';
import {useCallback, useMemo} from 'react';
import classes from '../analysis-types-follow-up-panel.module.scss';
import AnalysisActionBox from '../../../../../analyses/components/analysis-action-box/analysis-action-box.component';
import {useFormContext} from 'react-hook-form';
import {AnalysisTypeId} from '../../../../../../constants/analysis-type-id';

interface BoxConfig {
  description: string;
  icon: JSX.Element;
}
interface AnalysisTypeBoxesConfig {
  [key: number]: BoxConfig;
}

interface OwnProps {
  defaultFollowUpAnalysesTypeIds: AnalysisTypeId[];
  analysisTypeBoxConfig: AnalysisTypeBoxesConfig;
  analysisTypes: {data: AnalysisType[]};
}

type AllProps = OwnProps;

const AnalysisTypesFollowUpPanelBody = (props: AllProps) => {
  const {
    defaultFollowUpAnalysesTypeIds: DEFAULT_FOLLOW_UP_ANALYSES_TYPE_IDS,
    analysisTypeBoxConfig,
    analysisTypes,
  } = props;
  const {watch, setValue} = useFormContext();
  const followUpAnalysesTypeIds = watch('analysisTypeIds');

  const followUpAnalysesTypeIdsSet = useMemo(
    () => new Set(followUpAnalysesTypeIds),
    [followUpAnalysesTypeIds]
  );

  const handleBoxClick = useCallback(
    (analysisTypeId: number) => {
      const set = new Set(followUpAnalysesTypeIds);

      if (set.has(analysisTypeId)) {
        set.delete(analysisTypeId);
      } else {
        set.add(analysisTypeId);
      }
      setValue('analysisTypeIds', Array.from(set));
    },
    [followUpAnalysesTypeIds, setValue]
  );

  const followUpBoxes = useMemo(() => {
    const boxesConfig = {...analysisTypeBoxConfig};

    const boxes = [];
    for (const analysisTypeId of DEFAULT_FOLLOW_UP_ANALYSES_TYPE_IDS) {
      const analysisType = analysisTypes.data.find(at => at.id === analysisTypeId);
      if (Boolean(analysisType) && analysisTypeId in boxesConfig) {
        const box = {
          ...boxesConfig[analysisType.id],
          selected: followUpAnalysesTypeIdsSet.has(analysisType.id),
          onClick: () => handleBoxClick(analysisType.id),
          isCausal: analysisType.isCausal,
          name: analysisType.staticName,
        };
        boxes.push(box);
      }
    }
    return boxes;
  }, [
    analysisTypeBoxConfig,
    DEFAULT_FOLLOW_UP_ANALYSES_TYPE_IDS,
    analysisTypes,
    followUpAnalysesTypeIdsSet,
    handleBoxClick,
  ]);

  return (
    <div className={classes.Body}>
      {followUpBoxes.map(box => (
        <AnalysisActionBox key={`${box.name}`} {...box} />
      ))}
    </div>
  );
};

export default AnalysisTypesFollowUpPanelBody;
