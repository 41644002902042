import {CircleInfoRegularIcon} from 'ui-components';
import classes from '../table-form-panel.module.scss';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../../constants/translation-keys';
import {StandardCheckBox} from '../../../../shared/components/general/standard-check-box/standard-check-box.component';
import classNames from 'classnames';
import {Tooltip} from '@material-ui/core';

interface OwnProps {
  value: any;
  onChange: (isMain: boolean) => void;
  disabled?: boolean;
}

type AllProps = OwnProps;

const TableIsMainField = (props: AllProps) => {
  const {value, onChange, disabled} = props;
  const {t} = useTranslation();

  return (
    <span
      className={classNames(classes.MainEntityTableCheckboxWrapper, disabled && classes.Disabled)}
    >
      <StandardCheckBox checked={value} onChange={onChange} disabled={disabled} />
      {t(TransKeys.TABLE_FORM.INPUTS.IS_MAIN_ENTITY_TABLE.LABEL)}
      <Tooltip
        title={t(TransKeys.TABLE_FORM.INPUTS.IS_MAIN_ENTITY_TABLE.HELPER_TEXT)}
        placement={'top'}
      >
        <CircleInfoRegularIcon className={classes.HelperTextIcon} />
      </Tooltip>
    </span>
  );
};

export default TableIsMainField;
