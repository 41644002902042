import {ReactNode, useMemo} from 'react';
import {AppRoutes, LocationDescriptorObject} from '../../../../../constants/app-routes';
import {ImagesResource} from '../../../../../assets/images';
import {AppNavigatorLinkItem} from '../app-navigator.component';
import AppNavigatorLink from './app-navigator-link.component';
import classes from '../app-navigator.module.scss';
import classNames from 'classnames';
import {CollapseMenuIcon} from 'ui-components';
import {BetterNavLink} from '../../../../shared/core/override/better-nav-link.component.tsx';

interface OwnProps {
  links: AppNavigatorLinkItem[];
  renderBottom: () => ReactNode;
  rootLink?: () => LocationDescriptorObject;
  isCollapsed: boolean;
  toggleCollapse: () => void;
}

const AppNavigatorLayout = (props: OwnProps) => {
  const {links, isCollapsed, renderBottom, rootLink: rootLinkFromProps, toggleCollapse} = props;

  const rootLink = useMemo(
    () => (rootLinkFromProps && rootLinkFromProps()) || AppRoutes.root(),
    [rootLinkFromProps]
  );

  return (
    <div className={classNames(classes.AppNavigator, isCollapsed && classes.Collapsed)}>
      <div className={classes.Header}>
        <BetterNavLink className={classes.LogoLink} to={rootLink}>
          <img className={classes.ShortLogoImage} src={ImagesResource.app.miniLogoWhite} alt={''} />
          <img className={classes.FullLogoImage} src={ImagesResource.app.logoWhite} alt={''} />
        </BetterNavLink>
        <CollapseMenuIcon className={classes.CollapseIcon} onClick={toggleCollapse} />
      </div>
      <div className={classes.Links}>
        {links.map((l, idx) => (
          <AppNavigatorLink {...l} key={idx} collapsed={isCollapsed} />
        ))}
      </div>
      <div className={classes.Bottom}>{renderBottom()}</div>
    </div>
  );
};

export default AppNavigatorLayout;
