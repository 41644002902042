import {createContext, useMemo, useState} from 'react';
import {ConfirmationMessage} from '../../objects/system/confirmation-message.model';

interface ContextConfirmationMessage extends ConfirmationMessage {
  onApprove?: () => void;
  onReject?: () => void;
}

interface IConfirmationDialogContext {
  confirmationDialog: ContextConfirmationMessage;
  setConfirmationDialog: (confirmationDialog: ContextConfirmationMessage) => void;
}

interface OwnProps {
  children: any;
}

export const ConfirmationDialogContext = createContext<IConfirmationDialogContext>({
  confirmationDialog: undefined,
  setConfirmationDialog: undefined,
});

export const ConfirmationDialogContextProvider = (props: OwnProps) => {
  const {children} = props;

  const [confirmationDialog, setConfirmationDialog] = useState<ContextConfirmationMessage>();

  const contextValue = useMemo(
    () => ({confirmationDialog, setConfirmationDialog}),
    [confirmationDialog]
  );

  return (
    <ConfirmationDialogContext.Provider value={contextValue}>
      {children}
    </ConfirmationDialogContext.Provider>
  );
};
