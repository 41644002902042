import classNames from 'classnames';
import classes from './metric-suggestions.module.scss';
import {HomepageMetric} from '../../../../objects/models/homepage.model';
import {ArrowRightThinIcon, WandMagicSparklesSolidIcon} from 'ui-components';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import Carousel from '@brainhubeu/react-carousel';
import {useCallback, useMemo, useState} from 'react';
import {MetricSuggestionsFactoryUtils, SuggestionAction} from './metric-suggestions-factory.utils';
import {useNavigate} from 'react-router';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {ModelSeriesGranularity} from '../../../../objects/models/model-sample-series.model';
import {Experiment} from '../../../../objects/models/experiment.model';

interface OwnProps {
  metric: HomepageMetric;
  granularity: ModelSeriesGranularity;
  experiments: Partial<Experiment>[];
  onRequestAnalysis: (analysisTypeId: number, parameters: any) => void;
  className?: string;
}

type AllProps = OwnProps;

export const MetricSuggestions = (props: AllProps) => {
  const {metric, granularity, experiments, onRequestAnalysis, className} = props;
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {productEntitiesMap} = useProductData();
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const metricSuggestions = useMemo(
    () =>
      MetricSuggestionsFactoryUtils.getMetricSuggestions(
        metric,
        granularity,
        experiments,
        productEntitiesMap
      ),
    [metric, experiments, productEntitiesMap, granularity]
  );
  const onSuggestionsClicked = useCallback(
    (suggestion: SuggestionAction) => {
      if ('url' in suggestion) {
        navigate(suggestion.url);
        return;
      }
      if ('analysisTypeId' in suggestion) {
        onRequestAnalysis(suggestion.analysisTypeId, suggestion.parameters);
        return;
      }
    },
    [navigate, onRequestAnalysis]
  );

  return (
    <div className={classNames(classes.MetricSuggestions, className)}>
      <div className={classes.TitleWrapper}>
        <div className={classes.Title}>
          <WandMagicSparklesSolidIcon />
          {t(TransKeys.HOMEPAGE.METRIC_SUGGESTIONS.TITLE, {
            name: metric.name,
          })}
        </div>
      </div>

      <div className={classes.Main}>
        <Carousel value={activeSuggestionIndex} onChange={setActiveSuggestionIndex}>
          {metricSuggestions.map((s, idx) => (
            <div className={classes.Suggestion} key={idx}>
              <div className={classes.SuggestionTitle}>
                {t(s.transKey, s.transParams) as string}
              </div>
              <div className={classes.Button} onClick={() => onSuggestionsClicked(s.action)}>
                {t(s.buttonText)}
              </div>
            </div>
          ))}
        </Carousel>
      </div>
      <div className={classes.Controls}>
        <div
          onClick={
            activeSuggestionIndex > 0
              ? () => setActiveSuggestionIndex(activeSuggestionIndex - 1)
              : undefined
          }
          className={classNames(classes.Prev, activeSuggestionIndex === 0 && classes.Disabled)}
        >
          <ArrowRightThinIcon className={classes.Icon} />
          {t(TransKeys.HOMEPAGE.METRIC_SUGGESTIONS.ACTIONS.PREV_SUGGESTION)}
        </div>
        <div className={classes.Dots}>
          {metricSuggestions.map((s, idx) => (
            <div
              key={idx}
              onClick={() => setActiveSuggestionIndex(idx)}
              className={classNames(classes.Dot, activeSuggestionIndex === idx && classes.Active)}
            />
          ))}
        </div>
        <div
          onClick={
            activeSuggestionIndex < metricSuggestions.length - 1
              ? () => setActiveSuggestionIndex(activeSuggestionIndex + 1)
              : undefined
          }
          className={classNames(
            classes.Next,
            activeSuggestionIndex === metricSuggestions.length - 1 && classes.Disabled
          )}
        >
          {t(TransKeys.HOMEPAGE.METRIC_SUGGESTIONS.ACTIONS.NEXT_SUGGESTION)}
          <ArrowRightThinIcon className={classes.Icon} />
        </div>
      </div>
    </div>
  );
};
