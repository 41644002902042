import {useMemo} from 'react';
import {AddIcon, DisplayButton} from 'ui-components';
import {Tooltip} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {getMilestoneTypeName} from '../../../../../../constants/options';
import {
  MilestoneType,
  EDITABLE_MILESTONE_TYPES,
} from '../../../../../../objects/models/milestone.model';
import {useProductData} from '../../../../../../core/hooks/use-product-data.hook';
import {TableEntity} from '../../../../../../objects/models/table.model';

interface OwnProps {
  milestoneType: MilestoneType;
  entityContext: TableEntity;
  milestoneId?: number;
  onCreate?: () => void;
  disabled?: boolean;
  error?: boolean;
}

type AllProps = OwnProps;

export const MilestoneInput = (props: AllProps) => {
  const {milestoneId, milestoneType, entityContext, onCreate, disabled, error} = props;
  const {t} = useTranslation();
  const {getEntityName} = useProductData();

  const entityName = getEntityName(entityContext);
  const milestoneTypeName = getMilestoneTypeName(milestoneType);

  const isDefined = milestoneId !== undefined;
  const isUnavailable = !EDITABLE_MILESTONE_TYPES.includes(milestoneType);

  const createable = !isDefined && !isUnavailable && onCreate;

  const tooltipTitle = useMemo(() => {
    if (isDefined) {
      return t(TransKeys.MILESTONE_INPUT.TOOLTIP.TYPE_DEFINED, {
        milestoneTypeName,
        entityName,
      });
    }
    if (isUnavailable) {
      return t(TransKeys.MILESTONE_INPUT.TOOLTIP.TYPE_UNAVAILABLE, {
        milestoneTypeName,
        entityName,
      });
    }
    return t(TransKeys.MILESTONE_INPUT.TOOLTIP.TYPE_UNDEFINED, {
      milestoneTypeName,
      entityName,
    });
  }, [t, milestoneTypeName, entityName, isDefined, isUnavailable]);

  return (
    <Tooltip placement={'top'} title={tooltipTitle}>
      <div>
        <DisplayButton
          label={milestoneId && milestoneTypeName}
          placeholder={milestoneTypeName}
          disabled={disabled}
          error={error}
          onClick={createable ? onCreate : undefined}
          icon={AddIcon}
        />
      </div>
    </Tooltip>
  );
};
