import {useCallback, useMemo, useState} from 'react';
import {ModelCategory} from '../../../../../objects/system/model-category.enum';
import {StepProps} from '../weekly-interest-form-panel.component';
import ModelCategoriesInput from '../../../../shared/form/components/model-categories-input/model-categories-input.component';
import classes from './steps.module.scss';
import {Button, ModelType} from 'ui-components';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';

const ALLOWED_MODEL_CATEGORIES = [
  ModelCategory.RETENTION,
  ModelCategory.CONVERSION,
  ModelCategory.ACTIVATION,
  ModelCategory.CHURN,
  ModelCategory.FUNNEL,
  ModelCategory.FEATURE,
];

const MODEL_CATEGORY_TYPE_MAP = {
  [ModelCategory.FUNNEL]: ModelType.FUNNEL,
  [ModelCategory.FEATURE]: ModelType.FEATURE,
  [ModelCategory.CONVERSION]: ModelType.METRIC,
  [ModelCategory.ACTIVATION]: ModelType.METRIC,
  [ModelCategory.CHURN]: ModelType.METRIC,
  [ModelCategory.RETENTION]: ModelType.METRIC,
  [ModelCategory.ENGAGEMENT]: ModelType.METRIC,
};

export const InterestCategorySelectionStep = (props: StepProps) => {
  const {onNextStep} = props;
  const {t} = useTranslation();
  const [categories, setCategories] = useState<ModelCategory[]>([]);

  const onModelCategoryChange = useCallback(
    (categories: ModelCategory[]) => setCategories(categories),
    [setCategories]
  );

  const onNext = useCallback(() => {
    const category = categories[0];
    const modelType = MODEL_CATEGORY_TYPE_MAP[category];
    onNextStep({category, modelType});
  }, [categories, onNextStep]);

  const isDisabled = useMemo(() => categories.length === 0, [categories]);

  return (
    <>
      <div className={classes.Title}>
        {t(TransKeys.WEEKLY_INTEREST_FORM_PANEL.STEP_1_CATEGORY.TITLE)}
      </div>
      <div className={classes.Description}>
        {t(TransKeys.WEEKLY_INTEREST_FORM_PANEL.STEP_1_CATEGORY.DESCRIPTION)}
      </div>
      <div className={classes.Body}>
        <ModelCategoriesInput
          options={ALLOWED_MODEL_CATEGORIES}
          maxSelections={1}
          values={categories}
          onChange={onModelCategoryChange}
        />
      </div>
      <div className={classes.Footer}>
        <Button className={classes.Button} onClick={onNext} disabled={isDisabled}>
          {t(TransKeys.GENERAL.ACTIONS.NEXT)}
        </Button>
      </div>
    </>
  );
};
