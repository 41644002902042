import {useCallback, useContext} from 'react';
import {InteractionContext, InteractionType, Sentence, SentenceProps} from 'ui-components';

interface AllProps extends SentenceProps {
  disabled?: boolean;
}

export const AppSentence = (props: AllProps) => {
  const {disabled} = props;
  const {postMessage} = useContext(InteractionContext);

  const onPartClicked = useCallback(
    part => {
      if (part.name === 'REF') {
        postMessage({
          type: InteractionType.REFERENCE,
          payload: {
            modelType: part.type,
            modelId: part.parameters[0],
          },
        });
      }
    },
    [postMessage]
  );

  return <Sentence {...props} onClick={disabled ? undefined : onPartClicked} />;
};
