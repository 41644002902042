import {useCallback, useMemo} from 'react';
import {BaseSignalBuilder} from './base-signal-builder.component';
import {cloneDeep, get, keys, set} from 'lodash';
import {exists} from 'front-core';
import {QueryBuilderFactory} from 'ui-components';
import {SIGNAL_COLUMN_ANALYSIS_PARAMETER_MAPPING} from '../query-builders.config';
import {
  METADATA_KEY,
  PARAMETERS_METADATA_KEY,
} from '../../../../../constants/parameters-saved-keys';
import {SignalSmartSelectorKey} from '../../smart-selector/signal-smart-selector.component';

interface OwnProps {
  query: any;
  onChange?: (parameters: any, signal?: any) => void;
  filters?: any;
  include?: SignalSmartSelectorKey[];
  onSignalInfo?: (value: string | number) => void;
  errors?: any;
  disabled?: boolean;
  className?: string;
}

type AllProps = OwnProps;

const mapper = (value: any) => ({
  signal_id: get(value, SIGNAL_COLUMN_ANALYSIS_PARAMETER_MAPPING.signal_id),
});

export const SIGNAL_QUERY_BUILDER_NAME = 'SignalQueryBuilder';

export const SignalQueryBuilder = (props: AllProps) => {
  const {
    errors,
    filters,
    onChange: onChangeFromProps,
    onSignalInfo,
    query,
    include,
    disabled,
    className,
  } = props;

  const mappedValue = useMemo(() => mapper(query), [query]);
  const mappedErrors = useMemo(() => mapper(errors), [errors]);
  const onChange = useCallback(
    (changes, item) => {
      let newValue = cloneDeep(query);
      if (!exists(newValue)) {
        newValue = QueryBuilderFactory.createSignalColumn();
      }
      for (const k of keys(changes)) {
        set(newValue, SIGNAL_COLUMN_ANALYSIS_PARAMETER_MAPPING[k], changes[k]);
      }
      newValue[PARAMETERS_METADATA_KEY] = {
        [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: SIGNAL_QUERY_BUILDER_NAME,
      };
      onChangeFromProps(newValue, item);
    },
    [onChangeFromProps, query]
  );

  return (
    <BaseSignalBuilder
      errors={mappedErrors}
      onChange={onChange}
      onSignalInfo={onSignalInfo}
      include={include}
      filters={filters}
      value={mappedValue}
      className={className}
      disabled={disabled}
    />
  );
};
