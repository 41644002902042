import {Tooltip} from '@material-ui/core';
import {TeamDTO} from '../../../../../../objects/dto/team.dto';
import {UserGroupLightIcon} from 'ui-components';
import classes from './team-members-cell.module.scss';
import {getMemberFullNameOrEmail} from '../../../../member.utils.ts';

type TeamMembersCellProps = {team: TeamDTO};

export const TeamMembersCell = (props: TeamMembersCellProps) => {
  const {team} = props;

  return (
    <Tooltip
      title={<TeamMembersTooltipContent users={team.users} />}
      disableHoverListener={team.users.length === 0}
    >
      <div className={classes.TeamMembersCell}>
        <UserGroupLightIcon />
        <span>{team.users.length}</span>
      </div>
    </Tooltip>
  );
};

const TeamMembersTooltipContent = (props: {users: TeamDTO['users']}) => {
  const {users} = props;
  return (
    <>
      {users.map(user => (
        <div key={user.id}>{getMemberFullNameOrEmail(user)}</div>
      ))}
    </>
  );
};
