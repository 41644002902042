import {
  Experiment,
  ExperimentStatus,
} from '../../../../../../../../../objects/models/experiment.model';
import classes from './experiment-progress-cell.module.scss';
import moment from 'moment';
import {useMemo} from 'react';
import {Progress, TooltipIfOverflow} from 'ui-components';
import {ListRowItemCell} from '../../../../../../../../shared/components/general/list-row-item-cell/list-row-item-cell.component';
import classNames from 'classnames';
import {TIME_FORMATS} from '../../../../../../../../../constants/time-formats';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';

type ExperimentProgressCellProps = {
  experiment: Experiment;
  className?: string;
};

export const ExperimentProgressCell = (props: ExperimentProgressCellProps) => {
  const {experiment, className} = props;

  const {t} = useTranslation();

  const {percentage, remainingDaysLabel, dateRangeLabel} = useMemo(() => {
    const now = moment.utc();
    const start = moment.utc(experiment.startedOn);
    const maxEndDate =
      experiment.endedOn ?? experiment.lastCompletedAnalysisResult?.outputs?.otherData?.maxEndDate;
    const end = maxEndDate ? moment.utc(maxEndDate) : undefined;

    const remainingDuration =
      end && experiment.status === ExperimentStatus.IN_PROGRESS
        ? moment.duration(Math.max(end.diff(now), 0))
        : null;

    const remainingDays = Math.round(remainingDuration?.asDays());

    const totalDuration = end ? moment.duration(end.diff(start)) : undefined;
    const totalDays = totalDuration?.asDays();

    let percentage = ((totalDays - remainingDays) / totalDays) * 100;

    let dateRangeLabel = start ? `${start.format(TIME_FORMATS.READABLE_DATE)} - ` : undefined;

    if (experiment.status === ExperimentStatus.DONE) {
      percentage = 100;
    }

    // put a default of 0 if percentage is NaN
    percentage = percentage ? percentage : 0;

    dateRangeLabel += end ? end.format(TIME_FORMATS.READABLE_DATE) : 'ongoing';
    const remainingDaysLabel = end?.isBefore(moment.utc())
      ? 'Past due'
      : remainingDays
        ? `${remainingDays}d remaining`
        : undefined;

    return {percentage, remainingDaysLabel, dateRangeLabel};
  }, [experiment]);

  return (
    <ListRowItemCell
      title={t(TransKeys.EXPERIMENTS.PROGRESS_CELL.TITLE)}
      className={classNames(classes.ProgressCell, className)}
      content={
        <div className={classes.ProgressCellContent}>
          <div className={classes.ProgressBarContainer}>
            <div className={classes.ProgressBarFullWidth}>
              <Progress value={percentage} />
            </div>
          </div>
          <div className={classes.Dates}>
            <TooltipIfOverflow title={dateRangeLabel}>
              <div className={classes.DateRange}>{dateRangeLabel}</div>
            </TooltipIfOverflow>
            <TooltipIfOverflow title={remainingDaysLabel}>
              <div className={classes.RemainingDays}>{remainingDaysLabel}</div>
            </TooltipIfOverflow>
          </div>
        </div>
      }
    />
  );
};
