import classNames from 'classnames';
import {TreeMode} from '../../kpi-tree.consts';
import {
  Button,
  DatePickerInput,
  EditIcon,
  PlusLightIcon,
  SplitIconLight,
  PopoverWrapper,
} from 'ui-components';
import {MetricSelector} from '../../../metric-selector/metric-selector.component';
import classes from './kpi-tree-header.module.scss';
import {useCallback, useRef} from 'react';
import {Metric} from '../../../../../../objects/models/metric.model';
import {TIME_FORMATS} from '../../../../../../constants/time-formats';
import {createStartDateComparingProps, dateToLabel} from '../../../../../../utils/dates.utils';
import {TeamTreeSelector} from '../tree-team-selector/tree-team-selector.component';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';

interface OwnProps {
  mode: TreeMode;
  setMode: (mode: TreeMode) => void;
  onAutoLayout: () => void;
  onAddKPI: (metric: Metric) => void;
  excludeMetricIds?: number[];
  addDisabled?: boolean;
  date: string;
  setDate: (date: string) => void;
  teamId: number | null;
  onChangeTeam: (teamId: number | null) => void;
  disabled?: boolean;
  className?: string;
}

type AllProps = OwnProps;

const MAX_DATE = new Date();

const datePickerProps = createStartDateComparingProps('week');

export const KPITreeHeader = (props: AllProps) => {
  const {
    mode,
    setMode,
    onAutoLayout,
    onAddKPI: onAddKPIFromProps,
    date,
    setDate,
    addDisabled,
    disabled,
    excludeMetricIds,
    teamId,
    onChangeTeam,
    className,
  } = props;
  const {t} = useTranslation();
  const selectorRef = useRef<any>(null);
  const dateValue = useCallback((value: string) => dateToLabel(value, 'week'), []);

  const onAddKPI = useCallback(
    (metric: Metric) => {
      selectorRef.current?.close();
      onAddKPIFromProps(metric);
    },
    [onAddKPIFromProps]
  );

  return (
    <div className={classNames(classes.KPITreeHeader, className)}>
      <div className={classes.Main}>
        <TeamTreeSelector teamId={teamId} onChangeTeam={onChangeTeam} />
        <DatePickerInput
          {...datePickerProps}
          className={classes.DatePickerInput}
          onChange={setDate}
          value={date}
          maxDate={MAX_DATE}
          renderValue={dateValue}
          disabled={disabled || mode === TreeMode.EDIT}
          dateInputFormat={TIME_FORMATS.PARAMETER_DATE_FORMAT}
          utc
        />
      </div>
      <div className={classes.Actions}>
        {mode === TreeMode.EDIT && (
          <div className={classes.EditActions}>
            <Button
              onClick={() => onAutoLayout()}
              variant={'outlined'}
              icon={SplitIconLight}
              className={classes.Button}
            >
              {t(TransKeys.KPI_TREE.ACTIONS.AUTO_LAYOUT)}
            </Button>
            <PopoverWrapper
              ref={selectorRef}
              buttonRenderer={({onClick}) => (
                <Button
                  onClick={onClick}
                  variant={'outlined'}
                  disabled={addDisabled}
                  icon={PlusLightIcon}
                  className={classes.Button}
                >
                  {t(TransKeys.KPI_TREE.ACTIONS.ADD_KPI)}
                </Button>
              )}
            >
              <MetricSelector
                onChange={(metricId, metric) => onAddKPI(metric)}
                excludeIds={excludeMetricIds}
              />
            </PopoverWrapper>
            <div className={classes.Separator} />
          </div>
        )}
        {mode === TreeMode.VIEW && (
          <Button
            disabled={disabled}
            onClick={() => setMode(TreeMode.EDIT)}
            icon={EditIcon}
            className={classes.Button}
          >
            {t(TransKeys.KPI_TREE.ACTIONS.EDIT)}
          </Button>
        )}
        {mode === TreeMode.EDIT && (
          <Button onClick={() => setMode(TreeMode.VIEW)} className={classes.Button}>
            {t(TransKeys.GENERAL.ACTIONS.DONE)}
          </Button>
        )}
      </div>
    </div>
  );
};
