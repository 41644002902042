import classes from './layout.module.scss';
import {ReactNode} from 'react';

interface OwnProps {
  className?: string;
  children?: ReactNode;
}

type AllProps = OwnProps;

export const Layout = (props: AllProps) => {
  const {children} = props;

  return <div className={classes.Layout}>{children}</div>;
};
