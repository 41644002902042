import classNames from 'classnames';
import classes from './homepage-model-viewer-header.module.scss';
import {
  ArrowUpRightFromSquareRegularIcon,
  Button,
  TrendChip,
  UsersIcon,
  WandMagicSparklesSolidIcon,
} from 'ui-components';
import TransKeys from 'translations';
import {GoalStatus} from '../../../goal-status/goal-status.component';
import {Tooltip} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {
  AnomalyMode,
  HomepageMetric,
  HomepageModel,
} from '../../../../../../../../objects/models/homepage.model';
import {ConfidenceIntervalConfig} from '../../../../../../../../objects/models/user-settings.model';
import {useHomepageModelData} from '../../../../hooks/homepage-summary.hooks';
import {ModelSampleSeriesModel} from '../../../../../../../../objects/models/model-sample-series.model';

interface OwnProps {
  model: HomepageModel;
  configuration: ConfidenceIntervalConfig;
  onView: () => void;
  onViewOpportunities?: () => void;
  onViewSegmentation?: () => void;
  className?: string;
}

type AllProps = OwnProps;

export const HomepageModelViewerHeader = (props: AllProps) => {
  const {model, configuration, onView, onViewOpportunities, onViewSegmentation, className} = props;
  const {t} = useTranslation();
  const {reviewedSeries, trend} = useHomepageModelData(model);

  const renderActions = () => {
    const actions = [];

    if (onViewSegmentation) {
      actions.push(
        <Button
          key={'segmentation'}
          onClick={() => onViewSegmentation()}
          icon={UsersIcon}
          caps={false}
          variant={'outlined'}
        >
          {t(TransKeys.HOMEPAGE.ACTIONS.VIEW_SEGMENTATION)}
        </Button>
      );
    }
    if (onViewOpportunities) {
      actions.push(
        <Button
          key={'opportunities'}
          onClick={() => onViewOpportunities()}
          icon={WandMagicSparklesSolidIcon}
          caps={false}
        >
          {t(TransKeys.HOMEPAGE.ACTIONS.FIND_OPPORTUNITIES)}
        </Button>
      );
    }
    if (actions.length > 0) {
      return <div className={classes.Actions}>{actions}</div>;
    }
    return null;
  };

  return (
    <div className={classNames(classes.HomepageModelViewerHeader, className)}>
      <div className={classes.Top}>
        {trend?.isSignificant && (
          <div
            className={classNames(
              classes.SignificantIndication,
              trend.isBetter ? classes.Positive : classes.Negative
            )}
          >
            {configuration.anomalyMode === AnomalyMode.LOOPS_ALGO &&
              (trend.isBetter
                ? t(TransKeys.HOMEPAGE.METRIC_CHART.LABELS.INVESTIGATE_RISE_TITLE)
                : t(TransKeys.HOMEPAGE.METRIC_CHART.LABELS.INVESTIGATE_DROP_TITLE))}
            {configuration.anomalyMode === AnomalyMode.XOX_SAME_WEEKDAY &&
              (trend.isBetter
                ? t(TransKeys.HOMEPAGE.METRIC_CHART.LABELS.WOW_INVESTIGATE_RISE_TITLE, {
                    percent: configuration.anomalyThreshold * 100,
                  })
                : t(TransKeys.HOMEPAGE.METRIC_CHART.LABELS.WOW_INVESTIGATE_DROP_TITLE, {
                    percent: configuration.anomalyThreshold * 100,
                  }))}
          </div>
        )}
        {model.modelType === ModelSampleSeriesModel.METRIC && (model as HomepageMetric).goal && (
          <div className={classes.GoalStatus}>
            <GoalStatus metric={model as HomepageMetric} />
          </div>
        )}
      </div>
      <div className={classes.Main}>
        <div className={classes.MainTitle}>
          <span className={classes.KPIName} onClick={() => onView()}>
            {model.name}
            <ArrowUpRightFromSquareRegularIcon className={classes.OpenIcon} />
          </span>
          {trend && (
            <>
              <span>{trend?.isBetter ? 'increased by' : 'decreased by'}</span>
              <Tooltip
                title={t(TransKeys.HOMEPAGE.METRIC_TREND_HELPER_TEXT, {
                  granularity: reviewedSeries.granularity,
                })}
                placement={'top'}
                interactive={false}
              >
                <TrendChip {...trend} isSignificant={false} className={classes.TrendChip} />
              </Tooltip>
              <span>
                this {reviewedSeries.granularity} vs. last {reviewedSeries.granularity}
              </span>
            </>
          )}
        </div>
        {renderActions()}
      </div>
    </div>
  );
};
