import {
  MultiLoadResponse,
  SmartSelectorParameters,
  SmartSelectorSource,
  SmartSelector,
  useRemoteSourceStated,
} from 'ui-components';
import {useCallback, useContext, useEffect, useMemo, useRef} from 'react';
import {exists, HttpClientContext} from 'front-core';
import {featureSmartSelectorNetworkRequest} from '../../../../http/smart-selector.network-requests';
import classes from './smart-selector.module.scss';
import {getFeatureNetworkRequest} from '../../../../http/features.network-requests';
import {FeaturePreview} from './components/previews/feature-preview.component';
import {SmartSelectorLayout} from './smart-selector-layout.component';
import {SmartSelectorSharedProps} from './smart-selector.shared';

interface OwnProps extends SmartSelectorSharedProps {}

type AllProps = OwnProps;

export const FeatureSmartSelector = (props: AllProps) => {
  const {
    placeholder,
    value,
    onChange: onChangeFromProps,
    onSignalInfo,
    filters,
    className,
    disabled,
    clearable,
    error,
    fullWidth,
    emptyState,
  } = props;
  const selectorRef = useRef<any>(null);
  const http = useContext(HttpClientContext);
  const {source: feature, exec} = useRemoteSourceStated({
    type: 'source',
    networkRequest: getFeatureNetworkRequest,
    initialValue: null,
  });
  const selectedFeature = useMemo(
    () => (value && feature?.id === value ? feature : null),
    [value, feature]
  );
  const labelValue = useMemo(() => {
    if (value && selectedFeature) {
      return selectedFeature.name;
    }
  }, [value, selectedFeature]);
  const onChange = useCallback(
    (value: number, item?: any) => {
      selectorRef.current.close();
      onChangeFromProps(value, item);
    },
    [onChangeFromProps]
  );
  const sources: SmartSelectorSource[] = useMemo(
    () => [
      {
        key: 'features',
        name: `Features`,
        onSelect: item => onChange(item.id, item),
        renderItemPreview: item => (
          <FeaturePreview label={'Feature'} data={item} onSignalInfo={onSignalInfo} />
        ),
      },
    ],
    [onChange, onSignalInfo]
  );
  const load = useCallback(
    async (keys: string[], parameters: SmartSelectorParameters): Promise<MultiLoadResponse> => {
      // @ts-ignore
      const features = await http.exec(
        featureSmartSelectorNetworkRequest({
          page: parameters.page,
          q: parameters.query,
          limit: 10,
          orderBy: 'id',
          order: 'asc',
          ...(filters || {}),
        })
      );

      return {
        features: features as any,
      };
    },
    [http, filters]
  );
  useEffect(() => {
    value && feature?.id !== value && exec(value);
  }, [value, exec, feature]);

  return (
    <SmartSelectorLayout
      id={'feature-selector'}
      label={labelValue}
      disabled={disabled}
      error={error}
      clearable={clearable && exists(value)}
      onClear={() => onChange(null)}
      placeholder={placeholder}
      className={className}
      ref={selectorRef}
      fullWidth={fullWidth}
    >
      <SmartSelector
        className={classes.Selector}
        sources={sources}
        load={load}
        emptyState={emptyState}
      />
    </SmartSelectorLayout>
  );
};
