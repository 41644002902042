import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {MetricType} from '../../../../objects/models/metric.model';
import {exists} from 'front-core';
import TransKeys from '../../../../constants/translation-keys';
import {SwitchAction} from 'ui-components';
import {AggregationMode} from '../../../../objects/models/signal.model';

interface OwnProps {
  metricType: MetricType;
  mode: AggregationMode;
  onChange: (mode: AggregationMode) => void;
  excludeModes?: AggregationMode[];
}
type AllProps = OwnProps;

export const METRIC_TYPE_TO_METRIC_MODE_MAPPING = {
  [MetricType.USAGE]: [
    AggregationMode.SUM,
    AggregationMode.SUM_FROM_TOTAL,
    AggregationMode.COUNT_ENTITIES,
    AggregationMode.SUM_FROM_X,
    AggregationMode.COUNT_DISTINCT_FROM_X,
  ],
  [MetricType.REVENUE]: [
    AggregationMode.SUM,
    AggregationMode.SUM_FROM_TOTAL,
    AggregationMode.COUNT_ENTITIES,
  ],
};
export function useMetricAggregationModeSwitchActions(props: AllProps): SwitchAction[] {
  const {metricType, mode, excludeModes, onChange} = props;
  const {t} = useTranslation();

  return useMemo(() => {
    if (
      !exists(metricType) ||
      [MetricType.USAGE, MetricType.REVENUE].includes(metricType) === false ||
      !METRIC_TYPE_TO_METRIC_MODE_MAPPING[metricType]
    ) {
      return [];
    }
    let modesForMetricType = METRIC_TYPE_TO_METRIC_MODE_MAPPING[metricType];
    if (excludeModes) {
      modesForMetricType = modesForMetricType.filter(x => !excludeModes.includes(x));
    }
    return modesForMetricType.map(x => ({
      label: t(TransKeys.ANALYSIS_FORMS.ANALYSIS_101.ANALYSIS_MODES[x.toUpperCase()]?.LABEL),
      isActive: x === mode,
      onClick: () => onChange(x),
      helperText: t(
        TransKeys.ANALYSIS_FORMS.ANALYSIS_101.ANALYSIS_MODES[x.toUpperCase()]?.HELPER_TEXT
      ),
    }));
  }, [metricType, mode, onChange, t, excludeModes]);
}
