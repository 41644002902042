import {useTranslation} from 'react-i18next';
import classes from '../app-announcement-bar.module.scss';
import {useLocation} from 'react-router';
import {FC, useMemo} from 'react';
import TransKeys from 'translations';
import {useIsAdmin} from '../../../../../core/hooks/use-is-admin.hook';

export const IntegrationSetupAnnouncement: FC = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const isAdmin = useIsAdmin();
  const showOnlyAdminsMsg = useMemo(
    () =>
      isAdmin && !location.pathname.includes('/dmp') && !location.pathname.includes('/analyses/'),
    [isAdmin, location.pathname]
  );

  return (
    <div className={classes.AnnouncementBar}>
      <span>{t(TransKeys.APP_ANNOUNCEMENT.SETUP_MODE.SETUP_MODE)}</span>
      {showOnlyAdminsMsg && (
        <span className={classes.Bold}>{t(TransKeys.APP_ANNOUNCEMENT.SETUP_MODE.ONLY_ADMINS)}</span>
      )}
    </div>
  );
};
