import {exists} from 'front-core';
import {Action, Subject} from '../../../../../constants/permissions';
import usePermissions from '../../../../../core/hooks/use-permissions.hook';
import {useProductData} from '../../../../../core/hooks/use-product-data.hook';
import {MemberDTO} from '../../../../../objects/dto/members-settings.dto';
import {TeamIconChip, TeamSelector, UserGroupLightIcon} from 'ui-components';
import classes from './team-select-cell.module.scss';

type TeamSelectCellProps = {
  onChangeTeam?: (memberId: number, teamId: number) => void;
  selectedTeam: MemberDTO['team'];
  memberId: number;
};

export const TeamSelectCell = (props: TeamSelectCellProps) => {
  const {memberId, selectedTeam, onChangeTeam} = props;

  const {can} = usePermissions();
  const canEditMember = can(Subject.USER, Action.EDIT);

  const {actualTeams: teams} = useProductData();

  if (canEditMember) {
    return (
      <TeamSelector
        teams={teams}
        size={'large'}
        placeholder={
          <div className={classes.TeamSelectCellFlexContainer}>
            <UserGroupLightIcon />
            <span>No Team</span>
          </div>
        }
        value={selectedTeam?.id}
        onChange={teamId => onChangeTeam(memberId, teamId)}
      />
    );
  }

  if (exists(selectedTeam)) {
    return (
      <div className={classes.TeamSelectCellFlexContainer}>
        <TeamIconChip name={selectedTeam.name} color={selectedTeam.color} />
        <span>{selectedTeam.name}</span>
      </div>
    );
  }

  return (
    <div className={classes.TeamSelectCellFlexContainer}>
      <UserGroupLightIcon />
      <span>No Team</span>
    </div>
  );
};
