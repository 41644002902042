import {useRerunAnalysisResult} from '../../../core/hooks/use-rerun.hook';
import {useDispatch} from 'react-redux';
import {useCallback, useContext} from 'react';
import {AnalysisResult} from '../../../objects/models/analysis-result.model';
import {AmplitudeEvent} from '../../../constants/amplitude-event';
import TransKeys from '../../../constants/translation-keys';
import {PanelKey} from '../../../constants/panels';
import {
  ANALYSIS_FOLDER_ID_PATH_PARAM,
  ANALYSIS_ID_PATH_PARAM,
  ANALYSIS_RESULT_ID_PATH_PARAM,
  AppRoutes,
} from '../../../constants/app-routes';
import {PanelType} from '../../../objects/system/panel-type.enum';
import {useTranslation} from 'react-i18next';
import {PanelsContext} from '../../../core/contexts/panels.context';
import {useAmplitude} from '../../../core/hooks/amplitude.hook';
import {ConfirmationDialogContext} from '../../../core/contexts/confirmation-dialog.context';
import {
  changeAnalysisParameters,
  deleteAnalysisResult,
  stopAnalysisResult,
} from '../../../store/analyses/analyses.actions';
import {ShareResourceType} from '../../../objects/models/share.model';
import {useIsExplored} from '../../../core/hooks/user-reactions.hook';
import {ModelDiscriminatorType} from '../../../objects/models/user-reaction.model';
import {useNavigate} from 'react-router';
import {ModelKey} from '../../../constants/model-key';
import {HttpClientContext} from 'front-core';
import {downloadAnalysisResultArtifactUrl} from '../../../http/analysis-results.network-requests';

export interface AnalysisResultActions {
  onRequest: (otherProps?: any) => void;
  onView: (analysisRes: AnalysisResult) => void;
  onDuplicate: (analysisRes: AnalysisResult, otherProps?: any) => void;
  onEdit: (analysisRes: AnalysisResult) => void;
  onSchedule: (analysisRes: AnalysisResult) => void;
  onShare: (analysisRes: AnalysisResult) => void;
  onRename: (analysisRes: AnalysisResult) => void;
  onDelete: (analysisRes: AnalysisResult) => void;
  onExplore: (analysisRes: AnalysisResult) => void;
  onStop: (analysisRes: AnalysisResult) => void;
  onRerun: (analysisRes: AnalysisResult) => void;
  onDownloadArtifact: (analysisRes: AnalysisResult, artifactId: number) => void;
  onChangeFolder: (analysisRes: AnalysisResult) => void;
  onChangeParameters: (analysisRes: AnalysisResult, parameters: any) => void;
}

export function useAnalysisResultActions(): AnalysisResultActions {
  const dispatch = useDispatch();
  const notify = useAmplitude();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {setConfirmationDialog} = useContext(ConfirmationDialogContext);
  const {openPrimaryPanel} = useContext(PanelsContext);
  const http = useContext(HttpClientContext);
  const onExplore = useIsExplored(ModelDiscriminatorType.ANALYSIS_RESULT);
  // const onDismiss = useIsDismissed(ModelDiscriminatorType.ANALYSIS_RESULT);

  const onView = useCallback(
    (analysisRes: AnalysisResult) =>
      navigate(
        AppRoutes.viewAnalysis(analysisRes.analysisId, {
          [ANALYSIS_RESULT_ID_PATH_PARAM]: analysisRes.id,
        })
      ),
    [navigate]
  );

  const onRequest = useCallback(
    (parameters: any = {}) => {
      openPrimaryPanel(PanelKey.ANALYSIS_FORM_PANEL, parameters, PanelType.MODAL);
      notify(AmplitudeEvent.CREATE_ANALYSIS_CLICKED);
    },
    [openPrimaryPanel, notify]
  );

  const onDuplicate = useCallback(
    (analysisResult: AnalysisResult, otherProps: any = {}) => {
      openPrimaryPanel(
        PanelKey.ANALYSIS_FORM_PANEL,
        {
          [ANALYSIS_ID_PATH_PARAM]: analysisResult?.analysisId,
          [ANALYSIS_RESULT_ID_PATH_PARAM]: analysisResult?.id,
          ...otherProps,
        },
        PanelType.MODAL
      );
      notify(AmplitudeEvent.ANALYSIS_DUPLICATE_CLICKED, {
        analysis_type_id: analysisResult.analysisTypeId,
        analysis_result_id: analysisResult.id,
      });
    },
    [openPrimaryPanel, notify]
  );

  const onStop = useCallback(
    (analysisResult: AnalysisResult) => {
      dispatch(stopAnalysisResult(analysisResult.id));
    },
    [dispatch]
  );

  const onEdit = useCallback(
    (analysisResult: AnalysisResult) => {
      const amplitudePayload = {analysisResultId: analysisResult.id};
      notify(AmplitudeEvent.EDIT_ANALYSIS_CLICKED, amplitudePayload);
      setConfirmationDialog({
        title: t(TransKeys.CONFIRMATIONS.EDIT_ANALYSIS_RESULT.TITLE),
        content: t(TransKeys.CONFIRMATIONS.EDIT_ANALYSIS_RESULT.CONTENT),
        onApprove: () => {
          notify(AmplitudeEvent.EDIT_ANALYSIS_CONFIRMED_CLICKED, amplitudePayload);
          openPrimaryPanel(
            PanelKey.ANALYSIS_FORM_PANEL,
            {
              [ANALYSIS_ID_PATH_PARAM]: analysisResult.analysisId,
              [ANALYSIS_RESULT_ID_PATH_PARAM]: analysisResult.id,
              [ANALYSIS_FOLDER_ID_PATH_PARAM]: analysisResult.analysisFolderId,
              editMode: true,
            },
            PanelType.MODAL
          );
        },
      });
    },
    [notify, setConfirmationDialog, t, openPrimaryPanel]
  );

  const onDelete = useCallback(
    (analysisResult: AnalysisResult) => dispatch(deleteAnalysisResult(analysisResult.id)),
    [dispatch]
  );

  const onSchedule = useCallback(
    (analysisResult: AnalysisResult) => {
      openPrimaryPanel(
        PanelKey.SCHEDULE_ANALYSIS_PANEL,
        {
          [ANALYSIS_RESULT_ID_PATH_PARAM]: analysisResult.id,
        },
        PanelType.MODAL
      );
    },
    [openPrimaryPanel]
  );

  const onShare = useCallback(
    (analysisResult: AnalysisResult) => {
      openPrimaryPanel(
        PanelKey.SHARE_RESOURCE_PANEL,
        {
          modelId: analysisResult.id,
          type: ShareResourceType.ANALYSIS_RESULT,
          copyLink: AppRoutes.asUrl(
            AppRoutes.viewAnalysis(analysisResult.analysisId, {
              [ANALYSIS_RESULT_ID_PATH_PARAM]: analysisResult.id,
            })
          ),
        },
        PanelType.MODAL
      );
      notify(AmplitudeEvent.RESOURCE_SHARE_CLICKED, {
        type: ShareResourceType.ANALYSIS_RESULT,
        id: analysisResult.id,
      });
    },
    [openPrimaryPanel, notify]
  );

  const onRename = useCallback(
    (analysisResult: AnalysisResult) => {
      openPrimaryPanel(
        PanelKey.RENAME_ANALYSIS_PANEL,
        {
          [ANALYSIS_RESULT_ID_PATH_PARAM]: analysisResult.id,
        },
        PanelType.MODAL
      );
    },
    [openPrimaryPanel]
  );

  const onChangeFolder = useCallback(
    (analysisRes: AnalysisResult) => {
      openPrimaryPanel(
        PanelKey.MOVE_TO_ANALYSIS_FOLDER_PANEL,
        {
          modelId: analysisRes.analysisId,
          modelKey: ModelKey.ANALYSIS,
        },
        PanelType.MODAL
      );
    },
    [openPrimaryPanel]
  );
  const onDownloadArtifact = useCallback(
    async (analysisRes: AnalysisResult, artifactId: number) => {
      const res: any = await http.exec(
        downloadAnalysisResultArtifactUrl(analysisRes.id, artifactId)
      );
      window.open(res.url, '_blank');
    },
    [http]
  );

  const onChangeParameters = useCallback(
    (analysisRes: AnalysisResult, parameters: any = {}) => {
      dispatch(
        changeAnalysisParameters({
          analysisId: analysisRes.analysisId,
          analysisResultId: analysisRes.id,
          parameters,
        })
      );
    },
    [dispatch]
  );

  const {onRerun} = useRerunAnalysisResult(onDuplicate);

  return {
    onView,
    onRequest,
    onDuplicate,
    onExplore,
    onDelete,
    onRerun,
    onStop,
    onEdit,
    onSchedule,
    onShare,
    onRename,
    onChangeFolder,
    onChangeParameters,
    onDownloadArtifact,
  };
}
