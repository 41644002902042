import {useCallback, useMemo} from 'react';
import classNames from 'classnames';
import {CloseIcon, IconButton, QueryBuilderFactory, SqlElementType} from 'ui-components';
import classes from './table-events-query-builder.module.scss';
import {del, set} from 'object-path-immutable';
import {cloneDeep, get} from 'lodash';
import {
  METADATA_KEY,
  PARAMETERS_METADATA_KEY,
} from '../../../../../constants/parameters-saved-keys';
import {exists} from 'front-core';
import {SelectorModelType} from '../../smart-selector/advanced-smart-selector.component';
import {
  createTableEventCondition,
  TemplateItemQueryBuilder,
} from '../template-item-query-builder/template-item-query-builder.component';
import {TableEvent} from '../../../../../objects/models/table-event.model';

interface OwnProps {
  query: any;
  onChange?: (query: any) => void;
  errors?: any;
  multiEvents?: boolean;
  disabled?: boolean;
  filters?: any;
  className?: string;
  // sameTable?: boolean;
}

type AllProps = OwnProps;

export const TableEventsQueryBuilderName = 'TableEventsQueryBuilder';

export const createTableEventsInitialQuery = (tableEvent?: TableEvent) => {
  const q = QueryBuilderFactory.createOrCondition();
  q.conditions = [];
  q[PARAMETERS_METADATA_KEY] = {
    [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: TableEventsQueryBuilderName,
    [METADATA_KEY.BUILDER_VERSION]: 1,
  };

  if (tableEvent) {
    const andCondition = createTableEventCondition(tableEvent);
    q.conditions[0] = andCondition;
  }

  return q;
};

const fixVersion = query => {
  if (
    !exists(query) ||
    get(query, `${PARAMETERS_METADATA_KEY}.${METADATA_KEY.BUILDER_VERSION}`) === 1
  ) {
    return query;
  }
  const newQuery = cloneDeep(query);
  let cIdx = 0;
  for (const c of newQuery.conditions) {
    if (c.type === SqlElementType.CONDITION) {
      newQuery.conditions[cIdx] = QueryBuilderFactory.createAndCondition();
      newQuery.conditions[cIdx].conditions = [c];
      newQuery.conditions[cIdx][PARAMETERS_METADATA_KEY] = c[PARAMETERS_METADATA_KEY];
      cIdx++;
    }
  }
  newQuery[PARAMETERS_METADATA_KEY][METADATA_KEY.BUILDER_VERSION] = 1;
  return newQuery;
};

export const TableEventsQueryBuilder = (props: AllProps) => {
  const {
    query: queryFromProps,
    onChange,
    multiEvents = true,
    errors,
    disabled,
    filters,
    // sameTable,
    className,
  } = props;
  // fix for version 0
  const query = useMemo(() => fixVersion(queryFromProps), [queryFromProps]);
  const numberOfConditions = useMemo(() => (query?.conditions || []).length, [query]);
  // const rootEventTableId = useMemo(() => {
  //   let path = 'conditions.0.left.table_id';
  //   // handle add where
  //   const firstCondition = get(query, 'conditions.0');
  //   if (firstCondition?.type === SqlElementType.AND_CONDITION) {
  //     path = `conditions.0.${path}`;
  //   }
  //   return get(query, path);
  // }, [query]);
  // const filters = useMemo(
  //   () =>
  //     sameTable ? addFilters(filtersFromProps, {table_id: rootEventTableId}) : filtersFromProps,
  //   [filtersFromProps, rootEventTableId, sameTable]
  // );
  const onAddEvent = useCallback(
    condition => {
      const newQuery = query ? {...query} : createTableEventsInitialQuery();
      newQuery.conditions.push(condition);
      onChange && onChange(newQuery);
    },
    [query, onChange]
  );
  const onItemChanged = useCallback(
    (cIdx: number, condition: any) => {
      const newQ = set(query, `conditions.${cIdx}`, condition);
      onChange && onChange(newQ);
    },
    [query, onChange]
  );
  const onRemoveItem = useCallback(
    cIdx => {
      if (query?.conditions?.length === 1) {
        onChange && onChange(null);
        return;
      }
      const newQ = del(query, `conditions.${cIdx}`);
      onChange && onChange(newQ);
    },
    [query, onChange]
  );
  const showAdd = useMemo(() => {
    if (!query) {
      return true;
    }
    if (multiEvents) {
      return true;
    }
    if (query.conditions.length === 0) {
      return true;
    }
    if (!multiEvents && query.conditions.length > 0) {
      return false;
    }
  }, [query, multiEvents]);

  return (
    <div
      className={classNames(
        classes.TableEventsQueryBuilder,
        disabled && classes.Disabled,
        className
      )}
    >
      <div className={classes.Builder}>
        {query &&
          query.conditions.map((c, cIdx) => (
            <div key={cIdx} className={classes.TemplateItemQuery}>
              <TemplateItemQueryBuilder
                prefix={cIdx > 0 ? 'or' : undefined}
                query={c}
                errors={get(errors, `conditions.${cIdx}`)}
                onChange={v => onItemChanged(cIdx, v)}
                disabled={disabled}
                allowTypes={[SelectorModelType.EVENT]}
                eventFilters={filters}
              />
              {!disabled && (
                <div className={classes.RemoveConditionBtn}>
                  <IconButton
                    tooltipText={'Remove event'}
                    icon={CloseIcon}
                    className={classes.ShowOnHoverBtn}
                    onClick={() => onRemoveItem(cIdx)}
                  />
                </div>
              )}
            </div>
          ))}
        {showAdd && !disabled && (
          <div className={classes.AddEvent}>
            <TemplateItemQueryBuilder
              prefix={numberOfConditions > 0 ? 'or' : undefined}
              query={null}
              onChange={onAddEvent}
              disabled={disabled}
              allowTypes={[SelectorModelType.EVENT]}
              eventFilters={filters}
              errors={errors}
            />
          </div>
        )}
      </div>
    </div>
  );
};
