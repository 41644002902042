import {sortBy} from 'lodash';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../../constants/translation-keys';
import {ImagesResource} from '../../../../../assets/images';
import {SourceType} from '../../../../../objects/models/source.model';
import {DataSourceFormsMap} from '../data-sources.config';

export const useSourceWizardOptions = () => {
  const {t} = useTranslation();
  return useMemo(
    () => ({
      options: sortBy(
        Object.keys(DataSourceFormsMap).map(dataSource => ({
          label: t(TransKeys.GENERAL.DATA_SOURCES[dataSource.toUpperCase()]),
          icon: () => (
            <img
              style={{margin: '0.4rem 0.8rem 0.4rem 0'}}
              src={ImagesResource.dataSources[SourceType[dataSource.toUpperCase()]]}
              alt={t(TransKeys.GENERAL.DATA_SOURCES[dataSource.toUpperCase()])}
            />
          ),
          value: dataSource,
        })),
        'label'
      ),
    }),
    [t]
  );
};
