import {AnalysisFolder} from '../../../../../../objects/models/analysis-folder.model';
import classes from '../analysis-types-follow-up-panel.module.scss';
import TransKeys from 'translations';
import {AnalysisFolderSmartSelector} from '../../../../core/smart-selector/analysis-folder-smart-selector.component';
import {Button} from 'ui-components';
import {useTranslation} from 'react-i18next';
import {useCallback, useContext, useMemo} from 'react';
import {PanelKey} from '../../../../../../constants/panels';
import {PanelsContext} from '../../../../../../core/contexts/panels.context';
import {useFormContext} from 'react-hook-form';
import {useIsAdmin} from '../../../../../../core/hooks/use-is-admin.hook';
import {useProductData} from '../../../../../../core/hooks/use-product-data.hook';

interface OwnProps {
  disabled: boolean;
  onSubmit: () => void;
}

type AllProps = OwnProps;

const AnalysisTypesFollowUpPanelFooter = (props: AllProps) => {
  const {disabled, onSubmit} = props;
  const {watch, setValue} = useFormContext();
  const analysisFolderId = watch('analysisFolderId');
  const {t} = useTranslation();
  const {openSecondaryPanel} = useContext(PanelsContext);
  const isAdmin = useIsAdmin();
  const {rootPublicAnalysisFolder, rootPrivateAnalysisFolder} = useProductData();

  const onCreateAnalysisFolder = useCallback(
    () =>
      openSecondaryPanel(PanelKey.ANALYSIS_FOLDER_FORM_PANEL, {
        onSuccess: (analysisFolder: AnalysisFolder) =>
          setValue('analysisFolderId', analysisFolder.id),
      }),
    [openSecondaryPanel, setValue]
  );
  const defaultAnalysisFolderId = useMemo(() => {
    return isAdmin ? rootPrivateAnalysisFolder : rootPublicAnalysisFolder;
  }, [rootPublicAnalysisFolder, rootPrivateAnalysisFolder, isAdmin]);

  return (
    <div className={classes.Footer}>
      <div className={classes.AnalysisFolder}>
        <span className={classes.AnalysisFolderLabel}>
          {t(TransKeys.ANALYSIS_TYPES_FOLLOW_FOLLOW_UP_PANEL.FOOTER.ANALYSIS_FOLDER.LABEL)}
        </span>
        <AnalysisFolderSmartSelector
          value={analysisFolderId}
          onChange={(analysisFolderId: number) => setValue('analysisFolderId', analysisFolderId)}
          onCreate={onCreateAnalysisFolder}
          defaultFolderId={defaultAnalysisFolderId}
        />
      </div>
      <Button disabled={disabled} onClick={onSubmit}>
        {t(TransKeys.GENERAL.ACTIONS.RUN)}
      </Button>
    </div>
  );
};

export default AnalysisTypesFollowUpPanelFooter;
