import {useCallback, useContext, useEffect, useMemo} from 'react';
import {composition, exists} from 'front-core';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {AppRoutes, EXPERIMENT_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {
  FancyHeader,
  InteractionContext,
  InteractionType,
  LabelWrapper,
  LabIcon,
  Link,
  ModalLayout,
  ModelType,
  ParameterType,
  QueryBuilder,
  QueryBuilderConfig,
  TextButton,
  useRemoteSourceStated,
} from 'ui-components';
import TransKeys from '../../../../constants/translation-keys';
import classes from './experiment-view-panel.module.scss';
import {useTranslation} from 'react-i18next';
import {capitalize, get} from 'lodash';
import {Experiment} from '../../../../objects/models/experiment.model';
import {getExperimentNetworkRequest} from '../../../../http/experiments.network-requests';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {getComponentForQuery} from '../../../shared/core/query-builders/query-builders.utils';
import {
  experimentSelectorSchemaMapping,
  kpiSelectorSchemaMapping as abTestKPISelectorSchemaMapping,
  VARIANT_QUERY_KEY,
} from '../../../analyses/analysis-forms/analysis-parameters/analysis-91/analysis-91-form.component';
import {
  kpiSelectorSchemaMapping as releaseImpactTestKPISelectorSchemaMapping,
  releaseDateSelectorSchemaMapping,
} from '../../../analyses/analysis-forms/analysis-parameters/analysis-109/analysis-109-form.component';
import {queryBuilderModelConfig} from '../../../../constants/query-builder';
import {AnalysisTypeId} from '../../../../constants/analysis-type-id';
import {Modifiers} from '../../../shared/components/general/modifiers/modifiers.component';
import {
  PARAMETER_TYPE_TO_MULTI_LOAD_KEY,
  useParametersMultiLoad,
} from '../../../../core/hooks/parameters-multi-load.hook';
import {useNavigate} from 'react-router';
import moment from 'moment';
import {TIME_FORMATS} from '../../../../constants/time-formats';
import {METADATA_KEY, PARAMETERS_METADATA_KEY} from '../../../../constants/parameters-saved-keys';
import {withModalErrorHandler} from '../../../../core/hoc/with-model-error-handler.hoc';
import {getAnalysisParametersNetworkRequest} from '../../../../http/analyses.network-requests';

interface OwnProps {
  experiment: Experiment;
  errors: any;
  onClose?: () => void;
}

type AllProps = OwnProps;

const SELECTED_EXPERIMENT_KEY = SharedSelectionKeys.EXPERIMENT_VIEW__EXPERIMENT;

export const ExperimentViewPanelComponent = (props: AllProps) => {
  const {onClose, experiment} = props;
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {postMessage} = useContext(InteractionContext);
  const containerStyle = useMemo(() => ({height: '78vh', width: '60vw'}), []);
  const {
    source: parameters,
    exec: getParameters,
    isLoading,
  } = useRemoteSourceStated({
    networkRequest: getAnalysisParametersNetworkRequest,
    initialValue: {},
  });
  const {response: multiLoad, isLoading: isLoadingMultiLoad} = useParametersMultiLoad(
    parameters.values,
    parameters.schema
  );
  const variantQuery = useMemo(() => get(parameters, `values.${VARIANT_QUERY_KEY}`), [parameters]);
  const experimentScopeQuery = useMemo(
    () => get(parameters, `values.${experimentSelectorSchemaMapping.start_event_query}`),
    [parameters]
  );
  const isScopeTableEventsQueryBuilder = useMemo(() => {
    return (
      get(
        experimentScopeQuery,
        `${PARAMETERS_METADATA_KEY}.${METADATA_KEY.BUILDER_COMPONENT_NAME_KEY}`
      ) === 'TableEventsQueryBuilder'
    );
  }, [experimentScopeQuery]);
  const VariantQueryComponent = useMemo(
    () => getComponentForQuery(variantQuery) || QueryBuilder,
    [variantQuery]
  );
  const ExperimentScopeQueryComponent = useMemo(
    () => getComponentForQuery(experimentScopeQuery) || QueryBuilder,
    [experimentScopeQuery]
  );
  const queryBuilderConfig: QueryBuilderConfig = useMemo(
    () => ({
      modelConfig: queryBuilderModelConfig,
    }),
    []
  );
  const kpiKeys = useMemo(() => {
    if (parameters.analysisTypeId === AnalysisTypeId.A_B_TEST) {
      return abTestKPISelectorSchemaMapping;
    }
    if (parameters.analysisTypeId === AnalysisTypeId.RELEASE_IMPACT) {
      return releaseImpactTestKPISelectorSchemaMapping;
    }
  }, [parameters]);
  const onViewResults = useCallback(
    () => navigate(AppRoutes.viewExperiment(experiment.id)),
    [navigate, experiment]
  );

  useEffect(() => {
    experiment?.analysisId && getParameters(experiment.analysisId);
  }, [experiment, getParameters]);

  const renderKPI = (signalId: number) => {
    const signals = multiLoad[PARAMETER_TYPE_TO_MULTI_LOAD_KEY[ParameterType.SIGNAL]] || {};
    const signalData = signals[signalId] || {};
    return (
      <TextButton
        key={signalId}
        className={classes.KPIButton}
        onClick={() =>
          postMessage({
            type: InteractionType.REFERENCE,
            payload: {
              modelId: signalData.id,
              modelType: ModelType.SIGNAL,
            },
          })
        }
      >
        {signalData.name}
      </TextButton>
    );
  };

  const renderShared = () => {
    if (isLoadingMultiLoad) {
      return <GenericLoading />;
    }
    return (
      <>
        <LabelWrapper
          className={classes.BlockItem}
          label={t(TransKeys.VIEW_EXPERIMENT_PANEL.LABELS.KPIS)}
        >
          <div className={classes.KPIRow}>
            <div className={classes.Label}>Primary</div>
            {renderKPI(parameters.values[kpiKeys.primary_kpi])}
          </div>
          <div className={classes.KPIRow}>
            <div className={classes.Label}>Secondary</div>
            {(parameters.values[kpiKeys.secondary_kpis] || []).map(s => renderKPI(s))}
          </div>
        </LabelWrapper>
        {parameters.values['population_filter'] && (
          <LabelWrapper
            className={classes.BlockItem}
            label={t(TransKeys.VIEW_EXPERIMENT_PANEL.LABELS.EXPERIMENT_POPULATION)}
          >
            <QueryBuilder
              query={parameters.values['population_filter']}
              config={queryBuilderConfig}
              disabled
            />
          </LabelWrapper>
        )}
      </>
    );
  };

  const renderABTest = () => {
    return (
      <>
        <div className={classes.Block}>
          <LabelWrapper
            className={classes.BlockItem}
            label={t(TransKeys.VIEW_EXPERIMENT_PANEL.LABELS.ASSIGNMENT)}
          >
            <VariantQueryComponent
              viewMode
              config={queryBuilderConfig}
              query={variantQuery}
              disabled
            />
          </LabelWrapper>
          <LabelWrapper
            className={classes.BlockItem}
            label={t(TransKeys.VIEW_EXPERIMENT_PANEL.LABELS.EXPERIMENT_SCOPE)}
          >
            {experimentScopeQuery && (
              <>
                {isScopeTableEventsQueryBuilder && (
                  <div className={classes.SmallHelper}>
                    {t(TransKeys.VIEW_EXPERIMENT_PANEL.LABELS.EXPOSE_TO_HELPER_TEXT)}
                  </div>
                )}
                <ExperimentScopeQueryComponent
                  viewMode
                  config={queryBuilderConfig}
                  query={experimentScopeQuery}
                  disabled
                />
              </>
            )}
            {!exists(experimentScopeQuery) && (
              <div className={classes.Text}>
                {t(TransKeys.VIEW_EXPERIMENT_PANEL.LABELS.NEW_USERS)}
              </div>
            )}
          </LabelWrapper>
          {renderShared()}
        </div>
      </>
    );
  };

  const renderReleaseImpact = () => {
    return (
      <>
        <div className={classes.Block}>
          {parameters.values[releaseDateSelectorSchemaMapping.date_parameter] && (
            <LabelWrapper
              className={classes.BlockItem}
              label={t(TransKeys.VIEW_EXPERIMENT_PANEL.LABELS.RELEASE_DATE)}
            >
              {moment(parameters.values[releaseDateSelectorSchemaMapping.date_parameter]).format(
                TIME_FORMATS.READABLE_DATE
              )}
            </LabelWrapper>
          )}
          {renderShared()}
        </div>
      </>
    );
  };

  return (
    <div style={containerStyle}>
      <ModalLayout>
        <div className={classes.ExperimentViewPanel}>
          <FancyHeader
            icon={LabIcon}
            title={capitalize(t(TransKeys.MODELS.EXPERIMENT))}
            onClose={onClose}
            className={classes.Header}
          />
          <div className={classes.Body}>
            {isLoading && <GenericLoading />}
            <div className={classes.Block}>
              <div className={classes.Name}>{experiment.name}</div>
              {experiment.shortDescription && (
                <div className={classes.Description}>{experiment.shortDescription}</div>
              )}
              <Link className={classes.ViewResultButton} onClick={onViewResults}>
                {t(TransKeys.VIEW_EXPERIMENT_PANEL.ACTIONS.VIEW_EXPERIMENT_RESULT)}
              </Link>
            </div>
            {parameters.analysisTypeId === AnalysisTypeId.A_B_TEST && renderABTest()}
            {parameters.analysisTypeId === AnalysisTypeId.RELEASE_IMPACT && renderReleaseImpact()}
            {experiment.history.length > 0 && (
              <div className={classes.Block}>
                <Modifiers history={experiment.history} />
              </div>
            )}
          </div>
        </div>
      </ModalLayout>
    </div>
  );
};

const ExperimentViewPanel = composition<AllProps>(
  ExperimentViewPanelComponent,
  withModalErrorHandler({
    modalTitle: TransKeys.MODELS.EXPERIMENT,
    modalIcon: LabIcon,
    extractErrorCodeFromProps: props => get(props.errors, 'experiment.errorCode'),
  }),
  withLoadBefore({
    experiment: {
      selectedKey: SELECTED_EXPERIMENT_KEY,
      actionKey: SELECTED_EXPERIMENT_KEY,
      request: getExperimentNetworkRequest,
      mapPayloadFromProps: props => props[EXPERIMENT_ID_PATH_PARAM],
      shouldCall: props => props[EXPERIMENT_ID_PATH_PARAM] !== undefined,
    },
  })
);

export default ExperimentViewPanel;
