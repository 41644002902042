import classes from './action-card.module.scss';
import TransKeys from '../../../../constants/translation-keys';
import {Card} from '../../../shared/components/general/card/card.component';
import {OpportunityAction} from '../../../../objects/models/opportunity.model';
import {useTranslation} from 'react-i18next';
import {useMemo} from 'react';
import classNames from 'classnames';
import {AlsoDidThis} from '../also-did-this/also-did-this.component';
import {flatten} from 'lodash';

interface OwnProps {
  action: OpportunityAction;
  className?: string;
}

type AllProps = OwnProps;

export const ActionCard = (props: AllProps) => {
  // Props
  const {action, className} = props;
  const {t} = useTranslation();
  // Computed
  const companies = useMemo(() => action.caseStudies.map(cs => cs.companyName), [action]);
  const technologies = useMemo(() => {
    return action.technologies?.length > 0 ? action.technologies?.join(', ') : 'None';
  }, [action]);
  const images: string[] = useMemo(
    () =>
      action.caseStudies
        ? flatten(action.caseStudies.map(cs => (cs.images || []).map(i => i.url)))
        : [],
    [action]
  );

  return (
    <Card
      className={classNames(classes.ActionCard, className)}
      image={images}
      title={action.name}
      bottomInfo={
        <div className={classes.BottomInfo}>
          <div className={classes.Categories}>
            <span className={classes.Label}>{t(TransKeys.ACTIONS.CATEGORIES)}</span>
            <span className={classes.Value}>{technologies}</span>
          </div>
        </div>
      }
      blocks={[
        {
          render: () => <AlsoDidThis companies={companies} />,
        },
      ]}
    />
  );
};
