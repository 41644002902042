import classNames from 'classnames';
import classes from './onboarding-task.module.scss';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {snakeCase} from 'lodash';
import {ArrowRightRegularIcon, CircleCheckSolidIcon, CircleLightIcon} from 'ui-components';
import {useProductData} from '../../../../../core/hooks/use-product-data.hook';

interface OwnProps {
  task: any;
  focused: boolean;
  onActionClick: () => void;
}

type AllProps = OwnProps;

const TaskStatusIcon = ({completedOn}) => {
  return (
    <div className={classNames(classes.TaskStatusIcon, completedOn && classes.Completed)}>
      <CircleCheckSolidIcon className={classNames(classes.Icon, classes.CheckedCircle)} />
      <CircleLightIcon className={classNames(classes.Icon, classes.EmptyCircle)} />
    </div>
  );
};

const Subtask = ({type, completedOn}) => {
  const {t} = useTranslation();
  const transKey = snakeCase(type).toUpperCase();

  return (
    <div className={classNames(classes.Subtask, completedOn && classes.Completed)}>
      <TaskStatusIcon completedOn={completedOn} />
      <div className={classes.SubtaskTitle}>
        {t(TransKeys.APP_USER_ONBOARDING.TASKS[transKey]?.TITLE)}
      </div>
    </div>
  );
};

export const OnboardingTask = (props: AllProps) => {
  const {task, focused, onActionClick} = props;
  const {subtasks, completedOn} = task;
  const {userQuestionnaireRole} = useProductData();
  const transKey = task.type.toUpperCase();
  const roleTransKey = (userQuestionnaireRole || '').toUpperCase();
  const {t} = useTranslation();
  const taskTransKey = TransKeys.APP_USER_ONBOARDING.TASKS[transKey];
  const title = t(taskTransKey[roleTransKey]?.TITLE || taskTransKey.TITLE, task.metadata) as string;
  const description = t(
    taskTransKey[roleTransKey]?.DESCRIPTION || taskTransKey.DESCRIPTION,
    task.metadata
  ) as string;

  return (
    <div
      className={classNames(
        classes.Task,
        focused && classes.Focused,
        completedOn && classes.Completed
      )}
    >
      <div className={classes.Status}>
        <TaskStatusIcon completedOn={task.completedOn} />
      </div>
      <div className={classes.Title}>{title}</div>
      {onActionClick && (
        <div className={classes.Action} onClick={onActionClick}>
          <ArrowRightRegularIcon className={classes.IconButton} />
        </div>
      )}
      {description && <div className={classes.Description}>{description}</div>}
      {subtasks && (
        <div className={classes.Subtasks}>
          {Object.keys(subtasks).map(subtask => (
            <Subtask key={subtask} type={subtask} {...subtasks[subtask]} />
          ))}
        </div>
      )}
    </div>
  );
};
