import {BaseModel} from './base.model';
import {UserBasicInfo} from './user.model';

export enum AnalysisResultStatus {
  CREATED = 'created',
  RUNNING = 'running',
  COMPLETED = 'completed',
  FAILED = 'failed',
  QUEUED = 'queued',
  REQUESTED = 'requested',
  STOPPED = 'stopped',
}

export interface AnalysisResultArtifact {
  id: number;
  name: string;
  cloudFileId: string;
  createdOn: string;
}

export interface AnalysisGoal {
  modelId?: number;
  modelType?: string;
  name: string;
  parameterName: string;
}

export interface AnalysisResult extends BaseModel {
  userAnalysisName: string;
  analysisId: number;
  analysisTypeId?: number;
  isCausal?: boolean;
  requestedByUserId?: number;
  analysisFolderId?: number;
  analysisFolder: {id: number; name: string; isPrivateOther: boolean};
  analysisGoal?: AnalysisGoal;
  artifacts: AnalysisResultArtifact[];
  requestedByUser?: UserBasicInfo;
  rootDocumentId?: string;
  documentVersion?: number;
  isRequested?: boolean;
  name: string;
  analysisName: string;
  analysisTypeName: string;
  status: AnalysisResultStatus;
  runParameters: any;
  parameters: any;
  results?: any;
  userNotes?: string;
  outputs?: any;
  completedOn?: string;
  debugInfo?: any;
  isViewed?: boolean;
  isExample?: boolean;
  isExplored?: boolean;
  isDismissed?: boolean;
  cronExp?: string;
  opportunitiesCount: number;
  deletedAt?: string;
  isFollowUp?: number;
  deprecatedByUser?: {id: number; fullName: string};
  progress?: {
    currentStepIndex: number;
    totalStepsCount: number;
    currentStepText: string;
  };
  executedSqlsCount: number;
}
