import classes from './analysis-result-base-empty-state.module.scss';
import {ImagesResource} from '../../../../../../assets/images';
import {Button} from 'ui-components';
import classNames from 'classnames';

interface OwnProps {
  subTitle: string;
  onClick: () => void;
  title: string;
  buttonText: string;
  className?: string;
}

export type AnalysisResultBaseEmptyStateProps = OwnProps;

export const AnalysisResultBaseEmptyState = (props: AnalysisResultBaseEmptyStateProps) => {
  const {subTitle, title, buttonText, onClick, className} = props;

  return (
    <div className={classNames(classes.Container, className)}>
      <div className={classes.Content}>
        <div className={classes.ImageWrapper}>
          <img src={ImagesResource.app.telescopeV2} alt={''} />
        </div>
        <div className={classes.Title}>{title}</div>
        <div className={classes.SubTitle}>{subTitle}</div>
        <Button onClick={onClick} size={'large'}>
          {buttonText}
        </Button>
      </div>
    </div>
  );
};
