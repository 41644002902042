import {useCallback, useMemo} from 'react';
import {cloneDeep, get, keys, set} from 'lodash';
import {QueryBuilderFactory} from 'ui-components';
import {BaseHabitMomentQueryBuilder} from './base-habit-moment-query-builder.component';
import {
  METADATA_KEY,
  PARAMETERS_METADATA_KEY,
} from '../../../../../constants/parameters-saved-keys';
import {TableEntity} from '../../../../../objects/models/table.model';
import {HABIT_MOMENT_PARAMETER_MAPPING as PARAMETER_MAPPING} from '../query-builders.config';
import {MilestoneType} from '../../../../../objects/models/milestone.model';

export const HABIT_MOMENT_TEMPLATE_NAME = 'milestone_ts';
export const BUILDER_COMPONENT_NAME_KEY = 'HabitMomentQueryBuilder';

export enum HabitMomentTimeWindowUnit {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export enum HabitMomentReferenceType {
  USAGES = 'usages',
  DAYS = 'days',
  WEEKS = 'weeks',
}

export const REFERENCE_TYPE_OPTIONS = {
  options: [
    {
      value: HabitMomentReferenceType.USAGES,
      label: 'times',
    },
    {
      value: HabitMomentReferenceType.DAYS,
      label: 'distinct days',
    },
    {
      value: HabitMomentReferenceType.WEEKS,
      label: 'distinct weeks',
    },
  ],
};

export const createHabitMomentInitialQuery = (getSignalByTag, entity) => {
  const q = QueryBuilderFactory.createTemplate(HABIT_MOMENT_TEMPLATE_NAME);
  const joinDateSignal = getSignalByTag(MilestoneType.JOIN_DATE, entity);

  set(q, PARAMETER_MAPPING.signals, [null]);
  set(q, PARAMETER_MAPPING.ref_date, QueryBuilderFactory.createSignalColumn(joinDateSignal?.id));
  set(q, PARAMETER_MAPPING.time_window_unit, HabitMomentTimeWindowUnit.DAY);
  set(q, PARAMETER_MAPPING.time_window_n, 7);
  set(q, PARAMETER_MAPPING.reference_unit, HabitMomentReferenceType.USAGES);
  set(q, PARAMETER_MAPPING.reference_n, 3);

  q[PARAMETERS_METADATA_KEY] = {
    [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: BUILDER_COMPONENT_NAME_KEY,
  };

  return q;
};

interface OwnProps {
  query: any;
  onChange?: (parameters: any) => void;
  onSignalInfo?: (value: string | number) => void;
  entityContext?: TableEntity;
  errors?: any;
  disabled?: boolean;
  className?: string;
}

type AllProps = OwnProps;

const mapper = (value: any) => ({
  signals: get(value, PARAMETER_MAPPING.signals),
  ref_date: get(value, PARAMETER_MAPPING.ref_date),
  time_window_unit: get(value, PARAMETER_MAPPING.time_window_unit),
  time_window_n: get(value, PARAMETER_MAPPING.time_window_n),
  reference_unit: get(value, PARAMETER_MAPPING.reference_unit),
  reference_n: get(value, PARAMETER_MAPPING.reference_n),
});

export const HabitMomentQueryBuilder = (props: AllProps) => {
  const {errors, onChange, onSignalInfo, query, entityContext, disabled, className} = props;

  const mappedValue = useMemo(() => mapper(query), [query]);
  const mappedErrors = useMemo(() => mapper(errors), [errors]);

  const mappedOnChange = useCallback(
    changes => {
      const newValue = cloneDeep(query);
      for (const k of keys(changes)) {
        set(newValue, PARAMETER_MAPPING[k], changes[k]);
      }
      newValue[PARAMETERS_METADATA_KEY] = {
        [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: BUILDER_COMPONENT_NAME_KEY,
      };
      onChange && onChange(newValue);
    },
    [onChange, query]
  );

  return (
    <BaseHabitMomentQueryBuilder
      value={mappedValue}
      errors={mappedErrors}
      onChange={mappedOnChange}
      onSignalInfo={onSignalInfo}
      entityContext={entityContext}
      disabled={disabled}
      className={className}
    />
  );
};
