import {composition, exists} from 'front-core';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {getContentNetworkRequest} from '../../../../http/contents.network-requests';
import {CONTENT_ID_PATH_PARAM, SIGNAL_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {useState} from 'react';
import {
  FancyHeader,
  LabelWrapper,
  ModalLayout,
  ContentIcon,
  PrimaryTabs,
  PrimaryTab,
} from 'ui-components';
import TransKeys from '../../../../constants/translation-keys';
import {Content} from '../../../../objects/models/content.model';
import classes from './content-view-panel.module.scss';
import {useTranslation} from 'react-i18next';
import {get, values} from 'lodash';
import {FunnelTabs} from '../../../shared/components/general/funnel-tabs/funnel-tabs.component';
import {
  generateContentTabs,
  getInitialActionTabForContent,
} from '../content-form-panel/content-form-panel.component';
import {Modifiers} from '../../../shared/components/general/modifiers/modifiers.component';
import QueryDefinition from '../../../shared/components/general/query-definition/query-definition.component';
import SignalSql from '../../../shared/components/general/signal-sql/signal-sql.component';
import SampleClassesTable from '../../components/sample-classes-table/sample-classes-table.component';
import {withModalErrorHandler} from '../../../../core/hoc/with-model-error-handler.hoc';
import {QuerySqlTabsNames} from '../../../shared/core/query-sql-tabs/query-sql-tabs.component';
import {ValidationStatus} from '../../../../objects/models/signal.model';

interface OwnProps {
  content: Content;
  onClose?: () => void;
  [SIGNAL_ID_PATH_PARAM]?: number;
  initialTab?: QuerySqlTabsNames;
}

type AllProps = OwnProps;

const SELECTED_CONTENT_KEY = SharedSelectionKeys.CONTENT_VIEW__CONTENT;
const generateSignalDefinitionKey = key => key + 'SignalDefinition';
const generateSignalIdKey = key => key + 'SignalId';
const generateSignalKey = key => key + 'Signal';
const generateSampleClassesKey = key => key + 'Classes';
const generateTotalDistinctValuesKey = key => key + 'TotalDistinctValues';

export const ContentViewPanelComponent = (props: AllProps) => {
  const {onClose, content, [SIGNAL_ID_PATH_PARAM]: signalId, initialTab} = props;
  const {t} = useTranslation();
  const [selectedFunnelTab, setSelectedFunnelTab] = useState<string>(
    getInitialActionTabForContent(content, signalId)
  );
  const [selectedTabKey, setSelectedTabKey] = useState(initialTab || 'samples');
  const renderFunnelTab = (tabKey: string) => {
    const query = content[generateSignalDefinitionKey(tabKey)];
    const signalId = content[generateSignalIdKey(tabKey)];
    const sampleClasses = content[generateSampleClassesKey(tabKey)];
    const totalDistinctValues = content[generateTotalDistinctValuesKey(tabKey)];

    let suffix = '';
    if (exists(totalDistinctValues) && totalDistinctValues > 0) {
      suffix = ` (${totalDistinctValues})`;
    } else if (sampleClasses?.length > 0) {
      suffix = ` (${sampleClasses.length})`;
    }

    const tabs: PrimaryTab[] = [
      {
        label: `Samples ${suffix}`,
        key: 'samples',
        render: () => (
          <div className={classes.TabWrapper}>
            <SampleClassesTable
              classes={sampleClasses}
              entity={content.entity}
              sampleClassesTitle={t(TransKeys.CONTENT_CLASSES_TABLE_TAB.COLUMNS.SAMPLES)}
              emptyStateText={t(
                TransKeys.CONTENTS.CONTENT_VIEW_PANEL.TABS.CONTENT_SAMPLES.EMPTY_STATE
              )}
              noResultFoundText={t(
                TransKeys.CONTENTS.CONTENT_VIEW_PANEL.TABS.CONTENT_SAMPLES.NO_SAMPLES_EMPTY_STATE
              )}
            />
          </div>
        ),
      },
      {
        label: 'Definition',
        key: 'query',
        render: () => (
          <div className={classes.TabWrapper}>
            <QueryDefinition query={query} />
          </div>
        ),
      },
      {
        label: 'SQL',
        key: 'sql',
        render: () => (
          <div className={classes.TabWrapper}>
            <SignalSql signalId={signalId} />
          </div>
        ),
        hide: !signalId,
      },
    ];

    return (
      <PrimaryTabs
        className={classes.ContentTab}
        selected={selectedTabKey}
        onChange={setSelectedTabKey}
        tabs={tabs}
      />
    );
  };

  const funnelTabs = values(generateContentTabs()).map(tab => ({
    key: tab.key,
    title: tab.title,
    helperText: tab.helperText,
    render: () => renderFunnelTab(tab.key),
    disabled: !content[generateSignalIdKey(tab.key)],
    warningText:
      content[generateSignalKey(tab.key)]?.validationStatus === ValidationStatus.ERROR
        ? t(TransKeys.CONTENTS.SIGNAL_VALIDATION_STATUS_HELPER_TEXT)
        : null,
  }));

  return (
    <div className={classes.ContentViewPanelContainer}>
      <ModalLayout>
        <div className={classes.ContentViewPanel}>
          <FancyHeader
            icon={ContentIcon}
            title={t(TransKeys.MODELS.CONTENT)}
            onClose={onClose}
            className={classes.Header}
            caps
          />
          <div className={classes.Body}>
            <div className={classes.NameBlock}>
              <div className={classes.Name}>{content.name}</div>
              {content.shortDescription && (
                <div className={classes.Description}>{content.shortDescription}</div>
              )}
            </div>
            <div className={classes.Block}>
              <LabelWrapper label={t(TransKeys.GENERAL.LABELS.DEFINITION)}>
                <FunnelTabs
                  tabs={funnelTabs}
                  onTabChange={setSelectedFunnelTab}
                  selectedKey={selectedFunnelTab}
                />
              </LabelWrapper>
            </div>
            {content.history.length > 0 && (
              <div className={classes.Block}>
                <Modifiers history={content.history} />
              </div>
            )}
          </div>
        </div>
      </ModalLayout>
    </div>
  );
};

const ContentViewPanel = composition<AllProps>(
  ContentViewPanelComponent,
  withModalErrorHandler({
    modalTitle: TransKeys.MODELS.CONTENT,
    modalIcon: ContentIcon,
    extractErrorCodeFromProps: props => get(props.errors, 'content.errorCode'),
  }),
  withLoadBefore({
    content: {
      selectedKey: SELECTED_CONTENT_KEY,
      actionKey: SELECTED_CONTENT_KEY,
      request: getContentNetworkRequest,
      mapPayloadFromProps: props => props[CONTENT_ID_PATH_PARAM],
      shouldCall: props => props[CONTENT_ID_PATH_PARAM] !== undefined,
    },
  })
);

export default ContentViewPanel;
