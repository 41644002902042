import {NRC} from './index';
import {RequestType, toCamelCase, toSnakeCase} from 'front-core';
import {
  MemberDTO,
  MembersFiltersDTO,
  UpdateMemberPermissionRolePayloadDTO,
  UpdateTeamMemberPayloadDTO,
} from '../objects/dto/members-settings.dto';
import {toFiltersDTO} from '../objects/dto/to-filters.dto';

export const deleteMemberFromProductNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.DELETE,
  relativeUrl: `/app/users/management/${id}`,
});

export const updateMemberTeamNetworkRequest: NRC<{teamId: number}, void> = (
  payload: UpdateTeamMemberPayloadDTO
) => ({
  method: RequestType.PUT,
  relativeUrl: `/app/users/management/${payload.memberId}/team`,
  body: payload,
  bodyTransformer: toSnakeCase,
  responseTransformer: toCamelCase,
});

export const updateMemberPermissionRoleNetworkRequest: NRC<
  Pick<UpdateMemberPermissionRolePayloadDTO, 'permissionRole'>,
  void
> = (payload: UpdateMemberPermissionRolePayloadDTO) => ({
  method: RequestType.PUT,
  relativeUrl: `/app/users/management/${payload.memberId}/permission-role`,
  body: payload,
  bodyTransformer: toSnakeCase,
  responseTransformer: toCamelCase,
});

export const getMembersNetworkRequest: NRC<MemberDTO, MembersFiltersDTO> = payload => ({
  method: RequestType.GET,
  relativeUrl: `/app/users/management`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data),
});
