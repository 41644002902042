import {createRequestEpic} from 'front-core';
import {Epic} from 'redux-observable';
import {AmplitudeEvent} from '../../constants/amplitude-event';
import {ActionKey} from '../../constants/action-key';
import {ModelKey} from '../../constants/model-key';
import {
  deleteMemberFromProductNetworkRequest,
  updateMemberPermissionRoleNetworkRequest,
  updateMemberTeamNetworkRequest,
} from '../../http/members.network-requests';
import HttpClient from '../../services/http-client.service';
import {modelDeleted, modelUpdated, notifyEvent} from '../core/core.actions';
import {memberToastCreator} from '../toasts.actions';
import {MemberSettingsActionType} from './members.actions';

export const removeMemberEpic: Epic = createRequestEpic(
  {
    types: [MemberSettingsActionType.REMOVE_MEMBER],
    actionKey: ActionKey.REMOVE_MEMBER,
    request: deleteMemberFromProductNetworkRequest,
    onSuccess: (_, member) => [
      notifyEvent(AmplitudeEvent.MEMBER_DELETED, {id: member.id}),
      memberToastCreator('DELETE_SUCCESS'),
      modelDeleted(member, ModelKey.MEMBER),
    ],
    onError: () => [memberToastCreator('DELETE_ERROR')],
  },
  HttpClient
);

export const changeTeamForMemberEpic: Epic = createRequestEpic(
  {
    types: [MemberSettingsActionType.CHANGE_TEAM_FOR_MEMBER],
    actionKey: ActionKey.CHANGE_TEAM_FOR_MEMBER,
    request: updateMemberTeamNetworkRequest,
    onSuccess: (_, member) => [
      notifyEvent(AmplitudeEvent.CHANGED_TEAM_FOR_MEMBER, {id: member.id}),
      memberToastCreator('UPDATE_SUCCESS'),
      modelUpdated(member, ModelKey.MEMBER),
    ],
    onError: () => [memberToastCreator('UPDATE_ERROR')],
  },
  HttpClient
);

export const changePermissionRoleForMemberEpic: Epic = createRequestEpic(
  {
    types: [MemberSettingsActionType.CHANGE_PERMISSION_ROLE_FOR_MEMBER],
    actionKey: ActionKey.CHANGE_PERMISSION_ROLE_FOR_MEMBER,
    request: updateMemberPermissionRoleNetworkRequest,
    onSuccess: (res, member) => [
      notifyEvent(AmplitudeEvent.CHANGED_PERMISSION_ROLE_FOR_MEMBER, {id: member.id}),
      memberToastCreator('UPDATE_SUCCESS'),
      modelUpdated(member, ModelKey.MEMBER),
    ],
    onError: () => [memberToastCreator('UPDATE_ERROR')],
  },
  HttpClient
);
