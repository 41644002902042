import {
  MultiLoadResponse,
  SmartSelectorParameters,
  SmartSelectorSource,
  SmartSelector,
  useRemoteSourceStated,
} from 'ui-components';
import {useCallback, useContext, useEffect, useMemo, useRef} from 'react';
import {exists, HttpClientContext} from 'front-core';
import {segmentCategoriesSmartSelectorNetworkRequest} from '../../../../http/smart-selector.network-requests';
import classes from './smart-selector.module.scss';
import {SmartSelectorLayout} from './smart-selector-layout.component';
import {getSegmentCategoryNetworkRequest} from '../../../../http/segment-categories.network-requests';
import {CreatableSmartSelector, SmartSelectorSharedProps} from './smart-selector.shared';

interface OwnProps extends SmartSelectorSharedProps, CreatableSmartSelector {}

type AllProps = OwnProps;

export const SegmentCategorySmartSelector = (props: AllProps) => {
  const {
    placeholder,
    value,
    onChange: onChangeFromProps,
    filters,
    className,
    disabled,
    clearable,
    fullWidth,
    error,
    onCreate: onCreateFromProps,
  } = props;
  const selectorRef = useRef<any>(null);
  const http = useContext(HttpClientContext);
  const {source: table, exec} = useRemoteSourceStated({
    type: 'source',
    networkRequest: getSegmentCategoryNetworkRequest,
    initialValue: null,
  });
  const selectedTableColumn = useMemo(
    () => (value && table?.id === value ? table : null),
    [value, table]
  );
  const labelValue = useMemo(() => {
    if (value && selectedTableColumn) {
      return selectedTableColumn.name;
    }
  }, [value, selectedTableColumn]);
  const onChange = useCallback(
    (value: number, item?: any) => {
      selectorRef.current.close();
      onChangeFromProps(value, item);
    },
    [onChangeFromProps]
  );
  const onCreate = useCallback(() => {
    selectorRef.current.close();
    onCreateFromProps();
  }, [onCreateFromProps]);
  const sources: SmartSelectorSource[] = useMemo(
    () => [
      {
        key: 'categories',
        name: `Categories`,
        createLabel: 'Create Category',
        onSelect: item => onChange(item.id, item),
        onCreate: onCreateFromProps ? onCreate : undefined,
      },
    ],
    [onChange, onCreateFromProps, onCreate]
  );
  const load = useCallback(
    async (keys: string[], parameters: SmartSelectorParameters): Promise<MultiLoadResponse> => {
      // @ts-ignore
      const categories = await http.exec(
        segmentCategoriesSmartSelectorNetworkRequest({
          page: parameters.page,
          q: parameters.query,
          limit: 1000,
          orderBy: 'name',
          order: 'asc',
          ...(filters || {}),
        })
      );

      return {
        categories: {
          data: categories as any,
          hasNext: false,
          total: (categories as any).length,
        },
      };
    },
    [http, filters]
  );
  useEffect(() => {
    value && table?.id !== value && exec(value);
  }, [value, exec, table]);

  return (
    <SmartSelectorLayout
      id={'segment-category-selector'}
      label={labelValue}
      disabled={disabled}
      error={error}
      clearable={clearable && exists(value)}
      onClear={() => onChange(null)}
      placeholder={placeholder}
      className={className}
      ref={selectorRef}
      withPreview={false}
      fullWidth={fullWidth}
    >
      <SmartSelector
        className={classes.Selector}
        sources={sources}
        load={load}
        withPreview={false}
      />
    </SmartSelectorLayout>
  );
};
