import classNames from 'classnames';
import {Badge, ExpandIcon, IconButton, IndexSwitch, ModalLayout} from 'ui-components';
import classes from './image-viewer.module.scss';
import {useMemo, useState} from 'react';
import {Modal} from '@material-ui/core';
import {isArray} from 'lodash';
import Carousel from '@brainhubeu/react-carousel';

interface OwnProps {
  className?: string;
  src: string | string[];
  onClick?: (e) => void;
  modal?: boolean;
  classes?: {
    image?: string;
  };
}

type AllProps = OwnProps;

export const ImageViewer = (props: AllProps) => {
  const {src: src_, modal = true, className, classes: externalClasses, onClick} = props;
  const [open, setOpen] = useState(false);
  const [actionIndex, setActionIndex] = useState(0);
  const src = useMemo(() => (isArray(src_) ? src_ : [src_]), [src_]);

  const close = () => {
    setActionIndex(0);
    setOpen(false);
  };

  return (
    <>
      <div className={classNames(classes.ImageViewer, className)}>
        {modal && (
          <div className={classes.FullscreenIcon}>
            <Badge badgeContent={src.length} invisible={src.length === 1}>
              <IconButton
                icon={ExpandIcon}
                onClick={e => {
                  e.stopPropagation();
                  setOpen(true);
                }}
              />
            </Badge>
          </div>
        )}
        <div
          onClick={onClick}
          style={{backgroundImage: `url(${src[0]})`}}
          className={classNames(
            classes.Image,
            externalClasses?.image,
            onClick && classes.Clickable
          )}
        />
      </div>
      {modal && (
        <Modal open={open} onClose={close}>
          <div className={classes.ModalWrapper}>
            <ModalLayout onClose={close} className={classes.ImageModalViewer}>
              <div className={classes.ImageWrapper}>
                <div className={classes.IndexSwitch}>
                  <IndexSwitch
                    current={actionIndex}
                    count={src.length}
                    onChange={i => setActionIndex(i)}
                    color={'rgba(52, 131, 255, 1)'}
                  />
                </div>
                <div className={classes.Carousel}>
                  <Carousel value={actionIndex} onChange={setActionIndex}>
                    {src.map((src, idx) => (
                      <div
                        key={idx}
                        style={{backgroundImage: `url(${src})`}}
                        className={classes.Image}
                      />
                    ))}
                  </Carousel>
                </div>
              </div>
            </ModalLayout>
          </div>
        </Modal>
      )}
    </>
  );
};
