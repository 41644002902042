import {useTranslation} from 'react-i18next';
import classes from './add-slack-btn.module.scss';
import TransKeys from '../../../../../../constants/translation-keys';
import appConfig from '../../../../../../config/app.config';
import classNames from 'classnames';
import {useDemoProduct} from '../../../../../../core/hooks/use-demo-product.hook';

interface OwnProps {
  className?: string;
  size?: 'small' | 'medium';
  showText?: boolean;
}
type AllProps = OwnProps;

const SlackBtn = (props: AllProps) => {
  const {className, size = 'medium', showText = true} = props;
  const {isDemoProduct} = useDemoProduct();
  const {t} = useTranslation();

  return (
    <div className={classNames(classes.NotInstalled, className)}>
      {showText && (
        <div className={classes.PromptInstallation}>
          {t(TransKeys.USER_SETTINGS.SETTINGS.SLACK_NOTIFICATIONS.CONNECT.TITLE)}
        </div>
      )}
      <a
        className={classes.Link}
        href={isDemoProduct ? '#disabled' : `${appConfig.slackHref}`}
        target="_blank"
        rel="noreferrer"
      >
        <img
          alt="Add to Slack"
          src="https://platform.slack-edge.com/img/add_to_slack.png"
          className={classNames(
            classes.Image,
            size && classes[size],
            isDemoProduct && classes.Disabled
          )}
        />
      </a>
    </div>
  );
};

export {SlackBtn};
