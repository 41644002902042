import classNames from 'classnames';
import classes from './analyses-views.module.scss';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {useAnalysisResultList} from '../../../../hooks/use-analysis-result-list.hook';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {
  getExampleAnalysisResultsNetworkRequest,
  searchAnalysisResultsNetworkRequest,
} from '../../../../../../http/analysis-results.network-requests';
import {AnalysisResultList} from '../../../../components/analysis-result-list/analysis-result-list.component';
import {useAnalysisResultActions} from '../../../../hooks/use-analysis-result-actions.hook';
import SquigglyEmptyState from '../../../../../shared/components/general/squiggly-empty-state/squiggly-empty-state.component';
import {useProductData} from '../../../../../../core/hooks/use-product-data.hook';
import {
  Checkbox,
  useRemoteSourceStated,
  LocalStorageManager,
  ChevronDownIcon,
  IconButton,
  useLocalStorage,
} from 'ui-components';
import {exists} from 'front-core';
import {AnalysesListPlaceholder} from '../general/analyses-list-placeholder/analyses-list-placeholder.component';
import {LocalStorageKey} from '../../../../../../constants/local-storage-key';
import {useCurrentUser} from '../../../../../../core/hooks/use-user.hook';

interface OwnProps {
  className?: string;
  onFolderClicked?: (analysisFolderId: number | string) => void;
}

type AllProps = OwnProps;

const DEFAULT_FILTERS_FOR_RECENT = {
  itemsPerPage: 30,
  isAutoGenerated: false,
  isSystemGenerated: false,
};

export const RecentAnalysesView = (props: AllProps) => {
  const {onFolderClicked, className} = props;
  const {t} = useTranslation();
  const {userCreatedAnalysis} = useProductData();
  const user = useCurrentUser();
  const [showExamplesList, setShowExamplesList] = useLocalStorage('show_example_list', true);
  const [showRecentList, setShowRecentList] = useLocalStorage('show_recent_list', true);
  const analysisResultActions = useAnalysisResultActions();
  const [showOnlyMyAnalyses, setShowOnlyMyAnalyses] = useState(
    LocalStorageManager.getItem(LocalStorageKey.MY_ANALYSES_SHOW_MY_ANALYSES_FILTER)
  );
  const {
    source: examplesResponse,
    exec: getExamples,
    isLoading: isLoadingExample,
  } = useRemoteSourceStated({
    networkRequest: getExampleAnalysisResultsNetworkRequest,
  });
  const defaultFilters = useMemo(
    () => ({
      ...DEFAULT_FILTERS_FOR_RECENT,
      requestedByUserId: showOnlyMyAnalyses ? user.id : undefined,
    }),
    [showOnlyMyAnalyses, user]
  );

  const {
    listsData: recentListData,
    isLoading: isLoadingRecent,
    onPageChange,
  } = useAnalysisResultList({
    key: 'recent_results',
    extraDefaultFilters: defaultFilters,
    networkRequest: searchAnalysisResultsNetworkRequest,
  });
  const hideExamples = useMemo(() => {
    if (isLoadingExample || !exists(examplesResponse)) {
      return false;
    }
    return examplesResponse.meta.total === 0;
  }, [isLoadingExample, examplesResponse]);
  const hideRecent = useMemo(() => {
    if (isLoadingRecent || !exists(recentListData) || showOnlyMyAnalyses === true) {
      return false;
    }
    return recentListData.meta.total === 0;
  }, [isLoadingRecent, recentListData, showOnlyMyAnalyses]);

  const onChangeShowMyAnalysesFilter = useCallback(
    v => {
      LocalStorageManager.setItem(LocalStorageKey.MY_ANALYSES_SHOW_MY_ANALYSES_FILTER, v);
      setShowOnlyMyAnalyses(v);
    },
    [setShowOnlyMyAnalyses]
  );

  useEffect(() => {
    getExamples();
  }, [getExamples]);

  return (
    <div className={classNames(classes.BaseAnalysesView, className)}>
      <div className={classes.Header}>
        <div className={classes.TextHeader}>
          {t(TransKeys.ANALYSES_MAIN.FOLDERS_DEFAULT_NAME.RECENT.TITLE)}
        </div>
      </div>
      {!hideExamples && (
        <>
          <div className={classes.Title}>
            <IconButton
              icon={ChevronDownIcon}
              onClick={() => setShowExamplesList(!showExamplesList)}
              className={classNames(classes.ToggleOpen, showExamplesList && classes.Open)}
            />
            <div className={classes.Label}>
              {t(TransKeys.ANALYSES_MAIN.RECENT.EXAMPLE_ANALYSES_TITLE)}
            </div>
          </div>
          {isLoadingExample && !exists(examplesResponse?.meta?.total) && (
            <AnalysesListPlaceholder />
          )}
          {examplesResponse && examplesResponse.meta?.total > 0 && (
            <>
              <div className={classNames(classes.TableWrapper, !showExamplesList && classes.Hide)}>
                <AnalysisResultList
                  className={classes.Table}
                  {...analysisResultActions}
                  showFolderColumn={false}
                  onFolderClicked={onFolderClicked}
                  data={examplesResponse.data}
                  total={examplesResponse.meta.total}
                  page={examplesResponse.meta.page}
                  perPage={examplesResponse.meta.numPerPage}
                  showActionsColumn={false}
                  isLoading={isLoadingExample}
                />
              </div>
              {!userCreatedAnalysis && showExamplesList && (
                <SquigglyEmptyState
                  title={t(TransKeys.ANALYSES.EMPTY_STATE.EXAMPLE_ANALYSES.TITLE)}
                  subTitle={t(TransKeys.ANALYSES.EMPTY_STATE.EXAMPLE_ANALYSES.SUB_TITLE)}
                  buttonText={t(TransKeys.ANALYSES.EMPTY_STATE.EXAMPLE_ANALYSES.CTA)}
                  onClick={analysisResultActions.onRequest}
                />
              )}
            </>
          )}
        </>
      )}
      {!hideRecent && (
        <>
          <div className={classes.Title}>
            <IconButton
              icon={ChevronDownIcon}
              onClick={() => setShowRecentList(!showRecentList)}
              className={classNames(classes.ToggleOpen, showRecentList && classes.Open)}
            />
            <div className={classes.Label}>
              {t(TransKeys.ANALYSES_MAIN.RECENT.RECENT_ANALYSES_TITLE)}
            </div>
            {showRecentList && (
              <div className={classes.Actions}>
                <Checkbox
                  label={t(TransKeys.ANALYSES_MAIN.ACTIONS.SHOW_ONLY_USER_CREATED_ANALYSES_FILTER)}
                  checked={showOnlyMyAnalyses}
                  onChange={onChangeShowMyAnalysesFilter}
                  border
                />
              </div>
            )}
          </div>
          {isLoadingRecent && !exists(recentListData?.meta?.total) && (
            <AnalysesListPlaceholder className={classes.RecentPlaceholder} />
          )}
          {recentListData && (recentListData.meta?.total > 0 || showOnlyMyAnalyses) && (
            <div className={classNames(classes.TableWrapper, !showRecentList && classes.Hide)}>
              <AnalysisResultList
                className={classes.Table}
                {...analysisResultActions}
                showFolderColumn={true}
                onFolderClicked={onFolderClicked}
                data={recentListData.list}
                total={recentListData.meta.total}
                page={recentListData.meta.page}
                perPage={recentListData.meta.numPerPage}
                isLoading={isLoadingRecent}
                onPageChange={onPageChange}
                emptyStateRow={
                  <div className={classes.EmptyStateRow}>
                    {t(TransKeys.ANALYSES.EMPTY_STATE.TITLE)}
                  </div>
                }
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
