import {ConfidenceIntervalConfig} from '../../../../../../../../objects/models/user-settings.model';
import {AnomalyMode} from '../../../../../../../../objects/models/homepage.model';
import i18n from 'i18next';
import TransKeys from 'translations';
import {isSignificantValue} from '../../../../../../../../utils/general.utils';
import {exists} from 'front-core';
import pluralize from 'pluralize';
import {HomepageMetricChartSeries} from './homepage-metric-chart.component';
import {ChartMode} from '../../../../homepage-summary.types';

export const GOOD_COLOR = 'green';
export const BAD_COLOR = 'red';

export const generateChartIdForSeriesSamples = (id: string | number) => `samples_${id}`;
export const generateChartIdForSeriesDenominator = (id: string | number) => `denominator_${id}`;

export const getPointTooltipData = (
  isSignificantPositive: boolean,
  config: ConfidenceIntervalConfig
) => {
  if (isSignificantPositive === undefined || config === undefined) {
    return {
      pointTooltipTitle: undefined,
      pointTooltipCta: undefined,
    };
  }
  if (config.anomalyMode === AnomalyMode.LOOPS_ALGO) {
    if (isSignificantPositive) {
      return {
        pointTooltipTitle: i18n.t(TransKeys.HOMEPAGE.METRIC_CHART.LABELS.INVESTIGATE_RISE_TITLE),
        pointTooltipCta: i18n.t(TransKeys.HOMEPAGE.METRIC_CHART.LABELS.INVESTIGATE_RISE_CTA),
      };
    }
    return {
      pointTooltipTitle: i18n.t(TransKeys.HOMEPAGE.METRIC_CHART.LABELS.INVESTIGATE_DROP_TITLE),
      pointTooltipCta: i18n.t(TransKeys.HOMEPAGE.METRIC_CHART.LABELS.INVESTIGATE_DROP_CTA),
    };
  }
  if (config.anomalyMode === AnomalyMode.XOX_SAME_WEEKDAY) {
    if (isSignificantPositive) {
      return {
        pointTooltipTitle: i18n.t(
          TransKeys.HOMEPAGE.METRIC_CHART.LABELS.WOW_INVESTIGATE_RISE_TITLE,
          {percent: config.anomalyThreshold * 100}
        ),
        pointTooltipCta: i18n.t(TransKeys.HOMEPAGE.METRIC_CHART.LABELS.INVESTIGATE_RISE_CTA),
      };
    }
    return {
      pointTooltipTitle: i18n.t(TransKeys.HOMEPAGE.METRIC_CHART.LABELS.WOW_INVESTIGATE_DROP_TITLE, {
        percent: config.anomalyThreshold * 100,
      }),
      pointTooltipCta: i18n.t(TransKeys.HOMEPAGE.METRIC_CHART.LABELS.INVESTIGATE_DROP_CTA),
    };
  }
};

export const transformSeriesToDatasets = (
  defaultName: string,
  series: HomepageMetricChartSeries,
  isPercentage?: boolean,
  confidenceIntervalConfig?: ConfidenceIntervalConfig,
  hasCountEntities?: boolean,
  denominatorEntityName?: string,
  chartMode?: ChartMode
) => {
  if (!series || series.samples.length === 0) {
    return undefined;
  }
  const valuesDataset = series.samples
    .filter(s => (chartMode === ChartMode.PREDICTED ? !s.isPartial : true))
    .map((s, idx) => {
      const isSignificant = isSignificantValue(s.value, s.lower, s.upper);
      let markColor = undefined;
      let isSignificantPositive = undefined;
      if (isSignificant && !s.isPartial) {
        if (s.value > s.upper) {
          markColor = GOOD_COLOR;
          isSignificantPositive = true;
        } else {
          markColor = BAD_COLOR;
          isSignificantPositive = false;
        }
      }
      const base: any = {
        x: s.datetime,
        y: isPercentage ? s.value * 100 : s.value,
        dashed: s.isPartial,
        markColor,
        isSignificantPositive,
        metadata: {sample: s},
        clickable: !s.isPartial,
        upper: s.isPartial || !exists(s.upper) ? undefined : isPercentage ? s.upper * 100 : s.upper,
        lower: s.isPartial || !exists(s.lower) ? undefined : isPercentage ? s.lower * 100 : s.lower,
        ...getPointTooltipData(isSignificantPositive, confidenceIntervalConfig),
      };
      return base;
    });

  let denominatorDataset;
  if (hasCountEntities) {
    denominatorDataset = series.samples
      .filter(s => (chartMode === ChartMode.PREDICTED ? !s.isPartial : true))
      .map(
        s =>
          ({
            x: s.datetime,
            y: s.denominator || 0,
            dashed: s.isPartial,
          }) as any
      );
  }

  if (chartMode === ChartMode.PREDICTED && series.predictedSamples) {
    for (const s of series.predictedSamples) {
      valuesDataset.push({
        x: s.datetime,
        y: isPercentage ? s.value * 100 : s.value,
        dashed: true,
        upper: !exists(s.upper) ? undefined : isPercentage ? s.upper * 100 : s.upper,
        lower: !exists(s.lower) ? undefined : isPercentage ? s.lower * 100 : s.lower,
      } as any);
      if (hasCountEntities) {
        denominatorDataset.push({
          x: s.datetime,
          y: s.denominator || 0,
          dashed: true,
        });
      }
    }
  }

  const datasets = [];
  datasets.push({
    id: generateChartIdForSeriesSamples(series.id),
    label: series.name || defaultName,
    data: valuesDataset,
  });
  if (denominatorDataset) {
    datasets.push({
      id: generateChartIdForSeriesDenominator(series.id),
      label: pluralize(denominatorEntityName),
      data: denominatorDataset,
      yAxis: 'secondary',
    });
  }

  return datasets;
};
