import yup from '../../../../../config/yup.config';
import {PARAMETERS_METADATA_KEY} from '../../../../../constants/parameters-saved-keys';
import {get, isEmpty, values} from 'lodash';
import {TableEntity} from '../../../../../objects/models/table.model';
import {queryElementValidatorFactory} from '../../../../../objects/dto/query-builder.dto';
import moment from 'moment/moment';
import {TIME_FORMATS} from '../../../../../constants/time-formats';
import {MetricType} from '../../../../../objects/models/metric.model';
import {eventPropertiesSignalsValidator, segmentsGroupsValidator} from '../shared-validators';
import {ANALYSIS_MODE_KEY, GOAL_TYPE_KEY} from '../analysis-101/analysis-101-form.component';
import {exists} from 'front-core';
import i18n from 'i18next';
import TransKeys from '../../../../../constants/translation-keys';
import {ParameterType} from 'ui-components';
import {
  AnalysisParametersTransformer,
  baseAnalysisParametersTransformer,
  SPECIAL_TAGS_TRANSLATIONS,
} from '../shared-transformers';
import httpClientService from '../../../../../services/http-client.service';
import {validateRootCauseAnalysisSignalTimeframeRequest} from '../../../../../http/validations.network-requests';

export const analysis139ParametersValidator = yup.object().shape({
  parameters: yup
    .object()
    .shape({
      [PARAMETERS_METADATA_KEY]: yup.object().nullable(),
      entity: yup.string().oneOf(values(TableEntity)).required(),
      goal: yup.number().required(),
      population_filter: queryElementValidatorFactory(false),
      time_aggregation: yup.string().oneOf(['day', 'week', 'month']),
      use_loops_anomaly_detection_algo: yup.boolean().nullable(),
      analysis_mode: yup.string().nullable(),
      run_population_size_explainer: yup.boolean(),
      run_group_size_explainer: yup.boolean(),
      run_error_explainer: yup.boolean(),
      run_experiment_explainer: yup.boolean(),
      ...segmentsGroupsValidator(),
      ...eventPropertiesSignalsValidator({
        validateTagName: false,
      }),
    })
    .required(),
  runParameters: yup
    .object()
    .shape({
      start_date_anomaly: yup.string().required(),
      start_date_comparing: yup.string().nullable(),
    })
    .test({
      name: 'validate_root_cause_signal_timeframe',
      test: async function (dates, context) {
        const {parameters} = context.parent;
        const {time_aggregation, goal} = parameters;
        const startDateAnomaly = moment
          .utc(dates.start_date_anomaly)
          .format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT);

        try {
          await httpClientService.exec(
            validateRootCauseAnalysisSignalTimeframeRequest({
              signalId: goal,
              timeAggregation: time_aggregation,
              startDateAnomaly,
            })
          );
          return true;
        } catch (e) {
          const {data} = e;
          if (!data || data.status !== 400 || !data.parameters || isEmpty(data.parameters)) {
            return this.createError({
              message: 'An error occurred',
              path: `${context.path}.start_date_anomaly`,
            });
          }
          const errorParameters = data.parameters;
          const formattedMinimumDate = moment
            .utc(errorParameters.minimum_date)
            .format(TIME_FORMATS.DEFAULT_DATE_FORMAT);

          return this.createError({
            message: `${formattedMinimumDate} is the last available full ${errorParameters.unit} for the selected KPI`,
            path: `${context.path}.start_date_anomaly`,
          });
        }
      },
    })
    .test({
      name: 'start_date_anomaly',
      test: function (dates, context) {
        const {parameters} = context.parent;
        const {time_aggregation} = parameters;
        const startDateAnomaly = moment.utc(
          dates.start_date_anomaly,
          TIME_FORMATS.PARAMETER_DATE_FORMAT
        );
        let maxDate = moment.utc();
        switch (time_aggregation) {
          case 'day':
            maxDate = maxDate.subtract(1, 'day');
            break;
          case 'week':
            maxDate = maxDate.subtract(1, 'week');
            break;
          case 'month':
            maxDate = maxDate.subtract(1, 'month');
            break;
        }
        if (startDateAnomaly.isAfter(maxDate)) {
          return this.createError({
            message: `Anomaly date must be in the past`,
            path: `${context.path}.start_date_anomaly`,
          });
        }

        if (time_aggregation === 'week' && startDateAnomaly.day() !== 1) {
          return this.createError({
            message: `Anomaly date must be Monday when time granularity is a week`,
            path: `${context.path}.start_date_anomaly`,
          });
        }
        if (time_aggregation === 'month' && startDateAnomaly.date() !== 1) {
          return this.createError({
            message: `Anomaly date must be first day of the month when time granularity is a month`,
            path: `${context.path}.start_date_anomaly`,
          });
        }
        return true;
      },
    })
    .test({
      name: 'start_date_comparing',
      test: function (dates, context) {
        const {parameters} = context.parent;
        const {time_aggregation, use_loops_anomaly_detection_algo} = parameters;
        // If no startDayComparing and use_loops_anomaly_detection_algo
        // then no need to validate startDayComparing
        if (!dates.start_date_comparing && use_loops_anomaly_detection_algo) {
          return true;
        }
        // If no startDayComparing then use_loops_anomaly_detection_algo should be true
        if (!dates.start_date_comparing && !use_loops_anomaly_detection_algo) {
          return this.createError({
            message: `Comparing to must be provided when not using loops anomaly detection model`,
            path: `${context.path}.start_date_comparing`,
          });
        }
        const startDateAnomaly = moment.utc(
          dates.start_date_anomaly,
          TIME_FORMATS.PARAMETER_DATE_FORMAT
        );
        const startDateComparing = moment.utc(
          dates.start_date_comparing,
          TIME_FORMATS.PARAMETER_DATE_FORMAT
        );

        if (startDateComparing.isAfter(startDateAnomaly)) {
          return this.createError({
            message: `Comparing to must be before anomaly date`,
            path: `${context.path}.start_date_comparing`,
          });
        }
        // startDayComparing must be Monday
        if (time_aggregation === 'week' && startDateComparing.day() !== 1) {
          return this.createError({
            message: 'Comparing to must be Monday when time granularity is a week',
            path: `${context.path}.start_date_comparing`,
          });
        }
        if (time_aggregation === 'month' && startDateComparing.date() !== 1) {
          return this.createError({
            message: 'Comparing to must be the 1st of the month when time granularity is a month',
            path: `${context.path}.start_date_comparing`,
          });
        }
        const diff = moment.duration(startDateAnomaly.diff(startDateComparing));
        if (time_aggregation === 'week' && diff.asDays() < 7) {
          return this.createError({
            message: `Minimum time frame must be at least 7 days when the selected time granularity is a day or a week`,
            path: `${context.path}.start_date_comparing`,
          });
        }
        const diffInMonths = startDateAnomaly.diff(startDateComparing, 'months', true);
        if (time_aggregation === 'month' && diffInMonths < 1) {
          return this.createError({
            message: `Minimum time frame must be at least 1 month when the selected time granularity is a month`,
            path: `${context.path}.start_date_comparing`,
          });
        }
        return true;
      },
    })
    .test({
      name: 'goal_type_time_aggregation',
      test: function (dates, context) {
        const {parameters} = context.parent;
        const {time_aggregation, [PARAMETERS_METADATA_KEY]: ui_metadata} = parameters;
        const {metric_type} = ui_metadata || {};
        if (
          ![MetricType.DAU, MetricType.WAU, MetricType.MAU, MetricType.L7].includes(metric_type)
        ) {
          return true;
        }
        if (metric_type === MetricType.DAU && time_aggregation !== 'day') {
          return this.createError({
            message: `Time aggregation must be day when the selected kpi is DAU`,
            path: `${context.path}.time_aggregation`,
          });
        }
        if ([MetricType.WAU, MetricType.L7].includes(metric_type) && time_aggregation !== 'week') {
          return this.createError({
            message: `Time aggregation must be calendaric week when the selected kpi is WAU or L7`,
            path: `${context.path}.time_aggregation`,
          });
        }
        if (metric_type === MetricType.MAU && time_aggregation !== 'month') {
          return this.createError({
            message: `Time aggregation must be month when the selected kpi is MAU`,
            path: `${context.path}.time_aggregation`,
          });
        }
        return true;
      },
    }),
});

export const analysis139ParametersTransformer: AnalysisParametersTransformer = (
  values,
  schema,
  productEntities
) => {
  [values, schema] = baseAnalysisParametersTransformer(values, schema, productEntities);
  const newValues = {...values};
  if (GOAL_TYPE_KEY in schema) {
    delete schema[GOAL_TYPE_KEY];
  }

  if ('goal' in schema) {
    schema['goal'].order = 1;
  }

  if (ANALYSIS_MODE_KEY in schema && exists(newValues[ANALYSIS_MODE_KEY])) {
    schema[ANALYSIS_MODE_KEY].order = 2;
    newValues[ANALYSIS_MODE_KEY] = i18n.t(
      i18n.t(
        TransKeys.ANALYSIS_FORMS.ANALYSIS_101.ANALYSIS_MODES[
          newValues[ANALYSIS_MODE_KEY].toUpperCase()
        ]?.LABEL
      )
    );
  }

  if ('time_aggregation' in schema) {
    schema['time_aggregation'].order = 3;
  }

  if ('start_date_anomaly' in schema) {
    schema['start_date_anomaly'].order = 4;
  }

  if ('start_date_comparing' in schema) {
    schema['start_date_comparing'].order = 5;
  }

  if ('use_loops_anomaly_detection_algo' in schema) {
    schema['use_loops_anomaly_detection_algo'].order = 6;
  }

  if ('population_filter' in schema) {
    schema['population_filter'].order = 7;
  }

  schema['included_segments_tag'] = {
    name: 'Segment Groups',
    type: ParameterType.__MERGED_SEGMENT_GROUPS,
    key: 'included_segments_tag',
  };
  newValues['included_segments_tag'] = [
    ...(
      (newValues['included_segments_tag'] || '')
        .split(',')
        .map(v => get(SPECIAL_TAGS_TRANSLATIONS, v, v)) || []
    ).filter(i => i),
    ...(newValues['included_segments_signals'] || []),
  ];
  delete schema['included_segments_signals'];

  if ('excluded_segments' in schema) {
    schema['excluded_segments'].order = 8;
  }

  if ('included_event_properties_tag' in schema) {
    delete schema['included_event_properties_tag'];
    schema['event_properties'] = {
      name: 'Event Properties',
      type: ParameterType.STRING,
      key: 'event_properties',
      order: 1,
    };
    newValues['event_properties'] = 'All Event Properties';
  } else if ('event_properties' in schema) {
    schema['event_properties'].order = 1;
  }

  return [newValues, schema];
};
