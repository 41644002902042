import {useCallback, useMemo} from 'react';
import {BaseBoundedRevenueQueryBuilder} from './base-bounded-revenue-query-builder.component';
import {cloneDeep, get, keys, set} from 'lodash';
import {QueryBuilderFactory} from 'ui-components';
import {BOUNDED_ACTION_NUMERIC_PARAMETER_MAPPING as PARAMETER_MAPPING} from '../query-builders.config';
import {
  METADATA_KEY,
  PARAMETERS_METADATA_KEY,
} from '../../../../../constants/parameters-saved-keys';
import {TableEntity} from '../../../../../objects/models/table.model';
import {SelectorModelType} from '../../smart-selector/advanced-smart-selector.component';
import {MilestoneType} from '../../../../../objects/models/milestone.model';
import {TIME_UNIT_PLURAL_OPTIONS} from '../../../../../constants/options';
import {AggregationStrategy} from '../../../../../objects/models/signal.model';

interface OwnProps {
  query: any;
  onChange?: (parameters: any) => void;
  onSignalInfo?: (value: string | number) => void;
  entityContext?: TableEntity;
  refDateSignalFilters?: any;
  refDateAllowTypes?: SelectorModelType[];
  goalSignalFilters?: any;
  errors?: any;
  disabled?: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const BUILDER_COMPONENT_NAME = 'BoundedRevenueQueryBuilder';
export const TEMPLATE_NAME = 'bounded_action_nu';

export const createBoundedRevenueInitialQuery = (getSignalByTag, entity) => {
  const q = QueryBuilderFactory.createTemplate(TEMPLATE_NAME);
  const joinDateSignal = getSignalByTag(MilestoneType.JOIN_DATE, entity);

  set(q, PARAMETER_MAPPING.ref_date, QueryBuilderFactory.createSignalColumn(joinDateSignal?.id));
  set(q, PARAMETER_MAPPING.goal_bound_unit, TIME_UNIT_PLURAL_OPTIONS.options[1].value);
  set(q, PARAMETER_MAPPING.goal_bound_n_start, 0);
  set(q, PARAMETER_MAPPING.goal_bound_n_end, 2);
  set(q, PARAMETER_MAPPING.dimension_aggregation, AggregationStrategy.SUM);

  q[PARAMETERS_METADATA_KEY] = {
    [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: BUILDER_COMPONENT_NAME,
  };

  return q;
};

const mapper = (value: any) => ({
  goal: get(value, PARAMETER_MAPPING.goal),
  ref_date: get(value, PARAMETER_MAPPING.ref_date),
  goal_bound_unit: get(value, PARAMETER_MAPPING.goal_bound_unit),
  goal_bound_n_start: get(value, PARAMETER_MAPPING.goal_bound_n_start),
  goal_bound_n_end: get(value, PARAMETER_MAPPING.goal_bound_n_end),
  dimension_aggregation: get(value, PARAMETER_MAPPING.dimension_aggregation),
});

export const BoundedRevenueQueryBuilder = (props: AllProps) => {
  const {
    errors,
    onChange,
    onSignalInfo,
    query,
    disabled,
    entityContext,
    className,
    refDateSignalFilters,
    refDateAllowTypes,
    goalSignalFilters,
  } = props;
  const mappedValue = useMemo(() => mapper(query), [query]);
  const mappedErrors = useMemo(() => mapper(errors), [errors]);

  const mappedOnChange = useCallback(
    changes => {
      const newValue = cloneDeep(query);

      for (const k of keys(changes)) {
        set(newValue, PARAMETER_MAPPING[k], changes[k]);
      }
      newValue[PARAMETERS_METADATA_KEY][METADATA_KEY.BUILDER_COMPONENT_NAME_KEY] =
        BUILDER_COMPONENT_NAME;
      onChange && onChange(newValue);
    },
    [onChange, query]
  );

  return (
    <BaseBoundedRevenueQueryBuilder
      onChange={mappedOnChange}
      value={mappedValue}
      errors={mappedErrors}
      entityContext={entityContext}
      onSignalInfo={onSignalInfo}
      className={className}
      disabled={disabled}
      refDateSignalFilters={refDateSignalFilters}
      refDateAllowTypes={refDateAllowTypes}
      goalSignalFilters={goalSignalFilters}
    />
  );
};
