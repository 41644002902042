import classNames from 'classnames';
import classes from '../../analysis-forms.module.scss';
import {ParameterInputWrapper} from '../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {ParametersFormContext} from '../../../../shared/core/parameters-form/parameters-form.context';
import {useProductData} from '../../../../../core/hooks/use-product-data.hook';
import TransKeys from '../../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {TableEntity} from '../../../../../objects/models/table.model';
import {EntitySelector} from '../../components/ui-selectors/entity-selector/entity-selector.component';
import {AnalysisFormProps} from '../../analysis-forms.types';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {PanelsContext} from '../../../../../core/contexts/panels.context';
import {createUndefinedObject} from '../../../../../utils/general.utils';
import {values} from 'lodash';
import {SegmentFilterSelector} from '../../components/ui-selectors/segment-filter-selector/segment-filter-selector.component';
import {METRIC_ID_PATH_PARAM} from '../../../../../constants/app-routes';
import {PanelKey} from '../../../../../constants/panels';
import {ALLOW_AD_HOC_PROP_KEY} from '../../../../../constants/shared-component-prop-key';
import {Metric} from '../../../../../objects/models/metric.model';
import {MetricForSignalSmartSelector} from '../../../../shared/core/smart-selector/metric-for-signal-smart-selector.component';
import usePermissions from '../../../../../core/hooks/use-permissions.hook';
import {Action, Subject} from '../../../../../constants/permissions';
import AnomalyPeriodSelector from '../../components/ui-selectors/anomaly-period-selector/anomaly-period-selector.component';
import {LabelWrapper, SwitchActions} from 'ui-components';
import {useMetricKPIAnalysis} from '../../hooks/use-metric-kpi-analysis.hook';
import {
  ANALYSIS_MODE_KEY,
  createKPISignalFilters,
} from '../analysis-101/analysis-101-form.component';
import {PARAMETERS_METADATA_KEY} from '../../../../../constants/parameters-saved-keys';
import {SegmentCategoriesSelector} from '../../components/ui-selectors/segment-categories-selector/segment-categories-selector.component';
import {ExtendedParameters} from '../../../../shared/form/form-layout/extended-parameters/extended-parameters.component';
import {SimpleBooleanSelector} from '../../components/ui-selectors/simple-boolean-selector/simple-boolean-selector.component';
import {EventPropertiesSelector} from '../../components/ui-selectors/event-properties-selector/event-properties-selector.component';
import {useFeatureIsOn} from '@growthbook/growthbook-react';
import {FeatureFlag} from '../../../../../constants/feature-flags';

const GOAL_KEY = 'goal';

const entitySchemaMapping = {
  entity: 'entity',
};
const TIME_GRANULARITY_SCHEMA_MAPPING = {
  time_granularity: 'time_aggregation',
};
const SEGMENT_EXPLAINER_SCHEMA_MAPPING = {
  boolean_key: 'run_population_size_explainer',
};
const GROUP_SIZE_EXPLAINER_SCHEMA_MAPPING = {
  boolean_key: 'run_group_size_explainer',
};
const ERROR_EXPLAINER_SCHEMA_MAPPING = {
  boolean_key: 'run_error_explainer',
};
const EXPERIMENT_EXPLAINER_SCHEMA_MAPPING = {
  boolean_key: 'run_experiment_explainer',
};

const segmentFilterSchemaMapping = {
  population_filter: 'population_filter',
};
const anomalyPeriodSelector = {
  start_date_anomaly: 'start_date_anomaly',
  start_date_comparing: 'start_date_comparing',
  use_loops_anomaly_detection_algo: 'use_loops_anomaly_detection_algo',
  time_granularity: 'time_aggregation',
};
const segmentCategoriesSchemaMapping = {
  included_segments_tag: 'included_segments_tag',
  included_segments_signals: 'included_segments_signals',
};
const eventPropertiesSchemaMapping = {
  event_properties: 'event_properties',
  included_event_properties_tag: 'included_event_properties_tag',
};

export const Analysis139Form = (props: AnalysisFormProps) => {
  const {onSignalInfo, className} = props;
  const showModelEventPropertySelector = useFeatureIsOn(FeatureFlag.MODEL_PROPERTY_EVENT as any);
  const {errors, parameters, changeParametersValue, registerDefaultHandler, removeDefaultHandler} =
    useContext(ParametersFormContext);
  const {openSecondaryPanel} = useContext(PanelsContext);
  const {t} = useTranslation();
  const {can} = usePermissions();
  const [isOpenAdvancedParams, setIsOpenAdvancedParams] = useState(false);
  const {productEntities, defaultTableEntity} = useProductData();
  const entityContext = parameters[entitySchemaMapping.entity];

  useEffect(() => {
    registerDefaultHandler('analysis_139', parameters => {
      const defaults = {};
      defaults[entitySchemaMapping.entity] = defaultTableEntity;
      defaults[anomalyPeriodSelector.use_loops_anomaly_detection_algo] = true;
      defaults[TIME_GRANULARITY_SCHEMA_MAPPING.time_granularity] = 'week';
      defaults[SEGMENT_EXPLAINER_SCHEMA_MAPPING.boolean_key] = true;
      defaults[GROUP_SIZE_EXPLAINER_SCHEMA_MAPPING.boolean_key] = true;
      defaults[ERROR_EXPLAINER_SCHEMA_MAPPING.boolean_key] = true;
      defaults[EXPERIMENT_EXPLAINER_SCHEMA_MAPPING.boolean_key] = true;
      return defaults;
    });

    return () => {
      removeDefaultHandler('analysis_139');
    };
  }, [registerDefaultHandler, removeDefaultHandler, defaultTableEntity]);
  // Memos
  const metricFilters = useMemo(() => createKPISignalFilters(entityContext), [entityContext]);
  const {metric, onMetricSelected, showSelectMode, usageActions} = useMetricKPIAnalysis();
  const eventPropertiesOptions = useMemo(
    () =>
      (metric?.eventProperties || []).map(ep => ({
        signalId: ep.signal_id,
        name: ep.name,
      })),
    [metric]
  );

  const onChangeEntityContext = useCallback(
    (entity: TableEntity) => {
      const resetKeys = [
        GOAL_KEY,
        ANALYSIS_MODE_KEY,
        segmentFilterSchemaMapping.population_filter,
        ...values(segmentCategoriesSchemaMapping),
      ];
      const resetParameters = createUndefinedObject(resetKeys);
      changeParametersValue({
        [entitySchemaMapping.entity]: entity,
        ...resetParameters,
        // Reset metadata see useMetricKPIAnalysis
        [PARAMETERS_METADATA_KEY]: {},
      });
    },
    [changeParametersValue]
  );

  const onCreateKPI = useCallback(
    (metricId?: number) => {
      openSecondaryPanel(PanelKey.METRIC_FORM_PANEL, {
        [ALLOW_AD_HOC_PROP_KEY]: true,
        [METRIC_ID_PATH_PARAM]: metricId,
        onSuccess: (metric: Metric) => {
          onMetricSelected(metric.signalId, metric);
        },
      });
    },
    [openSecondaryPanel, onMetricSelected]
  );

  return (
    <div className={classNames(classes.AnalysisForm, className)}>
      <EntitySelector
        value={parameters}
        productEntities={productEntities}
        schemaKeysMapping={entitySchemaMapping}
        onChange={v => onChangeEntityContext(v[entitySchemaMapping.entity])}
        className={classes.Parameter}
      />
      <ParameterInputWrapper
        title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_139.GOAL.TITLE)}
        subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_139.GOAL.SUB_TITLE)}
        helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_139.GOAL.HELPER_TEXT)}
        className={classes.Parameter}
        error={errors?.[GOAL_KEY]}
      >
        <MetricForSignalSmartSelector
          placeholder={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_139.GOAL.SELECT_EXISTING)}
          value={parameters[GOAL_KEY]}
          error={Boolean(errors?.[GOAL_KEY])}
          onChange={onMetricSelected}
          onCreate={can(Subject.METRIC, Action.CREATE) ? onCreateKPI : undefined}
          onSignalInfo={onSignalInfo}
          filters={metricFilters}
          addButton={can(Subject.METRIC, Action.CREATE)}
        />
        {showSelectMode && (
          <>
            <div className={classes.MarginBottom} />
            <LabelWrapper label={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_101.DEFINE_KPI.SELECT_MODE)}>
              <SwitchActions showActionsLabel actions={usageActions} />
            </LabelWrapper>
          </>
        )}
      </ParameterInputWrapper>
      <AnomalyPeriodSelector
        className={classes.Parameter}
        errors={errors}
        value={parameters}
        schemaKeysMapping={anomalyPeriodSelector}
        onChange={changeParametersValue}
      />
      {entityContext && (
        <SegmentCategoriesSelector
          onChange={changeParametersValue}
          value={parameters}
          schemaKeysMapping={segmentCategoriesSchemaMapping}
          className={classes.Parameter}
          entityContext={entityContext}
          errors={errors}
        />
      )}
      {showModelEventPropertySelector && entityContext && (
        <EventPropertiesSelector
          onChange={changeParametersValue}
          value={parameters}
          schemaKeysMapping={eventPropertiesSchemaMapping}
          options={eventPropertiesOptions}
          className={classes.Parameter}
          errors={errors}
        />
      )}
      {entityContext && (
        <SegmentFilterSelector
          onChange={changeParametersValue}
          value={parameters}
          className={classes.Parameter}
          schemaKeysMapping={segmentFilterSchemaMapping}
          entityContext={entityContext}
          errors={errors}
        />
      )}
      <ExtendedParameters
        className={classes.SpaceBottom}
        label={t(TransKeys.GENERAL.LABELS.ADVANCED_PARAMETERS)}
        isOpen={isOpenAdvancedParams}
        onOpenChanged={() => setIsOpenAdvancedParams(!isOpenAdvancedParams)}
      >
        <SimpleBooleanSelector
          title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_139.SEGMENT_EXPLAINER.TITLE)}
          subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_139.SEGMENT_EXPLAINER.SUB_TITLE)}
          helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_139.SEGMENT_EXPLAINER.HELPER_TEXT)}
          className={classes.Parameter}
          onChange={changeParametersValue}
          schemaKeysMapping={SEGMENT_EXPLAINER_SCHEMA_MAPPING}
          value={parameters}
          errors={errors}
        />
        <SimpleBooleanSelector
          title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_139.GROUP_SIZE_EXPLAINER.TITLE)}
          subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_139.GROUP_SIZE_EXPLAINER.SUB_TITLE)}
          helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_139.GROUP_SIZE_EXPLAINER.HELPER_TEXT)}
          className={classes.Parameter}
          onChange={changeParametersValue}
          schemaKeysMapping={GROUP_SIZE_EXPLAINER_SCHEMA_MAPPING}
          value={parameters}
          errors={errors}
        />
        <SimpleBooleanSelector
          title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_139.ERROR_EXPLAINER.TITLE)}
          subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_139.ERROR_EXPLAINER.SUB_TITLE)}
          helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_139.ERROR_EXPLAINER.HELPER_TEXT)}
          className={classes.Parameter}
          onChange={changeParametersValue}
          schemaKeysMapping={ERROR_EXPLAINER_SCHEMA_MAPPING}
          value={parameters}
          errors={errors}
        />
        <SimpleBooleanSelector
          title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_139.EXPERIMENT_EXPLAINER.TITLE)}
          subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_139.EXPERIMENT_EXPLAINER.SUB_TITLE)}
          helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_139.EXPERIMENT_EXPLAINER.HELPER_TEXT)}
          className={classes.Parameter}
          onChange={changeParametersValue}
          schemaKeysMapping={EXPERIMENT_EXPLAINER_SCHEMA_MAPPING}
          value={parameters}
          errors={errors}
        />
      </ExtendedParameters>
    </div>
  );
};
