import classes from './viewers.module.scss';
import classNames from 'classnames';

interface OwnProps {
  controlLabel: string;
  others: string[];
  className?: string;
}

export const ABTestHeaderViewer = (props: OwnProps) => {
  const {controlLabel, others, className} = props;
  return (
    <div className={classNames(classes.ABTestHeader, className)}>
      <div className={classes.Left}>
        <div className={classNames(classes.Label, classes.Control)}>{controlLabel}</div>
      </div>
      <div className={classes.Center}>
        <div className={classes.Trophy} />
      </div>
      <div className={classes.Right}>
        {others.map(other => (
          <div key={other} className={classNames(classes.Label, classes.Other)}>
            {other}
          </div>
        ))}
      </div>
    </div>
  );
};
