import classNames from 'classnames';
import classes from './model-series-warnining-table.module.scss';
import {
  AdvancedTable,
  TableColumn as TableColumnDefinition,
} from '../../../../../shared/components/general/table/table.component';
import {useMemo} from 'react';
import {TIME_FORMATS} from '../../../../../../constants/time-formats';
import moment from 'moment';

interface ModelSeriesWarning {
  datetime: string;
  value?: number;
  warning: string;
}

interface OwnProps {
  warnings: ModelSeriesWarning[];
  className?: string;
}

type AllProps = OwnProps;

export const ModelSeriesWarningTable = (props: AllProps) => {
  const {warnings, className} = props;

  const tableColumnDefinition: TableColumnDefinition[] = useMemo(
    () => [
      {
        key: 'date',
        title: 'Date',
        sortable: true,
        width: '20%',
        render: (warn: ModelSeriesWarning) => (
          <div className={classes.ColumnName}>
            {moment(warn.datetime).format(TIME_FORMATS.DATE_CHART_SHORT)}
          </div>
        ),
      },
      {
        key: 'value',
        title: 'Value',
        sortable: true,
        width: '20%',
        render: (warn: ModelSeriesWarning) => <span>{warn.value}</span>,
      },
      {
        key: 'warning',
        title: 'Warning',
        sortable: true,
        width: '60%',
        render: (warn: ModelSeriesWarning) => <span>{warn.warning}</span>,
      },
    ],
    []
  );

  return (
    <div className={classNames(className)}>
      <AdvancedTable
        dataKey={'date'}
        columns={tableColumnDefinition}
        data={warnings}
        pagination={true}
        perPage={10}
        total={warnings.length}
        emptyStateRow={<span>No warnings were found</span>}
      />
    </div>
  );
};
