import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {
  ChildRenderer,
  ColumnType,
  DocumentElementType,
  PhenomenasFigure,
  PhenomenasViewer,
  useMountState,
  ChildRendererInjectedProps,
  DocumentPhenomenasContext,
} from 'ui-components';
import {exists} from 'front-core';
import {Phenomena} from '../../../../../objects/models/phenomena.model';
import {GenericLoading} from '../../../components/general/generic-loading/generic-loading.component';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../../constants/translation-keys';
import classes from './viewers.module.scss';
import classNames from 'classnames';
import {keyBy} from 'lodash';
import {PanelKey} from '../../../../../constants/panels';
import {UsefulResourceType} from '../../../../../objects/models/useful.model';
import {useIsUseful} from '../../../../../core/hooks/user-reactions.hook';
import {PanelsContext} from '../../../../../core/contexts/panels.context';

const getNextStatusForPhenomena = (
  currentStatus: boolean | null,
  nextStatus: boolean | null
): boolean | null => {
  let updateStatus;
  if (nextStatus && currentStatus) {
    updateStatus = null;
  } else if (!nextStatus && currentStatus === false) {
    updateStatus = null;
  } else if (nextStatus && !currentStatus) {
    updateStatus = true;
  } else {
    updateStatus = false;
  }

  return updateStatus;
};

export interface OwnProps extends PhenomenasFigure, Partial<ChildRendererInjectedProps> {}

export const RemotePhenomenasViewer = (props: OwnProps) => {
  const {data: dataFromProps, options: optionsFromProps = {}} = props;
  const {openSecondaryPanel} = useContext(PanelsContext);
  const {getPhenomenas, isLoading} = useContext(DocumentPhenomenasContext);
  const {t} = useTranslation();
  const isMounted = useMountState();
  const [tableData, setTableData] = useState(dataFromProps);
  const createUserUsefulReaction = useIsUseful(UsefulResourceType.PHENOMENA);
  const getPhenomenasFromAPI = useCallback(() => {
    setTableData(getPhenomenas(optionsFromProps.filters));
  }, [getPhenomenas, setTableData, optionsFromProps]);

  useEffect(() => {
    getPhenomenasFromAPI();
  }, [getPhenomenasFromAPI]);

  const options = useMemo(
    () => ({
      ...optionsFromProps,
      pagination: exists(optionsFromProps.pagination) ? optionsFromProps.pagination : true,
      perPage: exists(optionsFromProps.perPage) ? optionsFromProps.perPage : 3,
    }),
    [optionsFromProps]
  );
  const openSetResourceUsefulPanel = useCallback(
    (phenomena: Phenomena) =>
      openSecondaryPanel(PanelKey.SET_RESOURCE_USEFULNESS_PANEL, {
        type: UsefulResourceType.PHENOMENA,
        status: false,
        model: phenomena,
      }),
    [openSecondaryPanel]
  );
  const tableDataMap = useMemo(() => keyBy(tableData, 'id'), [tableData]);
  const onUpdateUseful = useCallback(
    async (phenomena: Phenomena, newStatus: boolean) => {
      const updateStatus = getNextStatusForPhenomena(phenomena.isUseful, newStatus);
      tableDataMap[phenomena.id]['isUseful'] = updateStatus;
      setTableData(td => [...td]);

      createUserUsefulReaction(
        phenomena,
        {status: updateStatus},
        {
          onSuccess: () => {
            if (updateStatus === false) {
              setTimeout(() => {
                isMounted && openSetResourceUsefulPanel(phenomena);
              }, 500);
            }
            return [];
          },
          onError: () => {
            tableDataMap[phenomena.id]['isUseful'] = phenomena.isUseful;
            setTableData(td => [...td]);
            return [];
          },
        }
      );
    },
    [tableDataMap, setTableData, isMounted, createUserUsefulReaction, openSetResourceUsefulPanel]
  );

  const additionalColumns: any = useMemo(
    () => [
      {
        title: '',
        type: ColumnType._RENDER_COLUMN,
        options: {
          width: '18rem',
        },
        render: item => {
          return (
            <div className={classes.UsefulBtnsWrapper}>
              <div
                className={classNames(classes.UsefulBtn, item.isUseful && classes.Active)}
                onClick={_ => onUpdateUseful(item, true)}
              >
                {t(TransKeys.GENERAL.ACTIONS.USEFUL)}
              </div>
              <span className={classes.Separator}>|</span>
              <div
                className={classNames(classes.UsefulBtn, item.isUseful === false && classes.Active)}
                onClick={_ => onUpdateUseful(item, false)}
              >
                {t(TransKeys.GENERAL.ACTIONS.NOT_USEFUL)}
              </div>
            </div>
          );
        },
      },
    ],
    [onUpdateUseful, t]
  );
  const loadingState = useMemo(
    () => [
      {
        type: DocumentElementType.BLOCK,
        children: [
          {
            type: DocumentElementType._WINDOW_BLOCK,
            render: () => (
              <div style={{position: 'relative', minHeight: '30rem'}}>
                <GenericLoading />
              </div>
            ),
          },
        ],
      },
    ],
    []
  );

  if (isLoading) {
    return <ChildRenderer children_={loadingState} />;
  }

  if (!exists(tableData)) {
    return null;
  }

  return (
    <PhenomenasViewer
      {...props}
      data={tableData}
      options={options}
      additionalColumns={additionalColumns}
      navigationType={'ref'}
      emptyStateRenderer={props.emptyStateRenderer ? props.emptyStateRenderer : () => null}
    />
  );
};
