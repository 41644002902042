import {useMemo} from 'react';
import classes from './metric-page-header.module.scss';
import {MetricPage} from '../../../../../../objects/models/metric-page.model';
import {MetricValueType} from '../../../../../../objects/models/metric.model';
import {number2k} from 'front-core';
import {values} from 'lodash';
import {TeamIconChip} from 'ui-components';
import {ModelSeriesGranularity} from '../../../../../../objects/models/model-sample-series.model';
import TransKeys from '../../../../../../constants/translation-keys';
import {UserSmartSelectorAvatar} from '../../../../../shared/core/smart-selector/user-smart-selector-avatar/user-smart-selector-avatar.component';
import {useTranslation} from 'react-i18next';
import {ModelPageHeader} from '../../../../../../modules/shared/components/layout/model-page-header/model-page-header.component';

interface OwnProps {
  metric: MetricPage;
  onViewDefinition: () => void;
  onOwnerChange: (userId: number) => void;
}

type AllProps = OwnProps;

export const MetricPageHeader = (props: AllProps) => {
  const {metric, onViewDefinition, onOwnerChange} = props;
  const {t} = useTranslation();

  const value = useMemo(() => {
    let v;
    if (ModelSeriesGranularity.WEEK in metric.valueAvg) {
      v = metric.valueAvg[ModelSeriesGranularity.WEEK];
    } else {
      v = values(metric.valueAvg)[0];
    }
    return metric.valueType === MetricValueType.PERCENTAGE ? `${number2k(v * 100)}%` : number2k(v);
  }, [metric]);

  return (
    <ModelPageHeader
      title={metric.name}
      value={value}
      valueDescription={t(TransKeys.METRIC_PAGE.HEADER.VALUE_DESCRIPTION)}
      description={metric.shortDescription}
      onViewDefinition={onViewDefinition}
      subtitleItems={[
        metric.teams.length > 0 && (
          <div className={classes.Teams}>
            {metric.teams.map(t => (
              <TeamIconChip {...t} key={t.id} showTooltip size={'medium'} />
            ))}
          </div>
        ),
        <UserSmartSelectorAvatar
          userName={metric.ownerUserName}
          onChange={userId => onOwnerChange(userId)}
          clearText={t(TransKeys.METRICS.ACTIONS.REMOVE_OWNER)}
          tooltipText={t(TransKeys.METRICS.ACTIONS.SET_OWNER)}
          size={'small'}
        />,
      ]}
    />
  );
};
