import yup from '../../config/yup.config';
import {
  Table,
  TableEntity,
  TableType,
  TableDefinitionType,
  GroupByTimeStrategy,
} from '../models/table.model';
import httpClientService from '../../services/http-client.service';
import {validateNameNetworkRequest} from '../../http/validations.network-requests';
import {values} from 'lodash';
import {nullableValues} from '../../utils/yup.utils';

export type TableDTO = Partial<Table>;

const tableDefinition = yup.object().shape({
  type: yup.string().required(),
  name: yup.string().when('type', {
    is: TableDefinitionType.TABLE,
    then: yup.string().required(),
    otherwise: yup.string().nullable(),
  }),
  prefix: yup.string().nullable(),
  query: yup.string().when('type', {
    is: TableDefinitionType.QUERY,
    then: yup.string().required(),
    otherwise: yup.string().nullable(),
  }),
});

const eventTableParameters = yup.object().shape({
  entity_id: yup.string().required(),
  event_name: yup.string().required(),
  timestamp: yup.string().required(),
});

const eventPropertiesTableParameters = yup.object().shape({
  entity_id: yup.string().required(),
});

const statePropertiesTableParameters = yup.object().shape({
  entity_id: yup.string().required(),
  start_at: yup.string().required(),
  group_by_time_strategy: yup.string().oneOf(values(GroupByTimeStrategy)).required(),
});

export const tableDTOValidator = yup.object().shape({
  id: yup.number().optional().nullable(),
  sourceId: yup.number().nullable(),
  name: yup
    .string()
    .required()
    .test('unique_name', 'Name already exists', async function (name: string) {
      const {id} = this.parent;
      try {
        await httpClientService.exec(
          validateNameNetworkRequest({
            name,
            existingId: id,
            modelType: 'table',
          })
        );
        return true;
      } catch (e) {
        return false;
      }
    }),
  shortDescription: yup.string().nullable(),
  entity: yup.mixed().oneOf(nullableValues(TableEntity)).required(),
  type: yup.mixed().oneOf(values(TableType)).required(),
  definition: tableDefinition,
  parameters: yup.object().when('type', v => {
    switch (v) {
      case TableType.EVENTS:
        return eventTableParameters;
      case TableType.ENTITY_PROPERTIES:
        return eventPropertiesTableParameters;
      case TableType.STATE:
        return statePropertiesTableParameters;
    }
  }),
  uiMetadata: yup.object().nullable(),
});
