import {QueryBuilderFactory} from 'ui-components';
import {cloneDeep, get, keys, set} from 'lodash';
import {
  METADATA_KEY,
  PARAMETERS_METADATA_KEY,
} from '../../../../../constants/parameters-saved-keys';
import {TIME_UNIT_PLURAL_OPTIONS} from '../../../../../constants/options';
import {useCallback, useMemo} from 'react';
import {BEHAVIORAL_CHURN_PARAMETER_MAPPING as PARAMETER_MAPPING} from '../query-builders.config';
import {MilestoneType} from '../../../../../objects/models/milestone.model';
import {
  BaseBehavioralChurnBuilder,
  BaseBehavioralChurnQueryBuilderOwnProps,
} from './base-behavioral-churn-builder.component';

const BUILDER_COMPONENT_NAME = 'BehavioralChurnQueryBuilder';
export const BEHAVIORAL_CHURN_TEMPLATE_NAME = 'behavioral_churn';

interface OwnProps extends BaseBehavioralChurnQueryBuilderOwnProps {}

type AllProps = OwnProps;

export const createBehavioralChurnInitialQuery = (getSignalByTag, entity) => {
  const q = QueryBuilderFactory.createTemplate(BEHAVIORAL_CHURN_TEMPLATE_NAME);
  const activeSignal = getSignalByTag(MilestoneType.ACTIVE, entity);

  set(q, PARAMETER_MAPPING.signal_id, QueryBuilderFactory.createSignalColumn(activeSignal?.id));
  set(q, PARAMETER_MAPPING.units, TIME_UNIT_PLURAL_OPTIONS.options[1].value);
  set(q, PARAMETER_MAPPING.n_units, 4);

  q[PARAMETERS_METADATA_KEY] = {
    [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: BUILDER_COMPONENT_NAME,
  };

  return q;
};

const mapper = (value: any) => ({
  signal_id: get(value, PARAMETER_MAPPING.signal_id),
  units: get(value, PARAMETER_MAPPING.units),
  n_units: get(value, PARAMETER_MAPPING.n_units),
});

export const BehavioralChurnQueryBuilder = (props: AllProps) => {
  const {errors, onChange, query} = props;

  const mappedValue = useMemo(() => mapper(query), [query]);
  const mappedErrors = useMemo(() => mapper(errors), [errors]);

  const mappedOnChange = useCallback(
    changes => {
      const newValue = cloneDeep(query);
      for (const k of keys(changes)) {
        set(newValue, PARAMETER_MAPPING[k], changes[k]);
      }
      newValue[PARAMETERS_METADATA_KEY][METADATA_KEY.BUILDER_COMPONENT_NAME_KEY] =
        BUILDER_COMPONENT_NAME;
      onChange(newValue);
    },
    [query, onChange]
  );

  return (
    <BaseBehavioralChurnBuilder
      {...props}
      query={mappedValue}
      errors={mappedErrors}
      onChange={mappedOnChange}
    />
  );
};
