import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ActionKey} from '../../../../constants/action-key';
import TransKeys from '../../../../constants/translation-keys';
import {METRIC_HEALTH_MONITOR_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {
  createHealthMonitorSubscription,
  editHealthMonitorSubscription,
  deleteHealthMonitorSubscription,
} from '../../../../store/health-monitor/health-monitor.actions';
import {healthMonitorSubscriptionDTOValidator} from '../../../../objects/dto/health-monitor-subscription.dto';
import {CRON_DEFAULT_VALUES} from '../../../../constants/cron-exp.consts';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {getHealthMonitorNetworkRequest} from '../../../../http/health-monitor.network-requests';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {HealthMonitorModel} from '../../../../objects/models/health-monitor.model';
import CronPanelFormComponent, {
  DeleteHealthMonitorSubscriptionDataParam,
} from '../../../shared/components/general/cron-panel-form/cron-panel-form.component';
import {RSSIcon} from 'ui-components';
import {composition} from 'front-core';
import {withDisableDemoProduct} from '../../../../core/hoc/with-disable-demo-product.hoc';

const SELECTED_HEALTH_MONITORING_KEY =
  SharedSelectionKeys.HEALTH_MONITOR_SUBSCRIPTION_PANEL__HEALTH_MONITOR;

interface OwnProps {
  metricHealthMonitor: HealthMonitorModel;
  onClose: () => void;
  panelId?: string;
  disabled?: boolean;
}

type AllProps = OwnProps;

const HealthMonitorSubscriptionPanelComponent = (props: AllProps) => {
  const {metricHealthMonitor, onClose, panelId, disabled} = props;
  const {t} = useTranslation();

  const defaultValues = useMemo(
    () => ({
      cronExp: metricHealthMonitor?.cronExp || CRON_DEFAULT_VALUES.WEEKLY,
      metricHealthMonitorId: metricHealthMonitor.id,
    }),
    [metricHealthMonitor]
  );

  const editMode = useMemo(() => Boolean(metricHealthMonitor?.cronExp), [metricHealthMonitor]);

  const actionHandlers = useMemo(
    () => ({
      edit: editHealthMonitorSubscription,
      create: createHealthMonitorSubscription,
      delete: deleteHealthMonitorSubscription,
    }),
    []
  );

  const onDeleteDataParam: DeleteHealthMonitorSubscriptionDataParam = useMemo(
    () => ({
      metricHealthMonitorId: metricHealthMonitor.id,
    }),
    [metricHealthMonitor]
  );

  const texts = useMemo(
    () => ({
      title: t(TransKeys.HEALTH_MONITOR.SUBSCRIPTION_FORM.TITLE),
      subTitleCta: t(
        TransKeys.HEALTH_MONITOR.SUBSCRIPTION_FORM[editMode ? 'EDIT' : 'CREATE'].SUBTITLE_CTA
      ),
      deleteBtn: t(TransKeys.GENERAL.ACTIONS.UNSUBSCRIBE),
      submitBtn: t(TransKeys.GENERAL.ACTIONS[editMode ? 'EDIT_SUBSCRIPTION' : 'SUBSCRIBE']),
    }),
    [t, editMode]
  );

  const formHiddenInputsName = useMemo(() => ['metricHealthMonitorId'], []);

  return (
    <CronPanelFormComponent
      icon={RSSIcon}
      onClose={onClose}
      panelId={panelId}
      defaultValues={defaultValues}
      resolverSchema={healthMonitorSubscriptionDTOValidator}
      loadingKeys={[
        ActionKey.CREATE_HEALTH_MONITOR_SUBSCRIPTION,
        ActionKey.EDIT_HEALTH_MONITOR_SUBSCRIPTION,
        ActionKey.DELETE_HEALTH_MONITOR_SUBSCRIPTION,
      ]}
      editMode={editMode}
      actionsHandlers={actionHandlers}
      onDeleteDataParam={onDeleteDataParam}
      texts={texts}
      showModalFooter={true}
      formHiddenInputsNames={formHiddenInputsName}
      showForm
      showDaily
      showWeekly
      disabled={disabled}
    />
  );
};

export const HealthMonitorSubscriptionPanel = composition<AllProps>(
  HealthMonitorSubscriptionPanelComponent,
  withDisableDemoProduct,
  withLoadBefore<AllProps>({
    metricHealthMonitor: {
      selectedKey: SELECTED_HEALTH_MONITORING_KEY,
      actionKey: SELECTED_HEALTH_MONITORING_KEY,
      request: getHealthMonitorNetworkRequest,
      mapPayloadFromProps: props => props[METRIC_HEALTH_MONITOR_ID_PATH_PARAM],
      shouldCall: props => props[METRIC_HEALTH_MONITOR_ID_PATH_PARAM] !== undefined,
    },
  })
);
