import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router';
import TransKeys from '../../../../constants/translation-keys';
import {getAnalysisEstimatedRuntime} from '../../hooks/use-analysis-runtime.hook';
import {AnalysisResultBaseEmptyState} from '../../pages/view-analysis/components/analysis-result-base-empty-state/analysis-result-base-empty-state.component';
import {AnalysisResultRunningEmptyState} from '../../pages/view-analysis/components/analysis-result-running-empty-state/analysis-result-running-empty-state.component';
import {
  AnalysisResult,
  AnalysisResultStatus,
} from '../../../../objects/models/analysis-result.model';
import {
  getAnalysisStepProgress,
  isAnalysisResultProcessing,
} from '../../../../utils/analysis-result.utils';

type ViewAnalysisEmptyStateProps = {
  analysisResult?: AnalysisResult;
  error?: boolean;
  className?: string;
};

export const ViewAnalysisEmptyState = (props: ViewAnalysisEmptyStateProps) => {
  const {analysisResult, error, className} = props;

  const navigate = useNavigate();
  const {t} = useTranslation();

  const sharedProps = {
    title: undefined,
    subTitle: undefined,
    onClick: () => navigate(-1),
  };

  if (!analysisResult) {
    sharedProps.title = '';
    sharedProps.subTitle = '';
  } else if (error) {
    sharedProps.title = t(TransKeys.VIEW_ANALYSIS.EMPTY_STATE_404.TITLE);
    sharedProps.subTitle = t(TransKeys.VIEW_ANALYSIS.EMPTY_STATE_404.SUB_TITLE);
  } else if (analysisResult.status === AnalysisResultStatus.STOPPED) {
    sharedProps.title = t(TransKeys.VIEW_ANALYSIS.EMPTY_STATE_STOPPED.TITLE);
    sharedProps.subTitle = t(TransKeys.VIEW_ANALYSIS.EMPTY_STATE_STOPPED.SUB_TITLE);
  } else if (isAnalysisResultProcessing(analysisResult, true)) {
    sharedProps.title = t(TransKeys.VIEW_ANALYSIS.WAITING_FOR_RESULTS.TITLE);
    const estimatedRuntime = getAnalysisEstimatedRuntime(analysisResult.analysisTypeId);
    const translationKey = estimatedRuntime
      ? TransKeys.CREATE_ANALYSIS_FORM.SUCCESS_STEP.SUB_TITLE.ESTIMATED_RUNNING_TIME
      : TransKeys.CREATE_ANALYSIS_FORM.SUCCESS_STEP.SUB_TITLE.DEFAULT;
    sharedProps.subTitle = t(translationKey, {estimatedRuntime});
  }
  const analysisStepProgress = getAnalysisStepProgress(analysisResult);

  if (analysisStepProgress > 0) {
    return (
      <AnalysisResultRunningEmptyState
        subTitle={sharedProps.subTitle}
        onClick={sharedProps.onClick}
        progress={analysisStepProgress}
        className={className}
      />
    );
  }

  return (
    <AnalysisResultBaseEmptyState
      title={sharedProps.title}
      subTitle={sharedProps.subTitle}
      onClick={sharedProps.onClick}
      buttonText={t(TransKeys.GENERAL.ACTIONS.BACK)}
      className={className}
    />
  );
};
