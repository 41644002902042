import classNames from 'classnames';
import {QuickRunForAdmins} from '../../../../../objects/models/analysis.model';
import classes from './quick-run-configuration-step.module.scss';
import {useCallback, useMemo, useState} from 'react';
import {Button, Checkbox, LabelWrapper} from 'ui-components';
import {FormStep} from '../../../../shared/components/layout/form-step/form-step.component';
import {AnalysisFolderSmartSelector} from '../../../../shared/core/smart-selector/analysis-folder-smart-selector.component';
import {
  CONTENT_SELECTION_RELEVANT_TYPES,
  QUICK_RUN_ANALYSES_CONFIG,
} from '../quick-run-config.utils';
import {capitalize, keys} from 'lodash';
import {getContentsNetworkRequest} from '../../../../../http/contents.network-requests';
import {Content} from '../../../../../objects/models/content.model';
import {composition} from 'front-core';
import {withLoadBefore} from '../../../../../core/hoc/with-load-before.hoc';

interface OwnProps {
  contents?: Content[];
  data: QuickRunForAdmins;
  onSubmit: (data: QuickRunForAdmins) => void;
  className?: string;
}

type AllProps = OwnProps;

const QuickRunConfigurationStepComponent = (props: AllProps) => {
  const {contents, data, onSubmit, className} = props;
  const showContentSelection = useMemo(
    () => CONTENT_SELECTION_RELEVANT_TYPES.indexOf(data.runType) > -1,
    [data.runType]
  );
  const [formData, setFormData] = useState<QuickRunForAdmins>({
    ...data,
    contentIds: showContentSelection ? contents.map(c => c.id) : undefined,
  });

  const contentIdsSet = useMemo(() => new Set(formData.contentIds || []), [formData.contentIds]);
  const onChange = useCallback(
    newData => {
      setFormData(fd => ({...fd, ...newData}));
    },
    [setFormData]
  );
  const onToggleConfig = useCallback(
    (key: string) => {
      setFormData(fd => ({
        ...fd,
        analysesConfiguration: {
          ...fd.analysesConfiguration,
          [key]: !fd.analysesConfiguration[key],
        },
      }));
    },
    [setFormData]
  );
  const onToggleContent = useCallback(
    (contentId: number) => {
      setFormData(fd => {
        const set = new Set(fd.contentIds);
        if (set.has(contentId)) {
          set.delete(contentId);
        } else {
          set.add(contentId);
        }
        return {
          ...fd,
          contentIds: Array.from(set),
        };
      });
    },
    [setFormData]
  );
  const options = useMemo(() => {
    const analysesConfiguration = QUICK_RUN_ANALYSES_CONFIG[data.runType];
    return keys(analysesConfiguration).map(key => ({
      value: key,
      label: capitalize(key.replace(/_/g, ' ')).replace(/kpi/gi, 'KPI'),
    }));
  }, [data.runType]);
  const disabled = useMemo(() => {
    return (
      formData.analysesConfiguration &&
      !keys(formData.analysesConfiguration).some(k => formData.analysesConfiguration[k])
    );
  }, [formData]);

  return (
    <FormStep
      footer={
        <Button disabled={disabled} onClick={disabled ? undefined : () => onSubmit(formData)}>
          Run analyses
        </Button>
      }
    >
      <div className={classNames(classes.QuickRunConfigurationStep, className)}>
        <LabelWrapper
          label={'Select the analyses you want to run'}
          className={classes.LabelWrapper}
          helperText={'You have to select at least one analysis'}
        >
          <div className={classes.Options}>
            {options.map(option => (
              <div
                className={classNames(
                  classes.Option,
                  formData.analysesConfiguration[option.value] && classes.Selected
                )}
                key={option.value}
                onClick={() => onToggleConfig(option.value)}
              >
                <Checkbox
                  multi
                  className={classes.CheckBox}
                  checked={formData.analysesConfiguration[option.value]}
                  onChange={() => onToggleConfig(option.value)}
                />
                {option.label}
              </div>
            ))}
          </div>
        </LabelWrapper>
        {showContentSelection && (
          <LabelWrapper
            label={'Select which contents to run the analyses on'}
            className={classes.LabelWrapper}
          >
            <div className={classes.Options}>
              {contents.map(content => (
                <div
                  className={classNames(
                    classes.Option,
                    contentIdsSet.has(content.id) && classes.Selected
                  )}
                  key={content.id}
                  onClick={() => onToggleContent(content.id)}
                >
                  <Checkbox
                    multi
                    className={classes.CheckBox}
                    checked={contentIdsSet.has(content.id)}
                    onChange={() => onToggleContent(content.id)}
                  />
                  {content.name}
                </div>
              ))}
            </div>
          </LabelWrapper>
        )}
        <LabelWrapper
          label={'Folder (optional)'}
          helperText={
            'If not specified the system will generate a new folder on your private folder'
          }
          className={classes.LabelWrapper}
        >
          <AnalysisFolderSmartSelector
            value={formData.analysisFolderId}
            onChange={v => onChange({analysisFolderId: v})}
            placeholder={'Select'}
            clearable={true}
          />
        </LabelWrapper>
      </div>
    </FormStep>
  );
};

export const QuickRunConfigurationStep = composition<AllProps>(
  QuickRunConfigurationStepComponent,
  withLoadBefore({
    contents: {
      selectedKey: 'contents',
      actionKey: 'contents',
      request: query => ({
        ...getContentsNetworkRequest(query),
        responseTransformer: res => res.data.data,
      }),
      mapPayloadFromProps: (props: AllProps) => ({
        limit: 1000,
        entity: props.data.entity,
      }),
      shouldCall: (props: AllProps) =>
        CONTENT_SELECTION_RELEVANT_TYPES.indexOf(props.data.runType) > -1,
    },
  })
);
