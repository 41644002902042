import classNames from 'classnames';
import classes from './user-role-step.module.scss';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useCallback, useMemo} from 'react';
import {StepTitle} from '../../components/step-title/step-title.component';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {values} from 'lodash';
import {Button, Select, TextInput} from 'ui-components';
import yup from '../../../../../../config/yup.config';

interface FormData {
  userRole: string;
  userRoleOther?: string;
}

interface OwnProps {
  data: any;
  onChange: (value: FormData) => void;
  className?: string;
}

enum UserRoleOption {
  PRODUCT_MANAGER = 'product_manager',
  ANALYST = 'analyst',
  MARKETING = 'marketing',
  DATA_SCIENTIST = 'data_scientist',
  DEVELOPER = 'develop',
  OTHER = 'other',
}

type AllProps = OwnProps;

export const userRoleStepValidations = yup.object().shape({
  userRole: yup.string().required(),
  userRoleOther: yup.string().when('userRole', {
    is: UserRoleOption.OTHER,
    then: schema => schema.required(),
    otherwise: schema => schema.nullable(),
  }),
});

export const UserRoleStep = (props: AllProps) => {
  const {data, onChange, className} = props;
  const {t} = useTranslation();

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: {errors, isValid},
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      userRole: '',
      userRoleOther: null,
      ...data,
    },
    resolver: yupResolver(userRoleStepValidations.noUnknown()),
  });
  const showOther = watch('userRole') === UserRoleOption.OTHER;
  const onSubmit = useCallback(data => onChange(data), [onChange]);
  const options = useMemo(
    () => ({
      options: values(UserRoleOption).map(o => ({
        value: o,
        label: t(TransKeys.QUESTIONNAIRE.USER_ROLE[o.toUpperCase()]),
      })),
    }),
    [t]
  );

  return (
    <div className={classNames(classes.UserRoleStep, className)}>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.Form}>
        <StepTitle
          className={classes.Title}
          title={t(TransKeys.QUESTIONNAIRE.ROLE_STEP.TITLE)}
          subTitle={t(TransKeys.QUESTIONNAIRE.ROLE_STEP.SUB_TITLE)}
        />
        <Controller
          name={'userRole'}
          control={control}
          render={({field, fieldState: {error}}) => (
            <Select
              onChange={v => {
                field.onChange(v);
                v !== UserRoleOption.OTHER &&
                  setValue('userRoleOther', null, {shouldValidate: true});
              }}
              error={Boolean(errors.userRole)}
              dropdownButtonClassName={classes.Input}
              value={field.value}
              options={options}
              clearable={false}
              searchable={false}
              sortValues={false}
              fullWidth
            />
          )}
        />
        {showOther && (
          <Controller
            name={'userRoleOther'}
            control={control}
            render={({field, fieldState: {error}}) => (
              <TextInput
                placeholder={'Type...'}
                error={Boolean(errors.userRoleOther)}
                className={classNames(classes.Input, classes.OtherInput)}
                inputClassName={classes.TextInput}
                onChange={field.onChange}
                value={field.value}
                fullWidth
              />
            )}
          />
        )}
        <div className={classes.Spacer} />
        <Button
          disabled={!isValid}
          onClick={handleSubmit(onSubmit)}
          type={'submit'}
          className={classNames(classes.Submit, !isValid && classes.Disabled)}
        >
          {t(TransKeys.QUESTIONNAIRE.ACTIONS.CONTINUE)}
        </Button>
      </form>
    </div>
  );
};
