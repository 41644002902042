import classNames from 'classnames';
import classes from '../../analysis-forms.module.scss';
import {QueryBuilderFactory} from 'ui-components';
import {TimeFrameSelector} from '../../components/ui-selectors/time-frame-selector/time-frame-selector.component';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {RetentionQueryBuilder} from '../../../../shared/core/query-builders/retention-builder/retention-query-builder.component';
import {ParametersFormContext} from '../../../../shared/core/parameters-form/parameters-form.context';
import {startEndDatesDefaultHandler} from '../../../../shared/core/parameters-form/parameters-form-default-handlers.utils';
import {useProductData} from '../../../../../core/hooks/use-product-data.hook';
import {SegmentFilterSelector} from '../../components/ui-selectors/segment-filter-selector/segment-filter-selector.component';
import {WindowSizeSelector} from '../../components/ui-selectors/window-size-selector/window-size-selector.component';
import {get, values} from 'lodash';
import {ParameterInputWrapper} from '../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {TableEntity, TableEntityBinding} from '../../../../../objects/models/table.model';
import {EntitySelector} from '../../components/ui-selectors/entity-selector/entity-selector.component';
import {createUndefinedObject, hasError} from '../../../../../utils/general.utils';
import {AnalysisFormProps} from '../../analysis-forms.types';
import {TreatmentSelector} from '../../components/ui-selectors/treatment-selector/treatment-selector.component';
import {SimpleNumberSelector} from '../../components/ui-selectors/simple-number-selector/simple-number-selector.component';
import {SelectorModelType} from '../../../../shared/core/smart-selector/advanced-smart-selector.component';
import {SignalDataType, SignalType} from '../../../../../objects/models/signal.model';
import TransKeys from '../../../../../constants/translation-keys';
import {ExtendedParameters} from '../../../../shared/form/form-layout/extended-parameters/extended-parameters.component';
import {useTranslation} from 'react-i18next';
import {SimpleBooleanSelector} from '../../components/ui-selectors/simple-boolean-selector/simple-boolean-selector.component';
import {useFeatureIsOn} from '@growthbook/growthbook-react';
import {FeatureFlag} from '../../../../../constants/feature-flags';
import {ConfoundersSelector} from '../../components/ui-selectors/confounders-selector/confounders-selector.component';

const QUERY_GOAL_KEY = 'goal_query';
const REF_DATE_PARAMETER = 'reference_date';
const habitMomentSelectorMap = {
  window_size_n: 'max_window',
  window_size_unit: 'max_window_unit',
};
const treatmentsSelectorMap = {
  treatments_tag: 'features_tag',
  treatments_signals: 'treatments_signals',
};
const entitySchemaMapping = {
  entity: 'entity',
};
const segmentFilterSchemaMapping = {
  population_filter: 'population_filter',
};
const minimumAdoptionRateSchemaMapping = {
  number_key: 'minimum_adoption_significance',
};
const filterUsersWhoDidNotDoTreatmentSchemaMapping = {
  boolean_key: 'filter_users_who_did_not_do_treatment',
};
const confoundersSelectorMap = {
  confounders_tag: 'confounders_tag',
  confounders_signals: 'confounders_signals',
};

const ADOPTION_RATE_OPTIONS = [1, 5, 10];

const REF_DATE_ALLOWED_TYPES = [
  SelectorModelType.SIGNAL,
  SelectorModelType.COLUMN,
  SelectorModelType.EVENT,
];
const createRefDateFilter = entityContext => [
  {
    type: SignalType.DIMENSION,
    data_type: SignalDataType.TIMESTAMP,
    entity_binding: TableEntityBinding.DEFAULT,
    entityContext,
    exclude_templates: '*',
  },
  {
    type: SignalType.DIMENSION,
    data_type: SignalDataType.TIMESTAMP,
    entity_binding: TableEntityBinding.DEFAULT,
    entityContext,
    templates: ['dom_template'],
  },
];
const CONFOUNDERS_SIGNAL_FILTERS = [
  {
    type: SignalType.MEASURE,
    data_type: [SignalDataType.BOOLEAN, SignalDataType.STRING],
  },
  {
    type: SignalType.DIMENSION,
    data_type: [
      SignalDataType.STRING,
      SignalDataType.INTEGER,
      SignalDataType.DECIMAL,
      SignalDataType.BOOLEAN,
    ],
  },
];

export const Analysis67Form = (props: AnalysisFormProps) => {
  const {onSignalInfo, className} = props;
  const {t} = useTranslation();
  const [isOpenAdvancedParams, setIsOpenAdvancedParams] = useState(false);
  const {errors, parameters, changeParametersValue, registerDefaultHandler, removeDefaultHandler} =
    useContext(ParametersFormContext);
  const {getSignalByTag, productEntities, defaultTableEntity} = useProductData();
  const entityContext = parameters[entitySchemaMapping.entity];
  // todo: check if this still in use, if not - delete!
  const exposeParameter = useFeatureIsOn(
    FeatureFlag.HABIT_MOMENT_FILTER_USERS_WHO_DID_NOT_DO_TREATMENT as string
  );
  useEffect(() => {
    registerDefaultHandler('start_end_dates', startEndDatesDefaultHandler);
    registerDefaultHandler('analysis_67', parameters => {
      const defaults = {};
      // goal_query
      defaults[entitySchemaMapping.entity] = defaultTableEntity;
      const joinDateSignal = getSignalByTag('join_date', entityContext);
      const activeSignal = getSignalByTag('active', entityContext);
      const query = QueryBuilderFactory.createTemplate();
      query.template = 'bounded_actions_ts';
      query.parameters[1] = 1;
      query.parameters[2] = 2;
      query.parameters[3] = 'month';
      query.parameters[4] = QueryBuilderFactory.createSignalColumn(joinDateSignal?.id);
      query.parameters[0] = [QueryBuilderFactory.createSignalColumn(activeSignal?.id)];
      defaults[QUERY_GOAL_KEY] = query;
      // habit moment
      defaults[habitMomentSelectorMap.window_size_n] = 2;
      defaults[habitMomentSelectorMap.window_size_unit] = 'week';
      defaults[minimumAdoptionRateSchemaMapping.number_key] = 5;
      defaults[filterUsersWhoDidNotDoTreatmentSchemaMapping.boolean_key] = false;
      return defaults;
    });

    return () => {
      removeDefaultHandler('start_end_dates');
      removeDefaultHandler('analysis_67');
    };
  }, [
    registerDefaultHandler,
    removeDefaultHandler,
    getSignalByTag,
    entityContext,
    defaultTableEntity,
  ]);

  useEffect(() => {
    const queryParamValue = get(parameters, `${QUERY_GOAL_KEY}.parameters.4`);
    if (queryParamValue !== parameters[REF_DATE_PARAMETER]) {
      changeParametersValue({[REF_DATE_PARAMETER]: queryParamValue});
    }
  }, [parameters, changeParametersValue]);

  const onChangeEntityContext = useCallback(
    (entity: TableEntity) => {
      const resetKeys = Array.from(
        new Set([
          ...values(segmentFilterSchemaMapping),
          ...values(treatmentsSelectorMap),
          QUERY_GOAL_KEY,
        ])
      );
      const resetParameters = createUndefinedObject(resetKeys);
      changeParametersValue({
        [entitySchemaMapping.entity]: entity,
        ...resetParameters,
      });
    },
    [changeParametersValue]
  );
  const refDateFilters = useMemo(() => createRefDateFilter(entityContext), [entityContext]);
  const isOpenAdvancedParamsOrError = useMemo(() => {
    return (
      isOpenAdvancedParams ||
      // todo
      hasError(errors, [])
    );
  }, [isOpenAdvancedParams, errors]);

  return (
    <div className={classNames(classes.AnalysisForm, className)}>
      <EntitySelector
        value={parameters}
        productEntities={productEntities}
        schemaKeysMapping={entitySchemaMapping}
        onChange={v => onChangeEntityContext(v[entitySchemaMapping.entity])}
        className={classes.Parameter}
      />
      <ParameterInputWrapper
        title={'Retention Goal'}
        subTitle={'Define your long-term retention goal.'}
        className={classes.Parameter}
        error={errors?.[QUERY_GOAL_KEY]}
      >
        <RetentionQueryBuilder
          query={parameters[QUERY_GOAL_KEY]}
          onChange={value => changeParametersValue({[QUERY_GOAL_KEY]: value})}
          onSignalInfo={onSignalInfo}
          errors={errors?.[QUERY_GOAL_KEY]}
          entityContext={entityContext}
          refDateAllowTypes={REF_DATE_ALLOWED_TYPES}
          refDateSignalFilters={refDateFilters}
        />
      </ParameterInputWrapper>
      <WindowSizeSelector
        title={'Habit moment period'}
        subTitle={'Define the habit moment period.'}
        helperText={
          'Habit moment period is an early stage user behavior that correlates with long-term retention. Define here this early stage period.'
        }
        value={parameters}
        onChange={changeParametersValue}
        className={classes.Parameter}
        schemaKeysMapping={habitMomentSelectorMap}
        errors={errors}
        preText={'Define habit moment period as'}
        postText={'since starting point'}
      />
      <TreatmentSelector
        value={parameters}
        onChange={changeParametersValue}
        className={classes.Parameter}
        schemaKeysMapping={treatmentsSelectorMap}
        errors={errors}
        entityContext={entityContext}
      />
      <SimpleNumberSelector
        title={'Minimum adoption rate'}
        subTitle={
          'Set the minimum adoption rate to filter recommendations, ensuring user adoption criteria are met for features, usage counts, and timeframes.'
        }
        value={parameters}
        onChange={changeParametersValue}
        className={classes.Parameter}
        schemaKeysMapping={minimumAdoptionRateSchemaMapping}
        errors={errors}
        options={ADOPTION_RATE_OPTIONS}
        percentage
        minValue={1}
        maxValue={100}
      />
      <TimeFrameSelector className={classes.Parameter} errors={errors} />
      {entityContext && (
        <SegmentFilterSelector
          onChange={changeParametersValue}
          value={parameters}
          className={classes.Parameter}
          schemaKeysMapping={segmentFilterSchemaMapping}
          entityContext={entityContext}
          errors={errors}
        />
      )}
      <ExtendedParameters
        className={classes.SpaceBottom}
        label={t(TransKeys.GENERAL.LABELS.ADVANCED_PARAMETERS)}
        isOpen={isOpenAdvancedParamsOrError}
        onOpenChanged={() => setIsOpenAdvancedParams(!isOpenAdvancedParams)}
      >
        <ConfoundersSelector
          onChange={changeParametersValue}
          value={parameters}
          schemaKeysMapping={confoundersSelectorMap}
          entityContext={entityContext}
          signalFilters={CONFOUNDERS_SIGNAL_FILTERS}
          className={classes.Parameter}
        />
        {exposeParameter && (
          <SimpleBooleanSelector
            title={"Filter users who didn't do the treatment at all"}
            subTitle={
              'For each driver, only take into account users who did the driver at-least once. This is only relevant for very specific cases'
            }
            onChange={changeParametersValue}
            value={parameters}
            schemaKeysMapping={filterUsersWhoDidNotDoTreatmentSchemaMapping}
          />
        )}
      </ExtendedParameters>
    </div>
  );
};
