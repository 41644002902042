import {useMemo} from 'react';
import classNames from 'classnames';
import classes from './workflow-list-item.module.scss';
import {
  Workflow,
  WorkflowStatus,
  WorkflowType,
} from '../../../../../../../objects/models/workflow.model';
import {
  Chip,
  CirclePlayLightIcon,
  CircleStopLightIcon,
  CopyIcon,
  EditIcon,
  MoreIcon,
  TeamIconChip,
  TooltipIfOverflow,
  TrashIcon,
  UserGroupLightIcon,
} from 'ui-components';
import {ListRowItemCell} from '../../../../../../shared/components/general/list-row-item-cell/list-row-item-cell.component';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {UserSmartSelectorAvatar} from '../../../../../../shared/core/smart-selector/user-smart-selector-avatar/user-smart-selector-avatar.component';
import {useWorkflowActions} from '../../../hooks/use-workflow-actions.hook';
import moment from 'moment';
import {Tooltip} from '@material-ui/core';
import {exists, withStopPropagation} from 'front-core';
import {ModelActionsDropdown} from '../../../../../../shared/core/model-actions-dropdown/model-actions-dropdown.component';
import {useProductData} from '../../../../../../../core/hooks/use-product-data.hook';
import {InlineEditName} from '../../../../../../shared/components/general/inline-edit-name/inline-edit-name.component';

interface OwnProps {
  workflow: Workflow;
  onMetricClick: (metricId: number) => void;
  onViewSQL: (sql: string) => void;
  className?: string;
}

type AllProps = OwnProps;

export const WorkflowListItem = (props: AllProps) => {
  const {workflow, onMetricClick, onViewSQL, className} = props;
  const {t} = useTranslation();
  const {
    onChangeOwner,
    setWorkflowTeam,
    setWorkflowStatus,
    onDelete,
    onRename,
    onDuplicate,
    onEdit,
  } = useWorkflowActions();
  const {actualTeams: teams} = useProductData();

  const teamOptions = useMemo(
    () => (exists(workflow.team?.id) ? teams.filter(t => workflow.team.id === t.id) : teams),
    [teams, workflow]
  );
  const renderKPI = () => {
    if (workflow.type === WorkflowType.KPI_REACH) {
      return (
        <TooltipIfOverflow title={workflow.metric.name}>
          <span
            className={classes.MetricName}
            onClick={withStopPropagation(() => onMetricClick(workflow.metric.id))}
          >
            {workflow.metric.name}
          </span>
        </TooltipIfOverflow>
      );
    }
    if (workflow.type === WorkflowType.CUSTOM_SQL) {
      return (
        <span
          className={classes.MetricName}
          onClick={withStopPropagation(() => onViewSQL(workflow.parameters.sql))}
        >
          Custom SQL
        </span>
      );
    }
  };
  const lastRun = useMemo(() => {
    if (!workflow.lastRun) {
      return 'In progress';
    }
    return moment.utc(workflow.lastRun).local().fromNow();
  }, [workflow]);

  return (
    <div className={classNames(classes.WorkflowListItem, className)}>
      <ListRowItemCell
        title={t(TransKeys.WORKFLOWS.TYPE[workflow.type.toUpperCase()].LABEL)}
        className={classes.NameCell}
        content={
          <div className={classes.NameWrapper}>
            <Tooltip
              interactive={false}
              title={t(TransKeys.WORKFLOWS.STATUS_HELPER[workflow.status.toUpperCase()])}
            >
              <div className={classNames(classes.Status, classes[workflow.status])} />
            </Tooltip>
            <TooltipIfOverflow title={workflow.name}>
              <InlineEditName
                value={workflow.name}
                className={classes.WorkflowName}
                inputClassName={classes.WorkflowNameInput}
                onSubmit={value => onRename(workflow.id, value)}
              />
            </TooltipIfOverflow>
          </div>
        }
      />
      <ListRowItemCell title={'KPI'} className={classes.MetricCell} content={renderKPI()} />
      <ListRowItemCell
        title={'Owner'}
        className={classes.OwnerCell}
        content={
          <UserSmartSelectorAvatar
            userName={workflow.ownerUserName}
            onChange={userId => onChangeOwner(userId, workflow.id)}
            clearText={t(TransKeys.EXPERIMENTS.ACTIONS.REMOVE_OWNER)}
            tooltipText={t(TransKeys.EXPERIMENTS.ACTIONS.SET_OWNER)}
            size={'small'}
          />
        }
      />
      <ListRowItemCell
        title={'Last run'}
        className={classes.LastRunCell}
        content={<div className={classes.LastRun}>{lastRun}</div>}
      />
      {teams.length > 0 && (
        <ListRowItemCell
          title={'Team'}
          className={classes.TeamCell}
          content={
            workflow.team ? (
              <TeamIconChip {...workflow.team} />
            ) : (
              <span className={classes.NoTeam}>-</span>
            )
          }
        />
      )}
      <ListRowItemCell
        title={'Outputs'}
        className={classNames(classes.OutputsCell, teams.length === 0 && classes.SpanMore)}
        content={
          <div className={classes.Outputs}>
            {workflow.outputDefinitions.map(output => (
              <Chip
                label={t(TransKeys.WORKFLOWS.OUTPUT_TYPE[output.type.toUpperCase()].LABEL)}
                key={output.type}
                size={'small'}
              />
            ))}
          </div>
        }
      />
      <div className={classes.Actions}>
        <ModelActionsDropdown
          border={false}
          rounded={false}
          actions={[
            {
              key: 'clone',
              title: t(TransKeys.GENERAL.ACTIONS.DUPLICATE),
              icon: CopyIcon,
              onClick: _ => onDuplicate(workflow.id),
            },
            {
              key: 'edit',
              title: t(TransKeys.GENERAL.ACTIONS.EDIT),
              icon: EditIcon,
              onClick: _ => onEdit(workflow.id),
            },
            {
              key: 'set-team',
              title: t(TransKeys.GENERAL.ACTIONS.ASSIGN_TO_TEAM),
              icon: UserGroupLightIcon,
              hide: teamOptions.length === 0,
              children: teamOptions.map(team => ({
                key: team.id.toString(),
                title: team.name,
                icon: ({className}) => <TeamIconChip {...team} className={className} />,
                onClick: _ => setWorkflowTeam(workflow.id, team.id),
              })),
            },
            {
              key: 'update-status',
              title:
                workflow.status === WorkflowStatus.ACTIVE
                  ? t(TransKeys.WORKFLOWS.ACTIONS.STOP_WORKFLOW)
                  : t(TransKeys.WORKFLOWS.ACTIONS.REACTIVE_WORKFLOW),
              icon:
                workflow.status === WorkflowStatus.ACTIVE
                  ? CircleStopLightIcon
                  : CirclePlayLightIcon,
              onClick: _ =>
                setWorkflowStatus(
                  workflow.id,
                  workflow.status === WorkflowStatus.ACTIVE
                    ? WorkflowStatus.STOPPED
                    : WorkflowStatus.ACTIVE
                ),
            },
            {
              key: 'delete',
              title: t(TransKeys.GENERAL.ACTIONS.DELETE),
              icon: TrashIcon,
              onClick: _ => onDelete(workflow.id),
            },
          ]}
          label={t(TransKeys.GENERAL.LABELS.MORE_DOTS)}
          iconDropdown
          icon={MoreIcon}
        />
      </div>
    </div>
  );
};
