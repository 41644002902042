import classNames from 'classnames';
import classes from './builder-wrapper.module.scss';

interface OwnProps {
  children: any;
  className?: string;
}

type AllProps = OwnProps;

export const BuilderWrapper = (props: AllProps) => {
  const {children, className} = props;

  return <div className={classNames(classes.BuilderWrapper, className)}>{children}</div>;
};
