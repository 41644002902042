import classNames from 'classnames';
import classes from './simple-options-selector.module.scss';
import {ParameterInputWrapper} from '../../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {useMemo} from 'react';
import {Checkbox, HoverHelperTip} from 'ui-components';
import {hasErrors} from '../../../../../../utils/general.utils';
import {values} from 'lodash';

interface SchemaKeysMapping {
  option_key: string;
}

const DEFAULT_SCHEMA_KEYS_MAPPING: SchemaKeysMapping = {
  option_key: 'option_key',
};

interface SimpleOption {
  value: string;
  label: string;
  helperText?: string;
}

interface OwnProps {
  title: string;
  onChange: (parameters: any) => void;
  value: any;
  options: SimpleOption[];
  schemaKeysMapping?: SchemaKeysMapping;
  subTitle?: any;
  helperText?: string;
  errors?: any;
  className?: string;
}

type AllProps = OwnProps;

export const SimpleOptionsSelector = (props: AllProps) => {
  const {
    title,
    subTitle,
    helperText,
    schemaKeysMapping = DEFAULT_SCHEMA_KEYS_MAPPING,
    errors,
    value,
    onChange,
    options,
    className,
  } = props;

  const hasError = useMemo(
    () => hasErrors(errors, values(schemaKeysMapping)),
    [errors, schemaKeysMapping]
  );

  return (
    <ParameterInputWrapper
      title={title}
      subTitle={subTitle}
      className={classNames(classes.SimpleOptionsSelector, className)}
      helperText={helperText}
      error={hasError}
    >
      <div className={classes.Options}>
        {options.map((o: any) => (
          <div
            key={o.value}
            onClick={() => onChange({[schemaKeysMapping.option_key]: o.value})}
            className={classes.Option}
          >
            <Checkbox
              checked={value[schemaKeysMapping.option_key] === o.value}
              onChange={() => onChange({[schemaKeysMapping.option_key]: o.value})}
              className={classes.Checkbox}
            />
            <span className={classes.Label}>{o.label}</span>
            {o.helperText && <HoverHelperTip title={o.helperText} />}
          </div>
        ))}
      </div>
    </ParameterInputWrapper>
  );
};
