import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {
  ANALYSIS_FOLDER_ID_PATH_PARAM,
  ANALYSIS_ID_PATH_PARAM,
  ANALYSIS_PARAMETERS_KEY,
  ANALYSIS_RESULT_ID_PATH_PARAM,
  ANALYSIS_RUN_PARAMETERS_KEY,
  ANALYSIS_TYPE_ID_PATH_PARAM,
  AppRoutes,
  SIGNAL_ID_PATH_PARAM,
} from '../../../../constants/app-routes';
import {AnalysisDTO} from '../../../../objects/dto/analysis.dto';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {
  AnalysisFileIcon,
  FancyHeader,
  ModalLayout,
  PrimaryTabs,
  useRemoteSource,
  WizardStepper,
} from 'ui-components';
import classes from './analysis-form-panel.module.scss';
import {getReducedLoadingStateSelector} from '../../../../store/store.selectors';
import {ActionKey} from '../../../../constants/action-key';
import {editAnalysis, requestAnalysis} from '../../../../store/analyses/analyses.actions';
import {isArray, pick} from 'lodash';
import {
  AnalysisParametersFormMode,
  AnalysisParametersTab,
} from './components/analysis-parameters-tab/analysis-parameters-tab.component';
import {AnalysisInfoTab} from './components/analysis-info-tab/analysis-info-tab.component';
import TransKeys from '../../../../constants/translation-keys';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {getAnalysisResultNetworkRequest} from '../../../../http/analysis-results.network-requests';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import {PanelKey} from '../../../../constants/panels';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {AnalysisResult} from '../../../../objects/models/analysis-result.model';
import {AnalysisTypeId} from '../../../../constants/analysis-type-id';
import {WizardCompletedTab} from './components/wizard-completed-tab/wizard-completed-tab.component';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {SOURCE_PROP_KEY} from '../../../../constants/shared-component-prop-key';
import {composition, exists, withMetadata} from 'front-core';
import {SOURCE_META_KEY} from '../../../../constants/app-sources';
import {getAnalysisEstimatedRuntime} from '../../hooks/use-analysis-runtime.hook';
import {AnalysisTypeSelectionTab} from './components/analysis-type-selection-tab/analysis-type-selection-tab.component';
import {HotjarEvent} from '../../../../constants/hotjar-event';
import {useIsAdmin} from '../../../../core/hooks/use-is-admin.hook';
import {getAnalysisFolderNetworkRequest} from '../../../../http/analysis-folders.network-requests';
import {AnalysisFolderType} from '../../../../objects/models/analysis-folder.model';
import {useDefaultAnalysisFolderId} from '../../hooks/use-default-analysis-folder-id.hook';
import {withModalInactiveSourceHandler} from '../../../../core/hoc/with-modal-inactive-source-handler.hoc';
import {useSimilarAnalyses} from '../../hooks/use-similar-analyses.hook';
import {SimilarAnalysis} from '../../../../objects/models/analysis.model';
import {useNavigate} from 'react-router';
import {useDemoProduct} from '../../../../core/hooks/use-demo-product.hook';
import {sendHotjarEvent} from '../../../../config/hotjar.config';

interface OwnProps {
  data: Partial<AnalysisDTO>;
  analysisResult?: AnalysisResult;
  onClose?: () => void;
  [ANALYSIS_RESULT_ID_PATH_PARAM]?: number;
  [ANALYSIS_ID_PATH_PARAM]?: number;
  [ANALYSIS_TYPE_ID_PATH_PARAM]?: number;
  [ANALYSIS_FOLDER_ID_PATH_PARAM]?: number;
  [ANALYSIS_PARAMETERS_KEY]?: any;
  [ANALYSIS_RUN_PARAMETERS_KEY]?: any;
  [SOURCE_PROP_KEY]?: string;
  editMode?: boolean;
}

type AllProps = OwnProps;

const createEmptyAnalysisResult = (): Partial<AnalysisDTO> => ({
  analysisId: null,
  analysisResultId: null,
  analysisTypeId: null,
  parameters: null,
  runParameters: null,
  userNotes: null,
});

enum WizardStep {
  SELECT_ANALYSIS = 'SELECT_ANALYSIS',
  SET_PARAMETERS = 'SET_PARAMETERS',
  ANALYSIS_INFO = 'ANALYSIS_INFO',
  DONE = 'DONE',
}

const wizardLabels = [
  {
    label: 'Select Analysis',
    value: WizardStep.SELECT_ANALYSIS,
  },
  {
    label: 'Set Parameters',
    value: WizardStep.SET_PARAMETERS,
  },
  {
    label: 'Name it',
    value: WizardStep.ANALYSIS_INFO,
  },
  {
    label: 'Done!',
    value: WizardStep.DONE,
  },
];

const SELECTED_ANALYSIS_RESULT_KEY = SharedSelectionKeys.ANALYSIS_FORM__ANALYSIS_RESULT;
const EXCLUDE_ANALYSIS_TYPE_IDS = [AnalysisTypeId.A_B_TEST, AnalysisTypeId.RELEASE_IMPACT];
const ADMIN_CONFIRM_PUBLIC_FOLDER_MESSAGE =
  'ADMIN NOTICE! You are about to create this analysis in a PUBLIC folder, please type in "yes" to continue!';
const ADMIN_CONFIRM_ANSWER = 'yes';
const MAX_SIMILAR_ANALYSES_COUNT = 2;

const AnalysisFormPanelComponent = (props: AllProps) => {
  const {
    onClose,
    data = createEmptyAnalysisResult(),
    [ANALYSIS_RESULT_ID_PATH_PARAM]: analysisResultIdFromProps,
    [ANALYSIS_TYPE_ID_PATH_PARAM]: analysisTypeIdFromProps,
    [ANALYSIS_ID_PATH_PARAM]: analysisIdFromProps,
    [ANALYSIS_FOLDER_ID_PATH_PARAM]: analysisFolderIdFromProps,
    [ANALYSIS_PARAMETERS_KEY]: analysisParametersFromProps,
    [ANALYSIS_RUN_PARAMETERS_KEY]: analysisRunParametersFromProps,
    [SOURCE_PROP_KEY]: appSource,
    editMode,
  } = props;
  const notify = useAmplitude();
  const dispatch = useDispatch();
  const {openSecondaryPanel} = useContext(PanelsContext);
  const isAdmin = useIsAdmin();
  const {isDemoProduct, getDemoAnalysisResultResource, analysisTypeIdsAvailableForDemo} =
    useDemoProduct();
  const navigate = useNavigate();
  const {exec: getAnalysisFolder, isLoading: isLoadingFolder} = useRemoteSource({
    networkRequest: getAnalysisFolderNetworkRequest,
    type: 'source',
  });
  const [createdAnalysisId, setCreatedAnalysisId] = useState<number>(null);
  const {similarAnalyses, isLoading: isLoadingSimilarAnalyses} = useSimilarAnalyses(
    createdAnalysisId,
    MAX_SIMILAR_ANALYSES_COUNT
  );
  const {t} = useTranslation();
  const [activeIndex, setActiveIndex] = useState(
    analysisResultIdFromProps || analysisTypeIdFromProps ? 1 : 0
  );
  const defaultAnalysisFolderId = useDefaultAnalysisFolderId(analysisFolderIdFromProps);
  const userAnalysisName = useMemo(() => {
    if (!data?.userAnalysisName) {
      return '';
    }
    if (data?.userAnalysisName && editMode) {
      return data?.userAnalysisName;
    }
    if (data?.userAnalysisName) {
      return `${data.userAnalysisName} (copy)`;
    }
  }, [data?.userAnalysisName, editMode]);
  const [formData, setFormData] = useState<any>({
    ...data,
    userNotes: '',
    userAnalysisName: userAnalysisName,
    analysisId: data?.analysisId || analysisIdFromProps,
    analysisTypeId: data?.analysisTypeId || analysisTypeIdFromProps,
    analysisResultId: data?.analysisResultId || analysisResultIdFromProps,
    analysisFolderId: data?.analysisFolderId || defaultAnalysisFolderId,
    parameters: data?.parameters || analysisParametersFromProps,
    runParameters: data?.runParameters || analysisRunParametersFromProps,
  });
  const analysisTypeIdRef = useRef<number>(formData.analysisTypeId);
  const isWizardRef = useRef<boolean>(false);
  const formModeRef = useRef<AnalysisParametersFormMode>(null);
  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(ActionKey.REQUEST_ANALYSIS, ActionKey.EDIT_ANALYSIS)(state)
  );
  const onWizardStepClick = useCallback(
    (value: WizardStep) => {
      const labelIndex = wizardLabels.findIndex(l => l.value === value);
      const analysisTypeId = formData.analysisTypeId || analysisTypeIdRef.current;
      setActiveIndex(labelIndex || 0);
      notify(AmplitudeEvent.USER_ANALYSIS_CREATION_STEP_VIEW, {
        step_index: labelIndex,
        step_name: wizardLabels[labelIndex]?.value,
        analysis_type_id: analysisTypeId,
        is_wizard_used: isWizardRef.current,
        form_mode: formModeRef.current,
      });

      if (analysisTypeId) {
        if (value === WizardStep.SET_PARAMETERS) {
          sendHotjarEvent(HotjarEvent.ANALYSIS_PARAMETERS, `analysis_type_${analysisTypeId}`);
        }
        if (value === WizardStep.DONE) {
          sendHotjarEvent(HotjarEvent.ANALYSIS_RUN, `analysis_type_${analysisTypeId}`);
        }
      }
    },
    [setActiveIndex, formData, notify, analysisTypeIdRef, isWizardRef]
  );
  const onSignalInfo = useCallback(
    signalId =>
      openSecondaryPanel(PanelKey.SIGNAL_DEFINITION_PANEL, {
        [SIGNAL_ID_PATH_PARAM]: signalId,
      }),
    [openSecondaryPanel]
  );
  const onRunAnother = useCallback(() => {
    setFormData({});
    setActiveIndex(0);
  }, []);
  const isLastStep = useMemo(
    () => wizardLabels[activeIndex]?.value === WizardStep.DONE,
    [activeIndex]
  );
  const onSelectAnalysis = useCallback(
    (newParameters: any = {}) => {
      setFormData({
        ...createEmptyAnalysisResult(),
        analysisFolderId: defaultAnalysisFolderId,
        ...newParameters,
      });
      isWizardRef.current = exists(newParameters.parameters);
    },
    [setFormData, isWizardRef, defaultAnalysisFolderId]
  );

  const successMessageContent = useMemo(() => {
    const estimatedRuntime = getAnalysisEstimatedRuntime(formData?.analysisTypeId);
    const translationKey = estimatedRuntime
      ? TransKeys.CREATE_ANALYSIS_FORM.SUCCESS_STEP.SUB_TITLE.ESTIMATED_RUNNING_TIME
      : TransKeys.CREATE_ANALYSIS_FORM.SUCCESS_STEP.SUB_TITLE.DEFAULT;
    return t(translationKey, {estimatedRuntime});
  }, [formData, t]);

  const disableRunForNonExistingAnalysisTypesInDemo = useMemo(() => {
    if (!isDemoProduct) {
      return false;
    }
    return !analysisTypeIdsAvailableForDemo.includes(formData.analysisTypeId);
  }, [isDemoProduct, analysisTypeIdsAvailableForDemo, formData.analysisTypeId]);
  const runAnalysisSubmitBtnHelperText = useMemo(
    () =>
      disableRunForNonExistingAnalysisTypesInDemo
        ? t(TransKeys.DEMO.DISABLED_RUN_ANALYSIS_HELPER_TOOLTIP)
        : undefined,
    [disableRunForNonExistingAnalysisTypesInDemo, t]
  );

  const onSimilarAnalysisClick = useCallback(
    (similarAnalysis: SimilarAnalysis) => {
      navigate(
        AppRoutes.viewAnalysis(similarAnalysis.id, {
          [ANALYSIS_RESULT_ID_PATH_PARAM]: similarAnalysis.resultId,
        }),
        {replace: true}
      );
      onClose();
      notify(AmplitudeEvent.SIMILAR_ANALYSIS_CLICKED, {
        from_analysis_id: createdAnalysisId,
        similar_analysis_id: similarAnalysis.id,
      });
    },
    [navigate, onClose, notify, createdAnalysisId]
  );
  const onSubmitForDemo = useCallback(
    data => {
      const demoAnalysisResult = getDemoAnalysisResultResource(data.analysisTypeId);
      setTimeout(() => {
        if (demoAnalysisResult) {
          onClose();
          navigate(demoAnalysisResult);
        } else {
          openSecondaryPanel(PanelKey.BOOK_A_DEMO_PANEL);
        }
      }, 500);
      // Move to DONE step before redirecting to the demo analysis or opening the demo panel
      onWizardStepClick(WizardStep.DONE);
    },
    [getDemoAnalysisResultResource, navigate, onWizardStepClick, onClose, openSecondaryPanel]
  );
  const onSubmit_ = useCallback(
    async (data: AnalysisDTO) => {
      const action = editMode ? editAnalysis : requestAnalysis;
      if (isAdmin) {
        const analysisFolderId = data.analysisFolderId;
        const analysisFolder: any = await getAnalysisFolder(analysisFolderId);
        if (analysisFolder.actualType === AnalysisFolderType.PUBLIC) {
          const answer = window.prompt(ADMIN_CONFIRM_PUBLIC_FOLDER_MESSAGE);
          if (answer === null || answer.toLowerCase() !== ADMIN_CONFIRM_ANSWER) {
            return;
          }
        }
      }

      dispatch(
        withMetadata(
          action(
            pick(data, [
              'userAnalysisName',
              'analysisId',
              'analysisResultId',
              'analysisFolderId',
              'analysisTypeId',
              'parameters',
              'runParameters',
              'userNotes',
            ]),
            analysisResult => {
              onWizardStepClick(WizardStep.DONE);
              setCreatedAnalysisId(analysisResult.analysisId);
              return [];
            }
          ),
          {[SOURCE_META_KEY]: appSource}
        )
      );
    },
    [
      dispatch,
      onWizardStepClick,
      appSource,
      editMode,
      isAdmin,
      getAnalysisFolder,
      setCreatedAnalysisId,
    ]
  );
  const onSubmit = useMemo(
    () => (isDemoProduct ? onSubmitForDemo : onSubmit_),
    [isDemoProduct, onSubmitForDemo, onSubmit_]
  );

  useEffect(() => {
    if (similarAnalyses && isArray(similarAnalyses)) {
      notify(AmplitudeEvent.SIMILAR_ANALYSIS_LOADED, {
        count: similarAnalyses.length,
        from_analysis_id: createdAnalysisId,
      });
    }
  }, [similarAnalyses, notify, createdAnalysisId]);

  return (
    <div className={classes.Container}>
      <ModalLayout>
        <FancyHeader title={'Run Analysis'} icon={AnalysisFileIcon} onClose={onClose} />
        <div className={classes.AnalysisForm}>
          {wizardLabels[activeIndex].value !== WizardStep.SELECT_ANALYSIS && (
            <div className={classes.WizardSteps}>
              {!isLastStep && (
                <WizardStepper
                  className={classes.FullWidth}
                  activeValue={wizardLabels[activeIndex]?.value}
                  lastCompletedValue={
                    activeIndex > 0 ? wizardLabels[activeIndex - 1]?.value : undefined
                  }
                  labels={wizardLabels}
                  onClick={step => onWizardStepClick(step as WizardStep)}
                />
              )}
            </div>
          )}
          {isLoading && <GenericLoading />}
          <PrimaryTabs
            className={classes.Tabs}
            selected={wizardLabels[activeIndex].value}
            hideTabsButtons
            tabs={[
              {
                key: WizardStep.SELECT_ANALYSIS,
                render: () => (
                  <AnalysisTypeSelectionTab
                    data={formData}
                    exclude={EXCLUDE_ANALYSIS_TYPE_IDS}
                    onSignalInfo={onSignalInfo}
                    onSubmit={data => {
                      analysisTypeIdRef.current = data.analysisTypeId;
                      onSelectAnalysis(data);
                      onWizardStepClick(WizardStep.SET_PARAMETERS);
                    }}
                  />
                ),
              },
              {
                key: WizardStep.SET_PARAMETERS,
                render: () => (
                  <AnalysisParametersTab
                    className={classes.NarrowContentWrapper}
                    key={formData.analysisTypeId}
                    data={formData}
                    onBack={editMode ? undefined : () => setActiveIndex(ai => ai - 1)}
                    onSwitchAnalysis={id => {
                      onSelectAnalysis({analysisTypeId: id});
                    }}
                    onSubmit={(data, formMode) => {
                      formModeRef.current = formMode;
                      setFormData(formData => ({...formData, ...data}));
                      onWizardStepClick(WizardStep.ANALYSIS_INFO);
                    }}
                    onSignalInfo={onSignalInfo}
                  />
                ),
              },
              {
                key: WizardStep.ANALYSIS_INFO,
                render: () => (
                  <AnalysisInfoTab
                    className={classes.NarrowContentWrapper}
                    data={formData}
                    onBack={() => setActiveIndex(ai => ai - 1)}
                    submitText={t(TransKeys.GENERAL.ACTIONS.RUN)}
                    defaultFolderId={defaultAnalysisFolderId}
                    disabled={
                      isLoading || isLoadingFolder || disableRunForNonExistingAnalysisTypesInDemo
                    }
                    submitHelperText={runAnalysisSubmitBtnHelperText}
                    onSubmit={data => {
                      setFormData(formData => ({...formData, ...data}));
                      onSubmit({...formData, ...data});
                    }}
                  />
                ),
              },
              {
                key: WizardStep.DONE,
                render: () => (
                  <WizardCompletedTab
                    title={t(TransKeys.CREATE_ANALYSIS_FORM.SUCCESS_STEP.TITLE)}
                    subTitle={successMessageContent}
                    similarAnalyses={similarAnalyses}
                    isLoadingSimilarAnalyses={isLoadingSimilarAnalyses}
                    onSimilarAnalysisClick={onSimilarAnalysisClick}
                    showSimilarAnalyses
                    actions={[
                      {
                        text: t(TransKeys.GENERAL.ACTIONS.RUN_ANOTHER),
                        onClick: onRunAnother,
                        variant: 'outlined',
                      },
                      {
                        text: t(TransKeys.GENERAL.ACTIONS.CLOSE),
                        onClick: onClose,
                      },
                    ]}
                  />
                ),
              },
            ]}
          />
        </div>
      </ModalLayout>
    </div>
  );
};

export const AnalysisFormPanel = composition<AllProps>(
  AnalysisFormPanelComponent,
  withModalInactiveSourceHandler,
  withLoadBefore<AllProps>({
    data: {
      selectedKey: SELECTED_ANALYSIS_RESULT_KEY,
      actionKey: SELECTED_ANALYSIS_RESULT_KEY,
      request: getAnalysisResultNetworkRequest,
      mapPayloadFromProps: props => props[ANALYSIS_RESULT_ID_PATH_PARAM],
      shouldCall: props => props[ANALYSIS_RESULT_ID_PATH_PARAM] !== undefined,
    },
  })
);
