import {IconButton, InfoIcon, TableIcon, SmartSelectorPanel, Chip} from 'ui-components';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../../../constants/translation-keys';
import {get} from 'lodash';
import {useMemo, useState} from 'react';
import classes from '../../smart-selector.module.scss';
import classNames from 'classnames';
import {Dialog, Tooltip} from '@material-ui/core';
import {SQLViewer} from '../../../../components/general/sql-viewer/sql-viewer.component';
import {TitleWithIcon} from '../../../../components/general/title/title.component';

interface OwnProps {
  label?: string;
  data: any;
  className?: string;
}

type AllProps = OwnProps;

export const LITERAL_TYPE_TO_TEXT = {
  integer: 'Numeric',
  float: 'Numeric',
  string: 'Text',
  boolean: 'Boolean',
  date: 'Date',
};

export const TableColumnPreview = (props: AllProps) => {
  const {label, data, className} = props;
  const {t} = useTranslation();
  const [showQuery, setShowQuery] = useState(false);
  const tableName = useMemo(() => get(data, 'tableDefinition.name'), [data]);
  const type = useMemo(() => get(data, 'tableDefinition.type'), [data]);
  const query = useMemo(() => get(data, 'tableDefinition.query'), [data]);
  const literalType = useMemo(() => LITERAL_TYPE_TO_TEXT[get(data, 'literalType')], [data]);

  return (
    <>
      <SmartSelectorPanel
        className={className}
        label={label}
        title={data.name}
        description={data.description}
      >
        <div className={classes.LabelRow}>
          <span className={classes.Label}>
            {type === 'query'
              ? t(TransKeys.GENERAL.LABELS.SOURCE_VIEW)
              : t(TransKeys.GENERAL.LABELS.SOURCE_TABLE)}
          </span>
        </div>
        <div className={classes.InfoRow}>
          <Tooltip title={tableName.length > 30 ? tableName : ''} placement={'top'}>
            <span className={classNames(classes.Text, classes.Strong)}>"{tableName}"</span>
          </Tooltip>
          {type === 'query' && (
            <IconButton
              tooltipText={t(TransKeys.GENERAL.ACTIONS.SHOW_DEFINITION)}
              onClick={_ => setShowQuery(true)}
              icon={InfoIcon}
              size={'small'}
            />
          )}
        </div>
        <div className={classes.LabelRow}>
          <span className={classes.Label}>Type</span>
        </div>
        <div className={classes.InfoRow}>
          <Chip label={literalType} />
        </div>
      </SmartSelectorPanel>
      <Dialog
        open={showQuery}
        onClose={_ => setShowQuery(false)}
        PaperProps={{className: classes.Modal}}
        maxWidth={'md'}
      >
        <div className={classes.InternalModal}>
          <TitleWithIcon
            className={classes.TitleWrapper}
            titleClassName={classes.Title}
            icon={TableIcon}
            text={tableName}
          />
          <div className={classes.Body}>
            <SQLViewer sql={query} />
          </div>
        </div>
      </Dialog>
    </>
  );
};
