import {SmartSelectorPanel, Ellipsis, Chip} from 'ui-components';
import classes from '../../smart-selector.module.scss';

interface OwnProps {
  label?: string;
  data: any;
  className?: string;
}

type AllProps = OwnProps;

export const SourceTableColumnPreview = (props: AllProps) => {
  const {data, className, label} = props;

  return (
    <SmartSelectorPanel
      className={className}
      label={label}
      title={<Ellipsis maxWidth={'100%'}>{data.name}</Ellipsis>}
    >
      <div className={classes.LabelRow}>
        <span className={classes.Label}>Type</span>
      </div>
      <div className={classes.InfoRow}>
        <Chip label={data.literalType} />
      </div>
      <div className={classes.LabelRow}>
        <span className={classes.Label}>Table Name</span>
      </div>
      <div className={classes.InfoRow}>
        <Ellipsis maxWidth={'100%'}>{data.sourceTable.name}</Ellipsis>
      </div>
    </SmartSelectorPanel>
  );
};
