import React, {useCallback, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {DEFAULT_OAUTH_PROVIDERS, useRemoteSource} from 'ui-components';
import {signUpNetworkRequest} from '../../../../http/auth.network-requests';
import AuthScopeFormContainer from '../auth-scope/auth-scope-form-container/auth-scope-form-container.component';
import TransKeys from '../../../../constants/translation-keys';
import {showToastMessage} from '../../../../store/interface/interface.actions';
import {ToastType} from '../../../../objects/system/toast-type.enum';
import {SignUpDto, signUpDTOValidator} from '../../../../objects/dto/sign-up.dto';
import AuthForm from '../auth-scope/auth-scope-form/auth-form.component';
import classes from '../shared-forms.module.scss';
import {AppRoutes} from '../../../../constants/app-routes';
import {useNavigate} from 'react-router';
import {StringParam, useQueryParam} from 'use-query-params';
import {AuthFormTextInput} from '../auth-scope/inputs/auth-text-input/auth-text-input.component';
import sharedClasses from '../shared-forms.module.scss';
import {OauthProviders} from '../oauth-providers/oauth-providers.component';
import {values} from 'lodash';
import {EnumFormInput} from '../../../shared/form/components/shared-form-input.component';
import authInputClasses from '../auth-scope/inputs/auth-text-input/auth-text-input.module.scss';

enum UserJobTitles {
  ANALYST = 'analyst',
  MARKETING = 'marketing',
  PRODUCT_MANAGER = 'product_manager',
  DEVELOPER = 'developer',
  DATA_SCIENTIST = 'data_scientist',
  OTHER = 'other',
}

interface OwnProps {}

type AllProps = OwnProps;

const SignUpForm = (props: AllProps) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [authRedirectError] = useQueryParam('err', StringParam);
  const dispatch = useDispatch();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const formMethods = useForm<any>({
    resolver: yupResolver(signUpDTOValidator, {abortEarly: false}),
  });
  const {handleSubmit, watch} = formMethods;
  const jobTitle = watch('jobTitle');
  const {exec: execSignUp, isLoading} = useRemoteSource({
    networkRequest: signUpNetworkRequest,
    type: null,
    onSuccess: () => setShowSuccessMessage(true),
    onError: err => {
      const message =
        err?.data?.message || t(TransKeys.AUTH_FORMS.SIGN_UP.TOASTS_MESSAGES.HTTP.ERROR);
      dispatch(showToastMessage(message, ToastType.ERROR));
      setShowSuccessMessage(false);
    },
  });
  const jobTitlesOptions = useMemo(
    () => ({
      options: values(UserJobTitles).map(title => ({
        value: title,
        label: t(TransKeys.USERS.JOB_TITLES[title.toUpperCase()]),
      })),
    }),
    [t]
  );

  const onSubmit = useCallback(
    async (data: SignUpDto) => {
      await execSignUp(data);
    },
    [execSignUp]
  );

  const renderFormContent = () => (
    <>
      <OauthProviders providers={DEFAULT_OAUTH_PROVIDERS} action={'Signup'} />
      <div className={classes.FormBlock}>
        <AuthFormTextInput
          required
          label={t(TransKeys.AUTH_FORMS.SIGN_UP.INPUTS.EMAIL.LABEL)}
          placeholder={t(TransKeys.AUTH_FORMS.SIGN_UP.INPUTS.EMAIL.PLACEHOLDER)}
          name={'email'}
          type={'text'}
        />
      </div>
      <div className={classes.FormBlock}>
        <AuthFormTextInput
          required
          label={t(TransKeys.AUTH_FORMS.SIGN_UP.INPUTS.FIRST_NAME.LABEL)}
          placeholder={t(TransKeys.AUTH_FORMS.SIGN_UP.INPUTS.FIRST_NAME.PLACEHOLDER)}
          name={'firstName'}
          type={'text'}
        />
      </div>
      <div className={classes.FormBlock}>
        <AuthFormTextInput
          required
          label={t(TransKeys.AUTH_FORMS.SIGN_UP.INPUTS.LAST_NAME.LABEL)}
          placeholder={t(TransKeys.AUTH_FORMS.SIGN_UP.INPUTS.LAST_NAME.PLACEHOLDER)}
          name={'lastName'}
          type={'text'}
        />
      </div>
      <div className={classes.FormBlock}>
        <AuthFormTextInput
          required
          label={t(TransKeys.AUTH_FORMS.SIGN_UP.INPUTS.COMPANY_NAME.LABEL)}
          placeholder={t(TransKeys.AUTH_FORMS.SIGN_UP.INPUTS.COMPANY_NAME.PLACEHOLDER)}
          name={'companyName'}
          type={'text'}
        />
      </div>
      <div className={classes.FormBlock}>
        <EnumFormInput
          required
          name={'jobTitle'}
          label={t(TransKeys.AUTH_FORMS.SIGN_UP.INPUTS.JOB_TITLE.LABEL)}
          placeholder={t(TransKeys.AUTH_FORMS.SIGN_UP.INPUTS.JOB_TITLE.PLACEHOLDER)}
          dropdownButtonClassName={authInputClasses.AuthInput}
          clearable={false}
          options={jobTitlesOptions}
          sortValues={false}
        />
      </div>
      {jobTitle === UserJobTitles.OTHER && (
        <div className={classes.FormBlock}>
          <AuthFormTextInput
            required={false}
            label={t(TransKeys.AUTH_FORMS.SIGN_UP.INPUTS.OTHER_JOB_TITLE.LABEL)}
            placeholder={t(TransKeys.AUTH_FORMS.SIGN_UP.INPUTS.OTHER_JOB_TITLE.PLACEHOLDER)}
            name={'otherJobTitle'}
            type={'text'}
          />
        </div>
      )}
    </>
  );

  const renderSuccessMessage = () => (
    <div className={sharedClasses.SuccessMessage}>
      {t(TransKeys.AUTH_FORMS.SIGN_UP.SUCCESS_RESPONSE_INFO)}
    </div>
  );

  return (
    <AuthScopeFormContainer>
      <FormProvider {...formMethods}>
        <AuthForm
          disableOnSubmit={isLoading || showSuccessMessage}
          onSubmit={handleSubmit(onSubmit)}
          onSubmitText={t(TransKeys.AUTH_FORMS.SIGN_UP.SUBMIT)}
          responseInfo={authRedirectError && t(TransKeys.AUTH_FORMS.SIGN_UP.ERROR_RESPONSE_INFO)}
          title={t(TransKeys.AUTH_FORMS.SIGN_UP.FORM_HEADING)}
          renderLeftToSubmit={
            <span className={classes.Footer} onClick={() => navigate(AppRoutes.login())}>
              {t(TransKeys.AUTH_FORMS.SHARED_LABELS.FOOTER.BACK)}
            </span>
          }
        >
          {!showSuccessMessage && renderFormContent()}
          {showSuccessMessage && !isLoading && renderSuccessMessage()}
        </AuthForm>
      </FormProvider>
    </AuthScopeFormContainer>
  );
};

export default SignUpForm;
