import classes from './source-wizard-header.module.scss';

import {ReactNode} from 'react';

type SourceWizardHeaderProps = {
  illustration?: ReactNode;
  showIllustration?: boolean;
  title?: string;
  subtitle?: string;
};

export const SourceWizardHeader = (props: SourceWizardHeaderProps) => {
  const {showIllustration = true, illustration, title, subtitle} = props;
  return (
    <div className={classes.WizardHeader}>
      {showIllustration && illustration && (
        <div className={classes.IllustrationContainer}>{illustration}</div>
      )}
      {title && <div className={classes.Title}>{title} </div>}
      {subtitle && <div className={classes.Subtitle}>{subtitle} </div>}
    </div>
  );
};
