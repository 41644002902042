import {useCallback, useContext, useState} from 'react';
import {
  ANALYSIS_RESULT_ID_PATH_PARAM,
  SIGNAL_ID_PATH_PARAM,
} from '../../../../constants/app-routes';
import {useDispatch, useSelector} from 'react-redux';
import {AnalysisFileIcon, FancyHeader, ModalLayout} from 'ui-components';
import classes from './analysis-form-panel.module.scss';
import {getReducedLoadingStateSelector} from '../../../../store/store.selectors';
import {ActionKey} from '../../../../constants/action-key';
import {AnalysisParametersTab} from './components/analysis-parameters-tab/analysis-parameters-tab.component';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {getAnalysisResultNetworkRequest} from '../../../../http/analysis-results.network-requests';
import {PanelKey} from '../../../../constants/panels';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {AnalysisResult} from '../../../../objects/models/analysis-result.model';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {changeAnalysisParameters} from '../../../../store/analyses/analyses.actions';
import {composition, OnSuccessActionHook} from 'front-core';
import TransKeys from '../../../../constants/translation-keys';
import {withModalInactiveSourceHandler} from '../../../../core/hoc/with-modal-inactive-source-handler.hoc';
import {withDisableDemoProduct} from '../../../../core/hoc/with-disable-demo-product.hoc';

interface OwnProps {
  analysisResult?: AnalysisResult;
  onClose?: () => void;
  [ANALYSIS_RESULT_ID_PATH_PARAM]?: number;
  onSuccess?: OnSuccessActionHook;
  disabled?: boolean;
}

type AllProps = OwnProps;

const SELECTED_ANALYSIS_RESULT_KEY = SharedSelectionKeys.ANALYSIS_FORM__ANALYSIS_RESULT;

const ChangeAnalysisParametersFormPanelComponent = (props: AllProps) => {
  const {analysisResult, onClose, onSuccess, disabled} = props;
  const dispatch = useDispatch();
  const {openSecondaryPanel} = useContext(PanelsContext);
  const [formData, setFormData] = useState<any>({
    analysisTypeId: analysisResult.analysisTypeId,
    analysisId: analysisResult.analysisId,
    analysisResultId: analysisResult.id,
    parameters: analysisResult.parameters,
    runParameters: analysisResult.runParameters,
  });
  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(ActionKey.CHANGE_ANALYSIS_PARAMETERS)(state)
  );
  const onSignalInfo = useCallback(
    signalId =>
      openSecondaryPanel(PanelKey.SIGNAL_DEFINITION_PANEL, {
        [SIGNAL_ID_PATH_PARAM]: signalId,
      }),
    [openSecondaryPanel]
  );

  const onSubmit = useCallback(() => {
    const onActionSuccess = (res, action) => {
      onSuccess && onSuccess(res, action);
      onClose();
    };
    dispatch(changeAnalysisParameters(formData, {onSuccess: onActionSuccess}));
  }, [dispatch, onSuccess, onClose, formData]);

  return (
    <div className={classes.Container}>
      <ModalLayout>
        <div className={classes.AnalysisForm}>
          <FancyHeader title={'Edit Parameters'} icon={AnalysisFileIcon} onClose={onClose} />
          {isLoading && <GenericLoading />}
          <div className={classes.Form}>
            <AnalysisParametersTab
              key={formData.analysisTypeId}
              data={formData}
              submitText={TransKeys.GENERAL.ACTIONS.SAVE}
              onSubmit={data => {
                setFormData(formData => ({...formData, ...data}));
                onSubmit();
              }}
              onSignalInfo={onSignalInfo}
              disabled={disabled}
            />
          </div>
        </div>
      </ModalLayout>
    </div>
  );
};

export const ChangeAnalysisParametersFormPanel = composition<AllProps>(
  ChangeAnalysisParametersFormPanelComponent,
  withModalInactiveSourceHandler,
  withDisableDemoProduct,
  withLoadBefore({
    analysisResult: {
      selectedKey: SELECTED_ANALYSIS_RESULT_KEY,
      actionKey: SELECTED_ANALYSIS_RESULT_KEY,
      request: getAnalysisResultNetworkRequest,
      mapPayloadFromProps: props => props[ANALYSIS_RESULT_ID_PATH_PARAM],
      shouldCall: props => props[ANALYSIS_RESULT_ID_PATH_PARAM] !== undefined,
    },
  })
);
