import {noop} from 'lodash';
import {ComponentProps, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Select} from 'ui-components';
import {Footer} from './components/footer/footer.component';
import classes from './source-wizard.module.scss';
import {createSource} from '../../../../store/sources/sources.actions';
import {DataSourceFormsMap, UNSUPPORTED_SOURCES} from './data-sources.config';
import {SourceWizardLabelWrapper} from './components/source-wizard-label-wrapper/source-wizard-label-wrapper.component';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../constants/translation-keys';
import {useSourceWizardOptions} from './hooks/use-source-wizard-options.hook';
import {SourceType} from '../../../../objects/models/source.model';
import {useSelector} from 'react-redux';
import {getReducedLoadingStateSelector} from '../../../../store/store.selectors';
import {ActionKey} from '../../../../constants/action-key';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';

type WizardProps = {
  onSelectDataSource?: VoidFunction;
  onSuccess?: (unsupportedSource?: boolean) => void;
  onError?: (e: Error) => void;
};

export const SourceWizard = (props: WizardProps) => {
  const {
    onSelectDataSource: onSelectDataSourceFromProps,
    onError,
    onSuccess: onSuccessFromProps,
  } = props;

  const dispatch = useDispatch();
  const {t} = useTranslation();
  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(ActionKey.CREATE_SOURCE)(state)
  );

  const [selectedDataSource, setSelectedDataSource] = useState<SourceType | undefined>();
  const options = useSourceWizardOptions();
  const onSelectDataSource = value => {
    onSelectDataSourceFromProps?.();
    setSelectedDataSource(value);
  };

  const FormToRender = DataSourceFormsMap[selectedDataSource];
  type SubmitFormValues = Parameters<ComponentProps<typeof FormToRender>['onSubmit']>[0];

  const onSubmitForm = (values: SubmitFormValues) => {
    const unsupportedSource = UNSUPPORTED_SOURCES[selectedDataSource];
    const onSuccess = () => onSuccessFromProps(!!unsupportedSource);
    dispatch(createSource({type: selectedDataSource, credentials: values}, onSuccess, onError));
  };

  return (
    <div className={classes.Wizard}>
      {isLoading && <GenericLoading className={classes.Loader} />}
      {!isLoading && (
        <>
          <SourceWizardLabelWrapper
            label={t(TransKeys.SOURCE_WIZARD.FIELDS.DATA_SOURCE_SELECTOR.LABEL)}
            className={classes.DataSourceSelector}
          >
            <Select
              clearable={false}
              searchable={false}
              dropdownButtonClassName={classes.SourceSelect}
              placeholder={t(TransKeys.SOURCE_WIZARD.FIELDS.DATA_SOURCE_SELECTOR.PLACEHOLDER)}
              onChange={onSelectDataSource}
              options={options}
              value={selectedDataSource}
              fullWidth
            />
          </SourceWizardLabelWrapper>
          {FormToRender && <FormToRender onSubmit={onSubmitForm} />}
          {!selectedDataSource && (
            <Footer
              buttonText={t(TransKeys.GENERAL.LABELS.CONTINUE)}
              onClickSubmit={noop}
              disabledCTAButton
            />
          )}
        </>
      )}
    </div>
  );
};
