import {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {AnalysisResult} from '../../../../../objects/models/analysis-result.model';
import {AnalysisTypeId} from '../../../../../constants/analysis-type-id';
import {FormProvider, useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useDispatch, useSelector} from 'react-redux';
import {getReducedLoadingStateSelector} from '../../../../../store/store.selectors';
import {ActionKey} from '../../../../../constants/action-key';
import {useAmplitude} from '../../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../../constants/amplitude-event';
import {GenericLoading} from '../../../components/general/generic-loading/generic-loading.component';
import {preventSubmitOnEnter} from '../../../../../utils/general.utils';
import {
  Checkbox,
  DatePickerInput,
  HoverHelperTip,
  LoopsIcon,
  KPIAnalysisLineChartFollowUpParameters,
  QueryBuilderFactory,
  LabelWrapper,
  TextInput,
  UsersDuotoneIcon,
} from 'ui-components';
import AnalysisTypesFollowUpPanel from '../analysis-types-follow-up-panel/analysis-types-follow-up-panel.component';
import AnalysisTypesFollowUpPanelHeader from '../analysis-types-follow-up-panel/components/analysis-types-follow-up-panel-header.component';
import {rcaFromLineChartFollowUpActionsDTOValidator} from '../../../../../objects/dto/follow-up-actions.dto';
import classes from './rca-follow-up-panel.module.scss';
import {createRCAFromLineChartFollowUpAction} from '../../../../../store/follow-up-actions/follow-up-actions.actions';
import {withLoadBefore} from '../../../../../core/hoc/with-load-before.hoc';
import {getAnalysisResultNetworkRequest} from '../../../../../http/analysis-results.network-requests';
import {ANALYSIS_RESULT_ID_PATH_PARAM} from '../../../../../constants/app-routes';
import {TIME_FORMATS} from '../../../../../constants/time-formats';
import moment from 'moment';
import {getMaxDate} from '../../../../analyses/analysis-forms/components/ui-selectors/anomaly-period-selector/anomaly-period-selector.component';
import {FunnelAnalysisLineChartFollowUpParameters} from 'ui-components';
import {BOUNDED_ACTION_ANALYSIS_PARAMETER_MAPPING} from '../../../core/query-builders/query-builders.config';
import {set} from 'lodash';
import {
  METADATA_KEY,
  PARAMETERS_METADATA_KEY,
} from '../../../../../constants/parameters-saved-keys';
import {Signal} from '../../../../../objects/models/signal.model';
import {CONVERSION_BUILDER_COMPONENT_NAME} from '../../../core/query-builders/conversion-builder/conversion-query-builder.component';
import {multiLoaderNetworkRequest} from '../../../../../http/multi-loader.network-requests';
import {MAX_DAYS_SCHEMA_KEYS} from '../../../../analyses/analysis-forms/analysis-parameters/analysis-49/analysis-49-form.component';
import {TableEntity} from '../../../../../objects/models/table.model';
import {useDefaultAnalysisFolderId} from '../../../../analyses/hooks/use-default-analysis-folder-id.hook';
import {SELECT_ALL_SEGMENT_CATEGORIES_TAG_KEY} from '../../../core/smart-selector/segment-catetories-for-analysis-smart-selector.component';
import {ExtendedParameters} from '../../../form/form-layout/extended-parameters/extended-parameters.component';
import {composition} from 'front-core';
import {withModalInactiveSourceHandler} from '../../../../../core/hoc/with-modal-inactive-source-handler.hoc';
import {createStartDateComparingProps} from '../../../../../utils/dates.utils';

export interface RCAPanelFormParameters {
  goal: number;
  timeAggregation: string;
  startDateAnomaly: string;
  startDateComparing?: string;
  analysisMode?: string;
  useLoopsAnomalyDetectionAlgo?: boolean;
  segment?: number;
  segmentClass?: string[];
  entity: TableEntity;
  higherIsBetter: boolean;
}

interface OwnProps {
  fromToLabel?: string;
  title?: string;
  notifyAmplitudeSubmitted?: AmplitudeEvent;
  analysisFormParameters?: RCAPanelFormParameters;
  parameters?: KPIAnalysisLineChartFollowUpParameters | FunnelAnalysisLineChartFollowUpParameters;
  analysisResult?: AnalysisResult;
  multiEntities?: any;
  goalMulti?: any;
  onClose?: () => void;
}

type AllProps = OwnProps;

const FOLLOW_UP_ANALYSES_TYPES = [AnalysisTypeId.RCA_ANALYSIS];
const _GOAL_SIGNAL_ID_KEY = 'goalSignalId';
const _REF_DATE_SIGNAL_ID_KEY = 'refDateSignalId';
const _GOAL_BOUND_UNIT = 'day';

const createSharedDefaultValues = (parameters: any, analysisResult?: AnalysisResult) => ({
  analysisFolderId: analysisResult?.analysisFolderId,
  analysisResultId: analysisResult?.id,
  analysisTypeIds: FOLLOW_UP_ANALYSES_TYPES,
  segment: parameters?.segment,
  segmentClass: parameters?.segmentClass,
  timeAggregation: parameters?.timeAggregation,
  analysisMode: parameters?.analysisMode,
  startDateAnomaly: moment
    .utc(parameters?.startDateAnomaly)
    .format(TIME_FORMATS.PARAMETER_DATE_FORMAT),
  startDateComparing: null,
  useLoopsAnomalyDetectionAlgo: true,
  userAnalysisName: '',
  runPopulationSizeExplainer: true,
  runGroupSizeExplainer: true,
  runErrorExplainer: true,
  runExperimentExplainer: true,
});

const createMappedUISignalColumn = (signal: Signal) => ({
  ...QueryBuilderFactory.createSignalColumn(signal.id),
  [PARAMETERS_METADATA_KEY]: {
    [METADATA_KEY.DISPLAY_NAME_KEY]: signal.name,
    [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: 'TemplateItemQueryBuilder',
  },
});

const rcaFollowUpConfigMap = {
  [AnalysisTypeId.KPI_ANALYSIS]: {
    createFormDefaultValues: (
      analysisResult: AnalysisResult,
      parameters: KPIAnalysisLineChartFollowUpParameters
    ) => ({
      ...createSharedDefaultValues(parameters, analysisResult),
      goal: parameters.goal,
    }),
    amplitudeOnClickEvent:
      AmplitudeEvent.CREATE_RCA_FOLLOW_UP_ACTION_FROM_KPI_ANALYSIS_LINE_CHART_CLICKED,
  },
  [AnalysisTypeId.FUNNEL_ANALYSIS]: {
    createFormDefaultValues: (
      analysisResult: AnalysisResult,
      parameters: FunnelAnalysisLineChartFollowUpParameters,
      multiEntities: any
    ) => {
      const defaultValues = createSharedDefaultValues(parameters, analysisResult);
      defaultValues['signalName'] = parameters.signalName;

      const boundedActionTsTemplateQuery = QueryBuilderFactory.createTemplate('bounded_action_ts');
      boundedActionTsTemplateQuery.parameters[1] = 0;
      const mappedParameters = {
        [BOUNDED_ACTION_ANALYSIS_PARAMETER_MAPPING.goal]: createMappedUISignalColumn(
          multiEntities[parameters[_GOAL_SIGNAL_ID_KEY]]
        ),
        [BOUNDED_ACTION_ANALYSIS_PARAMETER_MAPPING.goal_bound_n]:
          analysisResult.parameters[MAX_DAYS_SCHEMA_KEYS.days_count_key],
        [BOUNDED_ACTION_ANALYSIS_PARAMETER_MAPPING.ref_date]: createMappedUISignalColumn(
          multiEntities[parameters[_REF_DATE_SIGNAL_ID_KEY]]
        ),
        [BOUNDED_ACTION_ANALYSIS_PARAMETER_MAPPING.goal_bound_unit]: _GOAL_BOUND_UNIT,
      };
      for (const [mappedKey, parameterValue] of Object.entries(mappedParameters)) {
        set(boundedActionTsTemplateQuery, mappedKey, parameterValue);
      }
      boundedActionTsTemplateQuery[PARAMETERS_METADATA_KEY] = {
        [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: CONVERSION_BUILDER_COMPONENT_NAME,
      };
      defaultValues['goalDefinition'] = boundedActionTsTemplateQuery;
      return defaultValues;
    },
    amplitudeOnClickEvent:
      AmplitudeEvent.CREATE_RCA_FOLLOW_UP_ACTION_FROM_FUNNEL_ANALYSIS_LINE_CHART_CLICKED,
  },
};

const createSelectedKey = (key: string) => `RCA_FROM_LINE_CHART_FOLLOW_UP_FORM/${key}`;
const RCAFollowUpFormPanelComponent = (props: AllProps) => {
  const {
    parameters,
    analysisResult,
    multiEntities,
    goalMulti,
    onClose,
    title: titleFromProps,
    fromToLabel: fromToLabelFromProps,
    notifyAmplitudeSubmitted: notifyAmplitudeSubmittedFromProps,
    analysisFormParameters,
  } = props;
  const [followupAnalysesRunning, setFollowupAnalysesRunning] = useState(false);
  const [isOpenAdvancedParams, setIsOpenAdvancedParams] = useState(false);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const notify = useAmplitude();
  const analysisFolderId = useDefaultAnalysisFolderId(analysisResult?.analysisFolderId);
  const isAnalysisResultContext = useMemo(
    () => Boolean(analysisResult) && analysisResult.analysisTypeId,
    [analysisResult]
  );
  const fromToLabel = useMemo(
    () => (isAnalysisResultContext ? parameters.fromToLabel : fromToLabelFromProps),
    [isAnalysisResultContext, fromToLabelFromProps, parameters?.fromToLabel]
  );
  const formDefaultValues = useMemo(() => {
    let userAnalysisName = '';
    if (goalMulti?.signals) {
      // @ts-ignore
      const goalId = analysisFormParameters?.goal || parameters?.goal;
      const signalName = goalMulti?.signals?.[goalId]?.name;
      userAnalysisName = `${signalName} change on ${fromToLabel}`;
    }
    if (isAnalysisResultContext) {
      return {
        ...rcaFollowUpConfigMap[analysisResult.analysisTypeId].createFormDefaultValues(
          analysisResult,
          parameters,
          multiEntities?.signals
        ),
        analysisFolderId,
        userAnalysisName,
      };
    }
    return {
      ...createSharedDefaultValues(analysisFormParameters),
      goal: analysisFormParameters.goal,
      entity: analysisFormParameters.entity,
      higherIsBetter: analysisFormParameters.higherIsBetter,
      analysisFolderId,
      includedSegmentsTag: SELECT_ALL_SEGMENT_CATEGORIES_TAG_KEY,
      userAnalysisName,
    };
  }, [
    isAnalysisResultContext,
    analysisResult,
    parameters,
    multiEntities?.signals,
    goalMulti?.signals,
    analysisFormParameters,
    analysisFolderId,
    fromToLabel,
  ]);
  const formMethods = useForm({
    defaultValues: formDefaultValues,
    resolver: yupResolver(rcaFromLineChartFollowUpActionsDTOValidator.noUnknown()),
  });
  const {handleSubmit, watch, setValue, control} = formMethods;
  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(ActionKey.CREATE_RCA_FROM_LINE_CHART_FOLLOW_UP_ACTIONS)(state)
  );
  const timeAggregation = watch('timeAggregation');
  const startDateComparing = watch('startDateComparing');
  const useLoopsAnomalyDetectionAlgo = watch('useLoopsAnomalyDetectionAlgo');
  const startDateAnomaly = watch('startDateAnomaly');

  const notifyAmplitudeSubmitted = useMemo(
    () =>
      isAnalysisResultContext
        ? rcaFollowUpConfigMap[analysisResult.analysisTypeId].amplitudeOnClickEvent
        : notifyAmplitudeSubmittedFromProps,
    [isAnalysisResultContext, analysisResult, notifyAmplitudeSubmittedFromProps]
  );

  const title = useMemo(
    () => (isAnalysisResultContext ? parameters.title : titleFromProps),
    [isAnalysisResultContext, titleFromProps, parameters?.title]
  );

  const disabled = useMemo(
    () => isLoading || (!useLoopsAnomalyDetectionAlgo && !startDateComparing),
    [isLoading, useLoopsAnomalyDetectionAlgo, startDateComparing]
  );

  const anomalyComparingToDateMaxDate = useMemo(() => {
    if (!startDateAnomaly || !timeAggregation) {
      return undefined;
    }
    return getMaxDate(timeAggregation as any, startDateAnomaly);
  }, [startDateAnomaly, timeAggregation]);

  const startDateComparingProps = useMemo(
    () => ({
      ...createStartDateComparingProps(timeAggregation as any),
      maxDate: anomalyComparingToDateMaxDate,
    }),
    [timeAggregation, anomalyComparingToDateMaxDate]
  );

  const onSubmit = useCallback(
    data => {
      dispatch(
        createRCAFromLineChartFollowUpAction(data, () => {
          setFollowupAnalysesRunning(true);
        })
      );
      notify(notifyAmplitudeSubmitted);
    },
    [dispatch, notify, setFollowupAnalysesRunning, notifyAmplitudeSubmitted]
  );

  const onComparingToDateChange = useCallback(
    value => {
      setValue('startDateComparing', value);
    },
    [setValue]
  );

  const onLoopsAnomalyDetectionModelChange = useCallback(
    value => {
      if (value) {
        setValue('startDateComparing', null);
      }
      setValue('useLoopsAnomalyDetectionAlgo', value);
    },
    [setValue]
  );

  return (
    <FormProvider {...formMethods}>
      <AnalysisTypesFollowUpPanel
        onClose={onClose}
        showSuccessMessage={followupAnalysesRunning}
        disabled={disabled}
        onSubmit={e => {
          e.stopPropagation();
          handleSubmit(onSubmit)(e);
        }}
        className={classes.RCAFollowUpPanelContainer}
      >
        {isLoading && <GenericLoading />}
        <form
          onKeyDown={preventSubmitOnEnter}
          onSubmit={e => {
            e.stopPropagation();
            handleSubmit(onSubmit)(e);
          }}
          className={classes.RCAFollowUpForm}
        >
          <AnalysisTypesFollowUpPanelHeader
            onClose={onClose}
            topIcon={
              <div className={classes.TopIconContainer}>
                <UsersDuotoneIcon className={classes.Icon} />
              </div>
            }
            staticTitle={title}
            dynamicTitle={<span className={classes.FromToLabel}>{fromToLabel}</span>}
            className={classes.RCAHeader}
          />
          <div className={classes.ContentWrapper}>
            <div className={classes.Content}>
              <span className={classes.ContentLabel}>
                {t(TransKeys.RCA_FROM_LINE_CHART_FOLLOW_UP_PANEL.CONTENT_TITLE)}
              </span>
              <Checkbox
                checked={useLoopsAnomalyDetectionAlgo}
                onChange={() => onLoopsAnomalyDetectionModelChange(true)}
                className={classes.CompareOption}
                size={'large'}
                label={
                  <div className={classes.AlgoTextWrapper}>
                    <LoopsIcon className={classes.Icon} />
                    <span className={classes.AlgoText}>
                      {t(
                        TransKeys.RCA_FROM_LINE_CHART_FOLLOW_UP_PANEL
                          .ANOMALY_DETECTION_CHECKBOX_TEXT
                      )}
                    </span>
                    <HoverHelperTip
                      title={t(
                        TransKeys.RCA_FROM_LINE_CHART_FOLLOW_UP_PANEL
                          .ANOMALY_DETECTION_CHECKBOX_HELPER_TEXT
                      )}
                    />
                  </div>
                }
              />
              <Checkbox
                checked={!useLoopsAnomalyDetectionAlgo}
                onChange={() => onLoopsAnomalyDetectionModelChange(false)}
                className={classes.CompareOption}
                size={'large'}
                label={
                  <div className={classes.CustomCompareOption}>
                    <span className={classes.CustomCompareOptionLabel}>
                      {t(TransKeys.RCA_FROM_LINE_CHART_FOLLOW_UP_PANEL.ANOMALY_START_DATE.PREFIX)}
                    </span>
                    <DatePickerInput
                      placeholder={t(
                        TransKeys.RCA_FROM_LINE_CHART_FOLLOW_UP_PANEL.ANOMALY_START_DATE.PLACEHOLDER
                      )}
                      value={startDateComparing}
                      onChange={onComparingToDateChange}
                      {...startDateComparingProps}
                    />
                  </div>
                }
              />
              <LabelWrapper label={t(TransKeys.RCA_FROM_LINE_CHART_FOLLOW_UP_PANEL.LABEL)} required>
                <Controller
                  render={({field, fieldState: {error}}) => (
                    <TextInput
                      placeholder={t(TransKeys.RCA_FROM_LINE_CHART_FOLLOW_UP_PANEL.PLACEHOLDER)}
                      value={field.value}
                      onChange={field.onChange}
                      error={Boolean(error)}
                      required
                      className={classes.UserAnalysisNameInput}
                    />
                  )}
                  name={'userAnalysisName'}
                  control={control}
                />
              </LabelWrapper>
            </div>
            <div className={classes.Content}>
              <ExtendedParameters
                label={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_139.ADVANCED_PARAMETERS)}
                isOpen={isOpenAdvancedParams}
                onOpenChanged={() => setIsOpenAdvancedParams(prevState => !prevState)}
                headClassName={classes.ExtendedParametersHead}
              >
                <span className={classes.ExplainersSubtitle}>
                  {t(TransKeys.ANALYSIS_FORMS.ANALYSIS_139.EXPLAINERS_SUB_TITLE)}
                </span>
                <LabelWrapper className={classes.ExplainerLabelWrapper} label={'Analyse segments'}>
                  <Controller
                    render={({field}) => (
                      <Checkbox
                        className={classes.Checkbox}
                        checked={field.value}
                        onChange={field.onChange}
                        size={'large'}
                        label={
                          <span className={classes.Label}>
                            {t(TransKeys.ANALYSIS_FORMS.ANALYSIS_139.SEGMENT_EXPLAINER.SUB_TITLE)}
                          </span>
                        }
                      />
                    )}
                    name={'runPopulationSizeExplainer'}
                    control={control}
                  />
                </LabelWrapper>
                <LabelWrapper
                  className={classes.ExplainerLabelWrapper}
                  label={'Analyse group size'}
                >
                  <Controller
                    render={({field}) => (
                      <Checkbox
                        className={classes.Checkbox}
                        checked={field.value}
                        onChange={field.onChange}
                        size={'large'}
                        label={
                          <span className={classes.Label}>
                            {t(
                              TransKeys.ANALYSIS_FORMS.ANALYSIS_139.GROUP_SIZE_EXPLAINER.SUB_TITLE
                            )}
                          </span>
                        }
                      />
                    )}
                    name={'runGroupSizeExplainer'}
                    control={control}
                  />
                </LabelWrapper>
                <LabelWrapper className={classes.ExplainerLabelWrapper} label={'Analyse errors'}>
                  <Controller
                    render={({field}) => (
                      <Checkbox
                        className={classes.Checkbox}
                        checked={field.value}
                        onChange={field.onChange}
                        size={'large'}
                        label={
                          <span className={classes.Label}>
                            {t(TransKeys.ANALYSIS_FORMS.ANALYSIS_139.ERROR_EXPLAINER.SUB_TITLE)}
                          </span>
                        }
                      />
                    )}
                    name={'runErrorExplainer'}
                    control={control}
                  />
                </LabelWrapper>
                <LabelWrapper
                  className={classes.ExplainerLabelWrapper}
                  label={'Analyse experiments'}
                >
                  <Controller
                    render={({field}) => (
                      <Checkbox
                        checked={field.value}
                        onChange={field.onChange}
                        size={'large'}
                        label={
                          <span className={classes.Label}>
                            {t(
                              TransKeys.ANALYSIS_FORMS.ANALYSIS_139.EXPERIMENT_EXPLAINER.SUB_TITLE
                            )}
                          </span>
                        }
                      />
                    )}
                    name={'runExperimentExplainer'}
                    control={control}
                  />
                </LabelWrapper>
              </ExtendedParameters>
            </div>
          </div>
        </form>
      </AnalysisTypesFollowUpPanel>
    </FormProvider>
  );
};

export const RCAFollowUpFormPanel = composition<AllProps>(
  RCAFollowUpFormPanelComponent,
  withModalInactiveSourceHandler,
  withLoadBefore<AllProps>({
    analysisResult: {
      selectedKey: createSelectedKey('ANALYSIS_RESULT'),
      actionKey: createSelectedKey('ANALYSIS_RESULT'),
      request: getAnalysisResultNetworkRequest,
      shouldCall: props => props[ANALYSIS_RESULT_ID_PATH_PARAM] !== undefined,
      mapPayloadFromProps: props => props[ANALYSIS_RESULT_ID_PATH_PARAM],
    },
    goalMulti: {
      selectedKey: createSelectedKey('GOAL_MULTI'),
      actionKey: createSelectedKey('GOAL_MULTI'),
      request: multiLoaderNetworkRequest,
      shouldCall: props => {
        // @ts-ignore
        return Boolean(props.analysisFormParameters?.goal || props.parameters?.goal);
      },
      mapPayloadFromProps: props => {
        // @ts-ignore
        const goalId = props.analysisFormParameters?.goal || props.parameters?.goal;
        return {
          signals: [goalId],
        };
      },
    },
    multiEntities: {
      selectedKey: createSelectedKey('MULTI'),
      actionKey: createSelectedKey('MULTI'),
      request: multiLoaderNetworkRequest,
      shouldCall: props => {
        if (!props.parameters) {
          return false;
        }
        const {parameters} = props;
        return Boolean(
          parameters[_GOAL_SIGNAL_ID_KEY] !== undefined &&
            parameters[_REF_DATE_SIGNAL_ID_KEY] !== undefined
        );
      },
      mapPayloadFromProps: props => ({
        signals: [props.parameters[_GOAL_SIGNAL_ID_KEY], props.parameters[_REF_DATE_SIGNAL_ID_KEY]],
      }),
    },
  })
);
