import {useCallback, useContext, useMemo} from 'react';
import classes from './table-events-main.module.scss';
import {
  queryFilter,
  singleTableFilter,
  tableEventsFirstSeenFilter,
  tableEventsLastSeenFilter,
} from '../../../../constants/filters';
import {getTableEventsNetworkRequest} from '../../../../http/table-events.network-requests';
import TransKeys from '../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {TableEvent} from '../../../../objects/models/table-event.model';
import {TableColumn} from '../../../shared/components/general/table/table.component';
import {MainTableStructure} from '../../../shared/infrastracture/main-table-structure/main-table-structure.component';
import {PanelType} from '../../../../objects/system/panel-type.enum';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {tableEventsToastCreator} from '../../../../store/toasts.actions';
import {ModelKey} from '../../../../constants/model-key';
import {
  HandBackPointUpSolidIcon,
  MoreIcon,
  ShapesDuotoneIcon,
  TextButton,
  TooltipIfOverflow,
  UserGroupDuotoneIcon,
} from 'ui-components';
import moment from 'moment';
import {TIME_FORMATS} from '../../../../constants/time-formats';
import {PanelKey} from '../../../../constants/panels';
import {TABLE_EVENT_ID_PATH_PARAM, TABLE_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {SOURCE_PROP_KEY} from '../../../../constants/shared-component-prop-key';
import {AppSources} from '../../../../constants/app-sources';
import {Action, Subject} from '../../../../constants/permissions';
import {useDispatch, useSelector} from 'react-redux';
import {getListCount} from '../../../../store/store.selectors';
import {isNumber} from 'lodash';
import {SignalDependencies} from '../../components/signal-dependencies/signal-dependencies.component';
import {replaceList} from '../../../../store/remote-lists/remote-list.actions';
import {ValidationStatus} from '../../../../objects/models/signal.model';
import {FlexHorizontal} from '../../../shared/components/layout/flex-layout/general-flex-layouts.component.';
import InvalidSignalWarning from '../../components/invalid-signal-warning/invalid-signal-warning.component';

import {ModelActionsDropdown} from '../../../shared/core/model-actions-dropdown/model-actions-dropdown.component';
import usePermissions from '../../../../core/hooks/use-permissions.hook';
import {withStopPropagation} from 'front-core';

interface OwnProps {}

type AllProps = OwnProps;

const createListKey = () => `TABLE_EVENTS_MAIN/EVENTS`;

export const TableEventsMain = (props: AllProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {openPrimaryPanel} = useContext(PanelsContext);
  const {can} = usePermissions();
  const defaultFilters = useMemo(
    () => ({
      orderBy: 'firstSeen',
      order: 'desc',
      itemsPerPage: 100,
    }),
    []
  );
  const config = useMemo(
    () => ({
      listKey: createListKey(),
      actionKey: createListKey(),
      request: getTableEventsNetworkRequest,
      onError: err => [tableEventsToastCreator('GET_ERROR')],
      modelKey: ModelKey.TABLE_EVENT,
    }),
    []
  );
  const filtersDef = useMemo(
    () => [
      queryFilter(),
      tableEventsFirstSeenFilter(),
      tableEventsLastSeenFilter(),
      singleTableFilter(),
    ],
    []
  );
  const total = useSelector(state => getListCount(config.listKey)(state));
  const title = useMemo(
    () => `${t(TransKeys.TABLE_EVENTS.HEADER.TITLE)} ${isNumber(total) ? ` (${total})` : ''}`,
    [t, total]
  );
  const createFeature = useCallback(
    (tableEvent: TableEvent) => {
      openPrimaryPanel(
        PanelKey.FEATURE_FORM_PANEL,
        {
          [TABLE_EVENT_ID_PATH_PARAM]: tableEvent.id,
          [SOURCE_PROP_KEY]: AppSources.TABLE_EVENTS_MAIN,
          onSuccess: () => {
            dispatch(replaceList(config.listKey, {}, 'append', true));
          },
        },
        PanelType.MODAL
      );
    },
    [openPrimaryPanel, dispatch, config.listKey]
  );
  const createSegment = useCallback(
    (tableEvent: TableEvent) => {
      openPrimaryPanel(
        PanelKey.SEGMENT_FORM_PANEL,
        {
          [TABLE_EVENT_ID_PATH_PARAM]: tableEvent.id,
          onSuccess: () => {
            dispatch(replaceList(config.listKey, {}, 'append', true));
          },
        },
        PanelType.MODAL
      );
    },
    [openPrimaryPanel, dispatch, config.listKey]
  );
  const showTable = useCallback(
    (tableId: number) =>
      openPrimaryPanel(
        PanelKey.VIEW_TABLE_PANEL,
        {[TABLE_ID_PATH_PARAM]: tableId},
        PanelType.MODAL
      ),
    [openPrimaryPanel]
  );
  const columns: TableColumn[] = useMemo(
    () => [
      {
        key: 'name',
        title: t(TransKeys.GENERAL.HEADERS.NAME),
        width: '40rem',
        sticky: 'left',
        stretch: true,
        sortable: true,
        render: (tableEvent: TableEvent) => (
          <TooltipIfOverflow title={tableEvent.name}>
            <div className={classes.Title}>{tableEvent.name}</div>
          </TooltipIfOverflow>
        ),
      },
      {
        key: 'table',
        title: t(TransKeys.TABLE_EVENTS.TABLE.HEADERS.VIEW),
        width: '16rem',
        sortable: true,
        render: (tableEvent: TableEvent) => (
          <FlexHorizontal spacing verticalAlignCenter>
            <TextButton onClick={() => showTable(tableEvent.tableId)} className={classes.TextBtn}>
              {tableEvent.tableName}
            </TextButton>
            {tableEvent.tableValidationStatus === ValidationStatus.ERROR && (
              <InvalidSignalWarning
                title={t(TransKeys.TABLE_EVENTS.TABLE_VALIDATION_STATUS_HELPER_TEXT)}
              />
            )}
          </FlexHorizontal>
        ),
      },
      {
        key: 'firstSeen',
        title: t(TransKeys.TABLE_EVENTS.TABLE.HEADERS.FIRST_SEEN),
        width: '12rem',
        sortable: true,
        render: (tableEvent: TableEvent) =>
          tableEvent.firstSeen &&
          moment(tableEvent.firstSeen).format(TIME_FORMATS.DEFAULT_DATE_FORMAT),
      },
      {
        key: 'dependencies',
        title: t(TransKeys.TABLE_EVENTS.TABLE.HEADERS.DEPENDENCIES),
        sortable: true,
        width: '9rem',
        render: (tableEvent: TableEvent) => (
          <SignalDependencies dependencies={tableEvent.dependencies} />
        ),
      },
      {
        key: 'actions',
        title: '',
        sortable: false,
        width: '12rem',
        align: 'right',
        sticky: 'right',
        render: (tableEvent: TableEvent) => {
          return (
            <div className={classes.Actions}>
              <ModelActionsDropdown
                actions={[
                  {
                    key: 'create-segment',
                    title: t(TransKeys.GENERAL.ACTIONS.CREATE_SEGMENT),
                    hide: !can(Subject.SEGMENT, Action.CREATE),
                    showEnabled: tableEvent.tableValidationStatus !== ValidationStatus.ERROR,
                    onClick: withStopPropagation(() => createSegment(tableEvent)),
                    icon: UserGroupDuotoneIcon,
                  },
                  {
                    key: 'create-feature',
                    hide: !can(Subject.FEATURE, Action.CREATE),
                    title: t(TransKeys.GENERAL.ACTIONS.CREATE_FEATURE),
                    showEnabled: tableEvent.tableValidationStatus !== ValidationStatus.ERROR,
                    onClick: withStopPropagation(() => createFeature(tableEvent)),
                    icon: ShapesDuotoneIcon,
                  },
                ]}
                label={t(TransKeys.GENERAL.LABELS.MORE_DOTS)}
                icon={MoreIcon}
                iconDropdown
              />
            </div>
          );
        },
      },
    ],
    [t, createFeature, can, createSegment, showTable]
  );

  return (
    <MainTableStructure
      className={classes.TableEventsMain}
      title={title}
      icon={HandBackPointUpSolidIcon}
      columns={columns}
      config={config}
      defaultFilters={defaultFilters}
      filtersDef={filtersDef}
      emptyStateTranslationPath={TransKeys.TABLE_EVENTS.EMPTY_STATE}
      itemsPerPage={100}
    />
  );
};
