import classNames from 'classnames';
import classes from './single-date-selector.module.scss';
import {useMemo} from 'react';
import {ParameterInputWrapper} from '../../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {TIME_FORMATS} from '../../../../../../constants/time-formats';
import {DatePickerInput, LabelWrapper} from 'ui-components';
import {get, values} from 'lodash';
import {hasErrors} from '../../../../../../utils/general.utils';

interface SchemaKeysMapping {
  date_parameter: string;
}

const DEFAULT_SCHEMA_KEYS_MAPPING: SchemaKeysMapping = {
  date_parameter: 'date_parameter',
};

interface OwnProps {
  title: string;
  onChange: (parameters: any) => void;
  value: any;
  schemaKeysMapping?: SchemaKeysMapping;
  subTitle?: string;
  helperText?: any;
  errors?: any;
  allowFutureDates?: boolean;
  clearable?: boolean;
  className?: string;
  maxDate?: Date;
}

type AllProps = OwnProps;
const MAX_DATE = new Date();

export const SingleDateSelector = (props: AllProps) => {
  const {
    title,
    subTitle,
    helperText,
    errors,
    onChange,
    schemaKeysMapping = DEFAULT_SCHEMA_KEYS_MAPPING,
    value,
    allowFutureDates,
    clearable,
    maxDate: maxDateFromProps,
    className,
  } = props;
  const hasError = useMemo(
    () => hasErrors(errors, values(schemaKeysMapping)),
    [errors, schemaKeysMapping]
  );
  const errorMessage = useMemo(
    () => get(errors, schemaKeysMapping.date_parameter)?.message,
    [errors, schemaKeysMapping]
  );

  const maxDate = useMemo(() => {
    if (maxDateFromProps) {
      return maxDateFromProps;
    }

    return allowFutureDates ? undefined : MAX_DATE;
  }, [maxDateFromProps, allowFutureDates]);

  return (
    <ParameterInputWrapper
      title={title}
      subTitle={subTitle}
      className={classNames(classes.SingleDateSelector, className)}
      helperText={helperText}
      error={hasError}
    >
      <LabelWrapper label={''} error={hasError} helperText={errorMessage}>
        <DatePickerInput
          className={classes.FitContent}
          placeholder={'Select Date'}
          value={value[schemaKeysMapping.date_parameter]}
          onChange={value => onChange({[schemaKeysMapping.date_parameter]: value})}
          dateFormat={'DD/MM/YYYY'}
          dateInputFormat={TIME_FORMATS.PARAMETER_DATE_FORMAT}
          maxDate={maxDate}
          error={Boolean(errors?.[schemaKeysMapping.date_parameter])}
          fullWidth={false}
          clearable={clearable}
          utc
        />
      </LabelWrapper>
    </ParameterInputWrapper>
  );
};
