import {action, OnSuccessActionHook} from 'front-core';

export enum SourceTableActionType {
  TRIGGER_SOURCE_TABLE_DISCOVERY = '@@source-tables/TRIGGER_SOURCE_TABLE_DISCOVERY',
}

export const triggerSourceTableDiscovery = (onSuccess?: OnSuccessActionHook) =>
  action(SourceTableActionType.TRIGGER_SOURCE_TABLE_DISCOVERY, null, {
    onSuccess,
  });
