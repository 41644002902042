import {NoExplainers, RCAInsightSentence} from './objects';
import sharedClasses from '../insights.module.scss';

interface OwnProps extends RCAInsightSentence {
  formattedSentenceParts: NoExplainers;
}

type AllProps = OwnProps;

const NoExplainersFormatter = (props: AllProps) => {
  const {formattedSentenceParts} = props;
  const {changeDescription} = formattedSentenceParts;
  return (
    <div className={sharedClasses.InsightSentenceContainer}>
      <span
        className={sharedClasses.InsightSentencePart}
      >{`We didn’t find any segments that can exclusively and strongly explain the ${changeDescription} 💤`}</span>
    </div>
  );
};

export {NoExplainersFormatter};
