import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';
import classes from '../../analysis-forms.module.scss';
import {ExtendedParameters} from '../../../../shared/form/form-layout/extended-parameters/extended-parameters.component';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../../constants/translation-keys';
import {TimeFrameSelector} from '../../components/ui-selectors/time-frame-selector/time-frame-selector.component';
import {SegmentFilterSelector} from '../../components/ui-selectors/segment-filter-selector/segment-filter-selector.component';
import {ParametersFormContext} from '../../../../shared/core/parameters-form/parameters-form.context';
import {startEndDatesDefaultHandler} from '../../../../shared/core/parameters-form/parameters-form-default-handlers.utils';
import {useProductData} from '../../../../../core/hooks/use-product-data.hook';
import {SimpleBooleanSelector} from '../../components/ui-selectors/simple-boolean-selector/simple-boolean-selector.component';
import {EntitySelector} from '../../components/ui-selectors/entity-selector/entity-selector.component';
import {TableEntity} from '../../../../../objects/models/table.model';
import {values} from 'lodash';
import {createUndefinedObject, hasError} from '../../../../../utils/general.utils';
import {AnalysisFormProps} from '../../analysis-forms.types';
import {EventsDiscoveryGoalSelector} from '../../components/ui-selectors/events-discovery-goal-selector/events-discovery-goal-selector.component';
import {ExcludeEventsSelector} from '../../components/ui-selectors/exclude-events-selector/exclude-events-selector.component';
import {TreatmentSelector} from '../../components/ui-selectors/treatment-selector/treatment-selector.component';
import {SignalDataType, SignalType} from '../../../../../objects/models/signal.model';

const treatmentsSelectorMap = {
  treatments_tag: 'event_tag',
  treatments_signals: 'treatments_signals',
};
const EVENTS_ORDER_SCHEMA_MAPPING = {
  boolean_key: 'is_order_matter',
};
const entitySchemaMapping = {
  entity: 'entity',
};
const segmentFilterSchemaMapping = {
  population_filter: 'population_filter',
};
const goalSchemaMapping = {
  analysis_mode: 'analysis_mode',
  goal: 'goal',
  reference_date: 'reference_date',
  churn_bound_n: 'churn_bound_n',
  churn_bound_unit: 'churn_bound_unit',
  n_days_after_ref_date: 'n_days_after_ref_date',
};

const TREATMENT_SIGNAL_FILTERS = [
  {
    type: SignalType.MEASURE,
    data_type: [SignalDataType.BOOLEAN, SignalDataType.STRING],
  },
];

const eventsToExcludeSchemaMapping = {excluded_events: 'excluded_events'};

const ACCEPTED_ENTITIES = [TableEntity.USER, TableEntity.TEAM, TableEntity.LEAD];

export enum EventsDiscoveryMode {
  BEFORE_GOAL = 'before_goal',
  BEFORE_CHURN = 'before_churn',
  AFTER_GOAL = 'after_goal',
  BETWEEN = 'between',
}

export const Analysis138Form = (props: AnalysisFormProps) => {
  const {onSignalInfo, className} = props;
  const [isOpenAdvancedParams, setIsOpenAdvancedParams] = useState(false);
  const {t} = useTranslation();
  const {errors, parameters, changeParametersValue, registerDefaultHandler, removeDefaultHandler} =
    useContext(ParametersFormContext);
  const {productEntities, defaultTableEntity} = useProductData();
  const entityContext = parameters[entitySchemaMapping.entity];

  useEffect(() => {
    registerDefaultHandler('start_end_dates', startEndDatesDefaultHandler);
    registerDefaultHandler('analysis_138', parameters => {
      const defaults = {};
      defaults[goalSchemaMapping.analysis_mode] = EventsDiscoveryMode.BEFORE_GOAL;
      defaults[entitySchemaMapping.entity] = TableEntity.USER;
      defaults[EVENTS_ORDER_SCHEMA_MAPPING.boolean_key] = true;
      defaults[goalSchemaMapping.n_days_after_ref_date] = 5;
      return defaults;
    });

    return () => {
      removeDefaultHandler('start_end_dates');
      removeDefaultHandler('analysis_138');
    };
  }, [registerDefaultHandler, removeDefaultHandler, entityContext, defaultTableEntity]);

  const onChangeEntityContext = useCallback(
    (entity: TableEntity) => {
      const resetKeys = Array.from(
        new Set([
          ...values(treatmentsSelectorMap),
          ...values(goalSchemaMapping),
          ...values(segmentFilterSchemaMapping),
        ])
      );
      const resetParameters = createUndefinedObject(resetKeys);
      changeParametersValue({
        [entitySchemaMapping.entity]: entity,
        ...resetParameters,
      });
    },
    [changeParametersValue]
  );
  const isOpenAdvancedParamsOrError = useMemo(() => {
    return isOpenAdvancedParams || hasError(errors, [eventsToExcludeSchemaMapping.excluded_events]);
  }, [isOpenAdvancedParams, errors]);

  return (
    <div className={classNames(classes.AnalysisForm, className)}>
      <EntitySelector
        value={parameters}
        productEntities={productEntities}
        schemaKeysMapping={entitySchemaMapping}
        onChange={v => onChangeEntityContext(v[entitySchemaMapping.entity])}
        accept={ACCEPTED_ENTITIES}
        className={classes.Parameter}
      />
      <EventsDiscoveryGoalSelector
        title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_138.GOAL.TITLE)}
        subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_138.GOAL.SUB_TITLE)}
        helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_138.GOAL.HELPER_TEXT)}
        value={parameters}
        onChange={changeParametersValue}
        onSignalInfo={onSignalInfo}
        schemaKeysMapping={goalSchemaMapping}
        errors={errors}
        entityContext={entityContext}
        className={classes.Parameter}
      />
      <TreatmentSelector
        value={parameters}
        onChange={changeParametersValue}
        className={classes.Parameter}
        schemaKeysMapping={treatmentsSelectorMap}
        errors={errors}
        entityContext={entityContext}
        signalFilters={TREATMENT_SIGNAL_FILTERS}
      />
      <TimeFrameSelector className={classes.Parameter} errors={errors} />
      <SimpleBooleanSelector
        title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_138.IS_ORDER_MATTER.TITLE)}
        subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_138.IS_ORDER_MATTER.SUB_TITLE)}
        helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_138.IS_ORDER_MATTER.HELPER_TEXT)}
        display={'vertical'}
        trueLabel={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_138.IS_ORDER_MATTER.TRUE_LABEL)}
        falseLabel={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_138.IS_ORDER_MATTER.FALSE_LABEL)}
        className={classes.Parameter}
        onChange={changeParametersValue}
        schemaKeysMapping={EVENTS_ORDER_SCHEMA_MAPPING}
        value={parameters}
        errors={errors}
      />
      {entityContext && (
        <SegmentFilterSelector
          onChange={changeParametersValue}
          value={parameters}
          className={classes.Parameter}
          schemaKeysMapping={segmentFilterSchemaMapping}
          entityContext={entityContext}
          errors={errors}
        />
      )}
      <ExtendedParameters
        className={classes.SpaceBottom}
        label={t(TransKeys.GENERAL.LABELS.ADVANCED_PARAMETERS)}
        isOpen={isOpenAdvancedParamsOrError}
        onOpenChanged={() => setIsOpenAdvancedParams(!isOpenAdvancedParams)}
      >
        <ExcludeEventsSelector
          title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_138.EXCLUDED_EVENTS.TITLE)}
          subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_138.EXCLUDED_EVENTS.SUB_TITLE)}
          helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_138.EXCLUDED_EVENTS.HELPER_TEXT)}
          value={parameters}
          onChange={changeParametersValue}
          schemaKeysMapping={eventsToExcludeSchemaMapping}
        />
      </ExtendedParameters>
    </div>
  );
};
