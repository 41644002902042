import {useMemo} from 'react';
import classes from './analysis-result-card.module.scss';
import TransKeys from '../../../../constants/translation-keys';
import {Card} from '../../../shared/components/general/card/card.component';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import moment from 'moment';
import {FlexHorizontal} from '../../../shared/components/layout/flex-layout/general-flex-layouts.component.';
import {SecondaryText} from '../../../shared/components/general/secondary-text/secondary-text.component';
import {Button, Chip, Dot} from 'ui-components';
import {
  AnalysisResult,
  AnalysisResultStatus,
} from '../../../../objects/models/analysis-result.model';
import {AnalysisParameters} from '../analysis-parameters/analysis-parameters.component';
import {get} from 'lodash';
import {CircularProgress} from '@material-ui/core';
import {Parameter} from '../../../shared/components/general/parameter/parameter.component';
import {formatDate} from '../../../../utils/general.utils';

interface OwnProps {
  analysisResult: AnalysisResult;
  onClick: (e) => void;
  className?: string;
  selected?: boolean;
}

type AllProps = OwnProps;
const IS_NEW_DIFF_DAYS = 3;

export const AnalysisResultCard = (props: AllProps) => {
  const {t} = useTranslation();
  const {analysisResult, onClick, className, selected} = props;

  const lastRunOn = useMemo(() => moment(analysisResult.completedOn).fromNow(), [analysisResult]);
  const isNew = useMemo(
    () => moment(analysisResult.completedOn).add(IS_NEW_DIFF_DAYS, 'd').isAfter(moment()),
    [analysisResult]
  );
  const startDate = useMemo(
    () => formatDate(get(analysisResult, 'runParameters.start_date')),
    [analysisResult]
  );
  const endDate = useMemo(
    () => formatDate(get(analysisResult, 'runParameters.end_date')),
    [analysisResult]
  );
  const dates = useMemo(() => {
    if (startDate && endDate) {
      return `${startDate} - ${endDate}`;
    }
    return startDate || endDate;
  }, [endDate, startDate]);
  const isCompleted = useMemo(
    () =>
      analysisResult.status === AnalysisResultStatus.COMPLETED ||
      analysisResult.status === AnalysisResultStatus.FAILED,
    [analysisResult]
  );
  const isRunning = useMemo(
    () =>
      [
        AnalysisResultStatus.RUNNING,
        AnalysisResultStatus.QUEUED,
        AnalysisResultStatus.CREATED,
        AnalysisResultStatus.REQUESTED,
      ].indexOf(analysisResult.status) > -1,
    [analysisResult]
  );

  return (
    <Card
      onClick={onClick}
      className={classNames(classes.ActionCard, selected && classes.Selected, className)}
      imageModal={false}
      title={analysisResult.userAnalysisName || analysisResult.analysisName}
      subTitle={analysisResult.analysisTypeName}
      bottomInfo={
        <FlexHorizontal fullWidth spaceBetween verticalAlignCenter>
          <FlexHorizontal spacing verticalAlignCenter>
            {isCompleted && (
              <>
                <Dot className={classes.Dot} />
                <SecondaryText
                  text={
                    analysisResult.status === AnalysisResultStatus.FAILED
                      ? t(TransKeys.GENERAL.LABELS.FAILED)
                      : t(TransKeys.GENERAL.LABELS.COMPLETED, {
                          time: lastRunOn,
                        })
                  }
                />
              </>
            )}
            {isNew && isCompleted && (
              <Chip className={classes.Chip} label={t(TransKeys.GENERAL.LABELS.NEW)} />
            )}
            {isNew && isCompleted && (
              <Chip className={classes.Chip} label={t(TransKeys.GENERAL.LABELS.NEW)} />
            )}
            {isRunning && <Chip className={classes.Chip} label={'Running'} />}
          </FlexHorizontal>
          {(analysisResult.status === AnalysisResultStatus.REQUESTED ||
            analysisResult.status === AnalysisResultStatus.RUNNING) && (
            <FlexHorizontal spacing verticalAlignCenter>
              <CircularProgress variant={'indeterminate'} disableShrink size={16} thickness={3} />
              <span>{t(TransKeys.GENERAL.LABELS.PREPARING)}</span>
            </FlexHorizontal>
          )}
        </FlexHorizontal>
      }
      blocks={[
        {
          render: () => (
            <FlexHorizontal spacing verticalAlignCenter spaceBetween fullWidth>
              <Parameter
                className={classes.Parameter}
                label={t(TransKeys.GENERAL.LABELS.TIME_FRAME)}
                value={dates}
              />
            </FlexHorizontal>
          ),
        },
        {
          render: () =>
            analysisResult.userNotes ? (
              <div className={classes.UserNotes}>{analysisResult.userNotes}</div>
            ) : null,
        },
        {
          render: () => (
            <div className={classes.Parameters}>
              <AnalysisParameters
                className={classes.InlineAction}
                label={t(TransKeys.GENERAL.LABELS.SEE_ALL_PARAMETERS)}
                analysisResultId={analysisResult.id}
              />
              {!selected && isCompleted && (
                <Button disabled={selected} className={classes.InlineAction} onClick={onClick}>
                  {t(TransKeys.GENERAL.ACTIONS.VIEW)}
                </Button>
              )}
            </div>
          ),
        },
      ]}
    />
  );
};
