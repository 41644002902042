import classNames from 'classnames';
import classes from './user-interest-step.module.scss';
import yup from '../../../../../../config/yup.config';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useCallback, useEffect, useRef} from 'react';
import TransKeys from 'translations';
import {StepTitle} from '../../components/step-title/step-title.component';
import {useTranslation} from 'react-i18next';
import {
  BulbSolidDuotoneIcon,
  FileChartPieDuotoneIcon,
  ScaleUnbalancedDuotoneIcon,
  RocketLaunchDuotoneIcon,
  PenRulerDuotoneIcon,
  ArrowTrendDownDuotoneIcon,
  Button,
} from 'ui-components';

interface FormData {
  userInterest: string;
}

interface OwnProps {
  data: FormData;
  onChange: (value: FormData) => void;
  className?: string;
}

const MAX_OPTIONS = 3;

enum UserInterestOption {
  HYPOTHESIS = 'hypothesis',
  KPI_DROP = 'kpi_drop',
  SHARED_ANALYSIS = 'shared_analysis',
  CREATE_EXPERIMENT = 'create_experiment',
  GROWTH = 'growth',
  OTHER = 'other',
}

export const userInterestStepValidations = yup.object().shape({
  userInterests: yup.array().of(yup.string()).min(1).max(MAX_OPTIONS).required(),
  userInterestOther: yup.string().when('userInterests', {
    is: userInterests => userInterests.includes(UserInterestOption.OTHER),
    then: schema => schema.required(),
    otherwise: schema => schema.nullable(),
  }),
});

type AllProps = OwnProps;

const OPTIONS = [
  {
    icon: BulbSolidDuotoneIcon,
    className: classes.Hypothesis,
    label: TransKeys.QUESTIONNAIRE.INTEREST_STEP.OPTIONS.HYPOTHESIS,
    value: UserInterestOption.HYPOTHESIS,
  },
  {
    icon: ArrowTrendDownDuotoneIcon,
    className: classes.KPIDrop,
    label: TransKeys.QUESTIONNAIRE.INTEREST_STEP.OPTIONS.KPI_DROP,
    value: UserInterestOption.KPI_DROP,
  },
  {
    icon: FileChartPieDuotoneIcon,
    className: classes.SharedAnalysis,
    label: TransKeys.QUESTIONNAIRE.INTEREST_STEP.OPTIONS.SHARED_ANALYSIS,
    value: UserInterestOption.SHARED_ANALYSIS,
  },
  {
    icon: ScaleUnbalancedDuotoneIcon,
    className: classes.CreateExperiment,
    label: TransKeys.QUESTIONNAIRE.INTEREST_STEP.OPTIONS.CREATE_EXPERIMENT,
    value: UserInterestOption.CREATE_EXPERIMENT,
  },
  {
    icon: RocketLaunchDuotoneIcon,
    className: classes.Growth,
    label: TransKeys.QUESTIONNAIRE.INTEREST_STEP.OPTIONS.GROWTH,
    value: UserInterestOption.GROWTH,
  },
  {
    icon: PenRulerDuotoneIcon,
    className: classes.Other,
    label: TransKeys.QUESTIONNAIRE.INTEREST_STEP.OPTIONS.OTHER,
    value: UserInterestOption.OTHER,
  },
];

export const UserInterestStep = (props: AllProps) => {
  const {data, onChange, className} = props;
  const {t} = useTranslation();
  const otherInputRef = useRef<HTMLInputElement>(null);

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    trigger,
    formState: {isValid},
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      userInterests: [],
      userInterestOther: undefined,
      ...data,
    },
    resolver: yupResolver(userInterestStepValidations.noUnknown()),
  });

  const userInterests = watch('userInterests');
  const userInterestOther = watch('userInterestOther');

  useEffect(() => {
    trigger(['userInterests', 'userInterestOther']);
  }, [trigger, userInterests, userInterestOther]);

  const onSelectOption = useCallback(
    (value: string) => {
      const newValues = Array.from(new Set(userInterests).add(value));
      setValue('userInterests', newValues);
      if (value === UserInterestOption.OTHER) {
        otherInputRef.current?.focus();
      }
    },
    [setValue, userInterests]
  );
  const onUnselectOption = useCallback(
    (value: string) => {
      const newValues = userInterests.filter((v: string) => v !== value);
      setValue('userInterests', newValues);
      if (value === UserInterestOption.OTHER) {
        setValue('userInterestOther', undefined);
      }
    },
    [setValue, userInterests]
  );
  const onOtherInputChange = useCallback(
    e => {
      const {value} = e.target;
      setValue('userInterestOther', value);
      if (value) {
        onSelectOption(UserInterestOption.OTHER);
      } else {
        onUnselectOption(UserInterestOption.OTHER);
      }
    },
    [setValue, onSelectOption, onUnselectOption]
  );

  const onSubmit = useCallback(data => onChange(data), [onChange]);

  return (
    <div className={classNames(classes.UserInterestStep, className)}>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.Form}>
        <StepTitle
          title={t(TransKeys.QUESTIONNAIRE.INTEREST_STEP.TITLE)}
          subTitle={t(TransKeys.QUESTIONNAIRE.INTEREST_STEP.SUB_TITLE)}
        />
        <div className={classes.Options}>
          {OPTIONS.map(o => {
            const selectionOrder = userInterests.indexOf(o.value) + 1;
            const isSelected = Boolean(selectionOrder);
            return (
              <div
                key={o.value}
                onClick={() => (isSelected ? onUnselectOption(o.value) : onSelectOption(o.value))}
                className={classNames(
                  classes.Option,
                  userInterests.length >= MAX_OPTIONS && !isSelected && classes.Disabled,
                  !isSelected && o.className,
                  isSelected && classes.Selected
                )}
              >
                <div className={classes.IconWrapper}>
                  {!isSelected && <o.icon className={classes.Icon} />}
                  {isSelected && <div className={classes.Order}>{selectionOrder}</div>}
                </div>
                <div className={classes.Label}>
                  {o.value !== UserInterestOption.OTHER && t(o.label)}
                  {o.value === UserInterestOption.OTHER && (
                    <Controller
                      name={'userInterestOther'}
                      render={({field}) => (
                        <input
                          ref={otherInputRef}
                          className={classes.OtherInput}
                          placeholder={t(o.label)}
                          onChange={onOtherInputChange}
                          value={field.value || ''}
                          onClick={e => isSelected && e.stopPropagation()}
                          onBlur={() => !field.value && onUnselectOption(o.value)}
                        />
                      )}
                      control={control}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className={classes.Spacer} />
        <Button
          disabled={!isValid}
          onClick={handleSubmit(onSubmit)}
          type={'submit'}
          className={classNames(classes.Submit, !isValid && classes.Disabled)}
        >
          {t(TransKeys.QUESTIONNAIRE.ACTIONS.CONTINUE)}
        </Button>
      </form>
    </div>
  );
};
