import {ProductPermissions} from '../../constants/permissions';

export enum UserRole {
  USER = 'user',
  ADMIN = 'admin',
}

export enum MFAVia {
  MAIL = 'mail',
  SMS = 'sms',
}

export interface UserBasicInfo {
  id?: number;
  fullName?: string;
  isInternalUser?: boolean;
}

export interface User extends UserBasicInfo {
  firstName: string;
  lastName: string;
  email: string;
  mfaVia: MFAVia;
  products: any[];
  role: UserRole | '';
  createdOn?: string;
  jobTitle?: string;
  isManager?: boolean;
  companyName?: string;
  isInternalUser?: boolean;
  productPermissions?: ProductPermissions;
  isDemoUser?: boolean;
}
