import React, {useMemo} from 'react';
import {QueryBuilderConfig, QueryBuilder, LiteralValueType} from 'ui-components';
import {queryBuilderModelConfig} from '../../../../../constants/query-builder';
import {getComponentForQuery} from '../../../../shared/core/query-builders/query-builders.utils';
import {TableEntity, TableEntityBinding} from '../../../../../objects/models/table.model';
import {CustomQueryWarning} from '../../../components/custom-query-warning/custom-query-warning.component';
import {TableEventsValueQueryBuilder} from '../../../../shared/core/query-builders/table-events-value-query-builder/table-events-value-query-builder.component';

const queryBuilderConfig: QueryBuilderConfig = {
  modelConfig: queryBuilderModelConfig,
};

interface OwnProps {
  entity: TableEntity;
  signalDefinition: any;
  errors?: any;
  onChangeSignalDefinition: (signalDefinition: any) => void;
  onColumnTypeChange: (type: LiteralValueType) => void;
}

type AllProps = OwnProps;

export const ContentQueryBuilder = (props: AllProps) => {
  const {entity, signalDefinition, errors, onChangeSignalDefinition, onColumnTypeChange} = props;

  const tableEventFilters = useMemo(
    () => [
      {
        entityBinding: TableEntityBinding.TWO_WAY,
        entityContext: entity,
      },
    ],
    [entity]
  );
  if (signalDefinition && !getComponentForQuery(signalDefinition)) {
    return (
      <>
        <CustomQueryWarning modelName={'Content'} />
        <QueryBuilder
          config={queryBuilderConfig}
          query={signalDefinition}
          errors={errors}
          disabled
        />
      </>
    );
  }

  return (
    <TableEventsValueQueryBuilder
      query={signalDefinition}
      onChange={onChangeSignalDefinition}
      errors={errors}
      filters={tableEventFilters}
      onColumnTypeChange={onColumnTypeChange}
      thenText={'And select the property to group by'}
      multiEvents={false}
      multiSelection
      sameTypeThen
    />
  );
};
