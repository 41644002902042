import classNames from 'classnames';
import classes from './form-step.module.scss';

interface OwnProps {
  className?: string;
  children: any;
  footer?: any;
}

type AllProps = OwnProps;

export const FormStep = (props: AllProps) => {
  const {children, footer, className} = props;

  return (
    <div className={classNames(classes.FormStep, className)}>
      <div className={classes.ContentWrapper}>{children}</div>
      {footer && <div className={classes.Footer}>{footer}</div>}
    </div>
  );
};
