import {action, OnErrorActionHook, OnSuccessActionHook} from 'front-core';

export enum SourcesActionType {
  CREATE_SOURCE = '@@SOURCES/CREATE_SOURCE',
}

export const createSource = (
  payload: {type: string; credentials: Record<string, string | unknown>},
  onSuccess: OnSuccessActionHook,
  onError: OnErrorActionHook
) => action(SourcesActionType.CREATE_SOURCE, payload, {onSuccess, onError});
