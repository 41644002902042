import {InviteUsersDTO, inviteUsersDTOValidator} from '../../../../objects/dto/invite-users.dto';
import classes from './invite-users-form-panel.module.scss';
import {
  Button,
  FancyHeader,
  ModalLayout,
  InviteUserIcon,
  TeamIconChip,
  LabelWrapper,
  TeamSelector,
  Select,
} from 'ui-components';
import TransKeys from '../../../../constants/translation-keys';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {useForm, Controller, FormProvider, FieldError} from 'react-hook-form';
import {preventSubmitOnEnter} from '../../../../utils/general.utils';
import {
  TextareaFormInput,
  TextFormInput,
} from '../../../shared/form/components/shared-form-input.component';
import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {yupResolver} from '@hookform/resolvers/yup';
import {ActionKey} from '../../../../constants/action-key';
import {inviteUsers} from '../../../../store/users/users.actions';
import {getReducedLoadingStateSelector} from '../../../../store/store.selectors';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {CopyLinkButton} from '../../../shared/components/general/copy-link-button/copy-link-button.component';
import {AppRoutes} from '../../../../constants/app-routes';
import {composition, exists} from 'front-core';
import {withDisableDemoProduct} from '../../../../core/hoc/with-disable-demo-product.hoc';
import {useCurrentUser} from '../../../../core/hooks/use-user.hook';

interface OwnProps {
  onClose: () => void;
  disabled?: boolean;
}

type AllProps = OwnProps;

const InviteUsersFormPanelComponent = (props: AllProps) => {
  const {onClose, disabled} = props;
  const {inviteToken} = useProductData();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {actualTeams: teams, defaultTeamId} = useProductData();
  const user = useCurrentUser();

  const formMethods = useForm({
    defaultValues: {
      message: '',
      defaultTeamId,
      products: [user.products[0].id],
    } as any,
    resolver: yupResolver(inviteUsersDTOValidator),
  });
  const {
    handleSubmit,
    control,
    formState: {errors},
  } = formMethods;

  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(ActionKey.INVITE_USERS)(state)
  );

  const teamOptions = useMemo(
    () =>
      teams.map(t => ({
        value: t.id,
        label: t.name,
        icon: props => <TeamIconChip {...props} name={t.name} color={t.color} />,
      })),
    [teams]
  );
  const inviteLink = useMemo(
    () =>
      `${window.location.host}${AppRoutes.signup().pathname}?token=${inviteToken}&inviterUserId=${
        user.id
      }`,
    [inviteToken, user.id]
  );
  const onSubmit = useCallback(
    (data: InviteUsersDTO) =>
      dispatch(
        inviteUsers(data, {
          onSuccess: onClose,
        })
      ),
    [dispatch, onClose]
  );

  const productOptions = useMemo(() => {
    return user.products.length > 1 ? user.products : [];
  }, [user.products]);

  return (
    <div className={classes.Container}>
      <ModalLayout
        footer={
          <div className={classes.Footer}>
            <CopyLinkButton copyText={inviteLink} disabled={disabled} />
            <div className={classes.Actions}>
              <Button variant={'outlined'} onClick={onClose}>
                {t(TransKeys.INVITE_USERS_FORM.FOOTER_ACTIONS.CANCEL)}
              </Button>
              <Button disabled={disabled} onClick={handleSubmit(onSubmit)}>
                {t(TransKeys.INVITE_USERS_FORM.FOOTER_ACTIONS.INVITE)}
              </Button>
            </div>
          </div>
        }
      >
        <div className={classes.Invite}>
          <FancyHeader
            title={t(TransKeys.INVITE_USERS_FORM.HEADER)}
            icon={InviteUserIcon}
            onClose={onClose}
            className={classes.InviteHeader}
          />
          <div className={classes.FormWrapper}>
            {isLoading && <GenericLoading />}
            <FormProvider {...formMethods}>
              <form
                className={classes.Form}
                onKeyDown={preventSubmitOnEnter}
                onSubmit={handleSubmit(onSubmit)}
              >
                <TextFormInput
                  name={'emails'}
                  label={t(TransKeys.INVITE_USERS_FORM.INPUTS.EMAILS.LABEL)}
                  placeholder={t(TransKeys.INVITE_USERS_FORM.INPUTS.EMAILS.PLACEHOLDER)}
                  multiple
                />
                <TextareaFormInput
                  name={'message'}
                  label={t(TransKeys.INVITE_USERS_FORM.INPUTS.MESSAGE.LABEL)}
                  placeholder={t(TransKeys.INVITE_USERS_FORM.INPUTS.MESSAGE.PLACEHOLDER)}
                />
                {teamOptions.length > 1 && (
                  <LabelWrapper label={t(TransKeys.INVITE_USERS_FORM.INPUTS.TEAM.LABEL)}>
                    <Controller
                      control={control}
                      name={'defaultTeamId'}
                      render={({field}) => (
                        <TeamSelector teams={teams} value={field.value} onChange={field.onChange} />
                      )}
                    />
                  </LabelWrapper>
                )}
                {productOptions.length > 1 && (
                  <LabelWrapper
                    label={'Products'}
                    error={exists(errors?.products)}
                    helperText={
                      (Array.isArray(errors?.products)
                        ? errors.products[0]?.message
                        : (errors.products as FieldError)?.message) ??
                      'Select which products you want to invite users'
                    }
                  >
                    <Controller
                      name="products"
                      control={control}
                      render={({field, fieldState}) => {
                        return (
                          <Select
                            {...field}
                            multi
                            searchable={productOptions.length > 5}
                            value={field.value}
                            placeholder={'Search...'}
                            onChange={field.onChange}
                            options={{
                              options: productOptions.map(product => ({
                                label: product.name,
                                value: product.id,
                              })),
                            }}
                          />
                        );
                      }}
                    />
                  </LabelWrapper>
                )}
              </form>
            </FormProvider>
          </div>
        </div>
      </ModalLayout>
    </div>
  );
};

export const InviteUsersFormPanel = composition<AllProps>(
  InviteUsersFormPanelComponent,
  withDisableDemoProduct
);
