import * as yup from 'yup';

export type AthenaFormSchema = {
  workGroup: string;
  awsSecretAccessKey: string;
  awsAccessKeyId: string;
  regionName: string;
  s3StagingDir: string;
  catalogName: string;
};

export const athenaFormSchema: yup.SchemaOf<AthenaFormSchema> = yup.object({
  workGroup: yup.string().required(),
  awsSecretAccessKey: yup.string().required(),
  awsAccessKeyId: yup.string().required(),
  regionName: yup.string().required(),
  s3StagingDir: yup.string().optional(),
  catalogName: yup.string().optional(),
});

export type RedshiftFormSchema = {
  database: string;
  host: string;
  username: string;
  password: string;
  port: string;
};

export const redshiftFormSchema: yup.SchemaOf<RedshiftFormSchema> = yup.object({
  database: yup.string().required(),
  host: yup.string().required(),
  username: yup.string().required(),
  password: yup.string().required(),
  port: yup.string().optional(),
});

type FileFieldSchemaType = {file: File; content: Record<string, unknown>};

export type BigQueryFormSchema = {
  projectId: string;
  file: FileFieldSchemaType;
};

// Define the schema for the file field
const fileFieldSchema = yup.object({
  file: yup.mixed<File>().required(), // Expecting a File object
  content: yup.object().required(), // Record<string, unknown> validation
});

export const bigQuerySchema = yup.object({
  projectId: yup.string().required(),
  file: fileFieldSchema.required(),
});

export type DatabricksFormSchema = {
  serverHostname: string;
  accessToken: string;
  httpPath: string;
};

export const databricksSchema = yup.object({
  serverHostname: yup.string().required(),
  accessToken: yup.string().required(),
  httpPath: yup.string().required(),
});

export type GenericConnectionDetailsFormSchema = {
  connectionDetails: string;
};

export const genericConnectionDetailsFormSchema = yup.object({
  connectionDetails: yup.string().required(),
});

export enum SnowflakeSourceAuthTypes {
  PASSWORD = 'password',
  KEY = 'key',
}

export type SnowflakeFormSchema = {
  accountIdentifier: string;
  username: string;
  warehouseName: string;
  userRole: string;
  catalog: string;
} & (
  | {authType: SnowflakeSourceAuthTypes.PASSWORD; password: string}
  | {authType: SnowflakeSourceAuthTypes.KEY; key: string}
);

export const snowflakeSchema = yup.object({
  authType: yup
    .mixed<SnowflakeSourceAuthTypes>()
    .oneOf(Object.values(SnowflakeSourceAuthTypes))
    .required(),
  accountIdentifier: yup.string().required(),
  username: yup.string().required(),
  warehouseName: yup.string().required(),
  userRole: yup.string().optional(),
  catalog: yup.string().optional(),
  password: yup.lazy(() =>
    yup.string().when('authType', {
      is: SnowflakeSourceAuthTypes.PASSWORD,
      then: yup.string().required('Password is required'),
      otherwise: yup.string().notRequired(),
    })
  ),
  key: yup.lazy(() =>
    yup.string().when('authType', {
      is: SnowflakeSourceAuthTypes.KEY,
      then: yup.string().required('Key is required'),
      otherwise: yup.string().notRequired(),
    })
  ),
});
