import {NRC} from './index';
import {convertDateToUTC, RequestType, toCamelCase} from 'front-core';
import {toFiltersDTO} from '../objects/dto/to-filters.dto';

export const getTableEventsNetworkRequest: NRC<void, any> = query => ({
  method: RequestType.GET,
  relativeUrl: `/app/table-events`,
  query: query,
  queryTransformer: query => {
    const firstSeenFilter = {};
    if (query.firstSeen) {
      firstSeenFilter['firstSeenFrom'] = convertDateToUTC(query.firstSeen?.from).toISOString();
      firstSeenFilter['firstSeenTo'] = convertDateToUTC(query.firstSeen?.to).toISOString();
    }
    const lastSeenFilter = {};
    if (query.lastSeen) {
      lastSeenFilter['lastSeenFrom'] = convertDateToUTC(query.lastSeen?.from).toISOString();
      lastSeenFilter['lastSeenTo'] = convertDateToUTC(query.lastSeen?.to).toISOString();
    }

    return toFiltersDTO({
      ...query,
      ...firstSeenFilter,
      ...lastSeenFilter,
    });
  },
  responseTransformer: res => toCamelCase(res.data),
});

export const getTableEventNetworkRequest: NRC<void, any> = (id: number) => ({
  method: RequestType.GET,
  relativeUrl: `/app/table-events/${id}`,
  responseTransformer: res => toCamelCase(res.data),
});
