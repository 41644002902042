import {PanelKey} from '../../../../constants/panels';
import {
  FEATURE_ID_PATH_PARAM,
  FUNNEL_ID_PATH_PARAM,
  METRIC_ID_PATH_PARAM,
  SEGMENT_ID_PATH_PARAM,
} from '../../../../constants/app-routes';
import {ModelType} from 'ui-components';

export const MODEL_PANEL_MAPPING = {
  [ModelType.FEATURE]: {
    panel: PanelKey.VIEW_FEATURE_PANEL,
    param: FEATURE_ID_PATH_PARAM,
  },
  [ModelType.METRIC]: {
    panel: PanelKey.VIEW_METRIC_PANEL,
    param: METRIC_ID_PATH_PARAM,
  },
  [ModelType.FUNNEL]: {
    panel: PanelKey.VIEW_FUNNEL_PANEL,
    param: FUNNEL_ID_PATH_PARAM,
  },
  [ModelType.SEGMENT]: {
    panel: PanelKey.VIEW_SEGMENT_PANEL,
    param: SEGMENT_ID_PATH_PARAM,
  },
};
