import {useCallback} from 'react';
import {TeamDTO} from '../../../../../../objects/dto/team.dto';
import {EditIcon, IconButton, TrashCanLightIcon} from 'ui-components';
import classes from './team-actions-cell.module.scss';
import {Action, Subject} from '../../../../../../constants/permissions.ts';
import usePermissions from '../../../../../../core/hooks/use-permissions.hook.ts';

type TeamActionsCellProps = {
  team: TeamDTO;
  onEdit?: (team: TeamDTO) => void;
  onDelete?: (team: TeamDTO) => void;
};

export const TeamActionsCell = (props: TeamActionsCellProps) => {
  const {team, onEdit, onDelete} = props;
  const {can} = usePermissions();

  const canEditTeam = can(Subject.TEAM, Action.EDIT);
  const canDeleteTeam = can(Subject.TEAM, Action.DELETE);
  const showActions = canEditTeam || canDeleteTeam;

  const handleEdit = useCallback(() => onEdit(team), [team, onEdit]);
  const handleDelete = useCallback(() => onDelete(team), [team, onDelete]);

  return (
    showActions && (
      <div className={classes.TeamActionsCell}>
        {canEditTeam && <IconButton onClick={handleEdit} icon={EditIcon} border={false} />}
        {canDeleteTeam && (
          <IconButton onClick={handleDelete} icon={TrashCanLightIcon} border={false} />
        )}
      </div>
    )
  );
};
