import classes from './simple-number-selector.module.scss';
import {ParameterInputWrapper} from '../../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {LabelWrapper, PercentageIcon, Select, TextInput} from 'ui-components';
import {isNumber, max, min, values} from 'lodash';
import {exists} from 'front-core';
import {hasErrors} from '../../../../../../utils/general.utils';

interface SchemaKeysMapping {
  number_key: string;
}

const DEFAULT_SCHEMA_KEYS_MAPPING: SchemaKeysMapping = {
  number_key: 'number_key',
};
const CUSTOM_OPTION_VALUE = 'custom';

interface OwnProps {
  title: string;
  onChange: (parameters: any) => void;
  value: any;
  schemaKeysMapping?: SchemaKeysMapping;
  options?: number[];
  subTitle?: string;
  helperText?: string;
  errors: any;
  minValue?: number;
  maxValue?: number;
  postText?: string;
  percentage?: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const fixNumber = (v: number, minValue, maxValue) => {
  if (!exists(v)) {
    return undefined;
  }
  if (!exists(minValue) && !exists(maxValue)) {
    return v;
  }
  if (exists(minValue) && !exists(maxValue)) {
    return max([v, minValue]);
  }
  if (exists(maxValue) && !exists(minValue)) {
    return min([v, maxValue]);
  }
  return min([max([v, minValue]), maxValue]);
};

const isCustomValue = (v: number, options: number[]) => {
  if (!exists(v)) {
    return undefined;
  }
  return options ? options.indexOf(v) === -1 : true;
};

export const SimpleNumberSelector = (props: AllProps) => {
  const {
    title,
    subTitle,
    helperText,
    schemaKeysMapping = DEFAULT_SCHEMA_KEYS_MAPPING,
    errors,
    value,
    onChange,
    options,
    minValue,
    maxValue,
    postText,
    percentage,
    className,
  } = props;
  const numberValue = useMemo(
    () => value[schemaKeysMapping.number_key],
    [value, schemaKeysMapping]
  );
  const [isCustomMode, setIsCustomMode] = useState(isCustomValue(numberValue, options));
  const hasError = useMemo(
    () => hasErrors(errors, values(schemaKeysMapping)),
    [errors, schemaKeysMapping]
  );
  const optionsValues = useMemo(() => {
    if (!options) {
      return undefined;
    }
    const res: any[] = options.map(o => ({
      value: o,
      label: `${o}${percentage ? '%' : ''}`,
    }));
    res.push({
      value: CUSTOM_OPTION_VALUE,
      label: 'Custom',
    });
    return res;
  }, [options, percentage]);
  const onSelectChange = useCallback(
    v => {
      if (isNumber(v) || v === null) {
        onChange({
          [schemaKeysMapping.number_key]: fixNumber(v as any, minValue, maxValue),
        });
        setIsCustomMode(false);
        return;
      }
      if (v === CUSTOM_OPTION_VALUE) {
        setIsCustomMode(true);
      }
    },
    [onChange, minValue, maxValue, schemaKeysMapping]
  );

  useEffect(() => {
    if (isCustomMode === undefined && exists(numberValue)) {
      setIsCustomMode(isCustomValue(numberValue, options));
    }
  }, [numberValue, isCustomMode, setIsCustomMode, options]);

  return (
    <ParameterInputWrapper
      title={title}
      subTitle={subTitle}
      className={className}
      helperText={helperText}
      error={hasError}
    >
      <LabelWrapper
        label={''}
        error={exists(errors[schemaKeysMapping.number_key]?.message)}
        helperText={errors[schemaKeysMapping.number_key]?.message}
      >
        <div className={classes.SimpleNumberSelector}>
          {optionsValues && (
            <div className={classes.Item}>
              <Select
                value={isCustomMode ? CUSTOM_OPTION_VALUE : numberValue}
                onChange={onSelectChange}
                options={{options: optionsValues}}
                clearable={false}
                searchable={false}
                sortValues={false}
              />
            </div>
          )}
          {isCustomMode && (
            <div className={classes.Item}>
              <TextInput
                className={classes.NumberInput}
                value={numberValue}
                onChange={value =>
                  onChange({
                    [schemaKeysMapping.number_key]: fixNumber(value as any, minValue, maxValue),
                  })
                }
                placeholder={'Number'}
                type={'number'}
                leftIcon={percentage ? PercentageIcon : undefined}
              />
            </div>
          )}
          {postText && <div className={classes.Item}>{postText}</div>}
        </div>
      </LabelWrapper>
    </ParameterInputWrapper>
  );
};
