import classNames from 'classnames';
import classes from './ad-hoc-indication.module.scss';
import {Tooltip} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {ModelType, SingleUseIcon} from 'ui-components';

interface OwnProps {
  className?: string;
  model: ModelType;
}

type AllProps = OwnProps;

export const AdHocIndication = (props: AllProps) => {
  const {model, className} = props;
  const {t} = useTranslation();

  return (
    <Tooltip
      title={t(TransKeys.GENERAL.LABELS.AD_HOC_HELPER_TEXT, {
        model: t(TransKeys.MODELS[model.toUpperCase()]),
      })}
      placement={'top'}
    >
      <span className={classNames(classes.AdHocIndication, className)}>
        <SingleUseIcon className={classes.Icon} />
        <span className={classes.Text}>{t(TransKeys.GENERAL.LABELS.AD_HOC_CHIP_TEXT)}</span>
      </span>
    </Tooltip>
  );
};
