import classes from './event-groups-main.module.scss';
import {useCallback, useContext, useEffect, useMemo} from 'react';
import {
  eventGroupTypeFilter,
  queryFilter,
  tableEntityFilterGenerator,
} from '../../../../constants/filters';
import {eventGroupToastCreator} from '../../../../store/toasts.actions';
import {ModelKey} from '../../../../constants/model-key';
import TransKeys from '../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {TableColumn} from '../../../shared/components/general/table/table.component';
import {FlexHorizontal} from '../../../shared/components/layout/flex-layout/general-flex-layouts.component.';
import {Title, TitleWithIcon} from '../../../shared/components/general/title/title.component';
import {
  Button,
  Chip,
  CircleCheckRegularIcon,
  CopyIcon,
  EditIcon,
  FoldersDuotoneIcon,
  HoverHelperTip,
  IconButton,
  MoreIcon,
  TrashIcon,
} from 'ui-components';
import moment from 'moment';
import {MainTableStructure} from '../../../shared/infrastracture/main-table-structure/main-table-structure.component';
import {PanelKey} from '../../../../constants/panels';
import {EVENT_GROUP_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {PanelType} from '../../../../objects/system/panel-type.enum';
import {useDispatch, useSelector} from 'react-redux';
import {isNumber, keys} from 'lodash';
import {replaceList} from '../../../../store/remote-lists/remote-list.actions';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../store/actions-listener/actions-listener.actions';
import {CoreActionsType} from '../../../../store/core/core.actions';
import {generateLastModified} from '../../../../utils/history.utils';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {withStopPropagation} from '../../../../utils/general.utils';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook';
import {getEventGroupsNetworkRequest} from '../../../../http/event-groups.network-requests';
import {EventGroup} from '../../../../objects/models/event-group.model';
import {
  deleteEventGroupConfirmed,
  validateEventGroup,
} from '../../../../store/event-groups/event-groups.actions';
import {Permission} from '../../../../core/components/permission.component';
import {Action, Subject} from '../../../../constants/permissions';
import usePermissions from '../../../../core/hooks/use-permissions.hook';
import {useCurrentUser} from '../../../../core/hooks/use-user.hook';
import {getListCount} from '../../../../store/store.selectors';
import {ValidationStatus} from '../../../../objects/models/signal.model';
import {QuerySqlTabsNames} from '../../../shared/core/query-sql-tabs/query-sql-tabs.component';
import InvalidSignalWarning from '../../components/invalid-signal-warning/invalid-signal-warning.component';
import {ModelActionsDropdown} from '../../../shared/core/model-actions-dropdown/model-actions-dropdown.component';
import {useDemoProduct} from '../../../../core/hooks/use-demo-product.hook';
import {getEntityIcon} from '../../../../constants/entity.consts';

interface OwnProps {}

type AllProps = OwnProps;

const createListKey = () => `EVENT_GROUPS_MAIN/EVENT_GROUPS`;

export const EventGroupsMain = (props: AllProps) => {
  const {t} = useTranslation();
  const {can} = usePermissions();
  const {openPrimaryPanel} = useContext(PanelsContext);
  const dispatch = useDispatch();
  const notify = useAmplitude();
  const {productEntities, productEntitiesMap} = useProductData();
  const {isDemoProduct} = useDemoProduct();
  const user = useCurrentUser();
  const defaultFilters = useMemo(
    () => ({
      orderBy: 'updatedOn',
      order: 'desc',
      itemsPerPage: 50,
    }),
    []
  );
  const total = useSelector(state => getListCount(createListKey())(state));
  const title = useMemo(
    () => `${t(TransKeys.EVENT_GROUPS.HEADER.TITLE)} ${isNumber(total) ? ` (${total})` : ''}`,
    [t, total]
  );
  const filtersDef = useMemo(() => {
    const filters = [queryFilter(), eventGroupTypeFilter()];
    if (keys(productEntities).length > 1) {
      filters.push(tableEntityFilterGenerator(productEntities));
    }
    return filters;
  }, [productEntities]);
  const config = useMemo(
    () => ({
      listKey: createListKey(),
      actionKey: createListKey(),
      request: getEventGroupsNetworkRequest,
      onError: err => [eventGroupToastCreator('GET_ERROR')],
      modelKey: ModelKey.EVENT_GROUP,
    }),
    []
  );
  const onValidateEventGroup = useCallback(
    (eventGroup: EventGroup) => dispatch(validateEventGroup(eventGroup.id)),
    [dispatch]
  );
  const showEventGroup = useCallback(
    (eventGroup: EventGroup) => {
      const parameters = {[EVENT_GROUP_ID_PATH_PARAM]: eventGroup.id};
      if (eventGroup.signalValidationStatus === ValidationStatus.ERROR && !isDemoProduct) {
        parameters['initialTab'] = QuerySqlTabsNames.SQL;
      }
      openPrimaryPanel(PanelKey.VIEW_EVENT_GROUP_PANEL, parameters, PanelType.MODAL);
    },
    [openPrimaryPanel, isDemoProduct]
  );
  const onCreateEdit = useCallback(
    (eventGroup?: EventGroup, parameters: any = {}) => {
      openPrimaryPanel(
        PanelKey.EVENT_GROUP_FORM_PANEL,
        {
          [EVENT_GROUP_ID_PATH_PARAM]: eventGroup?.id,
          ...parameters,
        },
        PanelType.MODAL
      );
      if (!eventGroup) {
        notify(AmplitudeEvent.EVENT_GROUP_CREATE_CLICKED, {
          userId: user.id,
        });
      }
      if (eventGroup && parameters.cloneMode) {
        notify(AmplitudeEvent.EVENT_GROUP_DUPLICATE_CLICKED, {
          userId: user.id,
        });
      }
      if (eventGroup && !parameters.cloneMode) {
        notify(AmplitudeEvent.EVENT_GROUP_EDIT_CLICKED, {
          userId: user.id,
        });
      }
    },
    [openPrimaryPanel, user, notify]
  );
  const onDelete = useCallback(
    (eventGroup: EventGroup) => dispatch(deleteEventGroupConfirmed(eventGroup.id)),
    [dispatch]
  );

  const columns: TableColumn[] = useMemo(
    () => [
      {
        key: 'name',
        title: t(TransKeys.GENERAL.HEADERS.NAME),
        width: '36rem',
        sticky: 'left',
        stretch: true,
        sortable: true,
        render: (eventGroup: EventGroup) => (
          <FlexHorizontal spacing verticalAlignCenter className={classes.TitleWrapper}>
            <Title className={classes.Title} text={eventGroup.name} caps={false} />
            {eventGroup.shortDescription && <HoverHelperTip title={eventGroup.shortDescription} />}
            {eventGroup.signalValidationStatus === ValidationStatus.ERROR && (
              <InvalidSignalWarning
                title={t(TransKeys.EVENT_GROUPS.SIGNAL_VALIDATION_STATUS_HELPER_TEXT)}
              />
            )}
          </FlexHorizontal>
        ),
      },
      {
        key: 'type',
        title: t(TransKeys.GENERAL.HEADERS.TYPE),
        width: '12rem',
        sortable: true,
        render: (eventGroup: EventGroup) => (
          <FlexHorizontal spacing verticalAlignCenter>
            <Chip label={t(TransKeys.EVENT_GROUP.TYPE[eventGroup.type.toUpperCase()])} />
          </FlexHorizontal>
        ),
      },
      {
        key: 'entity',
        title: t(TransKeys.GENERAL.HEADERS.ENTITY),
        width: '12rem',
        sortable: true,
        hidden: keys(productEntitiesMap).length < 2,
        render: (eventGroup: EventGroup) => (
          <FlexHorizontal spacing verticalAlignCenter className={classes.TitleWrapper}>
            <TitleWithIcon
              className={classes.Entity}
              titleClassName={classes.Entity}
              text={productEntitiesMap[eventGroup.entity].name}
              icon={getEntityIcon(eventGroup.entity)}
            />
          </FlexHorizontal>
        ),
      },
      {
        key: 'updatedOn',
        title: t(TransKeys.GENERAL.HEADERS.UPDATED),
        sortable: true,
        width: '16rem',
        render: (eventGroup: EventGroup) => {
          const modifier = generateLastModified(eventGroup.history[0], true);
          if (modifier) {
            return (
              <FlexHorizontal spacing verticalAlignCenter>
                {modifier.user ? t(TransKeys.GENERAL.LABELS.MODIFIED_BY, modifier) : modifier.date}
              </FlexHorizontal>
            );
          }
          return (
            <FlexHorizontal spacing verticalAlignCenter>
              {moment.utc(eventGroup.updatedOn).local().fromNow()}
            </FlexHorizontal>
          );
        },
      },
      {
        key: 'actions',
        title: '',
        width: '12rem',
        sticky: 'right',
        align: 'right',
        render: (eventGroup: EventGroup) => (
          <div className={classes.Actions}>
            <Permission subject={Subject.EVENT_GROUP} action={Action.EDIT}>
              <IconButton
                className={classes.Button}
                tooltipText={t(TransKeys.GENERAL.ACTIONS.EDIT)}
                icon={EditIcon}
                onClick={withStopPropagation(() => onCreateEdit(eventGroup))}
              />
            </Permission>
            <Permission subject={Subject.EVENT_GROUP} action={Action.CREATE}>
              <IconButton
                className={classes.Button}
                tooltipText={t(TransKeys.GENERAL.ACTIONS.DUPLICATE)}
                icon={CopyIcon}
                onClick={withStopPropagation(() => onCreateEdit(eventGroup, {cloneMode: true}))}
              />
            </Permission>
            <ModelActionsDropdown
              className={classes.Button}
              actions={[
                {
                  key: 'delete',
                  hide: !can(Subject.EVENT_GROUP, Action.DELETE),
                  title: t(TransKeys.GENERAL.ACTIONS.DELETE),
                  onClick: () => onDelete(eventGroup),
                  icon: TrashIcon,
                },
                {
                  key: 'validate',
                  title: t(TransKeys.GENERAL.ACTIONS.VALIDATE),
                  onClick: () => onValidateEventGroup(eventGroup),
                  icon: CircleCheckRegularIcon,
                },
              ]}
              label={t(TransKeys.GENERAL.LABELS.MORE_DOTS)}
              icon={MoreIcon}
              iconDropdown
            />
          </div>
        ),
      },
    ],
    [t, can, onCreateEdit, onDelete, onValidateEventGroup, productEntitiesMap]
  );

  useEffect(() => {
    const listener = action => {
      if (action.payload.modelKey === ModelKey.EVENT_GROUP) {
        dispatch(replaceList(config.listKey, {page: 1}, 'append'));
      }
    };
    dispatch(registerActionListener(CoreActionsType.MODEL_CREATED, listener));
    return () => {
      dispatch(removeActionListener(CoreActionsType.MODEL_CREATED, listener));
    };
  }, [dispatch, config]);

  return (
    <MainTableStructure
      className={classes.EventGroupsMain}
      title={title}
      helperTextTitle={t(TransKeys.EVENT_GROUPS.HEADER.HELPER_TEXT)}
      icon={FoldersDuotoneIcon}
      columns={columns}
      config={config}
      defaultFilters={defaultFilters}
      filtersDef={filtersDef}
      onRowClicked={eventGroup => showEventGroup(eventGroup)}
      headerRenderRight={
        <Permission subject={Subject.EVENT_GROUP} action={Action.CREATE}>
          <Button onClick={() => onCreateEdit()}>
            {t(TransKeys.GENERAL.ACTIONS.CREATE_EVENT_GROUP)}
          </Button>
        </Permission>
      }
      emptyStateTranslationPath={TransKeys.EVENT_GROUPS.EMPTY_STATE}
      itemsPerPage={50}
    />
  );
};
