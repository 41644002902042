import {useCallback, useContext, useEffect, useMemo} from 'react';
import {composition} from 'front-core';
import {
  AppRoutes,
  OPPORTUNITY_ID_PATH_PARAM,
  SIGNAL_ID_PATH_PARAM,
} from '../../../../constants/app-routes';
import {OpportunityHeader} from '../../components/opportunity-header/opportunity-header.component';
import {AnalysisResults} from '../../../shared/core/document-viewer/analysis-results.component';
import {getAnalysisResultNetworkRequest} from '../../../../http/analysis-results.network-requests';
import {useParams} from 'react-router';
import {PanelType} from '../../../../objects/system/panel-type.enum';
import {CoreActionsType} from '../../../../store/core/core.actions';
import {useDispatch, useSelector} from 'react-redux';
import {
  getReducedLoadingStateSelector,
  getSingleSelectedSelector,
} from '../../../../store/store.selectors';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../store/actions-listener/actions-listener.actions';
import {ModelKey} from '../../../../constants/model-key';
import {get} from 'lodash';
import {PanelKey} from '../../../../constants/panels';
import {
  createSelected,
  getSelected,
  removeSelected,
} from '../../../../store/selected/selected.actions';
import {getOpportunityNetworkRequest} from '../../../../http/opportunities.network-requests';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import {ModelDiscriminatorType} from '../../../../objects/models/user-reaction.model';
import {
  useIsDismissed,
  useIsExplored,
  useIsSnoozed,
  useSyncIsViewed,
} from '../../../../core/hooks/user-reactions.hook';
import {ShareResourceType} from '../../../../objects/models/share.model';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import PageLayout from '../../../shared/components/layout/page-layout/index';

interface OwnProps {}

type AllProps = OwnProps;

const SELECTED_OPPORTUNITY_KEY = SharedSelectionKeys.VIEW_OPPORTUNITY__OPPORTUNITY;
const SELECTED_ANALYSIS_RESULT_KEY = SharedSelectionKeys.VIEW_OPPORTUNITY__RESULT;

const ViewOpportunityComponent = (props: AllProps) => {
  const dispatch = useDispatch();
  const notifyAmplitude = useAmplitude();
  const {openSecondaryPanel, openPrimaryPanel} = useContext(PanelsContext);
  const onMoveToExploring = useIsExplored(ModelDiscriminatorType.OPPORTUNITY);
  const onDismiss = useIsDismissed(ModelDiscriminatorType.OPPORTUNITY);
  const onSnooze = useIsSnoozed(ModelDiscriminatorType.OPPORTUNITY);

  const {[OPPORTUNITY_ID_PATH_PARAM]: oppoId} = useParams<any>();
  // Selectors
  const opportunity = useSelector(state =>
    getSingleSelectedSelector(SELECTED_OPPORTUNITY_KEY, state)
  );
  useSyncIsViewed(opportunity, ModelDiscriminatorType.OPPORTUNITY);

  const analysisResult = useSelector(state =>
    getSingleSelectedSelector(SELECTED_ANALYSIS_RESULT_KEY, state)
  );
  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(SELECTED_ANALYSIS_RESULT_KEY, SELECTED_OPPORTUNITY_KEY)(state)
  );
  const analysisResultId = useMemo(
    () => opportunity?.analysisResultId || opportunity?.analysis.lastCompletedResultId,
    [opportunity]
  );
  // Effects
  useEffect(() => {
    dispatch(
      createSelected({
        selectedKey: SELECTED_OPPORTUNITY_KEY,
        actionKey: SELECTED_OPPORTUNITY_KEY,
        request: getOpportunityNetworkRequest,
        modelKey: ModelKey.OPPORTUNITY,
      })
    );
    dispatch(
      createSelected({
        selectedKey: SELECTED_ANALYSIS_RESULT_KEY,
        actionKey: SELECTED_ANALYSIS_RESULT_KEY,
        request: getAnalysisResultNetworkRequest,
      })
    );
    return () => {
      dispatch(removeSelected(SELECTED_OPPORTUNITY_KEY));
      dispatch(removeSelected(SELECTED_ANALYSIS_RESULT_KEY));
    };
  }, [dispatch]);
  useEffect(() => {
    dispatch(getSelected(SELECTED_OPPORTUNITY_KEY, oppoId));
  }, [oppoId, dispatch]);
  useEffect(() => {
    opportunity?.id &&
      notifyAmplitude(AmplitudeEvent.OPPORTUNITY_DEEP_DIVE, {
        id: opportunity.id,
      });
  }, [opportunity?.id, notifyAmplitude]);
  useEffect(() => {
    const listener = action => {
      const {modelKey, data} = action.payload;
      if (modelKey === ModelKey.EXPERIMENT) {
        get(data, 'opportunityId') === Number(oppoId) &&
          dispatch(getSelected(SELECTED_OPPORTUNITY_KEY, oppoId));
      }
    };
    dispatch(registerActionListener(CoreActionsType.MODEL_CREATED, listener));
    return () => {
      dispatch(removeActionListener(CoreActionsType.MODEL_CREATED, listener));
    };
  }, [dispatch, oppoId]);
  useEffect(() => {
    analysisResultId &&
      analysisResult?.id !== analysisResultId &&
      dispatch(getSelected(SELECTED_ANALYSIS_RESULT_KEY, analysisResultId));
  }, [analysisResult, analysisResultId, dispatch]);
  // Handlers
  const onSeeActions = useCallback(
    () =>
      openPrimaryPanel(
        PanelKey.OPPORTUNITY_ACTIONS_PANEL,
        {
          [OPPORTUNITY_ID_PATH_PARAM]: opportunity.id,
        },
        PanelType.MODAL
      ),
    [openPrimaryPanel, opportunity]
  );
  const onSeeKPI = useCallback(
    () =>
      openSecondaryPanel(PanelKey.SIGNAL_DEFINITION_PANEL, {
        [SIGNAL_ID_PATH_PARAM]: opportunity.metric.signalId,
      }),
    [openSecondaryPanel, opportunity]
  );
  const onShare = useCallback(() => {
    openPrimaryPanel(
      PanelKey.SHARE_RESOURCE_PANEL,
      {
        modelId: opportunity.id,
        type: ShareResourceType.OPPORTUNITY,
        copyLink: AppRoutes.asUrl(AppRoutes.viewOpportunity(opportunity.id)),
      },
      PanelType.MODAL
    );
  }, [openPrimaryPanel, opportunity]);

  if (!opportunity) {
    return <GenericLoading />;
  }

  return (
    <PageLayout.Layout>
      <OpportunityHeader
        opportunity={opportunity}
        onDismiss={() => onDismiss(opportunity)}
        onMoveToExploring={() => onMoveToExploring(opportunity)}
        onSnooze={() => onSnooze(opportunity)}
        onSeeActions={onSeeActions}
        onSeeKPI={onSeeKPI}
        onShare={onShare}
      />
      <PageLayout.Body isLoading={!analysisResult} noPadding>
        {analysisResult && (
          <AnalysisResults
            analysisResult={analysisResult}
            documentId={opportunity.rootDocumentId}
            isLoading={isLoading}
            analysisResultDocumentParameters={opportunity.analysisResultParameters}
            navigationDisabled
          />
        )}
      </PageLayout.Body>
    </PageLayout.Layout>
  );
};

const ViewOpportunity = composition<OwnProps>(ViewOpportunityComponent);

export default ViewOpportunity;
