import classes from './metrics-main.module.scss';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {
  metricTypeFilter,
  metricValueTypeFilter,
  queryFilter,
  tableEntityFilterGenerator,
} from '../../../../constants/filters';
import {ModelKey} from '../../../../constants/model-key';
import TransKeys from '../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {
  BullsEyeArrowDuotoneIcon,
  Button,
  Filters,
  SlidersIcon,
  TeamSelector,
  useRemoteSourceStated,
  UserGroupLightIcon,
} from 'ui-components';
import {Metric, MetricsByCategory, TeamMetric} from '../../../../objects/models/metric.model';
import {
  getMetricsByCategoryNetworkRequest,
  getTeamMetricsNetworkRequest,
} from '../../../../http/metrics.network-requests';
import {PanelKey} from '../../../../constants/panels';
import {
  AppRoutes,
  CATEGORY_ID_PATH_PARAM,
  GOAL_ID_PATH_PARAM,
  METRIC_ID_PATH_PARAM,
  TEAM_ID_PATH_PARAM,
} from '../../../../constants/app-routes';
import {PanelType} from '../../../../objects/system/panel-type.enum';
import {useDispatch, useSelector} from 'react-redux';
import {isNumber, keys} from 'lodash';
import {
  addMetricToTeam,
  deleteMetricConfirmed,
  resampleMetricConfirmed,
  setMetricNorthStar,
  setMetricOwner,
  validateMetric,
} from '../../../../store/metrics/metrics.actions';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../store/actions-listener/actions-listener.actions';
import {CoreActionsType} from '../../../../store/core/core.actions';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook';
import {Permission} from '../../../../core/components/permission.component';
import {Action, Subject} from '../../../../constants/permissions';
import {useCurrentUser} from '../../../../core/hooks/use-user.hook';
import {
  getReducedLoadingStateSelector,
  getSingleSelectedSelector,
} from '../../../../store/store.selectors';
import {JsonParam, useQueryParams} from 'use-query-params';
import {
  createSelected,
  getSelected,
  removeSelected,
} from '../../../../store/selected/selected.actions';
import {MainHeader} from '../../../shared/components/layout/main-header/main-header.component';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {MetricCategoryTable} from './components/metric-category-table/metric-category-table.component';
import {SegmentCategory} from '../../../../objects/models/segment.model';
import {
  deleteMetricCategoryConfirmed,
  swapMetricCategory,
} from '../../../../store/metric-categories/metric-categories.actions';
import {ValidationStatus} from '../../../../objects/models/signal.model';
import {QuerySqlTabsNames} from '../../../shared/core/query-sql-tabs/query-sql-tabs.component';
import {useNavigate} from 'react-router';
import {useDemoProduct} from '../../../../core/hooks/use-demo-product.hook';

interface OwnProps {}

type AllProps = OwnProps;

const SELECTED_DATA_KEY = `METRICS_MAIN/METRICS`;
const LISTENER_ACTIONS = [
  CoreActionsType.MODEL_CREATED,
  CoreActionsType.MODEL_UPDATED,
  CoreActionsType.MODEL_DELETED,
];
const MODELS_KEYS_TO_LISTEN = [
  ModelKey.METRIC,
  ModelKey.METRIC_CATEGORY,
  ModelKey.TEAM_METRICS,
  ModelKey.GOAL,
];

export const MetricsMain = (props: AllProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const notify = useAmplitude();
  const user = useCurrentUser();
  const navigate = useNavigate();
  const {productEntities, actualTeams: teams, defaultTeamId} = useProductData();
  const {isDemoProduct} = useDemoProduct();
  const [teamId, setTeamId] = useState(defaultTeamId);
  const {openPrimaryPanel, openSecondaryPanel} = useContext(PanelsContext);
  const {source: metricsByTeam, exec: getTeamMetrics} = useRemoteSourceStated({
    networkRequest: getTeamMetricsNetworkRequest,
  });
  const teamMetrics: TeamMetric[] = useMemo(() => {
    if (!teamId || !metricsByTeam) {
      return undefined;
    }
    if (!metricsByTeam[teamId]) {
      return [];
    }
    return metricsByTeam[teamId];
  }, [teamId, metricsByTeam]);
  const filtersDef = useMemo(() => {
    const filters = [queryFilter(), metricTypeFilter(), metricValueTypeFilter()];
    if (keys(productEntities).length > 1) {
      filters.push(tableEntityFilterGenerator(productEntities));
    }
    return filters;
  }, [productEntities]);
  const defaultFilters = useMemo(() => ({}), []);
  const [filtersState, setFiltersState] = useQueryParams({
    filters: JsonParam,
  });
  const data: MetricsByCategory = useSelector(state =>
    getSingleSelectedSelector(SELECTED_DATA_KEY, state)
  );
  const teamOptions = useMemo(
    () => [
      {
        name: 'All',
        id: null,
        icon: UserGroupLightIcon,
      },
      ...teams,
    ],
    [teams]
  );
  const isLoading = useSelector(state => getReducedLoadingStateSelector(SELECTED_DATA_KEY)(state));
  const categories = useMemo(() => data?.categories || [], [data]);
  const title = useMemo(() => {
    let metricsCount = undefined;
    if (teamMetrics && teamMetrics.length > 0) {
      metricsCount = teamMetrics.length;
    } else if (isNumber(data?.total)) {
      metricsCount = data.total;
    }
    return `${t(TransKeys.METRICS.HEADER.TITLE)} ${metricsCount ? ` (${metricsCount})` : ''}`;
  }, [t, data, teamMetrics]);
  const showMetric = useCallback(
    (metric: Metric) => {
      const parameters = {[METRIC_ID_PATH_PARAM]: metric.id};
      if (metric.signalValidationStatus === ValidationStatus.ERROR && !isDemoProduct) {
        parameters['initialTab'] = QuerySqlTabsNames.SQL;
      }
      openPrimaryPanel(PanelKey.VIEW_METRIC_PANEL, parameters, PanelType.MODAL);
    },
    [openPrimaryPanel, isDemoProduct]
  );
  const onCreateEdit = useCallback(
    (metric?: Metric, parameters: any = {}) => {
      openPrimaryPanel(
        PanelKey.METRIC_FORM_PANEL,
        {
          [METRIC_ID_PATH_PARAM]: metric?.id,
          ...parameters,
        },
        PanelType.MODAL
      );

      if (!metric) {
        notify(AmplitudeEvent.METRIC_CREATE_CLICKED, {
          userId: user.id,
        });
      } else {
        notify(
          parameters.cloneMode
            ? AmplitudeEvent.METRIC_DUPLICATE_CLICKED
            : AmplitudeEvent.METRIC_EDIT_CLICKED,
          {
            id: metric.id,
            userId: user.id,
          }
        );
      }
    },
    [openPrimaryPanel, user, notify]
  );
  const onDelete = useCallback(
    (metric: Metric) => dispatch(deleteMetricConfirmed(metric.id)),
    [dispatch]
  );
  const onOwnerChange = useCallback(
    (userId: number, metric: Metric) =>
      dispatch(
        setMetricOwner({
          metricId: metric.id,
          userId,
        })
      ),
    [dispatch]
  );
  const onSetNorthStar = useCallback(
    (metric: Metric) => dispatch(setMetricNorthStar(metric.id)),
    [dispatch]
  );
  const onCreateEditCategory = useCallback(
    (category?: SegmentCategory) => {
      openPrimaryPanel(
        PanelKey.METRIC_CATEGORY_FORM_PANEL,
        {
          [CATEGORY_ID_PATH_PARAM]: category?.id,
        },
        PanelType.MODAL
      );
      if (!category?.id) {
        notify(AmplitudeEvent.METRIC_CATEGORY_CREATE_CLICKED, {
          userId: user.id,
        });
      }
    },
    [openPrimaryPanel, user, notify]
  );
  const onTeamMetricsClicked = useCallback(() => {
    openPrimaryPanel(PanelKey.TEAM_METRICS_PANEL, {
      [TEAM_ID_PATH_PARAM]: teamId,
    });
    notify(AmplitudeEvent.UPDATE_FAVORITES_SEGMENTS_CLICKED, {
      userId: user.id,
    });
  }, [openPrimaryPanel, notify, user, teamId]);
  const onAddToTeam = useCallback(
    (metricId: number, teamId: number) => dispatch(addMetricToTeam(metricId, teamId)),
    [dispatch]
  );
  const onDeleteCategory = useCallback(
    (category: SegmentCategory) => dispatch(deleteMetricCategoryConfirmed(category.id)),
    [dispatch]
  );
  const onSwapCategories = useCallback(
    (category: SegmentCategory, index: number, newIndex: number) => {
      dispatch(
        swapMetricCategory({
          metricCategoryId: category.id,
          index,
          newIndex,
        })
      );
    },
    [dispatch]
  );
  const onCreateEditGoal = useCallback(
    (metric: Metric, goalId: number) => {
      openSecondaryPanel(PanelKey.GOAL_FORM_PANEL, {
        [METRIC_ID_PATH_PARAM]: metric.id,
        [GOAL_ID_PATH_PARAM]: goalId,
      });
    },
    [openSecondaryPanel]
  );
  const onResample = useCallback(
    metric => {
      dispatch(resampleMetricConfirmed(metric.id));
    },
    [dispatch]
  );
  const onValidate = useCallback(
    (metric: Metric) => dispatch(validateMetric(metric.id)),
    [dispatch]
  );
  const onViewKPIPage = useCallback(
    (metric: Metric) => navigate(AppRoutes.viewMetric(metric.id)),
    [navigate]
  );
  const onFiltersChange = useCallback(
    (newFilters: any) => {
      const filters = {...newFilters, ...defaultFilters};
      setFiltersState({filters});
      dispatch(getSelected(SELECTED_DATA_KEY, filters));
    },
    [dispatch, defaultFilters, setFiltersState]
  );

  useEffect(() => {
    dispatch(
      createSelected({
        selectedKey: SELECTED_DATA_KEY,
        actionKey: SELECTED_DATA_KEY,
        request: getMetricsByCategoryNetworkRequest,
      })
    );
    getTeamMetrics();
    dispatch(getSelected(SELECTED_DATA_KEY, filtersState.filters));
    return () => {
      dispatch(removeSelected(SELECTED_DATA_KEY));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getTeamMetrics]);

  useEffect(() => {
    const listener = action => {
      const {modelKey} = action.payload;
      if (MODELS_KEYS_TO_LISTEN.indexOf(modelKey) > -1) {
        dispatch(getSelected(SELECTED_DATA_KEY, filtersState.filters));
      }
      if (modelKey === ModelKey.TEAM_METRICS) {
        getTeamMetrics();
      }
    };
    dispatch(registerActionListener(LISTENER_ACTIONS, listener));
    return () => {
      dispatch(removeActionListener(LISTENER_ACTIONS, listener));
    };
  }, [dispatch, getTeamMetrics, filtersState]);

  return (
    <div className={classes.MetricMain}>
      <div className={classes.Header}>
        <MainHeader
          title={title}
          icon={BullsEyeArrowDuotoneIcon}
          helperText={t(TransKeys.METRICS.HEADER.HELPER_TEXT)}
          renderRight={
            <div className={classes.ActionsWrapper}>
              {teams.length > 0 && (
                <Button
                  variant={'outlined'}
                  size={'large'}
                  onClick={() => onTeamMetricsClicked()}
                  icon={SlidersIcon}
                >
                  {t(TransKeys.METRICS.ACTIONS.TEAM_METRICS)}
                </Button>
              )}
              <Button variant={'outlined'} size={'large'} onClick={() => onCreateEditCategory()}>
                {t(TransKeys.GENERAL.ACTIONS.ADD_CATEGORY)}
              </Button>
              <Permission subject={Subject.METRIC} action={Action.CREATE}>
                <Button size={'large'} onClick={() => onCreateEdit()}>
                  {t(TransKeys.GENERAL.ACTIONS.CREATE_METRIC)}
                </Button>
              </Permission>
            </div>
          }
        />
      </div>
      <div className={classes.Filters}>
        {teams.length > 0 && (
          <TeamSelector
            teams={teamOptions}
            value={teamId}
            onChange={setTeamId}
            clearable={false}
            size={'large'}
            withPrefix
          />
        )}
        <Filters
          selected={filtersState?.filters || {}}
          hideDefaultFilters
          onChange={onFiltersChange}
          onClearAll={() => onFiltersChange({})}
          freeSearchFilterKey={'q'}
          filters={filtersDef}
          maxWidth={'unset'}
          className={classes.FiltersSelector}
        />
      </div>
      <div className={classes.Body}>
        {isLoading && <GenericLoading />}
        {categories.map((category, idx) => (
          <MetricCategoryTable
            className={classes.Category}
            key={category.id}
            category={category}
            teamMetrics={teamMetrics}
            showActions={category.id > 0}
            onResetTeamFilter={() => setTeamId(null)}
            onAddToTeam={onAddToTeam}
            showTeams={teams.length > 0}
            onEditCategory={() => onCreateEditCategory(category)}
            onDeleteCategory={() => onDeleteCategory(category)}
            onMoveDown={
              idx < categories.length - 2 && !isDemoProduct
                ? () => onSwapCategories(category, idx, idx + 1)
                : undefined
            }
            onMoveUp={
              idx > 0 && !isDemoProduct ? () => onSwapCategories(category, idx, idx - 1) : undefined
            }
            onMetricClicked={m => showMetric(m)}
            onEditMetric={m => onCreateEdit(m)}
            onDuplicateMetric={m => onCreateEdit(m, {cloneMode: true})}
            onDeleteMetric={m => onDelete(m)}
            onOwnerChange={(userId, m) => onOwnerChange(userId, m)}
            onSetNorthStar={m => onSetNorthStar(m)}
            onCreateEditGoal={onCreateEditGoal}
            onResample={m => onResample(m)}
            onValidate={m => onValidate(m)}
            onViewKPIPage={m => onViewKPIPage(m)}
            onCreateMetric={() =>
              onCreateEdit(undefined, {
                [CATEGORY_ID_PATH_PARAM]: category.id,
              })
            }
          />
        ))}
      </div>
    </div>
  );
};
