import {useMemo} from 'react';
import {Metric} from '../../../../../objects/models/metric.model';
import {TableEntity} from '../../../../../objects/models/table.model';

interface OwnProps {
  cloneMode: boolean;
  metric?: Metric;
  data: Partial<Metric>;
  categoryId?: number;
  defaultTableEntity: TableEntity;
}
type AllProps = OwnProps;

export function useMetricFormInitialValues(props: AllProps) {
  const {defaultTableEntity, data, categoryId, metric, cloneMode} = props;
  return useMemo(() => {
    const values = {
      entity: defaultTableEntity,
      ...data,
      categoryId,
      ...metric,
    };
    if (cloneMode) {
      delete values.id;

      values.eventProperties = (values.eventProperties || []).map(i => ({
        ...i,
        id: undefined,
      }));
    }
    return values;
  }, [defaultTableEntity, data, categoryId, metric, cloneMode]);
}
