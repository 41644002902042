import {encodeQueryParams, JsonParam} from 'use-query-params';
import {Path} from 'react-router';
import queryString from 'query-string';
import {PanelKey} from './panels';
import {PanelType} from '../objects/system/panel-type.enum';

export type PathParam = string | number;
export type QueryParam = string | number;

export const OPPORTUNITY_ID_PATH_PARAM = 'opportunityId';
export const ANALYSIS_ID_PATH_PARAM = 'analysisId';
export const PHENOMENA_ID_PATH_PARAM = 'phenomenaId';
export const ANALYSIS_TYPE_ID_PATH_PARAM = 'analysisTypeId';
export const EXPERIMENT_ID_PATH_PARAM = 'experimentId';
export const WORKFLOW_ID_PATH_PARAM = 'workflowId';
export const EXPERIMENT_AUTOMATION_ID_PATH_PARAM = 'expAutomationId';
export const ANALYSIS_RESULT_ID_PATH_PARAM = 'resultId';
export const ANALYSIS_FOLDER_ID_PATH_PARAM = 'analysisFolderId';
export const METRIC_ID_PATH_PARAM = 'metricId';
export const EVENT_GROUP_ID_PATH_PARAM = 'eventGroupId';
export const ANNOTATION_ID_PATH_PARAM = 'annotationId';
export const GOAL_ID_PATH_PARAM = 'goalId';
export const SEGMENT_ID_PATH_PARAM = 'segmentId';
export const SIGNAL_ID_PATH_PARAM = 'signalId';
export const FUNNEL_ID_PATH_PARAM = 'funnelId';
export const MILESTONE_ID_PATH_PARAM = 'milestoneId';
export const FEATURE_ID_PATH_PARAM = 'featureId';
export const CONTENT_ID_PATH_PARAM = 'contentId';
export const DOCUMENT_ID_PATH_PARAM = 'docId';
export const CATEGORY_ID_PATH_PARAM = 'categoryId';
export const METRIC_HEALTH_MONITOR_ID_PATH_PARAM = 'metricHealthMonitorId';
export const MONITORED_METRIC_ID_PATH_PARAM = 'monitoredMetricId';
export const HEALTH_MONITOR_GROUP_ID_PATH_PARAM = 'metricSeriesGroupId';
export const METRIC_SERIES_ID_PATH_PARAM = 'metricSeriesId';
export const TABLE_ID_PATH_PARAM = 'tableId';
export const TABLE_EVENT_ID_PATH_PARAM = 'tableEventId';
export const TABLE_COLUMN_ID_PATH_PARAM = 'tableColumnId';
export const HOMEPAGE_ID_PATH_PARAM = 'homepageId';
export const TEAM_ID_PATH_PARAM = 'teamId';
export const SUBSCRIPTION_ID_PATH_PARAM = 'subscriptionId';
// other
export const ANALYSIS_PARAMETERS_KEY = 'parameters';
export const ANALYSIS_RUN_PARAMETERS_KEY = 'runParameters';

export type LocationDescriptorObject = Partial<Path>;

export const AppRoutes = {
  root: (): LocationDescriptorObject => ({
    pathname: `/`,
  }),
  signup: (): LocationDescriptorObject => ({
    pathname: `/sign-up`,
  }),
  homepage: (): LocationDescriptorObject => ({
    pathname: `/homepage`,
  }),
  settings: (): LocationDescriptorObject => ({
    pathname: `/settings`,
  }),
  metrics: (): LocationDescriptorObject => ({
    pathname: `/kpis`,
  }),
  funnelPage: (): LocationDescriptorObject => ({
    pathname: `/funnels`,
  }),
  viewFunnel: (
    id: PathParam = `:${FUNNEL_ID_PATH_PARAM}`,
    query?: any
  ): LocationDescriptorObject => ({
    pathname: `${AppRoutes.funnelPage().pathname}/${id}`,
    search: query ? AppRoutes.withQuery(query) : undefined,
  }),
  settingsNotifications: (): LocationDescriptorObject => ({
    pathname: `${AppRoutes.settings().pathname}/notifications`,
  }),
  settingsTeams: (): LocationDescriptorObject => ({
    pathname: `${AppRoutes.settings().pathname}/teams`,
  }),
  settingsMembers: (): LocationDescriptorObject => ({
    pathname: `${AppRoutes.settings().pathname}/members`,
  }),
  settingsPermissions: (): LocationDescriptorObject => ({
    pathname: `${AppRoutes.settings().pathname}/permissions`,
  }),
  settingsOther: (): LocationDescriptorObject => ({
    pathname: `${AppRoutes.settings().pathname}/other`,
  }),
  login: (): LocationDescriptorObject => ({
    pathname: `/login`,
  }),
  forgotPassword: (): LocationDescriptorObject => ({
    pathname: `/forgot-password`,
  }),
  resetPassword: (): LocationDescriptorObject => ({
    pathname: `/reset-password`,
  }),
  setPassword: (): LocationDescriptorObject => ({
    pathname: `/set-password`,
  }),
  signUp: (): LocationDescriptorObject => ({
    pathname: `/sign-up`,
  }),
  dmp: (): LocationDescriptorObject => ({
    pathname: `/dmp`,
  }),
  opportunities: (filters?: any): LocationDescriptorObject => ({
    pathname: `/opportunities`,
    search: AppRoutes.withFilters(filters),
  }),
  viewOpportunity: (
    id: PathParam = `:${OPPORTUNITY_ID_PATH_PARAM}`,
    query?: any
  ): LocationDescriptorObject => ({
    pathname: `${AppRoutes.opportunities().pathname}/${id}`,
    search: query ? AppRoutes.withQuery(query) : undefined,
  }),
  analyses: (filters?: any): LocationDescriptorObject => ({
    pathname: `/analyses`,
    search: AppRoutes.withFilters(filters),
  }),
  sharedAnalyses: (filters?: any): LocationDescriptorObject => ({
    pathname: `/analyses`,
    search: AppRoutes.withFilters(filters) + AppRoutes.withQuery({tab: 'shared_with_me'}),
  }),
  viewAnalysis: (
    id: PathParam = `:${ANALYSIS_ID_PATH_PARAM}`,
    query?: any
  ): LocationDescriptorObject => ({
    pathname: `${AppRoutes.analyses().pathname}/${id}`,
    search: query ? AppRoutes.withQuery(query) : undefined,
  }),
  createAnalysis: (): LocationDescriptorObject => ({
    pathname: `${AppRoutes.analyses().pathname}`,
    search: queryString.stringify(
      encodeQueryParams(
        {panel: JsonParam},
        {
          panel: {
            key: PanelKey.ANALYSIS_FORM_PANEL,
            parameters: {},
            type: PanelType.MODAL,
          },
        }
      )
    ),
  }),
  viewPhenomena: (id: PathParam = `:${PHENOMENA_ID_PATH_PARAM}`): LocationDescriptorObject => ({
    pathname: `${AppRoutes.analyses().pathname}/phenomenas/${id}`,
  }),
  experiments: (filters?: any): LocationDescriptorObject => ({
    pathname: `/experiments`,
    search: AppRoutes.withFilters(filters),
  }),
  viewExperiment: (
    id: PathParam = `:${EXPERIMENT_ID_PATH_PARAM}`,
    query?: any
  ): LocationDescriptorObject => ({
    pathname: `${AppRoutes.experiments().pathname}/${id}`,
    search: query ? AppRoutes.withQuery(query) : undefined,
  }),
  chatBot: (filters?: any): LocationDescriptorObject => ({
    pathname: `/ai`,
    search: AppRoutes.withFilters(filters),
  }),
  workflows: (query?: any): LocationDescriptorObject => ({
    pathname: `/workflows`,
    search: AppRoutes.withFilters(query), //+ AppRoutes.withQuery({tab}),
  }),
  viewWorkflow: (
    id: PathParam = `:${WORKFLOW_ID_PATH_PARAM}`,
    query?: any
  ): LocationDescriptorObject => ({
    pathname: `${AppRoutes.workflows().pathname}/${id}`,
    search: query ? AppRoutes.withQuery(query) : undefined,
  }),
  kpiTree: (query?: any): LocationDescriptorObject => ({
    pathname: `${AppRoutes.metrics().pathname}/tree`,
    search: query ? AppRoutes.withQuery(query) : undefined,
  }),
  viewMetric: (
    id: PathParam = `:${METRIC_ID_PATH_PARAM}`,
    query?: any
  ): LocationDescriptorObject => ({
    pathname: `${AppRoutes.metrics().pathname}/${id}`,
    search: query ? AppRoutes.withQuery(query) : undefined,
  }),
  features: (filters?: any): LocationDescriptorObject => ({
    pathname: `${AppRoutes.dmp().pathname}/features`,
    search: AppRoutes.withFilters(filters),
  }),
  contents: (filters?: any): LocationDescriptorObject => ({
    pathname: `${AppRoutes.dmp().pathname}/contents`,
    search: AppRoutes.withFilters(filters),
  }),
  views: (filters?: any): LocationDescriptorObject => ({
    pathname: `${AppRoutes.dmp().pathname}/views`,
    search: AppRoutes.withFilters(filters),
  }),
  tableEvents: (filters?: any): LocationDescriptorObject => ({
    pathname: `${AppRoutes.dmp().pathname}/table-events`,
    search: AppRoutes.withFilters(filters),
  }),
  funnels: (filters?: any): LocationDescriptorObject => ({
    pathname: `${AppRoutes.dmp().pathname}/funnels`,
    search: AppRoutes.withFilters(filters),
  }),
  segments: (filters?: any): LocationDescriptorObject => ({
    pathname: `${AppRoutes.dmp().pathname}/segments`,
    search: AppRoutes.withFilters(filters),
  }),
  kpis: (filters?: any): LocationDescriptorObject => ({
    pathname: `${AppRoutes.dmp().pathname}/kpis`,
    search: AppRoutes.withFilters(filters),
  }),
  eventGroups: (filters?: any): LocationDescriptorObject => ({
    pathname: `${AppRoutes.dmp().pathname}/event-groups`,
    search: AppRoutes.withFilters(filters),
  }),
  annotations: (filters?: any): LocationDescriptorObject => ({
    pathname: `${AppRoutes.dmp().pathname}/annotations`,
    search: AppRoutes.withFilters(filters),
  }),
  milestones: (filters?: any): LocationDescriptorObject => ({
    pathname: `${AppRoutes.dmp().pathname}/milestones`,
    search: AppRoutes.withFilters(filters),
  }),
  healthMonitor: (filters?: any): LocationDescriptorObject => ({
    pathname: `/health-monitor`,
    search: AppRoutes.withFilters(filters),
  }),
  viewMetricSeriesResult: (
    id: PathParam = `:${METRIC_SERIES_ID_PATH_PARAM}`,
    query?: any
  ): LocationDescriptorObject => ({
    pathname: `/health-monitor/series/${id}/result`,
    search: AppRoutes.withQuery(query),
  }),
  withFilters: (filters: any) =>
    queryString.stringify(encodeQueryParams({filters: JsonParam}, {filters})),
  withQuery: (params: any) => queryString.stringify(params),
  asUrl: (loc: LocationDescriptorObject) => `${loc.pathname}${loc.search ? `?${loc.search}` : ''}`,
};
