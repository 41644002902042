import {createContext, useContext, useMemo, useState} from 'react';

import {useProductData} from '../hooks/use-product-data.hook';
import {exists} from 'front-core';

export interface ITeamFilter {
  teamId?: number;
  teamName?: string;
}

export const TeamFilterContext = createContext<ITeamFilter>({
  teamId: undefined,
  teamName: undefined,
});

interface OwnProps {
  teamId?: number;
  children: any;
}

export const TeamFilterProvider = (props: OwnProps) => {
  const {teamId, children} = props;
  const {teams} = useProductData();

  const teamName = useMemo(() => teams.find(t => t.id === teamId)?.name, [teamId, teams]);
  const contextValue = useMemo(() => ({teamId, teamName}), [teamId, teamName]);

  return <TeamFilterContext.Provider value={contextValue}>{children}</TeamFilterContext.Provider>;
};

interface UseTeamFilterResponse extends ITeamFilter {
  isActive: boolean;
  ignore: () => void;
}

export const useTeamFilter = (): UseTeamFilterResponse => {
  const [ignoreTeamFilter, setIgnoreTeamFilter] = useState(false);

  const {teamId, teamName} = useContext(TeamFilterContext) || {};

  return {
    teamId,
    teamName,
    isActive: !ignoreTeamFilter && exists(teamId),
    ignore: () => setIgnoreTeamFilter(true),
  };
};
