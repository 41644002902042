import {useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';
import {
  deleteWorkflowConfirmed,
  renameWorkflow,
  setWorkflowOwner,
  updateWorkflowStatus,
  updateWorkflowTeam,
} from '../../../../../store/workflows/workflows.actions';
import {WorkflowStatus} from '../../../../../objects/models/workflow.model';
import {PanelsContext} from '../../../../../core/contexts/panels.context';
import {PanelKey} from '../../../../../constants/panels';
import {WORKFLOW_ID_PATH_PARAM} from '../../../../../constants/app-routes';

export const useWorkflowActions = () => {
  const dispatch = useDispatch();
  const {openPrimaryPanel} = useContext(PanelsContext);

  const onDelete = useCallback(
    (workflowId: number) => dispatch(deleteWorkflowConfirmed(workflowId)),
    [dispatch]
  );

  const onChangeOwner = useCallback(
    (ownerId: number, workflowId: number) =>
      dispatch(
        setWorkflowOwner({
          workflowId: workflowId,
          userId: ownerId,
        })
      ),
    [dispatch]
  );

  const setWorkflowTeam = useCallback(
    (workflowId: number, teamId: number) => dispatch(updateWorkflowTeam({workflowId, teamId})),
    [dispatch]
  );

  const setWorkflowStatus = useCallback(
    (workflowId: number, status: WorkflowStatus) =>
      dispatch(updateWorkflowStatus({workflowId, status})),
    [dispatch]
  );

  const onRename = useCallback(
    (workflowId: number, name: string) => dispatch(renameWorkflow({workflowId, name})),
    [dispatch]
  );

  const onDuplicate = useCallback(
    (workflowId: number) =>
      openPrimaryPanel(PanelKey.WORKFLOW_FORM_PANEL, {
        [WORKFLOW_ID_PATH_PARAM]: workflowId,
        cloneMode: true,
      }),
    [openPrimaryPanel]
  );

  const onEdit = useCallback(
    (workflowId: number) =>
      openPrimaryPanel(PanelKey.WORKFLOW_FORM_PANEL, {
        [WORKFLOW_ID_PATH_PARAM]: workflowId,
      }),
    [openPrimaryPanel]
  );

  return {
    onDelete,
    onChangeOwner,
    setWorkflowTeam,
    setWorkflowStatus,
    onRename,
    onDuplicate,
    onEdit,
  };
};
