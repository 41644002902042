import {TableEntity, TableEntityBinding} from '../../../../../../objects/models/table.model';
import {SelectorModelType} from '../../../../../shared/core/smart-selector/advanced-smart-selector.component';
import {
  ConversionKPIIcon,
  MonetizationKPIGroupIcon,
  QueryBuilderFactory,
  RetentionKPIIcon,
  SwitchActions,
  HabitMomentKPIDuotoneIcon,
} from 'ui-components';
import {ConversionQueryBuilder} from '../../../../../shared/core/query-builders/conversion-builder/conversion-query-builder.component';
import {RetentionQueryBuilder} from '../../../../../shared/core/query-builders/retention-builder/retention-query-builder.component';
import {PaymentRetentionQueryBuilder} from '../../../../../shared/core/query-builders/payment-retention-builder/payment-retention-query-builder.component';
import sharedClasses from '../../../analysis-forms.module.scss';
import classes from './goal-query-strategy-selector.module.scss';
import {ParameterInputWrapper} from '../../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {useCallback, useMemo} from 'react';
import {useProductData} from '../../../../../../core/hooks/use-product-data.hook';
import {useTranslation} from 'react-i18next';
import {Signal, SignalDataType, SignalType} from '../../../../../../objects/models/signal.model';
import {TemplateItemQueryBuilder} from '../../../../../shared/core/query-builders/template-item-query-builder/template-item-query-builder.component';
import {SignalSmartSelectorKey} from '../../../../../shared/core/smart-selector/signal-smart-selector.component';
import TransKeys from '../../../../../../constants/translation-keys';
import {AnalysisSelectorProps, AnalysisSelectorVariant} from '../analysis-selector.types';
import {
  createHabitMomentInitialQuery,
  HabitMomentQueryBuilder,
} from '../../../../../shared/core/query-builders/habit-moment-query-builder/habit-moment-query-builder.component';
import {BuilderWrapper} from '../../../../../shared/components/general/builder-wrapper/builder-wrapper.component';

const SHOW_PAYMENT_RETENTION_FOR_PRODUCTS = [10, 17, 21, 20, 19, 8, 4];

export enum SupportedTemplates {
  BOUNDED_ACTION_NU = 'bounded_action_nu',
  BOUNDED_ACTION_TS = 'bounded_action_ts',
  BOUNDED_ACTIONS_TS = 'bounded_actions_ts',
  MILESTONE_TS = 'milestone_ts',
}

export interface GoalStrategyPickerSchemaKeysMapping {
  goal_query: string;
  ui_goal_type: string;
}

export enum UIGoalType {
  CONVERSION = 'conversion',
  RETENTION = 'retention',
  PAYMENT_RETENTION = 'payment_retention',
  HABIT_MOMENT = 'habit_moment',
  EXISTING = 'existing',
}

export const GOAL_QUERY_STRATEGIES_MAP = {
  [UIGoalType.CONVERSION]: {
    value: 'conversion',
    label: TransKeys.ANALYSIS_FORMS.GOAL_QUERY_STRATEGY_PICKER.CONVERSION.LABEL,
    helperText: TransKeys.ANALYSIS_FORMS.GOAL_QUERY_STRATEGY_PICKER.CONVERSION.HELPER_TEXT,
    icon: ConversionKPIIcon,
  },
  [UIGoalType.RETENTION]: {
    value: 'retention',
    label: TransKeys.ANALYSIS_FORMS.GOAL_QUERY_STRATEGY_PICKER.RETENTION.LABEL,
    helperText: TransKeys.ANALYSIS_FORMS.GOAL_QUERY_STRATEGY_PICKER.RETENTION.HELPER_TEXT,
    icon: RetentionKPIIcon,
  },
  [UIGoalType.PAYMENT_RETENTION]: {
    value: 'payment_retention',
    label: TransKeys.ANALYSIS_FORMS.GOAL_QUERY_STRATEGY_PICKER.PAYMENT_RETENTION.LABEL,
    helperText: TransKeys.ANALYSIS_FORMS.GOAL_QUERY_STRATEGY_PICKER.PAYMENT_RETENTION.HELPER_TEXT,
    icon: MonetizationKPIGroupIcon,
  },
  [UIGoalType.HABIT_MOMENT]: {
    value: 'habit_moment',
    label: TransKeys.ANALYSIS_FORMS.GOAL_QUERY_STRATEGY_PICKER.HABIT_MOMENT.LABEL,
    helperText: TransKeys.ANALYSIS_FORMS.GOAL_QUERY_STRATEGY_PICKER.HABIT_MOMENT.HELPER_TEXT,
    icon: HabitMomentKPIDuotoneIcon,
  },
};

interface OwnProps extends AnalysisSelectorProps {
  enabledStrategies: UIGoalType[];
  schemaKeysMapping: GoalStrategyPickerSchemaKeysMapping;
  errors?: any;
  onSignalInfo?: (value: string | number) => void;
  entityContext?: TableEntity;
  className?: string;
  templates?: SupportedTemplates[];
}

type AllProps = OwnProps;

const DEFAULT_SUPPORTED_ANALYSES_TEMPLATES = [
  SupportedTemplates.BOUNDED_ACTION_TS,
  SupportedTemplates.BOUNDED_ACTIONS_TS,
  SupportedTemplates.BOUNDED_ACTION_NU,
  'subscription_retention_ts',
];

// Existing Goal Query related
const createSignalFiltersForExistingGoal = (
  entityContext: TableEntity,
  templates: SupportedTemplates[] = []
) => ({
  with_population_filter: true,
  type: SignalType.DIMENSION,
  entity_binding: TableEntityBinding.DEFAULT,
  templates: Array.from(new Set([...DEFAULT_SUPPORTED_ANALYSES_TEMPLATES, ...templates])),
  entityContext,
});

const createRefDateFilters = (entityContext: TableEntity) => [
  {
    type: SignalType.DIMENSION,
    data_type: SignalDataType.TIMESTAMP,
    entity_binding: TableEntityBinding.DEFAULT,
    exclude_templates: '*',
    entityContext,
  },
  {
    type: SignalType.DIMENSION,
    data_type: SignalDataType.TIMESTAMP,
    entity_binding: TableEntityBinding.DEFAULT,
    templates: ['dom_template'],
    entityContext,
  },
  {
    type: SignalType.MEASURE,
    data_type: SignalDataType.BOOLEAN,
    entity_binding: TableEntityBinding.DEFAULT,
    exclude_templates: '*',
    entityContext,
  },
];

const EXISTING_GOAL_ALLOWED_TYPES = [SelectorModelType.SIGNAL];

const EXISTING_GOAL_SIGNALS_INCLUDE = [
  SignalSmartSelectorKey.METRICS,
  SignalSmartSelectorKey.FUNNELS,
  SignalSmartSelectorKey.FEATURES,
  SignalSmartSelectorKey.CONTENTS,
  SignalSmartSelectorKey.SIGNALS,
];

export const createInitialGoalQueryByStrategy = (
  strategy: UIGoalType,
  getSignalByTag: (tag: string, entityContext: TableEntity) => Signal,
  entityContext: TableEntity
) => {
  const joinDateSignal = getSignalByTag('join_date', entityContext);
  const activeSignal = getSignalByTag('active', entityContext);
  let query = QueryBuilderFactory.createTemplate();

  switch (strategy) {
    case UIGoalType.CONVERSION:
    case UIGoalType.RETENTION: {
      query.parameters[1] = strategy === GOAL_QUERY_STRATEGIES_MAP.conversion.value ? 0 : 1;
      query.parameters[2] = 2;
      query.parameters[3] = 'week';

      if (strategy === UIGoalType.CONVERSION) {
        query.template = 'bounded_action_ts';
        query.parameters[0] = null;
        query.parameters[4] = QueryBuilderFactory.createSignalColumn(joinDateSignal?.id);
      }
      if (strategy === UIGoalType.RETENTION) {
        query.template = 'bounded_actions_ts';
        query.parameters[4] = QueryBuilderFactory.createSignalColumn(joinDateSignal?.id);
        query.parameters[0] = [QueryBuilderFactory.createSignalColumn(activeSignal?.id)];
      }
      return query;
    }
    case UIGoalType.HABIT_MOMENT: {
      query = createHabitMomentInitialQuery(getSignalByTag, entityContext);
      return query;
    }
    case UIGoalType.PAYMENT_RETENTION: {
      query.template = 'subscription_retention';
      query.parameters[0] = 'month';
      query.parameters[1] = 2;
      query.parameters[2] = '1m';
      return query;
    }
    case UIGoalType.EXISTING: {
      query = QueryBuilderFactory.createSignalColumn();
      return query;
    }
  }
};
// End of Existing Goal Query related
// Define inline Goal Query related

const REF_DATE_ALLOWED_TYPES = [
  SelectorModelType.SIGNAL,
  SelectorModelType.COLUMN,
  SelectorModelType.EVENT,
];
// End of Define inline Goal Query related

const GoalQueryStrategySelector = (props: AllProps) => {
  const {
    title,
    subTitle,
    helperText,
    errors,
    value,
    schemaKeysMapping,
    onChange,
    onSignalInfo,
    entityContext,
    className,
    enabledStrategies,
    variant,
    templates,
  } = props;
  const {t} = useTranslation();
  const {productId} = useProductData();
  const query = useMemo(() => value[schemaKeysMapping.goal_query], [value, schemaKeysMapping]);
  const goalType = useMemo(() => value[schemaKeysMapping.ui_goal_type], [value, schemaKeysMapping]);
  // New Goal Query related
  const refDateSignalFilters = useMemo(() => createRefDateFilters(entityContext), [entityContext]);
  // Existing Goal Query related
  const existingGoalFilters = useMemo(
    () => createSignalFiltersForExistingGoal(entityContext, templates),
    [entityContext, templates]
  );

  const builderSharedProps = useMemo(
    () => ({
      query,
      onChange: v => onChange({[schemaKeysMapping.goal_query]: v}),
      entityContext,
      errors: errors[schemaKeysMapping.goal_query],
      className,
    }),
    [query, schemaKeysMapping, onChange, entityContext, errors, className]
  );

  const onChangeStrategy = useCallback(
    (goalType: string) => {
      onChange({
        [schemaKeysMapping.ui_goal_type]: goalType,
        [schemaKeysMapping.goal_query]: undefined,
      });
    },
    [onChange, schemaKeysMapping]
  );

  const strategyOptions = useMemo(() => {
    const options = enabledStrategies.map(analysisStrategy => {
      const o = GOAL_QUERY_STRATEGIES_MAP[analysisStrategy];
      return {
        value: o.value,
        label: t(o.label),
        helperText: t(o.helperText),
        onClick: () => onChangeStrategy(o.value),
        isActive: goalType === o.value,
        icon: o.icon,
      };
    });
    if (SHOW_PAYMENT_RETENTION_FOR_PRODUCTS.indexOf(productId) > -1) {
      return options;
    }
    return options.filter(o => o.value !== 'payment_retention');
  }, [productId, t, onChangeStrategy, enabledStrategies, goalType]);

  // Renders
  const renderStrategyBuilder = () => {
    let builder = null;
    switch (goalType) {
      case UIGoalType.CONVERSION:
        builder = (
          <ConversionQueryBuilder
            {...builderSharedProps}
            onSignalInfo={onSignalInfo}
            refDateSignalFilters={refDateSignalFilters}
            refDateAllowTypes={REF_DATE_ALLOWED_TYPES}
          />
        );
        break;
      case UIGoalType.RETENTION:
        builder = (
          <RetentionQueryBuilder
            {...builderSharedProps}
            onSignalInfo={onSignalInfo}
            refDateSignalFilters={refDateSignalFilters}
            refDateAllowTypes={REF_DATE_ALLOWED_TYPES}
          />
        );
        break;
      case UIGoalType.HABIT_MOMENT:
        builder = <HabitMomentQueryBuilder {...builderSharedProps} onSignalInfo={onSignalInfo} />;
        break;
      case UIGoalType.PAYMENT_RETENTION:
        builder = <PaymentRetentionQueryBuilder {...builderSharedProps} />;
        break;
      case UIGoalType.EXISTING:
        builder = (
          <TemplateItemQueryBuilder
            {...builderSharedProps}
            signalFilters={existingGoalFilters}
            signalsInclude={EXISTING_GOAL_SIGNALS_INCLUDE}
            allowTypes={EXISTING_GOAL_ALLOWED_TYPES}
            onSignalInfo={onSignalInfo}
          />
        );
        break;
    }
    return <BuilderWrapper>{builder}</BuilderWrapper>;
  };

  const renderContent = () => (
    <>
      {goalType !== UIGoalType.EXISTING && (
        <SwitchActions showActionsLabel actions={strategyOptions} className={classes.GoalType} />
      )}
      {renderStrategyBuilder()}
      <div
        onClick={() =>
          onChangeStrategy(
            goalType === UIGoalType.EXISTING ? UIGoalType.CONVERSION : UIGoalType.EXISTING
          )
        }
        className={classes.SelectGoalQuerySwitcher}
      >
        {goalType === UIGoalType.EXISTING
          ? t(TransKeys.ANALYSIS_FORMS.GOAL_QUERY_STRATEGY_PICKER.MODE.DEFINE_INLINE.TOGGLE_LABEL)
          : t(TransKeys.ANALYSIS_FORMS.GOAL_QUERY_STRATEGY_PICKER.MODE.EXISTING.TOGGLE_LABEL)}
      </div>
    </>
  );

  if (variant === AnalysisSelectorVariant.INLINE) {
    return renderContent();
  }

  return (
    <ParameterInputWrapper
      title={title}
      subTitle={subTitle}
      helperText={helperText}
      className={sharedClasses.Parameter}
      error={Boolean(errors[schemaKeysMapping.goal_query])}
    >
      {renderContent()}
    </ParameterInputWrapper>
  );
};

export default GoalQueryStrategySelector;
