import {Epic} from 'redux-observable';
import {createRequestEpic, empty} from 'front-core';
import {ActionKey} from '../../constants/action-key';
import HttpClient from '../../services/http-client.service';
import {UserSettingsActionType} from './user-settings.actions';
import {userSettingToastCreator} from '../toasts.actions';
import {updateUserSettingsNetworkRequest} from '../../http/user-settings.network-requests';
import {AmplitudeEvent} from '../../constants/amplitude-event';
import {getProductData, modelUpdated, notifyEvent} from '../core/core.actions';
import {demoProductLimitedActionsFilter} from '../store.utils';
import {ModelKey} from '../../constants/model-key';

export const updateUserSettingEpic: Epic = createRequestEpic(
  {
    types: [UserSettingsActionType.UPDATE_USER_SETTINGS],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.UPDATE_USER_SETTINGS,
    request: updateUserSettingsNetworkRequest,
    onSuccess: (data, payload) => [
      getProductData(),
      ...createEvents(payload),
      modelUpdated(data, ModelKey.USER_SETTINGS),
      userSettingToastCreator('UPDATE_SUCCESS'),
    ],
    onError: () => [userSettingToastCreator('UPDATE_ERROR')],
  },
  HttpClient
);

function createEvents(changes) {
  return Object.keys(changes).map(key => {
    switch (key) {
      case 'receiveEmailNotifications':
        const event = changes[key]
          ? AmplitudeEvent.USER_SETTINGS_EMAIL_NOTIFICATIONS_ON
          : AmplitudeEvent.USER_SETTINGS_EMAIL_NOTIFICATIONS_OFF;
        return notifyEvent(event, {});
      default:
        return empty();
    }
  });
}
