import {
  useRemoteSourceStated,
  SmartTreeSelector,
  createTreeNodesInfo,
  TreeSelectorValue,
} from 'ui-components';
import {useEffect, useMemo, useState} from 'react';
import {exists} from 'front-core';
import {confoundersSmartSelectorNetworkRequest} from '../../../../http/smart-selector.network-requests';
import {SmartSelectorLayout} from './smart-selector-layout.component';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {flatten, merge, some, sum, values} from 'lodash';
import classes from './smart-selector.module.scss';
import {TableEntity} from '../../../../objects/models/table.model';

interface OwnProps {
  placeholder: string;
  value: TreeSelectorValue;
  onChange: (value: TreeSelectorValue) => void;
  selectedText?: string;
  className?: string;
  disabled?: boolean;
  clearable?: boolean;
  entityContext: TableEntity;
  signalFilters?: any;
  error?: boolean;
}

type AllProps = OwnProps;

function hasSelectedValue(value: TreeSelectorValue, info: any) {
  const allValues = new Set(flatten(values(info).map(i => [i.value, ...i.childrenValues])));
  return some((value as any[]).map(v => allValues.has(v)));
}

export const ConfoundersSmartSelector = (props: AllProps) => {
  const {
    placeholder,
    value,
    onChange,
    entityContext,
    signalFilters,
    selectedText = 'selected',
    disabled,
    clearable,
    error,
    className,
  } = props;
  const [count, setCount] = useState(undefined);
  const {t} = useTranslation();
  const {source: options, exec} = useRemoteSourceStated({
    type: 'source',
    networkRequest: confoundersSmartSelectorNetworkRequest,
    initialValue: null,
  });
  const label = useMemo(
    () => (count === undefined || count === 0 ? undefined : `${count} ${selectedText}`),
    [count, selectedText]
  );
  const groups = useMemo(() => {
    if (!exists(options)) {
      return [];
    }
    const groupOptions = [];
    if (options['features'] && options['features'].length > 0) {
      const info: any = createTreeNodesInfo(options['features']);
      groupOptions.push({
        key: 'features',
        name: t(TransKeys.CONFOUNDERS_SELECTOR.FEATURES.LABEL),
        helperText: t(TransKeys.CONFOUNDERS_SELECTOR.FEATURES.HELPER_TEXT),
        data: options['features'],
        tab: t(TransKeys.CONFOUNDERS_SELECTOR.TABS.VERIFIED_CONFOUNDERS),
        // internal use
        info,
      });
    }
    if (options['contents'] && options['contents'].length > 0) {
      const info = createTreeNodesInfo(options['contents']);
      groupOptions.push({
        key: 'contents',
        name: t(TransKeys.CONFOUNDERS_SELECTOR.CONTENTS.LABEL),
        helperText: t(TransKeys.CONFOUNDERS_SELECTOR.CONTENTS.HELPER_TEXT),
        data: options['contents'],
        startOpen: hasSelectedValue(value, info),
        tab: t(TransKeys.CONFOUNDERS_SELECTOR.TABS.OTHER),
        // internal use
        info,
      });
    }
    if (options['confounderSegments'] && options['confounderSegments'].length > 0) {
      const info = createTreeNodesInfo(options['confounderSegments']);
      groupOptions.push({
        key: 'confounderSegments',
        name: t(TransKeys.CONFOUNDERS_SELECTOR.CONFOUNDER_SEGMENTS.LABEL),
        helperText: t(TransKeys.CONFOUNDERS_SELECTOR.CONFOUNDER_SEGMENTS.HELPER_TEXT),
        data: options['confounderSegments'],
        startOpen: hasSelectedValue(value, info),
        tab: t(TransKeys.CONFOUNDERS_SELECTOR.TABS.VERIFIED_CONFOUNDERS),
        // internal use
        info,
      });
    }
    if (options['segments'] && options['segments'].length > 0) {
      const info = createTreeNodesInfo(options['segments']);
      groupOptions.push({
        key: 'segments',
        name: t(TransKeys.CONFOUNDERS_SELECTOR.SEGMENTS.LABEL),
        helperText: t(TransKeys.CONFOUNDERS_SELECTOR.SEGMENTS.HELPER_TEXT),
        data: options['segments'],
        startOpen: hasSelectedValue(value, info),
        tab: t(TransKeys.CONFOUNDERS_SELECTOR.TABS.OTHER),
        // internal use
        info,
      });
    }
    if (options['eventGroups'] && options['eventGroups'].length > 0) {
      const info = createTreeNodesInfo(options['eventGroups']);
      groupOptions.push({
        key: 'eventGroups',
        name: t(TransKeys.CONFOUNDERS_SELECTOR.EVENT_GROUPS.LABEL),
        helperText: t(TransKeys.CONFOUNDERS_SELECTOR.EVENT_GROUPS.HELPER_TEXT),
        data: options['eventGroups'],
        startOpen: hasSelectedValue(value, info),
        tab: t(TransKeys.CONFOUNDERS_SELECTOR.TABS.OTHER),
        // internal use
        info,
      });
    }
    if (options['other'] && options['other'].length > 0) {
      const info = createTreeNodesInfo(options['other']);
      groupOptions.push({
        key: 'other',
        name: t(TransKeys.CONFOUNDERS_SELECTOR.OTHER.LABEL),
        helperText: t(TransKeys.CONFOUNDERS_SELECTOR.OTHER.HELPER_TEXT),
        data: options['other'],
        startOpen: hasSelectedValue(value, info),
        tab: t(TransKeys.CONFOUNDERS_SELECTOR.TABS.OTHER),
        // internal use
        info,
      });
    }
    return groupOptions;
  }, [options, t, value]);
  useEffect(() => {
    exec({entityContext, signalFilters});
  }, [exec, entityContext, signalFilters]);
  useEffect(() => {
    if (!groups) {
      return;
    }
    // @ts-ignore
    const allInfo = merge(...values(groups.map(g => g.info)));
    return setCount(
      sum((value as TreeSelectorValue[]).map(v => allInfo[v as any]?.leafsValues?.length))
    );
  }, [groups, value]);

  return (
    <SmartSelectorLayout
      id={'confounders-selector'}
      label={label}
      disabled={disabled}
      error={error}
      clearable={clearable && exists(value)}
      onClear={() => onChange(null)}
      placeholder={placeholder}
      className={className}
      withPreview={false}
    >
      <SmartTreeSelector
        className={classes.Selector}
        value={value}
        onChange={(value, originalValue: any) => {
          onChange(value);
          setCount(originalValue.length);
        }}
        groups={groups}
        selectParentValue={true}
        multi={true}
      />
    </SmartSelectorLayout>
  );
};
