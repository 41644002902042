import {yupResolver} from '@hookform/resolvers/yup';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {Textarea} from 'ui-components';
import {Footer} from '../../footer/footer.component';
import classes from './generic-connection-details-form.module.scss';
import {isEmpty} from 'lodash';
import {SourceWizardFormLayout} from '../../source-wizard-form-layout/source-wizard-form-layout.component';
import {SourceWizardLabelWrapper} from '../../source-wizard-label-wrapper/source-wizard-label-wrapper.component';
import {
  genericConnectionDetailsFormSchema,
  GenericConnectionDetailsFormSchema,
} from '../../../../../../../objects/dto/source.dto';

export type GenericConnectionDetailsFormProps = {
  onSubmit?: (values: GenericConnectionDetailsFormSchema) => void;
};

export const GenericConnectionDetailsForm = (props: GenericConnectionDetailsFormProps) => {
  const {onSubmit} = props;
  const formMethods = useForm({
    resolver: yupResolver(genericConnectionDetailsFormSchema),
  });
  const {
    handleSubmit,
    formState: {errors, isValid},
    control,
  } = formMethods;

  return (
    <SourceWizardFormLayout
      footer={
        <Footer
          buttonText="Continue"
          onClickSubmit={handleSubmit(onSubmit)}
          disabledCTAButton={!isEmpty(errors) || !isValid}
        />
      }
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.GenericConnectionDetailsForm}>
          <SourceWizardLabelWrapper label="Connection Details">
            <Controller
              render={({field, fieldState: {error}}) => (
                <Textarea
                  fullWidth
                  rows={5}
                  placeholder={'Write your connection details (username, password)'}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(error)}
                  required
                />
              )}
              name={'connectionDetails'}
              control={control}
            />
          </SourceWizardLabelWrapper>
        </form>
      </FormProvider>
    </SourceWizardFormLayout>
  );
};
