import {createRequestEpic} from 'front-core';
import {Epic} from 'redux-observable';
import {AmplitudeEvent} from '../../constants/amplitude-event';
import {ActionKey} from '../../constants/action-key';
import {ModelKey} from '../../constants/model-key';
import {createSourceNetworkRequest} from '../../http/sources.network-requests';
import HttpClient from '../../services/http-client.service';
import {modelCreated, notifyEvent} from '../core/core.actions';
import {sourceToastCreator} from '../toasts.actions';
import {SourcesActionType} from './sources.actions';

export const createSourceEpic: Epic = createRequestEpic(
  {
    types: [SourcesActionType.CREATE_SOURCE],
    actionKey: ActionKey.CREATE_SOURCE,
    request: createSourceNetworkRequest,
    onSuccess: source => [
      notifyEvent(AmplitudeEvent.SOURCE_CREATED),
      sourceToastCreator('CREATE_SUCCESS'),
      modelCreated(source, ModelKey.SOURCE),
    ],
    onError: () => [sourceToastCreator('CREATE_ERROR')],
  },
  HttpClient
);
