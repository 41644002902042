import {useCallback, useContext, useEffect, useMemo} from 'react';
import {
  Button,
  FancyHeader,
  Link,
  ModalLayout,
  TableIcon,
  TriangleExclamationLightIcon,
} from 'ui-components';
import {TABLE_ID_PATH_PARAM} from '../../../../constants/app-routes';
import classes from './table-form-panel.module.scss';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../constants/translation-keys';
import {FormProvider, useForm} from 'react-hook-form';
import {
  GroupByTimeStrategy,
  Table,
  TableDefinitionType,
  TableEntity,
  TableType,
} from '../../../../objects/models/table.model';
import {tableDTOValidator} from '../../../../objects/dto/table.dto';
import {yupResolver} from '@hookform/resolvers/yup';
import {useDispatch, useSelector} from 'react-redux';
import {
  getSingleErrorSelector,
  getReducedLoadingStateSelector,
} from '../../../../store/store.selectors';
import {sharedClasses} from '../../../shared';
import {getTableNetworkRequest} from '../../../../http/tables.network-requests';
import {ActionKey} from '../../../../constants/action-key';
import {composition, withMetadata, removeError, ApplicationError} from 'front-core';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {
  TextareaFormInput,
  TextFormInput,
} from '../../../shared/form/components/shared-form-input.component';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {contactSupport, preventSubmitOnEnter} from '../../../../utils/general.utils';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {
  OptionTab,
  OptionTabs,
} from '../../../shared/components/general/options-tabs/options-tabs.component';
import i18 from '../../../../config/i18n.config';
import {createTable, updateTable} from '../../../../store/tables/tables.actions';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import TableTab from './table-form-panel-tabs/table-tab.component';
import {TabHeader} from '../../../shared/components/general/tab-header/tab-header.component';
import {triggerSourceTableDiscovery} from '../../../../store/source-tables/source-tables.actions';
import {AppSources, SOURCE_META_KEY} from '../../../../constants/app-sources';
import QueryTab from './table-form-panel-tabs/query-tab-component';
import {ConfirmationDialogContext} from '../../../../core/contexts/confirmation-dialog.context';
import {useCurrentUser} from '../../../../core/hooks/use-user.hook';
import {withModalInactiveSourceHandler} from '../../../../core/hoc/with-modal-inactive-source-handler.hoc';
import {useDemoProduct} from '../../../../core/hooks/use-demo-product.hook';
import {withDisableDemoProduct} from '../../../../core/hoc/with-disable-demo-product.hoc';

interface OwnProps {
  table?: Table;
  onClose: () => void;
  cloneMode: boolean;
  disabled?: boolean;
  [TABLE_ID_PATH_PARAM]?: number;
}

type AllProps = OwnProps;

const TABS_HEADER_TITLES = () => ({
  [TableDefinitionType.TABLE]: i18.t(TransKeys.TABLE_FORM.TABS.TITLES.TABLE),
  [TableDefinitionType.QUERY]: i18.t(TransKeys.TABLE_FORM.TABS.TITLES.QUERY),
});
export const AGG_TO_TEXT = {
  [GroupByTimeStrategy.DAY]: 'daily',
  [GroupByTimeStrategy.WEEK]: 'weekly',
  [GroupByTimeStrategy.MONTH]: 'monthly',
};

const SELECTED_TABLE_KEY = SharedSelectionKeys.TABLE_FORM__TABLE;

const TableFormPanelComponent = (props: AllProps) => {
  const {table = {} as any, cloneMode, onClose, disabled} = props;
  const dispatch = useDispatch();
  const notify = useAmplitude();
  const {t} = useTranslation();
  const {defaultSource, defaultTableEntity, tableEntities, getEntityName} = useProductData();
  const {demoProductValidator} = useDemoProduct();
  const {setConfirmationDialog} = useContext(ConfirmationDialogContext);
  const user = useCurrentUser();
  const overrideData = useMemo(
    () =>
      cloneMode
        ? {
            id: undefined,
            definition: {...(table?.definition || {}), name: undefined},
          }
        : {},
    [cloneMode, table?.definition]
  );
  const formMethods = useForm({
    criteriaMode: 'all',
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      definition: {
        type: TableDefinitionType.TABLE,
      },
      entity: defaultTableEntity,
      sourceId: defaultSource?.id,
      type: TableType.ENTITY_PROPERTIES,
      ...table,
      ...overrideData,
    } as any,
    resolver: yupResolver(demoProductValidator || tableDTOValidator),
  });
  const {
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: {isSubmitted, isSubmitting, isValidating},
  } = formMethods;
  const id = watch('id');
  const editMode = Boolean(id);
  const entity = watch('entity');
  const parameters = watch('parameters');
  const definition = watch('definition');
  const uiMetadata = watch('uiMetadata');
  const tableDefinitionType = watch('definition.type');
  const submitError = useSelector<ApplicationError>(state =>
    getSingleErrorSelector(editMode ? ActionKey.UPDATE_TABLE : ActionKey.CREATE_TABLE, state)
  );
  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(ActionKey.CREATE_TABLE, ActionKey.UPDATE_TABLE)(state)
  );
  const isDisabled = useMemo(
    () => isLoading || isSubmitting || isValidating || table.isComputed || disabled,
    [isLoading, isSubmitting, isValidating, table, disabled]
  );
  const onSourceTableDiscovery = useCallback(() => {
    dispatch(
      withMetadata(triggerSourceTableDiscovery(), {
        [SOURCE_META_KEY]: AppSources.TABLE_FORM_PANEL,
      })
    );
  }, [dispatch]);
  const resetValidationResult = useCallback(
    () =>
      submitError &&
      dispatch(removeError(editMode ? ActionKey.UPDATE_TABLE : ActionKey.CREATE_TABLE)),
    [dispatch, editMode, submitError]
  );
  const setDefinition = useCallback(
    newDefinition => {
      setValue('definition', {
        ...definition,
        ...newDefinition,
      });
      isSubmitted && trigger('definition');
    },
    [setValue, definition, trigger, isSubmitted]
  );
  const setParameters = useCallback(
    newParameters => {
      setValue('parameters', {
        ...parameters,
        ...newParameters,
      });
      isSubmitted && trigger('parameters');
    },
    [setValue, parameters, trigger, isSubmitted]
  );
  const resetParameters = useCallback(() => {
    setParameters({
      entity_id: undefined,
      event_name: undefined,
      timestamp: undefined,
      start_at: undefined,
      group_by_time_strategy: undefined,
      is_main: undefined,
    });
  }, [setParameters]);
  const setMeta = useCallback(
    newMeta => {
      setValue('uiMetadata', {
        ...uiMetadata,
        ...newMeta,
      });
    },
    [setValue, uiMetadata]
  );
  const resetMeta = useCallback(() => {
    setMeta({
      sourceTableId: undefined,
      sourceTableType: undefined,
      sourceTableColumnEntityId: undefined,
      sourceTableColumnEventNameId: undefined,
      sourceTableColumnEventTimestampId: undefined,
      sourceTableColumnStartAtId: undefined,
    });
  }, [setMeta]);

  const setSelectedTab = useCallback(
    tab => {
      if (isDisabled) {
        return;
      }

      if (!defaultSource?.rawSqlEnabled) {
        return setDefinition({
          type: tab as TableDefinitionType,
        });
      }
      resetValidationResult();
      resetParameters();
      resetMeta();
      setDefinition({
        type: tab as TableDefinitionType,
        name: undefined,
        prefix: undefined,
        query: undefined,
      });
    },
    [isDisabled, resetParameters, resetMeta, defaultSource, setDefinition, resetValidationResult]
  );

  const confirmMainTableOverride = useCallback(
    (value: boolean, entity: TableEntity, onConfirm: () => void) => {
      const currentMainTable = entity && tableEntities?.[entity];
      const isReplacingMainTable = value && currentMainTable && currentMainTable?.id !== id;

      if (isReplacingMainTable) {
        setConfirmationDialog({
          title: t(TransKeys.CONFIRMATIONS.SET_MAIN_ENTITY_TABLE.TITLE),
          content: t(TransKeys.CONFIRMATIONS.SET_MAIN_ENTITY_TABLE.CONTENT, {
            currentMainTable: currentMainTable?.name,
            entity: getEntityName(entity),
          }),
          onApprove: onConfirm,
        });
      } else {
        onConfirm();
      }
    },
    [t, id, tableEntities, getEntityName, setConfirmationDialog]
  );
  const setEntity = useCallback(
    (entity: TableEntity) => {
      confirmMainTableOverride(parameters?.is_main, entity, () => {
        setValue('entity', entity);
        trigger('entity');
      });
    },
    [setValue, trigger, parameters, confirmMainTableOverride]
  );
  const setIsMain = useCallback(
    value => {
      confirmMainTableOverride(value, entity, () => {
        setParameters({
          is_main: value,
        });
      });
    },
    [setParameters, confirmMainTableOverride, entity]
  );

  const TABS_HEADER_TITLES_ = useMemo(() => TABS_HEADER_TITLES(), []);

  const tableDefinitionTabs: OptionTab[] = useMemo(
    () => [
      {
        key: TableDefinitionType.TABLE,
        title: TABS_HEADER_TITLES_[TableDefinitionType.TABLE],
        render: () => (
          <TableTab
            setEntity={setEntity}
            setIsMain={setIsMain}
            setMeta={setMeta}
            setDefinition={setDefinition}
            setParameters={setParameters}
            resetParameters={resetParameters}
            resetMeta={resetMeta}
            onSourceTableDiscovery={onSourceTableDiscovery}
            submitError={submitError as ApplicationError}
            editMode={editMode}
          />
        ),
      },
      {
        key: TableDefinitionType.QUERY,
        title: TABS_HEADER_TITLES_[TableDefinitionType.QUERY],
        render: () => {
          if (defaultSource?.rawSqlEnabled) {
            return (
              <QueryTab
                setEntity={setEntity}
                setIsMain={setIsMain}
                submitError={submitError as ApplicationError}
                resetParameters={resetParameters}
                disabled={isDisabled}
              />
            );
          }

          return (
            <div className={classes.ComingSoon}>
              <TabHeader title={'SQL query is coming soon!'} size={'small'} />
              <div className={classes.Content}>
                We're working on it! In the meanwhile, contact
                <Link onClick={() => contactSupport()} className={classes.Contact}>
                  support
                </Link>
                to define a view based on SQL query.
              </div>
            </div>
          );
        },
      },
    ],
    [
      resetParameters,
      resetMeta,
      setEntity,
      setIsMain,
      setDefinition,
      setParameters,
      setMeta,
      onSourceTableDiscovery,
      defaultSource,
      submitError,
      editMode,
      TABS_HEADER_TITLES_,
      isDisabled,
    ]
  );
  const onSubmit = useCallback(
    data => {
      const action = editMode ? updateTable : createTable;
      dispatch(action(data, onClose));
      notify(AmplitudeEvent.TABLE_SAVE_CLICKED, {
        userId: user.id,
      });
    },
    [onClose, editMode, dispatch, notify, user]
  );

  useEffect(() => {
    return () => {
      dispatch(removeError(editMode ? ActionKey.UPDATE_TABLE : ActionKey.CREATE_TABLE));
    };
  }, [dispatch, editMode]);

  return (
    <div className={classes.TableContainer}>
      <ModalLayout
        footer={
          <Button disabled={isDisabled} onClick={handleSubmit(onSubmit)}>
            {t(TransKeys.GENERAL.ACTIONS[isLoading ? 'VALIDATING' : 'SAVE'])}
          </Button>
        }
      >
        {isLoading && <GenericLoading />}
        <div className={classes.CreateTable}>
          <FancyHeader
            icon={TableIcon}
            title={t(TransKeys.TABLES.HEADER.TITLE)}
            subTitle={t(TransKeys.TABLE_FORM.MAIN_TITLE)}
            onClose={onClose}
            className={classes.CreateTableHeader}
          />
          <FormProvider {...formMethods}>
            {table.isComputed && (
              <div className={classes.ComputedTableMessageContainer}>
                <div className={classes.ComputedTableMessage}>
                  <TriangleExclamationLightIcon />
                  <span>{t(TransKeys.TABLE_FORM.COMPUTED_TABLE_MESSAGE)}</span>
                </div>
              </div>
            )}
            <form
              className={sharedClasses.Form}
              onKeyDown={preventSubmitOnEnter}
              onSubmit={e => {
                e.stopPropagation();
                handleSubmit(onSubmit)(e);
              }}
            >
              <div className={sharedClasses.FormContent}>
                <div className={sharedClasses.Block}>
                  <div className={sharedClasses.Input}>
                    <TextFormInput
                      label={t(TransKeys.TABLE_FORM.INPUTS.NAME.LABEL)}
                      placeholder={t(TransKeys.TABLE_FORM.INPUTS.NAME.PLACEHOLDER)}
                      name={'name'}
                      required
                    />
                  </div>
                </div>
                <div className={sharedClasses.Block}>
                  <div className={sharedClasses.Input}>
                    <TextareaFormInput
                      label={t(TransKeys.TABLE_FORM.INPUTS.SHORT_DESCRIPTION.LABEL)}
                      placeholder={t(TransKeys.TABLE_FORM.INPUTS.SHORT_DESCRIPTION.PLACEHOLDER)}
                      name={'shortDescription'}
                    />
                  </div>
                </div>
                <div className={sharedClasses.Block} />
                <div className={sharedClasses.Block}>
                  <OptionTabs
                    tabs={tableDefinitionTabs}
                    selectedKey={tableDefinitionType}
                    onTabChange={setSelectedTab}
                  />
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </ModalLayout>
    </div>
  );
};

const TableFormPanel = composition<AllProps>(
  TableFormPanelComponent,
  withModalInactiveSourceHandler,
  withDisableDemoProduct,
  withLoadBefore({
    table: {
      selectedKey: SELECTED_TABLE_KEY,
      actionKey: SELECTED_TABLE_KEY,
      request: getTableNetworkRequest,
      mapPayloadFromProps: props => props[TABLE_ID_PATH_PARAM],
      shouldCall: props => props[TABLE_ID_PATH_PARAM] !== undefined,
    },
  })
);

export {TableFormPanel};
