import classes from './rca-insight-section.module.scss';
import {RCAInsightSection, RCAInsightSentence, RCAInsightsSectionKeys} from '../../rca/objects';
import {RCA_INSIGHTS_SENTENCE_FORMATTERS_MAPPING} from '../../rca/mapping';

interface OwnProps extends RCAInsightSection {
  sectionKey: RCAInsightsSectionKeys;
}

type AllProps = OwnProps;

export const RCAInsightSectionComponent = (props: AllProps) => {
  const {sentences, sectionKey} = props;

  const renderSentence = (sentence: RCAInsightSentence) => {
    const Formatter = RCA_INSIGHTS_SENTENCE_FORMATTERS_MAPPING[sentence.key];
    if (!Formatter) {
      return null;
    }
    return <Formatter {...(sentence as any)} />;
  };
  if (!sentences || sentences.length === 0) {
    return null;
  }

  return (
    <div className={classes.SectionContainer}>
      <div className={classes.SectionContentList}>
        {sentences.map((sentence, idx) => (
          <>
            <span className={classes.Bullet} />
            <div
              key={`${sectionKey.toLowerCase()}_${sentence.key}_${idx}`}
              className={classes.SentenceContainer}
            >
              {renderSentence(sentence)}
            </div>
          </>
        ))}
      </div>
    </div>
  );
};
