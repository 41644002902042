import classes from './homepage-annotations-panel.module.scss';
import {getAnnotationsNetworkRequest} from '../../../../http/annotations.network-requests';
import {Annotation, AnnotationType} from '../../../../objects/models/annotation.model';
import {useCallback, useContext, useEffect, useMemo} from 'react';
import {
  AnalysisFileIcon,
  AnnotationIcon,
  Button,
  EditIcon,
  FancyHeader,
  FileCirclePlusLightIcon,
  IconButton,
  ModalLayout,
  MoreIcon,
  TrashIcon,
  useRemoteSourceStated,
} from 'ui-components';
import TransKeys from '../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {TIME_FORMATS} from '../../../../constants/time-formats';
import moment from 'moment';
import {ANNOTATION_ICONS} from '../../../../constants/ui';
import {deleteAnnotationConfirmed} from '../../../../store/annotations/annotations.actions';
import {PanelKey} from '../../../../constants/panels';
import {
  ANALYSIS_TYPE_ID_PATH_PARAM,
  ANNOTATION_ID_PATH_PARAM,
  AppRoutes,
} from '../../../../constants/app-routes';
import {useDispatch} from 'react-redux';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {AnalysisTypeId} from '../../../../constants/analysis-type-id';
import {useNavigate} from 'react-router';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {CoreActionsType} from '../../../../store/core/core.actions';
import {ModelKey} from '../../../../constants/model-key';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../store/actions-listener/actions-listener.actions';
import classNames from 'classnames';
import {composition, withStopPropagation} from 'front-core';
import {withModalInactiveSourceHandler} from '../../../../core/hoc/with-modal-inactive-source-handler.hoc';
import {ModelActionsDropdown} from '../../../shared/core/model-actions-dropdown/model-actions-dropdown.component';
import {withDisableDemoProduct} from '../../../../core/hoc/with-disable-demo-product.hoc';

interface OwnProps {
  date: string;
  onClose?: () => void;
  disabled?: boolean;
}

type AllProps = OwnProps;

const DEFAULT_FILTERS = {
  limit: 1000,
  order: 'desc',
  orderBy: 'createdOn',
};

const ACTIONS = [
  CoreActionsType.MODEL_UPDATED,
  CoreActionsType.MODEL_CREATED,
  CoreActionsType.MODEL_DELETED,
];
const MODELS = [ModelKey.ANNOTATION, ModelKey.EXPERIMENT];

const HomepageAnnotationsPanelComponent = (props: AllProps) => {
  const {date, onClose, disabled} = props;
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {openSecondaryPanel} = useContext(PanelsContext);

  const {
    source: annotations,
    exec: getAnnotations,
    isLoading: isLoadingAnnotations,
  } = useRemoteSourceStated({
    networkRequest: getAnnotationsNetworkRequest,
    initialValue: [],
    transformer: res => res.data,
  });

  const dateString = useMemo(
    () => moment.utc(date, TIME_FORMATS.PARAMETER_DATE_FORMAT).format(TIME_FORMATS.READABLE_DATE),
    [date]
  );

  const onDelete = useCallback(
    annotation => dispatch(deleteAnnotationConfirmed(annotation.id)),
    [dispatch]
  );
  const onEdit = useCallback(
    annotation =>
      openSecondaryPanel(PanelKey.ANNOTATION_FORM_PANEL, {
        [ANNOTATION_ID_PATH_PARAM]: annotation.id,
      }),
    [openSecondaryPanel]
  );
  const onViewExperiment = useCallback(
    (annotation: Annotation) => {
      navigate(AppRoutes.viewExperiment(annotation.relatedExperimentId));
      onClose();
    },
    [navigate, onClose]
  );
  const onCreateExperiment = useCallback(
    (annotation: Annotation) =>
      openSecondaryPanel(PanelKey.EXPERIMENT_FORM_PANEL, {
        [ANNOTATION_ID_PATH_PARAM]: annotation.id,
        [ANALYSIS_TYPE_ID_PATH_PARAM]: AnalysisTypeId.RELEASE_IMPACT,
        parameters: {
          release_date: moment(annotation.timestamp, TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT).format(
            TIME_FORMATS.PARAMETER_DATE_FORMAT
          ),
        },
      }),
    [openSecondaryPanel]
  );

  useEffect(() => {
    getAnnotations({
      ...DEFAULT_FILTERS,
      date,
    });
  }, [getAnnotations, date]);

  useEffect(() => {
    const listener = async action => {
      const {modelKey} = action.payload;
      if (MODELS.indexOf(modelKey) === -1) {
        return;
      }
      getAnnotations({
        ...DEFAULT_FILTERS,
        date,
      });
    };
    dispatch(registerActionListener(ACTIONS, listener));
    return () => {
      dispatch(removeActionListener(ACTIONS, listener));
    };
  }, [dispatch, getAnnotations, date]);

  const renderAnnotationRow = (annotation: Annotation) => {
    const Icon = ANNOTATION_ICONS[annotation.type];
    return (
      <div
        key={annotation.id}
        onClick={annotation.relatedExperimentId ? () => onViewExperiment(annotation) : undefined}
        className={classNames(
          classes.Annotation,
          annotation.relatedExperimentId && classes.HasAnalysis
        )}
      >
        <div className={classes.IconWrapper}>
          <Icon className={classes.Icon} />
        </div>
        <div className={classes.Type}>
          {t(TransKeys.ANNOTATION.TYPE[annotation.type.toUpperCase()])}
        </div>
        <div className={classes.Title}>{annotation.title}</div>
        <div className={classes.Actions}>
          {annotation.type === AnnotationType.RELEASE && (
            <IconButton
              onClick={
                annotation.relatedExperimentId
                  ? withStopPropagation(() => onViewExperiment(annotation))
                  : withStopPropagation(() => onCreateExperiment(annotation))
              }
              icon={annotation.relatedExperimentId ? AnalysisFileIcon : FileCirclePlusLightIcon}
              tooltipText={
                annotation.relatedExperimentId
                  ? t(TransKeys.ANNOTATIONS.ACTIONS.VIEW_RELEASE_IMPACT)
                  : t(TransKeys.ANNOTATIONS.ACTIONS.RUN_RELEASE_IMPACT)
              }
              className={annotation.relatedExperimentId ? undefined : classes.NoAnalysis}
              iconClassName={annotation.relatedExperimentId ? undefined : classes.NoAnalysisIcon}
            />
          )}
          <ModelActionsDropdown
            className={classes.Button}
            actions={[
              {
                key: 'edit',
                title: t(TransKeys.GENERAL.ACTIONS.EDIT),
                onClick: withStopPropagation(() => onEdit(annotation)),
                icon: EditIcon,
              },
              {
                key: 'delete',
                title: t(TransKeys.GENERAL.ACTIONS.DELETE),
                onClick: withStopPropagation(() => onDelete(annotation)),
                icon: TrashIcon,
              },
            ]}
            label={t(TransKeys.GENERAL.LABELS.MORE_DOTS)}
            icon={MoreIcon}
            iconDropdown
          />
        </div>
      </div>
    );
  };

  return (
    <div className={classes.HomepageAnnotationsPanelContainer}>
      <ModalLayout
        footer={
          <Button disabled={disabled} onClick={onClose}>
            {t(TransKeys.GENERAL.ACTIONS.DONE)}
          </Button>
        }
      >
        {isLoadingAnnotations && <GenericLoading />}
        <div className={classes.HomepageAnnotationsPanel}>
          <FancyHeader
            title={t(TransKeys.HOMEPAGE_ANNOTATIONS_PANEL.TITLE, {
              date: dateString,
            })}
            icon={AnnotationIcon}
            onClose={onClose}
            className={classes.Header}
          />
          <div className={classes.AnnotationsList}>
            {annotations.map(annotation => renderAnnotationRow(annotation))}
          </div>
        </div>
      </ModalLayout>
    </div>
  );
};

const HomepageAnnotationsPanel = composition<AllProps>(
  HomepageAnnotationsPanelComponent,
  withModalInactiveSourceHandler,
  withDisableDemoProduct
);

export {HomepageAnnotationsPanel};
