export enum SourceType {
  SQLITE3 = 'sqlite3',
  BIGQUERY = 'bigquery',
  REDSHIFT = 'redshift',
  SNOWFLAKE = 'snowflake',
  ATHENA = 'athena',
  VERTICA = 'vertica',
  S3 = 's3',
  POSTGRESQL = 'postgresql',
  DATABRICKS = 'databricks',
  CLICKHOUSE = 'clickhouse',
  MYSQL = 'mysql',
  SINGLESTORE = 'singlestore',
}
