import {action, OnSuccessActionHook} from 'front-core';
import {TableDTO} from '../../objects/dto/table.dto';

export enum TablesActionType {
  CREATE_TABLE = '@@tables/CREATE_TABLE',
  UPDATE_TABLE = '@@tables/UPDATE_TABLE',
  TRIGGER_TABLE_DISCOVERY = '@@tables/TRIGGER_TABLE_DISCOVERY',
  TRIGGER_ALL_TABLES_DISCOVERY = '@@tables/TRIGGER_ALL_TABLES_DISCOVERY',
  TRIGGER_SCAN_ALL_TYPES_OF_TABLES = '@@tables/TRIGGER_SCAN_ALL_TYPES_OF_TABLES',
}

export const createTable = (data: TableDTO, onSuccess: OnSuccessActionHook) =>
  action(TablesActionType.CREATE_TABLE, data, {onSuccess});

export const updateTable = (data: TableDTO, onSuccess: OnSuccessActionHook) =>
  action(TablesActionType.UPDATE_TABLE, data, {onSuccess});

export const triggerTableDiscovery = (tableId: number, onSuccess?: OnSuccessActionHook) =>
  action(TablesActionType.TRIGGER_TABLE_DISCOVERY, tableId, {onSuccess});

export const triggerAllTablesDiscovery = (onSuccess?: OnSuccessActionHook) =>
  action(TablesActionType.TRIGGER_ALL_TABLES_DISCOVERY, null, {onSuccess});

export const triggerScanAllTypesOfTables = (onSuccess?: OnSuccessActionHook) =>
  action(TablesActionType.TRIGGER_SCAN_ALL_TYPES_OF_TABLES, null, {
    onSuccess,
  });
