import classNames from 'classnames';
import classes from './list-layout.module.scss';
import {colorAlphaTransformer, IconButton, Link} from 'ui-components';
import {CircularProgress} from '@material-ui/core';
import {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import TransKeys from '../../../../../constants/translation-keys';

interface OwnProps {
  title?: string;
  icon?: any;
  list: any[];
  meta: any;
  renderItem: (item: any) => any;
  loadMore?: () => void;
  collapsible?: boolean;
  className?: string;
  color?: string;
  horizontal?: boolean;
  isLoading?: boolean;
}

type AllProps = OwnProps;

export const ListLayout = (props: AllProps) => {
  const {
    title,
    renderItem,
    icon: Icon,
    loadMore,
    meta,
    list: list_,
    collapsible = true,
    color,
    horizontal,
    className,
    isLoading,
  } = props;
  const [isOpen, setIsOpen] = useState<boolean>(collapsible || true);
  const {t} = useTranslation();
  const list = useMemo(() => list_ || [], [list_]);
  useEffect(() => {
    list.length === 0 ? setIsOpen(false) : setIsOpen(true);
  }, [list]);
  const colorOpacity = useMemo(() => colorAlphaTransformer(color, 0.2, true), [color]);

  return (
    <div
      style={{color}}
      className={classNames(
        classes.ListLayout,
        !isOpen && classes.Closed,
        horizontal && classes.Horizontal,
        className
      )}
    >
      <div className={classes.Spacer} />
      {title && (
        <div
          style={{
            backgroundImage: `linear-gradient(-45deg, ${colorOpacity} 0%, ${colorOpacity} 10%, ${
              isLoading ? 'rgba(255, 255, 255, 0.5)' : '#ffffff'
            } 10%, ${isLoading ? 'rgba(255, 255, 255, 0.5)' : '#ffffff'} 10%)`,
          }}
          className={classNames(classes.ListTitle)}
        >
          <div className={classes.TitleWrapper}>
            <div
              className={classNames(
                classes.CollapseButton,
                collapsible && classes.Collapsible,
                isLoading && classes.Loading
              )}
            >
              {collapsible && !isLoading && (
                <IconButton
                  onClick={e => setIsOpen(!isOpen)}
                  className={classes.CollapseIcon}
                  icon={ArrowDropUpIcon}
                  disabled={isLoading}
                />
              )}
              {isLoading && (
                <div className={classes.CircularProgress}>
                  <CircularProgress size={26} color={'secondary'} />
                </div>
              )}
            </div>
            <Icon className={classes.TitleIcon} />
            <span>{title}</span>
          </div>
          {!isLoading && meta?.total > 0 && (
            <div className={classes.Actions}>
              <span className={classes.Action}>
                {meta.total} {t(TransKeys.GENERAL.LABELS.ITEMS)}
              </span>
            </div>
          )}
        </div>
      )}
      <div className={classes.List}>
        {list.map((o, idx) => renderItem(o))}
        {meta?.hasNext && loadMore && (
          <div className={classes.LoadMore}>
            <Link disabled={isLoading} onClick={loadMore}>
              {t(TransKeys.GENERAL.ACTIONS.LOAD_MORE)}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
