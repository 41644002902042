import classes from './analysis-rerun-panel.module.scss';
import {Button, Checkbox, ModalLayout} from 'ui-components';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {useCallback, useMemo, useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import yup from '../../../../config/yup.config';
import {yupResolver} from '@hookform/resolvers/yup';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {SchemaOf} from 'yup';
import {AnalysisRerunDTO} from '../../../../objects/dto/analysis.dto';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import moment from 'moment';
import {TIME_FORMATS} from '../../../../constants/time-formats';
import {getReducedLoadingStateSelector} from '../../../../store/store.selectors';
import {useDispatch, useSelector} from 'react-redux';
import {ActionKey} from '../../../../constants/action-key';
import {rerunAnalysisResult} from '../../../../store/analyses/analyses.actions';
import {composition} from 'front-core';
import {withModalInactiveSourceHandler} from '../../../../core/hoc/with-modal-inactive-source-handler.hoc';
import {withDisableDemoProduct} from '../../../../core/hoc/with-disable-demo-product.hoc';

interface OwnProps {
  analysisResultId: number;
  onClose: () => void;
  disabled?: boolean;
}

type AllProps = OwnProps;

const reRunAnalysisDTOValidator: SchemaOf<AnalysisRerunDTO> = yup.object().shape({
  analysisResultId: yup.number().required(),
  endDate: yup.string().optional().nullable(),
});
const RerunAnalysisPanelComponent = (props: AllProps) => {
  const {onClose, analysisResultId, disabled} = props;
  const [updateEndDate, setUpdateEndDate] = useState(false);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {defaultSource} = useProductData();
  const {control, setValue, handleSubmit} = useForm({
    defaultValues: {
      endDate: null,
      analysisResultId,
    },
    resolver: yupResolver(reRunAnalysisDTOValidator),
  });
  const isLoading = useSelector(getReducedLoadingStateSelector(ActionKey.RERUN_ANALYSIS_RESULT));
  const isDisabled = useMemo(() => isLoading || disabled, [isLoading, disabled]);
  const newEndDate = useMemo(
    () =>
      (defaultSource?.lastValidDate
        ? moment(defaultSource.lastValidDate)
        : moment().startOf('d')
      ).format(TIME_FORMATS.PARAMETER_DATE_FORMAT),
    [defaultSource]
  );
  const onUpdateAnalysisEndDate = useCallback(
    (v: boolean) => {
      setUpdateEndDate(v);
      setValue('endDate', v ? newEndDate : null);
    },
    [setUpdateEndDate, newEndDate, setValue]
  );
  const onSubmit = useCallback(
    data => {
      dispatch(
        rerunAnalysisResult(data, () => {
          onClose();
          return [];
        })
      );
    },
    [dispatch, onClose]
  );

  return (
    <div className={classes.RerunAnalysisPanelContainer}>
      <ModalLayout
        footer={
          <div className={classes.Actions}>
            <Button className={classes.Action} variant={'outlined'} onClick={_ => onClose()}>
              {t(TransKeys.GENERAL.LABELS.CANCEL)}
            </Button>
            <Button
              className={classes.Action}
              onClick={e => {
                e.stopPropagation();
                handleSubmit(onSubmit)(e);
              }}
              variant={'contained'}
              disabled={isDisabled}
            >
              {t(TransKeys.GENERAL.LABELS.YES)}
            </Button>
          </div>
        }
      >
        <div className={classes.Body}>
          {isLoading && <GenericLoading />}
          <div className={classes.Title}>
            {t(TransKeys.CONFIRMATIONS.RERUN_ANALYSIS_RESULT.TITLE)}
          </div>
          <div className={classes.Content}>
            {t(TransKeys.CONFIRMATIONS.RERUN_ANALYSIS_RESULT.CONTENT)}
          </div>
          <Controller
            render={() => (
              <Checkbox
                className={classes.Checkbox}
                border={true}
                checked={updateEndDate}
                onChange={onUpdateAnalysisEndDate}
                label={t(TransKeys.CONFIRMATIONS.RERUN_ANALYSIS_RESULT.CHECKBOX_LABEL)}
              />
            )}
            name={'endDate'}
            control={control}
          />
        </div>
      </ModalLayout>
    </div>
  );
};

const RerunAnalysisPanel = composition<AllProps>(
  RerunAnalysisPanelComponent,
  withModalInactiveSourceHandler,
  withDisableDemoProduct
);

export {RerunAnalysisPanel};
