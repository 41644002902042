import classes from './viewers.module.scss';
import classNames from 'classnames';
import {ImagesResource} from '../../../../../assets/images';
import {WinnerLabel} from '../../../components/general/winner-label/winner-label.component';

interface OwnProps {
  className?: string;
  text?: string;
}

export const WinnerHeaderViewer = (props: OwnProps) => {
  const {text, className} = props;

  return (
    <div className={classNames(classes.WinnerHeader, className)}>
      <img src={ImagesResource.app.winner} alt={''} />
      <WinnerLabel text={text} />
    </div>
  );
};
