import {Epic} from 'redux-observable';
import {createRequestEpic} from 'front-core';
import {ActionKey} from '../../constants/action-key';
import HttpClient from '../../services/http-client.service';
import {SegmentsActionType} from './segments.actions';
import {
  addSegmentToTeamNetworkRequest,
  createSegmentNetworkRequest,
  deleteSegmentNetworkRequest,
  editSegmentNetworkRequest,
  rescanSegmentNetworkRequest,
  updateFavoritesSegmentsNetworkRequest,
  validateSegmentNetworkRequest,
} from '../../http/segments.network-requests';
import {
  getProductData,
  modelCreated,
  modelDeleted,
  modelUpdated,
  notifyEvent,
} from '../core/core.actions';
import {ModelKey} from '../../constants/model-key';
import {segmentToastCreator} from '../toasts.actions';
import {AmplitudeEvent} from '../../constants/amplitude-event';
import {showToastMessage} from '../interface/interface.actions';
import {ToastType} from '../../objects/system/toast-type.enum';
import {
  demoProductLimitedActionsFilter,
  errorExtractor,
  getDMPToastErrorMessage,
} from '../store.utils';
import {Segment} from '../../objects/models/segment.model';

export const createSegmentEpic: Epic = createRequestEpic(
  {
    types: [SegmentsActionType.CREATE_SEGMENT],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.CREATE_SEGMENT,
    request: createSegmentNetworkRequest,
    onSuccess: segment => [
      notifyEvent(AmplitudeEvent.SEGMENT_CREATED, {
        id: segment.id,
        entity: segment.entity,
      }),
      segmentToastCreator('CREATE_SUCCESS'),
      modelCreated(segment, ModelKey.SEGMENT),
    ],
    onError: err_ => {
      const err = errorExtractor(err_);
      if (err.errorCode === 5001) {
        return [
          showToastMessage('Segment name already is taken', ToastType.ERROR),
          notifyEvent(AmplitudeEvent.CREATE_SEGMENT_ERROR, err),
        ];
      }
      return [segmentToastCreator('CREATE_ERROR')];
    },
  },
  HttpClient
);

export const updateSegmentEpic: Epic = createRequestEpic(
  {
    types: [SegmentsActionType.UPDATE_SEGMENT],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.UPDATE_SEGMENT,
    request: editSegmentNetworkRequest,
    onSuccess: segment => [
      notifyEvent(AmplitudeEvent.SEGMENT_UPDATED, {id: segment.id}),
      segmentToastCreator('UPDATE_SUCCESS'),
      modelUpdated(segment, ModelKey.SEGMENT),
    ],
    onError: err_ => {
      const err = errorExtractor(err_);
      const toastMessage = getDMPToastErrorMessage(err, 'segment');
      if (toastMessage) {
        return [
          showToastMessage(toastMessage, ToastType.ERROR),
          notifyEvent(AmplitudeEvent.UPDATE_SEGMENT_ERROR, err),
        ];
      }
      return [segmentToastCreator('UPDATE_ERROR')];
    },
  },
  HttpClient
);

export const rescanSegmentEpic: Epic = createRequestEpic(
  {
    types: [SegmentsActionType.RESCAN_SEGMENT],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.RESCAN_SEGMENT,
    request: rescanSegmentNetworkRequest,
    onSuccess: segment => [
      notifyEvent(AmplitudeEvent.SEGMENT_RESCANNED, {id: segment.id}),
      segmentToastCreator('RESCAN_SUCCESS'),
      modelUpdated(segment, ModelKey.SEGMENT),
    ],
    onError: err_ => {
      const err = errorExtractor(err_);
      const toastMessage = getDMPToastErrorMessage(err, 'segment');
      if (toastMessage) {
        return [
          showToastMessage(toastMessage, ToastType.ERROR),
          notifyEvent(AmplitudeEvent.SEGMENT_RESCAN_VALIDATION_ERROR, err),
        ];
      }
      return [segmentToastCreator('RESCAN_ERROR')];
    },
  },
  HttpClient
);

export const deleteSegmentEpic: Epic = createRequestEpic(
  {
    types: [SegmentsActionType.DELETE_SEGMENT],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.DELETE_SEGMENT,
    request: deleteSegmentNetworkRequest,
    onSuccess: (res, payload) => [
      notifyEvent(AmplitudeEvent.SEGMENT_DELETED, {id: payload}),
      segmentToastCreator('DELETE_SUCCESS'),
      modelDeleted(payload, ModelKey.SEGMENT),
    ],
    onError: err => [segmentToastCreator('DELETE_ERROR')],
  },
  HttpClient
);

export const validateSegmentEpic: Epic = createRequestEpic(
  {
    types: [SegmentsActionType.VALIDATE_SEGMENT],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.VALIDATE_SEGMENT,
    request: validateSegmentNetworkRequest,
    onSuccess: (segment: Segment) => {
      return [
        notifyEvent(AmplitudeEvent.SEGMENT_VALIDATED, {id: segment.id}),
        segmentToastCreator('UPDATE_SUCCESS'),
        modelUpdated(segment, ModelKey.SEGMENT),
      ];
    },
    onError: err => {
      return [segmentToastCreator('UPDATE_ERROR')];
    },
  },
  HttpClient
);

export const updateFavoriteSegmentsEpic: Epic = createRequestEpic(
  {
    types: [SegmentsActionType.UPDATE_FAVORITES_SEGMENTS],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.UPDATE_FAVORITES_SEGMENTS,
    request: updateFavoritesSegmentsNetworkRequest,
    onSuccess: segmentPref => {
      return [
        getProductData(),
        notifyEvent(AmplitudeEvent.FAVORITE_SEGMENTS_UPDATED),
        showToastMessage('Team segments updated successfully', ToastType.SUCCESS),
        modelUpdated(segmentPref, ModelKey.FAVORITE_SEGMENTS),
      ];
    },
    onError: err => {
      return [showToastMessage('Error while updating team segments', ToastType.ERROR)];
    },
  },
  HttpClient
);

export const addSegmentsToTeamEpic: Epic = createRequestEpic(
  {
    types: [SegmentsActionType.ADD_SEGMENT_TO_TEAM],
    actionKey: ActionKey.ADD_SEGMENT_TO_TEAM,
    request: addSegmentToTeamNetworkRequest,
    onSuccess: segmentPref => {
      return [
        notifyEvent(AmplitudeEvent.FAVORITE_SEGMENTS_UPDATED),
        showToastMessage('Team segments updated successfully', ToastType.SUCCESS),
        modelUpdated(segmentPref, ModelKey.FAVORITE_SEGMENTS),
      ];
    },
    onError: err => {
      return [showToastMessage('Error while updating team segments', ToastType.ERROR)];
    },
  },
  HttpClient
);
