import classes from '../app-navigator.module.scss';
import classNames from 'classnames';
import {User} from '../../../../../objects/models/user.model';
import {ChevronDownIcon} from 'ui-components';

interface OwnProps {
  user: User;
  product: any;
  isActive: boolean;
  isCollapsed: boolean;
  onClick: (e: any) => void;
}

type AllProps = OwnProps;

const UserMenuLauncher = (props: AllProps) => {
  const {user, product, isActive, isCollapsed, onClick} = props;

  const userDisplayName = isCollapsed
    ? `${user?.firstName?.charAt(0)}${user?.lastName?.charAt(0)}`
    : `${user?.firstName} ${user?.lastName}`;

  return (
    <div
      onClick={onClick}
      className={classNames(
        classes.UserAvatar,
        isActive && classes.Active,
        isCollapsed && classes.Collapsed
      )}
    >
      <div className={classes.Names}>
        <span className={classes.ProductName}>{product?.name}</span>
        <span className={classes.UserName}>{userDisplayName}</span>
      </div>
      <ChevronDownIcon className={classes.Chevron} />
    </div>
  );
};

export default UserMenuLauncher;
