import React, {useMemo} from 'react';
import {Select} from 'ui-components';
import {User} from '../../../../objects/models/user.model';
import classes from './app-user-modal.module.scss';

interface OwnProps {
  user: User;
  scopeProductId?: number;
  onScopeChange: (id: number) => void;
}

type AllProps = OwnProps;

export const ProductSelect = (props: AllProps) => {
  const {user, scopeProductId, onScopeChange} = props;

  const options = useMemo(
    () =>
      user.products.map(p => ({
        value: p.id.toString(),
        label: p.name,
      })),
    [user.products]
  );

  const hasSingleProduct = options.length === 1;

  if (hasSingleProduct) {
    return options[0].label;
  }

  return (
    <Select
      className={classes.Select}
      dropdownButtonClassName={classes.DropdownButtonClassName}
      placeholder={'Select'}
      value={scopeProductId?.toString()}
      searchable={options.length > 5}
      clearable={false}
      onChange={onScopeChange as any}
      disablePortal
      fullWidth
      capitalize={false}
      options={{options}}
    />
  );
};
