import {
  RCAInsightSentence,
  SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseAndKpiIncreaseDecrease,
} from './objects';
import sharedClasses from '../insights.module.scss';
import {
  ChangeInShareOfEntitiesSentencePart,
  ChangeInValueSentencePart,
  SegmentNameSentencePart,
} from '../components/shared-sentence-parts/shared-sentence-parts.components';
import {FC} from 'react';

interface OwnProps extends RCAInsightSentence {
  formattedSentenceParts: SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseAndKpiIncreaseDecrease;
}

type AllProps = OwnProps;

const SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseAndKpiIncreaseDecreaseFormatter: FC<
  AllProps
> = (props: AllProps) => {
  const {formattedSentenceParts, sentenceParts} = props;
  const {
    pastTensePerformanceVsPriorWeek,
    entityChangeDescription,
    changeInShareOfEntities,
    entityChangeDescriptionSynonym,
    entity,
    presentTensePerformanceVsAvg,
    segmentChangeDescription,
    changeDescription,
  } = formattedSentenceParts;
  const {segmentViewData, changeInValue} = sentenceParts;

  return (
    <>
      <div className={sharedClasses.InsightSentenceContainer}>
        <SegmentNameSentencePart data={segmentViewData} />
        <span className={sharedClasses.InsightSentencePart}>
          {`${pastTensePerformanceVsPriorWeek} vs the analyzed period by `}
        </span>
        <ChangeInValueSentencePart changeInValue={changeInValue} />
        <span className={sharedClasses.InsightSentencePart}>{`. In`}</span>
        <span className={sharedClasses.InsightSentencePart}>
          {`addition - the share of ${entity} from this segment ${entityChangeDescription}d by`}
        </span>
        <ChangeInShareOfEntitiesSentencePart changeInShareOfEntities={changeInShareOfEntities} />
        <span className={sharedClasses.InsightSentencePart}>.</span>
      </div>
      <div className={sharedClasses.InsightSentenceContainer}>
        <span className={sharedClasses.InsightSentencePart}>
          {`This is a driver for the ${changeDescription} because:`}
        </span>
      </div>
      <div className={sharedClasses.InsightSentenceContainer}>
        <div className={sharedClasses.SecondaryUL}>
          <span className={sharedClasses.Bullet} />
          <div className={sharedClasses.InsightSentencePart}>
            {`You had ${entityChangeDescriptionSynonym} ${entity} from a segment that is ${presentTensePerformanceVsAvg} vs the average`}
          </div>
          <span className={sharedClasses.Bullet} />
          <div className={sharedClasses.InsightSentencePart}>
            {`The KPI value of this segment ${segmentChangeDescription}d vs the analyzed period`}
          </div>
        </div>
      </div>
    </>
  );
};

export {SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseAndKpiIncreaseDecreaseFormatter};
