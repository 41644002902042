import {yupResolver} from '@hookform/resolvers/yup';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {TextInput, Textarea} from 'ui-components';
import {Footer} from '../../footer/footer.component';
import classes from './snowflake-form.module.scss';
import {isEmpty} from 'lodash';
import {SourceWizardFormLayout} from '../../source-wizard-form-layout/source-wizard-form-layout.component';
import {StandardCheckBox} from '../../../../../../shared/components/general/standard-check-box/standard-check-box.component';
import {SourceWizardLabelWrapper} from '../../source-wizard-label-wrapper/source-wizard-label-wrapper.component';
import {
  SnowflakeFormSchema,
  snowflakeSchema,
  SnowflakeSourceAuthTypes,
} from '../../../../../../../objects/dto/source.dto';
export type SnowflakeFormProps = {
  onSubmit?: (values: SnowflakeFormSchema) => void;
};

export const SnowflakeForm = (props: SnowflakeFormProps) => {
  const {onSubmit} = props;
  const formMethods = useForm({
    resolver: yupResolver(snowflakeSchema),
    defaultValues: {
      authType: SnowflakeSourceAuthTypes.PASSWORD,
    },
  });

  const {
    handleSubmit,
    watch,
    formState: {errors, isValid},
    control,
  } = formMethods;

  const authType = watch('authType');

  return (
    <SourceWizardFormLayout
      footer={
        <Footer
          buttonText="Continue"
          onClickSubmit={handleSubmit(onSubmit)}
          disabledCTAButton={!isEmpty(errors) || !isValid}
        />
      }
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.SnowflakeForm}>
          <SourceWizardLabelWrapper label="Account Identifier">
            <Controller
              render={({field, fieldState: {error}}) => (
                <TextInput
                  size="large"
                  placeholder={'Type here'}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(error)}
                  required
                />
              )}
              name={'accountIdentifier'}
              control={control}
            />
          </SourceWizardLabelWrapper>
          <SourceWizardLabelWrapper label="Username">
            <Controller
              render={({field, fieldState: {error}}) => (
                <TextInput
                  size="large"
                  placeholder={'Type here'}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(error)}
                  required
                />
              )}
              name={'username'}
              control={control}
            />
          </SourceWizardLabelWrapper>
          <Controller
            render={({field}) => (
              <SourceWizardLabelWrapper label="Authentication Type">
                <div className={classes.RadioGroup}>
                  <div className={classes.RadioButton} onClick={() => field.onChange('password')}>
                    <StandardCheckBox radio size="small" checked={field.value === 'password'} />
                    <span>Password</span>
                  </div>
                  <div className={classes.RadioButton} onClick={() => field.onChange('key')}>
                    <StandardCheckBox radio size="small" checked={field.value === 'key'} />
                    <span>RSA Key Pair</span>
                  </div>
                </div>
              </SourceWizardLabelWrapper>
            )}
            name={'authType'}
            control={control}
          />
          {authType === SnowflakeSourceAuthTypes.PASSWORD && (
            <>
              <SourceWizardLabelWrapper label="Password">
                <Controller
                  render={({field, fieldState: {error}}) => (
                    <TextInput
                      size="large"
                      placeholder={'Type here'}
                      value={field.value}
                      onChange={field.onChange}
                      type="password"
                      error={Boolean(error)}
                    />
                  )}
                  name={'password'}
                  control={control}
                />
              </SourceWizardLabelWrapper>
            </>
          )}
          {authType === SnowflakeSourceAuthTypes.KEY && (
            <SourceWizardLabelWrapper label="Private Key">
              <Controller
                render={({field, fieldState: {error}}) => (
                  <Textarea
                    fullWidth
                    rows={5}
                    required
                    placeholder={'Enter Private key'}
                    value={field.value}
                    onChange={field.onChange}
                    error={true}
                  />
                )}
                name={'key'}
                control={control}
              />
            </SourceWizardLabelWrapper>
          )}
          <SourceWizardLabelWrapper label="Warehouse Name">
            <Controller
              render={({field, fieldState: {error}}) => (
                <TextInput
                  size="large"
                  placeholder={'Type here'}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(error)}
                  required
                />
              )}
              name={'warehouseName'}
              control={control}
            />
          </SourceWizardLabelWrapper>
          <SourceWizardLabelWrapper label="User Role (optional)">
            <Controller
              render={({field, fieldState: {error}}) => (
                <TextInput
                  size="large"
                  placeholder={'Type here'}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(error)}
                />
              )}
              name={'userRole'}
              control={control}
            />
          </SourceWizardLabelWrapper>
          <SourceWizardLabelWrapper label="Catalog (optional)">
            <Controller
              render={({field, fieldState: {error}}) => (
                <TextInput
                  size="large"
                  placeholder={'Type here'}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(error)}
                />
              )}
              name={'catalog'}
              control={control}
            />
          </SourceWizardLabelWrapper>
        </form>
      </FormProvider>
    </SourceWizardFormLayout>
  );
};
