import {useMemo} from 'react';
import classes from './opportunity-row.module.scss';
import {Opportunity} from '../../../../objects/models/opportunity.model';
import {
  FlexHorizontal,
  FlexVertical,
} from '../../../shared/components/layout/flex-layout/general-flex-layouts.component.';
import {TitleWithIcon} from '../../../shared/components/general/title/title.component';
import {HorizontalCard} from '../../../shared/components/general/horizontal-card/horizontal-card.component';
import {AREA_COLOR, AREA_ICONS} from '../../../../constants/ui';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../constants/translation-keys';
import classNames from 'classnames';
import moment from 'moment';
import {
  ActionsDropdown,
  AnalysisFileIcon,
  Chip,
  Dot,
  IconButton,
  KPIIcon,
  MoreIcon,
  ShareIcon,
  StarIcon,
  ToggleIconButton,
  ArchiveIcon,
  SnoozeIcon,
} from 'ui-components';
import {AppSentence} from '../../../shared/core/app-sentence/app-sentence.component';
import {AnalysisParameters} from '../../../analyses/components/analysis-parameters/analysis-parameters.component';
import {SOURCE_PROP_KEY} from '../../../../constants/shared-component-prop-key';

interface OwnProps {
  opportunity: Opportunity;
  onClick: (e) => void;
  onExplore: (e) => void;
  onSnooze: (e) => void;
  onDismiss: (e) => void;
  onShare: (e) => void;
  onViewMetric: (e) => void;
  active?: boolean;
  className?: string;
  [SOURCE_PROP_KEY]?: string;
}

type AllProps = OwnProps;

const IS_NEW_DIFF_DAYS = 7;

export const OpportunityRow = (props: AllProps) => {
  const {t} = useTranslation();
  const {
    opportunity,
    onClick,
    onExplore,
    onSnooze,
    onDismiss,
    onShare,
    onViewMetric,
    className,
    active,
    [SOURCE_PROP_KEY]: appSource,
  } = props;
  const relativeTime = useMemo(
    () => moment.utc(opportunity.updatedOn).local().fromNow(),
    [opportunity]
  );
  const isNew = useMemo(() => {
    return moment(opportunity.createdOn).add(IS_NEW_DIFF_DAYS, 'd').isAfter(moment());
  }, [opportunity]);
  const actions = useMemo(
    () => [
      {
        key: 'dismiss',
        title: opportunity.isDismissed
          ? t(TransKeys.GENERAL.ACTIONS.UNARCHIVE)
          : t(TransKeys.GENERAL.ACTIONS.ARCHIVE),
        onClick: onDismiss,
        hide: !onDismiss,
        icon: ArchiveIcon,
      },
      {
        key: 'delete',
        title: opportunity.snoozeUntil
          ? t(TransKeys.GENERAL.ACTIONS.UN_SNOOZE)
          : t(TransKeys.GENERAL.ACTIONS.SNOOZE),
        onClick: onSnooze,
        hide: !onSnooze,
        icon: SnoozeIcon,
      },
    ],
    [onDismiss, onSnooze, t, opportunity]
  );

  return (
    <HorizontalCard
      color={AREA_COLOR[opportunity.areaInProduct]}
      className={classNames(classes.OpportunityRow, className)}
      icon={AREA_ICONS[opportunity.areaInProduct]}
      active={active}
      columns={[
        {
          key: 'main',
          weight: 5,
          render: () => (
            <FlexVertical
              spacing={Boolean(isNew || opportunity.metric?.id)}
              className={classes.Main}
            >
              <div onClick={onClick}>
                <AppSentence
                  className={classNames(classes.Title, !opportunity.isViewed && classes.Bold)}
                  text={opportunity.title || ''}
                  disabled
                />
              </div>
              {(opportunity.metric?.id || isNew) && (
                <FlexHorizontal spacing verticalAlignCenter>
                  {opportunity.metric?.id && (
                    <TitleWithIcon
                      onClick={onViewMetric}
                      text={opportunity.metric.title || opportunity.metric.name}
                      icon={KPIIcon}
                      size={'small'}
                    />
                  )}
                  {isNew && <Chip label={t(TransKeys.GENERAL.LABELS.NEW)} />}
                </FlexHorizontal>
              )}
            </FlexVertical>
          ),
        },
        {
          key: 'analysis',
          weight: 2,
          divider: false,
          render: () => (
            <FlexVertical spacing>
              <TitleWithIcon
                text={opportunity.analysis.staticName}
                icon={AnalysisFileIcon}
                size={'medium'}
              />
              <AnalysisParameters
                className={classes.ParametersButton}
                label={t(TransKeys.GENERAL.LABELS.PARAMETERS)}
                analysisResultId={opportunity.analysisResultId}
                appSource={appSource}
              />
            </FlexVertical>
          ),
        },
        {
          key: 'actions',
          weight: 1,
          render: () => (
            <FlexVertical spacing>
              <div className={classes.UpdatedAt}>
                <Dot size={0.4} className={classes.Dot} />
                {relativeTime}
              </div>
              <FlexHorizontal verticalAlignCenter className={classes.Actions}>
                <ToggleIconButton
                  titleChecked={t(TransKeys.GENERAL.ACTIONS.UNEXPLORE)}
                  titleUnchecked={t(TransKeys.GENERAL.ACTIONS.EXPLORE)}
                  onChange={onExplore}
                  className={classes.IconBtn}
                  isChecked={opportunity.isExplored}
                  icon={StarIcon}
                />
                <IconButton
                  tooltipText={t(TransKeys.GENERAL.ACTIONS.SHARE)}
                  icon={ShareIcon}
                  className={classes.IconBtn}
                  onClick={() => onShare(opportunity)}
                />
                <ActionsDropdown
                  actions={actions}
                  label={t(TransKeys.GENERAL.LABELS.MORE_DOTS)}
                  icon={MoreIcon}
                  iconDropdown
                />
              </FlexHorizontal>
            </FlexVertical>
          ),
        },
      ]}
    />
  );
};
