import classes from './no-explainers-msg.module.scss';
import {WarningIcon} from 'ui-components';

interface OwnProps {}
type AllProps = OwnProps;

export const NoExplainersMsg = (props: AllProps) => {
  return (
    <div className={classes.NoExplainersMsgContainer}>
      <WarningIcon />
      <span>
        Notice: these segments do not explain the change in KPI. They only show interesting
        phenomena
      </span>
    </div>
  );
};
