import {withLoadBefore} from '../../../../../../core/hoc/with-load-before.hoc';
import TransKeys from '../../../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {useCallback, useMemo} from 'react';
import {
  MultiSelectInput,
  TextareaFormInput,
} from '../../../../../shared/form/components/shared-form-input.component';
import {getSlackChannelsNetworkRequest} from '../../../../../../http/slack-channels.network-requests';
import {groupBy, isArray} from 'lodash';
import {useAmplitude} from '../../../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../../../constants/amplitude-event';
import {ShareStrategyTypes} from '../../../../../../objects/models/share.model';
import {SlackBtn} from '../slack-btn/add-slack-btn.component';

export interface SlackChannel {
  id: string;
  name: string;
  type: 'channel' | 'user';
}

interface OwnProps {
  installedSlack: boolean;
  channels?: SlackChannel[];
}

type AllProps = OwnProps;

const parseMemberOption = (m: SlackChannel) => {
  // Check if the id of the member is an email address
  const isEmail = m.id.includes('@');
  if (isEmail) {
    return {
      label: `${m.name} (${isEmail ? m.id : ''})`,
      value: m.id,
    };
  }
  return {
    label: m.name,
    value: m.id,
  };
};

const ShareDirectSlackFormFieldsComponent = (props: AllProps) => {
  const {installedSlack, channels: channelsFromProps} = props;
  const {t} = useTranslation();
  const notify = useAmplitude();
  const {user: members, channel: channels} = useMemo(
    () => groupBy(channelsFromProps, 'type'),
    [channelsFromProps]
  );

  const inviteesOptionsProps = useMemo(() => {
    if (!members || !isArray(members) || members.length === 0) {
      return null;
    }
    return {
      options: members.map(parseMemberOption),
    };
  }, [members]);

  const channelsOptionsProps = useMemo(() => {
    if (!channels || !isArray(channels) || channels.length === 0) {
      return null;
    }
    return {
      options: channels.map(channel => ({
        label: channel.name,
        value: channel.id,
      })),
    };
  }, [channels]);

  const onShareInputChange = useCallback(
    (field: string) =>
      notify(AmplitudeEvent.RESOURCE_SHARE_INPUT_CHANGED, {
        strategy: ShareStrategyTypes.DIRECT_SLACK,
        field,
      }),
    [notify]
  );

  const onInviteesChange = useCallback(() => onShareInputChange('invitees'), [onShareInputChange]);
  const onChannelsChange = useCallback(() => onShareInputChange('channels'), [onShareInputChange]);

  // Technically if installedSlack is false we would never get members or channels
  // but just in case the network requests failed (for any reason) we don't want
  // to show the form
  if (!installedSlack || !members || !channels) {
    return <SlackBtn />;
  }

  return (
    <>
      <MultiSelectInput
        label={t(TransKeys.SHARE_RESOURCE_FORM.DIRECT_SLACK.INPUTS.CHANNELS.LABEL)}
        name={'channels'}
        options={channelsOptionsProps}
        placeholder={t(TransKeys.SHARE_RESOURCE_FORM.DIRECT_SLACK.INPUTS.CHANNELS.PLACEHOLDER)}
        onChange={onChannelsChange}
      />
      <MultiSelectInput
        label={t(TransKeys.SHARE_RESOURCE_FORM.DIRECT_SLACK.INPUTS.INVITEES.LABEL)}
        name={'invitees'}
        options={inviteesOptionsProps}
        placeholder={t(TransKeys.SHARE_RESOURCE_FORM.DIRECT_SLACK.INPUTS.INVITEES.PLACEHOLDER)}
        onChange={onInviteesChange}
      />
      <TextareaFormInput
        label={t(TransKeys.SHARE_RESOURCE_FORM.DIRECT_SLACK.INPUTS.MESSAGE.LABEL)}
        name={'message'}
        placeholder={t(TransKeys.SHARE_RESOURCE_FORM.DIRECT_SLACK.INPUTS.MESSAGE.PLACEHOLDER)}
        helperText={t(TransKeys.SHARE_RESOURCE_FORM.DIRECT_SLACK.INPUTS.MESSAGE.HELPER_TEXT)}
      />
    </>
  );
};

export const ShareDirectSlackFormFields = withLoadBefore<AllProps>({
  channels: {
    selectedKey: 'GET_SLACK_TEAM_CHANNELS',
    actionKey: 'GET_SLACK_TEAM_CHANNELS',
    request: getSlackChannelsNetworkRequest,
    shouldCall: props => props.installedSlack,
  },
})(ShareDirectSlackFormFieldsComponent);
