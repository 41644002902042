import {useCallback, useMemo, useState} from 'react';
import classNames from 'classnames';
import {HealthMonitorGroup} from '../../../../../../../objects/models/health-monitor.model';
import classes from './health-monitor-group-list.module.scss';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../../../../constants/translation-keys';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {ActionsDropdown, EditIcon, Link, MoreIcon, TrashIcon} from 'ui-components';
import {useAmplitude} from '../../../../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../../../../constants/amplitude-event';
import {
  SimpleTable,
  TableColumn,
} from '../../../../../../shared/components/general/table/table.component';

interface OwnProps {
  group: HealthMonitorGroup;
  columns: TableColumn[];
  onAddMetric: () => void;
  onRemoveGroup: () => void;
  onEditGroup: () => void;
  onClick: (metricSeriesId: number) => void;
  selectedMetricId?: number;
  className?: string;
}

type AllProps = OwnProps;

export const HealthMonitorGroupList = (props: AllProps) => {
  const {
    group,
    columns,
    onAddMetric,
    onRemoveGroup,
    onEditGroup,
    onClick,
    selectedMetricId,
    className,
  } = props;
  const [isOpen, setIsOpen] = useState(true);
  const {t} = useTranslation();
  const notify = useAmplitude();
  const changeGroupOpenState = useCallback(
    (v: boolean) => {
      setIsOpen(v);
      v === true &&
        notify(AmplitudeEvent.HEALTH_MONITOR_GROUP_OPENED, {
          group_id: group.id,
        });
    },
    [setIsOpen, notify, group]
  );
  const groupActions = useMemo(
    () => [
      {
        key: 'edit',
        title: t(TransKeys.GENERAL.ACTIONS.EDIT),
        onClick: onEditGroup,
        icon: EditIcon,
      },
      {
        key: 'delete',
        title: t(TransKeys.GENERAL.ACTIONS.DELETE),
        onClick: onRemoveGroup,
        icon: TrashIcon,
      },
    ],
    [onRemoveGroup, onEditGroup, t]
  );

  return (
    <div className={classNames(classes.HealthMonitorGroupList, isOpen && classes.Open, className)}>
      <div className={classes.Header}>
        <div className={classes.Title} onClick={() => changeGroupOpenState(!isOpen)}>
          <div className={classes.Text}>
            {group.name} ({group.series.length})
          </div>
          <div className={classes.Arrow}>
            <ArrowDropDownIcon className={classes.Icon} />
          </div>
        </div>
        <div className={classes.Space} />
        <div className={classes.Actions}>
          <ActionsDropdown
            actions={groupActions}
            label={t(TransKeys.GENERAL.LABELS.MORE_DOTS)}
            icon={MoreIcon}
            iconDropdown
          />
        </div>
      </div>
      <div className={classes.Body}>
        <div className={classes.Content}>
          {group.series.length === 0 && (
            <div className={classes.EmptyStateText}>
              {t(TransKeys.HEALTH_MONITOR.EMPTY_STATES.GROUP_EMPTY_STATE)}
            </div>
          )}
          {group.series.length > 0 && (
            <SimpleTable
              data={group.series}
              columns={columns}
              dataKey={'id'}
              onRowClicked={item => onClick(item.id)}
              selectedItemKey={selectedMetricId}
            />
          )}
          <Link className={classes.AddMetricButton} onClick={() => onAddMetric()}>
            + {t(TransKeys.GENERAL.ACTIONS.ADD_METRIC)}
          </Link>
        </div>
      </div>
    </div>
  );
};
