import {ErrorBoundary} from 'react-error-boundary';
import {FancyHeader, ModalLayout} from 'ui-components';
import {capitalize} from 'lodash';
import TransKeys from '../../constants/translation-keys';
import {EmptyState} from '../../modules/shared/components/general/override';
import {ComponentType, FC, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import sharedClasses from './shared.module.scss';
import {exists} from 'front-core';

export interface WithModelErrorHandlerConfig {
  modalTitle: string;
  modalIcon?: any;
  extractErrorCodeFromProps?: (props: any) => boolean;
}

function ErrorFallback(props: any) {
  const {modalTitle, modalIcon, onClose, extractErrorCodeFromProps} = props;
  const {t} = useTranslation();

  const errorTitle = useMemo(() => {
    const errorCode = extractErrorCodeFromProps ? extractErrorCodeFromProps(props) : undefined;
    if (errorCode === 1404) {
      return t(TransKeys.GENERAL.ERROR_STATES.NOT_FOUND);
    }
    return t(TransKeys.GENERAL.ERROR_STATES.UNKNOWN_ERROR);
  }, [extractErrorCodeFromProps, t, props]);

  return (
    <div className={sharedClasses.ErrorPanelContainer}>
      <ModalLayout>
        <div className={sharedClasses.ErrorPanel}>
          <div className={sharedClasses.Header}>
            <FancyHeader icon={modalIcon} title={capitalize(t(modalTitle))} onClose={onClose} />
          </div>
          <div className={sharedClasses.Body}>
            <EmptyState title={errorTitle} />
          </div>
        </div>
      </ModalLayout>
    </div>
  );
}

export function withModalErrorHandler(config: WithModelErrorHandlerConfig) {
  return function WithModalErrorHandler(WrappedComponent: ComponentType): ComponentType {
    const WithModalErrorHandler: FC = props => {
      const hasError = useMemo(() => exists(config.extractErrorCodeFromProps(props)), [props]);
      if (hasError) {
        return <ErrorFallback {...props} {...config} />;
      }

      return (
        <ErrorBoundary
          onError={console.error}
          FallbackComponent={errorProps => <ErrorFallback {...props} {...errorProps} {...config} />}
        >
          <WrappedComponent {...props} />
        </ErrorBoundary>
      );
    };

    const wrappedComponentName =
      WrappedComponent.displayName || (WrappedComponent as any).name || 'Component';
    (WithModalErrorHandler as any).displayName = `WithModelErrorHandler(${wrappedComponentName})`;

    return WithModalErrorHandler as any;
  };
}
