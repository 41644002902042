import {NRC} from './index';
import {RequestType, toCamelCase, toSnakeCase} from 'front-core';
import {toFiltersDTO} from '../objects/dto/to-filters.dto';
import {TeamDTO} from '../objects/dto/team.dto';
import {BaseFiltersDTO} from '../objects/dto/base-filters.dto';

export const getTeamsNetworkRequest: NRC<TeamDTO, BaseFiltersDTO> = payload => ({
  method: RequestType.GET,
  relativeUrl: `/app/teams/management`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data),
});

export const getTeamNetworkRequest: NRC<TeamDTO, BaseFiltersDTO> = (id: number) => ({
  method: RequestType.GET,
  relativeUrl: `/app/teams/management/${id}`,
  responseTransformer: res => toCamelCase(res.data),
});

export const createTeamNetworkRequest: NRC<Pick<TeamDTO, 'name'>, void> = (
  payload: Pick<TeamDTO, 'name'>
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/teams/management`,
  body: payload,
  bodyTransformer: toSnakeCase,
  responseTransformer: toCamelCase,
});

export const editTeamNetworkRequest: NRC<Pick<TeamDTO, 'name' | 'id'>, void> = (
  payload: Pick<TeamDTO, 'name' | 'id'>
) => ({
  method: RequestType.PUT,
  relativeUrl: `/app/teams/management/${payload.id}`,
  body: payload,
  bodyTransformer: toSnakeCase,
  responseTransformer: toCamelCase,
});

export const deleteTeamNetworkRequest: NRC<Pick<TeamDTO, 'id'>, void> = (
  payload: Pick<TeamDTO, 'id'>
) => ({
  method: RequestType.DELETE,
  relativeUrl: `/app/teams/management/${payload.id}`,
  responseTransformer: toCamelCase,
});
