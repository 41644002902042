import {Epic} from 'redux-observable';
import {createRequestEpic} from 'front-core';
import {ActionKey} from '../../constants/action-key';
import HttpClient from '../../services/http-client.service';
import {TablesActionType} from './tables.actions';
import {
  createTableNetworkRequest,
  editTableNetworkRequest,
  triggerAllTableDiscoveryNetworkRequest,
  triggerScanAllTypesOfTablesNetworkRequest,
  triggerTableDiscoveryNetworkRequest,
} from '../../http/tables.network-requests';
import {modelCreated, notifyEvent} from '../core/core.actions';
import {AmplitudeEvent} from '../../constants/amplitude-event';
import {tableToastCreator} from '../toasts.actions';
import {ModelKey} from '../../constants/model-key';
import {Table} from '../../objects/models/table.model';
import {showToastMessage} from '../interface/interface.actions';
import {ToastType} from '../../objects/system/toast-type.enum';
import {SOURCE_META_KEY} from '../../constants/app-sources';
import {demoProductLimitedActionsFilter} from '../store.utils';

export const createTableEpic: Epic = createRequestEpic(
  {
    types: [TablesActionType.CREATE_TABLE],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.CREATE_TABLE,
    request: createTableNetworkRequest,
    onSuccess: (table: Table) => [
      notifyEvent(AmplitudeEvent.TABLE_CREATED, {
        id: table.id,
        entity: table.entity,
        scope: table.definition.type,
      }),
      tableToastCreator('CREATE_SUCCESS'),
      modelCreated(table, ModelKey.TABLE),
    ],
    onError: err_ => [tableToastCreator('CREATE_ERROR')],
  },
  HttpClient
);

export const updateTableEpic: Epic = createRequestEpic(
  {
    types: [TablesActionType.UPDATE_TABLE],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.UPDATE_TABLE,
    request: editTableNetworkRequest,
    onSuccess: table => [
      notifyEvent(AmplitudeEvent.TABLE_UPDATED, {id: table.id}),
      tableToastCreator('UPDATE_SUCCESS'),
      modelCreated(table, ModelKey.TABLE),
    ],
    onError: err_ => [tableToastCreator('UPDATE_ERROR')],
  },
  HttpClient
);

export const triggerTableDiscoveryEpic: Epic = createRequestEpic(
  {
    types: [TablesActionType.TRIGGER_TABLE_DISCOVERY],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.TRIGGER_TABLE_DISCOVERY,
    request: triggerTableDiscoveryNetworkRequest,
    onSuccess: (res, _, {metadata}) => [
      notifyEvent(AmplitudeEvent.TABLE_DISCOVERY, {
        source: metadata?.[SOURCE_META_KEY],
      }),
      showToastMessage(
        'Job sent, it might take a few minutes until the table will appear',
        ToastType.SUCCESS
      ),
    ],
    onError: err_ => [showToastMessage('Failed to trigger scan table', ToastType.ERROR)],
  },
  HttpClient
);

export const triggerAllTablesDiscoveryEpic: Epic = createRequestEpic(
  {
    types: [TablesActionType.TRIGGER_ALL_TABLES_DISCOVERY],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.TRIGGER_ALL_TABLES_DISCOVERY,
    request: triggerAllTableDiscoveryNetworkRequest,
    onSuccess: (res, _, {metadata}) => [
      notifyEvent(AmplitudeEvent.ALL_TABLES_DISCOVERY, {
        source: metadata?.[SOURCE_META_KEY],
      }),
      showToastMessage(
        'Job sent, it might take a few minutes until the table will appear',
        ToastType.SUCCESS
      ),
    ],
    onError: err_ => [showToastMessage('Failed to trigger scan tables', ToastType.ERROR)],
  },
  HttpClient
);

export const triggerScanAllTypesOfTablesEpic: Epic = createRequestEpic(
  {
    types: [TablesActionType.TRIGGER_SCAN_ALL_TYPES_OF_TABLES],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.TRIGGER_SCAN_ALL_TYPES_OF_TABLES,
    request: triggerScanAllTypesOfTablesNetworkRequest,
    onSuccess: (res, _, {metadata}) => [
      notifyEvent(AmplitudeEvent.ALL_TABLE_TYPES_SCAN, {
        source: metadata?.[SOURCE_META_KEY],
      }),
      showToastMessage(
        'Job sent, it might take a few minutes until the table will appear',
        ToastType.SUCCESS
      ),
    ],
    onError: err_ => [showToastMessage('Failed to trigger scan tables', ToastType.ERROR)],
  },
  HttpClient
);
