import {isEmpty} from 'lodash';
import {Accept, useDropzone} from 'react-dropzone';
import {ImagesResource} from '../../../../../assets/images';
import {FileUploadedPreview} from './file-uploaded-preview/file-uploaded-preview.component';
import classes from './file-dropzone.module.scss';
import {FileType} from './file-dropzone.types';
import classNames from 'classnames';
import {FileUploadedActions} from './file-uploaded-actions/file-uploaded-actions.component';
import {useMemo} from 'react';

export interface FileDropZoneProps {
  files?: FileType[];
  onChange: (value: FileType[]) => void;
  onDelete?: () => void;
  error?: {message?: string};
  acceptedFileTypes: Accept;
  maxFiles?: number;
}

export const JSON_FILE_TYPE = {'application/json': ['.json']};

export const FileDropzone = ({
  files: filesFromProps,
  onChange,
  onDelete,
  error,
  acceptedFileTypes,
  maxFiles = 1,
}: FileDropZoneProps) => {
  const files = useMemo(() => {
    if (isEmpty(filesFromProps)) {
      return;
    }
    if (!Array.isArray(filesFromProps)) {
      return [filesFromProps];
    }
    return filesFromProps;
  }, [filesFromProps]);

  const {getRootProps, getInputProps, open, isDragActive} = useDropzone({
    accept: acceptedFileTypes,
    maxFiles,
    noClick: true,
    noKeyboard: true,
    noDragEventsBubbling: true,
    onDrop: acceptedFiles => {
      if (!isEmpty(acceptedFiles)) {
        onChange(acceptedFiles.slice(0, maxFiles).map(file => ({file, content: null})));
      }
    },
  });

  return (
    <div
      {...getRootProps()}
      className={classNames(
        classes.FileDropzone,
        isDragActive && classes.ActiveDrag,
        !!error && classes.Error
      )}
    >
      <input {...getInputProps()} />
      <div className={classNames(classes.FileDropzoneContent, !!error && classes.Error)}>
        {isEmpty(files) ? (
          <>
            <div>
              <img src={ImagesResource.app.uploadFile} />
            </div>
            <div>
              Drag & drop file here or{' '}
              <span className={classes.ChooseFile} onClick={open}>
                choose file
              </span>
            </div>
          </>
        ) : (
          <>
            <div className={classNames(classes.FileUploadedContainer, !!error && classes.Error)}>
              {!!error && <div className={classes.ErrorContainer}>{error.message}</div>}
              <FileUploadedPreview files={files} isError={!!error} />
              <FileUploadedActions
                onReplace={open}
                replaceActionLabel={error ? 'Choose a different file' : 'Replace'}
                onDelete={!error ? onDelete : null}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
