import {composition} from 'front-core';
import {connector, ConnectorInjectedProps} from 'front-core';
import {loginRequest, mfaRequest, resendMFARequest} from '../../../../store/auth/auth.actions';
import {LoginLinker, LoginViewModel} from './login.linker';
import MFAForm from './mfa-form/mfa-form.component';
import LoginForm from './login-form/login-form.component';
import {AuthStatus} from '../../../../objects/models/auth.model';
import {useEffect} from 'react';
import {upsert} from 'front-core';
import {AUTH_STORE_KEY} from '../../../../store/auth/auth.store';
import {useQueryParams, StringParam, NumberParam} from 'use-query-params';
import AuthScopeFormContainer from '../auth-scope/auth-scope-form-container/auth-scope-form-container.component';

interface OwnProps {}

type AllProps = OwnProps & LoginViewModel & ConnectorInjectedProps;

const LoginComponent = ({isLoading, dispatch, error, mfaTokenId, status}: AllProps) => {
  const [query] = useQueryParams({
    status: StringParam,
    token_id: NumberParam,
    err: StringParam,
  });
  useEffect(() => {
    query.status && dispatch(upsert(AUTH_STORE_KEY, 'status', query.status));
    query.token_id && dispatch(upsert(AUTH_STORE_KEY, 'mfaTokenId', query.token_id));
  }, [dispatch, query]);

  return (
    <AuthScopeFormContainer>
      {status === undefined && (
        <LoginForm
          onSubmit={data => dispatch(loginRequest(data))}
          isLoading={isLoading}
          error={error?.message || query.err}
        />
      )}
      {status === AuthStatus.MFA_REQUIRED && (
        <MFAForm
          onSubmit={data => dispatch(mfaRequest(data.code, data.tokenId))}
          onResend={() => {
            dispatch(resendMFARequest(mfaTokenId));
          }}
          tokenId={mfaTokenId}
          isLoading={isLoading}
          error={error?.message || query.err}
        />
      )}
    </AuthScopeFormContainer>
  );
};

const Login = composition<OwnProps>(LoginComponent, connector<LoginViewModel>(LoginLinker));

export default Login;
