import {useCallback, useMemo} from 'react';
import classNames from 'classnames';
import classes from './user-property-conditions-query-builder.module.scss';
import {
  CloseIcon,
  ConditionOperator,
  CopyIcon,
  IconButton,
  LiteralValueType,
  QueryBuilderFactory,
} from 'ui-components';
import {
  METADATA_KEY,
  PARAMETERS_METADATA_KEY,
} from '../../../../../constants/parameters-saved-keys';
import {TableColumnSmartSelector} from '../../smart-selector/table-column-smart-selector.component';
import {QueryCounter} from '../components/query-counter/query-counter.component';
import {LiteralValueSelector} from '../shared-builder-parts/literal-value-selector.component';
import {OpValueSelector} from '../shared-builder-parts/op-value-selector.component';
import {set, del, insert} from 'object-path-immutable';
import {cloneDeep, get, uniqueId} from 'lodash';
import {mapLiteralByOp} from '../../../../../utils/query-builder.utils';

interface OwnProps {
  query: any;
  onChange?: (query: any) => void;
  errors?: any;
  filters?: any;
  disabled?: boolean;
  className?: string;
}

type AllProps = OwnProps;

const {TABLE_COLUMN_ID_KEY, TABLE_COLUMN_TYPE_KEY} = METADATA_KEY;
const UI_CASE_KEY = 'ui_case_key';
const CASE_CONDITION_INDEX = 0;
const CASE_THEN_INDEX = 1;

const createUserPropertyConditionsInitialQuery = () => {
  const q = QueryBuilderFactory.createCases();
  q.cases = [];
  q.else_value = QueryBuilderFactory.createLiteral();
  q.else_value.value_type = LiteralValueType.STRING;
  q.else_value.value = 'Other';
  return q;
};

const createCase = () => {
  const newCase = [];
  const condition = QueryBuilderFactory.createCondition();
  condition.left = QueryBuilderFactory.createTableColumn();
  condition.op = ConditionOperator.EQ;
  condition.right = QueryBuilderFactory.createLiteral();
  condition.right.value = null;
  newCase[CASE_CONDITION_INDEX] = condition;
  const thenValue = QueryBuilderFactory.createLiteral();
  thenValue.value_type = LiteralValueType.STRING;
  newCase[CASE_THEN_INDEX] = thenValue;
  return newCase;
};

const createCaseFor = (tableColumnId: number, tableColumn: any) => {
  const case_ = createCase();
  case_[CASE_CONDITION_INDEX][PARAMETERS_METADATA_KEY] = {
    [TABLE_COLUMN_ID_KEY]: tableColumnId,
    [TABLE_COLUMN_TYPE_KEY]: tableColumn.literalType,
    [UI_CASE_KEY]: uniqueId('case_'),
  };
  case_[CASE_CONDITION_INDEX].left.table_id = tableColumn.tableId;
  case_[CASE_CONDITION_INDEX].left.column = tableColumn.name;
  case_[CASE_CONDITION_INDEX].right.value_type = tableColumn.literalType;
  if (tableColumn.literalType === LiteralValueType.DATE) {
    case_[CASE_CONDITION_INDEX].op = ConditionOperator.LT;
  }
  return case_;
};

const TABLE_COLUMN_FILTERS = {
  tableType: 'entity_properties',
  literalType: [
    LiteralValueType.DATE,
    LiteralValueType.INTEGER,
    LiteralValueType.FLOAT,
    LiteralValueType.STRING,
    LiteralValueType.BOOLEAN,
  ],
};

export const USER_PROPERTY_CONDITIONS_QUERY_BUILDER_NAME = 'UserPropertyConditionsQueryBuilder';

export const UserPropertyConditionsQueryBuilder = (props: AllProps) => {
  const {
    query: queryFromProps,
    onChange: onChangeFromProps,
    errors: errorsFromProps,
    disabled,
    filters = {},
    className,
  } = props;

  // Wrapping with fill null (proxying data)
  const query = useMemo(() => {
    if (queryFromProps) {
      return queryFromProps.elements[0];
    }
    return null;
  }, [queryFromProps]);
  const allFilters = useMemo(() => ({...TABLE_COLUMN_FILTERS, ...filters}), [filters]);
  // Wrapping with fill null (proxying data)
  const onChange = useCallback(
    newQuery => {
      const fillNullElement = QueryBuilderFactory.createFillNull();
      fillNullElement.elements = [newQuery];
      fillNullElement.default = newQuery.else_value;
      fillNullElement[PARAMETERS_METADATA_KEY] = {
        [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: USER_PROPERTY_CONDITIONS_QUERY_BUILDER_NAME,
      };
      onChangeFromProps(fillNullElement);
    },
    [onChangeFromProps]
  );
  // Wrapping with fill null (proxying data)
  const errors = useMemo(() => {
    return get(errorsFromProps, 'elements.0');
  }, [errorsFromProps]);
  const rootError = useMemo(() => Boolean(errorsFromProps?.message), [errorsFromProps]);

  const onAddColumn = useCallback(
    (tableColumnId: number, tableColumn: any) => {
      const newQuery = query ? {...query} : createUserPropertyConditionsInitialQuery();
      const case_ = createCaseFor(tableColumnId, tableColumn);
      newQuery.cases.push(case_);
      onChange(newQuery);
    },
    [query, onChange]
  );
  const onTableColumnChanged = useCallback(
    (tableColumnId: number, caseIdx: number, tableColumn: any) => {
      const caseElem = createCaseFor(tableColumnId, tableColumn);
      const newQ = set(query, `cases.${caseIdx}`, caseElem);
      onChange(newQ);
    },
    [query, onChange]
  );
  const onOpChanged = useCallback(
    (op: ConditionOperator, caseIdx: number) => {
      const caseElem = cloneDeep(get(query, `cases.${caseIdx}`));
      caseElem[CASE_CONDITION_INDEX].op = op;
      caseElem[CASE_CONDITION_INDEX].right.value = null;
      caseElem[CASE_CONDITION_INDEX].right = mapLiteralByOp(
        caseElem[CASE_CONDITION_INDEX].right,
        op
      );
      const newQ = set(query, `cases.${caseIdx}`, caseElem);
      onChange(newQ);
    },
    [query, onChange]
  );
  const onValueChanged = useCallback(
    (v: any, caseIdx: number) => {
      const caseElem = cloneDeep(get(query, `cases.${caseIdx}`));
      caseElem[CASE_CONDITION_INDEX].right.value = v;
      const newQ = set(query, `cases.${caseIdx}`, caseElem);
      onChange(newQ);
    },
    [query, onChange]
  );
  const onThenChanged = useCallback(
    (v: any, caseIdx: number) => {
      const caseElem = cloneDeep(get(query, `cases.${caseIdx}`));
      caseElem[CASE_THEN_INDEX].value = v;
      const newQ = set(query, `cases.${caseIdx}`, caseElem);
      onChange(newQ);
    },
    [query, onChange]
  );
  const onElseValueChanged = useCallback(
    (v: string) => {
      const newQ = set(query, `else_value.value`, v);
      onChange(newQ);
    },
    [query, onChange]
  );
  const onDeleteCase = useCallback(
    (caseIndex: number) => {
      const newQ = del(query, `cases.${caseIndex}`);
      onChange(newQ);
    },
    [query, onChange]
  );
  const onDuplicateCase = useCallback(
    (caseIndex: number) => {
      const dupCase = cloneDeep(get(query, `cases.${caseIndex}`));
      dupCase[CASE_CONDITION_INDEX][PARAMETERS_METADATA_KEY][UI_CASE_KEY] = uniqueId('case_');
      const newQ = insert(query, `cases`, dupCase, caseIndex);
      onChange(newQ);
    },
    [query, onChange]
  );

  return (
    <div className={classNames(classes.UserPropertyConditionsQueryBuilder, className)}>
      <div className={classes.Builder}>
        {query &&
          query.cases.map((c, caseIdx) => (
            <div
              key={c[CASE_CONDITION_INDEX][PARAMETERS_METADATA_KEY][UI_CASE_KEY]}
              className={classes.ConditionBlock}
            >
              <QueryCounter
                numberClassName={classes.NumberClass}
                className={classes.ConditionNumber}
                number={caseIdx + 1}
              />
              <div className={classes.ConditionBody}>
                <div className={classes.ItemWrapper}>
                  <div className={classes.Item}>When</div>
                </div>
                <div className={classes.ItemWrapper}>
                  <TableColumnSmartSelector
                    className={classes.Item}
                    placeholder={'Property'}
                    value={c[CASE_CONDITION_INDEX][PARAMETERS_METADATA_KEY]?.[TABLE_COLUMN_ID_KEY]}
                    onChange={(v, tableColumn) => onTableColumnChanged(v, caseIdx, tableColumn)}
                    error={Boolean(
                      get(errors, `cases.${caseIdx}.${CASE_CONDITION_INDEX}.left.table_id`)
                    )}
                    filters={allFilters}
                    disabled={disabled}
                  />
                </div>
                <OpValueSelector
                  className={classes.ItemWrapper}
                  op={c[CASE_CONDITION_INDEX].op}
                  type={c[CASE_CONDITION_INDEX][PARAMETERS_METADATA_KEY]?.[TABLE_COLUMN_TYPE_KEY]}
                  value={c[CASE_CONDITION_INDEX].right.value}
                  onValueChange={v => onValueChanged(v, caseIdx)}
                  onOpChange={v => onOpChanged(v, caseIdx)}
                  error={Boolean(
                    get(errors, `cases.${caseIdx}.${CASE_CONDITION_INDEX}.right.value`)
                  )}
                  disabled={disabled}
                />
                <div className={classes.ItemWrapper}>
                  <div className={classes.Item}>then</div>
                </div>
                <div className={classes.ItemWrapper}>
                  <LiteralValueSelector
                    type={LiteralValueType.STRING}
                    value={c[CASE_THEN_INDEX].value}
                    placeholder={'Segment name'}
                    onChange={v => onThenChanged(v, caseIdx)}
                    error={Boolean(get(errors, `cases.${caseIdx}.${CASE_THEN_INDEX}.value`))}
                    disabled={disabled}
                  />
                </div>
              </div>
              <div className={classes.Actions}>
                {!disabled && (
                  <IconButton
                    tooltipText={'Duplicate'}
                    icon={CopyIcon}
                    className={classNames(classes.ShowOnHoverBtn, classes.Action)}
                    onClick={() => onDuplicateCase(caseIdx)}
                  />
                )}
                {query.cases.length > 1 && !disabled && (
                  <IconButton
                    tooltipText={'Remove condition'}
                    icon={CloseIcon}
                    className={classNames(classes.ShowOnHoverBtn, classes.Action)}
                    onClick={() => onDeleteCase(caseIdx)}
                  />
                )}
              </div>
            </div>
          ))}
        {!disabled && (
          <div className={classes.AddEvent}>
            {query && <QueryCounter className={classes.ConditionNumber} plus />}
            <div className={classes.ItemWrapper}>When</div>
            <div className={classes.ItemWrapper}>
              <TableColumnSmartSelector
                placeholder={'Property'}
                value={null}
                onChange={onAddColumn}
                filters={allFilters}
                error={rootError}
                disabled={disabled}
              />
            </div>
          </div>
        )}
        {query && (
          <div className={classes.AddEvent}>
            <div className={classes.ItemWrapper}>Otherwise</div>
            <div className={classes.ItemWrapper}>
              <LiteralValueSelector
                type={LiteralValueType.STRING}
                value={query?.else_value?.value}
                onChange={onElseValueChanged}
                disabled={!query || disabled}
                error={Boolean(get(errors, `else_value.value`))}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
