import {SmartSelectorPanel, SmartSelectorOption, IconButton, InfoIcon} from 'ui-components';
import TransKeys from '../../../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import classes from '../../smart-selector.module.scss';
import {ValidationStatus} from '../../../../../../objects/models/signal.model';
import InvalidSignalWarning from '../../../../../dmp/components/invalid-signal-warning/invalid-signal-warning.component';

interface OwnProps {
  label?: string;
  data: any;
  onSignalInfo: (signalId: number) => void;
  className?: string;
  txValidationStatusHelperPath?: string;
}

type AllProps = OwnProps;

const FeaturePreview = (props: AllProps) => {
  const {
    label,
    data,
    onSignalInfo,
    className,
    txValidationStatusHelperPath = TransKeys.FEATURES.SIGNAL_VALIDATION_STATUS_HELPER_TEXT,
  } = props;
  const {t} = useTranslation();

  const createChild = (signal: any, label: string) => {
    if (!signal) {
      return null;
    }

    return (
      <SmartSelectorOption
        key={signal.id}
        label={label}
        title={
          <div className={classes.WithInfo}>
            <span className={classes.Title}>{signal.name}</span>
            <IconButton
              tooltipText={t(TransKeys.GENERAL.ACTIONS.SHOW_DEFINITION)}
              size={'small'}
              onClick={() => onSignalInfo(signal.id)}
              icon={InfoIcon}
            />
            {signal.validationStatus === ValidationStatus.ERROR && (
              <InvalidSignalWarning title={t(txValidationStatusHelperPath, {label})} />
            )}
          </div>
        }
        description={signal.description}
      />
    );
  };

  return (
    <SmartSelectorPanel
      className={className}
      label={label}
      title={data.name}
      description={data.description}
    >
      {createChild(data.usageSignal, t(TransKeys.GENERAL.LABELS.USAGE))}
      {createChild(data.exposureSignal, t(TransKeys.GENERAL.LABELS.EXPOSURE))}
      {createChild(data.intentSignal, t(TransKeys.GENERAL.LABELS.INTENT))}
      {createChild(data.activationSignal, t(TransKeys.GENERAL.LABELS.ACTIVATION))}
      {createChild(data.churnSignal, t(TransKeys.GENERAL.LABELS.CHURN))}
    </SmartSelectorPanel>
  );
};

export {FeaturePreview};
