import {filesize} from 'filesize';
import {ImagesResource} from '../../../../../../assets/images';
import classes from './file-uploaded-preview.module.scss';
import {FileType} from '../file-dropzone.types';

type FileUploadedPreviewProps = {
  files?: FileType[];
  isError?: boolean;
};

// only supports 1 file at the moment
export const FileUploadedPreview = (props: FileUploadedPreviewProps) => {
  const {files, isError} = props;
  return files?.length === 1 ? (
    <div className={classes.SingleFileUploadedContainer}>
      <img src={isError ? ImagesResource.app.jsonFileError : ImagesResource.app.jsonFile} />
      <div className={classes.FileDescription}>
        <div className={classes.FileName}>{files[0].file.name}</div>
        <div className={classes.FileSize}>{filesize(files[0].file.size)}</div>
      </div>
    </div>
  ) : null;
};
