import {useMemo} from 'react';
import classNames from 'classnames';
import classes from './notification-item.module.scss';
import {
  Notification,
  NotificationJobStatus,
  NotificationModelType,
} from '../../../../../objects/models/notification.model';
import {
  BullsEyeArrowRegularIcon,
  CloseIcon,
  FileChartColumnRegularIcon,
  NotificationsIcon,
  SquareListRegularIcon,
  TableListRegularIcon,
  UserGroupRegularIcon,
  DatabaseRegularIcon,
  StopRegularIcon,
} from 'ui-components';
import moment from 'moment/moment';
import {CircularProgress, makeStyles} from '@material-ui/core';
import {NotificationClassesFactory} from './notification-classes';

interface OwnProps {
  notification: Notification;
  onClick: () => void;
  className?: string;
}

const ICON_MAP = {
  [NotificationModelType.METRIC]: BullsEyeArrowRegularIcon,
  [NotificationModelType.SEGMENT]: UserGroupRegularIcon,
  [NotificationModelType.CONTENT]: SquareListRegularIcon,
  [NotificationModelType.TABLE]: TableListRegularIcon,
  [NotificationModelType.ANALYSIS_RESULT]: FileChartColumnRegularIcon,
  [NotificationModelType.SOURCE]: DatabaseRegularIcon,
};

type AllProps = OwnProps;

const useSlowLinearProgress = makeStyles({
  bar: {
    // apply a new animation-duration to the `.bar` class
    animationDuration: '4s',
  },
});

export const NotificationItem = (props: AllProps) => {
  const {notification, onClick, className} = props;
  const progressClasses = useSlowLinearProgress();
  const Icon = ICON_MAP[props.notification.modelType] || NotificationsIcon;
  const timeAgo = useMemo(
    () => moment.utc(notification.createdOn).local().fromNow(),
    [notification]
  );
  const isInProgress =
    notification.jobStatus === NotificationJobStatus.IN_PROGRESS ||
    notification.jobStatus === NotificationJobStatus.STARTING;

  const message = useMemo(() => {
    return NotificationClassesFactory.create(notification).stringify();
  }, [notification]);

  return (
    <div
      onClick={onClick}
      className={classNames(
        classes.NotificationItem,
        classes[notification.modelType],
        notification.jobStatus === NotificationJobStatus.FAILED && classes.Error,
        notification.isRead && classes.Read,
        className
      )}
    >
      <div className={classNames(classes.IconWrapper, isInProgress && classes.InProgress)}>
        {isInProgress && (
          <div className={classes.ProgressWrapper}>
            <CircularProgress
              className={classNames(classes.CircularProgress, progressClasses.bar)}
              variant={'indeterminate'}
              disableShrink
              size={40}
              thickness={3}
              color={'inherit'}
            />
          </div>
        )}
        <Icon className={classes.Icon} />
        {notification.jobStatus === NotificationJobStatus.FAILED && (
          <div className={classNames(classes.Indicator, classes.Error)}>
            <CloseIcon className={classes.IndicatorIcon} />
          </div>
        )}
        {notification.jobStatus === NotificationJobStatus.STOPPED && (
          <div className={classNames(classes.Indicator, classes.Stopped)}>
            <StopRegularIcon className={classes.IndicatorIcon} />
          </div>
        )}
      </div>
      <div className={classes.Content}>
        <div className={classes.Title}>{message}</div>
        <div className={classes.SubTitle}>{timeAgo}</div>
      </div>
    </div>
  );
};
