import yup from '../../../../../config/yup.config';
import {PARAMETERS_METADATA_KEY} from '../../../../../constants/parameters-saved-keys';
import {values} from 'lodash';
import {TableEntity} from '../../../../../objects/models/table.model';
import {queryElementValidatorFactory} from '../../../../../objects/dto/query-builder.dto';
import {
  confoundersValadator,
  queryValidator,
  startEndDatesValidator,
  treatmentValidator,
  validateSignalBoundingDays,
  validateSignalTimeframeBoundingFor,
} from '../shared-validators';
import {ParameterType} from 'ui-components';
import {ordinalSuffix} from 'front-core';
import {
  AnalysisParametersTransformer,
  baseAnalysisParametersTransformer,
  confoundersAnalysisParametersTransformer,
} from '../shared-transformers';

export const analysis80ParametersValidator = yup.object().shape({
  parameters: yup
    .object()
    .shape({
      [PARAMETERS_METADATA_KEY]: yup.object().nullable(),
      goal_query: queryValidator(),
      entity: yup.string().oneOf(values(TableEntity)).required(),
      model_version: yup
        .string()
        .oneOf(['retention', 'conversion', 'payment_retention', 'existing'])
        .required(),
      population_filter: queryElementValidatorFactory(false),
      ...treatmentValidator(),
      ...confoundersValadator,
      max_days_from_reference: yup
        .number()
        .min(1)
        .required()
        .test(
          validateSignalBoundingDays({
            signalQueryParameter: 'goal_query',
          })
        ),
    })
    .required(),
  runParameters: startEndDatesValidator.required().test(
    validateSignalTimeframeBoundingFor({
      signalQueryParameter: 'goal_query',
      parameterName: 'analysis goal',
    })
  ),
});

export const analysis80ParametersTransformer: AnalysisParametersTransformer = (
  values,
  schema,
  productEntities
) => {
  [values, schema] = baseAnalysisParametersTransformer(values, schema, productEntities);
  [values, schema] = confoundersAnalysisParametersTransformer(values, schema);

  const newValues = {...values};

  delete schema['treatments_tag'];
  delete schema['treatments_signals'];
  schema['treatments_tag'] = {
    name: 'Scope',
    type: ParameterType.__MERGED_TREATMENTS,
    key: 'treatments_tag',
  };
  newValues['treatments_tag'] = [
    ...(newValues['treatments_tag'] || []),
    ...(newValues['treatments_signals'] || []),
  ];

  if ('model_version' in schema) {
    delete schema['model_version'];
  }

  if (values['model_version'] === 'retention') {
    if ('ref_date' in schema) {
      schema['ref_date'].name = 'Starting point';
      schema['ref_date'].order = 1;
    }
    if ('actions' in schema) {
      schema['actions'].name = 'Retention actions';
      schema['actions'].order = 2;
    }
    if ('goal_bound_unit' in schema) {
      schema['goal_bound_unit'].order = 3;
    }
  }
  if (values['model_version'] === 'conversion') {
    if ('goal' in schema) {
      schema['goal'].name = 'Goal';
      schema['goal'].order = 1;
    }
    if ('goal_bound_unit' in schema) {
      schema['goal_bound_unit'].order = 2;
    }
    if ('ref_date' in schema) {
      schema['ref_date'].order = 3;
      schema['ref_date'].name = 'Starting point';
    }
  }

  if ('goal_query' in values) {
    schema['goal_query'].name = 'Goal';
    schema['goal_query'].order = 3;
  }

  if ('goal_bound_n' in schema && 'goal_bound_unit' in schema) {
    schema['goal_bound_unit'].name = 'Time window';
    schema['goal_bound_unit'].type = ParameterType.STRING;
    if (values['model_version'] === 'conversion') {
      newValues['goal_bound_unit'] =
        `Within ${newValues['goal_bound_n']} ${newValues['goal_bound_unit']}s`;
    }
    if (
      values['model_version'] === 'retention' ||
      values['model_version'] === 'payment_retention'
    ) {
      newValues['goal_bound_unit'] = `${ordinalSuffix(newValues['goal_bound_n'])} ${
        newValues['goal_bound_unit']
      }`;
    }

    delete schema['goal_bound_n'];
  }

  if ('max_days_from_reference' in schema) {
    schema['max_days_from_reference'].order = 100;
    schema['max_days_from_reference'].name = 'Preliminary behavior period';
  }

  return [newValues, schema];
};
