import TransKeys from '../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {
  Button,
  CodeLightIcon,
  CopyIcon,
  EditIcon,
  FileCodeLightIcon,
  IconButton,
  MoreIcon,
  RenameIcon,
  RerunIcon,
  RSSIcon,
  ShareIcon,
  StopIcon,
} from 'ui-components';
import {Experiment, ExperimentStatus} from '../../../../objects/models/experiment.model';
import {useCallback, useContext, useMemo} from 'react';
import {
  CrumbNavItem,
  PageHeader,
} from '../../../shared/components/layout/page-header/page-header.component';
import {AppRoutes, EXPERIMENT_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {AnalysisParameters} from '../../../analyses/components/analysis-parameters/analysis-parameters.component';
import {Parameter} from '../../../shared/components/general/parameter/parameter.component';
import moment from 'moment';
import {TIME_FORMATS} from '../../../../constants/time-formats';
import {AnalysisResult} from '../../../../objects/models/analysis-result.model';
import {AnalysisTypeId} from '../../../../constants/analysis-type-id';
import {PanelKey} from '../../../../constants/panels';
import {PanelType} from '../../../../objects/system/panel-type.enum';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {useRerunExperiment} from '../../../../core/hooks/use-rerun.hook';
import {useIsAdmin} from '../../../../core/hooks/use-is-admin.hook';
import {AnalysisArtifacts} from '../../../analyses/components/analysis-artifacts/analysis-artifacts.component';
import {exists} from 'front-core';
import {viewAnalysisResultNotebook} from '../../../../http/analysis-results.network-requests';
import {useFeatureIsOn} from '@growthbook/growthbook-react';
import {FeatureFlag} from '../../../../constants/feature-flags';
import {ModelActionsDropdown} from '../../../shared/core/model-actions-dropdown/model-actions-dropdown.component';
import {useDemoProduct} from '../../../../core/hooks/use-demo-product.hook';

interface OwnProps {
  experiment: Experiment;
  analysisResult: AnalysisResult;
  onChangeStatus: (status: ExperimentStatus) => void;
  onEdit: () => void;
  onRename: (experimentId: number) => void;
  onDuplicate: () => void;
  onShare: () => void;
  onViewAnalysisSqlQueries: () => void;
  onDownloadArtifact: (analysisRes: AnalysisResult, artifactId: number) => void;
}

type AllProps = OwnProps;

export const ExperimentHeader = (props: AllProps) => {
  const {
    experiment,
    analysisResult,
    onChangeStatus,
    onEdit,
    onRename,
    onDuplicate,
    onShare,
    onDownloadArtifact,
    onViewAnalysisSqlQueries,
  } = props;
  const {t} = useTranslation();
  const {isDemoProduct} = useDemoProduct();
  const {openPrimaryPanel} = useContext(PanelsContext);
  const {onRerun, getExperimentRerunHelperText, shouldDisableRerun} = useRerunExperiment();
  const isAdmin = useIsAdmin();
  const exposeArtifacts = useFeatureIsOn(FeatureFlag.EXPOSE_ARTIFACTS as string);

  const isABTest = experiment?.analysis?.analysisTypeId === AnalysisTypeId.A_B_TEST;
  const isReleaseImpact = experiment?.analysis?.analysisTypeId === AnalysisTypeId.RELEASE_IMPACT;

  const crumbs: CrumbNavItem[] = useMemo(
    () => [
      {
        name: t(TransKeys.EXPERIMENTS.HEADER.TITLE),
        navigateTo: AppRoutes.experiments(),
      },
    ],
    [t]
  );
  const releaseDate = useMemo(() => {
    if (!analysisResult) {
      return null;
    }
    const startDate = moment.utc(analysisResult.parameters['release_date']).local();
    return startDate.format(TIME_FORMATS.READABLE_DATE);
  }, [analysisResult]);
  const timeFrame = useMemo(() => {
    if (!analysisResult) {
      return null;
    }
    const startDate = moment.utc(analysisResult.runParameters['start_date']).local();
    const endDate = moment.utc(analysisResult.runParameters['end_date']).local();
    return `${startDate.format(TIME_FORMATS.READABLE_DATE)} - ${endDate.format(
      TIME_FORMATS.READABLE_DATE
    )}`;
  }, [analysisResult]);
  const endDateText = useMemo(() => {
    if (!analysisResult) {
      return;
    }
    if (!analysisResult.runParameters['end_date']) {
      return null;
    }
    const endDate = moment.utc(analysisResult.runParameters['end_date']).local();
    return endDate.format(TIME_FORMATS.READABLE_DATE);
  }, [analysisResult]);
  const analyzedOn = useMemo(() => {
    if (!experiment) {
      return null;
    }
    if (!experiment.lastCompletedAnalysisResult) {
      return null;
    }
    return moment
      .utc(experiment.lastCompletedAnalysisResult?.updatedOn, TIME_FORMATS.PARAMETER_DATE_FORMAT)
      .fromNow();
  }, [experiment]);
  const viewAnalysisParams = useMemo(() => {
    if (!experiment) {
      return;
    }
    const params: any = {};
    if (experiment.lastCompletedAnalysisResult) {
      params.analysisResultId = experiment.lastCompletedAnalysisResult.id;
    } else if (experiment.lastAnalysisResultId) {
      params.analysisResultId = experiment.lastAnalysisResultId;
    } else {
      params.analysisId = experiment.analysisId;
    }
    return params;
  }, [experiment]);

  const onSubscribe = useCallback(
    experiment =>
      openPrimaryPanel(
        PanelKey.EXPERIMENT_SUBSCRIPTION_FORM_PANEL,
        {[EXPERIMENT_ID_PATH_PARAM]: experiment.id},
        PanelType.MODAL
      ),
    [openPrimaryPanel]
  );

  const rerunHelperText = useMemo(
    () => getExperimentRerunHelperText(experiment),
    [getExperimentRerunHelperText, experiment]
  );
  const disableRerun = useMemo(
    () => shouldDisableRerun(experiment),
    [shouldDisableRerun, experiment]
  );
  const viewSqlQueriesProps = useMemo(() => {
    const disabled = Boolean(experiment?.lastCompletedAnalysisResult?.executedSqlsCount) === false;

    return {
      tooltipText: disabled
        ? t(TransKeys.ANALYSIS_RESULT.EXECUTED_SQL_QUERIES.NOT_AVAILABLE)
        : t(TransKeys.GENERAL.ACTIONS.VIEW_QUERIES),
      icon: CodeLightIcon,
      onClick: onViewAnalysisSqlQueries,
      size: 'large' as any,
      disabled,
    };
  }, [experiment?.lastCompletedAnalysisResult?.executedSqlsCount, t, onViewAnalysisSqlQueries]);
  const showArtifacts = useMemo(() => {
    if (!analysisResult) {
      return false;
    }
    if (!exists(analysisResult.artifacts) || analysisResult.artifacts?.length === 0) {
      return false;
    }
    if (isAdmin) {
      return true;
    }
    return exposeArtifacts && analysisResult.analysisTypeId === AnalysisTypeId.RELEASE_IMPACT;
  }, [isAdmin, analysisResult, exposeArtifacts]);
  const onViewNotebook = id => window.open(viewAnalysisResultNotebook(id), '_blank');

  return (
    <PageHeader
      title={experiment?.name}
      description={experiment?.shortDescription}
      crumbs={crumbs}
      actions={
        <>
          <IconButton {...viewSqlQueriesProps} />
          <IconButton
            tooltipText={t(TransKeys.GENERAL.ACTIONS.SHARE)}
            icon={ShareIcon}
            onClick={() => onShare()}
            size={'large'}
          />
          <ModelActionsDropdown
            label={t(TransKeys.GENERAL.LABELS.MORE_DOTS)}
            icon={MoreIcon}
            iconDropdown
            iconSize={'large'}
            actions={[
              {
                key: 'rename',
                title: t(TransKeys.GENERAL.ACTIONS.RENAME),
                onClick: _ => onRename(experiment.id),
                icon: RenameIcon,
                showEnabled: true,
              },

              {
                key: 'EDIT_EXPERIMENT',
                title: t(TransKeys.GENERAL.ACTIONS.EDIT),
                onClick: () => onEdit(),
                disabled: experiment?.status === ExperimentStatus.DONE,
                icon: EditIcon,
                showEnabled: true,
              },
              {
                key: 'DUPLICATE_EXPERIMENT',
                title: t(TransKeys.GENERAL.ACTIONS.DUPLICATE),
                onClick: () => onDuplicate(),
                icon: CopyIcon,
                showEnabled: true,
              },
              {
                key: 'STOP_EXPERIMENT',
                title: t(TransKeys.GENERAL.ACTIONS.STOP_EXPERIMENT),
                onClick: () => onChangeStatus(ExperimentStatus.STOPPED),
                hide: experiment?.status !== ExperimentStatus.IN_PROGRESS,
                icon: StopIcon,
              },
              {
                key: 'RERUN_EXPERIMENT_LAST_COMPLETED_ANALYSIS_RESULT',
                title: t(TransKeys.GENERAL.ACTIONS.RERUN),
                onClick: () => onRerun(experiment),
                disabled: disableRerun,
                helperText: rerunHelperText,
                icon: RerunIcon,
              },
              {
                key: 'notebook',
                title: 'View notebook (admins only)',
                onClick: _ => onViewNotebook(experiment?.lastCompletedAnalysisResult?.id),
                icon: FileCodeLightIcon,
                hide: !isAdmin || !experiment?.lastCompletedAnalysisResult?.id,
              },
            ]}
          />
          {experiment?.status === ExperimentStatus.PENDING && (
            <Button
              disabled={isDemoProduct}
              onClick={() => onChangeStatus(ExperimentStatus.IN_PROGRESS)}
            >
              {t(TransKeys.GENERAL.ACTIONS.START_EXPERIMENT)}
            </Button>
          )}
          {experiment?.status === ExperimentStatus.IN_PROGRESS && isABTest && (
            <Button
              variant={'outlined'}
              onClick={() => onSubscribe(experiment)}
              icon={RSSIcon}
              active={Boolean(experiment.cronExp)}
              size={'large'}
            >
              {t(TransKeys.GENERAL.ACTIONS[experiment.cronExp ? 'SUBSCRIBED' : 'SUBSCRIBE'])}
            </Button>
          )}
          {experiment?.status === ExperimentStatus.IN_PROGRESS && (
            <Button
              disabled={isDemoProduct}
              onClick={() => onChangeStatus(ExperimentStatus.DONE)}
              size={'large'}
            >
              {t(TransKeys.GENERAL.ACTIONS.COMPLETE_EXPERIMENT)}
            </Button>
          )}
          {(experiment?.status === ExperimentStatus.DONE ||
            experiment?.status === ExperimentStatus.STOPPED) && (
            <Button disabled variant={'outlined'} onClick={_ => {}} size={'large'}>
              {t(TransKeys.EXPERIMENT.STATUS[experiment.status.toUpperCase()])}
            </Button>
          )}
        </>
      }
      subHeaderItems={[
        <Parameter
          label={t(TransKeys.GENERAL.LABELS.TYPE)}
          value={experiment?.analysis?.analysisName}
        />,
        isABTest && timeFrame && (
          <Parameter label={t(TransKeys.GENERAL.LABELS.TIME_FRAME)} value={timeFrame} />
        ),
        isABTest && analyzedOn && (
          <Parameter label={t(TransKeys.GENERAL.LABELS.LAST_ANALYZED)} value={analyzedOn} />
        ),
        isReleaseImpact && releaseDate && (
          <Parameter label={t(TransKeys.GENERAL.LABELS.RELEASE_DATE)} value={releaseDate} />
        ),
        isReleaseImpact && endDateText && (
          <Parameter label={t(TransKeys.GENERAL.LABELS.END_DATE)} value={endDateText} />
        ),
        viewAnalysisParams && (
          <AnalysisParameters
            label={t(TransKeys.GENERAL.LABELS.PARAMETERS)}
            allowDuplicate={false}
            {...viewAnalysisParams}
            border={false}
          />
        ),
        showArtifacts && (
          <AnalysisArtifacts
            onDownloadArtifact={artifactId => onDownloadArtifact(analysisResult, artifactId)}
            artifacts={analysisResult.artifacts}
          />
        ),
      ]}
    />
  );
};
