import classNames from 'classnames';
import classes from './custom-query-warning.module.scss';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../constants/translation-keys';
import {Link} from 'ui-components';

interface OwnProps {
  modelName: string;
  clearSignalDefinition?: () => void;
  className?: string;
}

type AllProps = OwnProps;

export const CustomQueryWarning = (props: AllProps) => {
  const {modelName, clearSignalDefinition, className} = props;
  const {t} = useTranslation();

  return (
    <div className={classNames(classes.CustomQueryWarning, className)}>
      <div>{t(TransKeys.GENERAL.LABELS.CREATED_USING_CUSTOM_QUERY, {modelName})}</div>
      {clearSignalDefinition && (
        <div>
          {t(TransKeys.GENERAL.LABELS.OVERRIDE_CUSTOM_DEFINITION)}&nbsp;
          <Link underline={false} inline onClick={clearSignalDefinition}>
            {t(TransKeys.GENERAL.LABELS.EDITING_IT)}
          </Link>
          .
        </div>
      )}
    </div>
  );
};
