import {useCallback, useMemo, useState} from 'react';
import {Button, FancyHeader, FolderIcon, ModalLayout, SmartTreeSelector} from 'ui-components';
import TransKeys from '../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {useAnalysisFoldersTree} from '../../hooks/use-analysis-folders-tree.hook';
import classes from './move-to-folder-panel.module.scss';
import {ModelKey} from '../../../../constants/model-key';
import {useDispatch} from 'react-redux';
import {moveAnalysisFolder} from '../../../../store/analysis-folders/analysis-folders.actions';
import {moveAnalysisToFolder} from '../../../../store/analyses/analyses.actions';
import {AnalysisFolderType} from '../../../../objects/models/analysis-folder.model';
import {composition} from 'front-core';
import {withDisableDemoProduct} from '../../../../core/hoc/with-disable-demo-product.hoc';

interface OwnProps {
  onClose?: () => void;
  modelId: number;
  modelKey: ModelKey;
  folderTypes?: AnalysisFolderType[];
  disabled?: boolean;
}

type AllProps = OwnProps;

const MoveToFolderPanelComponent = (props: AllProps) => {
  const {modelId, modelKey, folderTypes, onClose, disabled} = props;
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [selectedFolderId, setSelectedFolderId] = useState(null);
  const analysisFoldersConfig = useMemo(
    () => ({
      folderTypes,
      excludeId: modelKey === ModelKey.ANALYSIS_FOLDER ? [modelId] : undefined,
    }),
    [folderTypes, modelId, modelKey]
  );
  const {groups, isLoading} = useAnalysisFoldersTree(analysisFoldersConfig);
  const isDisabled = useMemo(
    () => selectedFolderId === null || disabled,
    [selectedFolderId, disabled]
  );

  const onSubmit = useCallback(() => {
    const payload = {
      id: modelId,
      analysisFolderId: selectedFolderId,
    };
    if (modelKey === ModelKey.ANALYSIS_FOLDER) {
      dispatch(moveAnalysisFolder(payload, onClose));
      return;
    }
    if (modelKey === ModelKey.ANALYSIS) {
      dispatch(moveAnalysisToFolder(payload, onClose));
      return;
    }
  }, [dispatch, onClose, modelId, modelKey, selectedFolderId]);

  if (isLoading) {
    return <GenericLoading />;
  }

  return (
    <div className={classes.Container}>
      <ModalLayout
        footer={
          <Button onClick={onSubmit} disabled={isDisabled}>
            {t(TransKeys.GENERAL.ACTIONS.SAVE)}
          </Button>
        }
      >
        <div className={classes.Content}>
          <FancyHeader
            icon={FolderIcon}
            title={t(TransKeys.GENERAL.ACTIONS.MOVE_TO_FOLDER)}
            onClose={onClose}
            className={classes.Header}
          />
          <SmartTreeSelector
            className={classes.Selector}
            value={selectedFolderId}
            onChange={value => setSelectedFolderId(value as any)}
            groups={groups}
            startRootOpen={true}
            selectParentValue={true}
            multi={false}
          />
        </div>
      </ModalLayout>
    </div>
  );
};

export const MoveToFolderPanel = composition<AllProps>(
  MoveToFolderPanelComponent,
  withDisableDemoProduct
);
