import {Button, DocumentElementType, MarkdownViewer} from 'ui-components';
import classes from './analysis-type-selection-about.module.scss';
import modalClasses from '../../modal-layout.module.scss';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {AnalysisTypeImage} from '../../../../../../components/analysis-type-image/analysis-type-image.component';

interface OwnProps {
  className?: string;
  analysisType: any;
  onSelect: (analysisType: any) => void;
  onDismiss: () => void;
}

type AllProps = OwnProps;

export const AnalysisTypeSelectionAbout = (props: AllProps) => {
  const {analysisType, onSelect, onDismiss} = props;
  const {t} = useTranslation();

  return (
    <div className={modalClasses.ModalBody}>
      <div className={modalClasses.ModalContent}>
        <div className={classes.AboutAnalysis}>
          <div className={classes.AboutAnalysisContent}>
            <div>
              <MarkdownViewer
                title={analysisType.staticName}
                type={DocumentElementType.MARKDOWN}
                data={analysisType.description}
              />
            </div>
          </div>
          <div className={classes.AboutAnalysisImage}>
            <AnalysisTypeImage analysisType={analysisType} />
          </div>
        </div>
      </div>
      <footer className={modalClasses.ModalFooter}>
        <Button onClick={onDismiss} variant="outlined">
          {t(TransKeys.GENERAL.ACTIONS.BACK)}
        </Button>
        <Button onClick={() => onSelect(analysisType)}>
          {t(TransKeys.GENERAL.ACTIONS.SELECT_ANALYSIS)}
        </Button>
      </footer>
    </div>
  );
};
