import classNames from 'classnames';
import {ChartMode} from '../../../../homepage-summary.types';
import classes from './homepage-chart-mode-options.module.scss';
import {
  CrystalBallDuotoneIcon,
  PopoverWrapper,
  useLocalStorage,
  DialogLayout,
  Checkbox,
  ChevronFullDownIcon,
} from 'ui-components';
import {Tooltip} from '@material-ui/core';
import {ModelSampleSeries} from '../../../../../../../../objects/models/model-sample-series.model';
import {useCallback, useMemo, useRef} from 'react';
import {last} from 'lodash';
import {TIME_FORMATS} from '../../../../../../../../constants/time-formats';
import moment from 'moment';
import {number2k} from 'front-core';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';

interface OwnProps {
  mode: ChartMode;
  onChange: (mode: ChartMode) => void;
  series: ModelSampleSeries;
  isPercentageValue?: boolean;
  className?: string;
}

type AllProps = OwnProps;

enum ForecastMode {
  EOM = 'EOM',
  EOQ = 'EOQ',
}

export const HomepageChartModeOptions = (props: AllProps) => {
  const {mode, onChange, series, isPercentageValue, className} = props;
  const [forecastMode, setForecastMode] = useLocalStorage('forecastMode', ForecastMode.EOM);
  const popoverRef = useRef(null);
  const {t} = useTranslation();

  const value = useMemo(() => {
    if (!series.predictedSamples || series.predictedSamples.length === 0) {
      return null;
    }
    const lastNonPartialSample = last(series.samples.filter(sample => !sample.isPartial));
    if (!lastNonPartialSample) {
      return;
    }
    let date = moment.utc(lastNonPartialSample.datetime, TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT);
    if (forecastMode === ForecastMode.EOM) {
      date = date.endOf('month');
    } else if (forecastMode === ForecastMode.EOQ) {
      date = date.endOf('quarter');
    }
    const matchingIndex = series.predictedSamples.findIndex(sample =>
      moment.utc(sample.datetime, TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT).isSameOrAfter(date)
    );
    if (matchingIndex === -1) {
      return;
    }
    const value =
      series.predictedSamples[matchingIndex > 0 ? matchingIndex - 1 : matchingIndex].value;
    if (isPercentageValue) {
      return `${number2k(value * 100)}%`;
    }
    return number2k(value);
  }, [series, forecastMode, isPercentageValue]);
  const onChangeForecastMode = useCallback(
    (forecastMode: ForecastMode) => {
      setForecastMode(forecastMode);
      onChange(ChartMode.PREDICTED);
      popoverRef.current?.close();
    },
    [setForecastMode, onChange]
  );

  return (
    <div className={classNames(classes.HomepageChartModeOptions, className)}>
      <div
        onClick={() => onChange(ChartMode.INCOMPLETE)}
        className={classNames(
          classes.Option,
          classes.CurrentOption,
          mode === ChartMode.INCOMPLETE ? classes.Selected : null
        )}
      >
        {t(TransKeys.HOMEPAGE.CHART_MODE.CURRENT.LABEL)}
      </div>
      <Tooltip title={t(TransKeys.HOMEPAGE.CHART_MODE.PREDICTED.HELPER_TEXT)}>
        <div
          onClick={() => onChange(ChartMode.PREDICTED)}
          className={classNames(
            classes.Option,
            classes.PredictedOption,
            mode === ChartMode.PREDICTED && classes.Selected
          )}
        >
          <CrystalBallDuotoneIcon />
          <span>
            {forecastMode} {t(TransKeys.HOMEPAGE.CHART_MODE.PREDICTED.LABEL)}
          </span>
          {value && <span className={classes.Value}>{value}</span>}
        </div>
      </Tooltip>
      <PopoverWrapper
        ref={popoverRef}
        buttonRenderer={props => (
          <div
            onClick={props.onClick}
            className={classNames(
              classes.DropDown,
              props.isOpen && classes.Open,
              mode === ChartMode.PREDICTED && classes.Selected
            )}
          >
            <ChevronFullDownIcon className={classes.ChevronIcon} />
          </div>
        )}
      >
        <DialogLayout>
          <div className={classes.Options}>
            <div
              className={classNames(
                classes.Option,
                forecastMode === ForecastMode.EOM && classes.Selected
              )}
            >
              <Checkbox
                checked={forecastMode === ForecastMode.EOM}
                onChange={() => onChangeForecastMode(ForecastMode.EOM)}
                label={t(TransKeys.HOMEPAGE.FORECAST_MODE.EOM)}
              />
            </div>
            <div
              className={classNames(
                classes.Option,
                forecastMode === ForecastMode.EOQ && classes.Selected
              )}
            >
              <Checkbox
                checked={forecastMode === ForecastMode.EOQ}
                onChange={() => onChangeForecastMode(ForecastMode.EOQ)}
                label={t(TransKeys.HOMEPAGE.FORECAST_MODE.EOQ)}
              />
            </div>
          </div>
        </DialogLayout>
      </PopoverWrapper>
    </div>
  );
};
