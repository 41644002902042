import {BaseAnalysesView} from './base-analyses-view.component';
import classes from './analyses-views.module.scss';
import {useTranslation} from 'react-i18next';
import {useRemoteSourceStated} from 'ui-components';
import {searchAnalysisFoldersNetworkRequest} from '../../../../../../http/analysis-folders.network-requests';
import {useEffect} from 'react';
import {searchAnalysisResultsNetworkRequest} from '../../../../../../http/analysis-results.network-requests';
import TransKeys from 'translations';
import {EmptyState} from '../../../../../shared/components/general/override';

interface OwnProps {
  search: any;
  onFolderClicked: (analysisFolderId: number | string) => void;
  className?: string;
}

type AllProps = OwnProps;

export const SearchAnalysesView = (props: AllProps) => {
  const {search, onFolderClicked, className} = props;
  const {t} = useTranslation();
  const {
    source: folders,
    exec: searchFolders,
    isLoading: isLoadingFolders,
  } = useRemoteSourceStated({
    type: 'source',
    networkRequest: searchAnalysisFoldersNetworkRequest,
    initialValue: [],
    transformer: res => res.data,
  });

  useEffect(() => {
    search.q &&
      searchFolders({
        q: search.q,
        itemsPerPage: 1000,
      });
  }, [searchFolders, search.q]);

  return (
    <BaseAnalysesView
      className={className}
      onFolderClicked={onFolderClicked}
      extraFilters={search}
      children={folders}
      isLoading={isLoadingFolders}
      networkRequest={searchAnalysisResultsNetworkRequest}
      header={<div className={classes.TextHeader}>Search Results</div>}
      customEmptyState={
        <EmptyState
          title={t(TransKeys.ANALYSES.EMPTY_STATE.TITLE)}
          subTitle={t(TransKeys.ANALYSES.EMPTY_STATE.SUB_TITLE)}
        />
      }
    />
  );
};
