import {useCallback, useContext, useMemo} from 'react';
import {
  AnnotationIcon,
  Button,
  FancyHeader,
  LabelWrapper,
  ModalLayout,
  SwitchActions,
} from 'ui-components';
import classes from './annotation-form-panel.module.scss';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../constants/translation-keys';
import {FormProvider, useForm} from 'react-hook-form';
import {values} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {sharedClasses} from '../../../shared';
import {yupResolver} from '@hookform/resolvers/yup';
import {Annotation, AnnotationType} from '../../../../objects/models/annotation.model';
import {AnnotationDTO, annotationDTOValidator} from '../../../../objects/dto/annotation.dto';
import {
  createAnnotation,
  updateAnnotation,
} from '../../../../store/annotations/annotations.actions';
import {ANNOTATION_TYPE_OPTIONS} from '../../../../constants/options';
import {
  ANALYSIS_TYPE_ID_PATH_PARAM,
  ANNOTATION_ID_PATH_PARAM,
} from '../../../../constants/app-routes';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {getReducedLoadingStateSelector} from '../../../../store/store.selectors';
import {ActionKey} from '../../../../constants/action-key';
import {composition, exists} from 'front-core';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {getAnnotationNetworkRequest} from '../../../../http/annotations.network-requests';
import {FormHiddenInputs} from '../../../shared/form/components/form-hidden-inputs.component';
import {
  DateFormInput,
  TextFormInput,
} from '../../../shared/form/components/shared-form-input.component';
import {preventSubmitOnEnter} from '../../../../utils/general.utils';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {ANNOTATION_ICONS} from '../../../../constants/ui';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {PanelKey} from '../../../../constants/panels';
import {AnalysisTypeId} from '../../../../constants/analysis-type-id';
import {TIME_FORMATS} from '../../../../constants/time-formats';
import moment from 'moment';
import {withModalInactiveSourceHandler} from '../../../../core/hoc/with-modal-inactive-source-handler.hoc';
import {withDisableDemoProduct} from '../../../../core/hoc/with-disable-demo-product.hoc';

interface OwnProps {
  annotation?: Annotation;
  data: Partial<Annotation>;
  [ANNOTATION_ID_PATH_PARAM]?: number;
  onClose?: () => void;
  panelId?: string;
  disabled?: boolean;
}

type AllProps = OwnProps;

const createEmptyAnnotation = (): Partial<AnnotationDTO> => ({
  title: '',
  type: null,
  timestamp: null,
});

const SELECTED_ANNOTATION_KEY = SharedSelectionKeys.ANNOTATION_FORM__ANNOTATION;
const MAX_DATE = new Date();

const AnnotationFormPanelComponent = (props: AllProps) => {
  const {
    annotation = {},
    data = createEmptyAnnotation(),
    onClose,
    disabled: disabledFromProps,
  } = props;
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {openPrimaryPanel} = useContext(PanelsContext);
  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      ...data,
      ...annotation,
    } as any,
    resolver: yupResolver(annotationDTOValidator.noUnknown()),
  });
  const {
    handleSubmit,
    formState: {errors},
    watch,
    setValue,
  } = formMethods;
  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(ActionKey.CREATE_ANNOTATION, ActionKey.UPDATE_ANNOTATION)(state)
  );
  const type = watch('type');
  const annotationTypesOptions = useMemo(
    () =>
      ANNOTATION_TYPE_OPTIONS().map(p => ({
        key: p.value,
        value: p.value,
        label: p.label,
        onClick: () => setValue('type', p.value, {shouldValidate: true}),
        isActive: p.value === type,
        icon: ANNOTATION_ICONS[p.value],
      })),
    [setValue, type]
  );
  const editMode = Boolean(watch('id'));
  const onSubmit = useCallback(
    data => {
      const {runRelease, ...fromData} = data;
      const onSuccess = anno => {
        onClose();
        runRelease &&
          openPrimaryPanel(PanelKey.EXPERIMENT_FORM_PANEL, {
            [ANNOTATION_ID_PATH_PARAM]: anno.id,
            [ANALYSIS_TYPE_ID_PATH_PARAM]: AnalysisTypeId.RELEASE_IMPACT,
            parameters: {
              release_date: moment(anno.timestamp, TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT).format(
                TIME_FORMATS.PARAMETER_DATE_FORMAT
              ),
            },
          });
      };
      dispatch(
        editMode ? updateAnnotation(fromData, onSuccess) : createAnnotation(fromData, onSuccess)
      );
    },
    [editMode, dispatch, onClose, openPrimaryPanel]
  );
  const onSubmitRunRelease = useCallback(data => onSubmit({...data, runRelease: true}), [onSubmit]);
  const renderFooter = () => {
    if (type !== AnnotationType.RELEASE || editMode) {
      const disabled = values(errors).length > 0 || disabledFromProps;
      return (
        <Button disabled={disabled} onClick={handleSubmit(onSubmit)}>
          {t(TransKeys.GENERAL.ACTIONS.SAVE)}
        </Button>
      );
    }
    return (
      <div className={classes.FooterActions}>
        <Button
          variant={'outlined'}
          disabled={values(errors).length > 0}
          onClick={handleSubmit(onSubmit)}
        >
          {t(TransKeys.GENERAL.ACTIONS.SAVE)}
        </Button>
        <Button disabled={values(errors).length > 0} onClick={handleSubmit(onSubmitRunRelease)}>
          {t(TransKeys.ANNOTATION_FORM.SAVE_AND_RUN_RELEASE)}
        </Button>
      </div>
    );
  };
  const containerStyle = useMemo(() => ({minHeight: '28rem', width: '54rem'}), []);

  return (
    <div style={containerStyle}>
      <ModalLayout footer={renderFooter()}>
        {isLoading && <GenericLoading />}
        <div className={classes.CreateAnnotation}>
          <FancyHeader
            title={t(
              editMode
                ? TransKeys.ANNOTATION_FORM.EDIT_TITLE
                : TransKeys.ANNOTATION_FORM.CREATE_TITLE
            )}
            subTitle={t(TransKeys.ANNOTATION_FORM.MAIN_TITLE)}
            icon={AnnotationIcon}
            onClose={onClose}
            className={classes.Header}
          />

          <FormProvider {...formMethods}>
            <form
              className={sharedClasses.Form}
              onKeyDown={preventSubmitOnEnter}
              onSubmit={handleSubmit(onSubmit)}
            >
              <FormHiddenInputs names={['id']} />
              <div className={sharedClasses.FormContent}>
                <div className={sharedClasses.Block}>
                  <div className={sharedClasses.Input}>
                    <LabelWrapper
                      label={t(TransKeys.ANNOTATION_FORM.INPUTS.TYPE.LABEL)}
                      required
                      error={exists(errors['type'])}
                    >
                      <SwitchActions actions={annotationTypesOptions} showActionsLabel />
                    </LabelWrapper>
                  </div>
                </div>
                <div className={sharedClasses.Block}>
                  <div className={sharedClasses.Input}>
                    <DateFormInput
                      label={t(TransKeys.ANNOTATION_FORM.INPUTS.TIMESTAMP.LABEL)}
                      name={'timestamp'}
                      fullWidth={false}
                      maxDate={MAX_DATE}
                      required
                    />
                  </div>
                </div>
                <div className={sharedClasses.Block}>
                  <div className={sharedClasses.Input}>
                    <TextFormInput
                      label={t(TransKeys.ANNOTATION_FORM.INPUTS.TITLE.LABEL)}
                      name={'title'}
                      required
                    />
                  </div>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </ModalLayout>
    </div>
  );
};

const AnnotationFormPanel = composition<AllProps>(
  AnnotationFormPanelComponent,
  withModalInactiveSourceHandler,
  withDisableDemoProduct,
  withLoadBefore({
    annotation: {
      selectedKey: SELECTED_ANNOTATION_KEY,
      actionKey: SELECTED_ANNOTATION_KEY,
      mapPayloadFromProps: props => props[ANNOTATION_ID_PATH_PARAM],
      shouldCall: props => props[ANNOTATION_ID_PATH_PARAM] !== undefined,
      request: getAnnotationNetworkRequest,
    },
  })
);
export default AnnotationFormPanel;
