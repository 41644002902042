import {useTranslation} from 'react-i18next';
import {FormProvider, useForm} from 'react-hook-form';
import classes from './mfa-form.module.scss';
import TransKeys from '../../../../../constants/translation-keys';
import {composition} from 'front-core';
import {MFADTO, mfaDTOValidator} from '../../../../../objects/dto/mfa.dto';
import {useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';
import {throttle} from 'lodash';
import {yupResolver} from '@hookform/resolvers/yup';
import {AuthFormTextInput} from '../../auth-scope/inputs/auth-text-input/auth-text-input.component';
import sharedClasses from '../../shared-forms.module.scss';
import AuthForm from '../../auth-scope/auth-scope-form/auth-form.component';
import {FormHiddenInputs} from '../../../../shared/form/components/form-hidden-inputs.component';

interface OwnProps {
  onSubmit: (mfaDTO: MFADTO) => void;
  onResend: () => void;
  tokenId: number;
  isLoading: boolean;
  error?: string;
}

type AllProps = OwnProps;
const RESEND_DISABLED_SEC = 60;

const MFAFormComponent = ({onSubmit, isLoading, error, tokenId, onResend}: AllProps) => {
  const {t} = useTranslation();
  const [resendDisabledSec, setResendDisabledSec] = useState(RESEND_DISABLED_SEC);
  const formMethods = useForm<MFADTO>({
    defaultValues: {
      tokenId,
      code: '',
    },
    resolver: yupResolver(mfaDTOValidator, {abortEarly: false}),
  });
  const {handleSubmit, setValue} = formMethods;
  useEffect(() => {
    setValue('tokenId', tokenId);
    setResendDisabledSec(RESEND_DISABLED_SEC);
  }, [tokenId, setValue]);
  const _onResend = throttle(onResend, 1000);

  useEffect(() => {
    const interval = setInterval(() => {
      setResendDisabledSec(resendDisabledSec => {
        if (resendDisabledSec > 0) return resendDisabledSec - 1;
        return 0;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const renderLeftToSubmitProp = useMemo(
    () => (
      <div className={classes.Submit}>
        <span
          className={classNames(classes.Link, resendDisabledSec > 0 && classes.Disabled)}
          onClick={_ => _onResend()}
        >
          {t(TransKeys.AUTH_FORMS.LOGIN.FORM.RESEND_MFA, {
            sec: resendDisabledSec,
          })}
        </span>
      </div>
    ),
    [resendDisabledSec, _onResend, t]
  );

  return (
    <FormProvider {...formMethods}>
      <AuthForm
        onSubmit={handleSubmit(onSubmit)}
        onSubmitText={t(TransKeys.AUTH_FORMS.LOGIN.FORM.SUBMIT)}
        error={error}
        renderLeftToSubmit={renderLeftToSubmitProp}
      >
        <div className={classes.MFAForm} onSubmit={handleSubmit(onSubmit)}>
          <FormHiddenInputs names={['tokenId']} />
          <div className={classes.FormBody}>
            <div className={classes.InputRow}>
              {t(TransKeys.AUTH_FORMS.LOGIN.FORM.MFA_DESCRIPTION)}
            </div>
            <div className={sharedClasses.FormBlock}>
              <AuthFormTextInput
                required
                label={t(TransKeys.AUTH_FORMS.LOGIN.FORM.CODE)}
                type={'text'}
                name={'code'}
              />
            </div>
          </div>
        </div>
      </AuthForm>
    </FormProvider>
  );
};

const MFAForm = composition<OwnProps>(MFAFormComponent);
export default MFAForm;
